import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ChatMessage } from 'src/app/Models/chatmessage.model';
import { Doctor } from 'src/app/Models/doctor.model';
import { Nurse } from 'src/app/Models/nurse.model';
import { Patient } from 'src/app/Models/patient.model';
import { User } from 'src/app/Models/user.model';
import { ChatService } from 'src/app/services/chat.service';
import { DoctorService } from 'src/app/services/doctor.service';
import { HospitalService } from 'src/app/services/hospital.service';
import { PatientService } from 'src/app/services/patient.service';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-chat-for-patient',
  templateUrl: './chat-for-patient.component.html',
  styleUrls: ['./chat-for-patient.component.css'],
})
export class ChatForPatientComponent implements OnInit {
  // users = ['User1', 'User2', 'User3']; // Replace with real user list
  selectedUser: string;
  message: string;
  doctorId = 0;
  helper = new JwtHelperService();
  myToken = this.tokenService.getToken();
  users: User[] = [];
  myMessage: ChatMessage[];
  hospitalId: number;
  doctorList: Doctor[];
  nurseList: Nurse[];
  doctor: Doctor;
  combinedData: any[] = [];
  isSuccess = false;
  Frm: FormGroup;
  patientId: number = 0;
  patient: Patient;

  constructor(
    private chatService: ChatService,
    private fb: FormBuilder,
    private doctorService: DoctorService,
    private patientService: PatientService,
    private hospitalService: HospitalService,
    private tokenService: TokenService
  ) {}

  ngOnInit() {
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.patientId = parseInt(decodedToken.nameid);
    this.getPatient(this.patientId);
    this.patientService.getpatienHospitalId(this.patientId).subscribe((res) => {
      this.hospitalId = res.Hospital.HospitalId;
      this.getDoctors(this.hospitalId);
    });
    this.getMessages(this.patientId);

    this.Frm = this.fb.group({
      Message: [''],
      UserId: [],
    });
  }

  get UserId() {
    return this.Frm.get('UserId');
  }
  get Message() {
    return this.Frm.get('Message');
  }

  getDoctors(id) {
    this.hospitalService.getDoctors(id).subscribe((res) => {
      this.doctorList = res.Doctors;
    });
  }
  getPatient(id) {
    this.patientService.getPatientById(id).subscribe((res) => {
      this.patient = res;
    });
  }

  getMessages(id) {
    this.patientService.getPatientChats(id).subscribe((res) => {
      this.myMessage = res.ChatMessages;
    });
  }
  formSubmit() {
    let userId = this.Frm.value['UserId'];
    let message = this.Frm.value['Message'];
    let doctorName = this.doctorList.filter((it) => it.DoctorId == userId)[0]
      .DoctorName;
    let body = {
      Message: message,
      FromUserName:
        this.patient.PatientFirstName + this.patient.PatientLastName,
      ToUserName: doctorName,
      DoctorId: userId,
      PatientId: this.patientId,
      NurseId: 0,
    };
    // console.log(body);
    this.chatService.sendChatMessage(body).subscribe((res) => {
      console.log(res);
      this.isSuccess = true;
      this.myMessage.push(res);
    });
  }
  // sendMessage() {
  //   if (this.selectedUser && this.message) {
  //     this.chatService.sendMessage(this.selectedUser, this.message);
  //     this.message = ''; /
  //   }
  // }

  delete(id) {
    this.chatService.deleteChatMessage(id).subscribe((res) => {
      alert('are you sure do you wanted to delete message?');
      this.getMessages(this.doctorId);
    });
  }
}
