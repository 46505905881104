import { Component } from '@angular/core';

@Component({
  selector: 'app-add-medical-assistant',
  templateUrl: './add-medical-assistant.component.html',
  styleUrls: ['./add-medical-assistant.component.css']
})
export class AddMedicalAssistantComponent {

}
