<div class="subscription-container">
    <h2>Subscription Required</h2>
    <p>You need an active subscription to access the hospital dashboard.</p>
    <h3>Select a Subscription Plan</h3>
    <button type="button" (click)="logout()" color="accent" class="btn  float-right mx-3 my-3 logout "
        mat-raised-button>
        <!-- <mat-icon matPrefix>highlight_off</mat-icon> -->
        <span>Logout</span>
    </button>
    <!-- Dropdown for selecting a hospital -->
    <!-- <div class="col-lg-4">
        <mat-form-field appearance="fill" class="example-full-width">
            <mat-label>Select Hospital</mat-label>
            <mat-select [(ngModel)]="HospitalId">
                <mat-option *ngFor="let hs of hospitalList" [value]="hs.HospitalId">
                    {{ hs.HospitalName }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div> -->

    <!-- List of subscription plans -->
    <div class="plans">
        <div class="plan-card" *ngFor="let plan of plans" [class.selected]="plan.PlanId === selectedPlanId"
            (click)="selectedPlanId = plan.PlanId">
            <h3>{{ plan.PlanName }}</h3>
            <p>Price: {{ plan.Price | currency }}</p>
            <p>Max Users: {{ plan.MaxUsers }}</p>
            <p>Max Appointments: {{ plan.MaxAppointments }}</p>
            <p>Features: {{ plan.Features }}</p>
        </div>
    </div>

    <!-- Stripe Card Element -->
    <div id="card-element" class="stripe-card-element"></div>
    <div id="card-errors" role="alert" class="card-errors"></div>

    <!-- Subscribe button -->
    <button class="subscribe-button" (click)="subscribeToPlan()">Pay & Subscribe</button>
</div>