import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BillerService } from 'src/app/services/biller.service';
import { ClaimService } from 'src/app/services/claim.service';
import { DoctorService } from 'src/app/services/doctor.service';
import { HospitalService } from 'src/app/services/hospital.service';
import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-claim-notes',
  templateUrl: './claim-notes.component.html',
  styleUrls: ['./claim-notes.component.css'],
})
export class ClaimNotesComponent implements OnInit {
  uploadList: any[] = [];
  errorMessage: string | null = null;
  xmlList: any[] = [];
  helper = new JwtHelperService();

  myToken = this.tokenService.getToken();

  showItem = '';
  hospitalId: number;

  id = 0;
  accountKey = '';
  displayedColumns: string[] = [
    'ClaimId',
    'NoteText',
    'Username',
    'DateTime',
    'NoteId',
    'ClaimMdId',
    'Pcn',
  ];
  dataSource = new MatTableDataSource<any>([]);

  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(
    private claimService: ClaimService,
    private dialog: MatDialog,
    private hospitalService: HospitalService,
    private tokenService: TokenService,
    private billerService: BillerService,
    private doctorService: DoctorService,
    private sanitizer: DomSanitizer
  ) {}
  ngOnInit(): void {
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.id = parseInt(decodedToken.nameid);

    // Check the role in the decoded token
    if (decodedToken.role === 'Biller') {
      this.billerService.getbillerHospital(this.id).subscribe((res) => {
        this.hospitalId = res.Hospital.HospitalId;
        this.getCliamKey(this.hospitalId); // Fetch claim key for the biller's hospital
      });
    } else if (decodedToken.role === 'Hospital') {
      this.getCliamKey(this.id); // Fetch claim key directly using the hospital ID
    } else if (decodedToken.role === 'Doctor') {
      this.doctorService.getDoctorHospital(this.id).subscribe((res) => {
        this.hospitalId = res.Hospital.HospitalId;
        this.getCliamKey(this.hospitalId); // Fetch claim key for the biller's hospital
      });
    } else {
      console.error('Invalid role detected in token:', decodedToken.role);
    }
  }
  getCliamKey(id: number) {
    this.hospitalService.getclaimAccountKey(id).subscribe((res) => {
      if (res.ClaimAccountKeys && res.ClaimAccountKeys.length > 0) {
        this.accountKey = res.ClaimAccountKeys[0].AccountKey;
        this.getclaimNotes(this.accountKey);
      } else {
        console.error(
          'No ClaimAccountKeys found for the provided hospital ID.'
        );
      }
    });
  }
  getclaimNotes(id) {
    this.errorMessage = null; // Reset error message
    this.uploadList = []; // Reset upload list
    this.claimService.getClaimNotes(id).subscribe({
      next: (response: any) => {
        try {
          const parsedResponse = JSON.parse(response); // Parse the JSON string into an object
          this.xmlList = parsedResponse.result || [];
          this.dataSource.data = this.xmlList;

          // Pretty print the JSON
        } catch (error) {
          console.error('Error parsing JSON response:', error);
        }
      },
      error: (error: any) => {
        console.error('Error fetching upload list:', error);
      },
    });
  }
  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }
}
