import { Component } from '@angular/core';

@Component({
  selector: 'app-add-practice-manager',
  templateUrl: './add-practice-manager.component.html',
  styleUrls: ['./add-practice-manager.component.css']
})
export class AddPracticeManagerComponent {

}
