import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { PaymentmethodService } from 'src/app/services/paymentmethod.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { TokenService } from 'src/app/services/token.service';
import { environment } from 'src/environments/environment';
import { StripeScriptTag } from 'stripe-angular';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css'],
})
export class PaymentComponent implements OnInit {
  paymentForm: FormGroup;
  @ViewChild('stripeCard') stripeCard: any;
  stripe: any;
  card: any;
  invalidError: string | null = null;
  isPaymentSuccess = false;
  stripeKey = '';

  helper = new JwtHelperService();
  showItem = '';
  id = 0;
  myToken = this.tokenService.getToken();
  patientId: any;
  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private paymentMethodService: PaymentmethodService,
    private tokenService: TokenService,
    private stripeScriptTag: StripeScriptTag,
    @Inject(MAT_DIALOG_DATA)
    public data: { PatientId },
    private dialogRef: MatDialogRef<PaymentComponent>
  ) {
    this.paymentForm = this.fb.group({
      amount: ['', [Validators.required, Validators.min(1)]],
      description: ['', Validators.required],
    });
  }

  ngOnInit() {
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.id = parseInt(decodedToken.nameid);

    this.patientId = this.data.PatientId;
    // Retrieve the hospital's publishable key
    this.getPaymentMethod(this.id);
  }

  getPaymentMethod(id) {
    this.paymentMethodService
      .getStripeKeyByProviderId(id)
      .subscribe((response: any) => {
        this.stripeScriptTag.setPublishableKey(
          response.PaymentMethods[0].publishableKey
        );
      });
  }

  createStripeToken() {
    // Use the `stripe-card` directive to create a token
    if (this.stripeCard) {
      this.stripeCard.createToken().then((result: any) => {
        if (result.error) {
          this.invalidError = result.error.message;
          console.error('Error creating token:', result.error.message);
        } else {
          this.setStripeToken(result.token);
        }
      });
    } else {
      console.error('Stripe Card Element is not initialized');
    }
  }
  setStripeToken(token: any) {
    console.log('Stripe token received:', token);
    let amt = this.paymentForm.value['amount'];
    let description = this.paymentForm.value['description'];
    const body = {
      TransactionId: token.id,
      Amount: amt,
      PaymentType: 'Card Payment',
      Description: description,
      PatientId: this.patientId,
      HospitalId: this.id,
    };
    this.http.post(environment.Url + 'Payments', body).subscribe(
      (res: any) => {
        if (res.status === 'succeeded') {
          this.isPaymentSuccess = true;
          console.log('Payment successful');
        }
      },
      (err) => {
        console.error('Payment failed:', err);
      }
    );
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
