import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AuditLog, AuditLogService } from 'src/app/services/audit-log.service';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-audit-log',
  templateUrl: './audit-log.component.html',
  styleUrls: ['./audit-log.component.css'],
})
export class AuditLogComponent implements OnInit {
  displayedColumns: string[] = [
    'userName',
    'action',
    'controllerName',
    'methodName',
    'ipAddress',
    'timeStamp',
  ];
  dataSource = new MatTableDataSource<any>([]);
  totalRecords = 0;
  pageSize = 10;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(private auditLogService: AuditLogService) {}

  ngOnInit(): void {
    this.fetchLogs(0, this.pageSize);
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.paginator.page.subscribe((event) => {
      this.fetchLogs(event.pageIndex, event.pageSize);
    });
  }

  fetchLogs(pageIndex: number, pageSize: number): void {
    this.auditLogService
      .getAuditLogs(pageIndex + 1, pageSize)
      .subscribe((response) => {
        this.dataSource.data = response.data;
        this.totalRecords = response.totalRecords;
      });
  }
}
