<div class="dialog-header">
    <button mat-raised-button color="primary" class="close-button" (click)="onClose()">Close</button>
</div>
<div class="container" cdkDrag cdkDragRootElement=".cdk-overlay-pane">

    <div class="row">
        <div class="col-lg regFrm mat-elevation-z8">
            <form (keydown.enter)="$event.preventDefault()" [formGroup]="labFrm" (ngSubmit)="formSubmit()"
                class="example-form">
                <div class="row">
                    <div class="col-lg-3">

                        <mat-form-field class="example-full-width">
                            <mat-label>Report Type</mat-label>
                            <input type="text" formControlName="ReportType" matInput>
                            <mat-error>Please enter ReportType</mat-error>
                        </mat-form-field>

                    </div>

                    <div class="col-lg-3">

                        <mat-form-field class="example-full-width">
                            <mat-label>Date Of Report</mat-label>
                            <input type="date" formControlName="DateOfReport" matInput>
                            <mat-error>Please enter Date of report</mat-error>
                        </mat-form-field>

                    </div>
                    <div class="col-lg-3">

                        <mat-form-field class="example-full-width">
                            <mat-label>Date Drawn</mat-label>
                            <input type="date" formControlName="DateDrawn" matInput>
                            <mat-error>Please enter Date Drawn</mat-error>
                        </mat-form-field>

                    </div>
                    <div class="col-lg-3">

                        <mat-form-field class="example-full-width">
                            <mat-label>Date Received</mat-label>
                            <input type="date" formControlName="DateReceived" matInput>
                            <mat-error>Please enter Date Received</mat-error>
                        </mat-form-field>

                    </div>

                </div>

                <div class="row">
                    <div class="col-lg-4">

                        <mat-form-field class="example-full-width">
                            <mat-label>Ordered By Doctor</mat-label>


                            <input type="text" formControlName="OrderedBy" matInput>


                            <mat-error>Please select Name of DoctorName</mat-error>

                        </mat-form-field>

                    </div>
                    <div class="col-lg-4">

                        <mat-form-field class="example-full-width">
                            <mat-label>Name of Laboratory</mat-label>
                            <input type="text" formControlName="LabName" matInput>
                            <mat-error>Please enter Name of Laboratory</mat-error>
                        </mat-form-field>

                    </div>
                    <div class="col-lg-4">

                        <mat-form-field class="example-full-width">
                            <mat-label>Reported By</mat-label>
                            <input type="text" formControlName="ReportedBy" matInput>
                            <mat-error>Please enter Reported By</mat-error>
                        </mat-form-field>

                    </div>


                </div>

                <div class="row">
                    <div class="col-lg-12">


                        <mat-form-field appearance="fill" class="example-full-width">
                            <mat-label>Description </mat-label>

                            <textarea matInput formControlName="Description" cdkTextareaAutosize
                                #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="40"></textarea>
                            <mat-error *ngIf="Description.invalid && Description.touched">Please enter
                                Description's</mat-error>

                        </mat-form-field>
                    </div>


                </div>


                <div formArrayName="LabTests">
                    <div class="row" *ngFor="let item of LabTests.controls; let i = index" [formGroupName]="i">
                        <div class="col-lg-3">
                            <mat-form-field class="example-full-width">
                                <mat-label>Test Name</mat-label>
                                <mat-select formControlName="TestName"
                                    (selectionChange)="updateReferenceRanges(i, $event.value)">
                                    <mat-option *ngFor="let test of referenceRanges | keyvalue" [value]="test.key">
                                        {{ test.key }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="item.get('TestName').hasError('required')">Test Name is
                                    required</mat-error>
                            </mat-form-field>
                        </div>

                        <div class="col-lg-3">
                            <mat-form-field class="example-full-width">
                                <mat-label>Test Result Value</mat-label>
                                <input matInput formControlName="TestResultValue">
                                <mat-error *ngIf="item.get('TestResultValue').hasError('required')">
                                    Test Result Value is required
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="col-lg-3">
                            <mat-form-field class="example-full-width">
                                <mat-label>Reference Range</mat-label>
                                <mat-select formControlName="ReferanceRange">
                                    <mat-option *ngFor="let range of dynamicReferenceRanges[i]" [value]="range">
                                        {{ range }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="item.get('ReferanceRange').hasError('required')">
                                    Please select a Reference Range
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="col-lg-2">
                            <mat-form-field class="example-full-width">
                                <mat-label>Units</mat-label>
                                <input matInput formControlName="Units" readonly>
                                <mat-error *ngIf="item.get('Units').hasError('required')">Units are
                                    required</mat-error>
                            </mat-form-field>
                        </div>


                        <div class="col-lg-1">
                            <button type="button" mat-mini-fab color="warn" (click)="removeItem(i)">
                                <mat-icon>remove</mat-icon>
                            </button>
                        </div>
                    </div>

                    <button type="button" mat-mini-fab color="primary" (click)="addItem()">
                        <mat-icon>add</mat-icon>
                    </button>
                </div>


                <button type="submit" class="float-right lgBtCl" mat-button>
                    <mat-icon>save</mat-icon>
                </button>
                <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
                    Successfully submitted Lab Report
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </form>

        </div>
    </div>
</div>