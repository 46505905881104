import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PatientService } from 'src/app/services/patient.service';
import { Patient } from '../../Models/patient.model';
import { PatientVisit } from '../../Models/patientvisit.model';
import { PatientvisitService } from '../../services/patientvisit.service';

import { TokenService } from 'src/app/services/token.service';
import { FormControl } from '@angular/forms';
import html2pdf from 'html2pdf.js';

@Component({
  selector: 'app-patientdetails',
  templateUrl: './patientdetails.component.html',
  styleUrls: ['./patientdetails.component.css'],
})
export class PatientdetailsComponent implements OnInit {
  id = 0;
  patient: Patient;
  patientVisitList: PatientVisit[] = [];
  isAdmitted = false;
  filteredList;
  pvId = 0;
  PatientVisitId: PatientVisit[];
  showItem = '';
  show = true;
  isHide: boolean;
  email = new FormControl('');
  @ViewChild('PatientVisit') PatientVisit!: ElementRef;

  constructor(
    private patientService: PatientService,
    private route: ActivatedRoute,
    private router: Router,
    private patientVisitService: PatientvisitService,
    private cd: ChangeDetectorRef,
    private tokenService: TokenService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    this.patientService.getPatientvisits(this.id).subscribe((res) => {
      this.patient = res;
      this.email.setValue(res.Email);
      this.filteredList = res.PatientVisits.sort(function (x, y) {
        return y.PatientVisitId - x.PatientVisitId;
      });
      // this.patientVisitList = res.PatientVisits;
    });
  }

  printPage() {
    // window.print();
    document.execCommand('print');
  }
  search(searchValue) {
    // console.log(searchValue);
    if (searchValue.value != null) {
      this.filteredList = this.patientVisitList.filter((x) =>
        x.Date.toLowerCase().includes(searchValue.value.toLowerCase())
      );
    }
  }

  deleteVisit(id) {
    let C_confirm = confirm('Are you Sure you want to delete ?');
    if (C_confirm == true) {
      this.patientVisitService.deletePatientVisit(id).subscribe((res) => {
        this.filteredList = this.patientVisitList.filter(
          (x) => x.PatientVisitId != id
        );
      });
    }
  }

  itemsToShow = 2;

  // Function to load more items
  loadMore() {
    this.itemsToShow += 1; // Increase the number of items to show by 3
  }

  // Function to check if there are more items to load
  hasMoreItems() {
    return this.itemsToShow < this.filteredList.length;
  }
  downloadAsPDF() {
    const content = document.getElementById('printable');
    const button = document.querySelector('button'); // Select the button
    if (content) {
      button?.classList.add('downloadButton');
      // Configure html2pdf options
      const options = {
        margin: 1, // Margins in cm
        filename: 'medical-record.pdf', // Output file name
        image: { type: 'jpeg', quality: 0.98 }, // Image quality
        html2canvas: { scale: 2 }, // Scale for higher resolution
        jsPDF: { unit: 'cm', format: 'a4', orientation: 'portrait' }, // A4 portrait format
      };

      // Generate PDF
      html2pdf().set(options).from(content).save();
    }
  }
  send() {
    let body = {
      Email: this.email.value,
      Subject: 'Medical Records',
      Template: `${this.PatientVisit.nativeElement.innerHTML}`,
    };
    this.patientService.sendEmail(body).subscribe((res) => {
      alert('Medical Records sent');
    });
  }
}
