import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { EligibilityService } from 'src/app/services/eligibility.service';
import { DomSanitizer } from '@angular/platform-browser';
import { BillerService } from 'src/app/services/biller.service';
import { DoctorService } from 'src/app/services/doctor.service';
import { HospitalService } from 'src/app/services/hospital.service';
import { TokenService } from 'src/app/services/token.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpClient } from '@angular/common/http';
import { map, Observable, startWith } from 'rxjs';

@Component({
  selector: 'app-eligibility-check',
  templateUrl: './eligibility-check.component.html',
  styleUrls: ['./eligibility-check.component.css'],
  providers: [DatePipe],
})
export class EligibilityCheckComponent implements OnInit {
  eligibilityForm!: FormGroup;
  response: string | null = null;
  parsedData: any;
  parsedResponse: any = null;
  helper = new JwtHelperService();

  myToken = this.tokenService.getToken();
  filteredOptions: Observable<string[]>;

  showItem = '';
  hospitalId: number;

  id = 0;
  accountKey = '';
  payers: any[] = [];

  // payers = [
  //   { name: 'AARP', id: '36273' },
  //   { name: 'AK Medicaid', id: 'AKMCD' },
  //   { name: 'AK Medicare Part B', id: '00831' },
  //   { name: 'AK/OR/ID/WA Medicare Part A ', id: '02001' },
  //   { name: 'AL BCBS', id: '00510' },
  //   { name: 'Blue Cross Blue Shield', id: '00910' },
  //   { name: 'Aetna', id: '60054' },
  //   { name: 'Cigna', id: '62308' },
  //   { name: 'UnitedHealthcare', id: '87726' },
  // ];
  payerList: string;
  selectedPayer: any;
  constructor(
    private fb: FormBuilder,
    private eligibilityService: EligibilityService,
    private datePipe: DatePipe,
    private hospitalService: HospitalService,
    private tokenService: TokenService,
    private billerService: BillerService,
    private doctorService: DoctorService,
    private sanitizer: DomSanitizer,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    this.fetchPayers();
    const decodedToken = this.helper.decodeToken(this.myToken);
    this.id = parseInt(decodedToken.nameid);

    // Determine user role and fetch AccountKey
    if (decodedToken.role === 'Biller') {
      this.billerService.getbillerHospital(this.id).subscribe((res) => {
        this.hospitalId = res.Hospital.HospitalId;
        this.getCliamKey(this.hospitalId);
      });
    } else if (decodedToken.role === 'Hospital') {
      this.getCliamKey(this.id);
    } else if (decodedToken.role === 'Doctor') {
      this.doctorService.getDoctorHospital(this.id).subscribe((res) => {
        this.hospitalId = res.Hospital.HospitalId;
        this.getCliamKey(this.hospitalId);
      });
    } else {
      console.error('Invalid role detected in token:', decodedToken.role);
    }

    // Initialize the form
    this.initializeForm();
    this.filteredOptions = this.eligibilityForm.controls[
      'payer_name'
    ].valueChanges.pipe(
      startWith(''),
      map((value: string) => this._filter(value || ''))
    );
  }

  initializeForm(): void {
    this.eligibilityForm = this.fb.group({
      AccountKey: ['', Validators.required], // To be dynamically updated
      ins_name_l: ['', Validators.required],
      ins_name_f: ['', Validators.required],
      ins_dob: ['', Validators.required],
      payer_name: ['', Validators.required],
      payerid: ['', Validators.required],
      pat_rel: ['18', Validators.required], // Default to 'Self'
      fdos: ['', Validators.required],
      prov_npi: ['', Validators.required],
      prov_taxid: ['', Validators.required],
      ins_number: [''], // Optional
    });
  }

  getCliamKey(id: number): void {
    this.hospitalService.getclaimAccountKey(id).subscribe(
      (res) => {
        if (res.ClaimAccountKeys && res.ClaimAccountKeys.length > 0) {
          this.accountKey = res.ClaimAccountKeys[0].AccountKey;
          this.eligibilityForm.patchValue({ AccountKey: this.accountKey }); // Update form dynamically
        } else {
          console.error(
            'No ClaimAccountKeys found for the provided hospital ID.'
          );
        }
      },
      (error) => {
        console.error('Error fetching ClaimAccountKey:', error);
      }
    );
  }

  fetchPayers(): void {
    this.http.get('/assets/json/payer-list.json').subscribe({
      next: (res: any) => {
        this.payers = res; // Keep the original object structure for claim_form logic
      },
      error: (err) => {
        console.error('Error fetching payer list:', err);
      },
    });
  }
  onPayerChange(event: any): void {
    const selectedPayerName = event.option.value;
    this.selectedPayer =
      this.payers.find((payer) => payer.payer_name === selectedPayerName) ||
      null;

    // Update payerid in the form
    if (this.selectedPayer) {
      this.eligibilityForm.patchValue({
        payerid: this.selectedPayer.payerid,
      });
    }
  }
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.payers
      .map((payer) => payer.payer_name)
      .filter((name) => name.toLowerCase().includes(filterValue));
  }
  checkEligibility(): void {
    if (this.eligibilityForm.valid) {
      const formData = { ...this.eligibilityForm.value };

      // Format the date before sending
      // formData.fdos = this.datePipe.transform(
      //   formData.fdos,
      //   'yyyy-MM-dd'
      // ) as string;
      // formData.ins_dob = this.datePipe.transform(
      //   formData.ins_dob,
      //   'yyyy-MM-dd'
      // ) as string;

      this.eligibilityService.checkEligibility(formData).subscribe(
        (response) => {
          this.response = response;
          this.parsedResponse = this.parseXml(response);
        },
        (error) => {
          console.error('Error:', error);
          this.response = 'Error occurred while checking eligibility.';
        }
      );
    } else {
      this.response = 'Please fill all required fields correctly.';
    }
  }

  parseXml(xmlString: string): any {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlString, 'application/xml');

    // Extract eligibility details
    const elig = xmlDoc.querySelector('elig');
    const benefits = Array.from(xmlDoc.querySelectorAll('benefit')).map(
      (benefit) => ({
        benefit_code: benefit.getAttribute('benefit_code'),
        benefit_coverage_description: benefit.getAttribute(
          'benefit_coverage_description'
        ),
        benefit_description: benefit.getAttribute('benefit_description'),
        benefit_amount: benefit.getAttribute('benefit_amount'),
        benefit_level_description: benefit.getAttribute(
          'benefit_level_description'
        ),
        inplan_network: benefit.getAttribute('inplan_network'),
        benefit_notes: benefit.getAttribute('benefit_notes'),
      })
    );

    return {
      elig: {
        elig_result_date: elig?.getAttribute('elig_result_date'),
        group_number: elig?.getAttribute('group_number'),
        plan_number: elig?.getAttribute('plan_number'),
        ins_name_f: elig?.getAttribute('ins_name_f'),
        ins_name_l: elig?.getAttribute('ins_name_l'),
        ins_dob: elig?.getAttribute('ins_dob'),
        benefits,
      },
    };
  }
}
