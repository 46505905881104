import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Doctor } from 'src/app/Models/doctor.model';
import { PatientBooking } from 'src/app/Models/patientbooking.model';
import { AppointmentService } from 'src/app/services/appointment.service';
import { BookedslotsService } from 'src/app/services/bookedslots.service';
import { DoctorService } from 'src/app/services/doctor.service';
import { HospitalService } from 'src/app/services/hospital.service';
import { PatientService } from 'src/app/services/patient.service';

import { TokenService } from 'src/app/services/token.service';
import { UpdateAppointmentComponent } from '../update-appointment/update-appointment.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-view-all-appointments',
  templateUrl: './view-all-appointments.component.html',
  styleUrls: ['./view-all-appointments.component.css'],
})
export class ViewAllAppointmentsComponent implements OnInit {
  displayedColumns: string[] = [
    'PatientId',
    'DoctorName',
    'DoctorSpecialization',
    'VisitDate',
    'VisitTime',
    'Message',
    'Status',
    'checkin',
    'Detail',
    'Delete',
  ];

  doctor: Doctor[] = [];
  doctorList: Doctor[] = [];

  id = 0;
  ptAppointments: PatientBooking[] = [];

  filteredList;
  dataSource: MatTableDataSource<any>;
  helper = new JwtHelperService();

  myToken = this.tokenService.getToken();
  Frm: FormGroup;
  constructor(
    private doctorService: DoctorService,
    private route: ActivatedRoute,
    private router: Router,
    private appointmentService: AppointmentService,
    private hospitalService: HospitalService,
    private fb: FormBuilder,
    private tokenService: TokenService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.id = parseInt(decodedToken.nameid);

    // this.route.params.subscribe(res=>{
    //   this.id= res['id'];
    // })

    this.getDoctor(this.id);
    this.Frm = this.fb.group({
      DoctorId: [''],
    });

    this.getAppt();
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  get DoctorId() {
    return this.Frm.get('DoctorId');
  }

  getDoctor(id) {
    this.hospitalService.getDoctors(id).subscribe((res) => {
      this.doctor = res.Doctors;
    });
  }
  getAppt() {
    this.doctorService
      .getDoctorBooking(this.DoctorId.value)
      .subscribe((res) => {
        this.dataSource = new MatTableDataSource(res.PatientBookings);
        this.dataSource.paginator = this.paginator;
        this.filteredList = res.PatientBookings;

        this.ptAppointments = res.PatientBookings;
      });
  }

  search(searchValue) {
    // console.log(searchValue);
    if (searchValue.value != null) {
      this.filteredList = this.ptAppointments.filter((x) =>
        x.VisitDate.toLowerCase().includes(searchValue.value.toLowerCase())
      );
    }
  }
  delete(id) {
    this.appointmentService.deletePatientBooking(id).subscribe((res) => {
      this.getAppt();
    });
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  update(PatientBookingId: number): void {
    const dialogRef = this.dialog.open(UpdateAppointmentComponent, {
      width: '50%',
      height: '80%',
      disableClose: true, // Prevent closing without confirmation
      hasBackdrop: false, // Disable the backdrop
      panelClass: 'custom-dialog-container', // Optional custom styling
      data: { PatientBookingId }, // Pass data to the dialog
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('Dialog closed:', result);
      // Handle any cleanup or actions after the dialog is closed
    });
  }
}
