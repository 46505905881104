<mat-tab-group (selectedTabChange)="tabChanged($event)">

    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">person</mat-icon>
            Staff
        </ng-template>

        <div class="container mt-3">

            <div class="row">
                <div class="col-lg-6 "><span class="float-right"></span></div>
                <div class="col-lg-6 ">
                    <mat-form-field appearance="fill" class="example-full-width">
                        <mat-label>Search by Name</mat-label>
                        <mat-icon matPrefix>search</mat-icon>
                        <input type="text" #searchValue (keyup)="search(searchValue)" matInput>

                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-4" *ngFor="let item of filteredList">
                    <div class="card" style="width: 18rem;">
                        <ul class="list-group list-group-flush">
                            <img src="{{item.Image}}" class="doctor-image" alt="">
                            <li class="list-group-item divBg text-white"><span
                                    style="font-size: large;">{{item.FullName}} </span></li>
                            <li class="list-group-item">D.O.B: {{item.Dob}}</li>
                            <li class="list-group-item">NationalId: {{item.NationalId}}</li>
                            <li class="list-group-item">Phone: {{item.PhoneNumber}}</li>
                            <li class="list-group-item">Email: {{item.Email}}</li>
                            <li class="list-group-item">StartDate: {{item.StartDate}}</li>
                            <li class="list-group-item">EndDate: {{item.EndDate}}</li>
                            <li class="list-group-item">Status: {{item.Status? 'Active' : 'Inactive'}}</li>
                            <li class="list-group-item"> Address: {{item.Address}}</li>
                        </ul>

                    </div>
                    <button type="button" [routerLink]="['/updateFrontDeskStaff/',item.FrontDeskStaffId]" mat-mini-fab
                        color="primary">
                        <mat-icon matPrefix>edit</mat-icon>
                    </button>
                    <button mat-mini-fab (click)="delete(item.FrontDeskStaffId)"> <mat-icon>delete</mat-icon> </button>
                </div>

                <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>



            </div>
        </div>
    </mat-tab>


    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">add</mat-icon>
            Register new Staff
        </ng-template>


        <app-add-front-desk-staff></app-add-front-desk-staff>
    </mat-tab>


</mat-tab-group>