<div>
    <div>
        <app-tab1-biller [tabsArray]="tabs" (onTabChange)="tabChange($event)"></app-tab1-biller>
    </div>
    <div *ngIf="activatedTabIndex ==0">

        <app-doctor-patient-visit></app-doctor-patient-visit>

    </div>

    <div *ngIf="activatedTabIndex ==1">

        <app-view-doctor-report></app-view-doctor-report>

    </div>

    <div *ngIf="activatedTabIndex ==2">

        <app-view-labreport></app-view-labreport>

    </div>
    <div *ngIf="activatedTabIndex ==3">
        <!-- <app-view-radiology-report></app-view-radiology-report> -->
        <app-view-radiology-report-peach-recogintion-enabled></app-view-radiology-report-peach-recogintion-enabled>


    </div>
    <div *ngIf="activatedTabIndex ==4">

        <app-view-orders></app-view-orders>

    </div>
    <div *ngIf="activatedTabIndex ==5">
        <app-all-test-results></app-all-test-results>



    </div>
    <div *ngIf="activatedTabIndex ==6">
        <app-view-patient-pharmacy></app-view-patient-pharmacy>



    </div>




    <div *ngIf="activatedTabIndex ==7">

        <app-view-invoice></app-view-invoice>



    </div>
    <div *ngIf="activatedTabIndex ==8">

        <app-claim-status></app-claim-status>

    </div>
    <div *ngIf="activatedTabIndex ==9">

        <app-era-list></app-era-list>

    </div>
    <div *ngIf="activatedTabIndex ==10 ">

        <app-claim-notes></app-claim-notes>

    </div>




</div>