<div class="container">
    <h2>Artifical Intelligence Assistance (Ask Any Medical related Questions)</h2>
    <div id="editor" style="height: 200px; border: 1px solid #ccc;"></div>
    <div>
        <button type="submit" color="primary" class="float-right" mat-raised-button (click)="generate()">Submit
            Request</button>
        <button type="button" class="btn btn-success" mat-raised-button (click)="startSpeechRecognition()">Start Speech
            Recognition</button>
        <button type="button" color="accent" mat-raised-button (click)="stopRecognition()">Stop</button>
    </div>
    <div *ngIf="response">
        <h3>Generated Information:</h3>
        <pre>{{ response }}</pre>
    </div>
</div>