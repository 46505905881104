import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';

@Component({
  selector: 'app-cms1500',
  templateUrl: './cms1500.component.html',
  styleUrls: ['./cms1500.component.css'],
})
export class Cms1500Component implements OnInit {
  cms1500Form: FormGroup;

  constructor(private fb: FormBuilder) {
    this.cms1500Form = this.fb.group({
      // Payer Details
      payerName: ['', Validators.required],
      payerID: ['', Validators.required],
      payerAddress1: ['', Validators.required],
      payerAddress2: [''],
      payerCity: ['', Validators.required],
      payerState: ['', Validators.required],
      payerZip: ['', Validators.required],

      // Patient Details
      insuredID: ['', Validators.required],
      patientName: ['', Validators.required],
      patientDOB: ['', Validators.required],
      patientSex: ['', Validators.required],
      patientAddress: ['', Validators.required],
      patientRelationship: ['', Validators.required],

      // Additional Details
      maritalStatus: [''],
      employmentStatus: [''],
      secondaryInsuredName: [''],
      secondaryInsuredPolicy: [''],
      secondaryInsuredDOB: [''],
      secondaryInsuredRelationship: [''],
      secondaryPayerGroupName: [''],
      secondaryPayerGroupNumber: [''],

      // Diagnosis Codes
      diagnosisCodes: this.fb.array([this.createDiagnosisCodeGroup()]),

      // Service Lines (Section 24)
      serviceLines: this.fb.array([this.createServiceLineGroup()]),

      // Financial Information
      taxID: ['', Validators.required],
      patientAccountNumber: ['', Validators.required],
      totalCharge: ['', Validators.required],
      amountPaid: [''],
      balance: [''],
      acceptAssignment: [false],

      // Rendering Provider (Section 31)
      renderingProviderName: ['', Validators.required],
      renderingProviderNPI: ['', Validators.required],

      // Facility Details (Section 32)
      facilityName: ['', Validators.required],
      facilityAddress1: ['', Validators.required],
      facilityCity: ['', Validators.required],
      facilityState: ['', Validators.required],
      facilityZip: ['', Validators.required],

      // Billing Provider (Section 33)
      billingProviderName: ['', Validators.required],
      billingProviderAddress1: ['', Validators.required],
      billingProviderCity: ['', Validators.required],
      billingProviderState: ['', Validators.required],
      billingProviderZip: ['', Validators.required],
      billingProviderPhone: ['', Validators.required],
      billingProviderNPI: ['', Validators.required],
      billingProviderID: [''],
    });
  }

  ngOnInit(): void {}

  // Create Diagnosis Code Form Group
  createDiagnosisCodeGroup(): FormGroup {
    return this.fb.group({
      code: ['', Validators.required],
      description: [''],
    });
  }

  // Create Service Line Form Group
  createServiceLineGroup(): FormGroup {
    return this.fb.group({
      dateOfServiceFrom: ['', Validators.required],
      dateOfServiceTo: [''],
      placeOfService: ['', Validators.required],
      emergency: [false],
      procedureCode: ['', Validators.required],
      modifier: [''],
      diagnosisReference: [''],
      charges: ['', Validators.required],
      units: ['', Validators.required],
    });
  }

  // Getters for FormArrays
  get diagnosisCodes(): FormArray {
    return this.cms1500Form.get('diagnosisCodes') as FormArray;
  }

  get serviceLines(): FormArray {
    return this.cms1500Form.get('serviceLines') as FormArray;
  }

  // Add/Remove Methods
  addDiagnosisCode(): void {
    this.diagnosisCodes.push(this.createDiagnosisCodeGroup());
  }

  removeDiagnosisCode(index: number): void {
    this.diagnosisCodes.removeAt(index);
  }

  addServiceLine(): void {
    this.serviceLines.push(this.createServiceLineGroup());
  }

  removeServiceLine(index: number): void {
    this.serviceLines.removeAt(index);
  }

  onSubmit(): void {
    if (this.cms1500Form.valid) {
      console.log('Form Submitted:', this.cms1500Form.value);
    } else {
      console.log('Form Invalid');
    }
  }
}
