<div class="container mx-5 my-5">
    <button class="btn backG float-right mr-3" onClick="print()" value="print"><mat-icon>print</mat-icon></button>

    <div class="row">
        <!-- Loop over the evaluation list -->
        <div class="col-md-12" *ngFor="let item of ReviewOfSystemsList">
            <button type="button" [disabled]="CurrentDoctorId != item?.DoctorId" color="primary" mat-mini-fab
                (click)="update(item?.ReviewOfSystemId)"> <mat-icon matPrefix>edit</mat-icon></button>
            <!-- Edit and Delete Buttons -->
            <!-- <button class="btn btn-primary float-right" mat-mini-fab color="primary"
                [routerLink]="['/update-review-of-system/', item?.ReviewOfSystemId]"
                [disabled]="CurrentDoctorId != item?.DoctorId">
                <mat-icon>edit</mat-icon>
            </button> -->

            <button class="btn btn-danger ml-3" mat-mini-fab (click)="delete(item?.ReviewOfSystemId)"
                [disabled]="CurrentDoctorId != item?.DoctorId">
                <mat-icon>delete</mat-icon>
            </button>

            <!-- Card for Doctor Evaluation -->
            <mat-card class="mat-elevation-z8 mt-3" id="printable">
                <mat-card-content>
                    <h2 class="clr">Review of Systems</h2>
                    <p>{{item?.DoctorName}}</p>
                    <p class="float-right">Date: {{item?.CreatedDate | date: 'short'}}</p>

                    <hr>
                    <div class="container">



                        <div class="row">
                            <!-- Constitutional Section -->
                            <div class="col-md-12">
                                <h3>1. Constitutional</h3>
                                <table class="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <th>Chills</th>
                                            <td>{{ item?.Chills ? 'Yes' : 'No' }}</td>
                                        </tr>
                                        <tr>
                                            <th>Fatigue</th>
                                            <td>{{ item?.Fatigue ? 'Yes' : 'No' }}</td>
                                        </tr>
                                        <tr>
                                            <th>Fever</th>
                                            <td>{{ item?.Fever ? 'Yes' : 'No' }}</td>
                                        </tr>
                                        <tr>
                                            <th>Weight Gain</th>
                                            <td>{{ item?.WeightGain ? 'Yes' : 'No' }}</td>
                                        </tr>
                                        <tr>
                                            <th>Weight Loss</th>
                                            <td>{{ item?.WeightLoss ? 'Yes' : 'No' }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <!-- HEENT Section -->
                            <div class="col-md-12">
                                <h3>2. HEENT</h3>
                                <table class="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <th>Hearing Loss</th>
                                            <td>{{ item?.HearingLoss ? 'Yes' : 'No' }}</td>
                                        </tr>
                                        <tr>
                                            <th>Sinus Pressure</th>
                                            <td>{{ item?.SinusPressure ? 'Yes' : 'No' }}</td>
                                        </tr>
                                        <tr>
                                            <th>Visual Changes</th>
                                            <td>{{ item?.VisualChanges ? 'Yes' : 'No' }}</td>
                                        </tr>
                                        <tr>
                                            <th>Sore Throat</th>
                                            <td>{{ item?.SoreThroat ? 'Yes' : 'No' }}</td>
                                        </tr>
                                        <tr>
                                            <th>Nasal Congestion</th>
                                            <td>{{ item?.NasalCongestion ? 'Yes' : 'No' }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <!-- Respiratory Section -->
                            <div class="col-md-12">
                                <h3>3. Respiratory</h3>
                                <table class="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <th>Cough</th>
                                            <td>{{ item?.Cough ? 'Yes' : 'No' }}</td>
                                        </tr>
                                        <tr>
                                            <th>Shortness of Breath</th>
                                            <td>{{ item?.ShortnessOfBreath ? 'Yes' : 'No' }}</td>
                                        </tr>
                                        <tr>
                                            <th>Wheezing</th>
                                            <td>{{ item?.Wheezing ? 'Yes' : 'No' }}</td>
                                        </tr>
                                        <tr>
                                            <th>Hemoptysis</th>
                                            <td>{{ item?.Hemoptysis ? 'Yes' : 'No' }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <!-- Cardiovascular Section -->
                            <div class="col-md-12">
                                <h3>4. Cardiovascular</h3>
                                <table class="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <th>Chest Pain</th>
                                            <td>{{ item?.ChestPain ? 'Yes' : 'No' }}</td>
                                        </tr>
                                        <tr>
                                            <th>Pain while walking (Claudication)</th>
                                            <td>{{ item?.Claudication ? 'Yes' : 'No' }}</td>
                                        </tr>
                                        <tr>
                                            <th>Edema</th>
                                            <td>{{ item?.Edema ? 'Yes' : 'No' }}</td>
                                        </tr>
                                        <tr>
                                            <th>Palpitations</th>
                                            <td>{{ item?.Palpitations ? 'Yes' : 'No' }}</td>
                                        </tr>
                                        <tr>
                                            <th>Orthopnea</th>
                                            <td>{{ item?.Orthopnea ? 'Yes' : 'No' }}</td>
                                        </tr>
                                        <tr>
                                            <th>Paroxysmal Nocturnal Dyspnea</th>
                                            <td>{{ item?.ParoxysmalNocturnalDyspnea ? 'Yes' : 'No'
                                                }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <!-- Gastrointestinal Section -->
                            <div class="col-md-12">
                                <h3>5. Gastrointestinal</h3>
                                <table class="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <th>Abdominal Pain</th>
                                            <td>{{ item?.AbdominalPain ? 'Yes' : 'No' }}</td>
                                        </tr>
                                        <tr>
                                            <th>Blood in Stool</th>
                                            <td>{{ item?.BloodInStool ? 'Yes' : 'No' }}</td>
                                        </tr>
                                        <tr>
                                            <th>Constipation</th>
                                            <td>{{ item?.Constipation ? 'Yes' : 'No' }}</td>
                                        </tr>
                                        <tr>
                                            <th>Diarrhea</th>
                                            <td>{{ item?.Diarrhea ? 'Yes' : 'No' }}</td>
                                        </tr>
                                        <tr>
                                            <th>Heartburn</th>
                                            <td>{{ item?.Heartburn ? 'Yes' : 'No' }}</td>
                                        </tr>
                                        <tr>
                                            <th>Loss of Appetite</th>
                                            <td>{{ item?.LossOfAppetite ? 'Yes' : 'No' }}</td>
                                        </tr>
                                        <tr>
                                            <th>Nausea</th>
                                            <td>{{ item?.Nausea ? 'Yes' : 'No' }}</td>
                                        </tr>
                                        <tr>
                                            <th>Vomiting</th>
                                            <td>{{ item?.Vomiting ? 'Yes' : 'No' }}</td>
                                        </tr>
                                        <tr>
                                            <th>Jaundice</th>
                                            <td>{{ item?.Jaundice ? 'Yes' : 'No' }}</td>
                                        </tr>
                                        <tr>
                                            <th>Black Stools</th>
                                            <td>{{ item?.BlackStools ? 'Yes' : 'No' }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <!-- Genitourinary Section -->
                            <div class="col-md-12">
                                <h3>6. Genitourinary</h3>
                                <table class="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <th>Painful Urination (Dysuria)</th>
                                            <td>{{ item?.Dysuria ? 'Yes' : 'No' }}</td>
                                        </tr>
                                        <tr>
                                            <th>Excessive Urine (Polyuria)</th>
                                            <td>{{ item?.Polyuria ? 'Yes' : 'No' }}</td>
                                        </tr>
                                        <tr>
                                            <th>Urinary Frequency</th>
                                            <td>{{ item?.UrinaryFrequency ? 'Yes' : 'No' }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <!-- Metabolic/Endocrine Section -->
                            <div class="col-md-12">
                                <h3>7. Metabolic/Endocrine</h3>
                                <table class="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <th>Cold Intolerance</th>
                                            <td>{{ item?.ColdIntolerance ? 'Yes' : 'No' }}</td>
                                        </tr>
                                        <tr>
                                            <th>Heat Intolerance</th>
                                            <td>{{ item?.HeatIntolerance ? 'Yes' : 'No' }}</td>
                                        </tr>
                                        <tr>
                                            <th>Excessive Thirst (Polydipsia)</th>
                                            <td>{{ item?.Polydipsia ? 'Yes' : 'No' }}</td>
                                        </tr>
                                        <tr>
                                            <th>Excessive Hunger (Polyphagia)</th>
                                            <td>{{ item?.Polyphagia ? 'Yes' : 'No' }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <!-- Neurological Section -->
                            <div class="col-md-12">
                                <h3>8. Neurological</h3>
                                <table class="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <th>Dizziness</th>
                                            <td>{{ item?.Dizziness ? 'Yes' : 'No' }}</td>
                                        </tr>
                                        <tr>
                                            <th>Extremity Numbness</th>
                                            <td>{{ item?.ExtremityNumbness ? 'Yes' : 'No' }}</td>
                                        </tr>
                                        <tr>
                                            <th>Extremity Weakness</th>
                                            <td>{{ item?.ExtremityWeakness ? 'Yes' : 'No' }}</td>
                                        </tr>
                                        <tr>
                                            <th>Headaches</th>
                                            <td>{{ item?.Headaches ? 'Yes' : 'No' }}</td>
                                        </tr>
                                        <tr>
                                            <th>Seizures</th>
                                            <td>{{ item?.Seizures ? 'Yes' : 'No' }}</td>
                                        </tr>
                                        <tr>
                                            <th>Tremors</th>
                                            <td>{{ item?.Tremors ? 'Yes' : 'No' }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <!-- Psychiatric Section -->
                            <div class="col-md-12">
                                <h3>9. Psychiatric</h3>
                                <table class="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <th>Anxiety</th>
                                            <td>{{ item?.Anxiety ? 'Yes' : 'No' }}</td>
                                        </tr>
                                        <tr>
                                            <th>Depression</th>
                                            <td>{{ item?.Depression ? 'Yes' : 'No' }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <!-- Integumentary Section -->
                            <div class="col-md-12">
                                <h3>10. Integumentary</h3>
                                <table class="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <th>Breast Discharge</th>
                                            <td>{{ item?.BreastDischarge ? 'Yes' : 'No' }}</td>
                                        </tr>
                                        <tr>
                                            <th>Breast Lump</th>
                                            <td>{{ item?.BreastLump ? 'Yes' : 'No' }}</td>
                                        </tr>
                                        <tr>
                                            <th>Hives</th>
                                            <td>{{ item?.Hives ? 'Yes' : 'No' }}</td>
                                        </tr>
                                        <tr>
                                            <th>Mole Changes</th>
                                            <td>{{ item?.MoleChanges ? 'Yes' : 'No' }}</td>
                                        </tr>
                                        <tr>
                                            <th>Rash</th>
                                            <td>{{ item?.Rash ? 'Yes' : 'No' }}</td>
                                        </tr>
                                        <tr>
                                            <th>Skin Lesions</th>
                                            <td>{{ item?.SkinLesion ? 'Yes' : 'No' }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <!-- Musculoskeletal Section -->
                            <div class="col-md-12">
                                <h3>11. Musculoskeletal</h3>
                                <table class="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <th>Back Pain</th>
                                            <td>{{ item?.BackPain ? 'Yes' : 'No' }}</td>
                                        </tr>
                                        <tr>
                                            <th>Joint Pain</th>
                                            <td>{{ item?.JointPain ? 'Yes' : 'No' }}</td>
                                        </tr>
                                        <tr>
                                            <th>Joint Swelling</th>
                                            <td>{{ item?.JointSwelling ? 'Yes' : 'No' }}</td>
                                        </tr>
                                        <tr>
                                            <th>Neck Pain</th>
                                            <td>{{ item?.NeckPain ? 'Yes' : 'No' }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <!-- Hematologic Section -->
                            <div class="col-md-12">
                                <h3>12. Hematologic</h3>
                                <table class="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <th>Easily Bleeds</th>
                                            <td>{{ item?.EasilyBleeds ? 'Yes' : 'No' }}</td>
                                        </tr>
                                        <tr>
                                            <th>Easily Bruises</th>
                                            <td>{{ item?.HasilyBruises ? 'Yes' : 'No' }}</td>
                                        </tr>
                                        <tr>
                                            <th>Lymphedema</th>
                                            <td>{{ item?.Lymphedema ? 'Yes' : 'No' }}</td>
                                        </tr>
                                        <tr>
                                            <th>Blood Clots</th>
                                            <td>{{ item?.BloodClots ? 'Yes' : 'No' }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <!-- Immunologic Section -->
                            <div class="col-md-12">
                                <h3>13. Immunologic</h3>
                                <table class="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <th>Food Allergies</th>
                                            <td>{{ item?.FoodAllergies ? 'Yes' : 'No' }}</td>
                                        </tr>
                                        <tr>
                                            <th>Seasonal Allergies</th>
                                            <td>{{ item?.SeasonalAllergies ? 'Yes' : 'No' }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <!-- For 'None Apply' -->
                            <div class="col-md-12">
                                <h3>None Apply</h3>
                                <p>{{ item?.NoneApply ? 'Yes' : 'No' }}</p>
                            </div>
                        </div>
                    </div>



                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>