<div class="container">
  <div class="row">
    <div class="col-lg regFrm mat-elevation-z8">
      <h2>New Doctor</h2>

      <!-- Doctor Form -->
      <form (keydown.enter)="$event.preventDefault()" [formGroup]="doctorFrm" (ngSubmit)="formSubmit()"
        class="example-form">

        <!-- Department Selection and Picture Upload -->
        <div class="row">
          <div class="col-lg-4">
            <mat-form-field appearance="fill" class="example-full-width">
              <mat-label>Select Department</mat-label>
              <mat-select formControlName="DepartmentId">
                <mat-option *ngFor="let dpt of dptList" [value]="dpt.DepartmentId">
                  {{ dpt.DepartmentName }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="doctorFrm.get('DepartmentId').invalid">Please select a department</mat-error>
            </mat-form-field>
          </div>
          <div class="col-lg-4">
            <label>Upload Picture</label>
            <input type="file" (change)="image($event)" class="form-control" />
          </div>
        </div>

        <!-- Doctor Information -->
        <div class="row">
          <div class="col-lg-4">
            <mat-form-field class="example-full-width">
              <mat-label>Name of Doctor</mat-label>
              <input type="text" formControlName="DoctorName" matInput />
              <mat-error *ngIf="doctorFrm.get('DoctorName').invalid">Please enter the doctor's name</mat-error>
            </mat-form-field>
          </div>
          <div class="col-lg-4">
            <mat-form-field class="example-full-width">
              <mat-label>Doctor's Qualification</mat-label>
              <input type="text" formControlName="DoctorQualification" matInput />
              <mat-error *ngIf="doctorFrm.get('DoctorQualification').invalid">Please enter qualification</mat-error>
            </mat-form-field>
          </div>
          <div class="col-lg-4">
            <mat-form-field class="example-full-width">
              <mat-label>Specialization</mat-label>
              <mat-select formControlName="DoctorSpecialization">
                <mat-option *ngFor="let sp of doctorSpeciality" [value]="sp">{{ sp }}</mat-option>
              </mat-select>
              <mat-error *ngIf="doctorFrm.get('DoctorSpecialization').invalid">Please select a
                specialization</mat-error>
            </mat-form-field>
          </div>
        </div>

        <!-- Licensing Information -->
        <div class="row">
          <div class="col-lg-4">
            <mat-form-field class="example-full-width">
              <mat-label>License Number</mat-label>
              <input type="text" formControlName="LicenceNumber" matInput />
              <mat-error *ngIf="doctorFrm.get('LicenceNumber').invalid">Please enter a license number</mat-error>
            </mat-form-field>
          </div>
          <div class="col-lg-4">
            <mat-form-field class="example-full-width">
              <mat-label>NPI Number</mat-label>
              <input type="text" formControlName="NpiNumber" matInput />
              <mat-error *ngIf="doctorFrm.get('NpiNumber').invalid">Please enter an NPI number</mat-error>
            </mat-form-field>
          </div>
          <div class="col-lg-4">
            <mat-form-field class="example-full-width">
              <mat-label>Tax Id</mat-label>
              <input type="text" formControlName="TaxId" matInput placeholder="Enter Tax ID" />
              <mat-error *ngIf="doctorFrm.get('TaxId')?.hasError('required')">Please enter Tax Id</mat-error>
            </mat-form-field>
          </div>
        </div>

        <!-- Contact Information -->
        <div class="row">
          <div class="col-lg-4">
            <mat-form-field class="example-full-width">
              <mat-label>Doctor's D.O.B</mat-label>
              <input type="date" formControlName="DoctorDOB" matInput />
              <mat-error *ngIf="doctorFrm.get('DoctorDOB').invalid">Please enter the doctor's D.O.B</mat-error>
            </mat-form-field>
          </div>
          <div class="col-lg-4">
            <mat-form-field class="example-full-width">
              <mat-label>Email</mat-label>
              <input type="email" formControlName="Email" matInput />
              <mat-error *ngIf="doctorFrm.get('Email').invalid">Please enter a valid email</mat-error>
            </mat-form-field>
          </div>
          <div class="col-lg-4">
            <mat-form-field class="example-full-width">
              <mat-label>Phone Number</mat-label>
              <input type="number" formControlName="PhoneNumber" matInput />
              <mat-error *ngIf="doctorFrm.get('PhoneNumber').invalid">Please enter a valid phone number</mat-error>
            </mat-form-field>
          </div>
        </div>

        <!-- Address and Other Details -->
        <div class="row">
          <div class="col-lg-6">
            <mat-form-field class="example-full-width">
              <mat-label>Start Date</mat-label>
              <input type="date" formControlName="StartDate" matInput />
              <mat-error *ngIf="doctorFrm.get('StartDate').invalid">Please enter a start date</mat-error>
            </mat-form-field>
          </div>
          <div class="col-lg-6">
            <mat-form-field class="example-full-width">
              <mat-label>Address</mat-label>
              <input type="text" formControlName="DoctorAddress" matInput />
              <mat-error *ngIf="doctorFrm.get('DoctorAddress').invalid">Please enter the doctor's address</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">

          <div class="col-lg-3">
            <mat-form-field class="example-full-width">
              <mat-label>State</mat-label>
              <input type="text" formControlName="State" matInput />
              <mat-error *ngIf="doctorFrm.get('State').invalid">Please enter State</mat-error>
            </mat-form-field>
          </div>
          <div class="col-lg-3">
            <mat-form-field class="example-full-width">
              <mat-label>City</mat-label>
              <input type="text" formControlName="City" matInput />
              <mat-error *ngIf="doctorFrm.get('City').invalid">Please enter City</mat-error>
            </mat-form-field>
          </div>
          <div class="col-lg-3">
            <mat-form-field class="example-full-width">
              <mat-label>Zip</mat-label>
              <input type="text" formControlName="Zip" matInput />
              <mat-error *ngIf="doctorFrm.get('Zip').invalid">Please enter Zip</mat-error>
            </mat-form-field>
          </div>
        </div>

        <!-- Password -->
        <div class="row">
          <div class="col-lg-6">
            <mat-form-field class="example-full-width">
              <mat-label>Password</mat-label>
              <input type="password" formControlName="Password" matInput />
              <mat-error *ngIf="doctorFrm.get('Password').hasError('required')">Please enter a password</mat-error>
              <mat-error *ngIf="doctorFrm.get('Password').hasError('pattern')">
                Password must be at least 8 characters and include 1 uppercase letter, 1 number, and 1 special
                character.
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-lg-6">
            <p>Status</p>
            <mat-radio-group formControlName="Status">
              <mat-radio-button value="true">Active</mat-radio-button>
              <mat-radio-button value="false">Inactive</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <!-- Camera Capture -->
        <div class="row mt-4">
          <h4>Capture Image from Camera</h4>
          <div class="col-md-6">
            <webcam [trigger]="invokeObservable" (imageCapture)="captureImg($event)" [height]="180" [width]="180">
            </webcam>
          </div>
          <div class="col-md-6 text-center">
            <button type="button" class="btn btn-danger" (click)="getSnapshot()">Capture Image</button>
            <img [src]="webcamImage?.imageAsDataUrl" class="mt-3" height="180px" />
          </div>
        </div>

        <!-- Submit Button -->
        <div class="text-right mt-3">
          <button mat-button color="primary" [disabled]="!doctorFrm.valid">
            <mat-icon>save</mat-icon>
            Save
          </button>
        </div>

        <!-- Success Message -->
        <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-3" role="alert">
          Successfully created doctor
          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
<!-- Modal -->
<div class="modal-backdrop" *ngIf="showModal"></div>
<div class="modal" *ngIf="showModal">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Alert</h5>
        <button type="button" class="btn-close" (click)="closeModal()"></button>
      </div>
      <div class="modal-body">
        <p>{{ modalMessage }}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="closeModal()">OK</button>
      </div>
    </div>
  </div>
</div>