import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Doctor } from 'src/app/Models/doctor.model';
import { FavoriteDignosisCode } from 'src/app/Models/favoritediagnosiscode.model';
import { DoctorService } from 'src/app/services/doctor.service';
import { FavoriteDignosisCodeService } from 'src/app/services/favorite-dignosis-code.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-favorite-diagnosis-popup',
  templateUrl: './favorite-diagnosis-popup.component.html',
  styleUrls: ['./favorite-diagnosis-popup.component.css'],
})
export class FavoriteDiagnosisPopupComponent implements OnInit {
  helper = new JwtHelperService();
  myToken = this.tokenService.getToken();
  doctorId = 0;
  Doctor: Doctor;

  private readonly storageKey = 'favoriteIcdCodes'; // Key for local storage
  filteredLists: any[][] = [];

  icdCodeList: any[] = [];

  constructor(
    private tokenService: TokenService,
    private dialog: MatDialog,
    private doctorService: DoctorService,
    private favoriteDignosisCodesService: FavoriteDignosisCodeService,
    public dialogRef: MatDialogRef<FavoriteDiagnosisPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private localStorageService: LocalStorageService
  ) {
    this.filteredCodes = [...this.favoriteDiagnosisCodes];
  }

  favoriteDiagnosisCodes = this.data.favoriteDiagnosisCodes;
  filterText = '';
  filteredCodes = [];
  selectedCode = null;
  selectedCodes = [];
  ngOnInit(): void {
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.doctorId = parseInt(decodedToken.nameid);
    this.loadFavorites(this.doctorId);
    this.filterCodes();
  }
  loadFavorites(id) {
    // Attempt to retrieve cached codes from local storage
    const cachedIcdCodes = this.localStorageService.getItem<any[]>(
      this.storageKey
    );

    if (cachedIcdCodes && cachedIcdCodes.length > 0) {
      // Load from local storage
      this.favoriteDiagnosisCodes = cachedIcdCodes;
    } else {
      // Fetch from server if not in local storage
      this.doctorService
        .getDoctorFavoriteDiagnosisCodes(id)
        .subscribe((data) => {
          // Assume data.FavoriteDignosisCodes is the array we need
          this.favoriteDiagnosisCodes = data.FavoriteDignosisCodes;

          // Cache the data in local storage
          this.localStorageService.setItem(
            this.storageKey,
            this.favoriteDiagnosisCodes
          );
        });
    }
  }

  filterCodes() {
    const lowerCaseFilter = this.filterText.toLowerCase().trim();
    this.filteredCodes = this.favoriteDiagnosisCodes.filter(
      (code) => code.Code.toLowerCase().includes(lowerCaseFilter)
      // || code.Description.toLowerCase().includes(lowerCaseFilter)
    );
  }

  selectCode(code) {
    this.selectedCode = code;
  }

  confirm() {
    this.dialogRef.close(this.selectedCodes);
  }

  close() {
    this.dialogRef.close(null);
  }
}
