import { Component } from '@angular/core';
import { EmergencyAccessService } from 'src/app/services/emergency-access.service';

@Component({
  selector: 'app-emergency-access',
  templateUrl: './emergency-access.component.html',
  styleUrls: ['./emergency-access.component.css'],
})
export class EmergencyAccessComponent {
  constructor(private emergencyAccessService: EmergencyAccessService) {}

  requestEmergencyAccess() {
    const userId = '12345'; // Replace with the actual user ID from session or state
    const credentialId = 'abcdefg12345'; // Replace with the token generated by Face ID

    this.emergencyAccessService.verifyFaceId(userId, credentialId).subscribe(
      (response) => {
        console.log('Access granted. Token:', response.token);
      },
      (error) => {
        console.error('Access denied:', error);
      }
    );
  }
}
