import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { DoctorReport } from 'src/app/Models/doctorreport.model';
import { NurseReport } from 'src/app/Models/nursereport.model';
import { Patient } from 'src/app/Models/patient.model';
import { RadiologyMri } from 'src/app/Models/radiologymri.model';
import { UploadLabReport } from 'src/app/Models/uploadlabreport.model';
import { DoctorReportService } from 'src/app/services/doctor-report.service';
import { PatientService } from 'src/app/services/patient.service';
import { TokenService } from 'src/app/services/token.service';
import jsPDF from 'jspdf';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import html2pdf from 'html2pdf.js';

@Component({
  selector: 'app-patient-side-progress-note',
  templateUrl: './patient-side-progress-note.component.html',
  styleUrls: ['./patient-side-progress-note.component.css'],
})
export class PatientSideProgressNoteComponent implements OnInit {
  email = new FormControl('');
  @ViewChild('PatientVisit') PatientVisit!: ElementRef;

  heartRateChart: any = {
    series: [
      {
        name: 'Heart Rate',
        data: [],
      },
    ],
    title: {
      text: 'Heart Rate Chart',
    },
    chart: {
      type: 'line',
    },
    xAxis: {
      type: 'datetime',
    },
  };

  respirationChart: any = {
    series: [
      {
        name: 'Respiration Rate',
        data: [],
      },
    ],
    title: {
      text: 'Respiratory Chart',
    },
    chart: {
      type: 'line',
    },
    xAxis: {
      type: 'datetime',
    },
  };

  tempratureChart: any = {
    series: [
      {
        name: 'Temprature',
        data: [],
      },
    ],
    title: {
      text: 'Temprature Chart',
    },
    chart: {
      type: 'line',
    },
    xAxis: {
      type: 'datetime',
    },
  };

  bloodPressureChart: any = {
    series: [
      {
        name: 'Systolic',
        data: [],
      },
      {
        name: 'Diastolic',
        data: [],
      },
    ],
    title: {
      text: 'Blood Pressure Chart',
    },
    chart: {
      type: 'line',
    },
    xAxis: {
      categories: [],
    },
  };
  OxygenSaturationChart: any = {
    series: [
      {
        name: 'Oxygen Saturation',
        data: [],
      },
    ],
    title: {
      text: 'Oxygen Saturation Chart',
    },
    chart: {
      type: 'line',
    },
    xAxis: {
      type: 'datetime',
    },
  };
  displayedColumns: string[] = ['Date', 'ReportName', 'DoctorName', 'View'];

  id = 0;
  doctorReport: DoctorReport[] = [];
  dataSource: MatTableDataSource<any>;
  labDocument: string = '';
  patient: Patient;
  reportDetails: string;
  helper = new JwtHelperService();
  myToken = this.tokenService.getToken();

  CurrentDoctorId: number;
  nurseReports: NurseReport[] = [];
  templatesLabReportsUploaded: UploadLabReport[];
  templatesRadiologyStudyReports: RadiologyMri[];

  constructor(
    private patientService: PatientService,
    private doctorReportService: DoctorReportService,
    private route: ActivatedRoute,
    private router: Router,
    private tokenService: TokenService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.CurrentDoctorId = parseInt(decodedToken.nameid);
    this.GetReports(this.id);
    this.getPatient(this.id);
    this.getNurseReport(this.id);
    this.loadStudyReportsUploaded(this.id);
    this.loadLabReportsUploaded(this.id);
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  // ngAfterViewInit() {
  //   this.dataSource.paginator = this.paginator;
  //   this.dataSource.sort = this.sort;
  // }

  getPatient(id) {
    this.patientService.getPatientById(id).subscribe((res) => {
      this.patient = res;
    });
  }
  GetReports(id) {
    this.patientService.getDoctorReport(id).subscribe((res) => {
      // Process the data to replace <br> with </br>
      res.DoctorReports = res.DoctorReports.map((report) => {
        return {
          ...report,
          // Assuming 'Content' or similar field might have the <br> tags
          Content: report.ReportDescription?.replace(/<br>/g, '</br>'),
        };
      });

      // Assign the processed data to the table data source
      this.dataSource = new MatTableDataSource(
        res.DoctorReports.sort(function (x, y) {
          return y.DoctorReportId - x.DoctorReportId;
        })
      );
      this.dataSource.paginator = this.paginator;
    });
  }

  loadStudyReportsUploaded(id: number): void {
    this.patientService.getRadiologyMRI(id).subscribe((data) => {
      // Assuming data.RadiologyMris is an array of radiology reports
      if (data.RadiologyMris && data.RadiologyMris.length > 0) {
        // Sort the reports by 'createdDate' in descending order (most recent first)
        const sortedReports = data.RadiologyMris.sort((a, b) => {
          const dateA = new Date(a.createdDate); // Replace with the correct field name
          const dateB = new Date(b.createdDate); // Replace with the correct field name
          return dateB.getTime() - dateA.getTime();
        });

        // Select the most recent report
        this.templatesRadiologyStudyReports = [sortedReports[0]]; // Only keep the most recent record
      } else {
        this.templatesRadiologyStudyReports = []; // Handle case when there are no reports
      }
    });
  }

  loadLabReportsUploaded(id) {
    this.patientService.GetUploadedLabReport(id).subscribe((data) => {
      if (data.UploadLabReports && data.UploadLabReports.length > 0) {
        // Sort the reports by 'createdDate' in descending order (most recent first)
        const sortedReports = data.UploadLabReports.sort((a, b) => {
          const dateA = new Date(a.createdDate); // Replace with the correct field name
          const dateB = new Date(b.createdDate); // Replace with the correct field name
          return dateB.getTime() - dateA.getTime();
        });

        // Select the most recent report
        this.templatesLabReportsUploaded = [sortedReports[0]]; // Only keep the most recent record
      } else {
        this.templatesLabReportsUploaded = []; // Handle case when there are no reports
      }
    });
  }
  getNurseReport(id) {
    this.patientService.getNurseReport(id).subscribe((res) => {
      this.nurseReports = res.NurseReports;
      this.heartRateChart.series[0].data = this.nurseReports.map((report) => [
        new Date(report.createdDate).getTime(),
        report.HeartBeat,
      ]);
      this.OxygenSaturationChart.series[0].data = this.nurseReports.map(
        (report) => [
          new Date(report.createdDate).getTime(),
          report.OxygenSaturation,
        ]
      );
      this.respirationChart.series[0].data = this.nurseReports.map((report) => [
        new Date(report.createdDate).getTime(),
        report.Respiration,
      ]);
      this.tempratureChart.series[0].data = this.nurseReports.map((report) => [
        new Date(report.createdDate).getTime() - 5 * 60 * 60 * 1000,
        report.Temparature,
      ]);
      this.nurseReports.forEach((report: any) => {
        report.BloodPressure = report.BloodPressure?.split('/');
      });
      const bloodPressure = this.nurseReports
        .filter((report) => report.BloodPressure)
        .map((report) => {
          return {
            systolic: report.BloodPressure[0],
            diastolic: report.BloodPressure[1],
            date: report.createdDate,
          };
        });
      const systolic = bloodPressure.map((x) => x.systolic);
      const diastolic = bloodPressure.map((x) => x.diastolic);
      bloodPressure.forEach((rep, index) => {
        this.bloodPressureChart.xAxis.categories[index] = new Date(
          rep.date
        ).toLocaleDateString();
        this.bloodPressureChart.series[0].data = systolic;
        this.bloodPressureChart.series[1].data = diastolic;
      });
    });
  }
  delete(id) {
    this.doctorReportService.deleteDoctorReport(id).subscribe((res) => {
      alert('are you sure you wanted to delete record?');
      this.GetReports(this.id);
    });
  }
  // print() {
  //   window.print();
  // }
  print() {
    // Before printing, temporarily hide the body of the modal
    const modalBody = document.querySelector('.app-modal-body');

    // Open the print window and write the content
    let printWindow = window.open('', '', 'height=900,width=1200');
    printWindow.document.write('<html><head><title>Print</title>');

    // Include custom print styles
    printWindow.document.write(`
        <style>
            @media print {
                body {
                    margin: 10a;
                    padding: 0;
                }
                .app-modal-body {
                    display: none !important;   /* Hide modal body */
                }
                .app-modal-header, .app-modal-footer {
                    display: block !important;  /* Ensure header and footer are shown */
                }
                .dt-button, .btn-primary {
                    display: none !important;   /* Hide print buttons */
                }
                @page {
                    margin: 10;
                }
            }
        </style>
    `);

    // Write modal content (this can include only the visible parts)
    printWindow.document.write('<body>');
    printWindow.document.write(
      document.querySelector('.panel-demo1').innerHTML
    );
    printWindow.document.write('</body>');

    printWindow.document.close();

    // Print the page
    printWindow.print();
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  public viewReport(report: DoctorReport): void {
    // console.log('report: ', report);
    this.reportDetails = report.ReportDescription;
  }

  copyRowData(row: any): void {
    const htmlContent = `
      <p><strong>Date:</strong> ${new Date(
        row.createdDate
      ).toLocaleString()}</p>
      <p><strong>Report Name:</strong> ${row.ReportName}</p>
      <p><strong>Shortcut:</strong> ${row.shortcut}</p>
      <p><strong>Physician:</strong> ${row.DoctorName}</p>
      <p><strong>Description:</strong> ${row.ReportDescription}</p>
    `;

    navigator.clipboard
      .writeText(htmlContent)
      .then(() => {
        console.log('Row data copied to clipboard!');
      })
      .catch((err) => {
        console.error('Failed to copy row data: ', err);
      });
  }

  downloadAsPDF() {
    const content = document.getElementById('printable');
    const button = document.querySelector('button'); // Select the button
    if (content) {
      button?.classList.add('downloadButton');
      // Configure html2pdf options
      const options = {
        margin: 1, // Margins in cm
        filename: 'medical-record.pdf', // Output file name
        image: { type: 'jpeg', quality: 0.98 }, // Image quality
        html2canvas: { scale: 2 }, // Scale for higher resolution
        jsPDF: { unit: 'cm', format: 'a4', orientation: 'portrait' }, // A4 portrait format
      };

      // Generate PDF
      html2pdf().set(options).from(content).save();
    }
  }
  send() {
    let body = {
      Email: this.email.value,
      Subject: 'Medical Records',
      Template: `${this.PatientVisit.nativeElement.innerHTML}`,
    };
    this.patientService.sendEmail(body).subscribe((res) => {
      alert('Medical Records sent');
    });
  }
}
