import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RxNormApiService {
  private baseUrl = 'https://rxnav.nlm.nih.gov/REST';

  constructor(private http: HttpClient) {}

  // Search drugs using approximate term
  searchDrug(term: string): Observable<any> {
    const url = `${this.baseUrl}/approximateTerm.json?term=${encodeURIComponent(
      term
    )}`;
    return this.http.get(url);
  }

  // Get related drugs (dosages) using RxCUI
  getRelatedDrugs(rxCui: string): Observable<any> {
    const url = `${this.baseUrl}/rxcui/${rxCui}/related.json?tty=SCD+SBD`;
    return this.http.get(url);
  }
}
