import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { FrontDeskStaff } from 'src/app/Models/frontdeskstaff.mode';
import { FrontdeskService } from 'src/app/services/frontdesk.service';
import { HospitalService } from 'src/app/services/hospital.service';
import { TokenService } from 'src/app/services/token.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-view-front-desk-staff',
  templateUrl: './view-front-desk-staff.component.html',
  styleUrls: ['./view-front-desk-staff.component.css'],
})
export class ViewFrontDeskStaffComponent implements OnInit {
  id = 0;
  filteredList: FrontDeskStaff[];
  frontDeskStaffList: FrontDeskStaff[] = [];
  dataSource: MatTableDataSource<FrontDeskStaff>;
  helper = new JwtHelperService();
  myToken = this.tokenService.getToken();
  constructor(
    private router: ActivatedRoute,
    private hospitalService: HospitalService,
    private userService: UserService,
    private frontDeskService: FrontdeskService,
    private tokenService: TokenService
  ) {}

  ngOnInit(): void {
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.id = parseInt(decodedToken.nameid);
    this.getFrontDeskStaff(this.id);
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  getFrontDeskStaff(id) {
    this.hospitalService.getFrontDeskStaffs(id).subscribe((res) => {
      this.filteredList = res.FrontDeskStaffs;
      this.frontDeskStaffList = res.FrontDeskStaffs;
      // this.dataSource = new MatTableDataSource(res);
      // this.dataSource.paginator=this.paginator;
    });
  }

  tabChanged(event: MatTabChangeEvent): void {
    if (event.index === 0) {
      this.getFrontDeskStaff(this.id);
    }
  }

  search(searchValue) {
    // console.log(searchValue);
    if (searchValue.value != null) {
      this.filteredList = this.frontDeskStaffList.filter((x) =>
        x.FullName.toLowerCase().includes(searchValue.value.toLowerCase())
      );
    }
  }

  delete(id) {
    this.frontDeskService.deleteFrontDeskStaff(id).subscribe((res) => {
      this.userService.deleteUser(id).subscribe();
      this.getFrontDeskStaff(this.id);
    });
  }
}
