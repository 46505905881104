<div class="mt-5">
    <mat-tab-group (selectedTabChange)="tabChanged($event)">


        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">add</mat-icon>
                Past Medication
            </ng-template>

            <app-add-pastmedication></app-add-pastmedication>

        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">add</mat-icon>
                Surgical History
            </ng-template>
            <!-- (submitAction)="handleSubmitAction()" -->
            <app-add-surgical-history></app-add-surgical-history>

        </mat-tab>
        <!-- *ngIf="!currentPatient.IsVaccineCompleted" -->
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">add</mat-icon>
                Immunization
            </ng-template>
            <!-- (submitAction)="handleSubmitAction()" -->
            <app-add-vaccine></app-add-vaccine>

        </mat-tab>
        <!-- *ngIf="!currentPatient.IsAllergyCompleted" -->
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">add</mat-icon>
                Allergy
            </ng-template>
            <app-add-allergy></app-add-allergy>
            <!-- (submitAction)="handleSubmitAction()" -->
        </mat-tab>

        <mat-tab *ngIf="!currentPatient.IsFamilyHistoryCompleted">
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">add</mat-icon>
                Family History
            </ng-template>
            <app-add-familyhistory></app-add-familyhistory>

        </mat-tab>
        <mat-tab *ngIf="!currentPatient.IsSocialHistoryCompleted">
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">add</mat-icon>
                Social History
            </ng-template>
            <app-add-socialhistory></app-add-socialhistory>

        </mat-tab>
        <mat-tab *ngIf="!currentPatient.IsMedicalHistoryCompleted">
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">add</mat-icon>
                Medical History
            </ng-template>
            <app-add-medical-history></app-add-medical-history>

        </mat-tab>
    </mat-tab-group>
</div>