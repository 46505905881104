import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Patient } from 'src/app/Models/patient.model';
import { TestResult } from 'src/app/Models/testresult.model';
import { PatientService } from 'src/app/services/patient.service';
import { TestResultsService } from 'src/app/services/test-results.service';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-view-test-results',
  templateUrl: './view-test-results.component.html',
  styleUrls: ['./view-test-results.component.css'],
})
export class ViewTestResultsComponent implements OnInit {
  displayedColumns: string[] = [
    'Date',
    'DoctorName',
    'TestName',
    // 'Signature',
    'View',
    'Delete',
  ];

  id = 0;
  testResults: TestResult[] = [];
  dataSource: MatTableDataSource<any>;
  labDocument: string = '';
  patient: Patient;
  testName: string = '';
  patientComplaints: string = '';
  findings: string = '';
  impression: string = '';
  helper = new JwtHelperService();

  myToken = this.tokenService.getToken();

  CurrentDoctorId: number;
  constructor(
    private patientService: PatientService,
    private testResultService: TestResultsService,
    private route: ActivatedRoute,
    private router: Router,
    private tokenService: TokenService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });

    this.GetReports(this.id);
    this.getPatient(this.id);

    let decodedToken = this.helper.decodeToken(this.myToken);
    this.CurrentDoctorId = parseInt(decodedToken.nameid);
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  // ngAfterViewInit() {
  //   this.dataSource.paginator = this.paginator;
  //   this.dataSource.sort = this.sort;
  // }

  getPatient(id) {
    this.patientService.getPatientById(id).subscribe((res) => {
      this.patient = res;
    });
  }
  GetReports(id) {
    this.patientService.getProcedureTestResults(id).subscribe((res) => {
      console.log(res);
      this.dataSource = new MatTableDataSource(
        res.TestResults.sort(function (x, y) {
          return y.TestResultId - x.TestResultId;
        })
      );
      this.dataSource.paginator = this.paginator;
    });
  }
  delete(id) {
    this.testResultService.deleteTestResult(id).subscribe((res) => {
      this.GetReports(this.id);
    });
  }
  print() {
    window.print();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  public viewReport(report: TestResult): void {
    // console.log('report: ', report);
    this.testName = report.TestName;
    this.patientComplaints = report.PatientComplaints;
    this.findings = report.Findings;
    this.impression = report.Impression;
  }
}
