import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Biller } from 'src/app/Models/biller.model';
import { BillerService } from 'src/app/services/biller.service';
import { HospitalService } from 'src/app/services/hospital.service';
import { TokenService } from 'src/app/services/token.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-view-biller',
  templateUrl: './view-biller.component.html',
  styleUrls: ['./view-biller.component.css'],
})
export class ViewBillerComponent implements OnInit {
  id = 0;
  filteredList: Biller[];
  billerList: Biller[] = [];
  dataSource: MatTableDataSource<Biller>;
  helper = new JwtHelperService();
  myToken = this.tokenService.getToken();
  constructor(
    private router: ActivatedRoute,
    private hospitalService: HospitalService,
    private userService: UserService,
    private billerService: BillerService,
    private tokenService: TokenService
  ) {}

  ngOnInit(): void {
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.id = parseInt(decodedToken.nameid);
    this.getBiller(this.id);
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  getBiller(id) {
    this.hospitalService.getBillers(id).subscribe((res) => {
      this.filteredList = res.Billers;
      this.billerList = res.Billers;
      // this.dataSource = new MatTableDataSource(res);
      // this.dataSource.paginator=this.paginator;
    });
  }

  tabChanged(event: MatTabChangeEvent): void {
    if (event.index === 0) {
      this.getBiller(this.id);
    }
  }

  search(searchValue) {
    // console.log(searchValue);
    if (searchValue.value != null) {
      this.filteredList = this.billerList.filter((x) =>
        x.FullName.toLowerCase().includes(searchValue.value.toLowerCase())
      );
    }
  }

  delete(id) {
    this.billerService.deleteBiller(id).subscribe((res) => {
      this.userService.deleteUser(id).subscribe();
      this.getBiller(this.id);
    });
  }
}
