<div class="mt-4 ">
  <mat-tab-group backgroundColor="primary">
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon>person</mat-icon>
        <span class="ml-2 mt-1">Patient Info</span>
      </ng-template>
      <div class="row my-4 mx-0">
        <div class="col-lg-3 col-md-6 h-100 mx-auto mb-5">
          <div class="patient-image mx-auto" [ngStyle]="{
              'background-image': 'url(' + Patient?.PatientImage + ')'
            }"></div>
        </div>
        <!-- <div class="col-lg-9 col-md-6"> -->
        <!-- <div class="row mx-0"> -->
        <div class="col-lg-3 col-md-6 p-info-cols mb-5">
          <mat-card>
            <mat-card-content>
              <h2>Personal Information</h2>
              <table class="w-100">
                <tr>
                  <td>MRN:</td>
                  <td>{{ Patient?.PatientId }}</td>
                </tr>
                <tr>
                  <td>Patient Name:</td>
                  <td>{{ Patient?.PatientFirstName }}&nbsp;&nbsp;{{ Patient?.PatientLastName }}</td>
                </tr>

                <tr>
                  <td>D.O.B:</td>
                  <td>{{ Patient?.PatientDOB }}</td>
                </tr>
                <tr>
                  <td>Gender:</td>
                  <td>{{ Patient?.PatientGender }}</td>
                </tr>
                <tr>
                  <td>Email:</td>
                  <td>{{ Patient?.Email }}</td>
                </tr>
                <tr>
                  <td>Cell No:</td>
                  <td>{{ Patient?.PhoneNumber }}</td>
                </tr>
                <tr>
                  <td>SSN:</td>
                  <td>{{ Patient?.PatientNationalId }}</td>
                </tr>
                <tr>
                  <td>Blood Group:</td>
                  <td>{{ Patient?.BloodGroup }}</td>
                </tr>
                <tr>
                  <td>Height:</td>
                  <td>{{ Patient?.PatientHeight }}</td>
                </tr>
                <tr>
                  <td>Weight:</td>
                  <td>{{ Patient?.PatientWeight }}</td>
                </tr>
              </table>
            </mat-card-content>
          </mat-card>
        </div>
        <div class="col-lg-3 col-md-6 p-info-cols mb-5">
          <mat-card>
            <mat-card-content>
              <h2>Insurance Details</h2>
              <table class="w-100">
                <tr>
                  <td>Do you have Insurance:</td>
                  <td>{{ Insurance[0]?.DoyouhaveInsurance ?'Yes':'No' }}</td>
                </tr>
                <tr>
                  <td>Primary Policy HolderName:</td>
                  <td>{{ Insurance[0]?.PrimaryPolicyHolderName }}</td>
                </tr>
                <tr>
                  <td>Secondary Policy Holder Name:</td>
                  <td>{{ Insurance[0]?.SecondaryPolicyHolderName }}</td>
                </tr>
                <tr>
                  <td>Provider:</td>
                  <td>{{ Insurance[0]?.InsuaranceName }}</td>
                </tr>
                <tr>
                  <td>InsuaranceNo</td>
                  <td>{{ Insurance[0]?.InsuaranceNo }}</td>
                </tr>
                <tr>
                  <td>Expiry Date</td>
                  <td>{{ Insurance[0]?.InsuaranceExpiryDate }}</td>
                </tr>
                <tr>
                  <td>View Front</td>
                  <td>
                    <mat-icon type="button" color="primary" (click)="openPopFront()">visibility</mat-icon>
                    <!-- <mat-icon data-toggle="modal" color="primary" data-target="#exampleModalLong">visibility</mat-icon> -->
                  </td>
                </tr>
                <tr>
                  <td>View Back</td>
                  <td>
                    <mat-icon type="button" color="primary" (click)="openPopup()">visibility</mat-icon>
                  </td>
                </tr>
              </table>
            </mat-card-content>
          </mat-card>
        </div>
        <div class="col-lg-3 col-md-6 p-info-cols mb-5">
          <mat-card>
            <mat-card-content>
              <h2>Emergency Contact Details</h2>
              <table class="w-100">
                <tr>
                  <td>Contact Name:</td>
                  <td>{{ emContacts[0]?.EmergencyContactName }}</td>
                </tr>
                <tr>
                  <td>Contact Phone#</td>
                  <td>{{ emContacts[0]?.EmergencyContactPhone }}</td>
                </tr>
                <tr>
                  <td>Relationship:</td>
                  <td>{{ emContacts[0]?.EmergencyContactRelation }}</td>
                </tr>

                <tr>
                  <td class="d-flex">Address</td>
                  <td [title]="Patient?.PatientAddress" class="wrap-long-text">
                    {{ Patient?.PatientAddress }}
                  </td>
                </tr>
              </table>
              <div>
                <p>Name of Parent(if Minor):{{Patient?.NameOfParent}}</p>
              </div>
            </mat-card-content>
          </mat-card>
        </div>


      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">medical_services</mat-icon>
        <span class="ml-2 mt-1">Recent Medications</span>
      </ng-template>
      <!-- <div class="container-fluid w-100">
        <div class="row px-3">
          <div class="col-12 mt-4">
            <h2>Medications</h2>
            <table class="table table-stripped w-100">
              <thead class="">
                <th>Date of Issue </th>

                <th>Name of Drug -- (Prescribed)--</th>

                <th>Approved By Physician</th>
                <th>Approved By NP</th>
                <th>Quantity</th>
                <th>Refillby</th>
                <th>No. Refills</th>
                <th>Instruction</th>
                <th>Status</th>

              </thead>
              <tbody>
                <tr *ngFor="let ps of prescriptions">
                  <td>{{ ps?.CreatedDate | date: 'MM/dd/yyyy'}}</td>
                  <td>{{ ps?.DrugName }}</td>
                  <td>{{ ps?.DoctorName }}</td>
                  <td>{{ ps?.NurseName }}</td>
                  <td>{{ ps?.Quantity }}</td>
                  <td>{{ ps?.RefillDuration }}</td>
                  <td>{{ ps?.Numberofrefills }}</td>
                  <td>{{ ps?.Quantity }}</td>
                  <td>{{ ps?.Instruction }}</td>
                  <td>{{ ps?.Status }}</td>
                  <td>
                
                    <button type="button" [routerLink]="['/addRefillRequest/',ps.PrescriptionId]" mat-raised-button
                      color="primary"> Request Refill
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div> -->
      <div class="row">
        <div class="col-md-12" *ngFor="let ps of prescriptions" style="max-width: 500px;">
          <mat-card class="example-card mat-elevation-z8 mx-5 my-5">
            <mat-card-header>
              <mat-card-title>{{ ps?.DrugName }}</mat-card-title>
              <!-- <mat-card-subtitle>{{ ps?.Instruction }}</mat-card-subtitle> -->
            </mat-card-header>
            <mat-card-content>
              <div class="row">
                <div class="col-lg-6">

                  <p>Prescribed Date : {{ ps?.CreatedDate | date: 'MM/dd/yyyy'}}</p>
                  <h4> <strong> Approved By :</strong></h4>
                  <p *ngIf="ps.DoctorName">Physician : {{ ps.DoctorName }}</p>
                  <p *ngIf="ps.NurseName">NP : {{ ps.NurseName }}</p>
                </div>
                <div class="col-lg-6">
                  <h4><strong> Refill Details</strong></h4>
                  <p>Quantity : {{ ps?.Quantity }}</p>
                  <p>Refill By : {{ ps?.EndDate | date: 'MM/dd/yyyy'}} </p>
                </div>
                <h4 class="ml-2">Direction of Usage: {{ ps?.Instruction }}</h4>

              </div>


              <!-- <h6>Prescription Details</h6> -->
              <!-- <p>Prescribed Date : {{ ps?.CreatedDate | date: 'MM/dd/yyyy'}}</p> -->
              <!-- <p>Approved By : {{ ps?.DoctorName }}</p>
              <p>NP : {{ ps?.NurseName }}</p> -->




              <!-- <p>No. Of Refills: {{ ps?.Numberofrefills }}</p> -->
              <!-- 
              <h4>Pharmacy Details</h4>
              <ul class="list-unstyled">

                <li>Pharmacy Name : <span>{{pharmacy[0]?.PharmacyName}}</span>
                <li>Pharmacy Address : <span>{{pharmacy[0]?.PharmacyAddress}}</span>
                <li>Pharmacy Phone : <span>{{pharmacy[0]?.PhoneNumber}}</span>


              </ul> -->
            </mat-card-content>
            <mat-card-actions>
              <button type="button" class="float-right" [routerLink]="['/addRefillRequest/',ps.PrescriptionId]"
                mat-raised-button color="primary"> Request Refill

              </button>
            </mat-card-actions>
          </mat-card>
        </div>
      </div>
    </mat-tab>

    <!-- <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">medical_services</mat-icon>
        <span class="ml-2 mt-1">Prescriptions</span>
      </ng-template>
      <app-patient-view-patient-prescription></app-patient-view-patient-prescription>
    </mat-tab> -->
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">medical_services</mat-icon>
        <span class="ml-2 mt-1">Past Medications</span>
      </ng-template>
      <app-patient-view-pastmedication></app-patient-view-pastmedication>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">content_cut</mat-icon>
        <span class="ml-2 mt-1">Surgical Hostory</span>
      </ng-template>
      <div class="container-fluid w-100">
        <div class="row px-3">
          <div class="col-md-12 mt-4">
            <h2>Surgical Histories</h2>
            <table class="table table-stripped w-100">
              <thead class="">
                <th>Type of Surgery</th>
                <th>Date Of Surgery</th>
                <th>Description</th>
              </thead>
              <tbody>
                <tr *ngFor="let sr of surgeries">
                  <td>{{ sr?.SurgeyText }}</td>
                  <td>{{ sr?.DateOfSurgery }}</td>
                  <td>{{ sr?.Description }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">warning</mat-icon>
        <span class="ml-2 mt-1">Allergies</span>
      </ng-template>
      <div class="container-fluid ">
        <div class="row px-3">
          <div class="col-md-12 mt-4">
            <h2>Allergies</h2>
            <table class="table table-stripped w-100">
              <thead>
                <th>Allergic To Substance</th>
                <th>Reaction</th>
                <th>Description</th>
              </thead>
              <tbody>
                <tr *ngFor="let al of allergies">
                  <td>{{ al?.AllergicTo }}</td>
                  <td>{{ al?.Reaction }}</td>
                  <td>{{ al?.Description }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">history</mat-icon>
        <span class="ml-2 mt-1">Medical History</span>
      </ng-template>
      <app-patient-view-medicalhistory></app-patient-view-medicalhistory>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">history</mat-icon>
        <span class="ml-2 mt-1">Family History</span>
      </ng-template>
      <app-patient-view-familyhistory></app-patient-view-familyhistory>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">history</mat-icon>
        <span class="ml-2 mt-1">Social History</span>
      </ng-template>
      <app-patient-view-socialhistory></app-patient-view-socialhistory>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">vaccines</mat-icon>
        <span class="ml-2 mt-1">Immunization</span>
      </ng-template>
      <app-view-patient-immunization></app-view-patient-immunization>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">bug_report</mat-icon>
        <span class="ml-2 mt-1"> Lab Reports</span>
      </ng-template>
      <app-view-patient-labreport></app-view-patient-labreport>
    </mat-tab>
    <!-- <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">person</mat-icon>
        <span class="ml-2 mt-1">ID Proof</span>
      </ng-template>
      <app-view-patient-idproof></app-view-patient-idproof>
    </mat-tab> -->


    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">qr_code</mat-icon>
        <span class="ml-2 mt-1">QR Code</span>
      </ng-template>

      <div class="container" id="printable">
        <div class="row">
          <div class="col-lg-6">
            <qrcode *ngIf="PatientId" [qrdata]="PatientId" [width]="156" [errorCorrectionLevel]="'M'"></qrcode>
            <!-- <qr-code value="Yo world!" size="300" errorCorrectionLevel="M"></qr-code> -->
            <tr>
              <td>MRN:</td>
              <td>{{ Patient?.PatientId }}</td>
            </tr>
            <tr>
              <td>Patient Name:</td>
              <td>{{ Patient?.PatientFirstName }}&nbsp;&nbsp;{{ Patient?.PatientLastName }}</td>
            </tr>

            <tr>
              <td>D.O.B:</td>
              <td>{{ Patient?.PatientDOB }}</td>
            </tr>
          </div>
          <button class="btn backG float-right mr-3" onClick="window.focus(); window.print()"
            value="print"><mat-icon>print</mat-icon></button>

          <!-- <a class="btn btn-light text-capitalize border-0" data-mdb-ripple-color="dark"  (click)="print()"><i
            class="fas fa-print text-primary"></i> Print</a> -->
        </div>

      </div>

    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">history</mat-icon>
        <span class="ml-2 mt-1">Hipaa Compliance</span>
      </ng-template>
      <app-patient-view-hipaacompliance></app-patient-view-hipaacompliance>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">medical_services</mat-icon>
        <span class="ml-2 mt-1">Pharmacy</span>
      </ng-template>
      <app-view-patient-pharmacy></app-view-patient-pharmacy>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">medication</mat-icon>
        <span class="ml-2 mt-1"> Narcotic (Opioid) consent
        </span>
      </ng-template>
      <app-patient-view-narcotic-consent> </app-patient-view-narcotic-consent>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">info</mat-icon>
        <span class="ml-2 mt-1"> GENERAL CONSENT </span>
      </ng-template>
      <app-patient-view-general-consent></app-patient-view-general-consent>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">Apartment</mat-icon>
        <span class="ml-2 mt-1">OFFICE POLICIES agreement</span>
      </ng-template>
      <app-patient-view-office-policy></app-patient-view-office-policy>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">photo_camera</mat-icon>
        <span class="ml-2 mt-1"> PHOTOGRAPH/VIDEO consent</span>
      </ng-template>
      <app-patient-view-photographic-consent></app-patient-view-photographic-consent>
    </mat-tab>

  </mat-tab-group>
</div>

<!-- <app-navbar></app-navbar> -->
<div class="mx-5">

</div>

<!-- LabReport  -->

<!-- Modal -->
<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{ display: displayStyle1 }">

  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">
          Inusrance Image Front
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="ins-Front-image mx-auto" [ngStyle]="{
          'background-image': 'url(' + Insurance[0]?.InsImageFront + ')'
        }"></div>
        <!-- <div [innerHTML]="Insurance[0]?.InsImageFront"></div> -->
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="closePopFront()">
          Close
        </button>
        <!-- <button type="button" class="btn btn-secondary" data-dismiss="modal">
          Close
        </button> -->
        <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
      </div>
    </div>
  </div>
</div>

<!-- Back Image Modal -->
<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{ display: displayStyle }">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title"> Inusrance ImageBack</h4>
      </div>
      <div class="modal-body lab-document">
        <div class="ins-Back-image mx-auto" [ngStyle]="{
          'background-image': 'url(' + Insurance[0]?.InsImageBack + ')'
        }"></div>
        <!-- <div [innerHTML]="Insurance[0]?.InsImageBack"></div> -->
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="closePopup()">
          Close
        </button>
      </div>
    </div>
  </div>
</div>


<div *ngIf="upcomingAppointment; else noAppointment">
  <div class="card-body mx-auto upcoming-appointment ">
    <h2 class="mb-1">Upcoming Appointment</h2>
    <table class="w-100">
      <tr>
        <td>Doctor Name</td>
        <td>{{ upcomingAppointment?.DoctorName }}</td>
      </tr>
      <tr>
        <td>Doctor Speciality</td>
        <td>{{ upcomingAppointment?.DoctorSpecialization }}</td>
      </tr>
      <tr>
        <td>Date</td>
        <td>{{ upcomingAppointment?.VisitDate | date: 'mediumDate' }}</td>
      </tr>
      <tr>
        <td>Time</td>
        <td>{{ upcomingAppointment?.VisitTime }}</td>
      </tr>
      <tr *ngIf="upcomingAppointment?.VisitType?.includes('TeleVisit')">
        <td>Tele Visit</td>
        <td>
          <button mat-raised-button (click)="joinCall(upcomingAppointment?.DoctorId, PatientId)" color="primary">
            Join Call
          </button>
        </td>
      </tr>
    </table>
  </div>
</div>
<ng-template #noAppointment>
  <p>No upcoming appointments found.</p>
</ng-template>