import { ViewMedicationComponent } from './components/view-medication/view-medication.component';
import { PatientComponent } from './components/patient/patient.component';
import { PrescriptionDisplayComponent } from './components/prescription-display/prescription-display.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdmindashboardComponent } from './components/admindashboard/admindashboard.component';
import { DoctordashboardComponent } from './components/doctordashboard/doctordashboard.component';
import { LabdashboardComponent } from './components/labdashboard/labdashboard.component';
import { LoginComponent } from './components/login/login.component';
import { PatientdashboardComponent } from './components/patientdashboard/patientdashboard.component';

import { MedicationComponent } from './components/medication/medication.component';
import { ViewpatientComponent } from './components/viewpatient/viewpatient.component';
import { ViewDoctorComponent } from './components/view-doctor/view-doctor.component';
import { PatientdetailsComponent } from './components/patientdetails/patientdetails.component';
import { AddPatientVisitComponent } from './components/add-patient-visit/add-patient-visit.component';
import { NotepadComponent } from './components/notepad/notepad.component';
import { VewDoctorNoteComponent } from './components/vew-doctor-note/vew-doctor-note.component';
import { UpdatePatientVisitComponent } from './components/update-patient-visit/update-patient-visit.component';
import { ViewAllergyComponent } from './components/view-allergy/view-allergy.component';
import { AddAllergyComponent } from './components/add-allergy/add-allergy.component';
import { UpdateAllergyComponent } from './components/update-allergy/update-allergy.component';
import { AddpatientComponent } from './components/addpatient/addpatient.component';
import { AddPrescriptionComponent } from './components/add-prescription/add-prescription.component';
import { ViewSurgicalHistoryComponent } from './components/view-surgical-history/view-surgical-history.component';
import { AddSurgicalHistoryComponent } from './components/add-surgical-history/add-surgical-history.component';
import { AddHospitalComponent } from './components/add-hospital/add-hospital.component';
import { HospitaldetailsComponent } from './components/hospitaldetails/hospitaldetails.component';
import { HospitalDashboardComponent } from './components/hospital-dashboard/hospital-dashboard.component';
import { AddDoctorComponent } from './components/add-doctor/add-doctor.component';
import { AddDepartmentComponent } from './components/add-department/add-department.component';
import { AddNurseComponent } from './components/add-nurse/add-nurse.component';
import { AddWardComponent } from './components/add-ward/add-ward.component';
import { ViewDepartmentComponent } from './components/view-department/view-department.component';
import { DepartmentDashboardComponent } from './components/department-dashboard/department-dashboard.component';
import { ViewHospitalComponent } from './components/view-hospital/view-hospital.component';
import { AddAppointmentComponent } from './components/add-appointment/add-appointment.component';
import { AddInvoiceComponent } from './components/add-invoice/add-invoice.component';
import { AddLabreportComponent } from './components/add-labreport/add-labreport.component';
import { ViewInvoiceComponent } from './components/view-invoice/view-invoice.component';
import { ViewLabreportComponent } from './components/view-labreport/view-labreport.component';
import { ViewAllInvoicesComponent } from './components/view-all-invoices/view-all-invoices.component';
import { EmergencyContactComponent } from './components/emergency-contact/emergency-contact.component';
import { InsuranceComponent } from './components/insurance/insurance.component';
import { AddPatientInsuranceComponent } from './components/add-patient-insurance/add-patient-insurance.component';
import { AddEmergencyContactComponent } from './components/add-emergency-contact/add-emergency-contact.component';
import { PatientsummaryComponent } from './components/patientsummary/patientsummary.component';
import { PtmedicalrecordsComponent } from './components/ptmedicalrecords/ptmedicalrecords.component';
import { PtinvoicesComponent } from './components/ptinvoices/ptinvoices.component';
import { ViewAppointmentComponent } from './components/view-appointment/view-appointment.component';
import { PrintinvoiceComponent } from './components/printinvoice/printinvoice.component';
import { ViewAllAppointmentsComponent } from './components/view-all-appointments/view-all-appointments.component';
import { UpdateAppointmentComponent } from './components/update-appointment/update-appointment.component';
import { UpdateInvoiceComponent } from './components/update-invoice/update-invoice.component';
import { ViewDoctorAppointmentsComponent } from './components/view-doctor-appointments/view-doctor-appointments.component';
import { ViewDoctorPatientsComponent } from './components/view-doctor-patients/view-doctor-patients.component';
import { ViewNurseComponent } from './components/view-nurse/view-nurse.component';
import { AddMedicineComponent } from './components/add-medicine/add-medicine.component';
import { AddCptcodeComponent } from './components/add-cptcode/add-cptcode.component';
import { ViewMedicineComponent } from './components/view-medicine/view-medicine.component';
import { ViewCptcodeComponent } from './components/view-cptcode/view-cptcode.component';
import { DoctorPatientsComponent } from './components/doctor-patients/doctor-patients.component';
import { UpdateDoctorPatientVisitComponent } from './components/update-doctor-patient-visit/update-doctor-patient-visit.component';
import { UpdateNursePatientVisitComponent } from './components/update-nurse-patient-visit/update-nurse-patient-visit.component';
import { DoctorPatientVisitComponent } from './components/doctor-patient-visit/doctor-patient-visit.component';
import { NurseDashboardComponent } from './components/nurse-dashboard/nurse-dashboard.component';
import { NursePatientsComponent } from './components/nurse-patients/nurse-patients.component';
import { NursePatientDetailsComponent } from './components/nurse-patient-details/nurse-patient-details.component';
import { AddAppointmentPatientComponent } from './components/add-appointment-patient/add-appointment-patient.component';
import { AddMedicationComponent } from './components/add-medication/add-medication.component';
import { UpdatepatientComponent } from './components/updatepatient/updatepatient.component';
import { AddLabManagerComponent } from './components/add-lab-manager/add-lab-manager.component';
import { LabpatientComponent } from './components/labpatient/labpatient.component';
import { AddNursereportComponent } from './components/add-nursereport/add-nursereport.component';
import { UpdateNursereportComponent } from './components/update-nursereport/update-nursereport.component';
import { ViewNursereportComponent } from './components/view-nursereport/view-nursereport.component';
import { ViewPatientNursereportComponent } from './components/view-patient-nursereport/view-patient-nursereport.component';
import { UpdateMedicationComponent } from './components/update-medication/update-medication.component';
import { ViewNursePtMedicationComponent } from './components/view-nurse-pt-medication/view-nurse-pt-medication.component';
import { SetpasswordComponent } from './components/setpassword/setpassword.component';
import { ResetpasswordComponent } from './components/resetpassword/resetpassword.component';
import { ViewAppointmentsTodayComponent } from './components/view-appointments-today/view-appointments-today.component';
import { ViewDoctorAppointmentsTodayComponent } from './components/view-doctor-appointments-today/view-doctor-appointments-today.component';
import { DrawPadComponent } from './components/draw-pad/draw-pad.component';
import { ViewWardComponent } from './components/view-ward/view-ward.component';

import { ViewLabManagerComponent } from './components/view-lab-manager/view-lab-manager.component';

import { RoundbookComponent } from './components/roundbook/roundbook.component';
import { PtSummaryComponent } from './components/pt-summary/pt-summary.component';
import { AddTimeSlotComponent } from './components/add-time-slot/add-time-slot.component';
import { AddLabOrderComponent } from './components/add-lab-order/add-lab-order.component';
import { AddRadiologyOrderComponent } from './components/add-radiology-order/add-radiology-order.component';
import { ViewLabOrderComponent } from './components/view-lab-order/view-lab-order.component';
import { ViewRadiologyOrderComponent } from './components/view-radiology-order/view-radiology-order.component';
import { ViewAllLabOrdersComponent } from './components/view-all-lab-orders/view-all-lab-orders.component';
import { ViewAllRadiologyOrdersComponent } from './components/view-all-radiology-orders/view-all-radiology-orders.component';
import { AddNursingAssesmentComponent } from './components/add-nursing-assesment/add-nursing-assesment.component';
import { AddDoctorDatesComponent } from './components/add-doctor-dates/add-doctor-dates.component';

import { ViewNurseAssessmentComponent } from './components/view-nurse-assessment/view-nurse-assessment.component';
import { ViewNursingAssessmentPlanComponent } from './components/view-nursing-assessment-plan/view-nursing-assessment-plan.component';
import { VitalsChartComponent } from './components/vitals-chart/vitals-chart.component';
import { RadiologyDashboardComponent } from './components/radiology-dashboard/radiology-dashboard.component';
import { AddRadiologyMemberComponent } from './components/add-radiology-member/add-radiology-member.component';
import { ViewRadiologyMemberComponent } from './components/view-radiology-member/view-radiology-member.component';
import { RadiologyPatientComponent } from './components/radiology-patient/radiology-patient.component';
import { SigningPadComponent } from './components/signing-pad/signing-pad.component';
import { ViewPatientInsuranceComponent } from './components/view-patient-insurance/view-patient-insurance.component';
import { ViewPatientIdproofComponent } from './components/view-patient-idproof/view-patient-idproof.component';
import { AddPatientIdproofComponent } from './components/add-patient-idproof/add-patient-idproof.component';
import { AddMriComponent } from './components/add-mri/add-mri.component';
import { ViewMriComponent } from './components/view-mri/view-mri.component';
import { ScannerComponent } from './components/scanner/scanner.component';
import { SuperAdminDashboardComponent } from './components/super-admin-dashboard/super-admin-dashboard.component';
import { AddCompanyComponent } from './components/add-company/add-company.component';
import { CompanyComponent } from './components/company/company.component';
import { AuthguardGuardService } from './services/authguard-guard.service';
import { AdminGuard } from './admin/admin.guard';
import { UpdateRadiologyReportComponent } from './components/update-radiology-report/update-radiology-report.component';
import { AddRadiologyReportComponent } from './components/add-radiology-report/add-radiology-report.component';
import { UploadRadiologyReportComponent } from './components/upload-radiology-report/upload-radiology-report.component';
import { AllPatientsComponent } from './components/all-patients/all-patients.component';
import { PrintmedicationComponent } from './components/printmedication/printmedication.component';

import { UpdatePrescriptionComponent } from './components/update-prescription/update-prescription.component';
import { ViewDoctorAvailabilityComponent } from './components/view-doctor-availability/view-doctor-availability.component';
import { AddRadiologyOrderSubjectComponent } from './components/add-radiology-order-subject/add-radiology-order-subject.component';
import { AddLabOrderSubjectComponent } from './components/add-lab-order-subject/add-lab-order-subject.component';
import { ViewDischargeSummaryComponent } from './components/view-discharge-summary/view-discharge-summary.component';

import { AddVaccineComponent } from './components/add-vaccine/add-vaccine.component';
import { ViewNewLabOrderComponent } from './components/view-new-lab-order/view-new-lab-order.component';

import { AddCommunicationAssessmentComponent } from './components/add-communication-assessment/add-communication-assessment.component';
import { ViewIntakeAssessmentComponent } from './components/view-intake-assessment/view-intake-assessment.component';
import { ViewCommunicationAssessmentComponent } from './components/view-communication-assessment/view-communication-assessment.component';
import { NursePatientReportsComponent } from './components/nurse-patient-reports/nurse-patient-reports.component';
import { MedTrackerComponent } from './components/med-tracker/med-tracker.component';
import { UpdateIntakeAssessmentComponent } from './components/update-intake-assessment/update-intake-assessment.component';
import { UpdateCommunicationAssessmentComponent } from './components/update-communication-assessment/update-communication-assessment.component';
import { AddNurseNoteComponent } from './components/add-nurse-note/add-nurse-note.component';
import { AddDoctorReportComponent } from './components/add-doctor-report/add-doctor-report.component';
import { PatientIntakeItemsComponent } from './components/patient-intake-items/patient-intake-items.component';
import { TwofaComponent } from './components/twofa/twofa.component';
import { AddSocialhistoryComponent } from './components/add-socialhistory/add-socialhistory.component';
import { AddFamilyhistoryComponent } from './components/add-familyhistory/add-familyhistory.component';
import { ViewFamilyhistoryComponent } from './components/view-familyhistory/view-familyhistory.component';
import { ViewSocialhistoryComponent } from './components/view-socialhistory/view-socialhistory.component';
import { AddMedicalHistoryComponent } from './components/add-medical-history/add-medical-history.component';
import { ViewMedicalHistoryComponent } from './components/view-medical-history/view-medical-history.component';
import { ViewHippaComplianceComponent } from './components/view-hippa-compliance/view-hippa-compliance.component';
import { AddHippaComplianceComponent } from './components/add-hippa-compliance/add-hippa-compliance.component';

import { PrintlabreportComponent } from './components/printlabreport/printlabreport.component';
import { AddDoctorInitialEvaluationComponent } from './components/add-doctor-initial-evaluation/add-doctor-initial-evaluation.component';
import { ViewDoctorInitialEvaluationComponent } from './components/view-doctor-initial-evaluation/view-doctor-initial-evaluation.component';
import { UpdateDoctorInitialEvaluationComponent } from './components/update-doctor-initial-evaluation/update-doctor-initial-evaluation.component';
import { AddNurseDailyAssessmentComponent } from './components/add-nurse-daily-assessment/add-nurse-daily-assessment.component';
import { ViewNurseDailyAssessmentComponent } from './components/view-nurse-daily-assessment/view-nurse-daily-assessment.component';
import { UpdateNurseDailyAssessmentComponent } from './components/update-nurse-daily-assessment/update-nurse-daily-assessment.component';
import { UpdateNurseHandOffNoteComponent } from './components/update-nurse-hand-off-note/update-nurse-hand-off-note.component';
import { ViewNurseHandOffNoteByWardComponent } from './components/view-nurse-hand-off-note-by-ward/view-nurse-hand-off-note-by-ward.component';
import { ViewWardPatientsComponent } from './components/view-ward-patients/view-ward-patients.component';
import { UpdateSafetyManagementComponent } from './components/update-safety-management/update-safety-management.component';
import { AddDisclosureAndConsentComponent } from './components/add-disclosure-and-consent/add-disclosure-and-consent.component';
import { ViewDisclosureAndConsentComponent } from './components/view-disclosure-and-consent/view-disclosure-and-consent.component';
import { UpdateDisclosureAndConsentComponent } from './components/update-disclosure-and-consent/update-disclosure-and-consent.component';
import { AddMriScreeningComponent } from './components/add-mri-screening/add-mri-screening.component';
import { UpdateMriScreeningComponent } from './components/update-mri-screening/update-mri-screening.component';
import { ViewMriScreeningComponent } from './components/view-mri-screening/view-mri-screening.component';
import { AddPastmedicationComponent } from './components/add-pastmedication/add-pastmedication.component';
import { UpdatePastmedicationComponent } from './components/update-pastmedication/update-pastmedication.component';
import { ViewPastmedicationComponent } from './components/view-pastmedication/view-pastmedication.component';
import { ViewPatientLabreportComponent } from './components/view-patient-labreport/view-patient-labreport.component';
import { ViewVaccineComponent } from './components/view-vaccine/view-vaccine.component';
import { ViewPatientImmunizationComponent } from './components/view-patient-immunization/view-patient-immunization.component';
import { AddPatientPharmacyComponent } from './components/add-patient-pharmacy/add-patient-pharmacy.component';
import { ViewPatientPharmacyComponent } from './components/view-patient-pharmacy/view-patient-pharmacy.component';
import { UpdatePatientPharmacyComponent } from './components/update-patient-pharmacy/update-patient-pharmacy.component';
import { AddRefferalComponent } from './components/add-refferal/add-refferal.component';
import { ViewRefferalComponent } from './components/view-refferal/view-refferal.component';
import { AddUploadLabReporComponent } from './components/add-upload-lab-repor/add-upload-lab-repor.component';
import { ViewUploadLabReportComponent } from './components/view-upload-lab-report/view-upload-lab-report.component';
import { AddUploadRadiologyReportComponent } from './components/add-upload-radiology-report/add-upload-radiology-report.component';
import { ViewUploadRadiologyReportComponent } from './components/view-upload-radiology-report/view-upload-radiology-report.component';
import { PatientViewPastmedicationComponent } from './components/patient-view-pastmedication/patient-view-pastmedication.component';
import { PatientViewMedicalhistoryComponent } from './components/patient-view-medicalhistory/patient-view-medicalhistory.component';
import { PatientViewFamilyhistoryComponent } from './components/patient-view-familyhistory/patient-view-familyhistory.component';
import { PatientViewSocialhistoryComponent } from './components/patient-view-socialhistory/patient-view-socialhistory.component';
import { PatientViewIdproofComponent } from './components/patient-view-idproof/patient-view-idproof.component';
import { PatientViewHipaacomplianceComponent } from './components/patient-view-hipaacompliance/patient-view-hipaacompliance.component';
import { PatientViewNarcoticConsentComponent } from './components/patient-view-narcotic-consent/patient-view-narcotic-consent.component';
import { PatientViewGeneralConsentComponent } from './components/patient-view-general-consent/patient-view-general-consent.component';
import { PatientViewOfficePolicyComponent } from './components/patient-view-office-policy/patient-view-office-policy.component';
import { PatientViewPhotographicConsentComponent } from './components/patient-view-photographic-consent/patient-view-photographic-consent.component';
import { CompletedFormsComponent } from './components/completed-forms/completed-forms.component';
import { PatientDataScannerComponent } from './components/patient-data-scanner/patient-data-scanner.component';
import { DoctorPatientScannerComponent } from './components/doctor-patient-scanner/doctor-patient-scanner.component';
import { LabPatientScannerComponent } from './components/lab-patient-scanner/lab-patient-scanner.component';
import { ViewAllhospitalsComponent } from './components/view-allhospitals/view-allhospitals.component';
import { TwofaResetPassowrdComponent } from './twofa-reset-passowrd/twofa-reset-passowrd.component';
import { PrintInvoicePtSideComponent } from './components/print-invoice-pt-side/print-invoice-pt-side.component';
import { UpdateAppointmentByDoctorComponent } from './components/update-appointment-by-doctor/update-appointment-by-doctor.component';
import { ViewNursePatientsByHospitalComponent } from './components/view-nurse-patients-by-hospital/view-nurse-patients-by-hospital.component';
import { RadiologyPatientScannerComponent } from './components/radiology-patient-scanner/radiology-patient-scanner.component';
import { WritingPadComponent } from './components/writing-pad/writing-pad.component';
import { AddPaymentMethodComponent } from './components/add-payment-method/add-payment-method.component';
import { ViewPaymentMethodComponent } from './components/view-payment-method/view-payment-method.component';
import { UpdatePaymentMethodComponent } from './components/update-payment-method/update-payment-method.component';
import { PatientHasBalanceTopayComponent } from './components/patient-has-balance-topay/patient-has-balance-topay.component';
import { PatientHistoryComponent } from './components/patient-history/patient-history.component';
import { PharmacylistComponent } from './components/pharmacylist/pharmacylist.component';
import { UpdatePharmacylistComponent } from './components/update-pharmacylist/update-pharmacylist.component';
import { AddPaharmacyComponent } from './components/add-paharmacy/add-paharmacy.component';
import { ViewPrescriptionsTodayComponent } from './components/view-prescriptions-today/view-prescriptions-today.component';
import { AddPatientPrescriptionComponent } from './components/add-patient-prescription/add-patient-prescription.component';
import { ViewPatientPrescriptiionComponent } from './components/view-patient-prescriptiion/view-patient-prescriptiion.component';
import { UpdatePatientPrescriptiionComponent } from './components/update-patient-prescriptiion/update-patient-prescriptiion.component';
import { StaffViewPatientPrescriptionComponent } from './components/staff-view-patient-prescription/staff-view-patient-prescription.component';
import { PatientViewPatientPrescriptionComponent } from './components/patient-view-patient-prescription/patient-view-patient-prescription.component';
import { StaffViewPrescriptiondisplayComponent } from './components/staff-view-prescriptiondisplay/staff-view-prescriptiondisplay.component';
import { AddPrescriptionByNurseComponent } from './components/add-prescription-by-nurse/add-prescription-by-nurse.component';
import { ViewPrescriptionByNurseComponent } from './components/view-prescription-by-nurse/view-prescription-by-nurse.component';
import { AddRefillRequestComponent } from './components/add-refill-request/add-refill-request.component';
import { ViewRefillRequestComponent } from './components/view-refill-request/view-refill-request.component';
import { AppovePrescriptionComponent } from './components/appove-prescription/appove-prescription.component';
import { AppovePrescriptionByNurseComponent } from './components/appove-prescription-by-nurse/appove-prescription-by-nurse.component';
import { ViewRefillRequestByNurseComponent } from './components/view-refill-request-by-nurse/view-refill-request-by-nurse.component';
import { AddPatientPrescriptionByNurseComponent } from './components/add-patient-prescription-by-nurse/add-patient-prescription-by-nurse.component';
import { ViewPatientPrescriptionByNurseComponent } from './components/view-patient-prescription-by-nurse/view-patient-prescription-by-nurse.component';
import { CardViewPrescriptionComponent } from './components/card-view-prescription/card-view-prescription.component';
import { AddProcedureComponent } from './components/add-procedure/add-procedure.component';
import { ViewProcedureComponent } from './components/view-procedure/view-procedure.component';
import { UpdateProcedureComponent } from './components/update-procedure/update-procedure.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { AddTestResultsComponent } from './components/add-test-results/add-test-results.component';
import { ViewTestResultsComponent } from './components/view-test-results/view-test-results.component';
import { UpdateTestResultsComponent } from './components/update-test-results/update-test-results.component';
import { ViewNerveConductionStudyComponent } from './components/view-nerve-conduction-study/view-nerve-conduction-study.component';
import { UpdateNerveConductionStudyComponent } from './components/update-nerve-conduction-study/update-nerve-conduction-study.component';
import { AddNerveConductionStudyComponent } from './components/add-nerve-conduction-study/add-nerve-conduction-study.component';
import { AddNerveStudyResultsComponent } from './components/add-nerve-study-results/add-nerve-study-results.component';
import { ViewNerveStudyResultsComponent } from './components/view-nerve-study-results/view-nerve-study-results.component';
import { UpdateNerveStudyResultsComponent } from './components/update-nerve-study-results/update-nerve-study-results.component';
import { AddElectromyographyComponent } from './components/add-electromyography/add-electromyography.component';
import { UpdateElectromyographyComponent } from './components/update-electromyography/update-electromyography.component';
import { ViewElectromyographyComponent } from './components/view-electromyography/view-electromyography.component';
import { PatientHasInsurancePendingPaymentComponent } from './components/patient-has-insurance-pending-payment/patient-has-insurance-pending-payment.component';
import { PatientHasUnpaidAmountComponent } from './components/patient-has-unpaid-amount/patient-has-unpaid-amount.component';
import { BulkImportPatientComponent } from './components/bulk-import-patient/bulk-import-patient.component';
import { InvoiceHasABalanceComponent } from './components/invoice-has-a-balance/invoice-has-a-balance.component';
import { FaxComponent } from './components/fax/fax.component';
import { UpdatePrescriptionByNurseComponent } from './components/update-prescription-by-nurse/update-prescription-by-nurse.component';
import { ViewPatientPaymentComponent } from './components/view-patient-payment/view-patient-payment.component';
import { ViewPaymentHistoryComponent } from './components/view-payment-history/view-payment-history.component';
import { UpdateNurseNoteComponent } from './components/update-nurse-note/update-nurse-note.component';
import { ViewDoctorProfileComponent } from './components/view-doctor-profile/view-doctor-profile.component';
import { UpdateDoctorProfileComponent } from './components/update-doctor-profile/update-doctor-profile.component';
import { AddMyOffDaysComponent } from './components/add-my-off-days/add-my-off-days.component';
import { ViewMyOffDaysComponent } from './components/view-my-off-days/view-my-off-days.component';
import { ChatComponent } from './components/chat/chat.component';
import { ViewAppointmentStatusComponent } from './components/view-appointment-status/view-appointment-status.component';
import { ManualyConfirmAppointmentComponent } from './components/manualy-confirm-appointment/manualy-confirm-appointment.component';
import { DoctorPatientSplitScreenComponent } from './components/doctor-patient-split-screen/doctor-patient-split-screen.component';
import { TabsComponentComponent } from './components/tabs-component/tabs-component.component';
import { NewOrdersComponent } from './components/new-orders/new-orders.component';
import { ViewOrdersComponent } from './components/view-orders/view-orders.component';
import { AllTestResultsComponent } from './components/all-test-results/all-test-results.component';
import { CallEncounterComponent } from './components/call-encounter/call-encounter.component';
import { Tabs2ComponentComponent } from './components/tabs2-component/tabs2-component.component';
import { UpdateDoctorReportComponent } from './components/update-doctor-report/update-doctor-report.component';
import { UpdateRefferalComponent } from './components/update-refferal/update-refferal.component';
import { DoctorCustomTemplateComponent } from './components/doctor-custom-template/doctor-custom-template.component';
import { AddPrescriptionFreeTextComponent } from './components/add-prescription-free-text/add-prescription-free-text.component';
import { DoctorTemplateEditorComponent } from './components/doctor-template-editor/doctor-template-editor.component';
import { DoctorTemplateListComponent } from './components/doctor-template-list/doctor-template-list.component';
import { CalculatorComponent } from './components/calculator/calculator.component';
import { TemplateTextboxComponentComponent } from './components/template-textbox-component/template-textbox-component.component';
import { UserActivityLogComponent } from './components/user-activity-log/user-activity-log.component';
import { HospitalTemplateComponent } from './components/hospital-template/hospital-template.component';
import { UpdateDoctorTemplateEditorComponent } from './components/update-doctor-template-editor/update-doctor-template-editor.component';
import { ChatForNurseComponent } from './components/chat-for-nurse/chat-for-nurse.component';
import { DoctorPatientSplitViewComponent } from './components/doctor-patient-split-view/doctor-patient-split-view.component';
import { DicomViewerComponent } from './components/dicom-viewer/dicom-viewer.component';
import { VoiceToTextComponent } from './components/voice-to-text/voice-to-text.component';
import { TabNurseSideComponent } from './components/tab-nurse-side/tab-nurse-side.component';
import { NursePatientTabAppearanceComponent } from './components/nurse-patient-tab-appearance/nurse-patient-tab-appearance.component';
import { Tab2NurseSideComponent } from './components/tab2-nurse-side/tab2-nurse-side.component';
import { Tab2ClinicSideComponent } from './components/tab2-clinic-side/tab2-clinic-side.component';
import { PatientTabAppearanceComponent } from './components/patient-tab-appearance/patient-tab-appearance.component';
import { DoctorPatientInvoicesComponent } from './components/doctor-patient-invoices/doctor-patient-invoices.component';
import { MessageComponent } from './components/message/message.component';
import { ChatForPatientComponent } from './components/chat-for-patient/chat-for-patient.component';
import { TabClinicSideComponent } from './components/tab-clinic-side/tab-clinic-side.component';
import { AuditLogComponent } from './components/audit-log/audit-log.component';
import { TwofaDeaComponent } from './components/twofa-dea/twofa-dea.component';
import { AddDoctorProgressNoteComponent } from './components/add-doctor-progress-note/add-doctor-progress-note.component';
import { AddNurseTemplatesComponent } from './components/add-nurse-templates/add-nurse-templates.component';
import { UpdateNurseTemplatesComponent } from './components/update-nurse-templates/update-nurse-templates.component';
import { ViewNurseTemplatesComponent } from './components/view-nurse-templates/view-nurse-templates.component';
import { AddNurseProgressNoteComponent } from './components/add-nurse-progress-note/add-nurse-progress-note.component';
import { ViewNurseProgressNoteComponent } from './components/view-nurse-progress-note/view-nurse-progress-note.component';
import { UpdateNurseProgressNoteComponent } from './components/update-nurse-progress-note/update-nurse-progress-note.component';
import { ViewDoctorReportComponent } from './components/view-doctor-report/view-doctor-report.component';
import { AddPrescriptionMultipleComponent } from './components/add-prescription-multiple/add-prescription-multiple.component';
import { SignatureComponent } from './components/signature/signature.component';
import { ViewPrescriptionRequestedByNurseComponent } from './components/view-prescription-requested-by-nurse/view-prescription-requested-by-nurse.component';
import { AddVitalsByDoctorComponent } from './components/add-vitals-by-doctor/add-vitals-by-doctor.component';
import { QrScannerComponent } from './components/qr-scanner/qr-scanner.component';
import { AddReviewOfSystemComponent } from './components/add-review-of-system/add-review-of-system.component';
import { ViewReviewOfSystemComponent } from './components/view-review-of-system/view-review-of-system.component';
import { UpdateReviewOfSystemComponent } from './components/update-review-of-system/update-review-of-system.component';
import { UpdateHospitaltemplateComponent } from './components/update-hospitaltemplate/update-hospitaltemplate.component';
import { ViewHospitaltemplateComponent } from './components/view-hospitaltemplate/view-hospitaltemplate.component';
import { QrScannerNurseComponent } from './components/qr-scanner-nurse/qr-scanner-nurse.component';
import { RosterComponent } from './components/roster/roster.component';
import { AddRosterComponent } from './components/add-roster/add-roster.component';
import { UpdateRosterComponent } from './components/update-roster/update-roster.component';
import { AddDutyComponent } from './components/add-duty/add-duty.component';
import { UpdateDutyComponent } from './components/update-duty/update-duty.component';
import { ViewDutyComponent } from './components/view-duty/view-duty.component';
import { DrawToolComponent } from './components/draw-tool/draw-tool.component';
import { AddPainChartComponent } from './components/add-pain-chart/add-pain-chart.component';
import { ViewPainChartComponent } from './components/view-pain-chart/view-pain-chart.component';
import { AddGeneralUploadComponent } from './components/add-general-upload/add-general-upload.component';
import { ViewGeneralUploadComponent } from './components/view-general-upload/view-general-upload.component';
import { PatientSideProgressNoteComponent } from './components/patient-side-progress-note/patient-side-progress-note.component';
import { SplitAComponent } from './components/split-a/split-a.component';
import { SplitBComponent } from './components/split-b/split-b.component';
import { NurseSideSplitAComponent } from './components/nurse-side-split-a/nurse-side-split-a.component';
import { NurseSideSplitBComponent } from './components/nurse-side-split-b/nurse-side-split-b.component';
import { VideoCallComponent } from './components/video-call/video-call.component';
import { FormBuilderComponent } from './components/form-builder/form-builder.component';
import { ViewMriMultipleComponent } from './components/view-mri-multiple/view-mri-multiple.component';
import { PrintinvoiceDoctorPatientComponent } from './components/printinvoice-doctor-patient/printinvoice-doctor-patient.component';
import { AddInvoiceOnProgressNoteComponent } from './components/add-invoice-on-progress-note/add-invoice-on-progress-note.component';
import { AddRadiologyReportPeachRecogintionEnabledComponent } from './components/add-radiology-report-peach-recogintion-enabled/add-radiology-report-peach-recogintion-enabled.component';
import { ViewRadiologyReportPeachRecogintionEnabledComponent } from './components/view-radiology-report-peach-recogintion-enabled/view-radiology-report-peach-recogintion-enabled.component';
import { ClinicalAssistanceComponent } from './components/clinical-assistance/clinical-assistance.component';
import { FavoriteDiagnosisPopupComponent } from './components/favorite-diagnosis-popup/favorite-diagnosis-popup.component';
import { BillerDashBoardComponent } from './components/biller-dash-board/biller-dash-board.component';
import { FrontDeskDashBoardComponent } from './components/front-desk-dash-board/front-desk-dash-board.component';
import { PracticeManagerDashBoardComponent } from './components/practice-manager-dash-board/practice-manager-dash-board.component';
import { MedicalAssistantDashBoardComponent } from './components/medical-assistant-dash-board/medical-assistant-dash-board.component';
import { AddBillerComponent } from './components/add-biller/add-biller.component';
import { BillerSplitAComponent } from './components/biller-split-a/biller-split-a.component';
import { BillerSplitBComponent } from './components/biller-split-b/biller-split-b.component';
import { BillerPatientComponent } from './components/biller-patient/biller-patient.component';
import { Tab2BillerComponent } from './components/tab2-biller/tab2-biller.component';
import { Tab1BillerComponent } from './components/tab1-biller/tab1-biller.component';
import { ViewAllInvoiceByBillerComponent } from './components/view-all-invoice-by-biller/view-all-invoice-by-biller.component';
import { PatientHasBalanceViewByBillerComponent } from './components/patient-has-balance-view-by-biller/patient-has-balance-view-by-biller.component';
import { PatientHasUnpaindAmountViewByBillerComponent } from './components/patient-has-unpaind-amount-view-by-biller/patient-has-unpaind-amount-view-by-biller.component';
import { AddFrontDeskStaffComponent } from './components/add-front-desk-staff/add-front-desk-staff.component';
import { ViewFrontDeskStaffComponent } from './components/view-front-desk-staff/view-front-desk-staff.component';
import { ViewBillerComponent } from './components/view-biller/view-biller.component';
import { AddAppointmentByFrontDeskStaffComponent } from './components/add-appointment-by-front-desk-staff/add-appointment-by-front-desk-staff.component';
import { Tab1PatientComponent } from './components/tab1-patient/tab1-patient.component';
import { Tab2PatientComponent } from './components/tab2-patient/tab2-patient.component';
import { SplitAPatientComponent } from './components/split-a-patient/split-a-patient.component';
import { SplitBPatientComponent } from './components/split-b-patient/split-b-patient.component';
import { UpdateNurseComponent } from './components/update-nurse/update-nurse.component';
import { UpdateHospitalComponent } from './components/update-hospital/update-hospital.component';
import { UpdateBillerComponent } from './components/update-biller/update-biller.component';
import { UpdateFrontDeskStaffComponent } from './components/update-front-desk-staff/update-front-desk-staff.component';
import { UpdateInsuranceComponent } from './components/update-insurance/update-insurance.component';
import { EligibilityCheckComponent } from './components/eligibility-check/eligibility-check.component';
import { ClaimComponent } from './components/claim/claim.component';
import { ViewDoctorReportDetailsComponent } from './components/view-doctor-report-details/view-doctor-report-details.component';
import { PaymentComponent } from './components/payment/payment.component';
import { ViewPatientPaymentByHospitalComponent } from './components/view-patient-payment-by-hospital/view-patient-payment-by-hospital.component';
import { DrugInfoComponent } from './components/drug-info/drug-info.component';
import { AddVisitSummaryComponent } from './components/add-visit-summary/add-visit-summary.component';
import { UpdateVisitSummaryComponent } from './components/update-visit-summary/update-visit-summary.component';
import { ViewVisitSummaryComponent } from './components/view-visit-summary/view-visit-summary.component';
import { ViewVisitSummaryPatientSideComponent } from './components/view-visit-summary-patient-side/view-visit-summary-patient-side.component';
import { EligibilityCheckMedicineComponent } from './components/eligibility-check-medicine/eligibility-check-medicine.component';
import { ClaimStatusComponent } from './components/claim-status/claim-status.component';
import { Cms1500Component } from './components/cms1500/cms1500.component';
import { AddServiceRequestComponent } from './components/add-service-request/add-service-request.component';
import { UpdateServiceRequestComponent } from './components/update-service-request/update-service-request.component';
import { ViewServiceRequestComponent } from './components/view-service-request/view-service-request.component';
import { ViewCms1500Component } from './components/view-cms1500/view-cms1500.component';
import { UpdateCms1500Component } from './components/update-cms1500/update-cms1500.component';
import { UpdateClaimComponent } from './components/update-claim/update-claim.component';
import { EmergencyAccessComponent } from './components/emergency-access/emergency-access.component';
import { EraListComponent } from './components/era-list/era-list.component';
import { ProviderEnrollmentComponent } from './components/provider-enrollment/provider-enrollment.component';
import { ClaimNotesComponent } from './components/claim-notes/claim-notes.component';
import { UpdateClaimAccountKeyComponent } from './components/update-claim-account-key/update-claim-account-key.component';
import { AddClaimAccountKeyComponent } from './components/add-claim-account-key/add-claim-account-key.component';
import { ViewClaimAccountKeyComponent } from './components/view-claim-account-key/view-claim-account-key.component';
import { TwoFaEmergencyAccessComponent } from './components/two-fa-emergency-access/two-fa-emergency-access.component';
import { EmergencyAccessPhoneAuthComponent } from './components/emergency-access-phone-auth/emergency-access-phone-auth.component';
import { ClaimAttachmentComponent } from './components/claim-attachment/claim-attachment.component';
import { SubscriptionComponent } from './components/subscription/subscription.component';
import { SubscriptionPlanComponent } from './components/subscription-plan/subscription-plan.component';
import { SubscriptionGuard } from './services/subscription.guard';
import { AllSubscriptionsComponent } from './components/all-subscriptions/all-subscriptions.component';
import { UpdateLabReportComponent } from './components/update-lab-report/update-lab-report.component';

const routes: Routes = [
  { path: '', component: LoginComponent, pathMatch: 'full' },
  {
    path: 'medication',
    component: MedicationComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'patientDash/:id',
    component: PatientdashboardComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'doctorDash',
    component: DoctordashboardComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'nurseDash',
    component: NurseDashboardComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'labDash',
    component: LabdashboardComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'radiologyDash',
    component: RadiologyDashboardComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'adminDash',
    component: AdmindashboardComponent,
    canActivate: [AuthguardGuardService, AdminGuard],
  },
  {
    path: 'superAdminDash',
    component: SuperAdminDashboardComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'billerDash',
    component: BillerDashBoardComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'frontDeskDash',
    component: FrontDeskDashBoardComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'practiceManagerDash',
    component: PracticeManagerDashBoardComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'medicalAssistantDash',
    component: MedicalAssistantDashBoardComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'createPatient',
    component: AddpatientComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewPatient',
    component: ViewpatientComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'PharmacyList',
    component: PharmacylistComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'AddPharmacyList',
    component: AddPaharmacyComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'UpdatePharmacyList/:id',
    component: UpdatePharmacylistComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewAllNursePatient',
    component: ViewNursePatientsByHospitalComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewWardPatients/:id',
    component: ViewWardPatientsComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'nursePatient/:id',
    component: NursePatientsComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'nursePatientTabed/:id',
    component: NursePatientTabAppearanceComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'labPatient/:id',
    component: LabpatientComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'radiologyPatient/:id',
    component: RadiologyPatientComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewDepartment',
    component: ViewDepartmentComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'patientDetails/:id',
    component: PatientdetailsComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'nursePatientDetails/:id',
    component: NursePatientDetailsComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'doctorPatientVisit/:id',
    component: DoctorPatientVisitComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'hospitalDash',
    component: HospitalDashboardComponent,
    canActivate: [AuthguardGuardService, SubscriptionGuard],
  },
  {
    path: 'dptDash/:id',
    component: DepartmentDashboardComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewNurse',
    component: ViewNurseComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'viewDoctor',
    component: ViewDoctorComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewDoctorPatients/:id',
    component: ViewDoctorPatientsComponent,
    canActivate: [AuthguardGuardService],
    children: [
      {
        path: 'splitScreen/:id',
        component: DoctorPatientSplitScreenComponent,
        outlet: 'details',
      },
    ],
  },

  {
    path: 'addDepartment/:id',
    component: AddDepartmentComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addDoctor',
    component: AddDoctorComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addNurse',
    component: AddNurseComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addRadiologyMember',
    component: AddRadiologyMemberComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addBiller',
    component: AddBillerComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewBiller/:id',
    component: ViewBillerComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'updateBiller/:id',
    component: UpdateBillerComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addFrontDeskStaff',
    component: AddFrontDeskStaffComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewFrontDeskStaff/:id',
    component: ViewFrontDeskStaffComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'updateFrontDeskStaff/:id',
    component: UpdateFrontDeskStaffComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addWard/:id',
    component: AddWardComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'addAppointment/:id',
    component: AddAppointmentComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addAppointmentFrontDesk/:id',
    component: AddAppointmentByFrontDeskStaffComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addAppointmentPT/:id',
    component: AddAppointmentPatientComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'addInvoice/:id',
    component: AddInvoiceComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addLabreport/:id',
    component: AddLabreportComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addLabOrder/:id',
    component: AddLabOrderComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addRadiologyOrder/:id',
    component: AddRadiologyOrderComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addRadiologyReport/:id',
    component: AddRadiologyReportComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addPainChart/:id',
    component: AddPainChartComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewPainChart/:id',
    component: ViewPainChartComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addGeneralUpload/:id',
    component: AddGeneralUploadComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewGeneralUpload/:id',
    component: ViewGeneralUploadComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'uploadRadiologyReport/:id',
    component: UploadRadiologyReportComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'ViewPaymentMethod/:id',
    component: ViewPaymentMethodComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'UpdatePaymentMethod/:id',
    component: UpdatePaymentMethodComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addPaymentMethod',
    component: AddPaymentMethodComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addMRI/:id',
    component: AddMriComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addPatient',
    component: AddpatientComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addLabManager',
    component: AddLabManagerComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'addHospital',
    component: AddHospitalComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'updateHospital/:id',
    component: UpdateHospitalComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addCompany',
    component: AddCompanyComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'allPatient/:id',
    component: AllPatientsComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewPatient/:id',
    component: ViewpatientComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewPatienthasBalanceToPay/:id',
    component: PatientHasBalanceTopayComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewPatienthasBalanceViewByBiller/:id',
    component: PatientHasBalanceViewByBillerComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewPatienthasInsurancePendingPayment/:id',
    component: PatientHasInsurancePendingPaymentComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewPatienthasUnPaidBalance/:id',
    component: PatientHasUnpaidAmountComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewPatienthasUnPaidBalanceViewByBiller/:id',
    component: PatientHasUnpaindAmountViewByBillerComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addMedicine',
    component: AddMedicineComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addCptCode',
    component: AddCptcodeComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'addCommunicationAssessment/:id',
    component: AddCommunicationAssessmentComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addNurseNote/:id',
    component: AddNurseNoteComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addDoctorReport/:id',
    component: AddDoctorReportComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewDoctorReport/:id',
    component: ViewDoctorReportComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewDoctorReportDetails/:id',
    component: ViewDoctorReportDetailsComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addDoctorProgressNote/:id',
    component: AddDoctorProgressNoteComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'updateDoctorReport/:id',
    component: UpdateDoctorReportComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addVisitSummary/:id',
    component: AddVisitSummaryComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewVisitSummary/:id',
    component: ViewVisitSummaryComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewVisitSummaryPatientSide/:id',
    component: ViewVisitSummaryPatientSideComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'updateVisitSummary/:id',
    component: UpdateVisitSummaryComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addNurseProgressReport/:id',
    component: AddNurseProgressNoteComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewNurseProgressReport/:id',
    component: ViewNurseProgressNoteComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'updateNurseProgressReport/:id/:PatientId',
    component: UpdateNurseProgressNoteComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'PatientIntakeItems/:id',
    component: PatientIntakeItemsComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addPatientHistory/:id',
    component: PatientHistoryComponent,
    canActivate: [AuthguardGuardService],
  },
  { path: 'scanner', component: ScannerComponent },
  {
    path: 'PatientDataScanner',
    component: PatientDataScannerComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'WritingPad',
    component: WritingPadComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'doctorPatientScanner',
    component: DoctorPatientScannerComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'QrScanner',
    component: QrScannerComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'labPatientScanner',
    component: LabPatientScannerComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'radiologyPatientScanner',
    component: RadiologyPatientScannerComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'addWard',
    component: AddWardComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'addRadiologyOrderSubject',
    component: AddRadiologyOrderSubjectComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addLabOrderSubject',
    component: AddLabOrderSubjectComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'addPatientVisit/:id',
    component: AddPatientVisitComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'updatePatientVisit/:id',
    component: UpdatePatientVisitComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'updatePatient/:id',
    component: UpdatepatientComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'updateNurseReport/:id',
    component: UpdateNursereportComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'updateNurseNote/:id',
    component: UpdateNurseNoteComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'updateDoctorPatientVisit/:id/',
    component: UpdateDoctorPatientVisitComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'updateNursePatientVisit/:id',
    component: UpdateNursePatientVisitComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addAllergy/:id',
    component: AddAllergyComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addPharmacy/:id',
    component: AddPatientPharmacyComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewPharmacy/:id',
    component: ViewPatientPharmacyComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'updatePharmacy/:id',
    component: UpdatePatientPharmacyComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewDoctorProfile/:id',
    component: ViewDoctorProfileComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'updateDoctorProfile/:id',
    component: UpdateDoctorProfileComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'updateNurseProfile/:id',
    component: UpdateNurseComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addPastMedication/:id',
    component: AddPastmedicationComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'updatePastMedication/:id',
    component: UpdatePastmedicationComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewPastMedication/:id',
    component: ViewPastmedicationComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addVaccine/:id',
    component: AddVaccineComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'iewVaccine/:id',
    component: ViewVaccineComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewPateintImmunization/:id',
    component: ViewPatientImmunizationComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addPrescription/:id',
    component: AddPrescriptionComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addMutiplePrescription/:id',
    component: AddPrescriptionMultipleComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addPrescriptionFreeText/:id',
    component: AddPrescriptionFreeTextComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addPrescriptionByNurse/:id',
    component: AddPrescriptionByNurseComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'cardViewPrescription/:id',
    component: CardViewPrescriptionComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewPrescriptionByNurse/:id',
    component: ViewPrescriptionByNurseComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewPrescriptionRequestedByNurse/:id',
    component: ViewPrescriptionRequestedByNurseComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addPatientPrescription/:id',
    component: AddPatientPrescriptionComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addPatientPrescriptionByNurse/:id',
    component: AddPatientPrescriptionByNurseComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewPatientPrescriptionByNurse/:id',
    component: ViewPatientPrescriptionByNurseComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'staffViewPatientPrescription/:id',
    component: StaffViewPatientPrescriptionComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'staffViewPrescriptionDisplay/:id',
    component: StaffViewPrescriptiondisplayComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'patientViewPatientPrescription/:id',
    component: PatientViewPatientPrescriptionComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewPatientPrescription/:id',
    component: ViewPatientPrescriptiionComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'updatePatientPrescription/:id',
    component: UpdatePatientPrescriptiionComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'todaysPrescriptions/:id',
    component: ViewPrescriptionsTodayComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'FavoriteDiagnosisPopup/:id',
    component: FavoriteDiagnosisPopupComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addRefillRequest/:id',
    component: AddRefillRequestComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewRefillRequest/:id',
    component: ViewRefillRequestComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewRefillRequestByNurse/:id',
    component: ViewRefillRequestByNurseComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addMedication/:id',
    component: AddMedicationComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addNurseReport/:id',
    component: AddNursereportComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addVitalsByDctor/:id',
    component: AddVitalsByDoctorComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addSurgicalHistory/:id',
    component: AddSurgicalHistoryComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addFamilyHistory/:id',
    component: AddFamilyhistoryComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addSocialHistory/:id',
    component: AddSocialhistoryComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addMedicalHistory/:id',
    component: AddMedicalHistoryComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addHippaCompliance/:id',
    component: AddHippaComplianceComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'addEmergencyContact/:id',
    component: AddEmergencyContactComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addPatientInsurance/:id',
    component: AddPatientInsuranceComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'UpdatePatientInsurance/:id',
    component: UpdateInsuranceComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addPatientIdProof/:id',
    component: AddPatientIdproofComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'addTimeSlot',
    component: AddTimeSlotComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addDoctorDate',
    component: AddDoctorDatesComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addMyOffDays',
    component: AddMyOffDaysComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'addDisclosureandConsent/:id',
    component: AddDisclosureAndConsentComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewDisclosureandConsent/:id',
    component: ViewDisclosureAndConsentComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'updateDisclosureAndConsent/:id',
    component: UpdateDisclosureAndConsentComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addMriScreening/:id',
    component: AddMriScreeningComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewMriScreening/:id',
    component: ViewMriScreeningComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'updateMriScreening/:id',
    component: UpdateMriScreeningComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addNursingAssessment/:id',
    component: AddNursingAssesmentComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'addDctorEvaluation/:id',
    component: AddDoctorInitialEvaluationComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewDctorEvaluation/:id',
    component: ViewDoctorInitialEvaluationComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'updateDctorEvaluation/:id',
    component: UpdateDoctorInitialEvaluationComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'editNurseDailyAssessment/:id',
    component: UpdateNurseDailyAssessmentComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addNurseDailyAssessment/:id',
    component: AddNurseDailyAssessmentComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewNurseDailyAssessment/:id',
    component: ViewNurseDailyAssessmentComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'viewNurseHandOffNoteByWard/:id',
    component: ViewNurseHandOffNoteByWardComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'updateNurseHandOffNote/:id',
    component: UpdateNurseHandOffNoteComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'patientInsurance/:id',
    component: ViewPatientInsuranceComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'patientIdProof/:id',
    component: ViewPatientIdproofComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'printMedication/:id',
    component: PrintmedicationComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'medTracker/:id',
    component: MedTrackerComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'reportsNp/:id',
    component: NursePatientReportsComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'editIntakeAssessment/:id',
    component: UpdateIntakeAssessmentComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'editCommunicationAssessment/:id',
    component: UpdateCommunicationAssessmentComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'editAllergy/:id',
    component: UpdateAllergyComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'editAppointment/:id',
    component: UpdateAppointmentComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'editAppointmentByDoctor/:id',
    component: UpdateAppointmentByDoctorComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'editInvoice/:id',
    component: UpdateInvoiceComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'editNursePtMedication/:id',
    component: UpdateMedicationComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'notePad',
    component: NotepadComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'drawPad/:id',
    component: DrawPadComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'roundBook/:id',
    component: RoundbookComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'viewIntakeAssessment/:id',
    component: ViewIntakeAssessmentComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewCommunicationAssessment/:id',
    component: ViewCommunicationAssessmentComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'company',
    component: CompanyComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewHospital/:id',
    component: ViewHospitalComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewAllHospital',
    component: ViewAllhospitalsComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'viewRadiologyMember',
    component: ViewRadiologyMemberComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewLabManager',
    component: ViewLabManagerComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewHospital',
    component: ViewHospitalComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewDoctorNote/:id',
    component: VewDoctorNoteComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewAllergy/:id',
    component: ViewAllergyComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewInvoice/:id',
    component: ViewInvoiceComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewLabReport/:id',
    component: ViewLabreportComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'UpdateLabReport/:id',
    component: UpdateLabReportComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewPatientLabReport/:id',
    component: ViewPatientLabreportComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewLabOrder/:id',
    component: ViewLabOrderComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewNewLabOrder/:id',
    component: ViewNewLabOrderComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'editSafetyManagement/:id',
    component: UpdateSafetyManagementComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewAllLabOrder',
    component: ViewAllLabOrdersComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewRadiologyOrder/:id',
    component: ViewRadiologyOrderComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewAllRadiologyOrders',
    component: ViewAllRadiologyOrdersComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewPrescription/:id',
    component: PrescriptionDisplayComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewSurgicalHistory/:id',
    component: ViewSurgicalHistoryComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewFamliyHistory/:id',
    component: ViewFamilyhistoryComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewSocialHistory/:id',
    component: ViewSocialhistoryComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewMedicalHistory/:id',
    component: ViewMedicalHistoryComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewHippaCompliance/:id',
    component: ViewHippaComplianceComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'viewAllInvoices',
    component: ViewAllInvoicesComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewAllInvoicesByBiller/:id',
    component: ViewAllInvoiceByBillerComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewAllAppointments',
    component: ViewAllAppointmentsComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewTodaysAppointments',
    component: ViewAppointmentsTodayComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewPTinsurance',
    component: InsuranceComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewMedicine',
    component: ViewMedicineComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewMedication',
    component: ViewMedicationComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewNursePtMedication',
    component: ViewNursePtMedicationComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewNurseReport',
    component: ViewNursereportComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewPatientNurseReport',
    component: ViewPatientNursereportComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewCptCode',
    component: ViewCptcodeComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'viewNursingAssessmentPlan',
    component: ViewNursingAssessmentPlanComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'viewNursingAssessmentPlanById/:id',
    component: ViewNurseAssessmentComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'vitalsChart/:id',
    component: VitalsChartComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewDischargeSummary/:id',
    component: ViewDischargeSummaryComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'viewMri/:id',
    component: ViewMriComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewMriMultiple/:id',
    component: ViewMriMultipleComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewDicom',
    component: DicomViewerComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'viewWard',
    component: ViewWardComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'emergencyAccess',
    component: EmergencyAccessComponent,
    canActivate: [AuthguardGuardService],
  },

  { path: 'signature', component: SigningPadComponent },

  {
    path: 'updateRadiologyReport/:id',
    component: UpdateRadiologyReportComponent,
    canActivate: [AuthguardGuardService],
  },
  // path: 'updatePrescription/:id/:PatientId',

  {
    path: 'updatePrescription/:id',
    component: UpdatePrescriptionComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'updatePrescriptionNurse/:id',
    component: UpdatePrescriptionByNurseComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'appovePrescription/:id',
    component: AppovePrescriptionComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'appovePrescriptionByNurse/:id',
    component: AppovePrescriptionByNurseComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'viewDoctorAvaialabilty',
    component: ViewDoctorAvailabilityComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewDoctorOffDays',
    component: ViewMyOffDaysComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewEmergencyContact',
    component: EmergencyContactComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'patientSummary/:id',
    component: PatientsummaryComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'ptMedicalRecords/:id',
    component: PtmedicalrecordsComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'ptInvoices/:id',
    component: PtinvoicesComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'ptAppointment/:id',
    component: ViewAppointmentComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addRefferal/:id',
    component: AddRefferalComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'updateRefferal/:id',
    component: UpdateRefferalComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'uploadLabreport/:id',
    component: AddUploadLabReporComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewUploadLabreport/:id',
    component: ViewUploadLabReportComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'uploadRadiolgyreport/:id',
    component: AddUploadRadiologyReportComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewUploadRadiologyreport/:id',
    component: ViewUploadRadiologyReportComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewRefferal/:id',
    component: ViewRefferalComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'drAppointment/:id',
    component: ViewDoctorAppointmentsComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'drTodaysAppointment/:id',
    component: ViewDoctorAppointmentsTodayComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'patient/:id',
    component: PatientComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'patientTabed/:id',
    component: PatientTabAppearanceComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'doctorPatient/:id',
    component: DoctorPatientsComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'printInvoice/:id',
    component: PrintinvoiceComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'newInvoiceOnProgressNote/:id',
    component: AddInvoiceOnProgressNoteComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'newRadiologyReportSpeachEnabled/:id',
    component: AddRadiologyReportPeachRecogintionEnabledComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewRadiologyReportSpeachEnabled/:id',
    component: ViewRadiologyReportPeachRecogintionEnabledComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'printInvoiceDoctorPatient/:id',
    component: PrintinvoiceDoctorPatientComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'printInvoicePatient/:id',
    component: PrintInvoicePtSideComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'printlabreport/:id',
    component: PrintlabreportComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'patientViewPastMedication/:id',
    component: PatientViewPastmedicationComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'patientViewMedicalHistory/:id',
    component: PatientViewMedicalhistoryComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'patientViewfamilyhistory/:id',
    component: PatientViewFamilyhistoryComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'patientViewsocialhistory/:id',
    component: PatientViewSocialhistoryComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'patientViewIdProof/:id',
    component: PatientViewIdproofComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'patientViewHippa/:id',
    component: PatientViewHipaacomplianceComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'patientViewNarcotic/:id',
    component: PatientViewNarcoticConsentComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'patientViewGeneralConsent/:id',
    component: PatientViewGeneralConsentComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'patientViewOfficePolicy/:id',
    component: PatientViewOfficePolicyComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'patientViewPhotographicConsent/:id',
    component: PatientViewPhotographicConsentComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addProcedure/:id',
    component: AddProcedureComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewProcedure/:id',
    component: ViewProcedureComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'updateProcedure/:id',
    component: UpdateProcedureComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'add-review-of-system/:id',
    component: AddReviewOfSystemComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'view-review-of-system/:id',
    component: ViewReviewOfSystemComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'updateReviewOfSystem/:id',
    component: UpdateReviewOfSystemComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addProcedureTestResults/:id',
    component: AddTestResultsComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewProcedureTestResults/:id',
    component: ViewTestResultsComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'updateProcedureTestResults/:id',
    component: UpdateTestResultsComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'addNerveStudyResults/:id',
    component: AddNerveStudyResultsComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewNerveStudyResults/:id',
    component: ViewNerveStudyResultsComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'updateNerveStudyResults/:id',
    component: UpdateNerveStudyResultsComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addElectromyograpgy/:id',
    component: AddElectromyographyComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewElectromyograpgy/:id',
    component: ViewElectromyographyComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'updateElectromyograpgy/:id',
    component: UpdateElectromyographyComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addNerveConductionStudies',
    component: AddNerveConductionStudyComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewNerveConductionStudies',
    component: ViewNerveConductionStudyComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'updateNerveConductionStudies/:id',
    component: UpdateNerveConductionStudyComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'newOrders/:id',
    component: NewOrdersComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewOrders/:id',
    component: ViewOrdersComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewAllTestResults/:id',
    component: AllTestResultsComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'completedForms/:id',
    component: CompletedFormsComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'bulkImportDatas',
    component: BulkImportPatientComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'invoiceBalances/:id',
    component: InvoiceHasABalanceComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewPatientPayment/:id',
    component: ViewPatientPaymentComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'ViewPatientPaymentByHospital/:id',
    component: ViewPatientPaymentByHospitalComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewPaymentHistory/:id',
    component: ViewPaymentHistoryComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'doctorPatientInvoices/:id',
    component: DoctorPatientInvoicesComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'AppointmentStatus/:id',
    component: ViewAppointmentStatusComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'manualyConfirmAppointment/:id',
    component: ManualyConfirmAppointmentComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'splitScreenDctorPatient/:id',
    component: DoctorPatientSplitScreenComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'splitView/:id',
    component: DoctorPatientSplitViewComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'callEncounter/:id',
    component: CallEncounterComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'doctorPersonalTmplte/:id',
    component: DoctorCustomTemplateComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'doctorTemplte/:id',
    component: DoctorTemplateEditorComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'updatedoctorTemplte/:id',
    component: UpdateDoctorTemplateEditorComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewDoctorTemplte/:id',
    component: DoctorTemplateListComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'nurseTemplte/:id',
    component: AddNurseTemplatesComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'updateNurseTemplate/:id',
    component: UpdateNurseTemplatesComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewNurseTemplte/:id',
    component: ViewNurseTemplatesComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addHospitalTemplte/:id',
    component: HospitalTemplateComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'updateHospitalTemplate/:id',
    component: UpdateHospitaltemplateComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewHospital Templte/:id',
    component: ViewHospitaltemplateComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'patientProgressNote/:id',
    component: PatientSideProgressNoteComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'signature/:id',
    component: SignatureComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'Roster',
    component: RosterComponent,
    // canActivate: [AuthguardGuardService],
  },
  {
    path: 'Roster',
    component: RosterComponent,
    // canActivate: [AuthguardGuardService],
  },
  {
    path: 'Roster',
    component: RosterComponent,
    // canActivate: [AuthguardGuardService],
  },
  {
    path: 'AddRoster',
    component: AddRosterComponent,
    // canActivate: [AuthguardGuardService],
  },
  {
    path: 'UpdateRoster',
    component: UpdateRosterComponent,
    // canActivate: [AuthguardGuardService],
  },
  {
    path: 'Add-Duty',
    component: AddDutyComponent,
    // canActivate: [AuthguardGuardService],
  },
  {
    path: 'View-Duty',
    component: ViewDutyComponent,
    // canActivate: [AuthguardGuardService],
  },
  {
    path: 'UpdateDuty',
    component: UpdateDutyComponent,
    // canActivate: [AuthguardGuardService],
  },
  {
    path: 'drawTool',
    component: DrawToolComponent,
    // canActivate: [AuthguardGuardService],
  },
  {
    path: 'DrugInfo',
    component: DrugInfoComponent,
    // canActivate: [AuthguardGuardService],
  },

  {
    path: 'tabs',
    component: TabsComponentComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'message',
    component: MessageComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'payment/:id',
    component: PaymentComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'qrCodeScannerDoctor',
    component: QrScannerComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'qrCodeScannerNurse',
    component: QrScannerNurseComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'tabs2',
    component: Tabs2ComponentComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'tabs1Patient',
    component: Tab1PatientComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'tabs2Patient',
    component: Tab2PatientComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'tabs1Biller',
    component: Tab1BillerComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'tabs2Biller',
    component: Tab2BillerComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'tabsNurse',
    component: TabNurseSideComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'tabs2Nurse',
    component: Tab2NurseSideComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'tabs2Clinic',
    component: Tab2ClinicSideComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'tabsClinic',
    component: TabClinicSideComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'clinicalAssistance',
    component: ClinicalAssistanceComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'fax',
    component: FaxComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'ActivityLogs',
    component: UserActivityLogComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'chat/:id',
    component: ChatComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'chatForNurse/:id',
    component: ChatForNurseComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'chatForPatient/:id',
    component: ChatForPatientComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'split-a/:id',
    component: SplitAComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'split-b/:id',
    component: BillerSplitBComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'split-a-Patient/:id',
    component: SplitAPatientComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'split-b-Patient/:id',
    component: SplitBPatientComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'billerSplit-b/:id',
    component: SplitBComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'billerSplit-a/:id',
    component: BillerSplitAComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'billerPatient/:id',
    component: BillerPatientComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'Nurse-Side-split-a/:id',
    component: NurseSideSplitAComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'Nurse-Side-split-b/:id',
    component: NurseSideSplitBComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'AddServiceRequest/:id',
    component: AddServiceRequestComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'ViewServiceRequest',
    component: ViewServiceRequestComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'UpdateServiceRequest/:id',
    component: UpdateServiceRequestComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'calculator',
    component: CalculatorComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'videoCall',
    component: VideoCallComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'formBuilder',
    component: FormBuilderComponent,
    // canActivate: [AuthguardGuardService],
  },
  {
    path: 'claimAccountKey/:id',
    component: AddClaimAccountKeyComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'update-claim-account-key/:id',
    component: UpdateClaimAccountKeyComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'view-claim-account-key/:id',
    component: ViewClaimAccountKeyComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'claimsAttachment/:id',
    component: ClaimAttachmentComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'claims/:id',
    component: ClaimComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'updateclaims/:id',
    component: UpdateClaimComponent,
    // canActivate: [AuthguardGuardService],
  },
  {
    path: 'claimStatus',
    component: ClaimStatusComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'eraList',
    component: EraListComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'cms1500',
    component: Cms1500Component,
    // canActivate: [AuthguardGuardService],
  },
  {
    path: 'providerEnrollment',
    component: ProviderEnrollmentComponent,
    // canActivate: [AuthguardGuardService],
  },
  {
    path: 'claimNotes',
    component: ClaimNotesComponent,
    // canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewCms1500',
    component: ViewCms1500Component,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'updateCms1500',
    component: UpdateCms1500Component,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'Subscriptions',
    component: SubscriptionComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'SubscriptionPlan',
    component: SubscriptionPlanComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'AllSubscriptions',
    component: AllSubscriptionsComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'h-template',
    component: HospitalTemplateComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'templateText',
    component: TemplateTextboxComponentComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'eligibilityCheck',
    component: EligibilityCheckComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'eligibilityCheckMedicine',
    component: EligibilityCheckMedicineComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'voicetoText/:id',
    component: VoiceToTextComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'audit-logs',
    component: AuditLogComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
  },
  { path: 'twofa', component: TwofaComponent },
  { path: 'twofaDEA', component: TwofaDeaComponent },
  { path: 'setpassword', component: SetpasswordComponent },
  { path: 'resetpassword', component: ResetpasswordComponent },
  { path: 'resetTwofa', component: TwofaResetPassowrdComponent },
  {
    path: 'emergencyAccessTwofa/:UserName',
    component: TwoFaEmergencyAccessComponent,
  },
  {
    path: 'emergencyAccessPhoneAuthentication',
    component: EmergencyAccessPhoneAuthComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
