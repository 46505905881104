import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Patient } from 'src/app/Models/patient.model';
import { Payment } from 'src/app/Models/payment.model';
import { HospitalService } from 'src/app/services/hospital.service';
import { NurseNoteService } from 'src/app/services/nurse-note.service';
import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-view-patient-payment-by-hospital',
  templateUrl: './view-patient-payment-by-hospital.component.html',
  styleUrls: ['./view-patient-payment-by-hospital.component.css'],
})
export class ViewPatientPaymentByHospitalComponent implements OnInit {
  displayedColumns: string[] = [
    'Date',
    'PaymentType',
    'TransactionId',
    'Amount',
    'View',
  ];

  id = 0;
  payment: Payment[] = [];
  dataSource: MatTableDataSource<any>;
  labDocument: string = '';
  patient: Patient;

  constructor(
    private hospitalSeriviceService: HospitalService,
    private nurseNoteService: NurseNoteService,
    private route: ActivatedRoute,
    private router: Router,
    private tokenService: TokenService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });

    this.getPayment(this.id);
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  // ngAfterViewInit() {
  //   this.dataSource.paginator = this.paginator;
  //   this.dataSource.sort = this.sort;
  // }

  getPayment(id) {
    this.hospitalSeriviceService.getPatientPayment(id).subscribe((res) => {
      this.dataSource = new MatTableDataSource(
        res.Payments.sort(function (x, y) {
          return y.PaymentId - x.PaymentId;
        })
      );
      this.dataSource.paginator = this.paginator;
    });
  }
  delete(id) {
    this.nurseNoteService.deleteNurseNote(id).subscribe((res) => {
      this.getPayment(this.id);
    });
  }
  print() {
    window.print();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  public viewReport(report: Payment): void {
    // console.log('report: ', report);
  }
}
