<div class="dialog-header">
    <button mat-raised-button color="primary" class="close-button" (click)="onClose()">Close</button>
</div>
<div class="container" cdkDrag cdkDragRootElement=".cdk-overlay-pane">
    <h2 class="text-center">Update Service Request</h2>
    <form [formGroup]="serviceRequestForm" (ngSubmit)="onSubmit()">
        <div class="row">
            <!-- Ordered By -->
            <div class="col-md-6">
                <mat-form-field appearance="fill" class="w-100">
                    <mat-label>Ordered By</mat-label>
                    <input matInput formControlName="orderedBy" placeholder="Practitioner Name" />
                    <mat-error *ngIf="serviceRequestForm.get('orderedBy')?.hasError('required')">
                        Ordered By is required
                    </mat-error>
                </mat-form-field>
            </div>

            <!-- Subject -->
            <div class="col-md-6">
                <mat-form-field appearance="fill" class="w-100">
                    <mat-label>Subject</mat-label>
                    <input matInput formControlName="subject" placeholder="Patient Name" />
                    <mat-error *ngIf="serviceRequestForm.get('subject')?.hasError('required')">
                        Subject is required
                    </mat-error>
                </mat-form-field>
            </div>
        </div>

        <div class="row mt-3">
            <!-- Category -->
            <div class="col-md-6">
                <mat-form-field appearance="fill" class="w-100">
                    <mat-label>Category</mat-label>
                    <mat-select formControlName="category" (selectionChange)="onCategoryChange()">
                        <mat-option *ngFor="let category of categories" [value]="category">
                            {{ category }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="serviceRequestForm.get('category')?.hasError('required')">
                        Category is required
                    </mat-error>
                </mat-form-field>

            </div>

            <!-- Scheduled Date -->
            <div class="col-md-6">
                <mat-form-field appearance="fill" class="w-100">
                    <mat-label>Scheduled Date</mat-label>
                    <input matInput type="date" formControlName="scheduledDate" />
                    <mat-error *ngIf="serviceRequestForm.get('scheduledDate')?.hasError('required')">
                        Scheduled Date is required
                    </mat-error>
                </mat-form-field>
            </div>
        </div>

        <!-- Items Section -->
        <div formArrayName="items" class="mt-4">
            <div *ngFor="let item of items.controls; let i = index" [formGroupName]="i" class="border rounded p-3 mb-3">
                <h5>Item {{ i + 1 }}</h5>
                <div class="row">
                    <!-- Name Dropdown -->
                    <div class="col-md-4">
                        <mat-form-field appearance="fill" class="w-100">
                            <mat-label>Item Name</mat-label>
                            <mat-select formControlName="name" (selectionChange)="onItemSelected(i, $event.value)">
                                <mat-option *ngFor="let option of filteredItems" [value]="option.name">
                                    {{ option.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <!-- Code Field -->
                    <div class="col-md-4">
                        <mat-form-field appearance="fill" class="w-100">
                            <mat-label>Code</mat-label>
                            <input matInput formControlName="code" placeholder="Test Code" readonly />
                        </mat-form-field>
                    </div>

                    <!-- Comments Field -->
                    <div class="col-md-4">
                        <mat-form-field appearance="fill" class="w-100">
                            <mat-label>Comments</mat-label>
                            <input matInput formControlName="comments" placeholder="Optional Comments" />
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <mat-form-field appearance="fill" class="w-100">
                    <mat-label>Status</mat-label>
                    <input matInput formControlName="status" placeholder="Status" />

                </mat-form-field>
            </div>
        </div>
        <div class="text-end mt-4">
            <button class="float-right" mat-raised-button color="primary" type="submit">
                Update Service Request
            </button>
        </div>
    </form>

    <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
        Successfully Updated
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
</div>