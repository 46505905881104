import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Nurse } from 'src/app/Models/nurse.model';
import { NurseReport } from 'src/app/Models/nursereport.model';
import { DoctorService } from 'src/app/services/doctor.service';
import { NurseService } from 'src/app/services/nurse.service';
import { NursereportService } from 'src/app/services/nursereport.service';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-update-nursereport',
  templateUrl: './update-nursereport.component.html',
  styleUrls: ['./update-nursereport.component.css'],
})
export class UpdateNursereportComponent implements OnInit {
  id = 0;
  helper = new JwtHelperService();
  myToken = this.tokenService.getToken();
  nurse: Nurse;
  options: string[] = ['Morning', 'Afternoon', 'Evening', 'Night'];
  NurseId: number = 0;
  CurrentNurseId: number;
  nurseReports: NurseReport[];
  CurrentDoctorId: number;
  doctor: import('d:/Centralised_Neurology_ProgressiveApp/Neurology/src/app/Models/doctor.model').Doctor;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { NurseReportId: number },
    public dialogRef: MatDialogRef<UpdateNursereportComponent>,
    private fb: UntypedFormBuilder,
    private nurseReportService: NursereportService,
    private router: Router,
    private route: ActivatedRoute,
    private nurseService: NurseService,
    private doctorService: DoctorService,
    private tokenService: TokenService
  ) {}
  date;

  ngOnInit(): void {
    this.date = new Date();
    let decodedToken = this.helper.decodeToken(this.myToken);

    if (decodedToken.role === 'Nurse') {
      this.CurrentNurseId = parseInt(decodedToken.nameid, 10);
    } else if (decodedToken.role === 'Doctor') {
      this.CurrentDoctorId = parseInt(decodedToken.nameid, 10);
    }
    this.getNurse(this.CurrentNurseId);
    this.getDoctor(this.CurrentDoctorId);
    // this.route.params.subscribe((res) => {
    //   this.id = res['id'];
    // });
    this.id = this.data.NurseReportId;
    this.nurseReportService.getNurseReportById(this.id).subscribe((res) => {
      this.NurseId = res.NurseId;
      this.NurseName.setValue(res.NurseName);
      this.DoctorName.setValue(res.DoctorName);
      this.Date.setValue(res.createdDate);
      // this.shortcut.setValue(res.shortcut);
      this.Time.setValue(res.Time);
      this.Comments.setValue(res.Comments);
      this.Signature.setValue(res.Signature);
      this.Respiration.setValue(res.Respiration);
      this.HeartBeat.setValue(res.HeartBeat);
      this.Temparature.setValue(res.Temparature);
      this.BloodPressure.setValue(res.BloodPressure);
      this.BloodPressure.setValue(res.BloodPressure);
      this.OxygenSaturation.setValue(res.OxygenSaturation);
    });
  }

  medFrm = this.fb.group({
    NurseName: [''],
    DoctorName: [''],
    // shortcut: [''],
    Date: [''],
    Time: [''],
    Comments: [''],
    Signature: [''],
    Respiration: [''],
    Temparature: [''],
    HeartBeat: [''],
    BloodPressure: [''],
    OxygenSaturation: [''],
  });

  get Respiration() {
    return this.medFrm.get('Respiration');
  }
  get Temparature() {
    return this.medFrm.get('Temparature');
  }
  get HeartBeat() {
    return this.medFrm.get('HeartBeat');
  }
  get BloodPressure() {
    return this.medFrm.get('BloodPressure');
  }
  get OxygenSaturation() {
    return this.medFrm.get('OxygenSaturation');
  }
  get NurseName() {
    return this.medFrm.get('NurseName');
  }
  get DoctorName() {
    return this.medFrm.get('DoctorName');
  }

  get Date() {
    return this.medFrm.get('Date');
  }
  get Time() {
    return this.medFrm.get('Time');
  }
  get Comments() {
    return this.medFrm.get('Comments');
  }
  get Signature() {
    return this.medFrm.get('Signature');
  }
  get shortcut() {
    return this.medFrm.get('shortcut');
  }

  getNurse(id) {
    this.nurseService.getNurseById(id).subscribe((res) => {
      this.nurse = res;
      this.NurseName.setValue(res.NurseName);
      // this.Signature.setValue(res.NurseName);
    });
  }
  getDoctor(id) {
    this.doctorService.getDoctorById(id).subscribe((res) => {
      this.doctor = res;
      this.DoctorName.setValue(res.DoctorName);
      // this.Signature.setValue(res.NurseName);
    });
  }

  formSubmit() {
    let nurseName = this.medFrm.value['NurseName'];

    let date = this.medFrm.value['Date'];

    let time = this.medFrm.value['Time'];

    let Shortcut = this.medFrm.value['shortcut'];
    let respiration = this.medFrm.value['Respiration'];
    let temparature = this.medFrm.value['Temparature'];
    let heartBeat = this.medFrm.value['HeartBeat'];
    let bloodPressure = this.medFrm.value['BloodPressure'];
    let oxygenSaturation = this.medFrm.value['OxygenSaturation'];
    let comments = this.medFrm.value['Comments'];
    let signature = this.medFrm.value['Signature'];
    let nurseId = this.medFrm.value['NurseId'];
    // let idoctorName = this.nurseList.filter(it => it.NurseId == nurseId)[0].NurseName;

    let body = {
      Comments: comments,
      Date: date,
      Time: time,
      NurseName: nurseName,
      shortcut: Shortcut,
      Signature: signature,
      Respiration: respiration,
      Temparature: temparature,
      HeartBeat: heartBeat,
      BloodPressure: bloodPressure,
      OxygenSaturation: oxygenSaturation,
    };

    this.nurseReportService
      .updateNurseReport(this.id, body)
      .subscribe((res) => {
        alert('Successfully Updated ');
        this.onClose();
      });
  }
  onClose(): void {
    this.dialogRef.close();
  }
}
