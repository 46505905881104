<div>
    <div>
        <app-tab-clinic-side [tabsArray]="tabs" (onTabChange)="tabChange2($event)"></app-tab-clinic-side>
    </div>

    <div *ngIf="activatedTabIndex2 ==0">
        <app-completed-forms></app-completed-forms>


    </div>
    <div *ngIf="activatedTabIndex2 ==1">
        <app-patientsummary></app-patientsummary>


    </div>
    <div *ngIf="activatedTabIndex2 ==2">
        <app-view-appointment></app-view-appointment>


    </div>
    <div *ngIf="activatedTabIndex2 ==3">
        <app-view-invoice></app-view-invoice>


    </div>
    <div *ngIf="activatedTabIndex2 ==4">
        <app-view-payment-history></app-view-payment-history>


    </div>
    <div *ngIf="activatedTabIndex2 ==5">
        <app-view-patient-insurance></app-view-patient-insurance>


    </div>
    <div *ngIf="activatedTabIndex2 ==6">
        <app-view-patient-idproof></app-view-patient-idproof>


    </div>
    <div *ngIf="activatedTabIndex2 ==7">
        <app-emergency-contact></app-emergency-contact>


    </div>
    <div *ngIf="activatedTabIndex2 ==8">
        <app-view-patient-pharmacy></app-view-patient-pharmacy>

    </div>

    <div *ngIf="activatedTabIndex2 ==9">
        <app-view-allergy></app-view-allergy>

    </div>
    <div *ngIf="activatedTabIndex2 ==10">
        <app-view-surgical-history></app-view-surgical-history>

    </div>
    <div *ngIf="activatedTabIndex2 ==11">
        <app-view-refferal></app-view-refferal>

    </div>

    <div *ngIf="activatedTabIndex2 ==12">
        <app-view-vaccine></app-view-vaccine>

    </div>


    <div *ngIf="activatedTabIndex2 ==13">
        <app-view-pastmedication></app-view-pastmedication>

    </div>
    <div *ngIf="activatedTabIndex2 ==14">
        <app-view-pain-chart></app-view-pain-chart>

    </div>

    <div *ngIf="activatedTabIndex2 ==15">
        <app-staff-view-prescriptiondisplay></app-staff-view-prescriptiondisplay>

    </div>
    <div *ngIf="activatedTabIndex2 ==16">
        <app-patientdetails></app-patientdetails>


    </div>

    <div *ngIf="activatedTabIndex2 ==17">

        <app-view-visit-summary-patient-side></app-view-visit-summary-patient-side>


    </div>
    <div *ngIf="activatedTabIndex2 ==18">

        <app-patient-side-progress-note></app-patient-side-progress-note>


    </div>
</div>