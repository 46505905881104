import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { DoctorInitialEvaluation } from 'src/app/Models/doctorinitialevaluation.model';
import { PurposefullRound } from 'src/app/Models/purposefullround.model';
import { DoctorInitialEvaluationService } from 'src/app/services/doctor-initial-evaluation.service';
import { PatientService } from 'src/app/services/patient.service';

import { TokenService } from 'src/app/services/token.service';
import { UpdateDoctorInitialEvaluationComponent } from '../update-doctor-initial-evaluation/update-doctor-initial-evaluation.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-view-doctor-initial-evaluation',
  templateUrl: './view-doctor-initial-evaluation.component.html',
  styleUrls: ['./view-doctor-initial-evaluation.component.css'],
})
export class ViewDoctorInitialEvaluationComponent implements OnInit {
  helper = new JwtHelperService();
  myToken = this.tokenService.getToken();
  id = 0;
  evaluationList: DoctorInitialEvaluation[] = [];
  CurrentDoctorId: number;

  constructor(
    private route: ActivatedRoute,
    private patientService: PatientService,
    private doctorInitialEvaluationService: DoctorInitialEvaluationService,
    private tokenService: TokenService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.CurrentDoctorId = parseInt(decodedToken.nameid);
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });

    this.getPatientInitialEvaluation(this.id);
  }

  getPatientInitialEvaluation(id) {
    this.patientService.getDoctorEvaluation(id).subscribe((res) => {
      this.evaluationList = res.DoctorInitialEvaluations.sort(function (x, y) {
        return y.DoctorInitialEvaluationId - x.DoctorInitialEvaluationId;
      });
    });
  }

  print() {
    window.print();
  }

  delete(id) {
    this.doctorInitialEvaluationService
      .deleteDoctorInitialEvaluation(id)
      .subscribe((res) => {
        this.getPatientInitialEvaluation(this.id);
      });
  }

  update(DoctorInitialEvaluationId: number): void {
    const dialogRef = this.dialog.open(UpdateDoctorInitialEvaluationComponent, {
      width: '50%',
      height: '80%',
      disableClose: true, // Prevent closing without confirmation
      hasBackdrop: false, // Disable the backdrop
      panelClass: 'custom-dialog-container', // Optional custom styling
      data: { DoctorInitialEvaluationId }, // Pass data to the dialog
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('Dialog closed:', result);
      // Handle any cleanup or actions after the dialog is closed
    });
  }
}
