<div class="dialog-header">
  <button mat-raised-button color="primary" class="close-button" (click)="onClose()">Close</button>
</div>
<div class="container" cdkDrag cdkDragRootElement=".cdk-overlay-pane">

  <div class="row mt-4">

    <div class="col-lg regFrm mat-elevation-z8">
      <h1>Update Report</h1>
      <form (keydown.enter)="$event.preventDefault()" [formGroup]="Frm" (ngSubmit)="formSubmit()" class="example-form">
        <mat-form-field class="example-full-width">
          <mat-label>Report Name</mat-label>
          <!-- <input type="text" formControlName="ReportName" matInput> -->
          <input type="text" matInput formControlName="ReportName" [matAutocomplete]="auto5"> <mat-icon
            matSuffix>search</mat-icon>
          <mat-autocomplete #auto5="matAutocomplete">
            <mat-option *ngFor="let dos of filteredReportName | async" [value]="dos">
              {{ dos }}
            </mat-option>
          </mat-autocomplete>
          <mat-error>Please enter Report Name</mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
          <mat-label>Shortcut</mat-label>
          <!-- <input type="text" formControlName="ReportName" matInput> -->
          <input type="text" matInput formControlName="shortcut" [matAutocomplete]="auto6"> <mat-icon
            matSuffix>search</mat-icon>
          <mat-autocomplete #auto6="matAutocomplete">
            <mat-option *ngFor="let dos of filteredShortcut | async" [value]="dos">
              {{ dos }}
            </mat-option>
          </mat-autocomplete>
          <mat-error>Please enter Report Name</mat-error>
        </mat-form-field>
        <!-- Quill Editor Container -->

        <div formControlName="ReportDescription" #editorContainer id="editor" class="editor-container"></div>

        <!-- CPT Code Suggestions Dropdown -->
        <!-- <div *ngIf="suggestions.length > 0" class="cpt-suggestions">
                  <ul>
                      <li *ngFor="let ss of suggestions" (click)="onSelectCptCode(ss)">
                          {{ ss.code }}: {{ ss.description }}
                      </li>
                  </ul>
              </div> -->
        <button type="button" mat-raised-button color="primary" (click)="startVoiceRecognition()">Start Voice
          Recognition</button>
        <button type="button" class="mx-5" type="button" color="accent" (click)="stopRecognition()"
          mat-raised-button>Stop</button>


        <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
          Successfully submitted
          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <button class="float-right lgBtCl" mat-button>
          <mat-icon>save</mat-icon>
          Sign & Save</button>

      </form>

    </div>
  </div>
</div>