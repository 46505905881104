<div>
    <div>
        <app-tab2-biller [tabsArray]="tabs2" (onTabChange)="tabChange2($event)"></app-tab2-biller>
    </div>





    <div *ngIf="activatedTabIndex2 ==0">

        <app-add-invoice></app-add-invoice>

    </div>

    <div *ngIf="activatedTabIndex2 ==1">

        <app-claim></app-claim>

    </div>
    <div *ngIf="activatedTabIndex2 ==2">

        <app-claim-attachment></app-claim-attachment>

    </div>




</div>