<div class="ml-3">
    <div class="row">
        <div class="col-lg-6 float-right">
            <mat-form-field class="example-full-width mt-5">
                <mat-label>Filter</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Ex.Mia" #input>
            </mat-form-field>
        </div>
    </div>
</div>
<div class=" mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" matSort>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef> Actions </th>
            <td mat-cell *matCellDef="let row">
                <button mat-icon-button type="button" (click)="copyRowData(row)">
                    <mat-icon>content_copy</mat-icon>
                </button>
            </td>
        </ng-container>


        <ng-container matColumnDef="Date">
            <th mat-header-cell *matHeaderCellDef> Date </th>
            <td mat-cell *matCellDef="let row"> {{row.createdDate | date:'medium'}} </td>
        </ng-container>
        <ng-container matColumnDef="ReportName">
            <th mat-header-cell *matHeaderCellDef> Report Name </th>
            <td mat-cell *matCellDef="let row"> {{row.ReportName}} </td>
        </ng-container>
        <ng-container matColumnDef="ChiefComplaint">
            <th mat-header-cell *matHeaderCellDef> ChiefComplaint </th>
            <td mat-cell *matCellDef="let row"> {{row.ChiefComplaint}} </td>
        </ng-container>

        <ng-container matColumnDef="DoctorName">
            <th mat-header-cell *matHeaderCellDef> Physician </th>
            <td mat-cell *matCellDef="let row"> {{row.DoctorName}} </td>
        </ng-container>
        <!-- <ng-container matColumnDef="Decription">
        <th mat-header-cell *matHeaderCellDef> Decription </th>
        <td mat-cell *matCellDef="let row" [innerHTML]="row.ReportDescription"> </td>
      </ng-container>  -->

        <ng-container matColumnDef="Signature">
            <th mat-header-cell *matHeaderCellDef> Signed </th>
            <td mat-cell *matCellDef="let row"> {{row.Status?'Yes':'No'}} </td>
        </ng-container>


        <ng-container matColumnDef="View">
            <th mat-header-cell *matHeaderCellDef> View </th>
            <td mat-cell *matCellDef="let row">
                <button class="btn mybtn" (click)="modal1.show();viewReport(row) " mat-mini-fab
                    color="primary"><mat-icon>visibility</mat-icon></button>&nbsp;

                <app-modal #modal1 class="panel-demo1" [maximizable]="true" [backdrop]="false">
                    <ng-container class="app-modal-header">Patient Name: {{patient?.PatientFirstName}} &nbsp;
                        {{patient?.PatientLastName}}</ng-container>
                    <ng-container class="app-modal-body">
                        <div class="row ">

                            <div class=" col-lg float-right send-email-prescription d-flex align-items-baseline ">

                                <mat-form-field class="w-100 mr-3" appearance="outline">
                                    <mat-label>Enter Email to send records</mat-label>
                                    <input matInput type="text" [formControl]="email" placeholder="Enter email">
                                </mat-form-field>
                                <button mat-raised-button color="primary" class="mx-5" (click)="send()">Send</button>
                                <br>
                                <button mat-mini-fab color="mute"
                                    (click)="downloadAsPDF()"><mat-icon>download</mat-icon></button>

                            </div>
                        </div>
                        <div id="printable" #PatientVisit>

                            <app-view-hospital></app-view-hospital>

                            <div class="row">

                                <div class="col-lg-3"><span class="bld">MRN:</span>
                                    {{patient?.PatientId}}</div> |
                                <div class="col-lg-3">
                                    <span class="bld">Patient Name:</span> {{patient?.PatientFirstName}}
                                    {{patient?.PatientLastName}}
                                </div>|
                                <div class="col-lg-4"><span class="bld">Date Of Birth:</span> {{patient?.PatientDOB}}
                                </div>
                                <!-- <div class="col-lg-2"><span class="bld">SSN:</span> {{patient?.PatientNationalId}}</div>
                                <div class="col-lg-3"><span class="bld">Address:</span> {{patient?.PatientAddress}}
                                </div> -->
                            </div>
                            <hr>
                            <h1 class="text-center" style="font-weight:bold ;">After Visit Summary</h1>

                            <div class="row">
                                <!-- <div class="col-lg-3"><span class="bld">Visit Date:</span> {{row?.VistitDate | date
                                    :'short'}}
                                </div> -->
                                <!-- <div class="col-lg-3"> <span class="bld">Provider Name:</span> {{row?.DoctorName}}</div> -->
                                <div class="col-lg-12"> <span class="bld">You visited Dr.{{row?.DoctorName}} on
                                        {{row?.VistitDate | date
                                        :'short'}}following concerns where addressed</span>
                                    {{row?.ChiefComplaint}}</div>

                                <!-- <div class="col-lg-4"><span class="bld">Visit Time:</span> {{row?.VistTime}}</div> -->
                                <!-- <div class="col-lg-3"><span class="bld">Report Id :</span> {{row?.VisitSummaryId}}</div>
                                <div class="col-lg-3"><span class="bld">Report Date :</span> {{row?.createdDate| date
                                    :'short'}}</div> -->
                            </div>
                            <hr>


                            <!-- <p>Decription: {{reportDetails}}</p> -->
                            <div class="mt-2" [innerHTML]="reportDetails"></div>


                            <div class="row">
                                <div class="col-lg-3"> <span class="bld">Follow Up Instructions:</span>
                                    {{row?.FollowUpInstructions}}</div>

                                <div class="col-lg-3"><span class="bld">Next Follow Up Date:</span> {{row?.NextFollowUp
                                    | date
                                    :'short'}}
                                </div>

                            </div>
                            <hr>
                            <p class="mt-5">Signed By: {{row.DoctorName}}</p>
                            <p>Date: {{row.createdDate | date:'short'}}</p>

                        </div>
                    </ng-container>
                    <ng-container class="app-modal-footer">
                        <button type="button" (click)="print()" class="btn btn-primary ">Print</button>

                        <button class="dt-button dt-blue" style="float: right;" (click)="modal1.hide()">Close</button>
                    </ng-container>
                </app-modal>

            </td>
        </ng-container>
        <!-- modal  -->


        <ng-container matColumnDef="Update">
            <th mat-header-cell *matHeaderCellDef> Edit </th>
            <td mat-cell *matCellDef="let row">
                <button type="button" (click)="updateReport(row.VisitSummaryId,$event)" mat-mini-fab
                    [disabled]="CurrentDoctorId != row.DoctorId" color="mute">
                    <mat-icon matPrefix>edit</mat-icon>
                </button>
                <!-- <button type="button" [routerLink]="['/updateDoctorReport/',row.DoctorReportId,this.id]" mat-mini-fab
            [disabled]="CurrentDoctorId != row.DoctorId" color="mute">
            <mat-icon matPrefix>edit</mat-icon>
          </button> -->
            </td>
        </ng-container>
        <ng-container matColumnDef="Delete">
            <th mat-header-cell *matHeaderCellDef> Delete</th>
            <td mat-cell *matCellDef="let row">
                <button (click)="delete(row.VisitSummaryId)" [disabled]="CurrentDoctorId != row.DoctorId" mat-mini-fab
                    color="accent"><mat-icon>delete</mat-icon></button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
        </tr>
    </table>

    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100,150,200,500,750,1000]"></mat-paginator>
</div>



<!-- 
  <ng-container matColumnDef="View">
    <th mat-header-cell *matHeaderCellDef> Details </th>
    <td mat-cell *matCellDef="let row">
      <button type="button" (click)="detailView(row.DoctorReportId,$event)" mat-mini-fab color="mute">
        <mat-icon matPrefix>visibility</mat-icon>
      </button>
  
    </td>
  </ng-container> -->