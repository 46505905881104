import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { RxNormApiService } from 'src/app/services/rx-norm-api.service';

@Component({
  selector: 'app-drug-info',
  templateUrl: './drug-info.component.html',
  styleUrls: ['./drug-info.component.css'],
})
export class DrugInfoComponent implements OnInit {
  drugForm: FormGroup;
  dosageDetails: string[] = [];
  loading: boolean = false;

  constructor(
    private fb: FormBuilder,
    private rxNormApiService: RxNormApiService
  ) {
    this.drugForm = this.fb.group({
      drug: [''],
    });
  }

  ngOnInit(): void {}

  // Search for drugs and fetch dosage details
  searchDrug() {
    const searchTerm = this.drugForm.get('drug')?.value;

    if (searchTerm.trim()) {
      this.loading = true;
      this.dosageDetails = [];

      // Step 1: Search for approximate matches
      this.rxNormApiService.searchDrug(searchTerm).subscribe(
        (response: any) => {
          if (
            response &&
            response.approximateGroup &&
            response.approximateGroup.candidate
          ) {
            const firstRxCui = response.approximateGroup.candidate[0]?.rxcui;

            if (firstRxCui) {
              // Step 2: Fetch dosage details for the first RxCUI
              this.fetchDosageDetails(firstRxCui);
            } else {
              this.loading = false;
            }
          } else {
            this.loading = false;
          }
        },
        (error) => {
          console.error('Error fetching drug data:', error);
          this.loading = false;
        }
      );
    }
  }

  // Fetch dosage details for a specific RxCUI
  fetchDosageDetails(rxCui: string) {
    this.rxNormApiService.getRelatedDrugs(rxCui).subscribe(
      (response: any) => {
        if (
          response &&
          response.relatedGroup &&
          response.relatedGroup.conceptGroup
        ) {
          this.dosageDetails = response.relatedGroup.conceptGroup
            .flatMap((group: any) => group.conceptProperties || [])
            .map((property: any) => property.name); // Extract the full dosage detail name
        }
        this.loading = false;
      },
      (error) => {
        console.error('Error fetching dosage details:', error);
        this.loading = false;
      }
    );
  }

  // Handle drug selection
  onDrugSelected(event: any) {
    const selectedDrug = event.option.value;
    this.drugForm.patchValue({ drug: selectedDrug });
  }
}
