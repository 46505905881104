import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { DoctorReport } from 'src/app/Models/doctorreport.model';
import { NurseReport } from 'src/app/Models/nursereport.model';
import { Patient } from 'src/app/Models/patient.model';
import { RadiologyMri } from 'src/app/Models/radiologymri.model';
import { UploadLabReport } from 'src/app/Models/uploadlabreport.model';
import { DoctorReportService } from 'src/app/services/doctor-report.service';
import { PatientService } from 'src/app/services/patient.service';
import { TokenService } from 'src/app/services/token.service';
import { UpdateDoctorReportComponent } from '../update-doctor-report/update-doctor-report.component';
import { HospitalService } from 'src/app/services/hospital.service';

@Component({
  selector: 'app-view-doctor-report-details',
  templateUrl: './view-doctor-report-details.component.html',
  styleUrls: ['./view-doctor-report-details.component.css'],
})
export class ViewDoctorReportDetailsComponent implements OnInit {
  heartRateChart: any = {
    series: [
      {
        name: 'Heart Rate',
        data: [],
      },
    ],
    title: {
      text: 'Heart Rate Chart',
    },
    chart: {
      type: 'line',
    },
    xAxis: {
      type: 'datetime',
    },
  };

  respirationChart: any = {
    series: [
      {
        name: 'Respiration Rate',
        data: [],
      },
    ],
    title: {
      text: 'Respiratory Chart',
    },
    chart: {
      type: 'line',
    },
    xAxis: {
      type: 'datetime',
    },
  };

  tempratureChart: any = {
    series: [
      {
        name: 'Temprature',
        data: [],
      },
    ],
    title: {
      text: 'Temprature Chart',
    },
    chart: {
      type: 'line',
    },
    xAxis: {
      type: 'datetime',
    },
  };

  bloodPressureChart: any = {
    series: [
      {
        name: 'Systolic',
        data: [],
      },
      {
        name: 'Diastolic',
        data: [],
      },
    ],
    title: {
      text: 'Blood Pressure Chart',
    },
    chart: {
      type: 'line',
    },
    xAxis: {
      categories: [],
    },
  };
  OxygenSaturationChart: any = {
    series: [
      {
        name: 'Oxygen Saturation',
        data: [],
      },
    ],
    title: {
      text: 'Oxygen Saturation Chart',
    },
    chart: {
      type: 'line',
    },
    xAxis: {
      type: 'datetime',
    },
  };
  id;
  report: DoctorReport;
  patient: Patient;
  hospital: import('d:/Centralised_Neurology_ProgressiveApp/Neurology/src/app/Models/hospital.model').Hospital;
  nurseReports: NurseReport[];
  constructor(
    private patientService: PatientService,
    private doctorReportService: DoctorReportService,
    private route: ActivatedRoute,
    private router: Router,
    private tokenService: TokenService,
    private dialog: MatDialog,
    private hospitalService: HospitalService,
    private dialogRef: MatDialogRef<ViewDoctorReportDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { id: number }
  ) {}

  ngOnInit(): void {
    // this.route.params.subscribe((res) => {
    //   this.id = res['id'];
    // });
    this.GetReports(this.data.id);
  }

  GetReports(id) {
    this.doctorReportService.getDoctorReportById(id).subscribe((res) => {
      this.report = res;
      this.getPatient(res.PatientId);
      this.getHospitalId(res.PatientId);
      this.getNurseReport(res.PatientId);
    });
  }

  getPatient(id) {
    this.patientService.getPatientById(id).subscribe((res) => {
      this.patient = res;
    });
  }
  getHospitalId(id) {
    this.patientService.getpatienHospitalId(id).subscribe((res) => {
      this.id = res.Hospital.HospitalId;
      this.getHospital(this.id);
    });
  }
  getHospital(id) {
    this.hospitalService.getHospitalById(id).subscribe((res) => {
      this.hospital = res;
    });
  }

  getNurseReport(id) {
    this.patientService.getNurseReport(id).subscribe((res) => {
      this.nurseReports = res.NurseReports;
      this.heartRateChart.series[0].data = this.nurseReports.map((report) => [
        new Date(report.createdDate).getTime(),
        report.HeartBeat,
      ]);
      this.OxygenSaturationChart.series[0].data = this.nurseReports.map(
        (report) => [
          new Date(report.createdDate).getTime(),
          report.OxygenSaturation,
        ]
      );
      this.respirationChart.series[0].data = this.nurseReports.map((report) => [
        new Date(report.createdDate).getTime(),
        report.Respiration,
      ]);
      this.tempratureChart.series[0].data = this.nurseReports.map((report) => [
        new Date(report.createdDate).getTime() - 5 * 60 * 60 * 1000,
        report.Temparature,
      ]);
      this.nurseReports.forEach((report: any) => {
        report.BloodPressure = report.BloodPressure?.split('/');
      });
      const bloodPressure = this.nurseReports
        .filter((report) => report.BloodPressure)
        .map((report) => {
          return {
            systolic: report.BloodPressure[0],
            diastolic: report.BloodPressure[1],
            date: report.createdDate,
          };
        });
      const systolic = bloodPressure.map((x) => x.systolic);
      const diastolic = bloodPressure.map((x) => x.diastolic);
      bloodPressure.forEach((rep, index) => {
        this.bloodPressureChart.xAxis.categories[index] = new Date(
          rep.date
        ).toLocaleDateString();
        this.bloodPressureChart.series[0].data = systolic;
        this.bloodPressureChart.series[1].data = diastolic;
      });
    });
  }
  print() {
    window.print();
  }
  onClose(): void {
    this.dialogRef.close();
  }
}
