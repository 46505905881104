import { Component } from '@angular/core';

@Component({
  selector: 'app-practice-manager-dash-board',
  templateUrl: './practice-manager-dash-board.component.html',
  styleUrls: ['./practice-manager-dash-board.component.css']
})
export class PracticeManagerDashBoardComponent {

}
