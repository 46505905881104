<div class="toolbar mat-elevation-z6">

    <!-- <button mat-raised-button [routerLink]="['/doctorDash/']" class="backG mx-5"
        [matMenuTriggerFor]="crt"><mat-icon>dashboard</mat-icon>Doctor Dashboard</button>
    <button type="button" (click)="logout()" class="float-right mx-2 my-2" color="info" mat-raised-button>
        Logout
    </button> -->
    <mat-menu class="ItemS " #crt="matMenu">
        <!-- <div class="ItemS">
            <mat-icon mat-list-icon>dashboard</mat-icon>

            <div class="mx-2" [routerLink]="['/doctorDash/']"> Doctor Dashboard</div>
        </div> -->
        <!-- <div class="ItemS " (click)="showItem='0'" [ngClass]="{'active': showItem =='0'}">
            <mat-icon mat-list-icon>dashboard</mat-icon>
            <div class="mx-2">Back</div>
            <mat-icon>navigate_next</mat-icon>
        </div> -->

        <hr>
    </mat-menu>
    <h2 class="ml-5 text-white text-center"><img class="nurseimg " src="{{biller?.Image}}" alt="">
        {{biller?.FullName}} <span class="text-white "> </span></h2>
</div>

<div class="split-container">
    <div class=" ml-1 mt-2">

        <div class="patient-image" [ngStyle]="{
            'background-image': 'url(' + patient?.PatientImage + ')'
          }">

        </div>
        <br>
        <span style="font-weight: bold; font-size: medium; margin-bottom: 10px;">
            {{patient?.PatientFirstName}}
            {{patient?.PatientLastName}}</span> <br>
        MRN : {{patient?.PatientId}}, <br>

        <!-- Patient Name : {{patient.PatientFirstName}}
        {{patient.PatientLastName}}<br> -->

        D.O.B : {{patient?.PatientDOB}},<br>
        Sex : {{patient?.PatientGender}}<br>
        Phone : {{patient?.PhoneNumber}}<br>
        Email : {{patient?.Email}}<br>
        Address : {{patient?.PatientAddress}}


        <hr>


        <div class="mt-5">
            <hr>
        </div>


    </div>
    <!-- Left Panel: Component A -->
    <div class="split-panel left-panel" [style.width.%]="leftPanelWidth">
        <app-biller-split-a></app-biller-split-a>
    </div>

    <!-- Resizer -->
    <div class="resizer" (mousedown)="startResize($event)"></div>

    <!-- Right Panel: Component B -->
    <div class="split-panel right-panel" [style.width.%]="rightPanelWidth">
        <app-biller-split-b></app-biller-split-b>
    </div>
</div>