import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { InvoiceItem } from 'src/app/Models/invoiceitem.model';
import { Invoice } from '../../Models/invoice.model';
import { InvoiceService } from '../../services/invoice.service';
import { InvoiceItemService } from 'src/app/services/invoice-item.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HospitalService } from 'src/app/services/hospital.service';

import { TokenService } from 'src/app/services/token.service';
import { PrintinvoiceComponent } from '../printinvoice/printinvoice.component';
import { UpdateInvoiceComponent } from '../update-invoice/update-invoice.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-view-all-invoices',
  templateUrl: './view-all-invoices.component.html',
  styleUrls: ['./view-all-invoices.component.css'],
})
export class ViewAllInvoicesComponent implements OnInit {
  helper = new JwtHelperService();
  id = 0;
  myToken = this.tokenService.getToken();
  invoiceList: Invoice[] = [];
  invoiceItemList: InvoiceItem[] = [];

  filteredList;
  displayedColumns: string[] = [
    'PatientName',
    'Title',
    'Total',
    'Date',
    'Status',
    'Print',
    'Update',
    'Delete',
  ];
  dataSource: MatTableDataSource<any>;

  constructor(
    private invoiceService: InvoiceService,
    private invoiceItemService: InvoiceItemService,
    private hospitalService: HospitalService,
    private tokenService: TokenService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.id = parseInt(decodedToken.nameid);

    this.getInvoice(this.id);
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  getInvoice(id) {
    this.hospitalService.GetInvoices(id).subscribe((res) => {
      this.dataSource = new MatTableDataSource(
        res.Invoices.sort((x, y) => {
          return y.InvoiceId - x.InvoiceId;
        })
      );
      this.dataSource.paginator = this.paginator;

      this.filteredList = res;
      this.invoiceList = res.Invoices;
    });
  }

  getInvoiceItems(invoiceId) {
    return this.invoiceService.getInvoiceItems(invoiceId);
  }
  search(searchValue) {
    // console.log(searchValue);
    if (searchValue.value != null) {
      this.filteredList = this.invoiceList.filter((x) =>
        x.PatientName.toLowerCase().includes(searchValue.value.toLowerCase())
      );
    }
  }

  delete(id) {
    this.invoiceService.deleteInvoice(id).subscribe((res) => {
      alert('invoice deleted');

      // this.invoiceItemService.deleteInvoiceItem(id);
      this.getInvoice(this.id);
    });
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  printInvoice(InvoiceId): void {
    const dialogRef = this.dialog.open(PrintinvoiceComponent, {
      width: '60%',
      height: '80%',
      disableClose: false, // Prevent closing without confirmation
      hasBackdrop: false, // Disable the backdrop
      panelClass: 'custom-dialog-container', // Optional custom styling
      data: { InvoiceId }, // Pass data to the dialog
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('Dialog closed:', result);
      // Handle any cleanup or actions after the dialog is closed
    });
  }
  updateInvoice(InvoiceId): void {
    const dialogRef = this.dialog.open(UpdateInvoiceComponent, {
      width: '50%',
      height: '80%',
      disableClose: false, // Prevent closing without confirmation
      hasBackdrop: false, // Disable the backdrop
      panelClass: 'custom-dialog-container', // Optional custom styling
      data: { InvoiceId }, // Pass data to the dialog
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('Dialog closed:', result);
      // Handle any cleanup or actions after the dialog is closed
    });
  }
}
