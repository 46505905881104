<mat-tab-group (selectedTabChange)="tabChanged($event)">
  <!-- *ngIf = "!currentPatient.IsInsuranceCompleted" -->
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="example-tab-icon">add</mat-icon>
      View Lab Report
    </ng-template>



    <div class="ml-3">
      <div class="row">
        <div class="col-lg-6 float-right">
          <mat-form-field class="example-full-width mt-5">
            <mat-label>Filter</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Ex.Mia" #input>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class=" mat-elevation-z8">
      <table mat-table [dataSource]="dataSource" matSort>




        <ng-container matColumnDef="Date">
          <th mat-header-cell *matHeaderCellDef> Date </th>
          <td mat-cell *matCellDef="let row"> {{row.DateOfReport}} </td>
        </ng-container>
        <ng-container matColumnDef="ReportType">
          <th mat-header-cell *matHeaderCellDef> ReportType </th>
          <td mat-cell *matCellDef="let row"> {{row.ReportType}} </td>
        </ng-container>
        <ng-container matColumnDef="DateDrawn">
          <th mat-header-cell *matHeaderCellDef> Date Drawn </th>
          <td mat-cell *matCellDef="let row"> {{row.DateDrawn}} </td>
        </ng-container>
        <ng-container matColumnDef="DateReceived">
          <th mat-header-cell *matHeaderCellDef> Date Received </th>
          <td mat-cell *matCellDef="let row"> {{row.DateReceived}} </td>
        </ng-container>

        <!-- <ng-container matColumnDef="Description">
          <th class="message" mat-header-cell *matHeaderCellDef> Description </th>
          <td class="message" mat-cell *matCellDef="let row"> {{row.Description}} </td>
        </ng-container> -->
        <ng-container matColumnDef="OrderedBy">
          <th mat-header-cell *matHeaderCellDef> Ordered By </th>
          <td mat-cell *matCellDef="let row"> {{row.OrderedBy}} </td>
        </ng-container>
        <ng-container matColumnDef="LabName">
          <th mat-header-cell *matHeaderCellDef> Lab Name </th>
          <td mat-cell *matCellDef="let row"> {{row.LabName}} </td>
        </ng-container>
        <ng-container matColumnDef="ReportedBy">
          <th mat-header-cell *matHeaderCellDef> Reported By </th>
          <td mat-cell *matCellDef="let row"> {{row.ReportedBy}} </td>
        </ng-container>

        <!-- <ng-container matColumnDef="View">
      <th mat-header-cell *matHeaderCellDef> View </th>
      <td mat-cell *matCellDef="let element">
        <button type="button" [routerLink]="['/printlabreport/',element.LabReportId]" mat-mini-fab color="primary">
          <mat-icon matPrefix>report</mat-icon>
        </button>
      </td>
    </ng-container> -->

        <ng-container matColumnDef="View">
          <th mat-header-cell *matHeaderCellDef> View Report </th>
          <td mat-cell *matCellDef="let row">
            <button class="btn " (click)="modal2.show();viewLabReport(row) " color="primary"
              mat-mini-fab><mat-icon>visibility</mat-icon></button>&nbsp;
            <!-- <button type="button" class="btn btn-primary" (click)="viewReport(row)" data-toggle="modal"
            data-target=".bd-example-modal-lg"> View
          </button>  -->
            <app-modal id="printable" #modal2 class="panel-demo1" [maximizable]="true" [backdrop]="false">
              <ng-container class="app-modal-header">Patient Name: {{patient?.PatientFirstName}} &nbsp;
                {{patient?.PatientLastName}}</ng-container>
              <ng-container class="app-modal-body">
                <div class="modal-body " id="printable" #LabOrder>
                  <app-view-hospital></app-view-hospital>

                  <div class="areaStyle mb-1">
                    <P>Patient ID: {{patient?.PatientId}} </P>

                    <P>Patient Name: {{patient?.PatientFirstName}} &nbsp; {{patient?.PatientLastName}} </P>
                    <P>Patient D.O.B: {{patient?.PatientDOB}} </P>
                    <P>Patient Address: {{patient?.PatientAddress}} </P>
                    <hr>
                  </div>

                  <P>Subject: {{ReportType}}</P>
                  <P>OrderedBy: {{OrderedBy}}</P>
                  <P>Description: {{Description}}</P>
                  <P>Reported By: {{ReportedBy}}</P>
                  <p>Date: {{row.createdDate | date:'short'}}</p>

                  <!-- <P>Test Name: {{TestName}}</P> -->



                  <hr>
                  <div>
                    <table class="table table-striped">
                      <thead>

                        <th>Test Name</th>
                        <th>Test Result</th>
                        <th>Referance Range</th>
                        <th>Units</th>
                      </thead>
                      <tbody>
                        <tr *ngFor="let rw of labTest">
                          <td>{{rw.TestName}}</td>
                          <td>{{rw.TestResultValue}}</td>
                          <td>{{rw.ReferanceRange}}</td>
                          <td>{{rw.Units}}</td>
                        </tr>
                      </tbody>
                    </table>

                  </div>
                </div>
                <div>
                  <form (keydown.enter)="$event.preventDefault()" action="">
                    <mat-form-field class="w-30 ml-5 mr-3" appearance="outline">
                      <mat-label>Email to Lab</mat-label>
                      <input matInput type="text" [formControl]="email" placeholder="Enter email">
                    </mat-form-field>
                    <button mat-raised-button color="primary" class="mb-1" (click)="sendtoLab()">Send</button>
                    <div class="send-fax-prescription d-flex align-items-baseline">
                      <mat-form-field class="w-30 ml-5 mr-3" appearance="outline">
                        <input matInput type="text" [formControl]="fax" placeholder="Enter Fax Number">
                      </mat-form-field>
                      <button mat-raised-button color="primary" class="mb-1" (click)="faxPresciption()">Send</button>
                    </div>
                    <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
                      Fax has been sent Successfully.
                      <button type="button" class="btn-close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div *ngIf="errorMessage" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
                      {{errorMessage}}
                      <button type="button" class="close " data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  </form>
                </div>


              </ng-container>
              <ng-container class="app-modal-footer">
                <button type="button" (click)="print()" class="btn btn-primary ">Print</button>

                <button class="dt-button dt-blue" style="float: right;" (click)="modal2.hide()">Close</button>
              </ng-container>
            </app-modal>
            <!-- <button type="button" color="primary" (click)="viewLabReport(row)" mat-mini-fab data-toggle="modal"
              data-target=".bd-example-modal-lg">
              <mat-icon>report</mat-icon>
            </button> -->

            <!-- <div class="modal  fade bd-example-modal-lg" data-backdrop="false" tabindex="-1" role="dialog"
              aria-labelledby="myLargeModalLabel" aria-hidden="true">
              <div class="modal-dialog modal-lg">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLongTitle">Report </h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body " id="printable">
                    <app-view-hospital></app-view-hospital>

                    <div class="areaStyle mb-1">
                      <P>Patient ID: {{patient?.PatientId}} </P>

                      <P>Patient Name: {{patient?.PatientFirstName}} &nbsp; {{patient?.PatientLastName}} </P>
                      <P>Patient D.O.B: {{patient?.PatientDOB}} </P>
                      <P>Patient Address: {{patient?.PatientAddress}} </P>
                      <hr>
                    </div>
                    <P>Date: {{Date | date:'short'}}</P>
                    <P>Subject: {{ReportType}}</P>
                    <P>OrderedBy: {{OrderedBy}}</P>
                    <P>Description: {{Description}}</P>
                    <P>Reported By: {{ReportedBy}}</P>
                    <P>Test Name: {{TestName}}</P>
                  </div>
                  <hr>
                  <div>
                    <table class="table table-striped">
                      <thead>

                        <th>Test Name</th>
                        <th>Test Result</th>
                        <th>Referance Range</th>
                        <th>Units</th>
                      </thead>
                      <tbody>
                        <tr *ngFor="let rw of labTest">
                          <td>{{rw.TestName}}</td>
                          <td>{{rw.TestResultValue}}</td>
                          <td>{{rw.ReferanceRange}}</td>
                          <td>{{rw.Units}}</td>
                        </tr>
                      </tbody>
                    </table>

                  </div>
                  <div class="modal-footer">
                    <button type="button" (click)="print()" class="btn btn-primary ">Print</button>

                    <button type="button" class="btn btn-secondary mx-3" data-dismiss="modal">Close</button>
                  </div>
                </div>
              </div>
            </div> -->

          </td>
        </ng-container>


        <ng-container matColumnDef="Update">
          <th mat-header-cell *matHeaderCellDef> Update</th>
          <td mat-cell *matCellDef="let row">

            <button type="button" color="primary" mat-mini-fab (click)="update(row.LabReportId)"> <mat-icon
                matPrefix>edit</mat-icon></button>
          </td>
        </ng-container>
        <ng-container matColumnDef="Delete">
          <th mat-header-cell *matHeaderCellDef> Delete</th>
          <td mat-cell *matCellDef="let element">
            <button (click)="delete(element.LabReportId)" mat-mini-fab
              color="accent"><mat-icon>delete</mat-icon></button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
        </tr>
      </table>

      <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>


  </mat-tab>

  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="example-tab-icon">upload</mat-icon>
      View Uploaded Document
    </ng-template>
    <!-- (submitAction)="handleSubmitAction()" -->
    <app-view-upload-lab-report></app-view-upload-lab-report>

  </mat-tab>
</mat-tab-group>