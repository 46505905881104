import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable, startWith, map } from 'rxjs';
import { EmailService } from 'src/app/services/email.service';
import { HospitalService } from 'src/app/services/hospital.service';

import { TokenService } from 'src/app/services/token.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-update-hospital',
  templateUrl: './update-hospital.component.html',
  styleUrls: ['./update-hospital.component.css'],
})
export class UpdateHospitalComponent implements OnInit {
  helper = new JwtHelperService();
  filteredOptions: Observable<string[]>;
  Codes: any[] = [];

  id = 0;
  myToken = this.tokenService.getToken();
  isSuccess = false;
  imageUrl = '';

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private hospitalService: HospitalService,
    private router: Router,
    private userService: UserService,
    private http: HttpClient,
    private tokenService: TokenService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    this.countryCodes();
    this.filteredOptions = this.hsFrm.controls.CountryCode.valueChanges.pipe(
      startWith(''),
      map((value: string) => this._filter(value || ''))
    );
    this.hospitalService.getHospitalById(this.id).subscribe((res) => {
      this.hsFrm.patchValue(res);
    });
  }

  hsFrm = this.fb.group({
    HospitalName: ['', Validators.required],
    HospitalLogo: ['', Validators.required],
    HospitalPhone: ['', Validators.required],
    HospitalEmail: ['', Validators.required],
    HospitalAddress: ['', Validators.required],
    TaxId: [''],
    ProviderNo: [''],
    GroupNo: [''],
    NpiNumber: [''],
  });
  get TaxId() {
    return this.hsFrm.get('TaxId');
  }
  get ProviderNo() {
    return this.hsFrm.get('ProviderNo');
  }
  get GroupNo() {
    return this.hsFrm.get('GroupNo');
  }
  get CountryCode() {
    return this.hsFrm.get('CountryCode');
  }
  get NpiNumber() {
    return this.hsFrm.get('NpiNumber');
  }

  get Password() {
    return this.hsFrm.get('Password');
  }
  get HospitalName() {
    return this.hsFrm.get('HospitalName');
  }

  get HospitalLogo() {
    return this.hsFrm.get('HospitalLogo');
  }
  get HospitalPhone() {
    return this.hsFrm.get('HospitalPhone');
  }
  get HospitalEmail() {
    return this.hsFrm.get('HospitalEmail');
  }
  get HospitalAddress() {
    return this.hsFrm.get('HospitalAddress');
  }

  image1(e) {
    let formData = new FormData();
    let files = e.target.files;
    formData.append('file', files[0], files[0].name);

    this.hospitalService.imageUpload(formData).subscribe(
      (res) => {
        let stImg = environment.imgurl + res['path'];
        this.imageUrl = `<p><img src="${stImg}"/></p>`;
      },
      (err) => console.log(err)
    );
  }
  image(e: Event) {
    const inputElement = e.target as HTMLInputElement;

    if (inputElement.files && inputElement.files.length > 0) {
      const formData = new FormData();
      const file = inputElement.files[0];
      formData.append('file', file); // No need to append file name here

      this.hospitalService.imageUploadS3(formData).subscribe(
        (res: any) => {
          // Assuming res['path'] is the full S3 file URL returned by the backend
          this.imageUrl = res.path;
          // console.log('File uploaded successfully:', this.imageUrl);
        },
        (err) => {
          // console.error('Error uploading file:', err);
        }
      );
    }
  }
  public countryCodes() {
    this.http.get('/assets/json/country-codes.json').subscribe({
      next: (res: any) => {
        this.Codes = res.map((x) => x.dial_code);
      },
    });
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.Codes.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }
  formSubmit() {
    let taxId = this.hsFrm.value['TaxId'];
    let providerNo = this.hsFrm.value['ProviderNo'];
    let groupNo = this.hsFrm.value['GroupNo'];
    let npiNumber = this.hsFrm.value['NpiNumber'];
    let hospitalName = this.hsFrm.value['HospitalName'];
    let password = this.hsFrm.value['Password'];
    let hospitalLogo = this.hsFrm.value['HospitalLogo'];
    let hospitalPhone = this.hsFrm.value['HospitalPhone'];
    let hospitalEmail = this.hsFrm.value['HospitalEmail'];
    let hospitalAddress = this.hsFrm.value['HospitalAddress'];
    // let userBody = {
    //   UserName: hospitalEmail,
    //   Email: hospitalEmail,
    //   Password: password,
    //   PhoneNumber: hospitalPhone,
    // };
    // this.userService.updateUser(this.id, userBody).subscribe((res) => {
    let body = {
      HospitalName: hospitalName,
      HospitalLogo: this.imageUrl,
      HospitalPhone: hospitalPhone,
      HospitalEmail: hospitalEmail,
      HospitalAddress: hospitalAddress,
      TaxId: taxId,
      ProviderNo: providerNo,
      GroupNo: groupNo,
      NpiNumber: npiNumber,
    };

    this.hospitalService.updateHospital(this.id, body).subscribe((res) => {
      this.isSuccess = true;
      this.hsFrm.reset();

      setTimeout(() => {}, 2000);
      this.router.navigate(['/adminDash/']);
    });
    // });
  }
}
// .replace(/ {1,}/g,'')
//     .trim()                      trim gap between username
