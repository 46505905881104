<!-- <div>

  <button mat-raised-button color="primary" [routerLink]="['/hospitalDash/']"><mat-icon mat-list-icon>undo</mat-icon>
    Dashboard</button>
</div> -->
<div class="dialog-header">
  <button mat-raised-button color="primary" class="close-button" (click)="onClose()">Close</button>
</div>
<div class="container" cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <div class="row">
    <div class="col-lg regFrm mat-elevation-z8">
      <h1>Update Appointment</h1>
      <form (keydown.enter)="$event.preventDefault()" [formGroup]="addVisitFrm" (ngSubmit)="formSubmit()"
        class="example-form">
        <div class="row">
          <div class="col-lg-4">
            <mat-form-field class="example-full-width">
              <mat-label>Status</mat-label>
              <!-- <input type="text" formControlName="DoctorName" matInput >
                      <mat-error >Please enter Doctor Name</mat-error> -->

              <mat-select formControlName="Status">

                <mat-option *ngFor="let st of STS" [value]="st">{{st}}</mat-option>

              </mat-select>
            </mat-form-field>



          </div>
        </div>

        <div class="row">
          <!-- Doctor Name Dropdown -->
          <div class="col-lg-6">
            <mat-form-field class="example-full-width">
              <mat-label>Doctor Name</mat-label>
              <input type="text" formControlName="DoctorName" matInput>
              <mat-error>Please enter Doctor Name</mat-error>
              <!-- 
              <mat-select formControlName="DoctorId" (selectionChange)="getSpeciality()">
                <mat-option *ngFor="let doctor of doctorList" [value]="doctor.DoctorId">{{ doctor.DoctorName
                  }}</mat-option>
              </mat-select>
              <mat-error>Select a Doctor</mat-error> -->
            </mat-form-field>
          </div>

          <!-- Doctor Specialization Dropdown -->
          <div class="col-lg-4">
            <mat-form-field class="example-full-width">
              <mat-label>Doctor Specialization</mat-label>
              <input type="text" [value]="selectedDoctor?.DoctorSpecialization" formControlName="DoctorSpecialization"
                matInput readonly />
              <mat-error *ngIf="addVisitFrm.get('DoctorSpecialization')?.hasError('required')">
                Select a Doctor specialization
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-lg-4">

            <mat-form-field class="example-full-width">
              <mat-label>VisitType</mat-label>
              <!-- <input type="text" formControlName="VisitType" matInput > -->
              <!-- <mat-error >Please enter Visit Type</mat-error>  -->

              <mat-select formControlName="VisitType">

                <mat-option *ngFor="let op of options" [value]="op">{{op}}</mat-option>

              </mat-select>
            </mat-form-field>

          </div>
        </div>

        <div class="row">


          <div class="col-lg-3">
            <mat-form-field class="example-full-width">
              <mat-label>Vistit Date</mat-label>
              <input type="text" formControlName="VisitDate" matInput>
              <mat-error>Please enter Date</mat-error>
            </mat-form-field>
          </div>

          <div class="col-lg-3">
            <mat-form-field class="example-full-width">
              <mat-label>Vist Time</mat-label>
              <input type="text" formControlName="VisitTime" matInput>
              <mat-error>Please enter Visit Time</mat-error>
            </mat-form-field>
          </div>










          <div class="col-lg-6">

            <mat-form-field appearance="fill" class="example-full-width">
              <mat-label>Reason For Visit </mat-label>
              <!-- <mat-icon matPrefix>event</mat-icon> -->
              <!-- <input matInput type="text" class="txt_height" formControlName="TodoText"> -->
              <textarea matInput formControlName="Message" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="1" cdkAutosizeMaxRows="40"></textarea>

              <!--                  
                  <mat-error *ngIf="PatientNote.invalid && PatientNote.touched">Please enter PatientNote's</mat-error> -->
            </mat-form-field>

          </div>


        </div>




        <button class="float-right lgBtCl" mat-button>
          <mat-icon>save</mat-icon>
        </button>
      </form>

    </div>
  </div>

</div>