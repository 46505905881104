import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { PatientService } from 'src/app/services/patient.service';
import { Labreport } from '../../Models/labreport.model';
import { LabreportService } from '../../services/labreport.service';
import { Patient } from 'src/app/Models/patient.model';
import { LabTest } from 'src/app/Models/labtest.model';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { MatDialog } from '@angular/material/dialog';
import { OverlayContainer } from 'ngx-toastr';

import { TokenService } from 'src/app/services/token.service';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LabOrderService } from 'src/app/services/lab-order.service';
import html2pdf from 'html2pdf.js';
import { UpdateLabReportComponent } from '../update-lab-report/update-lab-report.component';

@Component({
  selector: 'app-view-labreport',
  templateUrl: './view-labreport.component.html',
  styleUrls: ['./view-labreport.component.css'],
})
export class ViewLabreportComponent implements OnInit {
  @ViewChild('LabOrder') LabOrder!: ElementRef;

  displayedColumns: string[] = [
    'Date',
    'ReportType',
    'DateDrawn',
    'DateReceived',
    // 'Description',
    'OrderedBy',
    'LabName',
    'ReportedBy',
    'View',
    'Update',
    'Delete',
  ];

  id = 0;
  labReport: Labreport[] = [];
  dataSource: MatTableDataSource<any>;
  labDocument: string = '';
  ReportType: string = '';
  OrderedBy: string = '';
  Description: string = '';
  ReportedBy: string = '';
  Date: string = '';
  TestName: string = '';
  TestResultValue: string = '';
  ReferanceRange: string = '';
  Units: string = '';
  patient: Patient;
  labTest: LabTest[];
  email = new FormControl('');
  display: string;
  FaxImage: FormData;
  fax = new FormControl('');
  errorMessage: string;
  isSuccess: boolean;
  constructor(
    private patientService: PatientService,
    private labservice: LabreportService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    public cdk: OverlayContainer,
    private tokenService: TokenService,
    private fb: FormBuilder,
    private labOrderservice: LabOrderService,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    this.getPatient(this.id);
    this.GetlabReports(this.id);
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  // ngAfterViewInit() {
  //   this.dataSource.paginator = this.paginator;
  //   this.dataSource.sort = this.sort;
  // }

  getPatient(id) {
    this.patientService.getPatientById(id).subscribe((res) => {
      this.patient = res;
    });
  }

  GetlabReports(id) {
    this.patientService.getPatienLabReport(id).subscribe((res) => {
      this.labReport = res.LabReports;

      this.dataSource = new MatTableDataSource(
        res.LabReports.sort(function (x, y) {
          return y.LabReportId - x.LabReportId;
        })
      );
      this.dataSource.paginator = this.paginator;
    });
  }

  delete(id) {
    this.labservice.deleteLabReport(id).subscribe((res) => {
      // this.labReport = this.labReport.filter(x=>x.LabReportId !=id)
      this.GetlabReports(this.id);
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  print() {
    window.print();
  }

  public viewLabReport(report: Labreport): void {
    this.Date = report.Date;
    this.ReportType = report.ReportType;
    this.ReportedBy = report.ReportedBy;
    this.OrderedBy = report.OrderedBy;
    this.Description = report.Description;
    this.labTest = report.LabTests;
    // this.TestName = report.TestName;
    // this.TestResultValue = report.TestResultValue;
    // this.ReferanceRange = report.ReferanceRange;
    // this.Units = report.Units;
    setTimeout(() => {
      const isModal = document.querySelector('.modal-backdrop.show');
      if (isModal) {
        isModal.classList.remove('modal-backdrop');
      }
      const labReportImage = document.querySelector(
        '.lab-document img'
      ) as HTMLImageElement;
      if (labReportImage) {
        labReportImage.style.width = '100%';
      }
    }, 0);
  }

  tabChanged(event: MatTabChangeEvent): void {
    if (event.index === 0) {
    }
  }

  sendtoLab() {
    let body = {
      Email: this.email.value,
      Subject: 'Lab Test Report orderd by' + this.OrderedBy,

      Template: `${this.LabOrder.nativeElement.innerHTML}`,
    };
    this.labOrderservice.sendLabOroder(body).subscribe((res) => {
      alert('Report sent');
    });
  }

  Frm = this.fb.group({
    FaxNumber: ['', Validators.required],
    Subject: [''],
    Template: [''],
  });

  get FaxNumber() {
    return this.Frm.get('FaxNumber');
  }

  get Subject() {
    return this.Frm.get('Subject');
  }

  get Template() {
    return this.Frm.get('Template');
  }
  openModal() {
    this.display = 'block';
  }
  onCloseHandled() {
    this.display = 'none';
  }
  image(e: any) {
    let formData = new FormData();
    let files = e.target.files;
    // this.FaxImage = files[0].name;
    formData.append('media', files[0], files[0].name);
    this.FaxImage = formData;
  }
  formSubmit() {}
  faxPresciption() {
    // const { FaxNumber } = this.Frm.value;
    const faxNumber = this.fax.value!;
    this.apiTriggerer({ toNumber: faxNumber });
  }

  async apiTriggerer({ toNumber }: { toNumber: string }) {
    try {
      let mediaName: string;

      if (this.FaxImage) {
        // Upload image to Telnyx
        const { media_name } = await this.uploadImage();
        mediaName = media_name;
        console.log('media', mediaName);
      } else {
        // Generate PDF from Template
        const templatePdfBlob = await this.generatePdfFromTemplate(
          this.Frm.value['Template']!
        );

        // Upload PDF to Telnyx
        const {
          data: { media_name },
        } = await this.uploadPdf(templatePdfBlob);
        mediaName = media_name;
      }

      // Send fax using Telnyx API
      const faxResponse = await this.sendFax({ toNumber, mediaName });
      this.isSuccess = true;
      this.errorMessage = '';
      console.log('Fax sent successfully:', faxResponse);
    } catch (error) {
      console.error('Failed to send fax:', error);
      this.isSuccess = false;
      this.errorMessage = 'Failed to send fax. Please try again.';
    }
  }

  uploadImage(): Promise<{ media_name: string }> {
    const headers = new HttpHeaders({
      Authorization:
        'Bearer KEY018F1B3936DBB73C952D24A5C5946FB7_uHXA9t9leqKfXcg7qaiwNj',
    });

    return new Promise((resolve, reject) => {
      this.http
        .post<any>('https://api.telnyx.com/v2/media', this.FaxImage, {
          headers,
        })
        .subscribe(
          (response) => resolve(response?.data),
          (error) => reject(error)
        );
    });
  }

  sendFax({
    toNumber,
    mediaName,
  }: {
    toNumber: string;
    mediaName: string;
  }): Promise<any> {
    const headers = new HttpHeaders({
      Authorization:
        'Bearer KEY018F1B3936DBB73C952D24A5C5946FB7_uHXA9t9leqKfXcg7qaiwNj',
    });

    return this.http
      .post<any>(
        'https://api.telnyx.com/v2/faxes',
        {
          connection_id: '2433304486127601151',
          media_name: mediaName,
          from: '+12523900048',
          to: toNumber,
        },
        { headers }
      )
      .toPromise();
  }

  // async generatePdfFromTemplate(template: string): Promise<Blob> {
  //   const doc = new jsPDF()
  //   doc.text(template, 10, 10) // Add text from template

  //   return doc.output('blob')
  // }

  async generatePdfFromTemplate(template: string): Promise<Blob> {
    // Create a container element
    const container = document.createElement('div');
    (container.innerHTML = `${this.LabOrder.nativeElement.innerHTML}`),
      document.body.appendChild(container);

    return new Promise((resolve, reject) => {
      html2pdf()
        .from(container)
        .toPdf()
        .output('blob')
        .then((blob: Blob) => {
          document.body.removeChild(container);
          resolve(blob);
        })
        .catch((error: any) => {
          document.body.removeChild(container);
          reject(error);
        });
    });
  }

  uploadPdf(pdfBlob: Blob): Promise<{ data: { media_name: string } }> {
    const headers = new HttpHeaders({
      Authorization:
        'Bearer KEY018F1B3936DBB73C952D24A5C5946FB7_uHXA9t9leqKfXcg7qaiwNj',
    });

    const formData = new FormData();
    formData.append('media', pdfBlob, 'fax.pdf');

    return this.http
      .post<any>('https://api.telnyx.com/v2/media', formData, { headers })
      .toPromise();
  }

  faxes() {
    const headers = new HttpHeaders({
      Authorization:
        'Bearer KEY018F1B3936DBB73C952D24A5C5946FB7_uHXA9t9leqKfXcg7qaiwNj',
    });

    return this.http
      .post<any>(
        'https://api.telnyx.com/v2/faxes',
        {
          connection_id: '2433304486127601151',
          media_name: `${this.LabOrder.nativeElement.innerHTML}`,
          from: '+12523900048',
          to: this.fax.value,
        },
        { headers }
      )
      .subscribe((res) => {
        console.log(res);
      });
  }

  update(LabReportId: number): void {
    const dialogRef = this.dialog.open(UpdateLabReportComponent, {
      width: '50%',
      height: '80%',
      disableClose: true, // Prevent closing without confirmation
      hasBackdrop: false, // Disable the backdrop
      panelClass: 'custom-dialog-container', // Optional custom styling
      data: { LabReportId }, // Pass data to the dialog
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('Dialog closed:', result);
      // Handle any cleanup or actions after the dialog is closed
    });
  }
}
