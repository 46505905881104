import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  constructor(private cookieService: CookieService) {}

  // Set the token in cookies
  setToken(token: string): void {
    this.cookieService.set('authToken', token, {
      path: '/',
      secure: true,
      sameSite: 'Strict',
    });
    // localStorage.setItem('token', token);
  }

  // Get the token from cookies
  getToken(): string | null {
    return this.cookieService.get('authToken') || localStorage.getItem('token');
  }
  getToken1(key: string): string | null {
    return localStorage.getItem(key); // Or sessionStorage if preferred
  }
  // Clear the token
  clearToken(): void {
    this.cookieService.delete('authToken', '/');
    // localStorage.removeItem('token');
  }
}
