<div class="toolbar mat-elevation-z6">


    <button type="button" (click)="logout()" class="float-right mx-2 my-2" color="info" mat-raised-button>
        Logout
    </button>

    <!-- <p class="ml-5 text-white text-center"><img class="nurseimg " src="{{Doctor?.DoctorImage}}" alt="">
        Dr. {{Doctor?.DoctorName}} <span class="text-white "> </span></p> -->
</div>

<div class="split-container">
    <div class="col-md-1 ml-1 mt-2">

        <div class="patient-image" [ngStyle]="{
            'background-image': 'url(' + patient?.PatientImage + ')'
          }">

        </div>
        <br>
        <span style="font-weight: bold; font-size: medium; margin-bottom: 10px;">
            {{patient?.PatientFirstName}}
            {{patient?.PatientLastName}}</span> <br>
        MRN : {{patient?.PatientId}}, <br>

        <!-- Patient Name : {{patient.PatientFirstName}}
        {{patient.PatientLastName}}<br> -->


        D.O.B : {{patient?.PatientDOB}},<br>
        Sex : {{patient?.PatientGender}}<br>
        Address : {{patient?.PatientAddress}}


        <hr>
        <h4 style="font-weight: bold; font-size: medium;">Patient Allergies</h4>
        <ul class="list-group" *ngFor="let alr of patientAllergies">
            <li class="list-group-item">{{alr.AllergicTo?alr.AllergicTo:'No Allergies'}}</li>
        </ul>
        <hr>
        <button class="mt-3" mat-raised-button (click)="toggleDropdown()">Keywords General</button>

        <div *ngIf="showDropdown">
            <p class="mt-2" *ngFor="let keyword of keywords" (click)="copyToClipboard(keyword)">
                {{ keyword }}
            </p>
        </div>
        <button class="mt-2" mat-raised-button (click)="toggleDropdown1()">Keywords Labitem</button>

        <div *ngIf="showDropdown1">
            <p class="mt-2" *ngFor="let keyword1 of keywords1" (click)="copyToClipboard(keyword1)">
                {{ keyword1 }}
            </p>
        </div>
        <button class="mt-2" mat-raised-button (click)="toggleDropdown2()">Aft. VisitSummary</button>

        <div *ngIf="showDropdown2">
            <p class="mt-2" *ngFor="let keyword2 of keywords2" (click)="copyToClipboard(keyword2)">
                {{ keyword2 }}
            </p>
        </div>


        <div class="mt-5">
            <hr>
        </div>


    </div>
    <!-- Left Panel: Component A -->
    <div class="split-panel left-panel" [style.width.%]="leftPanelWidth">
        <app-split-a></app-split-a>
    </div>

    <!-- Resizer -->
    <div class="resizer" (mousedown)="startResize($event)"></div>

    <!-- Right Panel: Component B -->
    <div class="split-panel right-panel" [style.width.%]="rightPanelWidth">
        <app-split-b></app-split-b>
    </div>
</div>