import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ClaimAccountKeyService } from 'src/app/services/claim-account-key.service';
import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-add-claim-account-key',
  templateUrl: './add-claim-account-key.component.html',
  styleUrls: ['./add-claim-account-key.component.css'],
})
export class AddClaimAccountKeyComponent implements OnInit {
  successMessage: string = '';
  errorMessage: string = '';
  helper = new JwtHelperService();
  id = 0;
  myToken = this.tokenService.getToken();
  constructor(
    private fb: FormBuilder,
    private claimAccountKeyService: ClaimAccountKeyService,
    private tokenService: TokenService
  ) {}
  ngOnInit(): void {
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.id = parseInt(decodedToken.nameid);
  }
  claimAccountKeyForm = this.fb.group({
    AccountKey: ['', Validators.required],
    Status: ['Active', Validators.required],
    // HospitalId: [null, Validators.required],
  });

  onSubmit() {
    if (this.claimAccountKeyForm.valid) {
      let body = {
        ...this.claimAccountKeyForm.value,
        HospitalId: this.id,
      };
      this.claimAccountKeyService.registerClaimAccountKey(body).subscribe({
        next: (response) => {
          this.successMessage = 'Claim Account Key added successfully!';
          this.claimAccountKeyForm.reset();
        },
        error: (err) => {
          this.errorMessage = err;
        },
      });
    }
  }
}
