import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ProviderEnrollmentService {
  // private apiUrl = 'https://your-backend-url/api/providers/enroll';
  apiUrl = environment.Url + 'ProviderEnrollment/enroll';

  constructor(private http: HttpClient) {}

  enrollProvider(providerData: any): Observable<any> {
    return this.http.post(this.apiUrl, providerData);
  }
}
