import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Electromyography } from 'src/app/Models/electromyography.model';
import { PatientService } from 'src/app/services/patient.service';
import { ElectromyographyService } from 'src/app/services/electromyography.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-view-electromyography',
  templateUrl: './view-electromyography.component.html',
  styleUrls: ['./view-electromyography.component.css'],
})
export class ViewElectromyographyComponent implements OnInit {
  id: number = 0;
  dataSource: MatTableDataSource<Electromyography> = new MatTableDataSource();
  displayedColumns: string[] = [
    'createdDate',
    'side',
    'muscle',
    'nerve',
    'root',
    'insAct',
    'fibs',
    'psw',
    'amp',
    'duration',
    'poly',
    'recrt',
    'intPat',
    'comments',
    'update',
    'delete',
  ];
  helper = new JwtHelperService();

  myToken = this.tokenService.getToken();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  CurrentDoctorId: number;

  constructor(
    private patientService: PatientService,
    private electromyographyService: ElectromyographyService,
    private route: ActivatedRoute,
    private tokenService: TokenService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.id = params['id'];
      this.getElectromyography(this.id);
    });
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.CurrentDoctorId = parseInt(decodedToken.nameid);
  }

  getElectromyography(id: number): void {
    this.patientService.GetElectromyography(id).subscribe((res) => {
      this.dataSource = new MatTableDataSource(res.Electromyographys);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  delete(id: number): void {
    if (confirm('Are you sure you want to delete this record?')) {
      this.electromyographyService.deleteElectromyography(id).subscribe(() => {
        this.getElectromyography(this.id);
      });
    }
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
