import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ClaimService } from 'src/app/services/claim.service';
import { UpdateClaimComponent } from '../update-claim/update-claim.component';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BillerService } from 'src/app/services/biller.service';
import { DoctorService } from 'src/app/services/doctor.service';
import { HospitalService } from 'src/app/services/hospital.service';
import { TokenService } from 'src/app/services/token.service';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'app-era-list',
  templateUrl: './era-list.component.html',
  styleUrls: ['./era-list.component.css'],
})
export class EraListComponent implements OnInit {
  displayedColumns: string[] = [
    'payer_name',
    'paid_amount',
    'paid_date',
    'prov_name',
    'prov_taxid',
    'check_number',
    'prov_npi',
    'action',
    // 'Delete',
  ];
  helper = new JwtHelperService();

  myToken = this.tokenService.getToken();

  showItem = '';
  hospitalId: number;

  id = 0;
  uploadList: any[] = [];
  errorMessage: string | null = null;
  claimStatus: any;
  xmlList: any[] = [];
  inboundId: string = '';
  status: any;
  dataSource: MatTableDataSource<any>;
  pdfSrc: SafeResourceUrl | null = null;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  accountKey = '';
  constructor(
    private claimService: ClaimService,
    private dialog: MatDialog,
    private sanitizer: DomSanitizer,
    private hospitalService: HospitalService,
    private tokenService: TokenService,
    private billerService: BillerService,
    private doctorService: DoctorService
  ) {}
  ngOnInit(): void {
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.id = parseInt(decodedToken.nameid);

    // Check the role in the decoded token
    if (decodedToken.role === 'Biller') {
      this.billerService.getbillerHospital(this.id).subscribe((res) => {
        this.hospitalId = res.Hospital.HospitalId;
        this.getCliamKey(this.hospitalId); // Fetch claim key for the biller's hospital
      });
    } else if (decodedToken.role === 'Hospital') {
      this.getCliamKey(this.id); // Fetch claim key directly using the hospital ID
    } else if (decodedToken.role === 'Doctor') {
      this.doctorService.getDoctorHospital(this.id).subscribe((res) => {
        this.hospitalId = res.Hospital.HospitalId;
        this.getCliamKey(this.hospitalId); // Fetch claim key for the biller's hospital
      });
    } else {
      console.error('Invalid role detected in token:', decodedToken.role);
    }
  }

  getCliamKey(id: number) {
    this.hospitalService.getclaimAccountKey(id).subscribe((res) => {
      // Assuming res.ClaimAccountKeys is an array, take the first item's AccountKey
      if (res.ClaimAccountKeys && res.ClaimAccountKeys.length > 0) {
        this.accountKey = res.ClaimAccountKeys[0].AccountKey;
        this.getEraList(this.accountKey);
      } else {
        console.error(
          'No ClaimAccountKeys found for the provided hospital ID.'
        );
      }
    });
  }
  getEraList(id): void {
    this.errorMessage = null; // Reset error message
    this.uploadList = []; // Reset upload list

    this.claimService.getEraList(id).subscribe({
      next: (response: any) => {
        try {
          const parsedResponse = JSON.parse(response); // Parse the JSON string into an object
          this.xmlList = parsedResponse.result || [];
          // this.xmlList = data;
          this.dataSource = new MatTableDataSource(this.xmlList);

          this.dataSource.paginator = this.paginator;

          // Pretty print the JSON
        } catch (error) {
          console.error('Error parsing JSON response:', error);
        }
      },
      error: (error: any) => {
        console.error('Error fetching upload list:', error);
      },
    });
  }

  getPdf(eraId: string): void {
    this.claimService.getEraPdf(eraId).subscribe({
      next: (response: any) => {
        try {
          const parsedResponse = JSON.parse(response); // Parse the JSON string into an object
          this.xmlList = parsedResponse.result || {};
          // this.pdfSrc = `data:application/pdf;base64,${parsedResponse.result.data}`;

          const dataUrl = `data:application/pdf;base64,${parsedResponse.result.data}`;
          this.pdfSrc = this.sanitizer.bypassSecurityTrustResourceUrl(dataUrl);
        } catch (error) {
          console.error('Error parsing JSON response:', error);
        }
      },
      error: (error: any) => {
        console.error('Error fetching upload list:', error);
      },
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
