import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Inject,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { InvoiceService } from '../../services/invoice.service';
import { PatientService } from '../../services/patient.service';
import { EmailPayload } from 'src/app/Models/email.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { PatientInsurance } from 'src/app/Models/patientinsurance.model';
import { PatientinsuranceService } from 'src/app/services/patientinsurance.service';
import { Hospital } from 'src/app/Models/hospital.model';
import html2pdf from 'html2pdf.js';

import { TokenService } from 'src/app/services/token.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PaymentmethodService } from 'src/app/services/paymentmethod.service';
import { StripeScriptTag, StripeSource } from 'stripe-angular';
import { ClaimService } from 'src/app/services/claim.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-printinvoice',
  templateUrl: './printinvoice.component.html',
  styleUrls: ['./printinvoice.component.css'],
})
export class PrintinvoiceComponent implements OnInit {
  @ViewChild('Invoice') Invoice!: ElementRef;
  @ViewChild('stripeCard') stripeCard: any;

  display: string;
  FaxImage: FormData;
  errorMessage: string;
  id = 0;
  invoice;
  invalidError = {};
  isPaymentSuccess = false;
  partialAmount = 0;
  email = new FormControl('');
  fax = new FormControl('');
  hide = true;
  insurance;
  hospital: Hospital;
  isSuccess: boolean;
  hospitalId: number;
  constructor(
    private invoiceService: InvoiceService,
    private route: ActivatedRoute,
    private router: Router,
    private patientService: PatientService,
    private claimsService: ClaimService,
    private http: HttpClient,
    private toastr: ToastrService,
    private patientInsurance: PatientinsuranceService,
    private fb: FormBuilder,
    private tokenService: TokenService,
    @Inject(MAT_DIALOG_DATA)
    public data: { InvoiceId: string },
    private dialogRef: MatDialogRef<PrintinvoiceComponent>,
    private paymentMethodService: PaymentmethodService,
    private stripeScriptTag: StripeScriptTag
  ) {}

  ngOnInit(): void {
    // this.route.params.subscribe((res) => {
    //   this.id = res['id'];
    // });
    const InvoiceId = this.data.InvoiceId;

    this.getPtInvoice(InvoiceId);
    this.patientService.getpatienHospitalId(this.id).subscribe((res) => {
      this.hospitalId = res.Hospital.HospitalId;
      this.getPaymentMethod(this.hospitalId);
    });
  }
  getPaymentMethod(id) {
    this.paymentMethodService
      .getPaymentMethodById(id)
      .subscribe((response: any) => {
        this.stripeScriptTag.setPublishableKey(
          response.PaymentMethods[0].publishableKey
        );
      });
  }

  getPatientInfo(id) {
    this.patientService.getPatientById(id).subscribe({
      next: (res) => {
        this.invoice['PatientId'] = res.PatientId;
        this.invoice['PatientAddress'] = res.PatientAddress;
        this.invoice['State'] = res.State;
        this.invoice['City'] = res.City;
        this.invoice['ZipCode'] = res.Zip;
        this.invoice['PatientDOB'] = res.PatientDOB;
        this.invoice['Email'] = res.Email;
        this.invoice['PatientGender'] = res.PatientGender;
        this.invoice['PhoneNumber'] = res.PhoneNumber;
      },
    });
  }

  getPtInvoice(id) {
    this.invoiceService.getInvoiceItems(id).subscribe((res: any) => {
      this.getPatientInfo(res.PatientId);
      this.getPatientHospital(res.PatientId);
      this.getPatientInsurance(res.PatientId);
      this.invoice = res;
    });
  }

  getPatientHospital(id) {
    this.patientService.getpatienHospitalId(id).subscribe((res) => {
      this.hospital = res.Hospital;
    });
  }
  getPatientInsurance(id) {
    this.patientService.getPatientInsurance(id).subscribe((res) => {
      this.insurance = res;
    });
  }
  downloadAsPDF() {
    const content = document.getElementById('printable');
    const button = document.querySelector('button'); // Select the button
    if (content) {
      button?.classList.add('downloadButton');
      // Configure html2pdf options
      const options = {
        margin: 1, // Margins in cm
        filename: `Invoice_${this.invoice.InvoiceId}.pdf`, // Output file name
        image: { type: 'jpeg', quality: 0.98 }, // Image quality
        html2canvas: { scale: 2 }, // Scale for higher resolution
        jsPDF: { unit: 'cm', format: 'a4', orientation: 'portrait' }, // A4 portrait format
      };

      // Generate PDF
      html2pdf().set(options).from(content).save();
    }
  }
  printPage() {
    window.print();
  }
  print(): void {
    const printElement = document.getElementById('printable');
    if (printElement) {
      const printContents = printElement.innerHTML;
      const printWindow = window.open('', '_blank', 'width=800,height=600');
      if (printWindow) {
        printWindow.document.open();
        printWindow.document.write(`
          <html>
          <head>
            <title>Print Invoice</title>
            <style>
              body { font-family: Arial, sans-serif; margin: 20px; }
            </style>
          </head>
          <body onload="window.print();window.close();">
            ${printContents}
          </body>
          </html>
        `);
        printWindow.document.close();
      }
    } else {
      console.error('Printable content not found');
    }
  }

  sendtoInsurance() {
    let body = {
      Email: this.email.value,
      Subject: 'Invoice' + this.invoice.InvoiceId,
      Template: `${this.Invoice.nativeElement.innerHTML}`,
    };
    this.patientService.sendInvoiceToInsurance(body).subscribe((res) => {
      alert('Invoice sent');
    });
  }
  claimResponse: string | null = null;

  exportDataAsFile(data: any): Blob {
    const jsonStr = JSON.stringify(data, null, 2); // Convert data to JSON string
    return new Blob([jsonStr], { type: 'application/json' }); // Create a Blob for the file
  }

  uploadJsonFile(file: Blob, apiUrl: string): Observable<any> {
    const formData = new FormData();
    formData.append('file', file, 'data.json'); // Append the file to FormData with a name

    const headers = new HttpHeaders({
      // Add additional headers if required by the API
    });

    return this.http.post(apiUrl, formData, { headers }); // Send the file to the API
  }
  // submitClaim(invoiceId: number): void {
  //   this.invoiceService.generateEdiClaim(invoiceId).subscribe((ediFile) => {
  //     console.log('EDI File Generated:', ediFile);

  //     const invoiceHtmlContent = this.Invoice.nativeElement.innerHTML;

  //     const payload = {
  //       ediFileContent: ediFile,
  //       invoiceHtml: invoiceHtmlContent,
  //     };

  //     this.invoiceService.submitClaim(payload).subscribe(() => {
  //       console.log('Claim Submitted Successfully');
  //     });
  //   });
  // }

  sendInvoice(): void {
    let body: EmailPayload = {
      Email: this.invoice['PatientEmail'],
      Subject: 'Invoice' + this.invoice.InvoiceId,
      Template: `${this.Invoice.nativeElement.innerHTML}`,
    };

    this.patientService.sendInvoice(body).subscribe({
      next: (res) => alert('Invoice sent'),
    });
  }

  cardReady = false;
  extraData = {
    name: null,
    address_city: null,
    address_line1: null,
    address_line2: null,
    address_state: null,
    address_zip: null,
    price: 30,
  };

  onStripeInvalid(error: Error) {
    // console.log('Validation Error', error)
  }
  createStripeToken() {
    // Use the `stripe-card` directive to create a token
    if (this.stripeCard) {
      this.stripeCard.createToken().then((result: any) => {
        if (result.error) {
          this.invalidError = result.error.message;
          console.error('Error creating token:', result.error.message);
        } else {
          this.setStripeToken(result.token);
        }
      });
    } else {
      console.error('Stripe Card Element is not initialized');
    }
  }
  setStripeToken(token) {
    console.log('Stripe token', token);
    let body = {
      TransactionId: token.id,
      InvoiceId: this.invoice.InvoiceId,
      Amount: this.invoice.TotalAmount,
      PaymentType: 'Card Payment',
      PatientId: this.invoice['PatientId'],
    };

    this.http.post(environment.Url + 'Payments', body).subscribe(
      (res) => {
        if (res['status'] == 'succeeded') {
          this.isPaymentSuccess = true;
        }
      },
      (err) => console.log(err)
    );
  }

  parialPayement(token, amount: number) {
    if (amount < 0) {
      this.toastr.error('You need to enter an amount greater then 0');
      return;
    }

    let body = {};
    if (amount < this.invoice.TotalAmount) {
      body = {
        TransactionId: token.id,
        InvoiceId: this.invoice.InvoiceId,
        Amount: amount,
        PaymentType: 'Card Payment',
        PatientId: this.invoice['PatientId'],
      };
    } else {
      body = {
        TransactionId: token.id,
        InvoiceId: this.invoice.InvoiceId,
        Amount: this.invoice.TotalAmount,
        PaymentType: 'Card Payment',
        PatientId: this.invoice['PatientId'],
      };
    }

    this.http.post(environment.Url + 'Payments', body).subscribe(
      (res) => {
        if (res['status'] == 'succeeded') {
          this.isPaymentSuccess = true;
        }
      },
      (err) => console.log(err)
    );
  }

  setStripeSource(source: StripeSource) {
    // console.log('Stripe source', source)
  }

  onStripeError(error: Error) {
    // console.error('Stripe error')
  }

  Frm = this.fb.group({
    FaxNumber: ['', Validators.required],
    Subject: [''],
    Template: [''],
  });

  get FaxNumber() {
    return this.Frm.get('FaxNumber');
  }

  get Subject() {
    return this.Frm.get('Subject');
  }

  get Template() {
    return this.Frm.get('Template');
  }
  openModal() {
    this.display = 'block';
  }
  onCloseHandled() {
    this.display = 'none';
  }
  image(e: any) {
    let formData = new FormData();
    let files = e.target.files;
    // this.FaxImage = files[0].name;
    formData.append('media', files[0], files[0].name);
    this.FaxImage = formData;
  }
  formSubmit() {}
  faxPresciption() {
    // const { FaxNumber } = this.Frm.value;
    const faxNumber = this.fax.value!;
    this.apiTriggerer({ toNumber: faxNumber });
  }

  async apiTriggerer({ toNumber }: { toNumber: string }) {
    try {
      let mediaName: string;

      if (this.FaxImage) {
        // Upload image to Telnyx
        const { media_name } = await this.uploadImage();
        mediaName = media_name;
        console.log('media', mediaName);
      } else {
        // Generate PDF from Template
        const templatePdfBlob = await this.generatePdfFromTemplate(
          this.Frm.value['Template']!
        );

        // Upload PDF to Telnyx
        const {
          data: { media_name },
        } = await this.uploadPdf(templatePdfBlob);
        mediaName = media_name;
      }

      // Send fax using Telnyx API
      const faxResponse = await this.sendFax({ toNumber, mediaName });
      this.isSuccess = true;
      this.errorMessage = '';
      // console.log('Fax sent successfully:', faxResponse);
    } catch (error) {
      // console.error('Failed to send fax:', error);
      this.isSuccess = false;
      this.errorMessage = 'Failed to send fax. Please try again.';
    }
  }

  uploadImage(): Promise<{ media_name: string }> {
    const headers = new HttpHeaders({
      Authorization:
        'Bearer KEY018F1B3936DBB73C952D24A5C5946FB7_uHXA9t9leqKfXcg7qaiwNj',
    });

    return new Promise((resolve, reject) => {
      this.http
        .post<any>('https://api.telnyx.com/v2/media', this.FaxImage, {
          headers,
        })
        .subscribe(
          (response) => resolve(response?.data),
          (error) => reject(error)
        );
    });
  }

  sendFax({
    toNumber,
    mediaName,
  }: {
    toNumber: string;
    mediaName: string;
  }): Promise<any> {
    const headers = new HttpHeaders({
      Authorization:
        'Bearer KEY018F1B3936DBB73C952D24A5C5946FB7_uHXA9t9leqKfXcg7qaiwNj',
    });

    return this.http
      .post<any>(
        'https://api.telnyx.com/v2/faxes',
        {
          connection_id: '2433304486127601151',
          media_name: mediaName,
          from: '+12523900048',
          to: toNumber,
        },
        { headers }
      )
      .toPromise();
  }

  // async generatePdfFromTemplate(template: string): Promise<Blob> {
  //   const doc = new jsPDF()
  //   doc.text(template, 10, 10) // Add text from template

  //   return doc.output('blob')
  // }

  async generatePdfFromTemplate(template: string): Promise<Blob> {
    // Create a container element
    const container = document.createElement('div');
    container.innerHTML = `${this.Invoice.nativeElement.innerHTML}`;
    document.body.appendChild(container);

    return new Promise((resolve, reject) => {
      html2pdf()
        .from(container)
        .toPdf()
        .output('blob')
        .then((blob: Blob) => {
          document.body.removeChild(container);
          resolve(blob);
        })
        .catch((error: any) => {
          document.body.removeChild(container);
          reject(error);
        });
    });
  }

  uploadPdf(pdfBlob: Blob): Promise<{ data: { media_name: string } }> {
    const headers = new HttpHeaders({
      Authorization:
        'Bearer KEY018F1B3936DBB73C952D24A5C5946FB7_uHXA9t9leqKfXcg7qaiwNj',
    });

    const formData = new FormData();
    formData.append('media', pdfBlob, 'fax.pdf');

    return this.http
      .post<any>('https://api.telnyx.com/v2/media', formData, { headers })
      .toPromise();
  }

  faxes() {
    const headers = new HttpHeaders({
      Authorization:
        'Bearer KEY018F1B3936DBB73C952D24A5C5946FB7_uHXA9t9leqKfXcg7qaiwNj',
    });

    return this.http
      .post<any>(
        'https://api.telnyx.com/v2/faxes',
        {
          connection_id: '2433304486127601151',
          media_name: `${this.Invoice.nativeElement.innerHTML}`,
          from: '+12523900048',
          to: this.fax.value,
        },
        { headers }
      )
      .subscribe((res) => {
        console.log(res);
      });
  }

  onClose(): void {
    this.dialogRef.close();
  }

  extractClaimData(): any {
    let data = {
      fileid: this.invoice?.InvoiceId,
      claim: [
        {
          payer_name: '',
          payerid: '',
          payer_addr_1: '',
          payer_city: '',
          payer_state: 'TX',
          payer_zip: '',
          //Patient
          pat_name_l: 'First,Last',
          pat_name_f: 'First,Last',
          pat_dob: '',
          pat_sex: '',
          pat_addr_1: '',
          pat_rel: '',
          pat_city: '',
          pat_state: 'TX',
          pat_zip: '',
          //insured details
          ins_number: '',
          ins_name_l: 'First,Last',
          ins_name_f: 'First,Last',
          ins_addr_1: '',
          ins_dob: '',
          ins_sex: '',
          employment_related: 'No',
          auto_accident: 'No',
          //Diagnosis
          diag_1: 'Code Only',
          diag_2: 'Code Only',
          diag_3: 'Code Only',
          diag_4: 'Code Only',
          diag_5: 'Code Only',
          diag_6: 'Code Only',
          diag_7: 'Code Only',
          diag_8: 'Code Only',
          diag_9: 'Code Only',
          diag_10: 'Code Only',
          diag_11: 'Code Only',
          diag_12: 'Code Only',

          //service
          from_date: '',
          thru_date: '',
          place_of_service: '11',
          //CPT
          proc_code: '99214',
          diag_ref: 'A',
          charge: '100.00',
          units: 1,
          //Facility
          prov_taxid: '741111111',
          prov_taxonomy: '208D00000X',
          prov_taxid_type: 'E',
          pcn: '47147-1',
          accept_assign: 'YES',
          total_charge: '',
          amount_paid: '',
          //Porivide
          ref_name_l: '',
          ref_name_f: '',
          ref_npi: '1231231238',
          prov_name_l: 'LastName',
          prov_name_f: 'FirstName',

          bill_name: 'Billing Provider Name',
          bill_addr_1: '',
          bill_addr_2: '',
          bill_city: '',
          bill_state: 'TX',
          bill_zip: '',
          bill_npi: '',
          bill_phone: '',
          facility_name: '',
          facility_addr_1: '',
          facility_city: '',
          facility_state: '',
          facility_zip: '',
          facility_npi: '1234567893',
        },
      ],
    };
    return data;
  }
}
