import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-biller-split-b',
  templateUrl: './biller-split-b.component.html',
  styleUrls: ['./biller-split-b.component.css'],
})
export class BillerSplitBComponent implements OnInit {
  id = 0;
  constructor(
    private route: ActivatedRoute,
    private tokenService: TokenService
  ) {}
  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
  }
  tabs: string[] = [
    'Visit Summary',
    'Progress Notes',
    'Lab Reports',
    'Radiology Reports',
    'View Orders',
    'Test Results',
    'Pharmacy',
    'View Invoice',
    'Claim Status',
    'Electronic Remitance Advice',
    'Claim Notes',
  ];
  activatedTabIndex: number = 0;

  tabChange(tabIndex: number) {
    // debugger;
    this.activatedTabIndex = tabIndex;
  }
}
