import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { PatientService } from '../../services/patient.service';
import { UserService } from '../../services/user.service';
import { Doctor } from 'src/app/Models/doctor.model';
import { DoctorService } from 'src/app/services/doctor.service';
import { WardService } from '../../services/ward.service';
import { Ward } from 'src/app/Models/ward.model';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';

import { Observable, Subject } from 'rxjs';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { HttpClient } from '@angular/common/http';
import { startWith, map } from 'rxjs/operators';
import { Department } from 'src/app/Models/department.model';
import { DepartmentService } from 'src/app/services/department.service';
import { Room } from 'src/app/Models/room.model';
import { Bed } from 'src/app/Models/bed.model';
import { BedService } from 'src/app/services/bed.service';
import { RoomService } from 'src/app/services/room.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HospitalService } from 'src/app/services/hospital.service';
import { EmailService } from 'src/app/services/email.service';

import { TokenService } from 'src/app/services/token.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-addpatient',
  templateUrl: './addpatient.component.html',
  styleUrls: ['./addpatient.component.css'],
})
export class AddpatientComponent implements OnInit {
  isSuccess = false;
  id = 0;
  helper = new JwtHelperService();

  myToken = this.tokenService.getToken();
  showModal = false; // Controls modal visibility
  modalMessage = ''; // Message to display in the modal
  Gender: string[] = ['Male', 'Female', 'Other'];
  bloodGroup: string[] = ['A+', 'B+', 'O+', 'AB+', 'A-', 'O-', 'B-', 'AB-'];
  maritalStatus: string[] = ['Married', 'Single', 'Widow', 'Other'];

  doctorList: Doctor[] = [];
  departmentList: Department[];
  wardList: Ward[];
  roomList: Room[];
  bedList: Bed[];
  imageUrl: string;
  Codes: any[] = [];
  filteredOptions: Observable<string[]>;
  hospitalName: string;
  selectedDoctor: Doctor;
  constructor(
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private patientService: PatientService,
    private userService: UserService,
    private router: Router,
    private doctorService: DoctorService,
    private wardService: WardService,
    private roomService: RoomService,
    private bedService: BedService,
    private departmentService: DepartmentService,
    private http: HttpClient,
    private hosptalService: HospitalService,
    private emailService: EmailService,
    private tokenService: TokenService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    // this.route.params.subscribe((res) => {
    //   this.id = res['id'];
    // });

    let decodedToken = this.helper.decodeToken(this.myToken);
    this.id = parseInt(decodedToken.nameid);

    this.hosptalService.getHospitalById(this.id).subscribe((res) => {
      this.hospitalName = res.HospitalName.substring(0, 3);
    });

    this.getDoctors(this.id);
    this.getDeparments(this.id);
    this.getWards(this.id);

    this.countryCodes();
    this.filteredOptions = this.ptFrm.controls.CountryCode.valueChanges.pipe(
      startWith(''),
      map((value: string) => this._filter(value || ''))
    );
  }

  ptFrm = this.fb.group({
    PatientFirstName: ['', Validators.required],
    PatientLastName: ['', Validators.required],
    PatientDOB: ['', Validators.required],
    PatientGender: ['', Validators.required],
    Email: ['', [Validators.required, Validators.email]],
    CountryCode: ['+1', Validators.required],
    PhoneNumber: ['', [Validators.required, Validators.minLength(10)]],
    DoctorId: ['', Validators.required],
    DoctorSpecialization: ['', Validators.required],
    PrimaryDoctorsName: ['', Validators.required],
    Password: [
      '',
      [
        Validators.required,
        Validators.pattern(
          '^(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$'
        ),
      ],
    ],
    PatientNationalId: ['', Validators.required],
    BloodGroup: ['', Validators.required],
    MaritalStatus: ['', Validators.required],
    Occupation: ['', Validators.required],
    PatientHeight: ['', Validators.required],
    PatientWeight: ['', Validators.required],
    NameOfParent: [''],
    ReferredDoctor: [''],
    DepartmentId: ['', Validators.required],
    WardId: ['', Validators.required],
    PatientAddress: ['', Validators.required],
    State: ['', Validators.required],
    City: ['', Validators.required],
    Zip: ['', Validators.required],
  });

  get Zip() {
    return this.ptFrm.get('Zip');
  }
  get City() {
    return this.ptFrm.get('City');
  }
  get State() {
    return this.ptFrm.get('State');
  }
  get NameOfParent() {
    return this.ptFrm.get('NameOfParent');
  }
  get CountryCode() {
    return this.ptFrm.get('CountryCode');
  }

  get PatientHeight() {
    return this.ptFrm.get('PatientHeight');
  }

  get PatientWeight() {
    return this.ptFrm.get('PatientWeight');
  }

  get ReferredDoctor() {
    return this.ptFrm.get('ReferredDoctor');
  }
  get DepartmentName() {
    return this.ptFrm.get('DepartmentName');
  }
  get DepartmentId() {
    return this.ptFrm.get('DepartmentId');
  }
  get WardId() {
    return this.ptFrm.get('WardId');
  }

  get WardName() {
    return this.ptFrm.get('WardName');
  }

  get NurseName() {
    return this.ptFrm.get('NurseName');
  }

  get DoctorId() {
    return this.ptFrm.get('DoctorId');
  }
  get PatientFirstName() {
    return this.ptFrm.get('PatientFirstName');
  }
  get PatientLastName() {
    return this.ptFrm.get('PatientLastName');
  }
  get PatientDOB() {
    return this.ptFrm.get('PatientDOB');
  }
  get PatientGender() {
    return this.ptFrm.get('PatientGender');
  }
  get Email() {
    return this.ptFrm.get('Email');
  }
  get PhoneNumber() {
    return this.ptFrm.get('PhoneNumber');
  }
  get PatientAddress() {
    return this.ptFrm.get('PatientAddress');
  }

  get DoctorName() {
    return this.ptFrm.get('DoctorName');
  }
  get PatientNationalId() {
    return this.ptFrm.get('PatientNationalId');
  }
  get MaritalStatus() {
    return this.ptFrm.get('MaritalStatus');
  }
  get Occupation() {
    return this.ptFrm.get('Occupation');
  }
  get BloodGroup() {
    return this.ptFrm.get('BloodGroup');
  }
  get Password() {
    return this.ptFrm.get('Password');
  }

  getDoctors(id) {
    this.hosptalService.getDoctors(id).subscribe((res) => {
      this.doctorList = res.Doctors;
    });
  }
  onDoctorChange(event: any): void {
    const selectedDoctorId = event; // Event provides the selected value (DoctorId)
    this.selectedDoctor =
      this.doctorList.find((doctor) => doctor.DoctorId === selectedDoctorId) ||
      null;

    // Update DoctorSpecialization in the form
    if (this.selectedDoctor) {
      this.ptFrm.patchValue({
        DoctorSpecialization: this.selectedDoctor.DoctorSpecialization,
      });
    } else {
      this.ptFrm.patchValue({
        DoctorSpecialization: '',
      });
    }
  }
  getWards(id) {
    this.hosptalService.getWards(id).subscribe((res) => {
      this.wardList = res.Wards;
    });
  }
  getDeparments(id) {
    this.hosptalService.getDepartment(id).subscribe((res) => {
      this.departmentList = res.Departments;
    });
  }

  image1(e) {
    let formData = new FormData();
    let files = e.target.files;
    formData.append('file', files[0], files[0].name);

    this.patientService.imageUpload(formData).subscribe(
      (res) => {
        let stImg = environment.imgurl + res['path'];
        this.imageUrl = stImg;
      },
      (err) => console.log(err)
    );
  }
  image(e: Event) {
    const inputElement = e.target as HTMLInputElement;

    if (inputElement.files && inputElement.files.length > 0) {
      const formData = new FormData();
      const file = inputElement.files[0];
      formData.append('file', file); // No need to append file name here

      this.patientService.imageUploadS3(formData).subscribe(
        (res: any) => {
          // Assuming res['path'] is the full S3 file URL returned by the backend
          this.imageUrl = res.path;
          // console.log('File uploaded successfully:', this.imageUrl);
        },
        (err) => {
          // console.error('Error uploading file:', err);
        }
      );
    }
  }

  private trigger: Subject<any> = new Subject();
  public webcamImage!: WebcamImage;
  private nextWebcam: Subject<any> = new Subject();
  sysImage = '';
  public getSnapshot(e): void {
    e.preventDefault;
    this.trigger.next(void 0);
  }
  public captureImg(webcamImage: WebcamImage): void {
    this.webcamImage = webcamImage;
    this.sysImage = webcamImage!.imageAsDataUrl;
  }
  public get invokeObservable(): Observable<any> {
    return this.trigger.asObservable();
  }
  public get nextWebcamObservable(): Observable<any> {
    return this.nextWebcam.asObservable();
  }

  formSubmit() {
    let state = this.ptFrm.value['State'];
    let city = this.ptFrm.value['City'];
    let zip = this.ptFrm.value['Zip'];
    let referredDoctor = this.ptFrm.value['ReferredDoctor'];
    let nameOfParent = this.ptFrm.value['NameOfParent'];
    let patientFirstName = this.ptFrm.value['PatientFirstName'];
    let patientLastName = this.ptFrm.value['PatientLastName'];
    let patientDOB = this.ptFrm.value['PatientDOB'];
    patientDOB = moment(patientDOB).format('MM/DD/YYYY');

    let patientGender = this.ptFrm.value['PatientGender'];
    let email = this.ptFrm.value['Email'];
    let phoneNumber =
      this.ptFrm.value['CountryCode'] + this.ptFrm.value['PhoneNumber'];
    let patientAddress = this.ptFrm.value['PatientAddress'];
    let patientWeight = this.ptFrm.value['PatientWeight'];
    let patientHeight = this.ptFrm.value['PatientHeight'];

    let patientNationalId = this.ptFrm.value['PatientNationalId'];
    let doctorsName = this.ptFrm.value['DoctorName'];
    let mMaritalStatus = this.ptFrm.value['MaritalStatus'];
    let occupation = this.ptFrm.value['Occupation'];
    let bloodGroup = this.ptFrm.value['BloodGroup'];
    let password = this.ptFrm.value['Password'];
    let doctorId = this.ptFrm.value['DoctorId'];
    let idoctorName = this.doctorList.filter((it) => it.DoctorId == doctorId)[0]
      .DoctorName;

    let wardId = this.ptFrm.value['WardId'];
    let wardName = this.wardList.filter((it) => it.WardId == wardId)[0]
      .WardName;
    let departmentId = this.ptFrm.value['DepartmentId'];
    let departmentName = this.departmentList.filter(
      (it) => it.DepartmentId == departmentId
    )[0].DepartmentName;

    let userBody = {
      Email: email,
      Password: password,
      UserName: this.hospitalName + email,
      PhoneNumber: phoneNumber,
    };
    this.userService.registerUser(userBody).subscribe(
      (res) => {
        let body = {
          HospitalId: this.id,
          PatientId: res.Id,
          DoctorId: doctorId,
          WardId: wardId,
          WardName: wardName,
          DepartmentId: departmentId,
          DepartmentName: departmentName,
          PatientFirstName: patientFirstName,
          PatientLastName: patientLastName,
          PatientDOB: patientDOB,
          PatientGender: patientGender,
          Email: email,
          Password: password,
          PhoneNumber: phoneNumber,
          PatientAddress: patientAddress,
          PatientHeight: patientHeight,
          PatientWeight: patientWeight,
          NameOfParent: nameOfParent,
          ReferredDoctor: referredDoctor,
          DoctorName: idoctorName,
          PrimaryDoctorsName: idoctorName,
          PatientNationalId: patientNationalId,
          MaritalStatus: mMaritalStatus,
          Occupation: occupation,
          BloodGroup: bloodGroup,
          PatientImage: this.sysImage ? this.sysImage : this.imageUrl,
          State: state,
          City: city,
          Zip: zip,
          Status: 'Active',
        };

        this.patientService.registerPatient(body).subscribe({
          next: (res) => {
            this.isSuccess = true;
            this.ptFrm.reset();

            this.emailService
              .sendEmail({
                Email: body.Email,
                Subject: 'Patient Login Credentials',
                Template: `<h3>Your Username ${userBody.UserName} please keep this safe. You may reset password now at https://binseraneurology.com. </h3> Complete Your Intake Form  <a href="https://binseraneurology.com/PatientIntakeItems/${res.PatientId}">
       
      </a>`,
              })
              .subscribe();

            const smsBodyDetails =
              `Your Username: ${userBody.UserName}. ` +
              `You may reset your password now at https://binseraneurology.com. ` +
              `Complete your intake form here: https://binseraneurology.com/PatientIntakeItems/${res.PatientId}`;
            let smsBody = {
              PhoneNumber: phoneNumber,
              Body: smsBodyDetails,
            };
            this.emailService.sendSms(smsBody).subscribe();
            this.isSuccess = true;
            this.ptFrm.reset();

            setTimeout(() => {}, 2000);
          },
        });
      },
      (error) => {
        // Handle error response
        if (error.status === 409) {
          this.showModalWithMessage(
            'User already exists. Please try with a different email.'
          );
        } else {
          this.showModalWithMessage('An error occurred. Please try again.');
        }
      }
    );
  }
  showModalWithMessage(message: string) {
    this.modalMessage = message;
    this.showModal = true;
  }

  closeModal() {
    this.showModal = false;
  }
  public countryCodes() {
    this.http.get('/assets/json/country-codes.json').subscribe({
      next: (res: any) => {
        this.Codes = res.map((x) => x.dial_code);
      },
    });
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.Codes.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }

  facingMode: string = 'user';
  allowCameraSwitch = true;

  public get videoOptions(): MediaTrackConstraints {
    const result: MediaTrackConstraints = {};
    if (this.facingMode && this.facingMode !== '') {
      result.facingMode = { ideal: this.facingMode };
    }
    return result;
  }
}
