import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { SubscriptionService } from 'src/app/services/subscription.service';

@Component({
  selector: 'app-all-subscriptions',
  templateUrl: './all-subscriptions.component.html',
  styleUrls: ['./all-subscriptions.component.css'],
})
export class AllSubscriptionsComponent implements OnInit {
  displayedColumns: string[] = [
    'index',
    'hospital',
    'plan',
    'startDate',
    'endDate',
    'status',
    'paymentStatus',
  ];
  subscriptions = new MatTableDataSource<any>([]);

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private subscriptionService: SubscriptionService) {}

  ngOnInit(): void {
    this.loadSubscriptions();
  }

  ngAfterViewInit(): void {
    this.subscriptions.paginator = this.paginator;
  }

  loadSubscriptions(): void {
    this.subscriptionService.getAllSubscriptions().subscribe({
      next: (data) => {
        this.subscriptions.data = data;
      },
      error: (err) => {
        console.error('Error loading subscriptions:', err);
      },
    });
  }
}
