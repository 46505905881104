import { Component, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ClaimAccountKey } from 'src/app/Models/claimaccountkey.model';
import { ClaimAccountKeyService } from 'src/app/services/claim-account-key.service';
import { HospitalService } from 'src/app/services/hospital.service';
import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-view-claim-account-key',
  templateUrl: './view-claim-account-key.component.html',
  styleUrls: ['./view-claim-account-key.component.css'],
})
export class ViewClaimAccountKeyComponent implements OnInit {
  claimAccountKeys: ClaimAccountKey[] = [];
  errorMessage: string = '';
  successMessage: string = '';
  helper = new JwtHelperService();
  id = 0;
  myToken = this.tokenService.getToken();
  constructor(
    private claimAccountKeyService: ClaimAccountKeyService,
    private hospitalService: HospitalService,
    private router: Router,
    private tokenService: TokenService
  ) {}

  ngOnInit() {
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.id = parseInt(decodedToken.nameid);
    this.fetchClaimAccountKeys(this.id);
  }

  fetchClaimAccountKeys(id) {
    this.hospitalService.getclaimAccountKey(id).subscribe({
      next: (response) => {
        this.claimAccountKeys = response.ClaimAccountKeys;
      },
      error: (err) => {
        this.errorMessage = err;
      },
    });
  }
  tabChanged(event: MatTabChangeEvent): void {
    if (event.index === 0) {
      this.fetchClaimAccountKeys(this.id);
    }
  }
  updateClaimAccountKey(id: number) {
    this.router.navigate(['/update-claim-account-key', id]);
  }

  deleteClaimAccountKey(id: number) {
    if (confirm('Are you sure you want to delete this Claim Account Key?')) {
      this.claimAccountKeyService.deleteClaimAccountKey(id).subscribe({
        next: () => {
          this.successMessage = 'Claim Account Key deleted successfully!';
          this.fetchClaimAccountKeys(this.id); // Refresh the list
        },
        error: (err) => {
          this.errorMessage = err;
        },
      });
    }
  }
}
