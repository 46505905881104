<div class="dialog-header">
    <button mat-raised-button color="primary" class="close-button" (click)="onClose()">Close</button>
</div>
<div class="container" cdkDrag cdkDragRootElement=".cdk-overlay-pane">
    <form [formGroup]="claimForm" (ngSubmit)="onSubmit()">
        <mat-card>
            <mat-card-title>Claim Submission Form</mat-card-title>
            <mat-divider></mat-divider>
            <!-- 
            <mat-form-field appearance="fill" class="full-width">
                <mat-label>File ID</mat-label>
                <input type="file" id="fileInput" (change)="onFileSelected($event)" multiple hidden />
                <mat-error *ngIf="claimForm.get('fileid')?.hasError('required')">
                    File ID is required
                </mat-error>
            </mat-form-field> -->
            <!-- File ID -->
            <!-- <mat-form-field appearance="fill" class="full-width">
                <mat-label>File ID</mat-label>
                <input matInput formControlName="fileid" required />
                <mat-error *ngIf="claimForm.get('fileid')?.hasError('required')">
                    File ID is required
                </mat-error>
            </mat-form-field> -->

            <!-- Claims Array -->

            <div class="claim-section">
                <mat-form-field appearance="fill">
                    <mat-label>Attachment Type</mat-label>
                    <mat-select formControlName="attachmentType">
                        <mat-option *ngFor="let type of attachmentTypes" [value]="type.code">
                            {{ type.description }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-divider></mat-divider>
                <div class="row">

                    <div class="col lg-4">
                        <!-- payerid Field -->
                        <mat-form-field appearance="fill">
                            <mat-label>Payer Name </mat-label>
                            <input matInput formControlName="payer_name" required />
                            <mat-error *ngIf="claimForm.get('payer_name')?.hasError('required')">
                                Payer ID is required
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col lg-4">
                        <!-- payerid Field -->
                        <mat-form-field appearance="fill">
                            <mat-label>Payer ID</mat-label>
                            <input matInput formControlName="payerid" required />
                            <mat-error *ngIf="claimForm.get('payerid')?.hasError('required')">
                                Payer ID is required
                            </mat-error>
                        </mat-form-field>
                    </div>


                </div>
                <h2>Patient Details</h2>

                <div class="row">
                    <div class="col lg-2">

                        <mat-form-field appearance="fill">
                            <mat-label>Patient First Name</mat-label>
                            <input matInput formControlName="pat_name_f" required />
                        </mat-form-field>
                    </div>
                    <div class="col lg-2">
                        <mat-form-field appearance="fill">
                            <mat-label>Patient Last Name</mat-label>
                            <input matInput formControlName="pat_name_l" required />
                        </mat-form-field>
                    </div>
                    <div class="col lg-2">
                        <mat-form-field appearance="fill">
                            <mat-label>Patient Gender </mat-label>

                            <mat-select formControlName="pat_sex" required>
                                <mat-option value="M">Male</mat-option>
                                <mat-option value="F">Female</mat-option>
                                <mat-option value="U">Unknown</mat-option>
                            </mat-select>

                        </mat-form-field>

                    </div>
                    <div class="col lg-2">
                        <mat-form-field appearance="fill">
                            <mat-label>Patient DOB</mat-label>
                            <input matInput formControlName="pat_dob" type="date" required />
                        </mat-form-field>
                    </div>

                    <div class="col lg-2">

                        <mat-form-field appearance="fill">
                            <mat-label>Patient Address</mat-label>
                            <input matInput formControlName="pat_addr_1" required />
                        </mat-form-field>
                    </div>
                    <div class="col lg-2">

                        <mat-form-field appearance="fill">
                            <mat-label>Patient City</mat-label>
                            <input matInput formControlName="pat_city" required />
                        </mat-form-field>
                    </div>
                    <div class="col lg-2">
                        <mat-form-field appearance="fill">
                            <mat-label>Patient Relationship</mat-label>
                            <mat-select formControlName="pat_rel" required>
                                <mat-option value="01">Spouse</mat-option>
                                <mat-option value="18">Self</mat-option>
                                <mat-option value="19">Child</mat-option>
                                <mat-option value="20">Employee</mat-option>
                                <mat-option value="21">Unknown</mat-option>
                                <mat-option value="39">Organ Donor</mat-option>
                                <mat-option value="40">Cadaver Donor</mat-option>
                                <mat-option value="53">Life Partner</mat-option>
                                <mat-option value="G8">Other Relationship</mat-option>
                            </mat-select>
                            <mat-error *ngIf="claimForm.get('pat_rel')?.hasError('required')">
                                Patient Relationship is required
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <h2>Insurance Details </h2>

                <div class="row">
                    <div class="col lg-3">

                        <!-- Insurance Details -->
                        <mat-form-field appearance="fill">
                            <mat-label>Insured First Name</mat-label>
                            <input matInput formControlName="ins_name_f" required />
                        </mat-form-field>
                    </div>
                    <div class="col lg-3">
                        <mat-form-field appearance="fill">
                            <mat-label>Insured Last Name</mat-label>
                            <input matInput formControlName="ins_name_l" required />
                        </mat-form-field>
                    </div>
                    <div class="col lg-3">
                        <mat-form-field appearance="fill">
                            <mat-label>Insured Gender</mat-label>
                            <mat-select formControlName="ins_sex" required>
                                <mat-option value="M">Male</mat-option>
                                <mat-option value="F">Female</mat-option>
                                <mat-option value="U">Unknown</mat-option>

                            </mat-select>
                            <mat-error *ngIf="claimForm.get('ins_sex')?.hasError('required')">
                                Insured Gender is required
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col lg-3">

                        <mat-form-field appearance="fill">
                            <mat-label>Insured DOB</mat-label>
                            <input matInput formControlName="ins_dob" type="date" required />
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col lg-3">
                        <mat-form-field appearance="fill">
                            <mat-label>Insured Number</mat-label>
                            <input matInput formControlName="ins_number" required />
                        </mat-form-field>
                    </div>
                    <div class="col lg-3">


                        <mat-form-field appearance="fill">
                            <mat-label>Insured Group</mat-label>
                            <input matInput formControlName="ins_group" required />
                        </mat-form-field>
                    </div>
                    <div class="col lg-3">

                        <mat-form-field appearance="fill">
                            <mat-label>Insured Address</mat-label>
                            <input matInput formControlName="ins_addr_1" required />
                        </mat-form-field>
                    </div>
                    <div class="col lg-3">
                        <mat-form-field appearance="fill">
                            <mat-label>Insured City</mat-label>
                            <input matInput formControlName="ins_city" required />
                        </mat-form-field>
                    </div>

                    <div class="col lg-3">


                        <!-- Dropdown: Auto Accident -->
                        <mat-form-field appearance="fill" class="full-width">
                            <mat-label>Auto Accident</mat-label>
                            <mat-select formControlName="auto_accident" required>
                                <mat-option value="Y">Yes</mat-option>
                                <mat-option value="N">No</mat-option>
                            </mat-select>
                            <mat-error *ngIf="claimForm.get('auto_accident')?.hasError('required')">
                                Auto Accident selection is required
                            </mat-error>
                        </mat-form-field>

                    </div>
                    <div class="col lg-3">

                        <!-- Dropdown: Employment Related -->
                        <mat-form-field appearance="fill" class="full-width">
                            <mat-label>Employment Related</mat-label>
                            <mat-select formControlName="employment_related" required>
                                <mat-option value="Y">Yes</mat-option>
                                <mat-option value="N">No</mat-option>
                            </mat-select>
                            <mat-error *ngIf="claimForm.get('employment_related')?.hasError('required')">
                                Employment Related selection is required
                            </mat-error>
                        </mat-form-field>
                    </div>

                </div>
                <h2>Refering Provider Details</h2>

                <div class="row">
                    <div class="col lg-3">

                        <!-- Reference Details -->
                        <mat-form-field appearance="fill">
                            <mat-label>Referring First Name</mat-label>
                            <input matInput formControlName="ref_name_f" required />
                        </mat-form-field>
                    </div>
                    <div class="col lg-3">
                        <mat-form-field appearance="fill">
                            <mat-label>Referring Last Name</mat-label>
                            <input matInput formControlName="ref_name_l" required />
                        </mat-form-field>
                    </div>
                    <div class="col lg-3">

                        <mat-form-field appearance="fill">
                            <mat-label>Referring Middle Name</mat-label>
                            <input matInput formControlName="ref_name_m" />
                        </mat-form-field>
                    </div>
                    <div class="col lg-3">


                        <mat-form-field appearance="fill">
                            <mat-label>Referring NPI</mat-label>
                            <input matInput formControlName="ref_npi" required />
                        </mat-form-field>
                    </div>
                </div>
                <!-- Diagnosis Fields -->
                <h4>Diagnosis Information</h4>
                <div class="row">
                    <div class="col lg-3">

                        <mat-form-field appearance="fill">
                            <mat-label>Diagnosis 1</mat-label>
                            <input matInput formControlName="diag_1" />
                        </mat-form-field>
                    </div>
                    <div class="col lg-3">


                        <mat-form-field appearance="fill">
                            <mat-label>Diagnosis 2</mat-label>
                            <input matInput formControlName="diag_2" />
                        </mat-form-field>
                    </div>
                    <div class="col lg-3">


                        <mat-form-field appearance="fill">
                            <mat-label>Diagnosis 3</mat-label>
                            <input matInput formControlName="diag_3" />
                        </mat-form-field>

                    </div>
                    <div class="col lg-3">

                        <mat-form-field appearance="fill">
                            <mat-label>Diagnosis 4</mat-label>
                            <input matInput formControlName="diag_4" />
                        </mat-form-field>

                    </div>
                </div>

                <div class="row">
                    <div class="col lg-3">
                        <mat-form-field appearance="fill">
                            <mat-label>Diagnosis 5</mat-label>
                            <input matInput formControlName="diag_5" />
                        </mat-form-field>

                    </div>
                    <div class="col lg-3">
                        <mat-form-field appearance="fill">
                            <mat-label>Diagnosis 6</mat-label>
                            <input matInput formControlName="diag_6" />
                        </mat-form-field>

                    </div>
                    <div class="col lg-3">
                        <mat-form-field appearance="fill">
                            <mat-label>Diagnosis 7</mat-label>
                            <input matInput formControlName="diag_7" />
                        </mat-form-field>

                    </div>
                    <div class="col lg-3">

                        <mat-form-field appearance="fill">
                            <mat-label>Diagnosis 8</mat-label>
                            <input matInput formControlName="diag_8" />
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col lg-3">

                        <mat-form-field appearance="fill">
                            <mat-label>Diagnosis 9</mat-label>
                            <input matInput formControlName="diag_9" />
                        </mat-form-field>

                    </div>
                    <div class="col lg-3">

                        <mat-form-field appearance="fill">
                            <mat-label>Diagnosis 10</mat-label>
                            <input matInput formControlName="diag_10" />
                        </mat-form-field>
                    </div>
                    <div class="col lg-3">

                        <mat-form-field appearance="fill">
                            <mat-label>Diagnosis 11</mat-label>
                            <input matInput formControlName="diag_11" />
                        </mat-form-field>
                    </div>
                    <div class="col lg-3">
                        <mat-form-field appearance="fill">
                            <mat-label>Diagnosis 12</mat-label>
                            <input matInput formControlName="diag_12" />
                        </mat-form-field>
                    </div>
                </div>


                <mat-card>
                    <mat-card-title>Charges</mat-card-title>
                    <mat-card-content formArrayName="charge">
                        <div *ngFor="let charge of charges.controls; let i = index" [formGroupName]="i">
                            <mat-form-field>
                                <mat-label>Charge</mat-label>
                                <input matInput formControlName="charge" />
                            </mat-form-field>

                            <mat-form-field>
                                <mat-label>Place of Service</mat-label>
                                <mat-select formControlName="place_of_service">
                                    <mat-option *ngFor="let code of placeOfServiceCodes" [value]="code.code">
                                        {{ code.description }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field>
                                <mat-label>Procedure Code</mat-label>
                                <input matInput formControlName="proc_code" />
                            </mat-form-field>

                            <mat-form-field>
                                <mat-label>Units</mat-label>
                                <input matInput formControlName="units" />
                            </mat-form-field>

                            <button mat-icon-button color="warn" (click)="removeCharge(i)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </div>
                    </mat-card-content>

                    <button mat-raised-button color="primary" (click)="addCharge()">Add Charge</button>
                </mat-card>
                <!-- Charges Array -->
                <!-- <div formArrayName="charge">
                    <h4>Charges</h4>
                    
                    <mat-card>
                        <mat-card-title>Charge {{ 1 }}</mat-card-title>
                        <mat-divider></mat-divider>

                        <mat-form-field appearance="fill">
                            <mat-label>From Date</mat-label>
                            <input matInput formControlName="from_date" type="date" required />
                            <mat-error *ngIf="claimForm.get('from_date').hasError('required')">
                                From Date is required
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="fill">
                            <mat-label>Thru Date</mat-label>
                            <input matInput formControlName="thru_date" type="date" required />
                            <mat-error *ngIf="claimForm.get('thru_date').hasError('required')">
                                Thru Date is required
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="fill">
                            <mat-label>Select Place of Service</mat-label>
                            <mat-select formControlName="place_of_service">
                                <mat-option *ngFor="let pos of placeOfServiceCodes" [value]="pos.code">
                                    {{ pos.code }} - {{ pos.description }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="claimForm.get('place_of_service')?.hasError('required')">
                                Place of Service is required.
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="fill">
                            <mat-label>Remote CHG ID</mat-label>
                            <input matInput formControlName="remote_chgid" required />
                            <mat-error *ngIf="claimForm.get('remote_chgid').hasError('required')">
                                Remote CHG ID is required
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="fill">
                            <mat-label>Procedure Code</mat-label>
                            <input matInput formControlName="proc_code" required />
                        </mat-form-field>



                        <mat-form-field appearance="fill">
                            <mat-label>Charge Record Type</mat-label>
                            <input matInput formControlName="charge_record_type" required />
                            <mat-error *ngIf="claimForm.get('charge_record_type').hasError('required')">
                                Charge Record Type is required
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="fill">
                            <mat-label>Diagnosis Reference</mat-label>
                            <input matInput formControlName="diag_ref" required />
                            <mat-error *ngIf="claimForm.get('diag_ref').hasError('required')">
                                Diagnosis Reference is required
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="fill">
                            <mat-label>Charge Amount</mat-label>
                            <input matInput formControlName="charge" type="number" required />
                            <mat-error *ngIf="claimForm.get('charge').hasError('required')">
                                Charge Amount is required
                            </mat-error>
                        </mat-form-field>



                        <mat-form-field appearance="fill">
                            <mat-label>Units</mat-label>
                            <input matInput formControlName="units" type="number" required />
                            <mat-error *ngIf="claimForm.get('units').hasError('required')">
                                Units are required
                            </mat-error>
                        </mat-form-field> -->

                <!-- <button mat-button color="warn" (click)="charges(i).removeAt(j)" type="button">
                            Remove Charge
                        </button>
                        <button mat-raised-button color="primary" type="button" (click)="addCharge(i)">
                            Add Charge
                        </button> -->
                <!-- </mat-card> -->
                <!-- </div> -->


                <!-- </div> -->
                <div class="row">
                    <div class="col lg-3">

                        <mat-form-field appearance="fill">
                            <mat-label>Billing Tax ID</mat-label>
                            <input matInput formControlName="bill_taxid" required />
                            <mat-error *ngIf="claimForm.get('bill_taxid')?.hasError('required')">
                                Billing Tax ID is required
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col lg-3">
                        <mat-form-field appearance="fill">
                            <mat-label>Billing Tax ID Type</mat-label>
                            <mat-select formControlName="bill_taxid_type" required>
                                <mat-option value="E">E</mat-option>
                                <mat-option value="EIN">EIN</mat-option>
                                <mat-option value="SSN">SSN</mat-option>
                            </mat-select>
                            <mat-error *ngIf="claimForm.get('bill_taxid_type')?.hasError('required')">
                                Billing Tax ID Type is required
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col lg-3">
                        <mat-form-field appearance="fill">
                            <mat-label>Patient Account Number (47147-1) </mat-label>
                            <input matInput formControlName="pcn" required />
                            <mat-error *ngIf="claimForm.get('pcn')?.hasError('required')">
                                PCN is required
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col lg-3">

                        <mat-form-field appearance="fill">
                            <mat-label>Accept Assign</mat-label>
                            <mat-select formControlName="accept_assign" required>
                                <mat-option value="Y">Yes</mat-option>
                                <mat-option value="N">No</mat-option>
                            </mat-select>
                            <mat-error *ngIf="claimForm.get('accept_assign')?.hasError('required')">
                                Accept Assign is required
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col lg-4">
                        <mat-form-field appearance="fill">
                            <mat-label>Amount Paid </mat-label>
                            <input matInput formControlName="amount_paid" type="number" />
                        </mat-form-field>
                    </div>
                    <div class="col lg-4">
                        <mat-form-field appearance="fill">
                            <mat-label>total charge </mat-label>
                            <input matInput formControlName="total_charge" type="number" required />
                        </mat-form-field>
                    </div>
                    <div class="col lg-4">
                        <mat-form-field appearance="fill">
                            <mat-label>Balance Due</mat-label>
                            <input matInput formControlName="balance_due" type="number" />
                        </mat-form-field>

                    </div>

                </div>

                <h2>Provider Details </h2>
                <div class="row">
                    <div class="col lg-3">
                        <!-- Provider Details -->
                        <mat-form-field appearance="fill">
                            <mat-label>Provider First Name</mat-label>
                            <input matInput formControlName="prov_name_f" required />
                        </mat-form-field>
                    </div>
                    <div class="col lg-3">

                        <mat-form-field appearance="fill">
                            <mat-label>Provider Last Name</mat-label>
                            <input matInput formControlName="prov_name_l" required />
                        </mat-form-field>
                    </div>
                    <div class="col lg-3">
                        <mat-form-field appearance="fill">
                            <mat-label>Provider Middle Name</mat-label>
                            <input matInput formControlName="prov_name_m" />
                        </mat-form-field>
                    </div>
                    <div class="col lg-3">
                        <mat-form-field appearance="fill">
                            <mat-label>Provider NPI</mat-label>
                            <input matInput formControlName="prov_npi" required />
                        </mat-form-field>
                    </div>
                    <div class="col lg-3">
                        <mat-form-field appearance="fill">
                            <mat-label>Provider Taxonomy</mat-label>
                            <input matInput formControlName="prov_taxonomy" required />
                        </mat-form-field>
                    </div>
                </div>
                <h2>Billing Provider Information</h2>

                <div class="row">

                    <div class="col lg-4">
                        <mat-form-field appearance="fill">
                            <mat-label>Billing Name</mat-label>
                            <input matInput formControlName="bill_name" required />
                            <mat-error *ngIf="claimForm.get('bill_name')?.hasError('required')">
                                Billing Name is required
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col lg-4">

                        <mat-form-field appearance="fill">
                            <mat-label>Billing Address</mat-label>
                            <input matInput formControlName="bill_addr_1" required />
                            <mat-error *ngIf="claimForm.get('bill_addr_1')?.hasError('required')">
                                Billing Address is required
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col lg-4">

                        <mat-form-field appearance="fill">
                            <mat-label>Billing City</mat-label>
                            <input matInput formControlName="bill_city" required />
                            <mat-error *ngIf="claimForm.get('bill_city')?.hasError('required')">
                                Billing City is required
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col lg-3">

                        <mat-form-field appearance="fill">
                            <mat-label>Billing State</mat-label>
                            <input matInput formControlName="bill_state" required />
                            <mat-error *ngIf="claimForm.get('bill_state')?.hasError('required')">
                                Billing State is required
                            </mat-error>
                        </mat-form-field>

                    </div>
                    <div class="col lg-3">
                        <mat-form-field appearance="fill">
                            <mat-label>Billing ZIP</mat-label>
                            <input matInput formControlName="bill_zip" required />
                            <mat-error *ngIf="claimForm.get('bill_zip')?.hasError('required')">
                                Billing ZIP is required
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col lg-3">

                        <mat-form-field appearance="fill">
                            <mat-label>Billing Phone</mat-label>
                            <input matInput formControlName="bill_phone" required />
                            <mat-error *ngIf="claimForm.get('bill_phone')?.hasError('required')">
                                Billing Phone is required
                            </mat-error>
                        </mat-form-field>

                    </div>
                    <div class="col lg-3">

                        <mat-form-field appearance="fill">
                            <mat-label>Billing NPI</mat-label>
                            <input matInput formControlName="bill_npi" required />
                            <mat-error *ngIf="claimForm.get('bill_npi')?.hasError('required')">
                                Billing NPI is required
                            </mat-error>
                        </mat-form-field>

                    </div>
                </div>

                <div class="row">
                    <div class="col lg-4">

                        <mat-form-field appearance="fill">
                            <mat-label> file-Id</mat-label>
                            <input matInput formControlName="remote_fileid" required />
                            <mat-error *ngIf="claimForm.get('remote_fileid')?.hasError('required')">
                                fileid is required
                            </mat-error>
                        </mat-form-field>

                    </div>
                    <div class="col lg-4">

                        <mat-form-field appearance="fill">
                            <mat-label>Batch Id</mat-label>
                            <input matInput formControlName="remote_batchid" required />
                            <mat-error *ngIf="claimForm.get('remote_batchid')?.hasError('required')">
                                batchid is required
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col lg-4">


                        <mat-form-field appearance="fill">
                            <mat-label>Claim Id</mat-label>
                            <input matInput formControlName="remote_claimid" required />
                            <mat-error *ngIf="claimForm.get('remote_claimid')?.hasError('required')">
                                claimid is required
                            </mat-error>
                        </mat-form-field>
                    </div>

                </div>


























            </div>



            <!-- Submit Button -->
            <div class="form-actions">
                <button mat-raised-button color="primary" type="submit">
                    Submit
                </button>
            </div>
        </mat-card>
    </form>
</div>