import { BrowserModule } from '@angular/platform-browser';
import { NgModule, isDevMode } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PatientdashboardComponent } from './components/patientdashboard/patientdashboard.component';
import { AdmindashboardComponent } from './components/admindashboard/admindashboard.component';
import { DoctordashboardComponent } from './components/doctordashboard/doctordashboard.component';
import { LabdashboardComponent } from './components/labdashboard/labdashboard.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material/material.module';
import { from } from 'rxjs';
import { MarkdownModule } from 'ngx-markdown';
import { QuillModule } from 'ngx-quill';

import { NgbModalDraggableModule } from 'ngb-modal-draggable';
import { NavbarComponent } from './navbar/navbar.component';

import { ErrorComponent } from './components/error/error.component';

import { MedicationComponent } from './components/medication/medication.component';
import { AddpatientComponent } from './components/addpatient/addpatient.component';
import { ViewpatientComponent } from './components/viewpatient/viewpatient.component';
import { UpdatepatientComponent } from './components/updatepatient/updatepatient.component';
import { AddDoctorComponent } from './components/add-doctor/add-doctor.component';
import { ViewDoctorComponent } from './components/view-doctor/view-doctor.component';
import { UpdateDoctorComponent } from './components/update-doctor/update-doctor.component';
import { AddPatientVisitComponent } from './components/add-patient-visit/add-patient-visit.component';
import { ViewPatientVisitComponent } from './components/view-patient-visit/view-patient-visit.component';
import { UpdatePatientVisitComponent } from './components/update-patient-visit/update-patient-visit.component';
import { PatientdetailsComponent } from './components/patientdetails/patientdetails.component';
import { NotepadComponent } from './components/notepad/notepad.component';
import { VewDoctorNoteComponent } from './components/vew-doctor-note/vew-doctor-note.component';
import { AddAllergyComponent } from './components/add-allergy/add-allergy.component';
import { ViewAllergyComponent } from './components/view-allergy/view-allergy.component';
import { UpdateAllergyComponent } from './components/update-allergy/update-allergy.component';
import { UpdatePrescriptionComponent } from './components/update-prescription/update-prescription.component';

import { AddPrescriptionComponent } from './components/add-prescription/add-prescription.component';
import { PrescriptionDisplayComponent } from './components/prescription-display/prescription-display.component';
import { AddSurgicalHistoryComponent } from './components/add-surgical-history/add-surgical-history.component';
import { ViewSurgicalHistoryComponent } from './components/view-surgical-history/view-surgical-history.component';
import { UpdateSurgicalHistoryComponent } from './components/update-surgical-history/update-surgical-history.component';
import { AddHospitalComponent } from './components/add-hospital/add-hospital.component';
import { UpdateHospitalComponent } from './components/update-hospital/update-hospital.component';
import { ViewHospitalComponent } from './components/view-hospital/view-hospital.component';
import { HospitaldetailsComponent } from './components/hospitaldetails/hospitaldetails.component';
import { HospitalDashboardComponent } from './components/hospital-dashboard/hospital-dashboard.component';
import { AddNurseComponent } from './components/add-nurse/add-nurse.component';
import { AddDepartmentComponent } from './components/add-department/add-department.component';
import { ViewDepartmentComponent } from './components/view-department/view-department.component';
import { AddWardComponent } from './components/add-ward/add-ward.component';
import { DepartmentDashboardComponent } from './components/department-dashboard/department-dashboard.component';
import { AddAppointmentComponent } from './components/add-appointment/add-appointment.component';
import { AddInvoiceComponent } from './components/add-invoice/add-invoice.component';
import { ViewInvoiceComponent } from './components/view-invoice/view-invoice.component';
import { AddLabreportComponent } from './components/add-labreport/add-labreport.component';
import { ViewLabreportComponent } from './components/view-labreport/view-labreport.component';
import { ViewAllInvoicesComponent } from './components/view-all-invoices/view-all-invoices.component';

import { EmergencyContactComponent } from './components/emergency-contact/emergency-contact.component';
import { AddEmergencyContactComponent } from './components/add-emergency-contact/add-emergency-contact.component';
import { AddPatientInsuranceComponent } from './components/add-patient-insurance/add-patient-insurance.component';
import { PatientsummaryComponent } from './components/patientsummary/patientsummary.component';
import { PtmedicalrecordsComponent } from './components/ptmedicalrecords/ptmedicalrecords.component';
import { PtinvoicesComponent } from './components/ptinvoices/ptinvoices.component';
import { ViewAppointmentComponent } from './components/view-appointment/view-appointment.component';
import { PatientComponent } from './components/patient/patient.component';
import { PrintinvoiceComponent } from './components/printinvoice/printinvoice.component';
import { ViewAllAppointmentsComponent } from './components/view-all-appointments/view-all-appointments.component';
import { UpdateAppointmentComponent } from './components/update-appointment/update-appointment.component';
import { UpdateInvoiceComponent } from './components/update-invoice/update-invoice.component';
import { ViewDoctorAppointmentsComponent } from './components/view-doctor-appointments/view-doctor-appointments.component';
import { ViewDoctorPatientsComponent } from './components/view-doctor-patients/view-doctor-patients.component';
import { ViewNurseComponent } from './components/view-nurse/view-nurse.component';
import { AddMedicineComponent } from './components/add-medicine/add-medicine.component';
import { AddCptcodeComponent } from './components/add-cptcode/add-cptcode.component';
import { ViewMedicineComponent } from './components/view-medicine/view-medicine.component';
import { UpdateMedicineComponent } from './components/update-medicine/update-medicine.component';
import { ViewCptcodeComponent } from './components/view-cptcode/view-cptcode.component';
import { DoctorPatientsComponent } from './components/doctor-patients/doctor-patients.component';
import { NursePatientsComponent } from './components/nurse-patients/nurse-patients.component';

import { UpdateNursePatientVisitComponent } from './components/update-nurse-patient-visit/update-nurse-patient-visit.component';
import { UpdateDoctorPatientVisitComponent } from './components/update-doctor-patient-visit/update-doctor-patient-visit.component';
import { DoctorPatientVisitComponent } from './components/doctor-patient-visit/doctor-patient-visit.component';
import { NurseDashboardComponent } from './components/nurse-dashboard/nurse-dashboard.component';
import { NursePatientDetailsComponent } from './components/nurse-patient-details/nurse-patient-details.component';
import { AddAppointmentPatientComponent } from './components/add-appointment-patient/add-appointment-patient.component';
import { AddMedicationComponent } from './components/add-medication/add-medication.component';
import { ViewMedicationComponent } from './components/view-medication/view-medication.component';
import { AddLabManagerComponent } from './components/add-lab-manager/add-lab-manager.component';
import { LabpatientComponent } from './components/labpatient/labpatient.component';
import { AddNursereportComponent } from './components/add-nursereport/add-nursereport.component';
import { ViewNursereportComponent } from './components/view-nursereport/view-nursereport.component';
import { UpdateNursereportComponent } from './components/update-nursereport/update-nursereport.component';
import { ViewPatientNursereportComponent } from './components/view-patient-nursereport/view-patient-nursereport.component';
import { UpdateMedicationComponent } from './components/update-medication/update-medication.component';
import { ViewNursePtMedicationComponent } from './components/view-nurse-pt-medication/view-nurse-pt-medication.component';
import { ResetpasswordComponent } from './components/resetpassword/resetpassword.component';
import { SetpasswordComponent } from './components/setpassword/setpassword.component';
import { ViewAppointmentsTodayComponent } from './components/view-appointments-today/view-appointments-today.component';
import { ViewDoctorAppointmentsTodayComponent } from './components/view-doctor-appointments-today/view-doctor-appointments-today.component';
import {
  DatePipe,
  HashLocationStrategy,
  LocationStrategy,
} from '@angular/common';
import { MatPaginatorModule } from '@angular/material/paginator';
import { DrawPadComponent } from './components/draw-pad/draw-pad.component';
import { ViewWardComponent } from './components/view-ward/view-ward.component';
import { ViewLabManagerComponent } from './components/view-lab-manager/view-lab-manager.component';
import { RoundbookComponent } from './components/roundbook/roundbook.component';
import { PtSummaryComponent } from './coponents/pt-summary/pt-summary.component';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatNativeDateModule } from '@angular/material/core';
import { TimeSlotComponent } from './components/time-slot/time-slot.component';
import { AddTimeSlotComponent } from './components/add-time-slot/add-time-slot.component';
import { AddLabOrderComponent } from './components/add-lab-order/add-lab-order.component';
import { ViewLabOrderComponent } from './components/view-lab-order/view-lab-order.component';
import { AddRadiologyOrderComponent } from './components/add-radiology-order/add-radiology-order.component';
import { ViewRadiologyOrderComponent } from './components/view-radiology-order/view-radiology-order.component';
import { ViewAllLabOrdersComponent } from './components/view-all-lab-orders/view-all-lab-orders.component';
import { ViewAllRadiologyOrdersComponent } from './components/view-all-radiology-orders/view-all-radiology-orders.component';

import { AddNursingAssesmentComponent } from './components/add-nursing-assesment/add-nursing-assesment.component';
import { AddDoctorDatesComponent } from './components/add-doctor-dates/add-doctor-dates.component';
import { ViewNurseAssessmentComponent } from './components/view-nurse-assessment/view-nurse-assessment.component';
import { AddInvoiceItemsComponent } from './components/add-invoice-items/add-invoice-items.component';
import { ViewNursingAssessmentByIdComponent } from './components/view-nursing-assessment-by-id/view-nursing-assessment-by-id.component';
import { ViewNursingAssessmentPlanComponent } from './components/view-nursing-assessment-plan/view-nursing-assessment-plan.component';
import { NotFoundComponentComponent } from './components/not-found-component/not-found-component.component';
import { VitalsChartComponent } from './components/vitals-chart/vitals-chart.component';
import { RadiologyDashboardComponent } from './components/radiology-dashboard/radiology-dashboard.component';
import { AddRadiologyMemberComponent } from './components/add-radiology-member/add-radiology-member.component';
import { ViewRadiologyMemberComponent } from './components/view-radiology-member/view-radiology-member.component';
import { AddRadiologyReportComponent } from './components/add-radiology-report/add-radiology-report.component';
import { ViewRadiologyReportComponent } from './components/view-radiology-report/view-radiology-report.component';
import { RadiologyPatientComponent } from './components/radiology-patient/radiology-patient.component';
import { SigningPadComponent } from './components/signing-pad/signing-pad.component';
import { ViewPatientInsuranceComponent } from './components/view-patient-insurance/view-patient-insurance.component';
import { AddPatientIdproofComponent } from './components/add-patient-idproof/add-patient-idproof.component';
import { ViewPatientIdproofComponent } from './components/view-patient-idproof/view-patient-idproof.component';
import { QRCodeModule } from 'angularx-qrcode';
import { AddMriComponent } from './components/add-mri/add-mri.component';
import { ViewMriComponent } from './components/view-mri/view-mri.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NgxDicomModule } from 'ngx-dicom';
import { StripeModule } from 'stripe-angular';

import { SuperAdminDashboardComponent } from './components/super-admin-dashboard/super-admin-dashboard.component';
import { ScannerComponent } from './components/scanner/scanner.component';
import { NgxScannerQrcodeModule } from 'ngx-scanner-qrcode';
import { WebcamModule } from 'ngx-webcam';
import { CompanyComponent } from './components/company/company.component';
import { AddCompanyComponent } from './components/add-company/add-company.component';
import { PatientImportComponent } from './components/patient-import/patient-import.component';
import { UpdateRadiologyReportComponent } from './components/update-radiology-report/update-radiology-report.component';
import { UploadRadiologyReportComponent } from './components/upload-radiology-report/upload-radiology-report.component';

import { CommonModule } from '@angular/common';
import { AllPatientsComponent } from './components/all-patients/all-patients.component';
import { AuthInterceptor } from './services/auth.interceptor';
import { PrintmedicationComponent } from './components/printmedication/printmedication.component';
import { ViewDoctorAvailabilityComponent } from './components/view-doctor-availability/view-doctor-availability.component';
import { AddRadiologyOrderSubjectComponent } from './components/add-radiology-order-subject/add-radiology-order-subject.component';
import { AddLabOrderSubjectComponent } from './components/add-lab-order-subject/add-lab-order-subject.component';
import { ViewLabOrderSubjectComponent } from './components/view-lab-order-subject/view-lab-order-subject.component';
import { ViewDischargeSummaryComponent } from './components/view-discharge-summary/view-discharge-summary.component';
import { AddVaccineComponent } from './components/add-vaccine/add-vaccine.component';
import { ViewVaccineComponent } from './components/view-vaccine/view-vaccine.component';
import { ViewNewLabOrderComponent } from './components/view-new-lab-order/view-new-lab-order.component';
import { ToastrModule } from 'ngx-toastr';
import { ViewNewRadiologyOrderComponent } from './components/view-new-radiology-order/view-new-radiology-order.component';
import { ViewIntakeAssessmentComponent } from './components/view-intake-assessment/view-intake-assessment.component';
import { ViewCommunicationAssessmentComponent } from './components/view-communication-assessment/view-communication-assessment.component';
import { AddCommunicationAssessmentComponent } from './components/add-communication-assessment/add-communication-assessment.component';
import { NursePatientReportsComponent } from './components/nurse-patient-reports/nurse-patient-reports.component';
import { MedTrackerComponent } from './components/med-tracker/med-tracker.component';
import { UpdateIntakeAssessmentComponent } from './components/update-intake-assessment/update-intake-assessment.component';
import { UpdateCommunicationAssessmentComponent } from './components/update-communication-assessment/update-communication-assessment.component';
import { AddDoctorReportComponent } from './components/add-doctor-report/add-doctor-report.component';
import { ViewDoctorReportComponent } from './components/view-doctor-report/view-doctor-report.component';
import { UpdateDoctorReportComponent } from './components/update-doctor-report/update-doctor-report.component';
import { AddNurseNoteComponent } from './components/add-nurse-note/add-nurse-note.component';
import { ViewNurseNoteComponent } from './components/view-nurse-note/view-nurse-note.component';
import { UpdateNurseNoteComponent } from './components/update-nurse-note/update-nurse-note.component';
import { PatientIntakeItemsComponent } from './components/patient-intake-items/patient-intake-items.component';
import { TwofaComponent } from './components/twofa/twofa.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { AddSocialhistoryComponent } from './components/add-socialhistory/add-socialhistory.component';
import { ViewSocialhistoryComponent } from './components/view-socialhistory/view-socialhistory.component';
import { UpdateSocialhistoryComponent } from './components/update-socialhistory/update-socialhistory.component';
import { AddFamilyhistoryComponent } from './components/add-familyhistory/add-familyhistory.component';
import { ViewFamilyhistoryComponent } from './components/view-familyhistory/view-familyhistory.component';
import { UpdateFamilyhistoryComponent } from './components/update-familyhistory/update-familyhistory.component';
import { AddMedicalHistoryComponent } from './components/add-medical-history/add-medical-history.component';
import { ViewMedicalHistoryComponent } from './components/view-medical-history/view-medical-history.component';
import { AddHippaComplianceComponent } from './components/add-hippa-compliance/add-hippa-compliance.component';
import { ViewHippaComplianceComponent } from './components/view-hippa-compliance/view-hippa-compliance.component';

import { SmsComponent } from './components/sms/sms.component';
import { EmailComponent } from './components/email/email.component';
import { AddPrescriptionArrayComponent } from './components/add-prescription-array/add-prescription-array.component';
import { ViewRadiologyOrderSubjectComponent } from './components/view-radiology-order-subject/view-radiology-order-subject.component';
import { PrintlabreportComponent } from './components/printlabreport/printlabreport.component';
import { AddDoctorInitialEvaluationComponent } from './components/add-doctor-initial-evaluation/add-doctor-initial-evaluation.component';
import { ViewDoctorInitialEvaluationComponent } from './components/view-doctor-initial-evaluation/view-doctor-initial-evaluation.component';
import { UpdateDoctorInitialEvaluationComponent } from './components/update-doctor-initial-evaluation/update-doctor-initial-evaluation.component';
import { AddNurseDailyAssessmentComponent } from './components/add-nurse-daily-assessment/add-nurse-daily-assessment.component';
import { ViewNurseDailyAssessmentComponent } from './components/view-nurse-daily-assessment/view-nurse-daily-assessment.component';
import { UpdateNurseDailyAssessmentComponent } from './components/update-nurse-daily-assessment/update-nurse-daily-assessment.component';
import { UpdateNurseHandOffNoteComponent } from './components/update-nurse-hand-off-note/update-nurse-hand-off-note.component';
import { ViewNurseHandOffNoteByWardComponent } from './components/view-nurse-hand-off-note-by-ward/view-nurse-hand-off-note-by-ward.component';
import { ViewWardPatientsComponent } from './components/view-ward-patients/view-ward-patients.component';
import { LabTestChartComponent } from './components/lab-test-chart/lab-test-chart.component';
import { UpdateSafetyManagementComponent } from './components/update-safety-management/update-safety-management.component';
import { ViewDutyRosterByShiftComponent } from './components/view-duty-roster-by-shift/view-duty-roster-by-shift.component';
import { ViewBloodTransfusionConsentComponent } from './components/view-blood-transfusion-consent/view-blood-transfusion-consent.component';
import { AddBloodTransfusionConsentComponent } from './components/add-blood-transfusion-consent/add-blood-transfusion-consent.component';
import { AddDisclosureAndConsentComponent } from './components/add-disclosure-and-consent/add-disclosure-and-consent.component';
import { ViewDisclosureAndConsentComponent } from './components/view-disclosure-and-consent/view-disclosure-and-consent.component';
import { UpdateDisclosureAndConsentComponent } from './components/update-disclosure-and-consent/update-disclosure-and-consent.component';
import { AddMriScreeningComponent } from './components/add-mri-screening/add-mri-screening.component';
import { ViewMriScreeningComponent } from './components/view-mri-screening/view-mri-screening.component';
import { UpdateMriScreeningComponent } from './components/update-mri-screening/update-mri-screening.component';
import { AddPastmedicationComponent } from './components/add-pastmedication/add-pastmedication.component';
import { ViewPastmedicationComponent } from './components/view-pastmedication/view-pastmedication.component';
import { UpdatePastmedicationComponent } from './components/update-pastmedication/update-pastmedication.component';
import { ViewPatientLabreportComponent } from './components/view-patient-labreport/view-patient-labreport.component';
import { ViewPatientImmunizationComponent } from './components/view-patient-immunization/view-patient-immunization.component';
import { ViewPatientPharmacyComponent } from './components/view-patient-pharmacy/view-patient-pharmacy.component';
import { AddPatientPharmacyComponent } from './components/add-patient-pharmacy/add-patient-pharmacy.component';
import { UpdatePatientPharmacyComponent } from './components/update-patient-pharmacy/update-patient-pharmacy.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ViewRefferalComponent } from './components/view-refferal/view-refferal.component';
import { AddRefferalComponent } from './components/add-refferal/add-refferal.component';
import { AddUploadLabReporComponent } from './components/add-upload-lab-repor/add-upload-lab-repor.component';
import { AddUploadRadiologyReportComponent } from './components/add-upload-radiology-report/add-upload-radiology-report.component';
import { ViewUploadRadiologyReportComponent } from './components/view-upload-radiology-report/view-upload-radiology-report.component';
import { ViewUploadLabReportComponent } from './components/view-upload-lab-report/view-upload-lab-report.component';
import { AddPhotographicConsentComponent } from './components/add-photographic-consent/add-photographic-consent.component';
import { ViewPhotographicConsentComponent } from './components/view-photographic-consent/view-photographic-consent.component';
import { ViewOfficePolicyConsentComponent } from './components/view-office-policy-consent/view-office-policy-consent.component';
import { AddOfficePolicyConsentComponent } from './components/add-office-policy-consent/add-office-policy-consent.component';
import { AddGeneralConsentForTreatmentComponent } from './components/add-general-consent-for-treatment/add-general-consent-for-treatment.component';
import { ViewGeneralConsentForTreatmentComponent } from './components/view-general-consent-for-treatment/view-general-consent-for-treatment.component';
import { ViewNarcoticMedicalPolicyAgreementComponent } from './components/view-narcotic-medical-policy-agreement/view-narcotic-medical-policy-agreement.component';
import { AddNarcoticMedicalPolicyAgreementComponent } from './components/add-narcotic-medical-policy-agreement/add-narcotic-medical-policy-agreement.component';
import { PatientViewPastmedicationComponent } from './components/patient-view-pastmedication/patient-view-pastmedication.component';
import { PatientViewMedicalhistoryComponent } from './components/patient-view-medicalhistory/patient-view-medicalhistory.component';
import { PatientViewFamilyhistoryComponent } from './components/patient-view-familyhistory/patient-view-familyhistory.component';
import { PatientViewSocialhistoryComponent } from './components/patient-view-socialhistory/patient-view-socialhistory.component';
import { PatientViewIdproofComponent } from './components/patient-view-idproof/patient-view-idproof.component';
import { PatientViewHipaacomplianceComponent } from './components/patient-view-hipaacompliance/patient-view-hipaacompliance.component';
import { PatientViewNarcoticConsentComponent } from './components/patient-view-narcotic-consent/patient-view-narcotic-consent.component';
import { PatientViewGeneralConsentComponent } from './components/patient-view-general-consent/patient-view-general-consent.component';
import { PatientViewOfficePolicyComponent } from './components/patient-view-office-policy/patient-view-office-policy.component';
import { PatientViewPhotographicConsentComponent } from './components/patient-view-photographic-consent/patient-view-photographic-consent.component';
import { CompletedFormsComponent } from './components/completed-forms/completed-forms.component';
import { MatSelectSearchModule } from 'mat-select-search';
import { ViewAllhospitalsComponent } from './components/view-allhospitals/view-allhospitals.component';
import { TwofaResetPassowrdComponent } from './twofa-reset-passowrd/twofa-reset-passowrd.component';
import { PrintInvoicePtSideComponent } from './components/print-invoice-pt-side/print-invoice-pt-side.component';
import { UpdateAppointmentByDoctorComponent } from './components/update-appointment-by-doctor/update-appointment-by-doctor.component';
import { ViewNursePatientsByHospitalComponent } from './components/view-nurse-patients-by-hospital/view-nurse-patients-by-hospital.component';
import { WritingPadComponent } from './components/writing-pad/writing-pad.component';
import { AddPaymentMethodComponent } from './components/add-payment-method/add-payment-method.component';
import { ViewPaymentMethodComponent } from './components/view-payment-method/view-payment-method.component';
import { UpdatePaymentMethodComponent } from './components/update-payment-method/update-payment-method.component';
import { AddAppointmentByDoctorComponent } from './components/add-appointment-by-doctor/add-appointment-by-doctor.component';
import { AddRefferalByDoctorComponent } from './components/add-refferal-by-doctor/add-refferal-by-doctor.component';
import { PatientHasBalanceTopayComponent } from './components/patient-has-balance-topay/patient-has-balance-topay.component';
import { PatientHistoryComponent } from './components/patient-history/patient-history.component';
import { PharmacylistComponent } from './components/pharmacylist/pharmacylist.component';
import { UpdatePharmacylistComponent } from './components/update-pharmacylist/update-pharmacylist.component';
import { AddPaharmacyComponent } from './components/add-paharmacy/add-paharmacy.component';
import { ViewPrescriptionsTodayComponent } from './components/view-prescriptions-today/view-prescriptions-today.component';
import { AddPatientPrescriptionComponent } from './components/add-patient-prescription/add-patient-prescription.component';
import { ViewPatientPrescriptiionComponent } from './components/view-patient-prescriptiion/view-patient-prescriptiion.component';
import { UpdatePatientPrescriptiionComponent } from './components/update-patient-prescriptiion/update-patient-prescriptiion.component';
import { StaffViewPatientPrescriptionComponent } from './components/staff-view-patient-prescription/staff-view-patient-prescription.component';
import { PatientViewPatientPrescriptionComponent } from './components/patient-view-patient-prescription/patient-view-patient-prescription.component';
import { StaffViewPrescriptiondisplayComponent } from './components/staff-view-prescriptiondisplay/staff-view-prescriptiondisplay.component';
import { AddPrescriptionByNurseComponent } from './components/add-prescription-by-nurse/add-prescription-by-nurse.component';
import { ViewPrescriptionByNurseComponent } from './components/view-prescription-by-nurse/view-prescription-by-nurse.component';
import { AddRefillRequestComponent } from './components/add-refill-request/add-refill-request.component';
import { ViewRefillRequestComponent } from './components/view-refill-request/view-refill-request.component';
import { AppovePrescriptionComponent } from './components/appove-prescription/appove-prescription.component';
import { ViewRefillRequestByNurseComponent } from './components/view-refill-request-by-nurse/view-refill-request-by-nurse.component';
import { AppovePrescriptionByNurseComponent } from './components/appove-prescription-by-nurse/appove-prescription-by-nurse.component';
import { AddPatientPrescriptionByNurseComponent } from './components/add-patient-prescription-by-nurse/add-patient-prescription-by-nurse.component';
import { ViewPatientPrescriptionByNurseComponent } from './components/view-patient-prescription-by-nurse/view-patient-prescription-by-nurse.component';
import { CardViewPrescriptionComponent } from './components/card-view-prescription/card-view-prescription.component';
import { AddProcedureComponent } from './components/add-procedure/add-procedure.component';
import { ViewProcedureComponent } from './components/view-procedure/view-procedure.component';
import { UpdateProcedureComponent } from './components/update-procedure/update-procedure.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { AddTestResultsComponent } from './components/add-test-results/add-test-results.component';
import { ViewTestResultsComponent } from './components/view-test-results/view-test-results.component';
import { UpdateTestResultsComponent } from './components/update-test-results/update-test-results.component';
import { AddNerveConductionStudyComponent } from './components/add-nerve-conduction-study/add-nerve-conduction-study.component';
import { ViewNerveConductionStudyComponent } from './components/view-nerve-conduction-study/view-nerve-conduction-study.component';
import { UpdateNerveConductionStudyComponent } from './components/update-nerve-conduction-study/update-nerve-conduction-study.component';
import { AddNerveStudySubtypeComponent } from './components/add-nerve-study-subtype/add-nerve-study-subtype.component';
import { AddNerveStudyResultsComponent } from './components/add-nerve-study-results/add-nerve-study-results.component';
import { ViewNerveStudySubtypeComponent } from './components/view-nerve-study-subtype/view-nerve-study-subtype.component';
import { ViewNerveStudyResultsComponent } from './components/view-nerve-study-results/view-nerve-study-results.component';
import { UpdateNerveStudyResultsComponent } from './components/update-nerve-study-results/update-nerve-study-results.component';
import { AddElectromyographyComponent } from './components/add-electromyography/add-electromyography.component';
import { ViewElectromyographyComponent } from './components/view-electromyography/view-electromyography.component';
import { UpdateElectromyographyComponent } from './components/update-electromyography/update-electromyography.component';
import { PatientHasUnpaidAmountComponent } from './components/patient-has-unpaid-amount/patient-has-unpaid-amount.component';
import { PatientHasInsurancePendingPaymentComponent } from './components/patient-has-insurance-pending-payment/patient-has-insurance-pending-payment.component';
import { AddPatientVisitByNurseComponent } from './components/add-patient-visit-by-nurse/add-patient-visit-by-nurse.component';
import { BulkImportPatientComponent } from './components/bulk-import-patient/bulk-import-patient.component';
import { InvoiceHasABalanceComponent } from './components/invoice-has-a-balance/invoice-has-a-balance.component';
import { FaxComponent } from './components/fax/fax.component';
import { UpdatePrescriptionByNurseComponent } from './components/update-prescription-by-nurse/update-prescription-by-nurse.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { ViewPaymentHistoryComponent } from './components/view-payment-history/view-payment-history.component';
import { ViewPatientPaymentComponent } from './components/view-patient-payment/view-patient-payment.component';
import { ViewDoctorProfileComponent } from './components/view-doctor-profile/view-doctor-profile.component';
import { UpdateDoctorProfileComponent } from './components/update-doctor-profile/update-doctor-profile.component';
import { AddMyOffDaysComponent } from './components/add-my-off-days/add-my-off-days.component';
import { ViewMyOffDaysComponent } from './components/view-my-off-days/view-my-off-days.component';
import { ChatComponent } from './components/chat/chat.component';
import { ViewAppointmentStatusComponent } from './components/view-appointment-status/view-appointment-status.component';
import { ManualyConfirmAppointmentComponent } from './components/manualy-confirm-appointment/manualy-confirm-appointment.component';
import { DoctorPatientSplitScreenComponent } from './components/doctor-patient-split-screen/doctor-patient-split-screen.component';
import { TabsComponentComponent } from './components/tabs-component/tabs-component.component';
import { ModalModule } from 'ng-modal-lib';

import { NewOrdersComponent } from './components/new-orders/new-orders.component';
import { ViewOrdersComponent } from './components/view-orders/view-orders.component';
import { NewTestResultsComponent } from './components/new-test-results/new-test-results.component';
import { AllTestResultsComponent } from './components/all-test-results/all-test-results.component';
import { Tabs2ComponentComponent } from './components/tabs2-component/tabs2-component.component';
import { CallEncounterComponent } from './components/call-encounter/call-encounter.component';
import { UpdateRefferalComponent } from './components/update-refferal/update-refferal.component';
import { RecentTransactionsComponent } from './components/recent-transactions/recent-transactions.component';
import { UserActivityLogComponent } from './components/user-activity-log/user-activity-log.component';
import { DoctorCustomTemplateComponent } from './components/doctor-custom-template/doctor-custom-template.component';
import { AddPrescriptionFreeTextComponent } from './components/add-prescription-free-text/add-prescription-free-text.component';
import { CardViewPrescriptionPatientSideComponent } from './components/card-view-prescription-patient-side/card-view-prescription-patient-side.component';
import { DoctorTemplateEditorComponent } from './components/doctor-template-editor/doctor-template-editor.component';
import { DoctorTemplateListComponent } from './components/doctor-template-list/doctor-template-list.component';
import { CalculatorComponent } from './components/calculator/calculator.component';
import { TemplateTextboxComponentComponent } from './components/template-textbox-component/template-textbox-component.component';
import { UserActivityComponent } from './components/user-activity/user-activity.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { HospitalTemplateComponent } from './components/hospital-template/hospital-template.component';
import { UpdateDoctorTemplateEditorComponent } from './components/update-doctor-template-editor/update-doctor-template-editor.component';
import { ChatForNurseComponent } from './components/chat-for-nurse/chat-for-nurse.component';
import { AddAppointmentHospitalComponent } from './components/add-appointment-hospital/add-appointment-hospital.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DoctorPatientSplitViewComponent } from './components/doctor-patient-split-view/doctor-patient-split-view.component';
import { DicomViewerComponent } from './components/dicom-viewer/dicom-viewer.component';
import { VoiceToTextComponent } from './components/voice-to-text/voice-to-text.component';
import { NursePatientTabAppearanceComponent } from './components/nurse-patient-tab-appearance/nurse-patient-tab-appearance.component';
import { TabNurseSideComponent } from './components/tab-nurse-side/tab-nurse-side.component';
import { Tab2NurseSideComponent } from './components/tab2-nurse-side/tab2-nurse-side.component';
import { Tab2ClinicSideComponent } from './components/tab2-clinic-side/tab2-clinic-side.component';
import { PatientTabAppearanceComponent } from './components/patient-tab-appearance/patient-tab-appearance.component';
import { DoctorPatientInvoicesComponent } from './components/doctor-patient-invoices/doctor-patient-invoices.component';
import { MessageComponent } from './components/message/message.component';
import { ChatForPatientComponent } from './components/chat-for-patient/chat-for-patient.component';
import { TabClinicSideComponent } from './components/tab-clinic-side/tab-clinic-side.component';
import { AuditLogComponent } from './components/audit-log/audit-log.component';
import { SplitAComponent } from './components/split-a/split-a.component';
import { SplitBComponent } from './components/split-b/split-b.component';
import { SubscriptionComponent } from './components/subscription/subscription.component';
import { TwofaDeaComponent } from './components/twofa-dea/twofa-dea.component';
import { AddDoctorProgressNoteComponent } from './components/add-doctor-progress-note/add-doctor-progress-note.component';
import { AddNurseProgressNoteComponent } from './components/add-nurse-progress-note/add-nurse-progress-note.component';
import { AddNurseTemplatesComponent } from './components/add-nurse-templates/add-nurse-templates.component';
import { ViewNurseTemplatesComponent } from './components/view-nurse-templates/view-nurse-templates.component';
import { UpdateNurseTemplatesComponent } from './components/update-nurse-templates/update-nurse-templates.component';
import { UpdateNurseProgressNoteComponent } from './components/update-nurse-progress-note/update-nurse-progress-note.component';
import { ViewNurseProgressNoteComponent } from './components/view-nurse-progress-note/view-nurse-progress-note.component';
import { AddPrescriptionMultipleComponent } from './components/add-prescription-multiple/add-prescription-multiple.component';
import { SignatureComponent } from './components/signature/signature.component';
import { ViewPrescriptionRequestedByNurseComponent } from './components/view-prescription-requested-by-nurse/view-prescription-requested-by-nurse.component';
import { AddVitalsByDoctorComponent } from './components/add-vitals-by-doctor/add-vitals-by-doctor.component';
import { QrScannerComponent } from './components/qr-scanner/qr-scanner.component';
import { AddReviewOfSystemComponent } from './components/add-review-of-system/add-review-of-system.component';
import { ViewReviewOfSystemComponent } from './components/view-review-of-system/view-review-of-system.component';
import { UpdateReviewOfSystemComponent } from './components/update-review-of-system/update-review-of-system.component';
import { UpdateHospitaltemplateComponent } from './components/update-hospitaltemplate/update-hospitaltemplate.component';
import { ViewHospitaltemplateComponent } from './components/view-hospitaltemplate/view-hospitaltemplate.component';
import { QrScannerNurseComponent } from './components/qr-scanner-nurse/qr-scanner-nurse.component';
import { RosterComponent } from './components/roster/roster.component';
import { AddRosterComponent } from './components/add-roster/add-roster.component';
import { UpdateRosterComponent } from './components/update-roster/update-roster.component';
import { AddDutyComponent } from './components/add-duty/add-duty.component';
import { ViewDutyComponent } from './components/view-duty/view-duty.component';
import { UpdateDutyComponent } from './components/update-duty/update-duty.component';
import { DrawToolComponent } from './components/draw-tool/draw-tool.component';
import { AddPainChartComponent } from './components/add-pain-chart/add-pain-chart.component';
import { ViewPainChartComponent } from './components/view-pain-chart/view-pain-chart.component';
import { AddGeneralUploadComponent } from './components/add-general-upload/add-general-upload.component';
import { ViewGeneralUploadComponent } from './components/view-general-upload/view-general-upload.component';
import { PatientSideProgressNoteComponent } from './components/patient-side-progress-note/patient-side-progress-note.component';
import { PatientSearchComponent } from './components/patient-search/patient-search.component';
import { NurseSideSplitAComponent } from './components/nurse-side-split-a/nurse-side-split-a.component';
import { NurseSideSplitBComponent } from './components/nurse-side-split-b/nurse-side-split-b.component';
import {
  MinutesSecondsPipe,
  VideoCallComponent,
} from './components/video-call/video-call.component';
import { FormBuilderComponent } from './components/form-builder/form-builder.component';
import { ViewMriMultipleComponent } from './components/view-mri-multiple/view-mri-multiple.component';
import { ViewFavoritePrescriptionComponent } from './components/view-favorite-prescription/view-favorite-prescription.component';
import { UpdateFavoritePrescriptionComponent } from './components/update-favorite-prescription/update-favorite-prescription.component';
import { PrintinvoiceDoctorPatientComponent } from './components/printinvoice-doctor-patient/printinvoice-doctor-patient.component';
import { AddInvoiceOnProgressNoteComponent } from './components/add-invoice-on-progress-note/add-invoice-on-progress-note.component';
import { AddRadiologyReportPeachRecogintionEnabledComponent } from './components/add-radiology-report-peach-recogintion-enabled/add-radiology-report-peach-recogintion-enabled.component';
import { ViewRadiologyReportPeachRecogintionEnabledComponent } from './components/view-radiology-report-peach-recogintion-enabled/view-radiology-report-peach-recogintion-enabled.component';
import { ClinicalAssistanceComponent } from './components/clinical-assistance/clinical-assistance.component';
import { ViewFavoriteDignosisCodeComponent } from './components/view-favorite-dignosis-code/view-favorite-dignosis-code.component';
import { UpdateFavoriteDignosisCodeComponent } from './components/update-favorite-dignosis-code/update-favorite-dignosis-code.component';
import { FavoriteDignosisCodeComponent } from './components/favorite-dignosis-code/favorite-dignosis-code.component';
import { ViewIcdCodesComponent } from './components/view-icd-codes/view-icd-codes.component';
import { AddIcdCodeComponent } from './components/add-icd-code/add-icd-code.component';
import { FavoriteDiagnosisPopupComponent } from './components/favorite-diagnosis-popup/favorite-diagnosis-popup.component';
import { BillerDashBoardComponent } from './components/biller-dash-board/biller-dash-board.component';
import { MedicalAssistantDashBoardComponent } from './components/medical-assistant-dash-board/medical-assistant-dash-board.component';
import { FrontDeskDashBoardComponent } from './components/front-desk-dash-board/front-desk-dash-board.component';
import { PracticeManagerDashBoardComponent } from './components/practice-manager-dash-board/practice-manager-dash-board.component';
import { AddPracticeManagerComponent } from './components/add-practice-manager/add-practice-manager.component';
import { ViewPracticeManagerComponent } from './components/view-practice-manager/view-practice-manager.component';
import { UpdatePracticeManagerComponent } from './components/update-practice-manager/update-practice-manager.component';
import { AddBillerComponent } from './components/add-biller/add-biller.component';
import { ViewBillerComponent } from './components/view-biller/view-biller.component';
import { UpdateBillerComponent } from './components/update-biller/update-biller.component';
import { AddMedicalAssistantComponent } from './components/add-medical-assistant/add-medical-assistant.component';
import { ViewMedicalAssistantComponent } from './components/view-medical-assistant/view-medical-assistant.component';
import { UpdateMedicalAssistantComponent } from './components/update-medical-assistant/update-medical-assistant.component';
import { ViewFrontDeskComponent } from './components/view-front-desk/view-front-desk.component';
import { UpdateFrontDeskComponent } from './components/update-front-desk/update-front-desk.component';
import { BillerPatientComponent } from './components/biller-patient/biller-patient.component';
import { BillerSplitBComponent } from './components/biller-split-b/biller-split-b.component';
import { BillerSplitAComponent } from './components/biller-split-a/biller-split-a.component';
import { AddInvoiceByBillerComponent } from './components/add-invoice-by-biller/add-invoice-by-biller.component';
import { Tab1BillerComponent } from './components/tab1-biller/tab1-biller.component';
import { Tab2BillerComponent } from './components/tab2-biller/tab2-biller.component';
import { ViewAllInvoiceByBillerComponent } from './components/view-all-invoice-by-biller/view-all-invoice-by-biller.component';
import { PatientHasBalanceViewByBillerComponent } from './components/patient-has-balance-view-by-biller/patient-has-balance-view-by-biller.component';
import { PatientHasUnpaindAmountViewByBillerComponent } from './components/patient-has-unpaind-amount-view-by-biller/patient-has-unpaind-amount-view-by-biller.component';
import { AddFrontDeskStaffComponent } from './components/add-front-desk-staff/add-front-desk-staff.component';
import { ViewFrontDeskStaffComponent } from './components/view-front-desk-staff/view-front-desk-staff.component';
import { UpdateFrontDeskStaffComponent } from './components/update-front-desk-staff/update-front-desk-staff.component';
import { AddAppointmentByFrontDeskStaffComponent } from './components/add-appointment-by-front-desk-staff/add-appointment-by-front-desk-staff.component';
import { Tab1PatientComponent } from './components/tab1-patient/tab1-patient.component';
import { Tab2PatientComponent } from './components/tab2-patient/tab2-patient.component';
import { SplitAPatientComponent } from './components/split-a-patient/split-a-patient.component';
import { SplitBPatientComponent } from './components/split-b-patient/split-b-patient.component';
import { ViewAllAppointmentsFrontDeskComponent } from './components/view-all-appointments-front-desk/view-all-appointments-front-desk.component';
import { ViewAppointmentsTodayFrontdeskComponent } from './components/view-appointments-today-frontdesk/view-appointments-today-frontdesk.component';
import { UpdateNurseComponent } from './components/update-nurse/update-nurse.component';
import { UpdateInsuranceComponent } from './components/update-insurance/update-insurance.component';
import { EligibilityCheckComponent } from './components/eligibility-check/eligibility-check.component';
import { ClaimComponent } from './components/claim/claim.component';
import { ViewDoctorReportDetailsComponent } from './components/view-doctor-report-details/view-doctor-report-details.component';
import { PaymentComponent } from './components/payment/payment.component';
import { ViewPatientPaymentByHospitalComponent } from './components/view-patient-payment-by-hospital/view-patient-payment-by-hospital.component';
import { DrugInfoComponent } from './components/drug-info/drug-info.component';
import { AddVisitSummaryComponent } from './components/add-visit-summary/add-visit-summary.component';
import { ViewVisitSummaryComponent } from './components/view-visit-summary/view-visit-summary.component';
import { UpdateVisitSummaryComponent } from './components/update-visit-summary/update-visit-summary.component';
import { ViewVisitSummaryPatientSideComponent } from './components/view-visit-summary-patient-side/view-visit-summary-patient-side.component';
import { ClaimStatusComponent } from './components/claim-status/claim-status.component';
import { Cms1500Component } from './components/cms1500/cms1500.component';
import { AddServiceRequestComponent } from './components/add-service-request/add-service-request.component';
import { UpdateServiceRequestComponent } from './components/update-service-request/update-service-request.component';
import { ViewServiceRequestComponent } from './components/view-service-request/view-service-request.component';
import { ViewCms1500Component } from './components/view-cms1500/view-cms1500.component';
import { UpdateCms1500Component } from './components/update-cms1500/update-cms1500.component';
import { ItemsModalComponent } from './components/items-modal/items-modal.component';
import { UpdateClaimComponent } from './components/update-claim/update-claim.component';
import { EmergencyAccessComponent } from './components/emergency-access/emergency-access.component';
import { EraListComponent } from './components/era-list/era-list.component';
import { EraPdfComponent } from './components/era-pdf/era-pdf.component';
import { ProviderEnrollmentComponent } from './components/provider-enrollment/provider-enrollment.component';
import { ClaimNotesComponent } from './components/claim-notes/claim-notes.component';

import { ViewClaimAccountKeyComponent } from './components/view-claim-account-key/view-claim-account-key.component';
import { UpdateClaimAccountKeyComponent } from './components/update-claim-account-key/update-claim-account-key.component';
import { AddClaimAccountKeyComponent } from './components/add-claim-account-key/add-claim-account-key.component';
import { TwoFaEmergencyAccessComponent } from './components/two-fa-emergency-access/two-fa-emergency-access.component';
import { EmergencyAccessPhoneAuthComponent } from './components/emergency-access-phone-auth/emergency-access-phone-auth.component';
import { ClaimAttachmentComponent } from './components/claim-attachment/claim-attachment.component';
import { SubscriptionPlanComponent } from './components/subscription-plan/subscription-plan.component';
import { SubscriptionGuard } from './services/subscription.guard';
import { AllSubscriptionsComponent } from './components/all-subscriptions/all-subscriptions.component';
import { UpdateLabReportComponent } from './components/update-lab-report/update-lab-report.component';

@NgModule({
  declarations: [
    MinutesSecondsPipe,
    AppComponent,
    LoginComponent,
    PatientdashboardComponent,
    AdmindashboardComponent,
    DoctordashboardComponent,
    LabdashboardComponent,
    NavbarComponent,
    ErrorComponent,
    MedicationComponent,
    AddpatientComponent,
    ViewpatientComponent,
    UpdatepatientComponent,
    AddDoctorComponent,
    ViewDoctorComponent,
    UpdateDoctorComponent,
    AddPatientVisitComponent,
    ViewPatientVisitComponent,
    UpdatePatientVisitComponent,
    PatientdetailsComponent,
    NotepadComponent,
    VewDoctorNoteComponent,
    AddAllergyComponent,
    ViewAllergyComponent,
    UpdateAllergyComponent,
    UpdatePrescriptionComponent,
    AddPrescriptionComponent,
    PrescriptionDisplayComponent,
    AddSurgicalHistoryComponent,
    ViewSurgicalHistoryComponent,
    UpdateSurgicalHistoryComponent,
    AddHospitalComponent,
    UpdateHospitalComponent,
    ViewHospitalComponent,
    HospitaldetailsComponent,
    HospitalDashboardComponent,
    AddNurseComponent,
    AddDepartmentComponent,
    ViewDepartmentComponent,
    AddWardComponent,
    DepartmentDashboardComponent,
    AddAppointmentComponent,
    AddInvoiceComponent,
    ViewInvoiceComponent,
    AddLabreportComponent,
    ViewLabreportComponent,
    ViewAllInvoicesComponent,
    EmergencyContactComponent,
    AddEmergencyContactComponent,
    AddPatientInsuranceComponent,
    PatientsummaryComponent,
    PtmedicalrecordsComponent,
    PtinvoicesComponent,
    ViewAppointmentComponent,
    PatientComponent,
    PrintinvoiceComponent,
    ViewAllAppointmentsComponent,
    UpdateAppointmentComponent,
    UpdateInvoiceComponent,
    ViewDoctorAppointmentsComponent,
    ViewDoctorPatientsComponent,
    ViewNurseComponent,
    AddMedicineComponent,
    AddCptcodeComponent,
    ViewMedicineComponent,
    UpdateMedicineComponent,
    ViewCptcodeComponent,
    DoctorPatientsComponent,
    NursePatientsComponent,
    UpdateNursePatientVisitComponent,
    UpdateDoctorPatientVisitComponent,
    DoctorPatientVisitComponent,
    NurseDashboardComponent,
    NursePatientDetailsComponent,
    AddAppointmentPatientComponent,
    AddMedicationComponent,
    ViewMedicationComponent,
    AddLabManagerComponent,
    LabpatientComponent,
    AddNursereportComponent,
    ViewNursereportComponent,
    UpdateNursereportComponent,
    ViewPatientNursereportComponent,
    UpdateMedicationComponent,
    ViewNursePtMedicationComponent,
    ResetpasswordComponent,
    SetpasswordComponent,
    ViewAppointmentsTodayComponent,
    ViewDoctorAppointmentsTodayComponent,
    DrawPadComponent,
    ViewWardComponent,

    ViewLabManagerComponent,
    RoundbookComponent,
    PtSummaryComponent,
    TimeSlotComponent,
    AddTimeSlotComponent,
    AddLabOrderComponent,
    ViewLabOrderComponent,
    AddRadiologyOrderComponent,
    ViewRadiologyOrderComponent,
    ViewAllLabOrdersComponent,
    ViewAllRadiologyOrdersComponent,

    AddNursingAssesmentComponent,

    AddDoctorDatesComponent,

    ViewNurseAssessmentComponent,

    AddInvoiceItemsComponent,

    ViewNursingAssessmentByIdComponent,

    ViewNursingAssessmentPlanComponent,

    NotFoundComponentComponent,

    VitalsChartComponent,

    RadiologyDashboardComponent,

    AddRadiologyMemberComponent,

    ViewRadiologyMemberComponent,

    AddRadiologyReportComponent,

    ViewRadiologyReportComponent,

    RadiologyPatientComponent,

    SigningPadComponent,

    ViewPatientInsuranceComponent,

    AddPatientIdproofComponent,

    ViewPatientIdproofComponent,

    AddMriComponent,

    ViewMriComponent,

    SuperAdminDashboardComponent,
    ScannerComponent,
    CompanyComponent,
    AddCompanyComponent,
    PatientImportComponent,
    UpdateRadiologyReportComponent,
    UploadRadiologyReportComponent,
    AllPatientsComponent,
    PrintmedicationComponent,

    ViewDoctorAvailabilityComponent,
    AddRadiologyOrderSubjectComponent,
    AddLabOrderSubjectComponent,
    ViewLabOrderSubjectComponent,
    ViewDischargeSummaryComponent,

    AddVaccineComponent,
    ViewVaccineComponent,
    ViewNewLabOrderComponent,

    ViewNewRadiologyOrderComponent,
    ViewIntakeAssessmentComponent,
    ViewCommunicationAssessmentComponent,
    AddCommunicationAssessmentComponent,
    NursePatientReportsComponent,
    MedTrackerComponent,
    UpdateIntakeAssessmentComponent,
    UpdateCommunicationAssessmentComponent,
    AddDoctorReportComponent,
    ViewDoctorReportComponent,
    UpdateDoctorReportComponent,
    AddNurseNoteComponent,
    ViewNurseNoteComponent,
    UpdateNurseNoteComponent,
    PatientIntakeItemsComponent,
    TwofaComponent,

    AddSocialhistoryComponent,
    ViewSocialhistoryComponent,
    UpdateSocialhistoryComponent,
    AddFamilyhistoryComponent,
    ViewFamilyhistoryComponent,
    UpdateFamilyhistoryComponent,
    AddMedicalHistoryComponent,
    ViewMedicalHistoryComponent,
    AddHippaComplianceComponent,
    ViewHippaComplianceComponent,
    SmsComponent,
    EmailComponent,
    AddPrescriptionArrayComponent,
    ViewRadiologyOrderSubjectComponent,
    PrintlabreportComponent,
    AddDoctorInitialEvaluationComponent,
    ViewDoctorInitialEvaluationComponent,
    UpdateDoctorInitialEvaluationComponent,
    AddNurseDailyAssessmentComponent,
    ViewNurseDailyAssessmentComponent,
    UpdateNurseDailyAssessmentComponent,

    UpdateNurseHandOffNoteComponent,
    ViewNurseHandOffNoteByWardComponent,
    ViewWardPatientsComponent,
    LabTestChartComponent,
    UpdateSafetyManagementComponent,
    ViewDutyRosterByShiftComponent,
    ViewBloodTransfusionConsentComponent,
    AddBloodTransfusionConsentComponent,

    AddDisclosureAndConsentComponent,
    ViewDisclosureAndConsentComponent,
    UpdateDisclosureAndConsentComponent,
    AddMriScreeningComponent,
    ViewMriScreeningComponent,
    UpdateMriScreeningComponent,
    AddPastmedicationComponent,
    ViewPastmedicationComponent,
    UpdatePastmedicationComponent,
    ViewPatientLabreportComponent,
    ViewPatientImmunizationComponent,
    ViewPatientPharmacyComponent,
    AddPatientPharmacyComponent,
    UpdatePatientPharmacyComponent,
    ViewRefferalComponent,
    AddRefferalComponent,
    AddUploadLabReporComponent,
    AddUploadRadiologyReportComponent,
    ViewUploadRadiologyReportComponent,
    ViewUploadLabReportComponent,
    AddPhotographicConsentComponent,
    ViewPhotographicConsentComponent,
    ViewOfficePolicyConsentComponent,
    AddOfficePolicyConsentComponent,
    AddGeneralConsentForTreatmentComponent,
    ViewGeneralConsentForTreatmentComponent,
    ViewNarcoticMedicalPolicyAgreementComponent,
    AddNarcoticMedicalPolicyAgreementComponent,
    PatientViewPastmedicationComponent,
    PatientViewMedicalhistoryComponent,
    PatientViewFamilyhistoryComponent,
    PatientViewSocialhistoryComponent,
    PatientViewIdproofComponent,
    PatientViewHipaacomplianceComponent,
    PatientViewNarcoticConsentComponent,
    PatientViewGeneralConsentComponent,
    PatientViewOfficePolicyComponent,
    PatientViewPhotographicConsentComponent,
    CompletedFormsComponent,
    ViewAllhospitalsComponent,
    TwofaResetPassowrdComponent,
    PrintInvoicePtSideComponent,
    UpdateAppointmentByDoctorComponent,
    ViewNursePatientsByHospitalComponent,
    WritingPadComponent,
    AddPaymentMethodComponent,
    ViewPaymentMethodComponent,
    UpdatePaymentMethodComponent,
    AddAppointmentByDoctorComponent,
    AddRefferalByDoctorComponent,
    PatientHasBalanceTopayComponent,
    PatientHistoryComponent,
    PharmacylistComponent,
    UpdatePharmacylistComponent,
    AddPaharmacyComponent,
    ViewPrescriptionsTodayComponent,
    AddPatientPrescriptionComponent,
    ViewPatientPrescriptiionComponent,
    UpdatePatientPrescriptiionComponent,
    StaffViewPatientPrescriptionComponent,
    PatientViewPatientPrescriptionComponent,
    StaffViewPrescriptiondisplayComponent,
    AddPrescriptionByNurseComponent,
    ViewPrescriptionByNurseComponent,
    AddRefillRequestComponent,
    ViewRefillRequestComponent,
    AppovePrescriptionComponent,
    ViewRefillRequestByNurseComponent,
    AppovePrescriptionByNurseComponent,
    AddPatientPrescriptionByNurseComponent,
    ViewPatientPrescriptionByNurseComponent,
    CardViewPrescriptionComponent,
    AddProcedureComponent,
    ViewProcedureComponent,
    UpdateProcedureComponent,
    PrivacyPolicyComponent,
    AddTestResultsComponent,
    ViewTestResultsComponent,
    UpdateTestResultsComponent,
    AddNerveConductionStudyComponent,
    ViewNerveConductionStudyComponent,
    UpdateNerveConductionStudyComponent,
    AddNerveStudySubtypeComponent,
    AddNerveStudyResultsComponent,
    ViewNerveStudySubtypeComponent,
    ViewNerveStudyResultsComponent,
    UpdateNerveStudyResultsComponent,
    AddElectromyographyComponent,
    ViewElectromyographyComponent,
    UpdateElectromyographyComponent,
    PatientHasUnpaidAmountComponent,
    PatientHasInsurancePendingPaymentComponent,
    AddPatientVisitByNurseComponent,
    BulkImportPatientComponent,
    InvoiceHasABalanceComponent,
    FaxComponent,
    UpdatePrescriptionByNurseComponent,
    ViewPatientPaymentComponent,
    ViewPaymentHistoryComponent,
    ViewDoctorProfileComponent,
    UpdateDoctorProfileComponent,
    AddMyOffDaysComponent,
    ViewMyOffDaysComponent,
    ChatComponent,
    ViewAppointmentStatusComponent,
    ManualyConfirmAppointmentComponent,
    DoctorPatientSplitScreenComponent,
    TabsComponentComponent,
    NewOrdersComponent,
    ViewOrdersComponent,
    NewTestResultsComponent,
    AllTestResultsComponent,
    Tabs2ComponentComponent,
    CallEncounterComponent,
    UpdateRefferalComponent,
    RecentTransactionsComponent,
    UserActivityLogComponent,
    DoctorCustomTemplateComponent,
    AddPrescriptionFreeTextComponent,
    CardViewPrescriptionPatientSideComponent,
    DoctorTemplateEditorComponent,
    DoctorTemplateListComponent,
    CalculatorComponent,
    TemplateTextboxComponentComponent,
    UserActivityComponent,
    HospitalTemplateComponent,
    UpdateDoctorTemplateEditorComponent,
    ChatForNurseComponent,
    AddAppointmentHospitalComponent,
    DoctorPatientSplitViewComponent,
    DicomViewerComponent,
    VoiceToTextComponent,
    NursePatientTabAppearanceComponent,
    TabNurseSideComponent,
    Tab2NurseSideComponent,
    Tab2ClinicSideComponent,
    PatientTabAppearanceComponent,
    DoctorPatientInvoicesComponent,
    MessageComponent,
    ChatForPatientComponent,
    TabClinicSideComponent,
    AuditLogComponent,
    SplitAComponent,
    SplitBComponent,
    SubscriptionComponent,
    TwofaDeaComponent,
    AddDoctorProgressNoteComponent,
    AddNurseProgressNoteComponent,
    AddNurseTemplatesComponent,
    ViewNurseTemplatesComponent,
    UpdateNurseTemplatesComponent,
    UpdateNurseProgressNoteComponent,
    ViewNurseProgressNoteComponent,
    AddPrescriptionMultipleComponent,
    SignatureComponent,
    ViewPrescriptionRequestedByNurseComponent,
    AddVitalsByDoctorComponent,
    QrScannerComponent,
    AddReviewOfSystemComponent,
    ViewReviewOfSystemComponent,
    UpdateReviewOfSystemComponent,
    UpdateHospitaltemplateComponent,
    ViewHospitaltemplateComponent,
    QrScannerNurseComponent,
    RosterComponent,
    AddRosterComponent,
    UpdateRosterComponent,
    AddDutyComponent,
    ViewDutyComponent,
    UpdateDutyComponent,
    DrawToolComponent,
    AddPainChartComponent,
    ViewPainChartComponent,
    AddGeneralUploadComponent,
    ViewGeneralUploadComponent,
    PatientSideProgressNoteComponent,
    PatientSearchComponent,
    NurseSideSplitAComponent,
    NurseSideSplitBComponent,
    VideoCallComponent,
    FormBuilderComponent,
    ViewMriMultipleComponent,
    ViewFavoritePrescriptionComponent,
    UpdateFavoritePrescriptionComponent,
    PrintinvoiceDoctorPatientComponent,
    AddInvoiceOnProgressNoteComponent,
    AddRadiologyReportPeachRecogintionEnabledComponent,
    ViewRadiologyReportPeachRecogintionEnabledComponent,
    ClinicalAssistanceComponent,
    ViewFavoriteDignosisCodeComponent,
    UpdateFavoriteDignosisCodeComponent,
    FavoriteDignosisCodeComponent,
    ViewIcdCodesComponent,
    AddIcdCodeComponent,
    FavoriteDiagnosisPopupComponent,
    BillerDashBoardComponent,
    MedicalAssistantDashBoardComponent,
    FrontDeskDashBoardComponent,
    PracticeManagerDashBoardComponent,
    AddPracticeManagerComponent,
    ViewPracticeManagerComponent,
    UpdatePracticeManagerComponent,
    AddBillerComponent,
    ViewBillerComponent,
    UpdateBillerComponent,
    AddMedicalAssistantComponent,
    ViewMedicalAssistantComponent,
    UpdateMedicalAssistantComponent,
    ViewFrontDeskComponent,
    UpdateFrontDeskComponent,

    BillerPatientComponent,
    BillerSplitBComponent,
    BillerSplitAComponent,
    AddInvoiceByBillerComponent,
    Tab1BillerComponent,
    Tab2BillerComponent,
    ViewAllInvoiceByBillerComponent,
    PatientHasBalanceViewByBillerComponent,
    PatientHasUnpaindAmountViewByBillerComponent,
    AddFrontDeskStaffComponent,
    ViewFrontDeskStaffComponent,
    UpdateFrontDeskStaffComponent,
    AddAppointmentByFrontDeskStaffComponent,
    Tab1PatientComponent,
    Tab2PatientComponent,
    SplitAPatientComponent,
    SplitBPatientComponent,
    ViewAllAppointmentsFrontDeskComponent,
    ViewAppointmentsTodayFrontdeskComponent,
    UpdateNurseComponent,
    UpdateInsuranceComponent,
    EligibilityCheckComponent,
    ClaimComponent,
    ViewDoctorReportDetailsComponent,
    PaymentComponent,
    ViewPatientPaymentByHospitalComponent,
    DrugInfoComponent,
    AddVisitSummaryComponent,
    ViewVisitSummaryComponent,
    UpdateVisitSummaryComponent,
    ViewVisitSummaryPatientSideComponent,
    ClaimStatusComponent,
    Cms1500Component,
    AddServiceRequestComponent,
    UpdateServiceRequestComponent,
    ViewServiceRequestComponent,
    ViewCms1500Component,
    UpdateCms1500Component,
    ItemsModalComponent,
    UpdateClaimComponent,
    EmergencyAccessComponent,
    EraListComponent,
    EraPdfComponent,
    ProviderEnrollmentComponent,
    ClaimNotesComponent,
    ViewClaimAccountKeyComponent,
    UpdateClaimAccountKeyComponent,
    AddClaimAccountKeyComponent,
    TwoFaEmergencyAccessComponent,
    EmergencyAccessPhoneAuthComponent,
    ClaimAttachmentComponent,
    SubscriptionPlanComponent,
    AllSubscriptionsComponent,
    UpdateLabReportComponent,

    // NgxMatTimepickerModule
  ],
  imports: [
    MarkdownModule.forRoot(),
    ModalModule,
    ScrollingModule,
    ToastrModule.forRoot(),
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    MatPaginatorModule,
    MatNativeDateModule,
    NgxMaterialTimepickerModule,
    NgApexchartsModule,
    NgxDicomModule,
    QRCodeModule,
    WebcamModule,
    NgxScannerQrcodeModule,
    NgOtpInputModule,
    MatSelectSearchModule,
    ClipboardModule,
    MatSnackBarModule,
    NgbModalDraggableModule,
    DragDropModule,
    QuillModule.forRoot(),
    StripeModule.forRoot('placeholder_key'),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),

    // NgxMaterialTimepickerModule.setLocale('en-US'),
  ],
  providers: [
    DatePipe,
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },

    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    SubscriptionGuard,
  ],

  bootstrap: [AppComponent],
})
export class AppModule {}
// 'pk_live_51IyUaAJnyILmQggyZxkWE0x9h2yKfuLi8mA7nJLMscjV73ZBTxRQv1Jfm2kkodBZMJ2i1Oi5KmPGBi0piMVkyzZ000WSawXnfZ'
