import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NurseNote } from 'src/app/Models/nursenote.model';
import { Patient } from 'src/app/Models/patient.model';
import { NurseNoteService } from 'src/app/services/nurse-note.service';
import { PatientService } from 'src/app/services/patient.service';

import { TokenService } from 'src/app/services/token.service';
import { UpdateNurseNoteComponent } from '../update-nurse-note/update-nurse-note.component';

@Component({
  selector: 'app-view-nurse-note',
  templateUrl: './view-nurse-note.component.html',
  styleUrls: ['./view-nurse-note.component.css'],
})
export class ViewNurseNoteComponent implements OnInit {
  helper = new JwtHelperService();
  myToken = this.tokenService.getToken();
  displayedColumns: string[] = [
    'Date',
    'shortcut',
    'ReportName',
    'Decription',
    'NurseName',
    'Signature',
    'View',
    'Update',
    'Delete',
  ];

  id = 0;
  nurseNote: NurseNote[] = [];
  dataSource: MatTableDataSource<any>;
  labDocument: string = '';
  patient: Patient;
  currentNurseId: number;

  constructor(
    private patientService: PatientService,
    private nurseNoteService: NurseNoteService,
    private route: ActivatedRoute,
    private router: Router,
    private tokenService: TokenService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.currentNurseId = parseInt(decodedToken.nameid);

    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });

    this.GetReports(this.id);
    this.getPatient(this.id);
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  // ngAfterViewInit() {
  //   this.dataSource.paginator = this.paginator;
  //   this.dataSource.sort = this.sort;
  // }

  getPatient(id) {
    this.patientService.getPatientById(id).subscribe((res) => {
      this.patient = res;
    });
  }
  GetReports(id) {
    this.patientService.getNurseNote(id).subscribe((res) => {
      this.dataSource = new MatTableDataSource(
        res.NurseNotes.sort(function (x, y) {
          return y.NurseNoteId - x.NurseNoteId;
        })
      );
      this.dataSource.paginator = this.paginator;
    });
  }
  delete(id) {
    this.nurseNoteService.deleteNurseNote(id).subscribe((res) => {
      this.GetReports(this.id);
    });
  }
  // print() {
  //   window.print();
  // }
  print() {
    let printWindow = window.open('', '', 'height=900,width=1200');

    printWindow.document.write(
      document.querySelector('.panel-demo1').innerHTML
    );

    printWindow.document.close();

    // Print the page
    printWindow.print();
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  public viewReport(report: NurseNote): void {
    // console.log('report: ', report);
    this.labDocument = report.ReportDescription;
  }

  update(NurseNoteId: number): void {
    const dialogRef = this.dialog.open(UpdateNurseNoteComponent, {
      width: '50%',
      height: '80%',
      disableClose: true, // Prevent closing without confirmation
      hasBackdrop: false, // Disable the backdrop
      panelClass: 'custom-dialog-container', // Optional custom styling
      data: { NurseNoteId }, // Pass data to the dialog
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('Dialog closed:', result);
      // Handle any cleanup or actions after the dialog is closed
    });
  }
}
