<mat-tab-group (selectedTabChange)="tabChanged($event)">

    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">key</mat-icon>
            Account Key
        </ng-template>


        <div class="container">
            <h2>View Claim Account Keys</h2>
            <table>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Account Key</th>
                        <th>Status</th>
                        <th>Hospital ID</th>
                        <th>Created Date</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let key of claimAccountKeys">
                        <td>{{ key.ClaimAccountKeyId }}</td>
                        <td>{{ key.AccountKey }}</td>
                        <td>{{ key.Status }}</td>
                        <td>{{ key.HospitalId }}</td>
                        <td>{{ key.createdDate | date: 'short'}}</td>
                        <td>
                            <button mat-mini-fab color="primary"
                                (click)="updateClaimAccountKey(key.ClaimAccountKeyId)"><mat-icon>edit</mat-icon></button>
                            <button mat-mini-fab class="mx-3" color="accent"
                                (click)="deleteClaimAccountKey(key.ClaimAccountKeyId)"><mat-icon>delete</mat-icon></button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p *ngIf="successMessage" class="success">{{ successMessage }}</p>
            <p *ngIf="errorMessage" class="error">{{ errorMessage }}</p>
        </div>

    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">key</mat-icon>
            Add Account Key
        </ng-template>
        <app-add-claim-account-key></app-add-claim-account-key>
    </mat-tab>

</mat-tab-group>