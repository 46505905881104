import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PatientbookingService } from 'src/app/services/patientbooking.service';
import { BarcodeScanner } from '@capacitor-community/barcode-scanner';
import { PatientService } from 'src/app/services/patient.service';
import * as moment from 'moment';
import { AppointmentService } from 'src/app/services/appointment.service';
import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-scanner',
  templateUrl: './scanner.component.html',
  styleUrls: ['./scanner.component.css'],
})
export class ScannerComponent implements OnInit {
  id = 0;
  isSuccess = false;
  Token = 0;
  newToken;

  constructor(
    private patientService: PatientService,
    private patientBooking: PatientbookingService,
    private route: ActivatedRoute,
    private appointmentService: AppointmentService,
    private tokenService: TokenService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    this.getlastToken();
  }
  getlastToken() {
    this.appointmentService.generateToken().subscribe((res) => {
      this.newToken = res;
    });
  }
  // https://www.npmjs.com/package/angularx-qrcode
  qrCodeString = 'This is a secret qr code message';
  scannedResult: any;
  content_visibility = '';

  // startScan() {
  //   this.barcodeScanner.scan().then(barcodeData => {
  //     console.log('Barcode data', barcodeData);
  //     this.scannedResult = barcodeData?.text;
  //    }).catch(err => {
  //        console.log('Error', err);
  //    });
  // }

  async checkPermission() {
    try {
      // check or request permission
      const status = await BarcodeScanner.checkPermission({ force: true });
      if (status.granted) {
        // the user granted permission
        return true;
      }
      return false;
    } catch (e) {
      // console.log(e);
    }
  }

  async startScan(e) {
    // console.log(e)
    try {
      const permission = await this.checkPermission();
      // if(!permission) {
      //   debugger
      //   return;
      // }
      await BarcodeScanner.hideBackground();
      document.querySelector('body').classList.add('scanner-active');
      this.content_visibility = 'hidden';
      const result = await BarcodeScanner.startScan();
      BarcodeScanner.showBackground();
      document.querySelector('body').classList.remove('scanner-active');
      this.content_visibility = '';
      if (result?.hasContent) {
        this.scannedResult = result.content;
        const Id = parseInt(this.scannedResult);

        this.patientService.getPatientBooking(Id).subscribe((res: any) => {
          if (res.PatientBookings?.length) {
            res.PatientBookings.forEach((booking, id) => {
              const today = moment();
              const appointmentDate = moment(booking.VisitDate);
              const isSameDay = appointmentDate.isSame(today, 'day');

              if (
                isSameDay &&
                booking.Status.includes('Confirmed by Phone || Approved')
              ) {
                const model = {
                  Status: 'Arrived',
                  Tocken: this.newToken,
                  // Tocken: Math.floor(Math.random() * 100) + 1

                  // Tocken: Date.now().toString().padStart(2,'0') +  Date.now().toString().padEnd(2,'0')
                };
                this.Token = model.Tocken;
                // res.PatientBookings[id].Status = 'Confirmed';
                this.patientBooking
                  .updateBookingStatus(booking.PatientBookingId, model)
                  .subscribe({
                    next: (res: any) => {
                      if (res.StatusCode === 200) {
                        this.isSuccess = true;
                      }
                    },
                  });
              }
            });
          }
        });
      }
    } catch (e) {
      this.stopScan();
    }
  }

  stopScan() {
    BarcodeScanner.showBackground();
    BarcodeScanner.stopScan();
    document.querySelector('body').classList.remove('scanner-active');
    this.content_visibility = '';
  }

  ngOnDestroy(): void {
    this.stopScan();
  }
}
