import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EligibilityService {
  private readonly apiUrl = '/services/eligdata/';
  private apiUrlMedicine = '/services/eligdata/eligibility/';

  private apiPayerListUrl = 'https://svc.claim.md/services/payerlist/'; // External API endpoint

  constructor(private http: HttpClient) {}

  checkEligibility(data: {
    AccountKey: string;
    ins_name_l: string;
    ins_name_f: string;
    ins_dob: string;
    payerid: string;
    pat_rel: string;
    fdos: string;
    prov_npi: string;
    prov_taxid: string;
  }): Observable<string> {
    const headers = new HttpHeaders({
      Accept: 'application/xml',
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': '*',
    });

    const body = new URLSearchParams();
    body.set('AccountKey', data.AccountKey);
    body.set('ins_name_l', data.ins_name_l);
    body.set('ins_name_f', data.ins_name_f);
    body.set('ins_dob', data.ins_dob);
    body.set('payerid', data.payerid);
    body.set('pat_rel', data.pat_rel);
    body.set('fdos', data.fdos);
    body.set('prov_npi', data.prov_npi);
    body.set('prov_taxid', data.prov_taxid);

    return this.http.post(this.apiUrl, body.toString(), {
      headers,
      responseType: 'text',
    });
  }

  checkEligibilityMedicine(data: any) {
    return this.http.post(this.apiUrlMedicine, data);
  }

  getPayerList(): Observable<string> {
    const headers = new HttpHeaders({
      Accept: 'application/xml',
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': '*',
    });
    const body = new URLSearchParams({
      AccountKey: '',
    });

    return this.http.post(this.apiUrl, body.toString(), {
      headers: headers,
      responseType: 'text', // To get raw XML as a string
    });
  }
}
