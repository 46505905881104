<mat-tab-group (selectedTabChange)="tabChanged($event)">

    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">local_pharmacy</mat-icon>
            Payment Method
        </ng-template>
        <div class="container-fluid">

            <table class="table table">
                <thead>
                    <th>ProviderName</th>
                    <th>PublicKey</th>
                    <th>SecurityKey</th>
                    <th>Status</th>
                </thead>
                <tbody>
                    <tr *ngFor="let ph of patmentMethodList">
                        <td>{{ph.ProviderName}}</td>
                        <td>{{ph.PublicKey}}</td>
                        <td>{{ph.SecurityKey}}</td>
                        <td>{{ph.Status ?'Current':'Old'}}</td>
                        <td>
                            <button (click)="delete(ph.PaymentMethodId)"
                                mat-mini-fab><mat-icon>delete</mat-icon></button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

    </mat-tab>


    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">add</mat-icon>
            Register Payment Method
        </ng-template>
        <app-add-payment-method></app-add-payment-method>
    </mat-tab>


</mat-tab-group>