<div class="container">
    <div class="row">
        <div class="col-lg regFrm mat-elevation-z8">
            <h1>GENERAL CONSENT FOR TREATMENT AND ACKNOWLEDGEMENT</h1>
            <form (keydown.enter)="$event.preventDefault()" [formGroup]="Frm" (ngSubmit)="formSubmit()"
                class="example-form">

                <hr>
                <p> <strong>MEDICAL CONSENT:</strong>I consent to all medical care, treatment, laboratory, imaging and
                    other medical
                    procedures performed or prescribed by a physician of the {{hospital.HospitalName}} and
                    his/her designees as directed in his/her judgement.</p>
                <p> <strong>NOTICE OF CARE PROVIDED TO OUT OF STATE PATIENTS:</strong>I understand that all healthcare
                    rendered
                    shall be governed exclusively and only by state Law where the provider is located and in no event
                    shall the law of any other state
                    apply
                    to any healthcare rendered. In the event of a dispute, any lawsuit, action, or cause which in any
                    way
                    relates to healthcare provided it shall only be brought in a {{hospital.HospitalName}} court in
                    the county/district where all or substantially of the healthcare was rendered. (consent applies only
                    to out of state patients)</p>

                <p> <strong>RIGHT TO REFUSE TREATEMENT:</strong>I understand that I have the right to make informed
                    decisions regarding all my care and treatments, and that I should ask my health care professional to
                    further clarify or explain anything I do not understand.
                    This right includes the right to refuse any treatments that I do not want.</p>

                <p> <strong>ACKNOWLEDGEMENT OF RECEIPT OF PATIENT RIGHTS & NOTICE OF PRIVACY PRACTICES:</strong>I
                    acknowledge that I have received both notices,
                    Notice of Patient Rights/Responsibilities and Notice of Privacy Practices.</p>

                <p> <strong>ADVANCE DIRECTIVES:</strong>I understand that I have an opportunity to make known my wishes,
                    in writing regarding my health care and/or end of life decisions. This directive is in the form of a
                    living will and/or durable power of attorney for health care.</p>


                <p> <strong>RELEASE OF MEDICAL INFORMATION:</strong>I authorize {{hospital.HospitalName}} to release
                    any information necessary to facilitate healthcare processing of claims, and audit of payments
                    relative to my care/treatment with {{hospital.HospitalName}}. I also consent to the release of
                    any information as needed for my care to other facilities, agencies, or healthcare providers as I
                    direct or as required by law. This order will remain in effect until revoked by me in writing.</p>
                <p> <strong>FINANCIAL AGREEMENT:</strong>certify that the insurance information that I have provided is
                    accurate, complete and current and that no other coverage or insurance exists. I understand I am
                    financially responsible to {{hospital.HospitalName}} for charges not paid under this agreement.
                    I
                    am responsible for all charges for services provided to me which are not covered by my Health
                    Insurance Plan or for which I am responsible for payment under my Health Insurance Plan.
                    {{hospital.HospitalName}} will make every attempt to notify me in advance if a service is not
                    covered. I agree to pay all applicable co-payments, deductibles, and co-insurance. I am responsible
                    to pay all copays, deductibles,
                    and patient responsibility at the time of service unless other arrangements have been made in
                    advance.</p>

                <p> <strong>ASSIGNMENT OF INSURANCE BENEFITS:</strong>I hereby assign all medical benefits, to include
                    major medical benefits to which I am entitled. I hereby authorize and direct my insurance
                    carrier(s), including Medicare, Medigap, Medicare Replacement, private insurance and any other
                    health / medical plan, to issue payment check(s) directly to {{hospital.HospitalName}} for
                    medical services rendered to myself. I understand that I am responsible for any amount not covered
                    by insurance.</p>
                <p> <strong>MEDICARE CERTIFICATION:</strong>I certify that the information given by me in applying for
                    payment under Title XVIII of the Social Security Act is correct. I authorized any holder of medical
                    or other information about me to release to the Social Security Administration, or its
                    intermediaries or carriers, any information needed for this or a related Medicare claim. I request
                    that payment of authorized benefits be made on my behalf. (consent applies only when applicable)</p>

                <strong>By signing below, I acknowledge that I have read, understand, and agree to the terms and
                    conditions of this form and that I am authorized as the patient or the Patient’s Legal
                    Representative to sign this document.</strong>
                <div class="row">

                    <div class="col-md-12">


                        <mat-form-field class="example-full-width">
                            <mat-label>This consent was signed by(Patient/Legal Representative Print Name):</mat-label>
                            <input type="text" formControlName="AuthorisedSignatoryName" matInput>
                            <mat-error *ngIf="Frm.get('AuthorisedSignatoryName')?.hasError('required')">
                                Please enter Patient/Legal Representative Print Name
                            </mat-error>

                        </mat-form-field>
                        <!-- <mat-form-field class="example-full-width">
                <mat-label>Signature:</mat-label>
                <input type="text" formControlName="Signauture" matInput>
                <mat-error>Please enter Description</mat-error>
              </mat-form-field> -->


                    </div>

                    <div class="container mt-5">
                        <canvas (mousedown)="onMouseDown($event)" (mousemove)="onMouseMove($event)"
                            (touchmove)="onMouseMove($event)" (touchstart)="onMouseDown($event)" #signPad width="350"
                            height="200">
                        </canvas>
                        <button type="button" class="ml-3" color="primary" mat-raised-button
                            (click)="saveSignature($event)">Save</button>

                        <button type="button" class="ml-3" color="accent" mat-raised-button
                            (click)="clearSignature($event)">Clear</button>
                    </div>



                </div>
                <button type="submit" [disabled]="Frm.invalid" class="float-right lgBtCl" mat-button>
                    <mat-icon>save</mat-icon>
                </button>
                <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
                    Successfully submitted
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </form>

        </div>

    </div>
</div>