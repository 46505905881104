<div class="ml-3">
    <div class="row">
        <div class="col-lg-6 float-right">
            <mat-form-field class="example-full-width mt-5">
                <mat-label>Filter</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Ex.Mia" #input>
            </mat-form-field>
        </div>
    </div>

</div>
<div class=" mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" matSort>


        <ng-container matColumnDef="Id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
            <td mat-cell *matCellDef="let row"> {{row.PatientId}} </td>
        </ng-container>



        <ng-container matColumnDef="PatientName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> PatientName </th>
            <td mat-cell *matCellDef="let row"> {{row.PatientName}} </td>
        </ng-container>
        <ng-container matColumnDef="BalanceAmount">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> BalanceAmount </th>
            <td mat-cell *matCellDef="let row"> {{row.BalanceAmount}} </td>
        </ng-container>
        <ng-container matColumnDef="Total">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Total Amount </th>
            <td mat-cell *matCellDef="let row"> {{row.Total}} </td>
        </ng-container>
        <ng-container matColumnDef="Status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
            <td class="ml-4" mat-cell *matCellDef="let row"> {{row.Status}} </td>
        </ng-container>
        <ng-container matColumnDef="Date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Date </th>
            <td mat-cell *matCellDef="let row"> {{row.Date}} </td>
        </ng-container>
        <!-- 
        <ng-container matColumnDef="View">
            <th mat-header-cell *matHeaderCellDef> Patient Details</th>
            <td mat-cell *matCellDef="let row">
                <button type="button" [routerLink]="['/invoiceBalances/',row.PatientId]" mat-mini-fab color="success">
                    <mat-icon matPrefix>remove_red_eye</mat-icon>
                </button>
            </td>
        </ng-container> -->

        <ng-container matColumnDef="View">
            <th mat-header-cell *matHeaderCellDef> View Contact </th>
            <td mat-cell *matCellDef="let row">
                <button type="button" color="primary" (click)="modal13.show();viewContact(row.PatientId)" mat-mini-fab
                    data-toggle="modal" data-target=".bd-example-modal-lg">
                    <mat-icon>Contact</mat-icon>
                </button>

                <app-modal id="printable" #modal13 class="panel-demo1" [maximizable]="true" [backdrop]="false">
                    <ng-container class="app-modal-header">Patient Name: {{patient?.PatientFirstName}} &nbsp;
                        {{patient?.PatientLastName}}</ng-container>
                    <ng-container class="app-modal-body">
                        <div class="modal-body " id="printable" #nursereportList>



                            <div class="areaStyle mb-1">
                                <P>Patient ID: {{patient?.PatientId}} </P>

                                <P>Patient Name: {{patient?.PatientFirstName}} &nbsp; {{patient?.PatientLastName}}
                                </P>
                                <P>Patient D.O.B: {{patient?.PatientDOB}} </P>
                                <P>Patient Address: {{patient?.PatientAddress}} </P>
                                <P>Patient Email: {{patient?.Email}} </P>
                                <P>Patient PhoneNumber: {{patient?.PhoneNumber}} </P>
                                <hr>
                            </div>
                        </div>


                    </ng-container>
                    <ng-container class="app-modal-footer">
                        <button type="button" (click)="print()" class="btn btn-primary ">Print</button>

                        <button class="dt-button dt-blue" style="float: right;" (click)="modal13.hide()">Close</button>

                    </ng-container>
                </app-modal>

            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
        </tr>
    </table>

    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</div>