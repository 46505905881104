import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ReviewOfSystem } from 'src/app/Models/reviewofsystem.model';
import { PatientService } from 'src/app/services/patient.service';
import { ReviewOfSystemService } from 'src/app/services/review-of-system.service';

import { TokenService } from 'src/app/services/token.service';
import { UpdateReviewOfSystemComponent } from '../update-review-of-system/update-review-of-system.component';

@Component({
  selector: 'app-view-review-of-system',
  templateUrl: './view-review-of-system.component.html',
  styleUrls: ['./view-review-of-system.component.css'],
})
export class ViewReviewOfSystemComponent implements OnInit {
  helper = new JwtHelperService();
  myToken = this.tokenService.getToken();
  id = 0;
  CurrentDoctorId: number;

  submittedData: any = null; // Store the submitted data
  ReviewOfSystemsList: ReviewOfSystem[];

  categories = [
    {
      name: 'Constitutional',
      key: 'constitutional',
      conditions: [
        { label: 'Chills', key: 'chills' },
        { label: 'Fatigue', key: 'fatigue' },
        { label: 'Fever', key: 'fever' },
        { label: 'Weight Gain', key: 'weightGain' },
        { label: 'Weight Loss', key: 'weightLoss' },
      ],
    },
    {
      name: 'HEENT',
      key: 'heent',
      conditions: [
        { label: 'Hearing Loss', key: 'hearingLoss' },
        { label: 'Sinus Pressure', key: 'sinusPressure' },
        { label: 'Visual Changes', key: 'visualChanges' },
      ],
    },
    {
      name: 'Respiratory',
      key: 'respiratory',
      conditions: [
        { label: 'Cough', key: 'cough' },
        { label: 'Shortness of Breath', key: 'shortnessOfBreath' },
        { label: 'Wheezing', key: 'wheezing' },
      ],
    },
    {
      name: 'Cardiovascular',
      key: 'cardiovascular',
      conditions: [
        { label: 'Chest Pain', key: 'chestPain' },
        { label: 'Pain while walking (Claudication)', key: 'claudication' },
        { label: 'Edema', key: 'edema' },
        { label: 'Palpitations', key: 'palpitations' },
      ],
    },
    {
      name: 'Gastrointestinal',
      key: 'gastrointestinal',
      conditions: [
        { label: 'Abdominal Pain', key: 'abdominalPain' },
        { label: 'Blood in Stool', key: 'bloodInStool' },
        { label: 'Constipation', key: 'constipation' },
        { label: 'Diarrhea', key: 'diarrhea' },
        { label: 'Heartburn', key: 'heartburn' },
        { label: 'Loss of Appetite', key: 'lossOfAppetite' },
        { label: 'Nausea', key: 'nausea' },
        { label: 'Vomiting', key: 'vomiting' },
      ],
    },
    {
      name: 'Genitourinary',
      key: 'genitourinary',
      conditions: [
        { label: 'Painful urination (Dysuria)', key: 'dysuria' },
        { label: 'Excessive amount of urine (Polyuria)', key: 'polyuria' },
        { label: 'Urinary Frequency', key: 'urinaryFrequency' },
      ],
    },
    {
      name: 'Metabolic/Endocrine',
      key: 'metabolicEndocrine',
      conditions: [
        { label: 'Cold Intolerance', key: 'coldIntolerance' },
        { label: 'Heat Intolerance', key: 'heatIntolerance' },
        { label: 'Excessive Thirst (Polydipsia)', key: 'polydipsia' },
        { label: 'Excessive Hunger (Polyphagia)', key: 'polyphagia' },
      ],
    },
    {
      name: 'Neurological',
      key: 'neurological',
      conditions: [
        { label: 'Dizziness', key: 'dizziness' },
        { label: 'Extremity Numbness', key: 'extremityNumbness' },
        { label: 'Extremity Weakness', key: 'extremityWeakness' },
        { label: 'Headaches', key: 'headaches' },
        { label: 'Seizures', key: 'seizures' },
        { label: 'Tremors', key: 'tremors' },
      ],
    },
    {
      name: 'Psychiatric',
      key: 'psychiatric',
      conditions: [
        { label: 'Anxiety', key: 'anxiety' },
        { label: 'Depression', key: 'depression' },
      ],
    },
    {
      name: 'Integumentary',
      key: 'integumentary',
      conditions: [
        { label: 'Breast Discharge', key: 'breastDischarge' },
        { label: 'Breast Lump', key: 'breastLump' },
        { label: 'Hives', key: 'hives' },
        { label: 'Mole Change(s)', key: 'moleChanges' },
        { label: 'Rash', key: 'rash' },
        { label: 'Skin Lesion', key: 'skinLesion' },
      ],
    },
    {
      name: 'Musculoskeletal',
      key: 'musculoskeletal',
      conditions: [
        { label: 'Back Pain', key: 'backPain' },
        { label: 'Joint Pain', key: 'jointPain' },
        { label: 'Joint Swelling', key: 'jointSwelling' },
        { label: 'Neck Pain', key: 'neckPain' },
      ],
    },
    {
      name: 'Hematologic',
      key: 'hematologic',
      conditions: [
        { label: 'Easily Bleeds', key: 'easilyBleeds' },
        { label: 'Easily Bruises', key: 'easilyBruises' },
        { label: 'Lymphedema', key: 'lymphedema' },
        { label: 'Issues with Blood Clots', key: 'bloodClots' },
      ],
    },
    {
      name: 'Immunologic',
      key: 'immunologic',
      conditions: [
        { label: 'Food Allergies', key: 'foodAllergies' },
        { label: 'Seasonal Allergies', key: 'seasonalAllergies' },
      ],
    },
  ];

  constructor(
    private reviewOfSystemService: ReviewOfSystemService,
    private route: ActivatedRoute,
    private patientService: PatientService,
    private tokenService: TokenService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.CurrentDoctorId = parseInt(decodedToken.nameid);
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    this.fetchSubmittedData(this.id);
  }

  fetchSubmittedData(id) {
    this.patientService.getReviewOfSystem(id).subscribe((res) => {
      this.ReviewOfSystemsList = res.ReviewOfSystems;
    });
  }

  print() {
    window.print();
  }

  delete(id) {
    this.reviewOfSystemService.deleteReviewOfSystem(id).subscribe((res) => {
      this.fetchSubmittedData(this.id);
    });
  }
  update(ReviewOfSystemId: number): void {
    const dialogRef = this.dialog.open(UpdateReviewOfSystemComponent, {
      width: '50%',
      height: '80%',
      disableClose: true, // Prevent closing without confirmation
      hasBackdrop: false, // Enable the backdrop for accessibility
      panelClass: 'custom-dialog-container', // Optional custom styling
      data: { ReviewOfSystemId }, // Pass data to the dialog
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('Dialog closed:', result);
      if (result) {
        this.refreshView(); // Refresh the view if needed
      }
    });
  }

  refreshView(): void {
    // Example: Fetch data again or update UI
    console.log('View refreshed');
    // Call a service or update your component state
  }
}
