import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Department } from 'src/app/Models/department.model';
import { Hospital } from '../../Models/hospital.model';
import { HospitalService } from '../../services/hospital.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as moment from 'moment';
import { combineLatest } from 'rxjs';
import { EmailPayload } from 'src/app/Models/email.model';
import { TextMessage } from 'src/app/Models/textmessage.model';
import { AppointmentService } from 'src/app/services/appointment.service';
import { DoctorService } from 'src/app/services/doctor.service';
import { EmailService } from 'src/app/services/email.service';
import { InvoiceService } from 'src/app/services/invoice.service';
import { NurseService } from 'src/app/services/nurse.service';
import { PatientService } from 'src/app/services/patient.service';
import { UserService } from 'src/app/services/user.service';
import { getFilteredDate } from 'src/app/shared/utility';
import { PatientVisit } from 'src/app/Models/patientvisit.model';

import { TokenService } from 'src/app/services/token.service';
import { SubscriptionService } from 'src/app/services/subscription.service';

@Component({
  selector: 'app-hospital-dashboard',
  templateUrl: './hospital-dashboard.component.html',
  styleUrls: ['./hospital-dashboard.component.css'],
})
export class HospitalDashboardComponent implements OnInit {
  helper = new JwtHelperService();
  showItem = '';
  id = 0;
  myToken = this.tokenService.getToken();
  Revenue: any = {};
  hospitalList: Hospital[];
  Patients: any = {};
  PatientVisits: any = {};
  NewPatients: any[] = [];
  AlertsExecuted = false;
  HospitalStaff: any = {};
  NewVisits: any[] = [];
  SoftwareRentalFee: any = {};
  hospital: Hospital;
  subscriptionMessage: string;
  expiresInDays: any;

  constructor(
    private hospitalService: HospitalService,
    private router: Router,
    private userService: UserService,
    private invoiceService: InvoiceService,
    private patientService: PatientService,
    private appointmentService: AppointmentService,
    private emailServices: EmailService,
    private doctorService: DoctorService,
    private nurseService: NurseService,
    private tokenService: TokenService,
    private subscriptionService: SubscriptionService
  ) {}

  ngOnInit(): void {
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.id = parseInt(decodedToken.nameid);
    if (decodedToken.role === 'Hospital') {
      setTimeout(() => {
        this.logout();
      }, 43200000);
    }
    this.checkSubscriptionStatus();
    this.getHospital(this.id);
    this.getHospitalRevene(this.id);
    this.getAllPatients(this.id);
    this.getAllPatientVisits(this.id);
    this.getHospitalStaff(this.id);
    if (!this.AlertsExecuted) {
      this.getAppointments();
      this.AlertsExecuted = true;
    }

    const interval = 4 * 60 * 60 * 1000; // Every 4 hours
    setInterval(() => {
      this.getAppointments();
    }, interval);
  }

  upcomingAppointments = [];
  getAppointments() {
    this.appointmentService.getPatientBookings().subscribe({
      next: (res) => {
        const now = moment();

        this.upcomingAppointments = res.filter((appointment) => {
          const visitDate = moment(
            appointment.VisitDate + ' ' + appointment.VisitTime,
            'MM/DD/YYYY HH:mm'
          );

          // Check for 24-hour notification
          const is24HoursBefore = now.isBetween(
            visitDate.clone().subtract(24, 'hours'),
            visitDate.clone().subtract(23, 'hours')
          );

          // Check for 3-hour notification
          const is3HoursBefore = now.isBetween(
            visitDate.clone().subtract(3, 'hours'),
            visitDate.clone().subtract(2, 'hours')
          );

          // Notify if not already notified
          return (
            (is24HoursBefore && !appointment.IsNotified24) ||
            (is3HoursBefore && !appointment.IsNotified3)
          );
        });

        this.sendAlerts();
      },
      error: (err) => {
        console.error('Error fetching appointments:', err);
      },
    });
  }

  sendAlerts() {
    this.upcomingAppointments.forEach((appointment) => {
      const now = moment();
      const visitDate = moment(
        appointment.VisitDate + ' ' + appointment.VisitTime,
        'MM/DD/YYYY HH:mm'
      );

      // Determine the type of reminder
      const is24HoursBefore = now.isBetween(
        visitDate.clone().subtract(24, 'hours'),
        visitDate.clone().subtract(23, 'hours')
      );
      const is3HoursBefore = now.isBetween(
        visitDate.clone().subtract(3, 'hours'),
        visitDate.clone().subtract(2, 'hours')
      );

      if (is24HoursBefore && !appointment.IsNotified24) {
        this.notifyAppointment(appointment, '24-hour');
        appointment.IsNotified24 = true; // Mark 24-hour notification as sent
      }

      if (is3HoursBefore && !appointment.IsNotified3) {
        this.notifyAppointment(appointment, '3-hour');
        appointment.IsNotified3 = true; // Mark 3-hour notification as sent
      }

      // Update notification status in the database
      this.appointmentService
        .updatePatientBooking(appointment.PatientBookingId, appointment)
        .subscribe({
          next: () => console.log('Notification status updated'),
          error: (err) =>
            console.error('Error updating notification status:', err),
        });
    });
  }

  notifyAppointment(appointment, reminderType: string) {
    // Send email
    this.emailServices
      .sendEmail(this.setEmailPayload(appointment, reminderType))
      .subscribe({
        next: () => console.log(`Email sent for ${reminderType} reminder`),
        error: (err) =>
          console.error(
            `Failed to send email for ${reminderType} reminder:`,
            err
          ),
      });

    // Send SMS
    this.emailServices
      .sendSms(this.setSmsPayload(appointment, reminderType))
      .subscribe({
        next: () => console.log(`SMS sent for ${reminderType} reminder`),
        error: (err) =>
          console.error(
            `Failed to send SMS for ${reminderType} reminder:`,
            err
          ),
      });
  }

  setEmailPayload(data, reminderType: string): EmailPayload {
    return {
      Email: data.Email,
      Subject: `${reminderType} Appointment Reminder`,
      Template: `<h2 style="text-align:center">Appointment Reminder (${reminderType})</h2>
        <p>Hi, you have an upcoming appointment with Dr. ${data.DoctorName} (${data.DoctorSpecialization})</p>
        <p>Date: ${data.VisitDate}</p>
        <p>Time: ${data.VisitTime}</p>
        <a href="https://binseraneurology.com">Cancel Appointment</a> <br/>
        <a href="https://binseraneurology.com">Reschedule Appointment</a>`,
    };
  }

  setSmsPayload(data, reminderType: string): TextMessage {
    return {
      PhoneNumber: data.PhoneNumber,
      Body: `Reminder (${reminderType}): Your appointment with Dr. ${data.DoctorName} (${data.DoctorSpecialization}) is scheduled for ${data.VisitDate} at ${data.VisitTime}.`,
    };
  }

  getHospitalStaff(id): void {
    const doctors = this.hospitalService.getDoctors(id);
    const nurses = this.hospitalService.getNurses(id);
    combineLatest([doctors, nurses]).subscribe((res) => {
      this.HospitalStaff.Doctors = {
        active: res[0].Doctors.filter(
          (doctor) => Boolean(doctor.Status) === Boolean('true')
        ).length,
        total: res[0].Doctors.length,
      };
      this.HospitalStaff.Nurses = {
        active: res[1].Nurses.filter((nurse) => nurse.Status === 'true').length,
        total: res[1].Nurses.length,
      };
    });
  }
  checkSubscriptionStatus(): void {
    this.subscriptionService.checkSubscriptionStatus1(this.id).subscribe({
      next: (res) => {
        if (res.isSubscribed) {
          this.subscriptionMessage = 'Your subscription is active.';
          this.expiresInDays = res.expiresInDays;
        } else {
          this.subscriptionMessage = res.message;
          this.expiresInDays = null;
        }
      },
      error: (err) => {
        console.error('Error checking subscription status:', err);
        this.subscriptionMessage = 'Error retrieving subscription status.';
      },
    });
  }
  getHospital(id) {
    this.hospitalService.getHospitalById(id).subscribe((res) => {
      this.hospital = res;
    });
  }
  getHospitalRevene(id) {
    this.hospitalService.GetInvoices(id).subscribe({
      next: (res: any) => {
        const unpaid = res.Invoices.map(
          (r) => r.Status === 'UnPaid' && r.Total
        ).reduce((a, b) => Number(a) + Number(b), 0);

        const paid = res.Invoices.map(
          (r) => r.Status === 'Paid' && r.Total
        ).reduce((a, b) => Number(a) + Number(b), 0);

        const pending = res.Invoices.map(
          (r) => r.Status === 'Pending' && r.Total
        ).reduce((a, b) => Number(a) + Number(b), 0);

        const recievable = res.Invoices.map(
          (r) => r.Status === 'Balance' && r.BalanceAmount
        ).reduce((a, b) => Number(a) + Number(b), 0);

        const total = res.Invoices.map((r) => r.Total);
        this.Revenue['Total'] = total.reduce(
          (acc, curr) => Number(acc) + Number(curr),
          0
        );
        const fee = res.Invoices.map((r) => r.SoftwareFee);
        this.Revenue['SoftwareFee'] = fee.reduce(
          (acc, curr) => Number(acc) + Number(curr),
          0
        );
        // this.Revenue["Total"] = unpaid+paid+pending;
        this.Revenue['Unpaid'] = unpaid ? unpaid : 0;
        this.Revenue['Paid'] = paid ? paid : 0;
        this.Revenue['Pending'] = pending ? pending : 0;
        this.Revenue['Balance'] = recievable ? recievable : 0;
        const currentMonth = res.Invoices.filter((invoice) => {
          const startDate = getFilteredDate(new Date(new Date().setDate(1)));
          const endDate = getFilteredDate(new Date(new Date().setDate(30)));
          if (
            new Date(invoice.createdDate) <= new Date(endDate) ||
            new Date(invoice.createdDate) >= new Date(startDate)
          ) {
            return invoice;
          }
        });
        this.Revenue['MonthTotal'] = currentMonth
          .map((inv) => inv.Total)
          .reduce((acc, curr) => Number(acc) + Number(curr), 0);
        const Munpaid = currentMonth
          .map((r) => r.Status === 'UnPaid' && r.Total)
          .reduce((a, b) => Number(a) + Number(b), 0);
        const Mpaid = currentMonth
          .map((r) => r.Status === 'Paid' && r.Total)
          .reduce((a, b) => Number(a) + Number(b), 0);

        const Mpending = currentMonth
          .map((r) => r.Status === 'Pending' && r.Total)
          .reduce((a, b) => Number(a) + Number(b), 0);
        const Mrecievable = currentMonth
          .map((r) => r.Status === 'Balance' && r.BalanceAmount)
          .reduce((a, b) => Number(a) + Number(b), 0);
        const fees = currentMonth
          .map((r) => r.SoftwareFee)
          .reduce((a, b) => Number(a) + Number(b), 0);

        this.Revenue['MonthUnpaid'] = Munpaid ? Munpaid : 0;
        this.Revenue['MonthPaid'] = Mpaid ? Mpaid : 0;
        this.Revenue['MonthPending'] = Mpending ? Mpending : 0;
        this.Revenue['MonthReceivable'] = Mrecievable ? Mrecievable : 0;
        this.Revenue['SoftwareFee'] = fees ? fees : 0;
      },
    });
  }

  getAllPatients(id): void {
    this.hospitalService.getPatients(id).subscribe({
      next: (res) => {
        this.Patients['Active'] = res.Patients.filter(
          (patient) => patient.Status === 'Active'
        ).length;
        this.Patients['Discharged'] = res.Patients.filter(
          (patient) => patient.Status === 'Discharged'
        ).length;
        this.Patients['Total'] = res.Patients.filter(
          (patient) => patient
        ).length;
        this.NewPatients = res.Patients.filter((patient) =>
          this.isAddedInLast30Days(
            patient.CreatedDate,
            res.Patients.map((p) => p.CreatedDate)
          )
        );
        this.Patients['New'] = this.NewPatients.length;
        this.Patients['NewActive'] = this.NewPatients.filter(
          (patient) => patient.Status === 'Active'
        ).length;
        this.Patients['NewDischarged'] = this.NewPatients.filter(
          (patient) => patient.Status === 'Discharged'
        ).length;
      },
    });
  }

  isAddedInLast30Days(date, patients) {
    const now = new Date();
    const last30Days = now;
    last30Days.setDate(last30Days.getDate() - 30);

    const existsInLast30Days = patients.filter((patient) => {
      return (
        now.getTime() - new Date(patient).getTime() <= 30 * 24 * 60 * 60 * 1000
      );
    });

    return existsInLast30Days.includes(date);
  }
  getAllPatientVisits(id): void {
    this.hospitalService.GetPatientVisits(id).subscribe({
      next: (res) => {
        this.NewVisits = res.PatientVisits.filter((visit) =>
          this.visitsAddedInLast30Days(
            visit.createdDate,
            res.PatientVisits.map((p) => p.createdDate)
          )
        );
        const currentMonth = res.PatientVisits.filter((visit) => {
          const startDate = getFilteredDate(new Date(new Date().setDate(1)));
          const endDate = getFilteredDate(new Date(new Date().setDate(30)));
          if (
            new Date(visit.createdDate) <= new Date(endDate) ||
            new Date(visit.createdDate) >= new Date(startDate)
          ) {
            return visit;
          }
        });

        const fees = currentMonth
          .map((r) => r.SoftwareFee)
          .reduce((a, b) => Number(a) + Number(b), 0);
        this.PatientVisits['New'] = this.NewVisits.length;
        this.PatientVisits['SoftwareFee'] = fees ? fees : 0;

        // this.PatientVisits['NewActive'] = this.NewVisits.filter(
        //   (patient) => patient.SoftwareFee != null
        // ).length;
      },
    });
  }

  visitsAddedInLast30Days(date, patientVisits) {
    // const now = new Date();
    // const last30Days = now;
    var today = new Date();
    var lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    lastDayOfMonth.setDate(lastDayOfMonth.getDate() - 30);

    const existsInLast30Days = patientVisits.filter((patient) => {
      return (
        today.getTime() - new Date(patient).getTime() <=
        30 * 24 * 60 * 60 * 1000
      );
    });

    return existsInLast30Days.includes(date);
  }

  logout() {
    localStorage.removeItem('token');
    this.router.navigate(['/']);
  }
}
