<div class="container">
    <h2>Audit Trail</h2>
    <mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
        <!-- User Name Column -->
        <ng-container matColumnDef="userName">
            <mat-header-cell *matHeaderCellDef mat-sort-header> User </mat-header-cell>
            <mat-cell *matCellDef="let log"> {{ log.UserName }} </mat-cell>
        </ng-container>

        <!-- Action Column -->
        <ng-container matColumnDef="action">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Action </mat-header-cell>
            <mat-cell *matCellDef="let log"> {{ log.Action }} </mat-cell>
        </ng-container>

        <!-- Controller Column -->
        <ng-container matColumnDef="controllerName">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Controller </mat-header-cell>
            <mat-cell *matCellDef="let log"> {{ log.ControllerName }} </mat-cell>
        </ng-container>

        <!-- Method Column -->
        <ng-container matColumnDef="methodName">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Method </mat-header-cell>
            <mat-cell *matCellDef="let log"> {{ log.MethodName }} </mat-cell>
        </ng-container>

        <!-- IP Address Column -->
        <ng-container matColumnDef="ipAddress">
            <mat-header-cell *matHeaderCellDef mat-sort-header> IP Address </mat-header-cell>
            <mat-cell *matCellDef="let log"> {{ log.IpAddress }} </mat-cell>
        </ng-container>

        <!-- Timestamp Column -->
        <ng-container matColumnDef="timeStamp">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Timestamp </mat-header-cell>
            <mat-cell *matCellDef="let log"> {{ log.TimeStamp | date: 'short' }} </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>

    <mat-paginator [length]="totalRecords" [pageSize]="pageSize" [pageSizeOptions]="[500]" showFirstLastButtons>
    </mat-paginator>
</div>