<div class="container regFrm mat-elevation-z8 mt-5">
    <h2>Eligibility Check</h2>
    <form [formGroup]="eligibilityForm" (ngSubmit)="checkEligibility()" class="eligibility-form">
        <div class="row">
            <div class="col-lg-4">
                <mat-form-field class="compact-field">
                    <mat-label>Insured Last Name</mat-label>
                    <input matInput formControlName="ins_name_l" required />
                    <mat-error *ngIf="eligibilityForm.get('ins_name_l')?.hasError('required')">
                        Last Name is required.
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-lg-4">
                <mat-form-field class="compact-field">
                    <mat-label>Insured First Name</mat-label>
                    <input matInput formControlName="ins_name_f" required />
                    <mat-error *ngIf="eligibilityForm.get('ins_name_f')?.hasError('required')">
                        First Name is required.
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-lg-4">

                <mat-form-field class="compact-field">
                    <mat-label>Insured Date of Birth</mat-label>
                    <input matInput type="date" formControlName="ins_dob" required />
                    <mat-error *ngIf="eligibilityForm.get('ins_dob')?.hasError('required')">
                        Date of Birth is required.
                    </mat-error>
                </mat-form-field>

            </div>
        </div>
        <div class="row">
            <div class="col lg-8">



                <mat-form-field class="compact-field">
                    <mat-label>Payer Name</mat-label>
                    <input type="text" matInput formControlName="payer_name" [matAutocomplete]="auto" />
                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onPayerChange($event)">
                        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                            {{ option }}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>


            </div>

            <div class="col lg-2">
                <!-- Payer ID Field -->

                <mat-form-field class="compact-field">
                    <mat-label>Payer ID</mat-label>
                    <input matInput [value]="selectedPayer?.payerid" formControlName="payerid" readonly />
                </mat-form-field>
            </div>

            <div class="col-lg-2">
                <mat-form-field class="compact-field">
                    <mat-label>Patient Relationship</mat-label>
                    <mat-select formControlName="pat_rel" required>
                        <mat-option value="18">Self</mat-option>
                        <mat-option value="19">Spouse</mat-option>
                        <mat-option value="20">Child</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

        </div>
        <div class="row">
            <div class="col-lg-4">
                <mat-form-field class="compact-field">
                    <mat-label>Date of Service</mat-label>
                    <input matInput type="date" formControlName="fdos" required />


                    <mat-error *ngIf="eligibilityForm.get('fdos')?.hasError('required')">
                        Date of Service is required.
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-lg-4">
                <mat-form-field class="compact-field">
                    <mat-label>Provider NPI</mat-label>
                    <input matInput formControlName="prov_npi" required />
                    <mat-error *ngIf="eligibilityForm.get('prov_npi')?.hasError('required')">
                        Provider NPI is required.
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-lg-4">
                <mat-form-field class="compact-field">
                    <mat-label>Provider Tax ID</mat-label>
                    <input matInput formControlName="prov_taxid" required />
                    <mat-error *ngIf="eligibilityForm.get('prov_taxid')?.hasError('required')">
                        Provider Tax ID is required.
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <button mat-raised-button class="lgBtCl float-right" type="submit" [disabled]="eligibilityForm.invalid">
            Check Eligibility
        </button>
    </form>
    <!-- 
    <div class="response mt-4" *ngIf="response">
        <h3>Response:</h3>
        <mat-card>
            <pre>{{ response }}</pre>
        </mat-card>
    </div> -->



    <div class="response mt-4" *ngIf="parsedResponse">
        <h3>Eligibility Response:</h3>
        <mat-card>
            <div *ngIf="parsedResponse.elig">
                <h4>Eligibility Details</h4>
                <p><strong>Eligibility Date:</strong> {{ parsedResponse.elig.elig_result_date }}</p>
                <p><strong>Group Number:</strong> {{ parsedResponse.elig.group_number }}</p>
                <p><strong>Plan:</strong> {{ parsedResponse.elig.plan_number }}</p>
                <p><strong>Insured Name:</strong> {{ parsedResponse.elig.ins_name_f }} {{ parsedResponse.elig.ins_name_l
                    }}</p>
                <p><strong>DOB:</strong> {{ parsedResponse.elig.ins_dob }}</p>
            </div>
            <table class="benefits" *ngIf="parsedResponse.elig.benefits">
                <thead>
                    <tr>
                        <th>Code</th>
                        <th>Coverage Description</th>
                        <th>Description</th>
                        <th>Amount</th>
                        <th>Level</th>
                        <th>Network</th>
                        <th>Notes</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let benefit of parsedResponse.elig.benefits">
                        <td>{{ benefit.benefit_code }}</td>
                        <td>{{ benefit.benefit_coverage_description }}</td>
                        <td>{{ benefit.benefit_description }}</td>
                        <td>{{ benefit.benefit_amount || 'N/A' }}</td>
                        <td>{{ benefit.benefit_level_description || 'N/A' }}</td>
                        <td>{{ benefit.inplan_network }}</td>
                        <td>{{ benefit.benefit_notes || 'N/A' }}</td>
                    </tr>
                </tbody>
            </table>
        </mat-card>
    </div>

</div>