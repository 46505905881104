<div class="dialog-header ">
    <button mat-raised-button color="primary" class="close-button float-right " (click)="onClose()">Close</button>
</div>
<button type="button" (click)="print()" class="btn btn-primary ">Print</button>

<div class="container mt-3" id="printable" cdkDrag cdkDragRootElement=".cdk-overlay-pane">
    <div class="row">
        <div class="col-lg-4 ">

            <div [innerHTML]="hospital?.HospitalLogo" class="cell"></div>

        </div>
        <div class="col-lg-4"></div>
        <div class="col-lg-4 ">
            <p> {{hospital?.HospitalName}}</p>
            <p>Address: {{hospital?.HospitalAddress}}</p>
            <p>Phone: {{hospital?.HospitalPhone}}</p>
            <p>Email: {{hospital?.HospitalEmail}}</p>

        </div>
    </div>
    <div class="row">
        <h1 class="text-center" style="font-weight:bold ;">Medical Records</h1>

        <!-- <button *ngIf="show" class="btn backG float-right mr-3" onClick="window.focus(); window.print()"
            value="print"><mat-icon>print</mat-icon></button> -->
    </div>

    <h2 class="">Patient Information</h2>

    <div class="row">

        <div class="col-lg-2"><span class="bld">Patient No:</span>
            {{patient?.PatientId}}</div>
        <div class="col-lg-3">
            <span class="bld">Patient Name:</span> {{patient?.PatientFirstName}} {{patient?.PatientLastName}}
        </div>
        <div class="col-lg-2"><span class="bld">Date Of Birth:</span> {{patient?.PatientDOB}}</div>
        <div class="col-lg-2"><span class="bld">SSN:</span> {{patient?.PatientNationalId}}</div>
        <div class="col-lg-3"><span class="bld">Address:</span> {{patient?.PatientAddress}}</div>
    </div>
    <hr>




    <!-- 
            <div class="row">
                <div class="col-lg-6">
                    <h2 class="">Patient Visits Date: {{report?.createdDate | date :'short'}}</h2>

                </div>

            </div> -->
    <div class="row">
        <div class="col-lg-3"> <span class="bld">Provider Name:</span> {{report?.DoctorName}}</div>
        <div class="col-lg-3"> <span class="bld">Speciality:</span> {{report?.DoctorSpecialization}} </div>

        <div class="col-lg-3"><span class="bld">Visit Date:</span> {{report?.VistitDate | date :'short'}}
        </div>
        <div class="col-lg-3"><span class="bld">Visit Time:</span> {{report?.VistTime}}</div>
    </div>

    <hr>
    <div class="row">
        <div class="col-lg-2"><span class="bld">Report Id :</span> {{report?.DoctorReportId}}</div>
        <div class="col-lg-4"><span class="bld">Report Date :</span> {{report?.createdDate| date :'short'}}</div>
        <div class="col-lg-6"><span class="bld">Visit Type:</span> {{report?.VisitType}}</div>
    </div>

    <div class="row mt-3">

        <div class="col-lg-4"><span class="bld">Report Name :</span> {{report?.ReportName}}</div>
    </div>

    <div class="row mt-5">
        <div class="col-lg-6">
            <div class="row">
                <apx-chart [series]="heartRateChart.series" [chart]="heartRateChart.chart"
                    [xaxis]="heartRateChart.xAxis" [title]="heartRateChart.title"></apx-chart>
            </div>

            <div class="row">
                <apx-chart [series]="respirationChart.series" [chart]="respirationChart.chart"
                    [xaxis]="respirationChart.xAxis" [title]="respirationChart.title"></apx-chart>
            </div>
        </div>
        <div class="col-lg-6">
            <div class="row">
                <apx-chart [series]="tempratureChart.series" [chart]="tempratureChart.chart"
                    [xaxis]="tempratureChart.xAxis" [title]="tempratureChart.title"></apx-chart>
            </div>
            <div class="row" *ngIf="bloodPressureChart.xAxis?.categories?.length > 0">
                <apx-chart [series]="bloodPressureChart.series" [chart]="bloodPressureChart.chart"
                    [xaxis]="bloodPressureChart.xAxis" [title]="bloodPressureChart.title"></apx-chart>
            </div>
        </div>
    </div>
    <div class="row">
        <apx-chart [series]="OxygenSaturationChart.series" [chart]="OxygenSaturationChart.chart"
            [xaxis]="OxygenSaturationChart.xAxis" [title]="OxygenSaturationChart.title"></apx-chart>
    </div>

    <div class="row mt-4">
        <span class="bld ml-3">Details :</span>
        <div class="col-lg-12" [innerHTML]="report?.ReportDescription"> </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-lg-12"><span class="bld">Reported & Signed By :</span> {{report?.Signature}}
            <br> <span class=""> Date: {{report?.createdDate | date :'short'}}</span>
        </div>
    </div>
    <hr>
</div>