<div class="upload-container">
    <h2>Upload Attachment to Claim</h2>

    <!-- Form Group Wrapper -->
    <form [formGroup]="claimForm">
        <div>
            <span [ngClass]="{
                'text-green': Attachment === 'Yes',
                'text-red': Attachment === 'No'
              }">
                Attachment Allowed: {{ Attachment }}
            </span>
        </div>
        <div class="row">
            <div class="col lg-4">


                <mat-form-field appearance="fill" class="full-width" [ngClass]="{
                  'highlight-empty': claimForm.get('payer_name')?.invalid && !claimForm.get('payer_name')?.touched
                }">
                    <mat-label>Payer Name</mat-label>
                    <input type="text" matInput formControlName="payer_name" [matAutocomplete]="auto" />
                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onPayerChange($event)">
                        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                            {{ option }}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>


            </div>

            <div class="col lg-4">
                <!-- Payer ID Field -->
                <mat-form-field appearance="fill" class="full-width">
                    <mat-label>Payer ID</mat-label>
                    <input matInput [value]="selectedPayer?.payerid" formControlName="payerid" readonly />
                </mat-form-field>
            </div>




            <div class="col lg-4">
                <mat-form-field appearance="fill">
                    <mat-label>Insured Number</mat-label>
                    <input matInput formControlName="ins_number" required />
                </mat-form-field>
            </div>
        </div>

        <!-- Unique Attachment ID Input -->
        <mat-form-field appearance="fill" class="full-width">
            <mat-label>Unique Attachment ID #</mat-label>
            <input matInput formControlName="attach_acn" placeholder="Enter Attachment ID" required />
        </mat-form-field>

        <!-- Attachment Type Dropdown -->
        <mat-form-field appearance="fill" class="full-width">
            <mat-label>Attachment Type</mat-label>
            <mat-select formControlName="attach_type_code" required>
                <mat-option *ngFor="let type of attachmentTypes" [value]="type.code">
                    {{ type.description }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </form>

    <!-- File Input -->
    <input type="file" (change)="onFileSelected($event)" />

    <!-- Upload Button -->
    <button mat-raised-button color="primary" (click)="uploadClaim()" [disabled]="!selectedFile || !claimForm.valid">
        Upload
    </button>

    <!-- Response Message -->
    <div *ngIf="claimResponse" class="response-message">
        <strong>Response:</strong> {{ claimResponse }}
    </div>
</div>