import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ServiceRequest } from 'src/app/Models/servicerequest.model';
import { ServiceRequestService } from 'src/app/services/service-request.service';
import { ItemsModalComponent } from '../items-modal/items-modal.component';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { UpdateServiceRequestComponent } from '../update-service-request/update-service-request.component';
import { PatientService } from 'src/app/services/patient.service';
import { ActivatedRoute } from '@angular/router';
import { Patient } from 'src/app/Models/patient.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import html2pdf from 'html2pdf.js';

@Component({
  selector: 'app-view-service-request',
  templateUrl: './view-service-request.component.html',
  styleUrls: ['./view-service-request.component.css'],
})
export class ViewServiceRequestComponent implements OnInit {
  displayedColumns: string[] = [
    'index',
    'orderedBy',
    'subject',
    'category',
    'scheduledDate',
    'items',
    'View',
    'actions',
  ];
  email = new FormControl('');
  display: string;
  FaxImage: FormData;
  fax = new FormControl('');
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  selectedEmail = new FormControl(''); // FormControl for the email input

  serviceRequests: ServiceRequest[] = [];
  id = 0;
  patient: Patient;
  isSuccess: boolean;
  errorMessage: string;
  constructor(
    private serviceRequestService: ServiceRequestService,
    private dialog: MatDialog,
    private patientService: PatientService,
    private route: ActivatedRoute,
    private http: HttpClient,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    this.loadServiceRequests(this.id);
    this.getPatient(this.id);
  }

  loadServiceRequests(id): void {
    this.patientService.GetServiceRequest(id).subscribe({
      next: (data) => {
        this.serviceRequests = data.ServiceRequests;
        this.dataSource = new MatTableDataSource(data.ServiceRequests);

        this.dataSource.paginator = this.paginator;
      },
      error: (err) => {
        console.error('Error loading service requests:', err);
      },
    });
  }
  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  getPatient(id) {
    this.patientService.getPatientById(id).subscribe((res) => {
      this.patient = res;
    });
  }
  openItemsModal(items: any[]): void {
    this.dialog.open(ItemsModalComponent, {
      width: '40%',

      hasBackdrop: false,
      data: { items }, // Pass items to the modal
    });
  }
  @ViewChild('LabOrder') LabOrder!: ElementRef;

  sendtoLab1() {
    let body = {
      Email: this.email.value || this.selectedEmail.value,
      Subject: `New Order`,
      Template: `${this.LabOrder.nativeElement.innerHTML}`,
    };
    this.serviceRequestService.sendLabOroder(body).subscribe((res) => {
      alert('Order sent');
    });
  }
  sendtoLab(order: ServiceRequest): void {
    if (!this.selectedEmail.value) {
      alert('Please enter an email address.');
      return;
    }

    const body = {
      Email: this.selectedEmail.value,
      Subject: `New Order - ${order.Subject}`,
      Template: this.generateOrderTemplate(order),
    };

    this.serviceRequestService.sendLabOroder(body).subscribe({
      next: () => {
        alert('Order sent successfully!');
      },
      error: (err) => {
        console.error('Error sending  order:', err);
        alert('Failed to send  order.');
      },
    });
  }
  print() {
    window.print();
  }
  private generateOrderTemplate(order: ServiceRequest): string {
    const itemsHtml = order.Items?.map(
      (item) =>
        `<li><strong>Name:</strong> ${item.Name}, <strong>Code:</strong> ${
          item.Code
        }, <strong>Comments:</strong> ${item.Comments || 'N/A'}</li>`
    ).join('');

    return `
      <div>
        <h2>New Service request</h2>
          
         <p><strong>Patient Name:</strong> ${
           this.patient.PatientFirstName + this.patient.PatientLastName
         }</p>
        <p><strong>Patient DOB:</strong> ${this.patient.PatientDOB}</p>
        <p><strong>Subject:</strong> ${order.Subject}</p>
        <p><strong>Category:</strong> ${order.Category}</p>
        <p><strong>Scheduled Date:</strong> ${order.ScheduledDate}</p>
        <h3>Items</h3>
        <ul>${itemsHtml || '<li>No items available</li>'}</ul>
        <p>------------------------------------------------</p>
        <p><strong>Ordered By:</strong> ${order.OrderedBy}</p>

      </div>
    `;
  }

  printServiceRequest(serviceRequest: ServiceRequest): void {
    const itemsHtml = serviceRequest.Items?.map(
      (item) =>
        `<li><strong>Name:</strong> ${item.Name}, <strong>Code:</strong> ${
          item.Code
        }, <strong>Comments:</strong> ${item.Comments || 'N/A'}</li>`
    ).join('');

    const printContents = `
      <div>
        <h2>New Service request</h2>
   
         <p><strong>Patient Name:</strong> ${
           this.patient.PatientFirstName + this.patient.PatientLastName
         }</p>
        <p><strong>Patient DOB:</strong> ${this.patient.PatientDOB}</p>
        <p><strong>Subject:</strong> ${serviceRequest.Subject}</p>
        <p><strong>Category:</strong> ${serviceRequest.Category}</p>
        <p><strong>Scheduled Date:</strong> ${serviceRequest.ScheduledDate}</p>
        <h3>Items</h3>
        <ul>${itemsHtml || '<li>No items available</li>'}</ul>
        <p>------------------------------------------------</p>

        <p><strong>Ordered By:</strong> ${serviceRequest.OrderedBy}</p>

      </div>
    `;

    const printWindow = window.open('', '_blank');
    if (printWindow) {
      printWindow.document.write(`
        <html>
          <head>
            <title>Print Service Request</title>
            <style>
              body {
                font-family: Arial, sans-serif;
                margin: 20px;
              }
              h2, h3 {
                color: #333;
              }
              ul {
                list-style-type: none;
                padding: 0;
              }
              li {
                margin-bottom: 10px;
              }
              strong {
                font-weight: bold;
              }
            </style>
          </head>
          <body>
            ${printContents}
          </body>
        </html>
      `);
      printWindow.document.close();
      printWindow.print();
    }
  }

  deleteServiceRequest(id: number): void {
    if (confirm('Are you sure you want to delete this service request?')) {
      this.serviceRequestService.deleteServiceRequest(id).subscribe({
        next: () => {
          alert('Service request deleted successfully!');
          this.loadServiceRequests(this.id);
        },
        error: (err) => {
          console.error(err);
          alert('Failed to delete service request.');
        },
      });
    }
  }

  openUpdateDialog(serviceRequestId: number): void {
    const dialogRef = this.dialog.open(UpdateServiceRequestComponent, {
      width: '40%',
      disableClose: true,
      hasBackdrop: false,
      data: { serviceRequestId }, // Pass as an object with `serviceRequestId`
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.loadServiceRequests(this.id);
      }
    });
  }

  Frm = this.fb.group({
    FaxNumber: ['', Validators.required],
    Subject: [''],
    Template: [''],
  });

  get FaxNumber() {
    return this.Frm.get('FaxNumber');
  }

  get Subject() {
    return this.Frm.get('Subject');
  }

  get Template() {
    return this.Frm.get('Template');
  }
  openModal() {
    this.display = 'block';
  }
  onCloseHandled() {
    this.display = 'none';
  }
  image(e: any) {
    let formData = new FormData();
    let files = e.target.files;
    // this.FaxImage = files[0].name;
    formData.append('media', files[0], files[0].name);
    this.FaxImage = formData;
  }
  formSubmit() {}
  faxPresciption() {
    // const { FaxNumber } = this.Frm.value;
    const faxNumber = this.fax.value!;
    this.apiTriggerer({ toNumber: faxNumber });
  }

  async apiTriggerer({ toNumber }: { toNumber: string }) {
    try {
      let mediaName: string;

      if (this.FaxImage) {
        // Upload image to Telnyx
        const { media_name } = await this.uploadImage();
        mediaName = media_name;
        console.log('media', mediaName);
      } else {
        // Generate PDF from Template
        const templatePdfBlob = await this.generatePdfFromTemplate(
          this.Frm.value['Template']!
        );

        // Upload PDF to Telnyx
        const {
          data: { media_name },
        } = await this.uploadPdf(templatePdfBlob);
        mediaName = media_name;
      }

      // Send fax using Telnyx API
      const faxResponse = await this.sendFax({ toNumber, mediaName });
      this.isSuccess = true;
      this.errorMessage = '';
      console.log('Fax sent successfully:', faxResponse);
    } catch (error) {
      console.error('Failed to send fax:', error);
      this.isSuccess = false;
      this.errorMessage = 'Failed to send fax. Please try again.';
    }
  }

  uploadImage(): Promise<{ media_name: string }> {
    const headers = new HttpHeaders({
      Authorization:
        'Bearer KEY018F1B3936DBB73C952D24A5C5946FB7_uHXA9t9leqKfXcg7qaiwNj',
    });

    return new Promise((resolve, reject) => {
      this.http
        .post<any>('https://api.telnyx.com/v2/media', this.FaxImage, {
          headers,
        })
        .subscribe(
          (response) => resolve(response?.data),
          (error) => reject(error)
        );
    });
  }

  sendFax({
    toNumber,
    mediaName,
  }: {
    toNumber: string;
    mediaName: string;
  }): Promise<any> {
    const headers = new HttpHeaders({
      Authorization:
        'Bearer KEY018F1B3936DBB73C952D24A5C5946FB7_uHXA9t9leqKfXcg7qaiwNj',
    });

    return this.http
      .post<any>(
        'https://api.telnyx.com/v2/faxes',
        {
          connection_id: '2433304486127601151',
          media_name: mediaName,
          from: '+12523900048',
          to: toNumber,
        },
        { headers }
      )
      .toPromise();
  }

  // async generatePdfFromTemplate(template: string): Promise<Blob> {
  //   const doc = new jsPDF()
  //   doc.text(template, 10, 10) // Add text from template

  //   return doc.output('blob')
  // }

  async generatePdfFromTemplate(template: string): Promise<Blob> {
    // Create a container element
    const container = document.createElement('div');
    (container.innerHTML = `${this.LabOrder.nativeElement.innerHTML}`),
      document.body.appendChild(container);

    return new Promise((resolve, reject) => {
      html2pdf()
        .from(container)
        .toPdf()
        .output('blob')
        .then((blob: Blob) => {
          document.body.removeChild(container);
          resolve(blob);
        })
        .catch((error: any) => {
          document.body.removeChild(container);
          reject(error);
        });
    });
  }

  uploadPdf(pdfBlob: Blob): Promise<{ data: { media_name: string } }> {
    const headers = new HttpHeaders({
      Authorization:
        'Bearer KEY018F1B3936DBB73C952D24A5C5946FB7_uHXA9t9leqKfXcg7qaiwNj',
    });

    const formData = new FormData();
    formData.append('media', pdfBlob, 'fax.pdf');

    return this.http
      .post<any>('https://api.telnyx.com/v2/media', formData, { headers })
      .toPromise();
  }

  faxes() {
    const headers = new HttpHeaders({
      Authorization:
        'Bearer KEY018F1B3936DBB73C952D24A5C5946FB7_uHXA9t9leqKfXcg7qaiwNj',
    });

    return this.http
      .post<any>(
        'https://api.telnyx.com/v2/faxes',
        {
          connection_id: '2433304486127601151',
          media_name: `${this.LabOrder.nativeElement.innerHTML}`,
          from: '+12523900048',
          to: this.fax.value,
        },
        { headers }
      )
      .subscribe((res) => {
        console.log(res);
      });
  }
}
