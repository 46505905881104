import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Doctor } from 'src/app/Models/doctor.model';
import { PatientBooking } from 'src/app/Models/patientbooking.model';
import { AppointmentService } from 'src/app/services/appointment.service';
import { DoctorService } from 'src/app/services/doctor.service';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-update-appointment',
  templateUrl: './update-appointment.component.html',
  styleUrls: ['./update-appointment.component.css'],
})
export class UpdateAppointmentComponent implements OnInit {
  id;
  options: string[] = ['In Person Visit', 'TeleVisit'];
  hospitalId: number;

  helper = new JwtHelperService();
  myToken = this.tokenService.getToken();
  STS: string[] = [
    'Arrived',
    'Completed',
    'Approved',
    'Confirmed by Phone',
    'not confirmed',
    'cancelled',
  ];
  doctorSpeciality: string[] = [
    'Allergy and Immunology',
    'Anesthesiology',
    'Cardiology',
    'Critical Care Medicine',
    'Colon and Rectal Surgery',
    'Dermatology',
    'Emergency Medicine',
    'Family Medicine',
    'Forensic Pathology',
    'Gastroenterology',
    'General Medicine',
    'Geriatric Medicine',
    'General Surgery',
    'Genetics and Genomics',
    'Hospice and Palliative Medicine',
    'Hospital Medicine',
    'General Internal Medicine',
    'General Surgery',
    'Neurology',
    'Neurological Surgery',
    'Obstetrics and Gynecology',
    'Ophthalmic Surgery',
    'Orthopaedic Surgery',
    'Otolaryngology',
    'Occupational Medicine',
    'Ophthalmology',
    'Orthopedic Surgery',
    'Pathology',
    'Pediatrics',
    'Physical Medicine and Rehabilitation',
    'Plastic Surgery',
    'Preventive Medicine',
    'Psychiatry',
    'Radiology',
    'Respirology',
    'Rheumatology',
    'Sleep Medicine',
    'Thoracic Surgery',
    'Urology',
    'Urology',
    'Cardiovascular Surgery',
    'Spinal Surgeon',
  ];
  doctorList: Doctor[] = [];
  newToken: PatientBooking;
  filteredSpecialization: string | null = null;
  selectedDoctor: Doctor;
  spciality: string;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private appointmentService: AppointmentService,
    private fb: UntypedFormBuilder,
    private doctorService: DoctorService,
    private tokenService: TokenService,
    @Inject(MAT_DIALOG_DATA) public data: { PatientBookingId: number },
    public dialogRef: MatDialogRef<UpdateAppointmentComponent>
  ) {}

  ngOnInit(): void {
    // this.route.params.subscribe((res) => {
    //   this.id = res['id'];
    // });
    this.id = this.data.PatientBookingId;

    this.loadPatientData();
    this.getDoctors();

    this.getlastToken();
  }

  addVisitFrm = this.fb.group({
    DoctorId: [''],
    DoctorName: ['', Validators.required],
    DoctorSpecialization: ['', Validators.required],
    VisitType: ['', Validators.required],
    VisitDate: ['', Validators.required],
    VisitTime: ['', Validators.required],
    Status: ['', Validators.required],
    Message: [''],
  });

  get DoctorId() {
    return this.addVisitFrm.get('DoctorId');
  }
  get Status() {
    return this.addVisitFrm.get('Status');
  }
  get VisitType() {
    return this.addVisitFrm.get('VisitType');
  }
  get DoctorName() {
    return this.addVisitFrm.get('DoctorName');
  }

  get DoctorSpecialization() {
    return this.addVisitFrm.get('DoctorSpecialization');
  }

  get VisitDate() {
    return this.addVisitFrm.get('VisitDate');
  }
  get VisitTime() {
    return this.addVisitFrm.get('VisitTime');
  }

  get Message() {
    return this.addVisitFrm.get('Message');
  }
  loadPatientData() {
    this.appointmentService.getPatientBookingById(this.id).subscribe((res) => {
      this.addVisitFrm.patchValue(res); // Patch entire form
    });
  }
  getDoctors() {
    this.doctorService.getDoctors().subscribe((res) => {
      this.doctorList = res;
    });
  }
  onDoctorChange(event: any): void {
    const selectedDoctorId = event; // Event provides the selected value (DoctorId)
    this.selectedDoctor =
      this.doctorList.find((doctor) => doctor.DoctorId === selectedDoctorId) ||
      null;

    // Update DoctorSpecialization in the form
    if (this.selectedDoctor) {
      this.addVisitFrm.patchValue({
        DoctorSpecialization: this.selectedDoctor.DoctorSpecialization,
      });
    } else {
      this.addVisitFrm.patchValue({
        DoctorSpecialization: '',
      });
    }
  }
  getSpeciality() {
    this.doctorService.getDoctorById(this.DoctorId.value).subscribe((res) => {
      this.spciality = res.DoctorSpecialization;
      this.DoctorSpecialization.setValue(res.DoctorSpecialization);
    });
  }
  getlastToken() {
    this.appointmentService.generateToken().subscribe((res) => {
      this.newToken = res;
    });
  }
  formSubmit() {
    let doctorName = this.addVisitFrm.value['DoctorName'];
    let doctorSpecialization = this.addVisitFrm.value['DoctorSpecialization'];
    let visitType = this.addVisitFrm.value['VisitType'];
    let date = this.addVisitFrm.value['VisitDate'];
    let time = this.addVisitFrm.value['VisitTime'];
    let message = this.addVisitFrm.value['Message'];
    let status = this.addVisitFrm.value['Status'];

    let body = {
      DoctorName: doctorName,
      DoctorSpecialization: doctorSpecialization,
      VisitType: visitType,
      VisitDate: date,
      VisitTime: time,
      Message: message,
      Status: status,
      Tocken: this.newToken,
      // Tocken: Date.now(),
    };

    this.appointmentService
      .updatePatientBooking(this.id, body)
      .subscribe((res) => {
        this.onClose();
      });
  }
  onClose(): void {
    this.dialogRef.close();
  }
}
