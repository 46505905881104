import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceRequestService } from 'src/app/services/service-request.service';

@Component({
  selector: 'app-update-service-request',
  templateUrl: './update-service-request.component.html',
  styleUrls: ['./update-service-request.component.css'],
})
export class UpdateServiceRequestComponent implements OnInit {
  serviceRequestForm: FormGroup;
  categories = ['Lab', 'Radiology', 'Procedure'];
  id: number = 0;
  allItems = {
    Lab: [
      // General Tests
      { name: 'Complete Blood Count (CBC)', code: 'CBC001' },
      { name: 'Basic Metabolic Panel (BMP)', code: 'BMP002' },
      { name: 'Comprehensive Metabolic Panel (CMP)', code: 'CMP003' },
      { name: 'Lipid Panel', code: 'LIP004' },
      { name: 'Thyroid Stimulating Hormone (TSH)', code: 'TSH005' },
      { name: 'Hemoglobin A1c', code: 'HBA1C006' },

      // Vitamin and Nutrient Tests
      { name: 'Vitamin D, 25-Hydroxy', code: 'VD007' },
      { name: 'Vitamin B12', code: 'VB1216' },
      { name: 'Folate (Folic Acid)', code: 'FOL015' },
      { name: 'Iron Studies', code: 'IRON008' },
      { name: 'Ferritin', code: 'FER014' },

      // Inflammation and Infection
      { name: 'C-Reactive Protein (CRP)', code: 'CRP009' },
      { name: 'Erythrocyte Sedimentation Rate (ESR)', code: 'ESR012' },
      { name: 'Procalcitonin (PCT)', code: 'PCT021' },
      { name: 'Lactic Acid', code: 'LACT022' },

      // Kidney Function
      { name: 'Blood Urea Nitrogen (BUN)', code: 'BUN020' },
      { name: 'Creatinine', code: 'CRE023' },
      { name: 'Urinalysis', code: 'UA024' },
      { name: 'Microalbumin', code: 'MA025' },

      // Liver Function
      { name: 'Liver Function Panel', code: 'LFP018' },
      { name: 'Alanine Transaminase (ALT)', code: 'ALT026' },
      { name: 'Aspartate Transaminase (AST)', code: 'AST027' },
      { name: 'Bilirubin', code: 'BILI028' },
      { name: 'Alkaline Phosphatase (ALP)', code: 'ALP029' },

      // Heart Health
      { name: 'Troponin I', code: 'TROP030' },
      { name: 'Troponin T', code: 'TROP031' },
      { name: 'BNP (B-type Natriuretic Peptide)', code: 'BNP032' },
      { name: 'Lipid Panel (Cholesterol, HDL, LDL)', code: 'LIP004' },

      // Diabetes Monitoring
      { name: 'Glucose, Fasting', code: 'GLU033' },
      { name: 'Glucose, Random', code: 'GLU034' },
      { name: 'Hemoglobin A1c (HbA1c)', code: 'HBA1C006' },

      // Hormone Tests
      { name: 'Free T3', code: 'FT3035' },
      { name: 'Free T4', code: 'FT4036' },
      { name: 'Cortisol, AM', code: 'CORT037' },
      { name: 'Cortisol, PM', code: 'CORT038' },
      { name: 'Parathyroid Hormone (PTH)', code: 'PTH039' },

      // Blood Coagulation
      { name: 'Prothrombin Time (PT)', code: 'PT010' },
      { name: 'Partial Thromboplastin Time (PTT)', code: 'PTT011' },
      { name: 'INR (International Normalized Ratio)', code: 'INR040' },

      // Infectious Disease
      { name: 'HIV Antigen/Antibody Test', code: 'HIV041' },
      { name: 'Hepatitis B Surface Antigen (HBsAg)', code: 'HBV042' },
      { name: 'Hepatitis C Antibody (HCV)', code: 'HCV043' },
      { name: 'COVID-19 PCR Test', code: 'COVID044' },
      { name: 'COVID-19 Antigen Test', code: 'COVID045' },

      // Allergy and Immunology
      { name: 'Immunoglobulin E (IgE)', code: 'IGE046' },
      { name: 'Rheumatoid Factor (RF)', code: 'RF047' },
      { name: 'ANA (Antinuclear Antibodies)', code: 'ANA048' },
      { name: 'Allergy Panel (Food)', code: 'ALL049' },
      { name: 'Allergy Panel (Environmental)', code: 'ALL050' },
    ],
    Radiology: [
      // General Radiology
      { name: 'Chest X-Ray (PA and Lateral)', code: 'XR001' },
      { name: 'Abdominal X-Ray', code: 'XR002' },
      { name: 'Pelvic X-Ray', code: 'XR003' },
      { name: 'Spinal X-Ray (Cervical)', code: 'XR004' },
      { name: 'Spinal X-Ray (Thoracic)', code: 'XR005' },
      { name: 'Spinal X-Ray (Lumbar)', code: 'XR006' },

      // Computed Tomography (CT)
      { name: 'CT Head without Contrast', code: 'CT001' },
      { name: 'CT Chest with Contrast', code: 'CT002' },
      { name: 'CT Abdomen and Pelvis without Contrast', code: 'CT003' },
      { name: 'CT Angiography (CTA) Chest', code: 'CT004' },
      { name: 'CT Angiography (CTA) Abdomen', code: 'CT005' },

      // Magnetic Resonance Imaging (MRI)
      { name: 'MRI Brain without Contrast', code: 'MRI001' },
      { name: 'MRI Brain with and without Contrast', code: 'MRI002' },
      { name: 'MRI Spine (Cervical)', code: 'MRI003' },
      { name: 'MRI Spine (Lumbar)', code: 'MRI004' },
      { name: 'MRI Knee without Contrast', code: 'MRI005' },

      // Ultrasound
      { name: 'Ultrasound Abdomen Complete', code: 'US001' },
      { name: 'Ultrasound Pelvis', code: 'US002' },
      { name: 'Ultrasound Thyroid', code: 'US003' },
      { name: 'Ultrasound Breast', code: 'US004' },
      { name: 'Ultrasound Obstetric (First Trimester)', code: 'US005' },

      // Mammography
      { name: 'Screening Mammogram', code: 'MAM001' },
      { name: 'Diagnostic Mammogram', code: 'MAM002' },

      // Nuclear Medicine
      { name: 'PET Scan (Whole Body)', code: 'PET001' },
      { name: 'Bone Scan', code: 'NM001' },
      { name: 'Thyroid Scan', code: 'NM002' },

      // Fluoroscopy
      { name: 'Barium Swallow', code: 'FL001' },
      { name: 'Upper GI Series', code: 'FL002' },
      { name: 'Hysterosalpingography (HSG)', code: 'FL003' },

      // Specialized Imaging
      { name: 'Bone Density (DEXA Scan)', code: 'DEX001' },
      { name: 'Carotid Doppler Ultrasound', code: 'DOP001' },
      { name: 'Echocardiogram (Echo)', code: 'ECHO001' },
    ],
    Procedure: [
      // General Surgery
      { name: 'Appendectomy', code: 'PROC001' },
      { name: 'Cholecystectomy (Gallbladder Removal)', code: 'PROC002' },
      { name: 'Hernia Repair', code: 'PROC003' },
      { name: 'Mastectomy', code: 'PROC004' },
      { name: 'Thyroidectomy', code: 'PROC005' },

      // Orthopedic Surgery
      { name: 'Total Knee Replacement', code: 'ORTH001' },
      { name: 'Total Hip Replacement', code: 'ORTH002' },
      { name: 'Arthroscopy (Knee)', code: 'ORTH003' },
      { name: 'Rotator Cuff Repair', code: 'ORTH004' },

      // Gastrointestinal Procedures
      { name: 'Colonoscopy', code: 'GI001' },
      { name: 'Upper Endoscopy (EGD)', code: 'GI002' },
      { name: 'Hemorrhoidectomy', code: 'GI003' },
      { name: 'Liver Biopsy', code: 'GI004' },

      // Cardiovascular Procedures
      { name: 'Coronary Angioplasty (PCI)', code: 'CARD001' },
      { name: 'Coronary Artery Bypass Grafting (CABG)', code: 'CARD002' },
      { name: 'Pacemaker Insertion', code: 'CARD003' },
      { name: 'Carotid Endarterectomy', code: 'CARD004' },

      // Obstetrics and Gynecology
      { name: 'Cesarean Section (C-Section)', code: 'OBGYN001' },
      { name: 'Hysterectomy', code: 'OBGYN002' },
      { name: 'Dilation and Curettage (D&C)', code: 'OBGYN003' },
      { name: 'Tubal Ligation', code: 'OBGYN004' },

      // Urological Procedures
      { name: 'Cystoscopy', code: 'URO001' },
      { name: 'Prostatectomy', code: 'URO002' },
      { name: 'Ureteroscopy', code: 'URO003' },

      // ENT Procedures
      { name: 'Tonsillectomy', code: 'ENT001' },
      { name: 'Septoplasty', code: 'ENT002' },
      { name: 'Myringotomy (Ear Tubes)', code: 'ENT003' },

      // Neurological Procedures
      { name: 'Craniotomy', code: 'NEURO001' },
      { name: 'Spinal Fusion', code: 'NEURO002' },
      { name: 'Ventriculoperitoneal (VP) Shunt Placement', code: 'NEURO003' },

      // Cosmetic and Reconstructive Procedures
      { name: 'Breast Augmentation', code: 'COS001' },
      { name: 'Liposuction', code: 'COS002' },
      { name: 'Rhinoplasty', code: 'COS003' },
    ],
  };
  filteredItems: { name: string; code: string }[] = [];
  isSuccess: boolean = false;
  constructor(
    private fb: FormBuilder,
    private serviceRequestService: ServiceRequestService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: { serviceRequestId: number },
    public dialogRef: MatDialogRef<UpdateServiceRequestComponent>
  ) {
    this.serviceRequestForm = this.fb.group({
      orderedBy: ['', Validators.required],
      subject: ['', Validators.required],
      description: ['', Validators.required],
      category: ['', Validators.required],
      scheduledDate: ['', Validators.required],
      items: this.fb.array([]),
      status: [''],
    });
  }

  ngOnInit(): void {
    this.id = this.data.serviceRequestId;
    this.loadServiceRequest(this.id);
  }
  private formatDate(date: string | Date): string {
    if (!date) return '';
    const parsedDate = new Date(date);
    return parsedDate.toISOString().split('T')[0]; // Extract `YYYY-MM-DD`
  }
  loadServiceRequest(id: number): void {
    this.serviceRequestService.getServiceRequestById(id).subscribe({
      next: (data) => {
        // Ensure the date is properly formatted before patching
        const formattedDate = this.formatDate(data.ScheduledDate);

        // Patch the basic fields
        this.serviceRequestForm.patchValue({
          orderedBy: data.OrderedBy,
          subject: data.Subject,
          description: data.Description,
          category: data.Category,
          status: data.Status,
          scheduledDate: formattedDate, // Use the formatted date
        });

        // Populate the items FormArray
        this.items.clear();
        data.Items.forEach((item: any) => {
          this.items.push(
            this.fb.group({
              name: [item.Name, Validators.required],
              code: [item.Code, Validators.required],
              comments: [item.Comments || ''],
            })
          );
        });

        // Update filtered items for the selected category
        this.filteredItems = this.allItems[data.Category] || [];
      },
      error: (err) => {
        console.error('Error loading service request:', err);
        alert('Failed to load service request.');
      },
    });
  }

  get items(): FormArray {
    return this.serviceRequestForm.get('items') as FormArray;
  }

  onCategoryChange(): void {
    const selectedCategory = this.serviceRequestForm.get('category')?.value;
    this.filteredItems = this.allItems[selectedCategory] || [];

    // Reset the `name` and `code` fields for each item
    this.items.controls.forEach((group) => {
      group.patchValue({
        name: '',
        code: '',
      });
    });
  }

  addItem(): void {
    const itemGroup = this.fb.group({
      name: ['', Validators.required],
      code: ['', Validators.required],
      comments: [''],
    });
    this.items.push(itemGroup);
  }

  removeItem(index: number): void {
    this.items.removeAt(index);
  }

  onItemSelected(index: number, selectedName: string): void {
    const selectedItem = this.filteredItems.find(
      (item) => item.name === selectedName
    );

    if (selectedItem) {
      this.items.at(index).patchValue({
        code: selectedItem.code,
      });
    }
  }

  onSubmit(): void {
    if (this.serviceRequestForm.valid) {
      const serviceRequest = {
        ...this.serviceRequestForm.value,
      };

      this.serviceRequestService
        .updateServiceRequest(this.id, serviceRequest)
        .subscribe({
          next: () => {
            alert('Service request updated successfully!');
            this.isSuccess = true;
          },
          error: (err) => {
            console.error('Error updating service request:', err);
            alert('Failed to update service request.');
          },
        });
    } else {
      alert('Please fill out all required fields.');
    }
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
