<!-- <div>

  <button mat-raised-button color="primary" [routerLink]="['/hospitalDash/']"><mat-icon mat-list-icon>undo</mat-icon>
    Dashboard</button>
</div> -->
<div class="dialog-header">
  <button mat-raised-button color="primary" class="close-button" (click)="onClose()">Close</button>
</div>
<div class="container" cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <div class="row">
    <div class="col-lg regFrm mat-elevation-z8">
      <h1>Update Invoice</h1>
      <form (keydown.enter)="$event.preventDefault()" [formGroup]="invForm" (ngSubmit)="formSubmit()"
        class="example-form">
        <div class="row">
          <div class="col-lg-4">
            <mat-form-field class="example-full-width">
              <mat-label>Patient Name</mat-label>
              <input type="text" formControlName="PatientName" matInput readonly />
              <mat-error>Please Enter Value</mat-error>

            </mat-form-field>
            <!-- <mat-label>Patient Name</mat-label> -->
            <!-- <p class="text-bold">Patient Name: <input type="text" formControlName="PatientName" matInput readonly /></p> -->
          </div>
        </div>
        <div class="row">

          <div class="col-lg-4">
            <mat-form-field class="example-full-width">
              <mat-label>Insuarance Name</mat-label>
              <input type="text" formControlName="InsuaranceName" matInput>
              <mat-error>Please Enter Value</mat-error>

            </mat-form-field>

          </div>
          <div class="col-lg-4">
            <mat-form-field class="example-full-width">
              <mat-label>Insuarance No</mat-label>
              <input type="text" formControlName="InsuaranceNo" matInput>
              <mat-error>Please Enter Value</mat-error>

            </mat-form-field>

          </div>
          <div class="col-lg-4">
            <mat-form-field class="example-full-width">
              <mat-label>Group No</mat-label>
              <input type="text" formControlName="GroupNo" matInput>
              <mat-error>Please Enter Value</mat-error>

            </mat-form-field>
          </div>

        </div>


        <div class="row">


          <div class="col-lg-3">
            <mat-form-field class="example-full-width">
              <mat-label>Subject</mat-label>
              <input type="text" formControlName="Title" matInput>
              <mat-error>Please Enter Value</mat-error>

            </mat-form-field>
          </div>

          <div class="col-lg-3">
            <mat-form-field class="example-full-width">
              <mat-label>Date of Invoice</mat-label>
              <input type="date" formControlName="Date" matInput>
              <mat-error>Please Enter Value</mat-error>

            </mat-form-field>
          </div>
          <div class="col-lg-3">
            <mat-form-field class="example-full-width">
              <mat-label>Provider Name</mat-label>
              <input type="text" formControlName="DoctorName" matInput>
              <mat-error>Please Enter Provider Name</mat-error>

            </mat-form-field>
          </div>
          <div class="col-lg-3">
            <mat-form-field class="example-full-width">
              <mat-label>NPI Number</mat-label>
              <input type="text" formControlName="NpiNumber" matInput>
              <mat-error>Please Enter National Provider Number</mat-error>

            </mat-form-field>
          </div>
        </div>
        <div class="" formArrayName="InvoiceItems">
          <div class="row" *ngFor="let item of InvoiceItems.controls; let i=index">
            <ng-container [formGroup]="item">
              <input type="text" hidden formControlName="InvoiceItemId">

              <div class="col-lg-6">

                <mat-form-field class="example-full-width">
                  <mat-label>Procedure Code</mat-label>
                  <input type="text" matInput formControlName="ProcedureCode" (change)="updateInvoiceItems(i)">
                  <mat-error>Please Enter Value</mat-error>

                </mat-form-field>
              </div>
              <!-- <div class="col-lg-4">
                <mat-form-field appearance="fill" class="example-full-width">
                  <mat-label>Description </mat-label>

                  <textarea matInput formControlName="Description" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                    cdkAutosizeMinRows="1" cdkAutosizeMaxRows="8" (change)="updateInvoiceItems(i)"></textarea>
                  <mat-error>Please Enter Value</mat-error>

                </mat-form-field>
              </div> -->
              <div class="col-lg-1">

                <mat-form-field class="example-full-width">
                  <mat-label>Amount</mat-label>
                  <input type="number" matInput formControlName="Amount" (input)="getGrandTotal()"
                    (change)="updateInvoiceItems(i)">
                  <mat-error>Please Enter Value</mat-error>

                </mat-form-field>
              </div>
              <div class="col-lg-1">

                <mat-form-field class="example-full-width">
                  <mat-label>Units</mat-label>
                  <input type="number" matInput formControlName="Units">
                  <mat-error>Please Enter Value</mat-error>

                </mat-form-field>
              </div>
              <div class="col-lg-2">

                <mat-form-field class="example-full-width">
                  <mat-label>Duration(min)</mat-label>
                  <input type="number" matInput formControlName="Duration">
                  <mat-error>Please Enter Value</mat-error>

                </mat-form-field>
              </div>
              <div class="col-lg-1">
                <button type="button" mat-mini-fab
                  (click)="removeItem(i,item.value.InvoiceItemId)"><mat-icon>remove</mat-icon></button>
              </div>
            </ng-container>
          </div>

          <button type="button" mat-mini-fab color="primary" (click)="addItem()"><mat-icon>add</mat-icon></button>


        </div>
        <div class="row">
          <div class="col-lg-3">

            <mat-form-field class="example-full-width">
              <mat-label>CoPay</mat-label>
              <input type="number" matInput formControlName="CoPay">
              <mat-error>Please Enter Value</mat-error>

            </mat-form-field>
          </div>

          <div class="col-lg-3">

            <mat-form-field class="example-full-width">
              <mat-label>Insurance Pay</mat-label>
              <input type="number" matInput formControlName="InsurancePay" (input)="getGrandTotal()">

            </mat-form-field>
          </div>
          <div class="col-lg-3">

            <mat-form-field class="example-full-width">
              <mat-label>Adjusment Amount</mat-label>
              <input type="number" matInput formControlName="AdjustmentAmount" (input)="getGrandTotal()">

            </mat-form-field>
          </div>
        </div>

        <div class="row">


          <div class="col-lg-3">

            <mat-form-field class="example-full-width">
              <mat-label>SubTotal</mat-label>
              <input type="number" matInput formControlName="SubTotal" readonly>

            </mat-form-field>
          </div>

          <div class="col-lg-3">

            <mat-form-field class="example-full-width">
              <mat-label>Total</mat-label>
              <input type="number" matInput formControlName="Total" readonly>
            </mat-form-field>
          </div>
          <div class="col-lg-3">

            <mat-form-field class="example-full-width">
              <mat-label>Balance Amount to Pay</mat-label>
              <input type="number" matInput formControlName="BalanceAmount" readonly>
            </mat-form-field>
          </div>

          <div class="col-lg-3">
            <mat-form-field class="example-full-width">
              <mat-label>Status</mat-label>
              <!-- <input type="text" formControlName="DoctorSpecialization" matInput > -->
              <mat-select formControlName="Status">

                <mat-option *ngFor="let sp of StatusList" [value]="sp">{{sp}}</mat-option>
                <mat-error>please select status</mat-error>
              </mat-select>
            </mat-form-field>
          </div>
        </div>








        <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
          Successfully Updated Invoice
          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <button type="submit" class="float-right lgBtCl" mat-button>
          <mat-icon>save</mat-icon>
          Save</button>
      </form>

    </div>
  </div>

</div>