import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import Quill from 'quill';
import { Observable, startWith, map } from 'rxjs';
import { Allergy } from 'src/app/Models/allergy.models';
import { Doctor } from 'src/app/Models/doctor.model';
import { DoctorTemplate } from 'src/app/Models/doctortemplate.model';
import { HospitalTemplate } from 'src/app/Models/hospitaltemplate.mode';
import { Labreport } from 'src/app/Models/labreport.model';
import { NurseReport } from 'src/app/Models/nursereport.model';
import { Patient } from 'src/app/Models/patient.model';
import { Prescription } from 'src/app/Models/prescription.model';
import { RadiologyMri } from 'src/app/Models/radiologymri.model';
import { RadiologyReport } from 'src/app/Models/radiologyreport.model';
import { UploadLabReport } from 'src/app/Models/uploadlabreport.model';
import { VisitSummary } from 'src/app/Models/visitsummary.model';
import { ClinicalAssistanceService } from 'src/app/services/clinical-assistance.service';
import { DoctorService } from 'src/app/services/doctor.service';
import { HospitaltemplateService } from 'src/app/services/hospitaltemplate.service';
import { PatientService } from 'src/app/services/patient.service';
import { TokenService } from 'src/app/services/token.service';
import { VisitSummaryService } from 'src/app/services/visit-summary.service';
import { AddInvoiceOnProgressNoteComponent } from '../add-invoice-on-progress-note/add-invoice-on-progress-note.component';
import { FavoriteDiagnosisPopupComponent } from '../favorite-diagnosis-popup/favorite-diagnosis-popup.component';
import DOMPurify from 'dompurify'; // Import DOMPurify for sanitization
import { LabOrder } from 'src/app/Models/laborder.model';
import { RadiologyOrder } from 'src/app/Models/radilogyorder.model';
import { Procedure } from 'src/app/Models/procedure.model';
import { Refferal } from 'src/app/Models/refferal.model';
import { PatientsPharmacy } from 'src/app/Models/patientspharmacy.model';
import { ServiceRequest } from 'src/app/Models/servicerequest.model';

@Component({
  selector: 'app-add-visit-summary',
  templateUrl: './add-visit-summary.component.html',
  styleUrls: ['./add-visit-summary.component.css'],
})
export class AddVisitSummaryComponent implements OnInit {
  displayedColumns: string[] = ['Name'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: any;
  id = 0;
  reportList: VisitSummary[] = [];
  isSuccess = false;
  helper = new JwtHelperService();
  myToken = this.tokenService.getToken();
  UserId: number = 0;
  doctor: Doctor;
  patient: Patient;
  templates: DoctorTemplate[] = [];
  templatesDrProgressNote: VisitSummary[] = [];
  templatesVitals: NurseReport[] = [];
  templatesPrescriptions: Prescription[] = [];
  templatesLabOrders: LabOrder[];
  templatesRadilogyOrders: RadiologyOrder[];
  templatesProcedureOrders: Procedure[];
  templatesRadiologyReports: RadiologyReport[];
  templatesServiceRequests: ServiceRequest[];
  filteredReportName: Observable<string[]>;
  filteredShortcut: Observable<string[]>;
  shortcutNames: string[] = ['@MostRecent', '@00'];
  options: string[] = [
    'New Patient Office Visit',
    'New Patient Telemedicine',
    'Established Patient Follow Up Office Visit',
    'Established Patient Telemedicine',
  ];
  reportNames: string[] = ['Visit Summary'];
  quillEditor: any;
  previousTranscript: string = '';
  recognition: any;
  isRecognizing: any;

  @ViewChild('editorContainer', { static: true }) editorContainer!: ElementRef;

  suggestions: any[] = []; // Holds the suggestions based on the input
  selectedCptCode: any = null; // Store the selected CPT code to insert into the editor
  editorContent: string = ''; // To hold the content typed into the editor
  templatesRadiologyStudyReports: RadiologyMri[];
  templatesAllergies: Allergy[];
  hospitaltemplates: HospitalTemplate[];
  hospitalId = 0;
  favoriteDiagnosisCodes = [];
  templatesRefferals: Refferal[];
  templatesPatientPharmacys: PatientsPharmacy[];

  constructor(
    private fb: UntypedFormBuilder,
    private visitSummaryService: VisitSummaryService,
    private route: ActivatedRoute,
    private doctorService: DoctorService,
    private patientService: PatientService,
    private hospitalTemplateService: HospitaltemplateService,
    private dialog: MatDialog,
    private assistanceService: ClinicalAssistanceService,
    private cdRef: ChangeDetectorRef,
    private tokenService: TokenService
  ) {}
  editorModules = {
    toolbar: [
      [{ header: '1' }, { header: '2' }, { font: [] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ align: [] }],
      ['bold', 'italic', 'underline', 'strike'],
      ['link', 'image'],
      [{ color: [] }, { background: [] }],
      ['blockquote', 'code-block'],
      [{ script: 'sub' }, { script: 'super' }],
      ['emoji'], // You can add any additional modules you want
    ],
    clipboard: true, // Enable clipboard for copy-paste
  };
  ngOnInit(): void {
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.UserId = parseInt(decodedToken.nameid);

    this.loadTemplates(this.UserId);

    this.loadGlobalTemplates();

    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    this.loadVitals(this.id);
    this.loadAllergies(this.id);
    this.loadPrescriptions(this.id);

    this.loadLabOrders(this.id);
    this.loadRadiologyOrders(this.id);
    this.loadProcedureOrders(this.id);
    this.loadServiceRequests(this.id);
    this.loadReferrals(this.id);
    this.loadPharmacy(this.id);

    this.getPatient(this.id);
    this.getDoctor(this.UserId);

    this.filteredReportName = this.ReportName.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter5(value || ''))
    );
    this.filteredShortcut = this.shortcut.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter6(value || ''))
    );
  }
  ngAfterViewInit(): void {
    if (this.editorContainer && this.editorContainer.nativeElement) {
      this.quillEditor = new Quill(this.editorContainer.nativeElement, {
        theme: 'snow',
        modules: this.editorModules,
      });
      this.quillEditor.on('text-change', (delta, oldDelta, source) => {
        const editor = document.querySelector('.ql-editor') as HTMLElement;
        const container = document.querySelector(
          '.ql-container'
        ) as HTMLElement;
        container.style.height = editor.scrollHeight + 'px';
        this.onTextInput();
      });
    } else {
      console.error('Editor container not found');
    }
  }

  Frm = this.fb.group({
    ChiefComplaint: ['', Validators.required],
    ReportName: ['Visit Summary', Validators.required],
    VistitDate: ['', Validators.required],
    VisitTime: ['', Validators.required],
    Signature: ['', Validators.required],
    NextFollowUp: ['', Validators.required],
    FollowUpInstructions: ['', Validators.required],
  });
  get ChiefComplaint() {
    return this.Frm.get('ChiefComplaint');
  }
  get FollowUpInstructions() {
    return this.Frm.get('FollowUpInstructions');
  }
  get VisitType() {
    return this.Frm.get('VisitType');
  }
  get VistitDate() {
    return this.Frm.get('VistitDate');
  }
  get VisitTime() {
    return this.Frm.get('VisitTime');
  }
  get Signature() {
    return this.Frm.get('Signature');
  }
  get NextFollowUp() {
    return this.Frm.get('NextFollowUp');
  }
  get shortcut() {
    return this.Frm.get('shortcut');
  }
  get ReportName() {
    return this.Frm.get('ReportName');
  }

  get ReportDescription() {
    return this.Frm.get('ReportDescription');
  }

  startVoiceRecognition() {
    this.recognition = new (window.SpeechRecognition ||
      (window as any).webkitSpeechRecognition)();
    this.recognition.lang = 'en-US';
    this.recognition.continuous = true; // Keep listening
    this.recognition.interimResults = true; // Show real-time speech results
    this.isRecognizing = true;
    this.recognition.onresult = (event: any) => {
      const transcript = event.results[event.resultIndex][0].transcript;

      // Only insert the final result or if the text is different from the previous
      if (
        event.results[event.resultIndex].isFinal &&
        transcript !== this.previousTranscript
      ) {
        // console.log('Final Recognized Text:', transcript);

        // Get the current selection (cursor position)
        const range = this.quillEditor.getSelection();

        if (range) {
          // Insert the recognized text at the current cursor position
          this.quillEditor.insertText(range.index, transcript);
        }

        // Update the previousTranscript to prevent duplicate insertions
        this.previousTranscript = transcript;
      }
    };

    this.recognition.onerror = (event: any) => {
      // console.error('Speech recognition error:', event.error);
    };
    // Event listener for when recognition ends (you can stop manually here too if needed)
    this.recognition.onend = () => {
      // console.log('Speech recognition ended');
      this.isRecognizing = false;
    };
    this.recognition.start();
  }
  // Stop Speech Recognition
  stopRecognition() {
    if (this.isRecognizing) {
      console.log('Stopping recognition...');
      this.recognition.stop(); // Stop the recognition
      this.isRecognizing = false;
    }
  }
  getDoctor(id) {
    this.doctorService.getDoctorById(id).subscribe((res) => {
      this.doctor = res;
    });
  }
  getPatient(id) {
    this.patientService.getPatientById(id).subscribe((res) => {
      this.patient = res;
    });
  }

  loadTemplates(id) {
    this.doctorService.getDoctorTemplates(id).subscribe((data) => {
      // console.log(data);
      this.templates = data.DoctorTemplates;
      this.dataSource = new MatTableDataSource(data.DoctorTemplates);
      this.dataSource.paginator = this.paginator;
    });
  }
  loadGlobalTemplates() {
    this.hospitalTemplateService.getTemplates().subscribe((data) => {
      // console.log(data);
      this.hospitaltemplates = data;
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
    });
  }
  loadAllergies(id) {
    this.patientService.getPatientAllergy(id).subscribe((data) => {
      // console.log(data);
      this.templatesAllergies = data.Allergies;
    });
  }
  loadVitals(id) {
    this.patientService.getNurseReport(id).subscribe((data) => {
      // console.log(data);
      this.templatesVitals = data.NurseReports;
    });
  }
  loadPrescriptions(id) {
    this.patientService.getPatientPrescription(id).subscribe((data) => {
      // console.log(data);
      this.templatesPrescriptions = data.Prescriptions;
    });
  }

  loadLabOrders(id) {
    this.patientService.getPatienLabOrder(id).subscribe((data) => {
      this.templatesLabOrders = data.LabOrders;
    });
  }
  loadRadiologyOrders(id) {
    this.patientService.getRadiologyOrder(id).subscribe((data) => {
      this.templatesRadilogyOrders = data.RadiologyOrders;
    });
  }
  loadProcedureOrders(id) {
    this.patientService.getPatienProcedure(id).subscribe((data) => {
      this.templatesProcedureOrders = data.Procedures;
    });
  }
  loadServiceRequests(id) {
    this.patientService.GetServiceRequest(id).subscribe((data) => {
      this.templatesServiceRequests = data.ServiceRequests;
    });
  }
  loadReferrals(id) {
    this.patientService.GetRefferal(id).subscribe((data) => {
      this.templatesRefferals = data.Refferals;
    });
  }
  loadPharmacy(id) {
    this.patientService.getPatientsPharmacy(id).subscribe((data) => {
      this.templatesPatientPharmacys = data.PatientsPharmacys;
    });
  }
  // Method to handle click on the table row for template selection
  onTableRowClick(template: any) {
    // console.log(template.Content);
    this.quillEditor.clipboard.dangerouslyPasteHTML(template.Content);
    // this.selectTemplate(template.Content);
  }
  // This function is triggered on every keystroke or text area change in the Quill editor
  onTextInput(): void {
    const cursorPosition = this.quillEditor.getSelection()?.index;
    let currentText = this.quillEditor.getText();

    this.templates.forEach((template) => {
      if (currentText.includes(template.shortcut)) {
        const startIndex = currentText.indexOf(template.shortcut);
        const endIndex = startIndex + template.shortcut.length;

        // Remove shortcut and insert content
        this.quillEditor.deleteText(startIndex, template.shortcut.length);

        // Sanitize HTML content to ensure safe insertion
        const sanitizedContent = DOMPurify.sanitize(template.Content);

        // Insert sanitized content
        this.quillEditor.clipboard.dangerouslyPasteHTML(
          startIndex,
          sanitizedContent
        );
        this.quillEditor.setSelection(startIndex + sanitizedContent.length, 0);
      }
    });
    this.hospitaltemplates.forEach((template) => {
      if (currentText.includes(template.shortcut)) {
        const startIndex = currentText.indexOf(template.shortcut);
        const endIndex = startIndex + template.shortcut.length;

        // Remove shortcut and insert content
        this.quillEditor.deleteText(startIndex, template.shortcut.length);

        // Sanitize HTML content to ensure safe insertion
        const sanitizedContent = DOMPurify.sanitize(template.Content);

        // Insert sanitized content
        this.quillEditor.clipboard.dangerouslyPasteHTML(
          startIndex,
          sanitizedContent
        );
        this.quillEditor.setSelection(startIndex + sanitizedContent.length, 0);
      }
    });
    this.templatesAllergies.forEach((DrReport) => {
      this.quillEditor.on('text-change', () => {
        const currentText = this.quillEditor.getText();

        // Check for the keyword "MostRecent"
        if (currentText.includes('Allergy')) {
          // Find the position of the keyword
          const startIndex = currentText.indexOf('Allergy');
          const endIndex = startIndex + 'Allergy'.length;

          // Remove the keyword from the editor
          this.quillEditor.deleteText(startIndex, 'Allergy'.length);

          // Fetch or construct the table content dynamically
          const mostRecentData = this.templatesAllergies.sort(
            (a, b) =>
              new Date(b.createtdAt).getTime() -
              new Date(a.createtdAt).getTime()
          )[0];

          if (mostRecentData) {
            // Create an HTML table with the Todays data
            const tableContent = `
                <p><strong> Allergies (Recorded: ${new Date(
                  mostRecentData.createtdAt
                ).toLocaleString()}):</strong></p>
        <p>Allergic to Substance: ${mostRecentData.AllergicTo}</p>
        <p>Reaction: ${mostRecentData.Reaction}</p>
       
      `;

            // Sanitize the table content
            const sanitizedContent = DOMPurify.sanitize(tableContent);

            // Insert the sanitized table into the editor
            this.quillEditor.clipboard.dangerouslyPasteHTML(
              startIndex,
              sanitizedContent
            );

            // Set the cursor position after the inserted table
            this.quillEditor.setSelection(
              startIndex + sanitizedContent.length,
              0
            );
          }
        }
      });
    });
    this.templatesPatientPharmacys.forEach((item) => {
      this.quillEditor.on('text-change', () => {
        const currentText = this.quillEditor.getText();

        if (currentText.includes('RecentPharmacy')) {
          const startIndex = currentText.indexOf('RecentPharmacy');
          const endIndex = startIndex + 'RecentPharmacy'.length;

          this.quillEditor.deleteText(startIndex, 'RecentPharmacy'.length);

          const mostRecentData = this.templatesPatientPharmacys.sort(
            (a, b) =>
              new Date(b.createdDate).getTime() -
              new Date(a.createdDate).getTime()
          )[0];

          if (mostRecentData) {
            const pharmcyData = `
                    <p><strong> Pharmacy Details (Recorded: ${new Date(
                      mostRecentData.createdDate
                    ).toLocaleString()}):</strong></p>
               <p>Pharmacy Name: ${mostRecentData.PharmacyName}</p>
        <p>Pharmacy Address: ${mostRecentData.PharmacyAddress}</p>
        <p>Pharmacy Address: ${mostRecentData.PhoneNumber}</p>
          `;

            const sanitizedContent = DOMPurify.sanitize(pharmcyData);

            this.quillEditor.clipboard.dangerouslyPasteHTML(
              startIndex,
              sanitizedContent
            );

            this.quillEditor.setSelection(
              startIndex + sanitizedContent.length,
              0
            );
          }
        }
      });
    });
    this.templatesLabOrders.forEach((DrReport) => {
      this.quillEditor.on('text-change', () => {
        const currentText = this.quillEditor.getText();

        if (currentText.includes('RecentLabOrder')) {
          const startIndex = currentText.indexOf('RecentLabOrder');
          const endIndex = startIndex + 'RecentLabOrder'.length;

          this.quillEditor.deleteText(startIndex, 'RecentLabOrder'.length);

          const mostRecentData = this.templatesLabOrders.sort(
            (a, b) => new Date(b.Date).getTime() - new Date(a.Date).getTime()
          )[0];

          if (mostRecentData) {
            const tableContent = `
                    <p><strong>Todays  Lab Order (Recorded: ${new Date(
                      mostRecentData.Date
                    ).toLocaleString()}):</strong></p>
            <p> Lab Order Details: ${mostRecentData.Description}</p>
            <p> Ordered By: ${mostRecentData.OrderedBy}</p>

          `;

            const sanitizedContent = DOMPurify.sanitize(tableContent);

            this.quillEditor.clipboard.dangerouslyPasteHTML(
              startIndex,
              sanitizedContent
            );

            this.quillEditor.setSelection(
              startIndex + sanitizedContent.length,
              0
            );
          }
        }
      });
    });
    this.templatesRadilogyOrders.forEach((DrReport) => {
      this.quillEditor.on('text-change', () => {
        const currentText = this.quillEditor.getText();

        if (currentText.includes('RecentRadiologyOrder')) {
          const startIndex = currentText.indexOf('RecentRadiologyOrder');
          const endIndex = startIndex + 'RecentRadiologyOrder'.length;

          this.quillEditor.deleteText(
            startIndex,
            'RecentRadiologyOrder'.length
          );

          const mostRecentData = this.templatesRadilogyOrders.sort(
            (a, b) => new Date(b.Date).getTime() - new Date(a.Date).getTime()
          )[0];

          if (mostRecentData) {
            const tableContent = `
                    <p><strong>Todays  Radiology Order (Recorded: ${new Date(
                      mostRecentData.Date
                    ).toLocaleString()}):</strong></p>
            <p> Radiology Order Details: ${mostRecentData.Description}</p>
            <p> Ordered By: ${mostRecentData.OrderedBy}</p>

          `;

            const sanitizedContent = DOMPurify.sanitize(tableContent);

            this.quillEditor.clipboard.dangerouslyPasteHTML(
              startIndex,
              sanitizedContent
            );

            this.quillEditor.setSelection(
              startIndex + sanitizedContent.length,
              0
            );
          }
        }
      });
    });
    this.templatesProcedureOrders.forEach((DrReport) => {
      this.quillEditor.on('text-change', () => {
        const currentText = this.quillEditor.getText();

        if (currentText.includes('RecentProcedureOrder')) {
          const startIndex = currentText.indexOf('RecentProcedureOrder');
          const endIndex = startIndex + 'RecentProcedureOrder'.length;

          this.quillEditor.deleteText(
            startIndex,
            'RecentProcedureOrder'.length
          );

          const mostRecentData = this.templatesLabOrders.sort(
            (a, b) => new Date(b.Date).getTime() - new Date(a.Date).getTime()
          )[0];

          if (mostRecentData) {
            const tableContent = `
                    <p><strong>Todays  Procedure Order (Recorded: ${new Date(
                      mostRecentData.Date
                    ).toLocaleString()}):</strong></p>
            <p> Procedure Order Details: ${mostRecentData.Description}</p>
            <p> Ordered By: ${mostRecentData.OrderedBy}</p>

          `;

            const sanitizedContent = DOMPurify.sanitize(tableContent);

            this.quillEditor.clipboard.dangerouslyPasteHTML(
              startIndex,
              sanitizedContent
            );

            this.quillEditor.setSelection(
              startIndex + sanitizedContent.length,
              0
            );
          }
        }
      });
    });
    this.templatesServiceRequests.forEach((DrReport) => {
      this.quillEditor.on('text-change', () => {
        const currentText = this.quillEditor.getText();

        if (currentText.includes('RecentOrders')) {
          const startIndex = currentText.indexOf('RecentOrders');
          const endIndex = startIndex + 'RecentOrders'.length;

          this.quillEditor.deleteText(startIndex, 'RecentOrders'.length);

          const mostRecentData = this.templatesServiceRequests.sort(
            (a, b) =>
              new Date(b.CreatedDate).getTime() -
              new Date(a.CreatedDate).getTime()
          )[0];

          if (mostRecentData) {
            const tableContent = `
            <p><strong>Todays Order (Recorded: ${new Date(
              mostRecentData.CreatedDate
            ).toLocaleString()}):</strong></p>
            <p>Category : ${mostRecentData.Category}</p>
            <ol>
              ${mostRecentData.Items.map(
                (x) =>
                  `<li>Test Name: ${x.Name}, Code: ${x.Code} ,Comments: ${x.Comments}</li>`
              ).join('')}
            </ol>

            <p><strong>Descriptive Reason for Order:</strong> ${
              mostRecentData.Description
            }</p>
        
            <p>Ordered By: ${mostRecentData.OrderedBy} ,Date: ${
              mostRecentData.AuthoredOn
            }</p>
          `;

            const sanitizedContent = DOMPurify.sanitize(tableContent);

            this.quillEditor.clipboard.dangerouslyPasteHTML(
              startIndex,
              sanitizedContent
            );

            this.quillEditor.setSelection(
              startIndex + sanitizedContent.length,
              0
            );
          }
        }
      });
    });
    this.templatesRefferals.forEach((DrReport) => {
      this.quillEditor.on('text-change', () => {
        const currentText = this.quillEditor.getText();

        if (currentText.includes('RecentRefferals')) {
          const startIndex = currentText.indexOf('RecentRefferals');
          const endIndex = startIndex + 'RecentRefferals'.length;

          this.quillEditor.deleteText(startIndex, 'RecentRefferals'.length);

          const mostRecentData = this.templatesRefferals.sort(
            (a, b) =>
              new Date(b.createdDate).getTime() -
              new Date(a.createdDate).getTime()
          )[0];

          if (mostRecentData) {
            const tableContent = `
                    <p><strong>  Refferal (Recorded: ${new Date(
                      mostRecentData.createdDate
                    ).toLocaleString()}):</strong></p>
            <p> Reffered To : ${mostRecentData.RefferedToDoctorName}</p>
            <p> Email: ${mostRecentData.Email}</p>
            <p> Phone: ${mostRecentData.Phone}</p>
            <p> Address: ${mostRecentData.Address}</p>
            <p> Subject: ${mostRecentData.Subject}</p>
            <p> Refferal Details: ${mostRecentData.Description}</p>
            <p> Ordered By: ${mostRecentData.RefferingDoctorName}</p>

          `;

            const sanitizedContent = DOMPurify.sanitize(tableContent);

            this.quillEditor.clipboard.dangerouslyPasteHTML(
              startIndex,
              sanitizedContent
            );

            this.quillEditor.setSelection(
              startIndex + sanitizedContent.length,
              0
            );
          }
        }
      });
    });
    this.templatesRefferals.forEach((DrReport) => {
      this.quillEditor.on('text-change', () => {
        const currentText = this.quillEditor.getText();

        if (currentText.includes('RefferalsToday')) {
          const startIndex = currentText.indexOf('RefferalsToday');
          const endIndex = startIndex + 'RefferalsToday'.length;

          this.quillEditor.deleteText(startIndex, 'RefferalsToday'.length);

          // Get the current date in local time (start and end of the day)
          const today = new Date();
          const startOfDay = new Date(today.setHours(0, 0, 0, 0));
          const endOfDay = new Date(today.setHours(23, 59, 59, 999));

          // Filter referrals for the same day
          const sameDayRefferals = this.templatesRefferals.filter(
            (referral) => {
              const createdDate = new Date(referral.createdDate);
              return createdDate >= startOfDay && createdDate <= endOfDay;
            }
          );

          if (sameDayRefferals.length > 0) {
            let tableContent = `<p><strong>Referrals Made Today:</strong></p>`;

            sameDayRefferals.forEach((referral) => {
              tableContent += `
                <p>Recorded: ${new Date(
                  referral.createdDate
                ).toLocaleString()}</p>
                <p>Referred To: ${referral.RefferedToDoctorName}</p>
                <p>Email: ${referral.Email}</p>
                <p>Phone: ${referral.Phone}</p>
                <p>Address: ${referral.Address}</p>
                <p>Subject: ${referral.Subject}</p>
                <p>Referral Details: ${referral.Description}</p>
                <p>Ordered By: ${referral.RefferingDoctorName}</p>
                <hr>
              `;
            });

            const sanitizedContent = DOMPurify.sanitize(tableContent);

            this.quillEditor.clipboard.dangerouslyPasteHTML(
              startIndex,
              sanitizedContent
            );

            this.quillEditor.setSelection(
              startIndex + sanitizedContent.length,
              0
            );
          } else {
            this.quillEditor.insertText(
              startIndex,
              'No referrals found for today.',
              'bold',
              true
            );
          }
        }
      });
    });

    this.templatesVitals.forEach((nureseReport) => {
      if (currentText.includes(nureseReport.shortcut)) {
        const startIndex = currentText.indexOf(nureseReport.shortcut);

        // Remove the shortcut
        this.quillEditor.deleteText(startIndex, nureseReport.shortcut.length);

        // Insert nureseReport data
        const nureseReportContent = `
         <p><strong>Todays Vitals(Recorded: ${new Date(
           nureseReport.createdDate
         ).toLocaleString()}):</strong></p>
          <p>Respiration: ${nureseReport.Respiration}</p>
          <p>Blood Pressure: ${nureseReport.BloodPressure}</p>
          <p>Temperature: ${nureseReport.Temparature}</p>
          <p>Heart Beat: ${nureseReport.HeartBeat}</p>
          <p>Oxygen Saturation: ${nureseReport.OxygenSaturation}</p>
          <p>BMI: ${nureseReport.BMI}</p>
        `;

        // Sanitize and insert content
        const sanitizedContent = DOMPurify.sanitize(nureseReportContent);
        this.quillEditor.clipboard.dangerouslyPasteHTML(
          startIndex,
          sanitizedContent
        );
        this.quillEditor.setSelection(startIndex + sanitizedContent.length, 0);
      }

      this.quillEditor.on('text-change', () => {
        const currentText = this.quillEditor.getText();

        // Check for the keyword "MostRecentVitals"
        if (currentText.includes('LatestVitals')) {
          const startIndex = currentText.indexOf('LatestVitals');
          const endIndex = startIndex + 'LatestVitals'.length;

          // Remove the keyword from the editor
          this.quillEditor.deleteText(startIndex, 'LatestVitals'.length);

          // Fetch or construct the Todays vitals data
          const mostRecentData = this.templatesVitals.sort(
            (a, b) =>
              new Date(b.createdDate).getTime() -
              new Date(a.createdDate).getTime()
          )[0];

          if (mostRecentData) {
            const tableContent = `
        <p><strong>Todays Vitals (Recorded: ${new Date(
          mostRecentData.createdDate
        ).toLocaleString()}):</strong></p>
        <p>Respiration: ${mostRecentData.Respiration}</p>
        <p>Blood Pressure: ${mostRecentData.BloodPressure}</p>
        <p>Temperature: ${mostRecentData.Temparature}</p>
        <p>Heart Beat: ${mostRecentData.HeartBeat}</p>
            <p>Oxygen Saturation: ${mostRecentData.OxygenSaturation}</p>
          <p>Weight: ${mostRecentData.Weight}</p>
          <p>Height: ${mostRecentData.Height}</p>
          <p>BMI: ${mostRecentData.BMI}</p>
      `;
            const sanitizedContent = DOMPurify.sanitize(tableContent);
            this.quillEditor.clipboard.dangerouslyPasteHTML(
              startIndex,
              sanitizedContent
            );
            this.quillEditor.setSelection(
              startIndex + sanitizedContent.length,
              0
            );
          }
        }
        // Check for the keyword "MostRecentVitals"
        if (currentText.includes('VitalsRecent-10')) {
          const startIndex = currentText.indexOf('VitalsRecent-10');
          const endIndex = startIndex + 'VitalsRecent-10'.length;

          // Remove the keyword from the editor
          this.quillEditor.deleteText(startIndex, 'VitalsRecent-10'.length);

          // Fetch or construct the 10 Todays vitals data
          const mostRecentData = this.templatesVitals
            .sort(
              (a, b) =>
                new Date(b.createdDate).getTime() -
                new Date(a.createdDate).getTime()
            )
            .slice(0, 10); // Get the 10 Todays records

          if (mostRecentData.length > 0) {
            // Construct the table content for the 10 Todays vitals
            let tableContent;
            mostRecentData.forEach((record) => {
              tableContent += `
         <p><strong>Todays Vitals(Recorded: ${new Date(
           nureseReport.createdDate
         ).toLocaleString()}):</strong></p>
          <p>Respiration: ${nureseReport.Respiration}</p>
          <p>Blood Pressure: ${nureseReport.BloodPressure}</p>
          <p>Temperature: ${nureseReport.Temparature}</p>
          <p>Heart Beat: ${nureseReport.HeartBeat}</p>
              <p>Oxygen Saturation: ${nureseReport.OxygenSaturation}</p>
          <p>Weight: ${nureseReport.Weight}</p>
          <p>Height: ${nureseReport.Height}</p>
          <p>BMI: ${nureseReport.BMI}</p>
        `;
            });

            tableContent += `
        </tbody>
      </table>
    `;

            // Sanitize the content
            const sanitizedContent = DOMPurify.sanitize(tableContent);

            // Insert the sanitized content into the Quill editor
            this.quillEditor.clipboard.dangerouslyPasteHTML(
              startIndex,
              sanitizedContent
            );

            // Adjust the cursor position
            this.quillEditor.setSelection(
              startIndex + sanitizedContent.length,
              0
            );
          }
        }

        // Check for the keyword "MostRecentBloodPressure"
        if (currentText.includes('BPRecent')) {
          const startIndex = currentText.indexOf('BPRecent');
          const endIndex = startIndex + 'BPRecent'.length;

          // Remove the keyword from the editor
          this.quillEditor.deleteText(startIndex, 'BPRecent'.length);

          // Fetch or construct the 5 Todays blood pressure readings
          const recentBloodPressure = this.templatesVitals
            .filter((vital) => vital.BloodPressure)
            .sort(
              (a, b) =>
                new Date(b.createdDate).getTime() -
                new Date(a.createdDate).getTime()
            )
            .slice(0, 5);

          if (recentBloodPressure.length > 0) {
            let bpContent =
              '<p><strong>Todays Blood Pressure Readings:</strong></p>';
            recentBloodPressure.forEach((entry) => {
              bpContent += `
        
             <p>Date: ${new Date(
               entry.createdDate
             ).toLocaleString()}: Blood Pressure ${entry.BloodPressure}</p>
        `;
            });
            // <p>Heart Rate: ${entry.HeartBeat}</p>
            // <p><strong>Recorded: ${new Date(
            //   entry.createdDate
            // ).toLocaleString()}</strong></p>
            const sanitizedContent = DOMPurify.sanitize(bpContent);
            this.quillEditor.clipboard.dangerouslyPasteHTML(
              startIndex,
              sanitizedContent
            );
            this.quillEditor.setSelection(
              startIndex + sanitizedContent.length,
              0
            );
          }
        }

        // Check for the keyword "BloodPressureLast10Days"
        if (currentText.includes('BPlast-10days')) {
          const startIndex = currentText.indexOf('BPlast-10days');
          const endIndex = startIndex + 'BPlast-10days'.length;

          // Remove the keyword from the editor
          this.quillEditor.deleteText(startIndex, 'BPlast-10days'.length);

          // Get blood pressure readings from the last 10 days
          const last10DaysBP = this.templatesVitals
            .filter(
              (vital) =>
                new Date(vital.createdDate) >=
                new Date(new Date().setDate(new Date().getDate() - 10))
            )
            .sort(
              (a, b) =>
                new Date(b.createdDate).getTime() -
                new Date(a.createdDate).getTime()
            );

          if (last10DaysBP.length > 0) {
            let bpContent =
              '<p><strong>Blood Pressure Trend (Last 10 Days):</strong></p>';
            last10DaysBP.forEach((entry) => {
              bpContent += `
         
         <p>Date: ${new Date(
           entry.createdDate
         ).toLocaleString()}: Blood Pressure ${entry.BloodPressure}</p>
        `;
            });
            // <p>Heart Rate: ${entry.HeartBeat}</p>
            // <p><strong>Recorded: ${new Date(
            //   entry.createdDate
            // ).toLocaleString()}</strong></p>
            const sanitizedContent = DOMPurify.sanitize(bpContent);
            this.quillEditor.clipboard.dangerouslyPasteHTML(
              startIndex,
              sanitizedContent
            );
            this.quillEditor.setSelection(
              startIndex + sanitizedContent.length,
              0
            );
          }
        }
      });
    });
    this.templatesPrescriptions.forEach((prescription) => {
      if (currentText.includes(prescription.shortcut)) {
        const startIndex = currentText.indexOf(prescription.shortcut);

        // Remove the shortcut
        this.quillEditor.deleteText(startIndex, prescription.shortcut.length);

        // Insert prescription data
        const prescriptionContent = `
          <p><strong>Todays Precriptions (Recorded: ${new Date(
            prescription.CreatedDate
          ).toLocaleString()}):</strong></p>
        <p>Drug Name: ${prescription.DrugName}</p>
        <p>Amount PerDose: ${prescription.AmountPerDose}${prescription.Unit}</p>
        <p>Route: ${prescription.Route}</p>
        <p>Frequency: ${prescription.Frequency}</p>
        <p>Refill Duration: ${prescription.RefillDuration}</p>
        <p>Number of Refills: ${prescription.Numberofrefills}</p>
        <p>Quantity: ${prescription.Quantity}${prescription.Unit}</p>
        <p>StartDate: ${prescription.StartDate}</p>
        <p>Expiry Date: ${prescription.EndDate}</p>
        <p>Direction of Use: ${prescription.Instruction}</p>
      `;

        // Sanitize and insert content
        const sanitizedContent = DOMPurify.sanitize(prescriptionContent);
        this.quillEditor.clipboard.dangerouslyPasteHTML(
          startIndex,
          sanitizedContent
        );
        this.quillEditor.setSelection(startIndex + sanitizedContent.length, 0);
      }

      this.quillEditor.on('text-change', () => {
        const currentText = this.quillEditor.getText();

        // Check for the keyword "MostRecentPrescriptions"
        if (currentText.includes('RxActive')) {
          const startIndex = currentText.indexOf('RxActive');
          const endIndex = startIndex + 'RxActive'.length;

          // Remove the keyword from the editor
          this.quillEditor.deleteText(startIndex, 'RxActive'.length);

          // Find the Todays prescriptions
          const mostRecentDate = new Date(
            Math.max(
              ...this.templatesPrescriptions.map((p) =>
                new Date(p.CreatedDate).getTime()
              )
            )
          );
          const mostRecentPrescriptions = this.templatesPrescriptions.filter(
            (p) =>
              new Date(p.CreatedDate).toDateString() ===
              mostRecentDate.toDateString()
          );

          if (mostRecentPrescriptions.length > 0) {
            const prescriptionsContent = mostRecentPrescriptions
              .map(
                (prescription) => `
                <p><strong>Prescription Recorded: ${new Date(
                  prescription.CreatedDate
                ).toLocaleString()}</strong></p>
                <p>Drug Name: ${prescription.DrugName}</p>
                <p>Amount Per Dose: ${prescription.AmountPerDose} ${
                  prescription.Unit
                }</p>
                <p>Route: ${prescription.Route}</p>
                <p>Frequency: ${prescription.Frequency}</p>
                <p>Refill Duration: ${prescription.RefillDuration}</p>
                <p>Number of Refills: ${prescription.Numberofrefills}</p>
                <p>Quantity: ${prescription.Quantity} ${prescription.Unit}</p>
                <p>Start Date: ${prescription.StartDate}</p>
                <p>Expiry Date: ${prescription.EndDate}</p>
                <p>Direction of Use: ${prescription.Instruction}</p>
                <p>____________________________________________________________</p>
              `
              )
              .join('');

            const sanitizedContent = DOMPurify.sanitize(prescriptionsContent);

            this.quillEditor.clipboard.dangerouslyPasteHTML(
              startIndex,
              sanitizedContent
            );
            this.quillEditor.setSelection(
              startIndex + sanitizedContent.length,
              0
            );
          }
        }

        // Check for the keyword "recent5prescriptions"
        if (currentText.includes('RxLast-5')) {
          const startIndex = currentText.indexOf('RxLast-5');
          const endIndex = startIndex + 'RxLast-5'.length;

          // Remove the keyword from the editor
          this.quillEditor.deleteText(startIndex, 'RxLast-5'.length);

          // Sort prescriptions by created date and take the Todays 5
          const recent5Prescriptions = this.templatesPrescriptions
            .sort(
              (a, b) =>
                new Date(b.CreatedDate).getTime() -
                new Date(a.CreatedDate).getTime()
            )
            .slice(0, 5);

          if (recent5Prescriptions.length > 0) {
            const prescriptionsContent = recent5Prescriptions
              .map(
                (prescription) => `
                <p><strong>Prescription Recorded: ${new Date(
                  prescription.CreatedDate
                ).toLocaleString()}</strong></p>
                <p>Drug Name: ${prescription.DrugName}</p>
                <p>Amount Per Dose: ${prescription.AmountPerDose} ${
                  prescription.Unit
                }</p>
                <p>Route: ${prescription.Route}</p>
                <p>Frequency: ${prescription.Frequency}</p>
                <p>Refill Duration: ${prescription.RefillDuration}</p>
                <p>Number of Refills: ${prescription.Numberofrefills}</p>
                <p>Quantity: ${prescription.Quantity} ${prescription.Unit}</p>
                <p>Start Date: ${prescription.StartDate}</p>
                <p>Expiry Date: ${prescription.EndDate}</p>
                <p>Direction of Use: ${prescription.Instruction}</p>
                <p>____________________________________________________________</p>
              `
              )
              .join('');

            const sanitizedContent = DOMPurify.sanitize(prescriptionsContent);

            this.quillEditor.clipboard.dangerouslyPasteHTML(
              startIndex,
              sanitizedContent
            );
            this.quillEditor.setSelection(
              startIndex + sanitizedContent.length,
              0
            );
          }
        }

        // Check for the keyword "last10DaysPrescriptions"
        if (currentText.includes('RxLast-10')) {
          const startIndex = currentText.indexOf('RxLast-10');
          const endIndex = startIndex + 'RxLast-10'.length;

          // Remove the keyword from the editor
          this.quillEditor.deleteText(startIndex, 'RxLast-10'.length);

          // Filter prescriptions from the last 10 days
          const last10DaysPrescriptions = this.templatesPrescriptions.filter(
            (prescription) =>
              new Date(prescription.CreatedDate) >=
              new Date(new Date().setDate(new Date().getDate() - 10))
          );

          if (last10DaysPrescriptions.length > 0) {
            const prescriptionsContent = last10DaysPrescriptions
              .map(
                (prescription) => `
                <p><strong>Prescription Recorded: ${new Date(
                  prescription.CreatedDate
                ).toLocaleString()}</strong></p>
                <p>Drug Name: ${prescription.DrugName}</p>
                <p>Amount Per Dose: ${prescription.AmountPerDose} ${
                  prescription.Unit
                }</p>
                <p>Route: ${prescription.Route}</p>
                <p>Frequency: ${prescription.Frequency}</p>
                <p>Refill Duration: ${prescription.RefillDuration}</p>
                <p>Number of Refills: ${prescription.Numberofrefills}</p>
                <p>Quantity: ${prescription.Quantity} ${prescription.Unit}</p>
                <p>Start Date: ${prescription.StartDate}</p>
                <p>Expiry Date: ${prescription.EndDate}</p>
                <p>Direction of Use: ${prescription.Instruction}</p>
                <p>____________________________________________________________</p>
              `
              )
              .join('');

            const sanitizedContent = DOMPurify.sanitize(prescriptionsContent);

            this.quillEditor.clipboard.dangerouslyPasteHTML(
              startIndex,
              sanitizedContent
            );
            this.quillEditor.setSelection(
              startIndex + sanitizedContent.length,
              0
            );
          }
        }
      });
    });
  }

  formSubmit() {
    let Shortcut = this.Frm.value['shortcut'];
    let reportName = this.Frm.value['ReportName'];
    let chiefComplaint = this.Frm.value['ChiefComplaint'];
    let visitType = this.Frm.value['VisitType'];
    let vistitDate = this.Frm.value['VistitDate'];
    let visitTime = this.Frm.value['VisitTime'];
    let nextFollowUp = this.Frm.value['NextFollowUp'];
    let followUpInstructions = this.Frm.value['FollowUpInstructions'];
    let reportDescription = this.Frm.value['ReportDescription'];

    let body = {
      PatientId: this.id,
      DoctorId: this.UserId,
      ReportName: reportName,
      ChiefComplaint: chiefComplaint,
      Summary: this.quillEditor.root.innerHTML,
      DoctorName: this.doctor.DoctorName,
      DoctorQualification: this.doctor.DoctorQualification,
      DoctorSpecialization: this.doctor.DoctorSpecialization,
      NpiNumber: this.doctor.NpiNumber,
      Signature: this.doctor.DoctorName,
      VistitDate: new Date().toISOString(),
      VisitTime: visitTime,
      NextFollowUp: nextFollowUp,
      FollowUpInstructions: followUpInstructions,
      Status: true,
      SoftwareFee: '1.5',
    };
    this.visitSummaryService.registerVisitSummary(body).subscribe((res) => {
      this.isSuccess = true;
      this.Frm.reset();
      this.quillEditor = '';
    });
  }

  private _filter5(value: string): string[] {
    const ds = value.toLowerCase();

    return this.reportNames.filter((aa) => aa.toLowerCase().includes(ds));
  }
  private _filter6(value: string): string[] {
    const ss = value.toLowerCase();

    return this.shortcutNames.filter((dd) => dd.toLowerCase().includes(ss));
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  delete(id) {
    this.visitSummaryService.deleteVisitSummary(id).subscribe((res) => {
      this.dataSource(this.id);
    });
  }
  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode == KEY_CODE.DOWN_ARROW) {
      // Your row selection code
      // console.log(event);
      this.stopRecognition();
    }
  }
  pasteIntoQuill(quillEditor: any): void {
    navigator.clipboard
      .readText()
      .then((clipboardData) => {
        quillEditor.clipboard.dangerouslyPasteHTML(clipboardData);
      })
      .catch((err) => {
        console.error('Failed to read clipboard data: ', err);
      });
  }
  newInvoice(): void {
    const patientId = Number(this.id); // Ensure `patientId` is a number
    const dialogRef = this.dialog.open(AddInvoiceOnProgressNoteComponent, {
      width: '50%',
      height: '80%',
      disableClose: false, // Prevent closing without confirmation
      hasBackdrop: false, // Disable the backdrop
      panelClass: 'custom-dialog-container',
      data: { patientId }, // Pass data as number
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('Dialog closed:', result);
    });
  }

  openFavoriteDiagnosisPopup() {
    const dialogRef = this.dialog.open(FavoriteDiagnosisPopupComponent, {
      width: '400px',
      data: { favoriteDiagnosisCodes: this.favoriteDiagnosisCodes }, // Pass your favorite codes here
    });

    dialogRef.afterClosed().subscribe((selectedCodes) => {
      if (selectedCodes && selectedCodes.length > 0) {
        this.insertCodesIntoEditor(selectedCodes); // Handle multiple selected codes
      }
    });
  }

  insertCodesIntoEditor(codes: any[]) {
    // Format selected codes: Each code on a new line with a description
    const formattedCodes = codes
      .map((code) => `${code.Code + ',' + code.Description} `)
      .join('\n');

    // Ensure the editor is focused
    this.quillEditor.focus();

    // Get the current cursor position or default to the end of the text
    const selection = this.quillEditor.getSelection();
    const cursorPosition =
      selection?.index !== null && selection?.index !== undefined
        ? selection.index
        : this.quillEditor.getLength(); // Fallback to the end of the editor content

    // Insert the formatted text at the cursor position
    this.quillEditor.insertText(cursorPosition, formattedCodes);

    // Move the cursor to the end of the inserted text
    this.quillEditor.setSelection(cursorPosition + formattedCodes.length);
  }

  generate() {
    // Get the current content from the Quill editor
    const query = this.quillEditor.getText().trim();
    if (query) {
      this.assistanceService.generateDocumentation(query).subscribe(
        (res) => {
          this.insertTextAtCursor(res.result);
        },
        (err) => {
          console.error('Error:', err);
        }
      );
    }
  }

  insertTextAtCursor(text: string) {
    const range = this.quillEditor.getSelection();
    if (range) {
      this.quillEditor.insertText(range.index, text);
      this.quillEditor.setSelection(range.index + text.length); // Move cursor after the inserted text
    } else {
      this.quillEditor.insertText(this.quillEditor.getLength(), text); // Append at the end if no selection
    }
  }
}

export enum KEY_CODE {
  UP_ARROW = 38,
  DOWN_ARROW = 40,
  RIGHT_ARROW = 39,
  LEFT_ARROW = 37,
}
