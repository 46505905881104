<div class="container mt-4">
    <h2 class="text-center">New Service Request</h2>
    <form [formGroup]="serviceRequestForm" (ngSubmit)="onSubmit()">
        <div class="row">
            <!-- Ordered By -->
            <div class="col-md-6">
                <mat-form-field appearance="fill" class="w-100">
                    <mat-label>Ordered By</mat-label>
                    <input matInput formControlName="orderedBy" placeholder="Practitioner Name" />
                    <mat-error *ngIf="serviceRequestForm.get('orderedBy')?.hasError('required')">
                        Ordered By is required
                    </mat-error>
                </mat-form-field>
            </div>

            <!-- Subject -->
            <div class="col-md-6">
                <mat-form-field appearance="fill" class="w-100">
                    <mat-label>Subject</mat-label>
                    <input matInput formControlName="subject" placeholder="Patient Name" />
                    <mat-error *ngIf="serviceRequestForm.get('subject')?.hasError('required')">
                        Subject is required
                    </mat-error>
                </mat-form-field>
            </div>

        </div>

        <div class="row mt-3">

            <div class="col-md-6">
                <mat-form-field appearance="fill" class="w-100">
                    <mat-label>Scheduled Date</mat-label>
                    <input matInput type="date" formControlName="scheduledDate" placeholder="Patient Name" />
                    <mat-error *ngIf="serviceRequestForm.get('subject')?.hasError('required')">
                        Subject is required
                    </mat-error>
                </mat-form-field>
            </div>
            <!-- Category -->
            <div class="col-md-6">
                <mat-form-field appearance="fill" class="w-100">
                    <mat-label>Category</mat-label>
                    <mat-select formControlName="category" (selectionChange)="onCategoryChange()">
                        <mat-option *ngFor="let category of categories" [value]="category">
                            {{ category }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="serviceRequestForm.get('category')?.hasError('required')">
                        Category is required
                    </mat-error>
                </mat-form-field>
            </div>

        </div>

        <div formArrayName="items" class="mt-4">
            <div *ngFor="let item of items.controls; let i = index" [formGroupName]="i" class="border rounded p-3 mb-3">
                <h5>Item {{ i + 1 }}</h5>
                <div class="row">
                    <!-- Name -->
                    <div class="col-md-4">
                        <mat-form-field appearance="fill" class="w-100">
                            <mat-label>Item Name</mat-label>
                            <mat-select formControlName="name" (selectionChange)="onItemSelected(i, $event.value)">
                                <mat-option *ngFor="let item of filteredItems" [value]="item.name">
                                    {{ item.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <!-- Code -->
                    <div class="col-md-3">
                        <mat-form-field appearance="fill" class="w-100">
                            <mat-label>Code</mat-label>
                            <input matInput formControlName="code" placeholder="Test Code" readonly />
                        </mat-form-field>
                    </div>

                    <!-- Comments -->
                    <div class="col-md-3">
                        <mat-form-field appearance="fill" class="w-100">
                            <mat-label>Comments</mat-label>
                            <input matInput formControlName="comments" placeholder="Optional Comments" />
                        </mat-form-field>
                    </div>
                    <button mat-mini-fab color="warn" type="button" (click)="removeItem(i)">
                        <mat-icon>delete</mat-icon>
                    </button>
                    <button mat-mini-fab color="primary" class="ml-2" type="button"
                        (click)="addItem()"><mat-icon>add</mat-icon>
                    </button>
                </div>




            </div>

        </div>



        <div class="row">
            <!-- Description -->
            <div class="col-md-12">
                <mat-form-field appearance="fill" class="w-100">
                    <mat-label>Descriptive Reason for Order</mat-label>
                    <textarea matInput formControlName="description" placeholder="Details"></textarea>
                    <mat-error *ngIf="serviceRequestForm.get('description')?.hasError('required')">
                        Description is required
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
            Successfully Created Order
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="text-end mt-4">
            <button mat-raised-button color="primary" type="submit">
                Submit
            </button>
        </div>
    </form>
</div>