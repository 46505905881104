<div class="container mt-4">
    <form [formGroup]="cms1500Form" (ngSubmit)="onSubmit()">
        <h2 class="mb-4"> Claim Form</h2>
        <h3>Payer Details</h3>

        <!-- Payer Details -->
        <div class="row">
            <div class="col-lg-2">
                <mat-form-field class="example-full-width">
                    <mat-label>Payer Name</mat-label>
                    <input matInput formControlName="payerName" />
                </mat-form-field>
            </div>
            <div class="col-lg-2">
                <mat-form-field class="example-full-width">
                    <mat-label>Payer ID</mat-label>
                    <input matInput formControlName="payerID" />
                </mat-form-field>
            </div>
            <div class="col-lg-2">
                <mat-form-field class="example-full-width">
                    <mat-label>Payer Address 1</mat-label>
                    <input matInput formControlName="payerAddress1" />
                </mat-form-field>
            </div>
            <div class="col-lg-2">
                <mat-form-field class="example-full-width">
                    <mat-label>Payer Address 2</mat-label>
                    <input matInput formControlName="payerAddress2" />
                </mat-form-field>
            </div>
            <div class="col-lg-2">
                <mat-form-field class="example-full-width">
                    <mat-label>Payer City</mat-label>
                    <input matInput formControlName="payerCity" />
                </mat-form-field>
            </div>
            <div class="col-lg-2">
                <mat-form-field class="example-full-width">
                    <mat-label>Payer State</mat-label>
                    <input matInput formControlName="payerState" />
                </mat-form-field>
            </div>
            <div class="col-lg-2">
                <mat-form-field class="example-full-width">
                    <mat-label>Payer ZIP</mat-label>
                    <input matInput formControlName="payerZip" />
                </mat-form-field>
            </div>
            <div class="col-lg-2">
                <mat-form-field class="example-full-width">
                    <mat-label>Payer Phone</mat-label>
                    <input matInput formControlName="payerPhone" />
                </mat-form-field>
            </div>
        </div>
        <h3>Patient Details</h3>

        <!-- Patient Details -->
        <div class="row mt-4">
            <div class="col-lg-2">
                <mat-form-field class="example-full-width">
                    <mat-label>Insured ID</mat-label>
                    <input matInput formControlName="insuredID" />
                </mat-form-field>
            </div>
            <div class="col-lg-2">
                <mat-form-field class="example-full-width">
                    <mat-label>Patient Name</mat-label>
                    <input matInput formControlName="patientName" />
                </mat-form-field>
            </div>
            <div class="col-lg-2">
                <mat-form-field class="example-full-width">
                    <mat-label>Patient DOB</mat-label>
                    <input matInput formControlName="patientDOB" type="date" />
                </mat-form-field>
            </div>
            <div class="col-lg-2">
                <mat-form-field class="example-full-width">
                    <mat-label>Patient Sex</mat-label>
                    <mat-select formControlName="patientSex">
                        <mat-option value="male">Male</mat-option>
                        <mat-option value="female">Female</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-lg-2">
                <mat-form-field class="example-full-width">
                    <mat-label>Patient Address</mat-label>
                    <input matInput formControlName="patientAddress" />
                </mat-form-field>
            </div>
            <div class="col-lg-2">
                <mat-form-field class="example-full-width">
                    <mat-label>Patient City</mat-label>
                    <input matInput formControlName="patientCity" />
                </mat-form-field>
            </div>
            <div class="col-lg-2">
                <mat-form-field class="example-full-width">
                    <mat-label>Patient State</mat-label>
                    <input matInput formControlName="patientState" />
                </mat-form-field>
            </div>
            <div class="col-lg-2">
                <mat-form-field class="example-full-width">
                    <mat-label>Patient ZIP</mat-label>
                    <input matInput formControlName="patientZip" />
                </mat-form-field>
            </div>
            <div class="col-lg-2">
                <mat-form-field class="example-full-width">
                    <mat-label>Marital Status</mat-label>
                    <input matInput formControlName="maritalStatus" />
                </mat-form-field>
            </div>
            <div class="col-lg-2">
                <mat-form-field class="example-full-width">
                    <mat-label>Employment Status</mat-label>
                    <input matInput formControlName="employmentStatus" />
                </mat-form-field>
            </div>
            <div class="col-lg-2">
                <mat-form-field class="compact-field">
                    <mat-label>Patient Relationship</mat-label>
                    <mat-select formControlName="patientRelationship" required>
                        <mat-option value="18">Self</mat-option>
                        <mat-option value="19">Spouse</mat-option>
                        <mat-option value="20">Child</mat-option>
                    </mat-select>
                </mat-form-field>

            </div>
        </div>
        <h3>Diagnosis Codes</h3>

        <!-- Diagnosis Codes -->
        <div class="row mt-4" formArrayName="diagnosisCodes" *ngFor="let code of diagnosisCodes.controls; let i = index"
            [formGroupName]="i">

            <div class="col-lg-3">
                <mat-form-field class="example-full-width">
                    <mat-label>Diagnosis Code</mat-label>
                    <input matInput formControlName="code" />
                </mat-form-field>
            </div>
            <div class="col-lg-5">
                <mat-form-field class="example-full-width">
                    <mat-label>Description</mat-label>
                    <input matInput formControlName="description" />
                </mat-form-field>
            </div>
            <div class="col-lg-2">
                <button mat-mini-fab color="primary" (click)="addDiagnosisCode()"><mat-icon>add</mat-icon></button>
                <button mat-mini-fab color="warn" class="ml-2"
                    (click)="removeDiagnosisCode(i)"><mat-icon>delete</mat-icon></button>


            </div>

        </div>

        <h3>Service Lines (Section 24)</h3>

        <!-- Service Lines -->
        <div class="row mt-4" formArrayName="serviceLines" *ngFor="let service of serviceLines.controls; let i = index"
            [formGroupName]="i">

            <div class="col-lg-2">
                <mat-form-field class="example-full-width">
                    <mat-label>Date of Service From</mat-label>
                    <input matInput formControlName="dateOfServiceFrom" type="date" />
                </mat-form-field>
            </div>
            <div class="col-lg-2">
                <mat-form-field class="example-full-width">
                    <mat-label>Date of Service To</mat-label>
                    <input matInput formControlName="dateOfServiceTo" type="date" />
                </mat-form-field>
            </div>
            <div class="col-lg-2">
                <mat-form-field class="example-full-width">
                    <mat-label>Place of Service</mat-label>
                    <input matInput formControlName="placeOfService" />
                </mat-form-field>
            </div>
            <div class="col-lg-2">
                <mat-form-field class="example-full-width">
                    <mat-label>Procedure Code</mat-label>
                    <input matInput formControlName="procedureCode" />
                </mat-form-field>
            </div>
            <div class="col-lg-1">
                <mat-form-field class="example-full-width">
                    <mat-label>Charges</mat-label>
                    <input matInput formControlName="charges" type="number" />
                </mat-form-field>
            </div>
            <div class="col-lg-1">
                <mat-form-field class="example-full-width">
                    <mat-label>Units</mat-label>
                    <input matInput formControlName="units" type="number" />
                </mat-form-field>
            </div>
            <div class="col-lg-2">
                <button mat-mini-fab color="primary" (click)="addServiceLine()"><mat-icon>add</mat-icon></button>
                <button mat-mini-fab color="warn" class="ml-2"
                    (click)="removeServiceLine(i)"><mat-icon>delete</mat-icon></button>

            </div>
        </div>

        <h3>Financial Information</h3>

        <!-- Financial Information -->
        <div class="row mt-4">
            <div class="col-lg-2">
                <mat-form-field class="example-full-width">
                    <mat-label>Tax ID</mat-label>
                    <input matInput formControlName="taxID" />
                </mat-form-field>
            </div>
            <div class="col-lg-2">
                <mat-form-field class="example-full-width">
                    <mat-label>Total Charge</mat-label>
                    <input matInput formControlName="totalCharge" type="number" />
                </mat-form-field>
            </div>
            <div class="col-lg-2">
                <mat-form-field class="example-full-width">
                    <mat-label>Amount Paid</mat-label>
                    <input matInput formControlName="amountPaid" type="number" />
                </mat-form-field>
            </div>
            <div class="col-lg-2">
                <mat-form-field class="example-full-width">
                    <mat-label>Balance</mat-label>
                    <input matInput formControlName="balance" type="number" />
                </mat-form-field>
            </div>
        </div>
        <h3>Rendering Provider</h3>

        <!-- Rendering Provider -->
        <div class="row mt-4">
            <div class="col-lg-2">
                <mat-form-field class="example-full-width">
                    <mat-label>Provider Name</mat-label>
                    <input matInput formControlName="renderingProviderName" />
                </mat-form-field>
            </div>
            <div class="col-lg-2">
                <mat-form-field class="example-full-width">
                    <mat-label>Provider NPI</mat-label>
                    <input matInput formControlName="renderingProviderNPI" />
                </mat-form-field>
            </div>
        </div>
        <h3>Facility Information</h3>

        <!-- Facility Information -->
        <div class="row mt-4">
            <div class="col-lg-2">
                <mat-form-field class="example-full-width">
                    <mat-label>Facility Name</mat-label>
                    <input matInput formControlName="facilityName" />
                </mat-form-field>
            </div>
            <div class="col-lg-2">
                <mat-form-field class="example-full-width">
                    <mat-label>Facility Address</mat-label>
                    <input matInput formControlName="facilityAddress1" />
                </mat-form-field>
            </div>
            <div class="col-lg-2">
                <mat-form-field class="example-full-width">
                    <mat-label>Facility City</mat-label>
                    <input matInput formControlName="facilityCity" />
                </mat-form-field>
            </div>
            <div class="col-lg-2">
                <mat-form-field class="example-full-width">
                    <mat-label>Facility State</mat-label>
                    <input matInput formControlName="facilityState" />
                </mat-form-field>
            </div>
            <div class="col-lg-2">
                <mat-form-field class="example-full-width">
                    <mat-label>Facility ZIP</mat-label>
                    <input matInput formControlName="facilityZip" />
                </mat-form-field>
            </div>
        </div>
        <h3>Billing Provider (Section 33)</h3>

        <!-- Billing Provider -->
        <div class="row mt-4">
            <div class="col-lg-2">
                <mat-form-field class="example-full-width">
                    <mat-label>Billing Provider Name</mat-label>
                    <input matInput formControlName="billingProviderName" />
                </mat-form-field>
            </div>
            <div class="col-lg-2">
                <mat-form-field class="example-full-width">
                    <mat-label>Billing Provider Address</mat-label>
                    <input matInput formControlName="billingProviderAddress1" />
                </mat-form-field>
            </div>
            <div class="col-lg-2">
                <mat-form-field class="example-full-width">
                    <mat-label>Billing Provider City</mat-label>
                    <input matInput formControlName="billingProviderCity" />
                </mat-form-field>
            </div>
            <div class="col-lg-2">
                <mat-form-field class="example-full-width">
                    <mat-label>Billing Provider State</mat-label>
                    <input matInput formControlName="billingProviderState" />
                </mat-form-field>
            </div>
            <div class="col-lg-2">
                <mat-form-field class="example-full-width">
                    <mat-label>Billing Provider ZIP</mat-label>
                    <input matInput formControlName="billingProviderZip" />
                </mat-form-field>
            </div>
            <div class="col-lg-2">
                <mat-form-field class="example-full-width">
                    <mat-label>Billing Provider Phone</mat-label>
                    <input matInput formControlName="billingProviderPhone" />
                </mat-form-field>
            </div>
            <div class="col-lg-2">
                <mat-form-field class="example-full-width">
                    <mat-label>Billing Provider NPI</mat-label>
                    <input matInput formControlName="billingProviderNPI" />
                </mat-form-field>
            </div>
        </div>
        <!-- Submit Button -->
        <div class="row mt-4">
            <div>
                <button mat-raised-button color="primary" type="submit">Submit Claim</button>
            </div>
        </div>
    </form>
</div>