import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { LabreportService } from '../../services/labreport.service';
import { Doctor } from '../../Models/doctor.model';
import { DoctorService } from '../../services/doctor.service';
import * as moment from 'moment';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { PatientService } from 'src/app/services/patient.service';
import { Patient } from 'src/app/Models/patient.model';
import { HospitalService } from 'src/app/services/hospital.service';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-add-labreport',
  templateUrl: './add-labreport.component.html',
  styleUrls: ['./add-labreport.component.css'],
})
export class AddLabreportComponent implements OnInit {
  id = 0;
  imageUrl: string;
  doctorList: Doctor[];
  isSuccess = false;

  labFrm: FormGroup;
  patient: Patient;
  hospitalId: number;

  referenceRanges = {
    'Blood Sugar': {
      ranges: [
        'Low (<70 mg/dL)',
        'Normal (70-99 mg/dL)',
        'High (100-125 mg/dL)',
        'Critical (>125 mg/dL)',
      ],
      units: 'mg/dL',
    },
    Hemoglobin: {
      ranges: [
        'Low (<12 g/dL)',
        'Normal (12-16 g/dL)',
        'High (>16 g/dL)',
        'Critical (>18 g/dL)',
      ],
      units: 'g/dL',
    },
    Cholesterol: {
      ranges: [
        'Low (<150 mg/dL)',
        'Normal (150-200 mg/dL)',
        'High (201-240 mg/dL)',
        'Critical (>240 mg/dL)',
      ],
      units: 'mg/dL',
    },
    Triglycerides: {
      ranges: [
        'Low (<50 mg/dL)',
        'Normal (50-150 mg/dL)',
        'High (151-200 mg/dL)',
        'Critical (>200 mg/dL)',
      ],
      units: 'mg/dL',
    },
    WBC: {
      ranges: [
        'Low (<4,000 cells/µL)',
        'Normal (4,000–11,000 cells/µL)',
        'High (>11,000 cells/µL)',
        'Critical (>30,000 cells/µL)',
      ],
      units: 'cells/µL',
    },
    'Platelet Count': {
      ranges: [
        'Low (<150,000/µL)',
        'Normal (150,000–450,000/µL)',
        'High (>450,000/µL)',
        'Critical (>1,000,000/µL)',
      ],
      units: '/µL',
    },
    Potassium: {
      ranges: [
        'Low (<3.5 mmol/L)',
        'Normal (3.5–5.0 mmol/L)',
        'High (>5.0 mmol/L)',
        'Critical (>6.5 mmol/L)',
      ],
      units: 'mmol/L',
    },
    Sodium: {
      ranges: [
        'Low (<135 mmol/L)',
        'Normal (135–145 mmol/L)',
        'High (>145 mmol/L)',
        'Critical (>160 mmol/L)',
      ],
      units: 'mmol/L',
    },
    Calcium: {
      ranges: [
        'Low (<8.5 mg/dL)',
        'Normal (8.5–10.5 mg/dL)',
        'High (>10.5 mg/dL)',
        'Critical (>12 mg/dL)',
      ],
      units: 'mg/dL',
    },
    RBC: {
      ranges: [
        'Low (<4.1 million/µL)',
        'Normal (4.1–5.9 million/µL)',
        'High (>5.9 million/µL)',
        'Critical (>7.0 million/µL)',
      ],
      units: 'million/µL',
    },
    MCV: {
      ranges: ['Low (<80 fL)', 'Normal (80–100 fL)', 'High (>100 fL)'],
      units: 'fL',
    },
    MCH: {
      ranges: ['Low (<27 pg)', 'Normal (27–33 pg)', 'High (>33 pg)'],
      units: 'pg',
    },
    MCHC: {
      ranges: ['Low (<32 g/dL)', 'Normal (32–36 g/dL)', 'High (>36 g/dL)'],
      units: 'g/dL',
    },
    'Vitamin D': {
      ranges: [
        'Deficient (<20 ng/mL)',
        'Insufficient (20-30 ng/mL)',
        'Normal (30-100 ng/mL)',
        'Toxicity (>100 ng/mL)',
      ],
      units: 'ng/mL',
    },
    Creatinine: {
      ranges: [
        'Low (<0.6 mg/dL)',
        'Normal (0.6–1.2 mg/dL)',
        'High (>1.2 mg/dL)',
        'Critical (>5 mg/dL)',
      ],
      units: 'mg/dL',
    },
    BUN: {
      ranges: [
        'Low (<7 mg/dL)',
        'Normal (7–20 mg/dL)',
        'High (21–50 mg/dL)',
        'Critical (>50 mg/dL)',
      ],
      units: 'mg/dL',
    },
    ESR: {
      ranges: [
        'Low (<0 mm/hr)',
        'Normal (0–20 mm/hr)',
        'High (>20 mm/hr)',
        'Critical (>100 mm/hr)',
      ],
      units: 'mm/hr',
    },
    CRP: {
      ranges: [
        'Low (<1 mg/L)',
        'Moderate (1–3 mg/L)',
        'High (>3 mg/L)',
        'Critical (>10 mg/L)',
      ],
      units: 'mg/L',
    },
    'Thyroid TSH': {
      ranges: [
        'Low (<0.4 mIU/L)',
        'Normal (0.4–4.0 mIU/L)',
        'High (>4.0 mIU/L)',
        'Critical (>10 mIU/L)',
      ],
      units: 'mIU/L',
    },
    Ferritin: {
      ranges: ['Low (<30 ng/mL)', 'Normal (30–300 ng/mL)', 'High (>300 ng/mL)'],
      units: 'ng/mL',
    },
  };

  dynamicReferenceRanges: string[][] = [];
  dynamicUnits: string[] = []; // Store units for each test row

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private labreportService: LabreportService,
    private router: Router,
    private doctorService: DoctorService,
    private patientService: PatientService,
    private hospitalService: HospitalService,
    private tokenService: TokenService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    this.getPatient(this.id);

    this.patientService.getpatienHospitalId(this.id).subscribe((res) => {
      this.hospitalId = res.Hospital.HospitalId;
      this.getDoctors(this.hospitalId);
    });

    this.labFrm = this.fb.group({
      ReportType: [''],
      PatientId: this.id,
      DateReceived: [''],
      DateOfReport: [''],
      DateDrawn: [''],
      Document: [''],
      Description: ['', Validators.required],
      Date: ['', Validators.required],
      OrderedBy: ['', Validators.required],
      LabName: ['', Validators.required],
      ReportedBy: ['', Validators.required],
      LabTests: this.fb.array([]),
    });
    this.addItem();
  }

  public addItem(): void {
    const item = this.fb.group({
      TestName: [''],
      TestResultValue: [''],
      ReferanceRange: [''],
      Units: [''],
    });
    this.LabTests.push(item);
  }

  public removeItem(i: number): void {
    this.LabTests.removeAt(i);
  }
  updateReferenceRanges(index: number, testName: string): void {
    const testDetails = this.referenceRanges[testName] || {
      ranges: [],
      units: '',
    };
    this.dynamicReferenceRanges[index] = testDetails.ranges; // Update reference ranges
    const labTestGroup = this.LabTests.at(index) as FormGroup;
    labTestGroup.get('Units').setValue(testDetails.units); // Update the Units form control
  }

  get LabTests(): UntypedFormArray {
    return this.labFrm.get('LabTests') as UntypedFormArray;
  }

  get DateReceived() {
    return this.labFrm.get('DateReceived');
  }
  get DateOfReport() {
    return this.labFrm.get('DateOfReport');
  }

  get DateDrawn() {
    return this.labFrm.get('DateDrawn');
  }

  get TestName() {
    return this.labFrm.get('TestName');
  }
  get TestResultValue() {
    return this.labFrm.get('TestResultValue');
  }
  get ReferanceRange() {
    return this.labFrm.get('ReferanceRange');
  }
  get Units() {
    return this.labFrm.get('Units');
  }
  get ReportType() {
    return this.labFrm.get('ReportType');
  }

  get Document() {
    return this.labFrm.get('Document');
  }
  get Description() {
    return this.labFrm.get('Description');
  }
  get Date() {
    return this.labFrm.get('Date');
  }
  get OrderedBy() {
    return this.labFrm.get('OrderedBy');
  }
  get LabName() {
    return this.labFrm.get('LabName');
  }
  get ReportedBy() {
    return this.labFrm.get('ReportedBy');
  }

  image(e) {
    let formData = new FormData();
    let files = e.target.files;
    formData.append('file', files[0], files[0].name);

    this.labreportService.imageUpload(formData).subscribe(
      (res) => {
        let stImg = environment.imgurl + res['path'];
        // this.imageUrl = stImg
        this.imageUrl = `<p><img src="${stImg}"/></p>`;
      },
      (err) => console.log(err)
    );
  }

  getDoctors(id) {
    this.hospitalService.getDoctors(id).subscribe((res) => {
      this.doctorList = res.Doctors;
    });
  }
  getPatient(id) {
    this.patientService.getPatientById(id).subscribe((res) => {
      this.patient = res;
    });
  }
  formSubmit() {
    this.labreportService
      .registerLabReport(this.labFrm.value)
      .subscribe((res) => {
        this.isSuccess = true;
        this.labFrm.reset();
      });
  }

  tabChanged(event: MatTabChangeEvent): void {
    if (event.index === 0) {
    }
  }
}
