<div class="container ">
    <div class="row">
        <div class="col-lg regFrm mat-elevation-z8">
            <h1>New Visit</h1>
            <form (keydown.enter)="$event.preventDefault()" [formGroup]="Frm" (ngSubmit)="formSubmit()"
                class="example-form">

                <div class="row">
                    <div class="col-lg-3">

                        <mat-form-field class="example-full-width">
                            <mat-label>VisitType</mat-label>
                            <!-- <input type="text" formControlName="VisitType" matInput >
                    <mat-error >Please enter Visit Type</mat-error> -->

                            <mat-select formControlName="VisitType">

                                <mat-option *ngFor="let op of options" [value]="op">{{op}}</mat-option>

                            </mat-select>
                            <mat-error>Please select Visit Type </mat-error>
                        </mat-form-field>

                    </div>
                    <div class="col-lg-3">
                        <mat-form-field class="example-full-width">
                            <mat-label>VistitDate</mat-label>
                            <input type="date" formControlName="VistitDate" matInput>
                            <mat-error>Please enter Date</mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-lg-3">
                        <mat-form-field class="example-full-width">
                            <mat-label>Vist Time</mat-label>
                            <!-- <input type="text" formControlName="VistTime" matInput > -->
                            <input [ngxTimepicker]="picker" matInput formControlName="VistTime">
                            <ngx-material-timepicker #picker></ngx-material-timepicker>
                            <mat-error>Please enter Visit Time</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">

                        <mat-form-field class="example-full-width">
                            <mat-label>Chief Complaints:</mat-label>

                            <textarea matInput (input)="onChiefComplaintsChange($event)"
                                formControlName="ChiefComplaint" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                                cdkAutosizeMinRows="1" cdkAutosizeMaxRows="140"
                                [value]="voiceRecognitionChiefComplaintService.chiefComplaints" matInput
                                (blur)="onChiefComplaintsBlur()"></textarea>
                            <button type="button" class="app-speech-input-btn" [disabled]="listening"
                                (click)="startChiefComplaintService()"></button>

                        </mat-form-field>

                    </div>
                    <!-- <button type="button" color="accent" (click)="stopChiefComplaintService()"
              mat-mini-fab><mat-icon>stop</mat-icon></button> -->
                </div>
                <div class="row">
                    <div class="col-md-12">

                        <mat-form-field class="example-full-width">
                            <mat-label>Present Illness:</mat-label>


                            <textarea matInput (input)="onPresentIllnesssChange($event)"
                                formControlName="PresentIllness" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                                cdkAutosizeMinRows="1" cdkAutosizeMaxRows="140"
                                [value]="voiceRecognitionPresentIllness.presentIllness" matInput
                                (blur)="onPresentIllnesssBlur()"></textarea>
                            <button type="button" class="app-speech-input-btn" [disabled]="listening"
                                (click)="startPresentIllnessService()"></button>

                        </mat-form-field>

                    </div>
                    <!-- <button type="button" color="accent" (click)="stopChiefComplaintService()"
              mat-mini-fab><mat-icon>stop</mat-icon></button> -->
                </div>

                <h4> Past History</h4>
                <div class="row">
                    <div class="col-md-6">

                        <mat-form-field class="example-full-width">
                            <mat-label>Medical:</mat-label>


                            <textarea matInput (input)="onMedicalsChange($event)" formControlName="Medical"
                                cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="140" [value]="VoiceRecognitionMedical.medicalComment" matInput
                                (blur)="onMedicalBlur()"></textarea>
                            <button type="button" class="app-speech-input-btn" [disabled]="listening"
                                (click)="startMedicalService()"></button>

                        </mat-form-field>

                    </div>
                    <!-- <button type="button" color="accent" (click)="stopChiefComplaintService()"
              mat-mini-fab><mat-icon>stop</mat-icon></button> -->


                    <div class="col-md-6">

                        <mat-form-field class="example-full-width">
                            <mat-label>Surgical:</mat-label>


                            <textarea matInput (input)="onSurgicalsChange($event)" formControlName="Surgical"
                                cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="140" [value]="VoiceRecognitionSurgical.surgical" matInput
                                (blur)="onSurgicalBlur()"></textarea>
                            <button type="button" class="app-speech-input-btn" [disabled]="listening"
                                (click)="startSurgicalService()"></button>

                        </mat-form-field>

                    </div>
                    <!-- <button type="button" color="accent" (click)="stopChiefComplaintService()"
              mat-mini-fab><mat-icon>stop</mat-icon></button> -->

                </div>
                <div class="row">
                    <div class="col-md-6">

                        <mat-form-field class="example-full-width">
                            <mat-label>Allergies:</mat-label>


                            <textarea matInput (input)="onAllergiessChange($event)" formControlName="Allergies"
                                cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="140" [value]="VoiceRecognitionAllergies.allergies" matInput
                                (blur)="onAllergiesBlur()"></textarea>
                            <button type="button" class="app-speech-input-btn" [disabled]="listening"
                                (click)="startAllergiesService()"></button>

                        </mat-form-field>

                    </div>
                    <!-- <button type="button" color="accent" (click)="stopChiefComplaintService()"
              mat-mini-fab><mat-icon>stop</mat-icon></button> -->


                    <div class="col-md-6">

                        <mat-form-field class="example-full-width">
                            <mat-label>Medications:</mat-label>


                            <textarea matInput (input)="onMedicationssChange($event)" formControlName="Medications"
                                cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="140" [value]="VoiceRecognitionMedications.medication" matInput
                                (blur)="onMedicationsBlur()"></textarea>
                            <button type="button" class="app-speech-input-btn" [disabled]="listening"
                                (click)="startMedicationsService()"></button>

                        </mat-form-field>

                    </div>
                    <!-- <button type="button" color="accent" (click)="stopChiefComplaintService()"
              mat-mini-fab><mat-icon>stop</mat-icon></button> -->

                </div>
                <div class="row">
                    <div class="col-md-6">

                        <mat-form-field class="example-full-width">
                            <mat-label>SocialHistory:</mat-label>


                            <textarea matInput (input)="onSocialHistorysChange($event)" formControlName="SocialHistory"
                                cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="140" [value]="VoiceRecognitionSocialHistory.socialhsitory" matInput
                                (blur)="onSocialHistoryBlur()"></textarea>
                            <button type="button" class="app-speech-input-btn" [disabled]="listening"
                                (click)="startSocialHistoryService()"></button>

                        </mat-form-field>

                    </div>
                    <!-- <button type="button" color="accent" (click)="stopChiefComplaintService()"
              mat-mini-fab><mat-icon>stop</mat-icon></button> -->


                    <div class="col-md-6">

                        <mat-form-field class="example-full-width">
                            <mat-label>FamilyHistory:</mat-label>


                            <textarea matInput (input)="onFamilyHistorysChange($event)" formControlName="FamilyHistory"
                                cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="140" [value]="VoiceRecognitionFamilyHistory.familyHistory" matInput
                                (blur)="onFamilyHistoryBlur()"></textarea>
                            <button type="button" class="app-speech-input-btn" [disabled]="listening"
                                (click)="startFamilyHistoryService()"></button>

                        </mat-form-field>

                    </div>
                    <!-- <button type="button" color="accent" (click)="stopChiefComplaintService()"
              mat-mini-fab><mat-icon>stop</mat-icon></button> -->

                </div>
                <div class="row">
                    <div class="col-md-12">

                        <mat-form-field class="example-full-width">
                            <mat-label>ReviewOfSymptoms:</mat-label>


                            <textarea matInput (input)="onReviewOfSymptomssChange($event)"
                                formControlName="ReviewOfSymptoms" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                                cdkAutosizeMinRows="1" cdkAutosizeMaxRows="140"
                                [value]="VoiceRecognitionReviewOfSymptoms.reviewofsymptoms" matInput
                                (blur)="onReviewOfSymptomsBlur()"></textarea>
                            <button type="button" class="app-speech-input-btn" [disabled]="listening"
                                (click)="startReviewOfSymptomsService()"></button>

                        </mat-form-field>

                    </div>
                    <!-- <button type="button" color="accent" (click)="stopChiefComplaintService()"
              mat-mini-fab><mat-icon>stop</mat-icon></button> -->

                </div>



                <button type="button" class="float-right" color="accent" (click)="stopRecomendationService()"
                    mat-raised-button>Stop
                    All<mat-icon>stop</mat-icon></button>
                <h4 class="my-3">Physical Examinations</h4>
                <div class="row">
                    <div class="col-lg-12">

                        <mat-form-field class="w-100">
                            <mat-chip-list>
                                <ng-container *ngIf="selectedIcdCodes.length">
                                    <mat-chip *ngFor="let item of selectedIcdCodes" (removed)="removeOption(item)">
                                        {{ item }}
                                        <mat-icon matChipRemove>cancel</mat-icon>
                                    </mat-chip></ng-container>
                                <mat-label>Diagnosis:</mat-label>

                                <input type="text" placeholder="Type code to search" aria-label="Diagnosis Codes"
                                    matInput [formControl]="IcdCode" [matAutocomplete]="auto" />
                                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="addOption($event)">

                                    <cdk-virtual-scroll-viewport [ngStyle]="{height: '200px'}" itemSize="25">
                                        <mat-option *cdkVirtualFor="let entry of filteredValues" [value]="entry.Code">
                                            {{entry.Code}}
                                        </mat-option>
                                    </cdk-virtual-scroll-viewport>
                                </mat-autocomplete>
                            </mat-chip-list>
                        </mat-form-field>
                    </div>
                </div>


                <h4>Vital Signs</h4>
                <div class="row">
                    <div class="col-md-4">


                        <mat-form-field class="example-full-width">
                            <mat-label>Height:</mat-label>


                            <textarea matInput (input)="onHeightsChange($event)" formControlName="Height"
                                cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="140" [value]="VoiceRecognitionHeight.height" matInput
                                (blur)="onHeightBlur()"></textarea>
                            <button type="button" class="app-speech-input-btn" [disabled]="listening"
                                (click)="startHeightService()"></button>

                        </mat-form-field>

                    </div>
                    <!-- <button type="button" color="accent" (click)="stopChiefComplaintService()"
              mat-mini-fab><mat-icon>stop</mat-icon></button> -->

                    <div class="col-md-4">


                        <mat-form-field class="example-full-width">
                            <mat-label>Weight:</mat-label>


                            <textarea matInput (input)="onWeightsChange($event)" formControlName="Weight"
                                cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="140" [value]="VoiceRecognitionWeight.weight" matInput
                                (blur)="onWeightBlur()"></textarea>
                            <button type="button" class="app-speech-input-btn" [disabled]="listening"
                                (click)="startWeightService()"></button>

                        </mat-form-field>

                    </div>
                    <!-- <button type="button" color="accent" (click)="stopChiefComplaintService()"
              mat-mini-fab><mat-icon>stop</mat-icon></button> -->
                    <div class="col-md-4">

                        <mat-form-field class="example-full-width">
                            <mat-label>Blood Pressure:</mat-label>


                            <textarea matInput (input)="onBloodPressuresChange($event)" formControlName="BloodPressure"
                                cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="140" [value]="VoiceRecognitionBloodPressure.bloodPressure" matInput
                                (blur)="onBloodPressureBlur()"></textarea>
                            <button type="button" class="app-speech-input-btn" [disabled]="listening"
                                (click)="startBloodPressureService()"></button>

                        </mat-form-field>

                    </div>
                    <!-- <button type="button" color="accent" (click)="stopChiefComplaintService()"
              mat-mini-fab><mat-icon>stop</mat-icon></button> -->

                </div>
                <div class="row">
                    <div class="col-md-4">



                        <mat-form-field class="example-full-width">
                            <mat-label>Pulse:</mat-label>


                            <textarea matInput (input)="onPulsesChange($event)" formControlName="Pulse"
                                cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="140" [value]="VoiceRecognitionPulse.pulse" matInput
                                (blur)="onPulseBlur()"></textarea>
                            <button type="button" class="app-speech-input-btn" [disabled]="listening"
                                (click)="startPulseService()"></button>

                        </mat-form-field>

                    </div>
                    <!-- <button type="button" color="accent" (click)="stopChiefComplaintService()"
              mat-mini-fab><mat-icon>stop</mat-icon></button> -->

                    <div class="col-md-4">


                        <mat-form-field class="example-full-width">
                            <mat-label>Temparature:</mat-label>


                            <textarea matInput (input)="onTemparaturesChange($event)" formControlName="Temparature"
                                cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="140" [value]="VoiceRecognitionTemparature.temparature" matInput
                                (blur)="onTemparatureBlur()"></textarea>
                            <button type="button" class="app-speech-input-btn" [disabled]="listening"
                                (click)="startTemparatureService()"></button>

                        </mat-form-field>

                    </div>
                    <!-- <button type="button" color="accent" (click)="stopChiefComplaintService()"
              mat-mini-fab><mat-icon>stop</mat-icon></button> -->

                    <div class="col-md-4">

                        <mat-form-field class="example-full-width">
                            <mat-label>Heart Rate:</mat-label>


                            <textarea matInput (input)="onHeartRatesChange($event)" formControlName="HeartRate"
                                cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="140" [value]="VoiceRecognitionHeartRate.heartRate" matInput
                                (blur)="onHeartRateBlur()"></textarea>
                            <button type="button" class="app-speech-input-btn" [disabled]="listening"
                                (click)="startHeartRateService()"></button>

                        </mat-form-field>

                    </div>
                    <!-- <button type="button" color="accent" (click)="stopHeartRateService()"
              mat-mini-fab><mat-icon>stop</mat-icon></button> -->

                </div>
                <div class="row">

                    <div class="col-md-6">

                        <mat-form-field class="example-full-width">
                            <mat-label>Head, Eyes, Ears, Nose and Throat:</mat-label>



                            <textarea matInput (input)="onHENTsChange($event)" formControlName="HENT"
                                cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="140" [value]="VoiceRecognitionHENT.hent" matInput
                                (blur)="onHENTBlur()"></textarea>
                            <button type="button" class="app-speech-input-btn" [disabled]="listening"
                                (click)="startHENTService()"></button>

                        </mat-form-field>

                    </div>
                    <!-- <button type="button" color="accent" (click)="stopHeartRateService()"
              mat-mini-fab><mat-icon>stop</mat-icon></button> -->
                    <div class="col-md-6">


                        <mat-form-field class="example-full-width">
                            <mat-label>Neck:</mat-label>


                            <textarea matInput (input)="onNecksChange($event)" formControlName="Neck"
                                cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="140" [value]="VoiceRecognitionNeck.neck" matInput
                                (blur)="onNeckBlur()"></textarea>
                            <button type="button" class="app-speech-input-btn" [disabled]="listening"
                                (click)="startNeckService()"></button>

                        </mat-form-field>

                    </div>
                    <!-- <button type="button" color="accent" (click)="stopNeckService()"
                mat-mini-fab><mat-icon>stop</mat-icon></button> -->
                </div>
                <div class="row">
                    <div class="col-md-3">

                        <mat-form-field class="example-full-width">
                            <mat-label>Chest:</mat-label>


                            <textarea matInput (input)="onChestsChange($event)" formControlName="Chest"
                                cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="140" [value]="VoiceRecognitionChest.chest" matInput
                                (blur)="onChestBlur()"></textarea>
                            <button type="button" class="app-speech-input-btn" [disabled]="listening"
                                (click)="startChestService()"></button>

                        </mat-form-field>

                    </div>
                    <!-- <button type="button" color="accent" (click)="stopChestService()"
                mat-mini-fab><mat-icon>stop</mat-icon></button> -->
                    <div class="col-md-3">



                        <mat-form-field class="example-full-width">
                            <mat-label>Heart:</mat-label>


                            <textarea matInput (input)="onHeartsChange($event)" formControlName="Heart"
                                cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="140" [value]="VoiceRecognitionHeart.heart" matInput
                                (blur)="onHeartBlur()"></textarea>
                            <button type="button" class="app-speech-input-btn" [disabled]="listening"
                                (click)="startHeartService()"></button>

                        </mat-form-field>

                    </div>
                    <!-- <button type="button" color="accent" (click)="stopHeartService()"
                mat-mini-fab><mat-icon>stop</mat-icon></button> -->
                    <div class="col-md-3">

                        <mat-form-field class="example-full-width">
                            <mat-label>Abdomen:</mat-label>


                            <textarea matInput (input)="onAbdomensChange($event)" formControlName="Abdomen"
                                cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="140" [value]="VoiceRecognitionAbdomen.abdomen" matInput
                                (blur)="onAbdomenBlur()"></textarea>
                            <button type="button" class="app-speech-input-btn" [disabled]="listening"
                                (click)="startAbdomenService()"></button>

                        </mat-form-field>

                    </div>
                    <!-- <button type="button" color="accent" (click)="stopAbdomenService()"
                mat-mini-fab><mat-icon>stop</mat-icon></button> -->
                    <div class="col-md-3">

                        <mat-form-field class="example-full-width">
                            <mat-label>Extermities:</mat-label>


                            <textarea matInput (input)="onExtermitiessChange($event)" formControlName="Extermities"
                                cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="140" [value]="VoiceRecognitionExtermities.extermities" matInput
                                (blur)="onExtermitiesBlur()"></textarea>
                            <button type="button" class="app-speech-input-btn" [disabled]="listening"
                                (click)="startExtermitiesService()"></button>

                        </mat-form-field>

                    </div>
                    <!-- <button type="button" color="accent" (click)="stopExtermitiesService()"
                mat-mini-fab><mat-icon>stop</mat-icon></button> -->
                </div>

                <div class="row">
                    <div class="col-md-12">

                        <mat-form-field class="example-full-width">
                            <mat-label>Neurological:</mat-label>


                            <textarea matInput (input)="onNeurologicalsChange($event)" formControlName="Neurological"
                                cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="140" [value]="VoiceRecognitionNeurological.neurological" matInput
                                (blur)="onNeurologicalBlur()"></textarea>
                            <button type="button" class="app-speech-input-btn" [disabled]="listening"
                                (click)="startNeurologicalService()"></button>

                        </mat-form-field>

                    </div>
                    <!-- <button type="button" color="accent" (click)="stopNeurologicalService()"
              mat-mini-fab><mat-icon>stop</mat-icon></button> -->
                </div>
                <div class="row">
                    <div class="col-md-12">


                        <mat-form-field class="example-full-width">
                            <mat-label>Radiology(X-rays/MRI's):</mat-label>



                            <textarea matInput (input)="onRadiologysChange($event)" formControlName="Radiology"
                                cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="140" [value]="VoiceRecognitionRadiology.radiologyComment" matInput
                                (blur)="onRadiologyBlur()"></textarea>
                            <button type="button" class="app-speech-input-btn" [disabled]="listening"
                                (click)="startRadiologyService()"></button>

                        </mat-form-field>

                    </div>
                    <!-- <button type="button" color="accent" (click)="stopRadiologyService()"
                mat-mini-fab><mat-icon>stop</mat-icon></button> -->
                </div>
                <div class="row">
                    <div class="col-md-12">

                        <mat-form-field class="example-full-width">
                            <mat-label>Impression:</mat-label>


                            <textarea matInput (input)="onImpressionsChange($event)" formControlName="Impression"
                                cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="140" [value]="VoiceRecognitionImpression.impression" matInput
                                (blur)="onImpressionBlur()"></textarea>
                            <button type="button" class="app-speech-input-btn" [disabled]="listening"
                                (click)="startImpressionService()"></button>

                        </mat-form-field>

                    </div>
                    <!-- <button type="button" color="accent" (click)="stopImpressionService()"
                mat-mini-fab><mat-icon>stop</mat-icon></button> -->
                </div>

                <div class="row">
                    <div class="col-md-12">

                        <mat-form-field class="example-full-width">
                            <mat-label>Recomendations:</mat-label>


                            <textarea matInput (input)="onRecomendationsChange($event)" formControlName="Recomendation"
                                cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="140" [value]="VoiceRecognitionRecomendation.recomendation" matInput
                                (blur)="onRecomendationBlur()"></textarea>
                            <button type="button" class="app-speech-input-btn" [disabled]="listening"
                                (click)="startRecomendationService()"></button>

                        </mat-form-field>

                    </div>
                    <!-- <button type="button" color="accent" (click)="stopRecomendationService()"
                mat-mini-fab><mat-icon>stop</mat-icon></button> -->
                </div>
                <div class="row">
                    <div class="col-md-4"><mat-form-field class="example-full-width">
                            <mat-label>Nurse Name</mat-label>
                            <input type="text" formControlName="NurseName" matInput>
                            <mat-error>Please enter Report Name</mat-error>
                        </mat-form-field></div>
                    <div class="col-md-4">
                        <mat-form-field class="example-full-width">
                            <mat-label>Signature</mat-label>
                            <input type="text" formControlName="Signature" matInput>
                            <mat-error>Please enter Signature</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-4">
                        <mat-form-field class="example-full-width">
                            <mat-label>Date</mat-label>
                            <input type="date" formControlName="Date" matInput>
                            <mat-error>Please enter Date</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <button class="float-right lgBtCl" mat-button>
                    <mat-icon>save</mat-icon>
                    Save</button>

                <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
                    Successfully submitted
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </form>

        </div>
    </div>
</div>