<div class="container">
  <div class="row">
    <div class="col-lg regFrm mat-elevation-z8">
      <h2>New Lab Manager</h2>
      <form (keydown.enter)="$event.preventDefault()" [formGroup]="doctorFrm" (ngSubmit)="formSubmit()"
        class="example-form">
        <div class="row">
          <div class="col-lg-4">
            <mat-form-field appearance="fill" class="example-full-width">
              <mat-label>Select Department</mat-label>
              <mat-select formControlName="DepartmentId">

                <mat-option *ngFor="let dpt of dptList" [value]="dpt.DepartmentId">{{dpt.DepartmentName}}</mat-option>

              </mat-select>

              <mat-error *ngIf="DepartmentId.hasError('required')">You must make a selection</mat-error>

            </mat-form-field>
          </div>
        </div>
        <!-- Personal Information -->
        <div class="row">
          <div class="col-lg-4">
            <mat-form-field class="example-full-width">
              <mat-label>Full Name</mat-label>
              <input type="text" formControlName="FullName" matInput placeholder="Enter Full Name">
              <mat-error *ngIf="doctorFrm.get('FullName')?.hasError('required')">Please enter Full Name</mat-error>
            </mat-form-field>
          </div>

          <div class="col-lg-1">
            <mat-form-field class="example-full-width">
              <mat-label>Country Code</mat-label>
              <input type="text" formControlName="CountryCode" [matAutocomplete]="auto" matInput placeholder="+1, +91">
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                  {{ option }}
                </mat-option>
              </mat-autocomplete>
              <mat-error *ngIf="doctorFrm.get('CountryCode')?.hasError('required')">
                Please enter a valid country code (e.g., +1, +91, +44).
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-lg-3">
            <mat-form-field class="example-full-width">
              <mat-label>Phone</mat-label>
              <input type="text" formControlName="PhoneNumber" matInput placeholder="Enter Phone Number">
              <mat-error *ngIf="doctorFrm.get('PhoneNumber')?.hasError('required')">Please enter Phone
                Number</mat-error>
            </mat-form-field>
          </div>

          <div class="col-lg-4">
            <mat-form-field class="example-full-width">
              <mat-label>National ID</mat-label>
              <input type="tel" formControlName="NationalId" matInput placeholder="Enter National ID">
              <mat-error *ngIf="doctorFrm.get('NationalId')?.hasError('required')">Please enter National ID</mat-error>
            </mat-form-field>
          </div>
        </div>

        <!-- Date of Birth, Email, and Password -->
        <div class="row">
          <div class="col-lg-4">
            <mat-form-field class="example-full-width">
              <mat-label>Date of Birth</mat-label>
              <input type="date" formControlName="Dob" matInput>
              <mat-error *ngIf="doctorFrm.get('Dob')?.hasError('required')">Please enter Date of Birth</mat-error>
            </mat-form-field>
          </div>

          <div class="col-lg-4">
            <mat-form-field class="example-full-width">
              <mat-label>Email</mat-label>
              <input type="email" formControlName="Email" matInput placeholder="Enter Email">
              <mat-error *ngIf="doctorFrm.get('Email')?.hasError('required')">Please enter Email</mat-error>
              <mat-error *ngIf="doctorFrm.get('Email')?.hasError('email')">Please enter a valid Email</mat-error>
            </mat-form-field>
          </div>

          <div class="col-lg-4">
            <mat-form-field class="example-full-width">
              <mat-label>Password</mat-label>
              <input type="password" formControlName="Password" matInput placeholder="Enter Password">
              <mat-error *ngIf="doctorFrm.get('Password')?.hasError('required')">Please enter Password</mat-error>
              <mat-error *ngIf="doctorFrm.get('Password')?.hasError('pattern')">
                Password must contain at least 7 characters, 1 number, 1 uppercase letter, and 1 special character.
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <!-- Address and Status -->
        <div class="row">
          <div class="col-lg-8">
            <mat-form-field class="example-full-width">
              <mat-label>Address</mat-label>
              <input type="text" formControlName="Address" matInput placeholder="Enter Address">
              <mat-error *ngIf="doctorFrm.get('Address')?.hasError('required')">Please enter Address</mat-error>
            </mat-form-field>
          </div>

          <div class="col-lg-4">
            <p>Status</p>
            <mat-radio-group formControlName="Status">
              <mat-radio-button value="true" class="mx-3">Active</mat-radio-button>
              <mat-radio-button value="false" class="mx-3">Inactive</mat-radio-button>
            </mat-radio-group>
            <mat-error *ngIf="doctorFrm.get('Status')?.hasError('required')">Please select a Status</mat-error>
          </div>
        </div>


        <button class="float-right lgBtCl" mat-button>
          <mat-icon>save</mat-icon>
        </button>

        <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
          Successfully Created Lab Manager
          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </form>

    </div>
  </div>

</div>

<!-- Modal -->
<div class="modal-backdrop" *ngIf="showModal"></div>
<div class="modal" *ngIf="showModal">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Alert</h5>
        <button type="button" class="btn-close" (click)="closeModal()"></button>
      </div>
      <div class="modal-body">
        <p>{{ modalMessage }}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="closeModal()">OK</button>
      </div>
    </div>
  </div>
</div>