<div class="container">
    <h2>Provider Enrollment</h2>

    <form [formGroup]="providerForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
            <label for="providerName">Provider Name</label>
            <input id="providerName" class="form-control" formControlName="providerName"
                placeholder="Enter Provider Name" required />
        </div>

        <div class="form-group">
            <label for="npi">NPI</label>
            <input id="npi" class="form-control" formControlName="npi" placeholder="Enter NPI" required />
        </div>

        <div class="form-group">
            <label for="taxId">Tax ID</label>
            <input id="taxId" class="form-control" formControlName="taxId" placeholder="Enter Tax ID" required />
        </div>

        <div class="form-group">
            <label for="address">Address</label>
            <input id="address" class="form-control" formControlName="address" placeholder="Enter Address" required />
        </div>

        <div class="form-group">
            <label for="city">City</label>
            <input id="city" class="form-control" formControlName="city" placeholder="Enter City" required />
        </div>

        <div class="form-group">
            <label for="state">State</label>
            <input id="state" class="form-control" formControlName="state" placeholder="Enter State" required />
        </div>

        <div class="form-group">
            <label for="zipCode">Zip Code</label>
            <input id="zipCode" class="form-control" formControlName="zipCode" placeholder="Enter Zip Code" required />
        </div>

        <div class="form-group">
            <label for="phoneNumber">Phone Number</label>
            <input id="phoneNumber" class="form-control" formControlName="phoneNumber" placeholder="Enter Phone Number"
                required />
        </div>

        <div class="form-group">
            <label for="email">Email</label>
            <input id="email" class="form-control" formControlName="email" placeholder="Enter Email" type="email"
                required />
        </div>

        <button type="submit" class="btn btn-primary" [disabled]="!providerForm.valid">
            Enroll Provider
        </button>
    </form>
</div>