<mat-card>
    <mat-card-title>Claim Notes</mat-card-title>
    <mat-card-content>
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8"
            *ngIf="dataSource?.data?.length; else noData">

            <!-- Claim ID Column -->
            <ng-container matColumnDef="ClaimId">
                <th mat-header-cell *matHeaderCellDef>Claim ID</th>
                <td mat-cell *matCellDef="let note">{{ note.ClaimId }}</td>
            </ng-container>

            <!-- Note Column -->
            <ng-container matColumnDef="NoteText">
                <th mat-header-cell *matHeaderCellDef>Note</th>
                <td mat-cell *matCellDef="let note">{{ note.NoteText }}</td>
            </ng-container>

            <!-- Username Column -->
            <ng-container matColumnDef="Username">
                <th mat-header-cell *matHeaderCellDef>Username</th>
                <td mat-cell *matCellDef="let note">{{ note.Username }}</td>
            </ng-container>

            <!-- Date/Time Column -->
            <ng-container matColumnDef="DateTime">
                <th mat-header-cell *matHeaderCellDef>Date/Time</th>
                <td mat-cell *matCellDef="let note">{{ note.DateTime }}</td>
            </ng-container>

            <!-- Note ID Column -->
            <ng-container matColumnDef="NoteId">
                <th mat-header-cell *matHeaderCellDef>Note ID</th>
                <td mat-cell *matCellDef="let note">{{ note.NoteId }}</td>
            </ng-container>

            <!-- ClaimMd ID Column -->
            <ng-container matColumnDef="ClaimMdId">
                <th mat-header-cell *matHeaderCellDef>ClaimMd ID</th>
                <td mat-cell *matCellDef="let note">{{ note.ClaimMdId }}</td>
            </ng-container>

            <!-- PCN Column -->
            <ng-container matColumnDef="Pcn">
                <th mat-header-cell *matHeaderCellDef>PCN</th>
                <td mat-cell *matCellDef="let note">{{ note.Pcn }}</td>
            </ng-container>

            <!-- Header and Row Definitions -->
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <!-- Paginator -->
        <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>

        <!-- No Data Template -->
        <ng-template #noData>
            <p style="text-align: center; color: gray;">No claim notes available.</p>
        </ng-template>
    </mat-card-content>
</mat-card>