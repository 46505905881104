<div class="container">
    <h2>Update Claim Account Key</h2>
    <form [formGroup]="claimAccountKeyForm" (ngSubmit)="onSubmit()">
        <div>
            <label for="AccountKey">Account Key:</label>
            <input id="AccountKey" formControlName="AccountKey" />
        </div>
        <div>
            <label for="Status">Status:</label>
            <input id="Status" formControlName="Status" />
        </div>
        <div>
            <label for="HospitalId">Hospital ID:</label>
            <input id="HospitalId" formControlName="HospitalId" type="number" />
        </div>
        <button type="submit">Update</button>
    </form>
    <p *ngIf="successMessage" class="success">{{ successMessage }}</p>
    <p *ngIf="errorMessage" class="error">{{ errorMessage }}</p>
</div>