import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ClaimAccountKeyService } from 'src/app/services/claim-account-key.service';

@Component({
  selector: 'app-update-claim-account-key',
  templateUrl: './update-claim-account-key.component.html',
  styleUrls: ['./update-claim-account-key.component.css'],
})
export class UpdateClaimAccountKeyComponent implements OnInit {
  claimAccountKeyForm: FormGroup;
  successMessage: string = '';
  errorMessage: string = '';
  claimAccountKeyId: number;

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private claimAccountKeyService: ClaimAccountKeyService
  ) {
    this.claimAccountKeyId = +this.route.snapshot.paramMap.get('id')!;
    this.claimAccountKeyForm = this.fb.group({
      AccountKey: ['', Validators.required],
      Status: ['', Validators.required],
      HospitalId: [null, Validators.required],
    });
  }

  ngOnInit() {
    this.loadClaimAccountKey();
  }

  loadClaimAccountKey() {
    this.claimAccountKeyService
      .getClaimAccountKeyById(this.claimAccountKeyId)
      .subscribe({
        next: (response) => {
          this.claimAccountKeyForm.patchValue(response);
        },
        error: (err) => {
          this.errorMessage = err;
        },
      });
  }

  onSubmit() {
    if (this.claimAccountKeyForm.valid) {
      this.claimAccountKeyService
        .updateClaimAccountKey(
          this.claimAccountKeyId,
          this.claimAccountKeyForm.value
        )
        .subscribe({
          next: () => {
            this.successMessage = 'Claim Account Key updated successfully!';
          },
          error: (err) => {
            this.errorMessage = err;
          },
        });
    }
  }
}
