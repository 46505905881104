import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  UntypedFormBuilder,
} from '@angular/forms';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService } from 'src/app/services/auth.service';
import { TokenService } from 'src/app/services/token.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-emergency-access-phone-auth',
  templateUrl: './emergency-access-phone-auth.component.html',
  styleUrls: ['./emergency-access-phone-auth.component.css'],
})
export class EmergencyAccessPhoneAuthComponent implements OnInit {
  id = 0;
  helper = new JwtHelperService();
  error = false;
  IsthrowError = '';
  netWorkFailure: string;
  passwordFieldType: string = 'password';

  constructor(
    private authService: AuthService,
    private fb: UntypedFormBuilder,
    private router: Router,
    private tokenService: TokenService
  ) {}

  ngOnInit(): void {}

  logiForm = this.fb.group({
    UserName: ['', [Validators.required]],
    Password: ['', Validators.required],
  });

  get UserName() {
    return this.logiForm.get('UserName');
  }
  get Password() {
    return this.logiForm.get('Password');
  }
  togglePasswordVisibility(): void {
    this.passwordFieldType =
      this.passwordFieldType === 'password' ? 'text' : 'password';
  }
  loginSubmit() {
    if (this.logiForm.valid) {
      this.authService.emergency(this.logiForm.value).subscribe(
        (res) => {
          window.localStorage.setItem('token', res['token']);

          let currentToken = res['token'];

          let decodedToken = this.helper.decodeToken(currentToken);
          this.id = parseInt(decodedToken.nameid);

          // this.router.navigate(['twofa', decodedToken]);
          if (decodedToken.role == 'Admin') {
            this.router.navigate(['/adminDash']);
          } else if (decodedToken.role == 'Doctor') {
            this.router.navigate(['/doctorDash']);
          } else if (decodedToken.role == 'Nurse') {
            this.router.navigate(['/nurseDash/']);
          } else if (decodedToken.role == 'LabManager') {
            this.router.navigate(['/labDash/']);
          } else if (decodedToken.role == 'PharmacyMember') {
            this.router.navigate(['/pharmacyDash/']);
          } else if (decodedToken.role == 'RadiologyMember') {
            this.router.navigate(['/radiologyDash/']);
          } else {
            alert('not user found');
          }
        },
        (err) => {
          this.error = true;
          this.netWorkFailure = err;
        }
      );
    } else {
      this.IsthrowError =
        'Please review and check privacy policy to continue login';
    }
  }
}
// if(this.logiForm.valid){
//   this.authService.TwoFa(this.logiForm.value).subscribe(res=>{
//    this.router.navigate(['twofa'])
//   })

//   }
