import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-nurse-side-split-a',
  templateUrl: './nurse-side-split-a.component.html',
  styleUrls: ['./nurse-side-split-a.component.css'],
})
export class NurseSideSplitAComponent implements OnInit {
  id = 0;
  constructor(
    private route: ActivatedRoute,
    private tokenService: TokenService
  ) {}
  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
  }
  tabs: string[] = [
    'New Vitals Report',
    'New Progress Report',
    'New  Note',
    'Prescription',
    'New Visit',
    'New Communication-Assessment',
    'New Order',
    'New Refferal',
    'New Lab Results',
    'Upload Lab Report',
    'Upload Radiology Report',
    'Upload Study Images',
    'New Review Of System',
  ];
  activatedTabIndex: number = 0;

  tabChange(tabIndex: number) {
    // debugger;
    this.activatedTabIndex = tabIndex;
  }
  viewMode = 'tab1';
}
