import { Component } from '@angular/core';

@Component({
  selector: 'app-view-front-desk',
  templateUrl: './view-front-desk.component.html',
  styleUrls: ['./view-front-desk.component.css']
})
export class ViewFrontDeskComponent {

}
