<div class="dialog-header">
    <button mat-raised-button color="primary" class="close-button" (click)="onClose()">Close</button>
</div>
<div class="container" cdkDrag cdkDragRootElement=".cdk-overlay-pane">
    <h2>Review of Systems</h2>
    <form (keydown.enter)="$event.preventDefault()" [formGroup]="reviewOfSystemForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
            <h3>1. Constitutional</h3>

            <mat-checkbox color="primary" formControlName="Chills">Chills</mat-checkbox>
            <mat-checkbox color="primary" formControlName="Fatigue">Fatigue</mat-checkbox>
            <mat-checkbox color="primary" formControlName="Fever">Fever</mat-checkbox>
            <mat-checkbox color="primary" formControlName="WeightGain">Weight Gain</mat-checkbox>
            <mat-checkbox color="primary" formControlName="WeightLoss">Weight Loss</mat-checkbox>
        </div>

        <div class="form-group">
            <h3>2. HEENT</h3>
            <mat-checkbox color="primary" formControlName="HearingLoss">Hearing Loss</mat-checkbox>
            <mat-checkbox color="primary" formControlName="SinusPressure">Sinus Pressure</mat-checkbox>
            <mat-checkbox color="primary" formControlName="VisualChanges">Visual Changes</mat-checkbox>
        </div>

        <div class="form-group">
            <h3>3. Respiratory</h3>
            <mat-checkbox color="primary" formControlName="Cough">Cough</mat-checkbox>
            <mat-checkbox color="primary" formControlName="ShortnessOfBreath">Shortness of
                Breath</mat-checkbox>
            <mat-checkbox color="primary" formControlName="Wheezing">Wheezing</mat-checkbox>
        </div>

        <div class="form-group">
            <h3>4. Cardiovascular</h3>
            <mat-checkbox color="primary" formControlName="ChestPain">Chest Pain</mat-checkbox>
            <mat-checkbox color="primary" formControlName="Claudication">Pain while walking
                (Claudication)</mat-checkbox>
            <mat-checkbox color="primary" formControlName="Edema">Edema</mat-checkbox>
            <mat-checkbox color="primary" formControlName="Palpitations">Palpitations</mat-checkbox>
        </div>

        <div class="form-group">
            <h3>5. Gastrointestinal</h3>
            <mat-checkbox color="primary" formControlName="AbdominalPain">Abdominal Pain</mat-checkbox>
            <mat-checkbox color="primary" formControlName="BloodInStool">Blood in Stool</mat-checkbox>
            <mat-checkbox color="primary" formControlName="Constipation">Constipation</mat-checkbox>
            <mat-checkbox color="primary" formControlName="Diarrhea">Diarrhea</mat-checkbox>
            <mat-checkbox color="primary" formControlName="Heartburn">Heartburn</mat-checkbox>
            <mat-checkbox color="primary" formControlName="LossOfAppetite">Loss of
                Appetite</mat-checkbox>
            <mat-checkbox color="primary" formControlName="Nausea">Nausea</mat-checkbox>
            <mat-checkbox color="primary" formControlName="Vision">Vomiting</mat-checkbox>
        </div>

        <div class="form-group">
            <h3>6. Genitourinary</h3>
            <mat-checkbox color="primary" formControlName="Dysuria">Painful urination
                (Dysuria)</mat-checkbox>
            <mat-checkbox color="primary" formControlName="Polyuria">Excessive amount of urine
                (Polyuria)</mat-checkbox>
            <mat-checkbox color="primary" formControlName="UrinaryFrequency">Urinary
                Frequency</mat-checkbox>
        </div>

        <div class="form-group">
            <h3>7. Metabolic/Endocrine</h3>
            <mat-checkbox color="primary" formControlName="ColdIntolerance">Cold
                Intolerance</mat-checkbox>
            <mat-checkbox color="primary" formControlName="HeatIntolerance">Heat
                Intolerance</mat-checkbox>
            <mat-checkbox color="primary" formControlName="Polydipsia">Excessive Thirst
                (Polydipsia)</mat-checkbox>
            <mat-checkbox color="primary" formControlName="Polyphagia">Excessive Hunger
                (Polyphagia)</mat-checkbox>
        </div>

        <div class="form-group">
            <h3>8. Neurological</h3>
            <mat-checkbox color="primary" formControlName="Dizziness">Dizziness</mat-checkbox>
            <mat-checkbox color="primary" formControlName="ExtremityNumbness">Extremity
                Numbness</mat-checkbox>
            <mat-checkbox color="primary" formControlName="ExtremityWeakness">Extremity
                Weakness</mat-checkbox>
            <mat-checkbox color="primary" formControlName="Headaches">Headaches</mat-checkbox>
            <mat-checkbox color="primary" formControlName="Seizures">Seizures</mat-checkbox>
            <mat-checkbox color="primary" formControlName="Thinners">Tremors</mat-checkbox>
        </div>

        <div class="form-group">
            <h3>9. Psychiatric</h3>
            <mat-checkbox color="primary" formControlName="Anxiety">Anxiety</mat-checkbox>
            <mat-checkbox color="primary" formControlName="DateOfSymtomsBegan">Depression</mat-checkbox>
        </div>

        <div class="form-group">
            <h3>10. Integumentary</h3>
            <mat-checkbox color="primary" formControlName="BreastDischarge">Breast
                Discharge</mat-checkbox>
            <mat-checkbox color="primary" formControlName="BreastLump">Breast Lump</mat-checkbox>
            <mat-checkbox color="primary" formControlName="Hives">Hives</mat-checkbox>
            <mat-checkbox color="primary" formControlName="MoleChanges">Mole Change(s)</mat-checkbox>
            <mat-checkbox color="primary" formControlName="Rash">Rash</mat-checkbox>
            <mat-checkbox color="primary" formControlName="SkinLesion">Skin Lesion</mat-checkbox>
        </div>

        <div class="form-group">
            <h3>11. Musculoskeletal</h3>
            <mat-checkbox color="primary" formControlName="BackPain">Back Pain</mat-checkbox>
            <mat-checkbox color="primary" formControlName="JointPain">Joint Pain</mat-checkbox>
            <mat-checkbox color="primary" formControlName="JointSwelling">Joint Swelling</mat-checkbox>
            <mat-checkbox color="primary" formControlName="Nausea">Neck Pain</mat-checkbox>
        </div>

        <div class="form-group">
            <h3>12. Hematologic</h3>
            <mat-checkbox color="primary" formControlName="EasilyBleeds">Easily Bleeds</mat-checkbox>
            <mat-checkbox color="primary" formControlName="EasilyBruises">Easily Bruises</mat-checkbox>
            <mat-checkbox color="primary" formControlName="Lymphedema">Lymphedema</mat-checkbox>
            <mat-checkbox color="primary" formControlName="BloodClots">Blood Clots</mat-checkbox>
        </div>

        <div class="form-group">
            <h3>13. Immunologic</h3>
            <mat-checkbox color="primary" formControlName="FoodAllergies">Food Allergies</mat-checkbox>
            <mat-checkbox color="primary" formControlName="SeasonalAllergies">Seasonal
                Allergies</mat-checkbox>
        </div>

        <div class="form-group">
            <mat-checkbox color="primary" formControlName="NoneApply">None Apply</mat-checkbox>
        </div>
        <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
            Successfully Submitted refill request
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <button type="submit" mat-raised-button color="primary" [disabled]="reviewOfSystemForm.invalid">Submit</button>
    </form>
</div>