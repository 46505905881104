<div class="dialog-header">
  <button mat-raised-button color="primary" class="close-button" (click)="onClose()">Close</button>
</div>
<div class="container" cdkDrag cdkDragRootElement=".cdk-overlay-pane">

  <div class="col-lg regFrm mat-elevation-z8">
    <h1>Update Prescription</h1>
    <form (keydown.enter)="$event.preventDefault()" [formGroup]="prescriptionFrm"
      (keydown.enter)="$event.preventDefault()" (ngSubmit)="formSubmit()" class="example-form">
      <div class="">
        <div>

          <div class="row">

            <div class="col-lg-6">
              <mat-form-field class="example-full-width">
                <mat-label>Drug Name</mat-label>
                <mat-select formControlName="DrugName">
                  <mat-option>
                    <lib-mat-select-search [list]="medicineList" [searchProperties]="['DrugName']"
                      (filtered)="flteredmedicineList = $event">
                    </lib-mat-select-search>
                  </mat-option>

                  <cdk-virtual-scroll-viewport [ngStyle]="{height: '200px'}" itemSize="25">
                    <mat-option *cdkVirtualFor="let entry of flteredmedicineList" [value]="entry.DrugName">
                      {{entry.DrugName}}
                    </mat-option>
                  </cdk-virtual-scroll-viewport>
                </mat-select>
                <!-- <input type="text" formControlName="DrugName" matInput > -->
                <mat-error>Drug Name required</mat-error>

              </mat-form-field>
            </div>

            <div class="col-lg-3">
              <mat-form-field class="example-full-width">
                <mat-label>Amount Per Dose</mat-label>


                <mat-error>Units required</mat-error>
                <input type="text" matInput formControlName="AmountPerDose" [matAutocomplete]="autoDs">
                <mat-icon matSuffix>search</mat-icon>
                <mat-autocomplete #autoDs="matAutocomplete">
                  <mat-option *ngFor="let ext of filteredDose | async" [value]="dose" #doseTb>
                    {{ dose }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>


            </div>
            <!-- QuantityUnit -->
            <div class="col-lg-3">
              <mat-form-field class="example-full-width">
                <mat-label>Units</mat-label>

                <mat-error>Units required</mat-error>
                <input type="text" matInput formControlName="Unit" [matAutocomplete]="auto6"> <mat-icon
                  matSuffix>search</mat-icon>
                <mat-autocomplete #auto6="matAutocomplete">
                  <mat-option *ngFor="let ext of filteredExt | async" [value]="ext" #extTb
                    (onSelectionChange)="getExtValue(extTb.value)">
                    {{ ext }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>


            </div>

          </div>
          <div class="row">


            <div class="col-lg-4">
              <mat-form-field class="example-full-width">
                <mat-label>Route</mat-label>
                <input type="text" matInput formControlName="Route" [matAutocomplete]="auto"> <mat-icon
                  matSuffix>search</mat-icon>
                <mat-autocomplete #auto="matAutocomplete">
                  <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                    {{ option }}
                  </mat-option>
                </mat-autocomplete>
                <mat-error>Route
                  required</mat-error>

              </mat-form-field>

            </div>

            <div class="col-lg-6">
              <mat-form-field class="example-full-width">
                <mat-label>Frequency</mat-label>
                <!-- <mat-select formControlName="Instruction"> -->
                <input type="text" matInput formControlName="Frequency" [matAutocomplete]="auto3"><mat-icon
                  matSuffix>search</mat-icon>
                <mat-autocomplete #auto3="matAutocomplete">
                  <mat-option *ngFor="let fc of filteredFrequency | async" #stateInput [value]="fc"
                    (onSelectionChange)="getFrequency(stateInput.value)">
                    {{ fc }}
                  </mat-option>
                </mat-autocomplete>
                <mat-error>Frequency
                  required</mat-error>
              </mat-form-field>

            </div>

          </div>
          <div class="row">
            <div class="col-lg-4">
              <mat-form-field class="example-full-width">
                <mat-label>Refill Duration</mat-label>
                <!-- <input type="text" matInput formControlName="RefillDuration"> -->
                <input type="text" matInput formControlName="RefillDuration" [matAutocomplete]="auto4"><mat-icon
                  matSuffix>search</mat-icon>
                <mat-autocomplete #auto4="matAutocomplete">
                  <mat-option *ngFor="let fgc of filteredRefill | async" [value]="fgc" #stateInput2
                    (onSelectionChange)="getRefillValue(stateInput2.value)">
                    {{ fgc }}
                  </mat-option>
                </mat-autocomplete>
                <mat-error>Refill Duration required</mat-error>

              </mat-form-field>

            </div>
            <div class="col-lg-4">
              <mat-form-field class="example-full-width">
                <mat-label>Quantity</mat-label>
                <!-- (input)="getGrandTotal()" -->
                <input type="number" matInput formControlName="Quantity" (input)="getGrandTotal()">
                <mat-error>Quantity
                  required</mat-error>

              </mat-form-field>

            </div>
            <div class="col-lg-4">
              <mat-form-field class="example-full-width">
                <mat-label>Number Of Refills</mat-label>
                <mat-select formControlName="NumberOfRefills">
                  <mat-option *ngFor="let option of NumberOfRefillsList " [value]="option">
                    {{ option }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

            </div>
          </div>
          <!-- <div class="row">
  
              <div class="col-lg-3">
                <mat-form-field class="example-full-width">
                  <mat-label> Morning </mat-label>
                  <input [ngxTimepicker]="picker" matInput formControlName="MorningTime">
                  <ngx-material-timepicker #picker></ngx-material-timepicker>
                  <mat-error>Please enter Available Morning</mat-error>
                </mat-form-field>
              </div>
              <div class="col-lg-3">
                <mat-form-field class="example-full-width">
                  <mat-label> Afternoon </mat-label>
                  <input [ngxTimepicker]="picker1" matInput formControlName="AfternoonTime">
                  <ngx-material-timepicker #picker1></ngx-material-timepicker>
                  <mat-error>Please enter Available Morning</mat-error>
                </mat-form-field>
              </div>
              <div class="col-lg-3">
                <mat-form-field class="example-full-width">
                  <mat-label> Evening </mat-label>
                  <input [ngxTimepicker]="picker2" matInput formControlName="EveningTime">
                  <ngx-material-timepicker #picker2></ngx-material-timepicker>
                  <mat-error>Please enter Available Morning</mat-error>
                </mat-form-field>
              </div>
              <div class="col-lg-3">
                <mat-form-field class="example-full-width">
                  <mat-label> Night </mat-label>
                  <input [ngxTimepicker]="picker3" matInput formControlName="NightTime">
                  <ngx-material-timepicker #picker3></ngx-material-timepicker>
                  <mat-error>Please enter Available Morning</mat-error>
                </mat-form-field>
              </div>
  
  
            </div> -->
          <div class="row">
            <!-- <div class="col-lg-6">
                <mat-form-field class="example-full-width">
                  <mat-label>Q-Time</mat-label>
                  <mat-select formControlName="QTime">
  
                    <mat-option *ngFor="let q of QTimes" [value]="q">{{q}}</mat-option>
  
                  </mat-select>
  
                </mat-form-field>
              </div> -->

          </div>

          <div class="row">

            <div class="col-lg-4">
              <mat-form-field class="example-full-width">
                <mat-label>Start Date</mat-label>
                <input type="date" matInput formControlName="StartDate">
                <mat-error>Start Date required</mat-error>

              </mat-form-field>

            </div>
            <div class="col-lg-4">
              <mat-form-field class="example-full-width">
                <mat-label>End Date</mat-label>
                <input type="date" matInput formControlName="EndDate">
                <!-- (input)="setEndDate()" -->
                <mat-error>End Date required</mat-error>

              </mat-form-field>

            </div>

            <div class="col-lg-4">

              <p>Status</p>
              <mat-radio-group formControlName="Status" aria-label="Select an option my-3">
                <mat-radio-button class="mx-3" value="true">Current</mat-radio-button>
                <mat-radio-button class="mx-3 " value="false"> Stopped</mat-radio-button>
              </mat-radio-group>

            </div>
          </div>
          <div class="row">


            <div class="col-lg-12">
              <mat-form-field class="example-full-width">
                <mat-label>Direction of Use</mat-label>
                <mat-select formControlName="Instruction">
                  <mat-option *ngFor="let option of directionOfUseOptions" [value]="option">
                    {{ option }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <!-- <mat-form-field class="example-full-width">
                  <mat-label>Direction of Use (Patient Sig)</mat-label>
                 
                  <input type="text" matInput formControlName="Instruction" [matAutocomplete]="auto2"><mat-icon
                    matSuffix>search</mat-icon>
                  <mat-autocomplete #auto2="matAutocomplete">
                    <mat-option *ngFor="let ins of filteredinstructions | async" [value]="ins">
                      {{ ins }}
                    </mat-option>
                  </mat-autocomplete>
                  <mat-error>Direction of use
                    required</mat-error>
                </mat-form-field> -->

            </div>

          </div>
          <div class="row">


            <div class="col-lg-8">
              <mat-form-field class="example-full-width">
                <mat-label>Comments to Pharmacy</mat-label>
                <input type="text" formControlName="Note" matInput>
                <mat-error>Note</mat-error>
              </mat-form-field>
            </div>




            <div class="col-lg-4">
              <mat-form-field class="example-full-width">
                <mat-label>Doctor Name </mat-label>
                <input type="text" formControlName="DoctorName" matInput>
                <mat-error>DoctorName</mat-error>
              </mat-form-field>
            </div>

          </div>

          <div class="row">
            <div class="col" *ngIf="!isApproved">
              <button type="button" mat-raised-button color="warn" (click)="approve($event)">Approve</button>

            </div>
            <div class="col" *ngIf="isApproved">
              <p [ngStyle]="{'color': 'blue'}">Doctor Approved! please save</p>
            </div>
          </div>






        </div>





      </div>




      <button class="float-right lgBtCl" mat-button>
        <mat-icon>save</mat-icon>
        Save</button>
      <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
        Successfully Created Prescription
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </form>

  </div>
</div>

<div mat-dialog-actions class="float-right">
  <button mat-button mat-dialog-close>Close</button>
  <!-- <button mat-button>Save</button> -->
</div>
<!-- <div class="col-lg-6">
                  <mat-form-field class="example-full-width">
                    <mat-label>Signature</mat-label>
                    <input type="text" formControlName="DoctorSignature" matInput>
                    <mat-error>Sign required</mat-error>
                  </mat-form-field>
                </div> -->