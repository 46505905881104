import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { WebcamImage } from 'ngx-webcam';
import { Observable, startWith, map, Subject } from 'rxjs';
import { Department } from 'src/app/Models/department.model';
import { BillerService } from 'src/app/services/biller.service';
import { DepartmentService } from 'src/app/services/department.service';
import { EmailService } from 'src/app/services/email.service';
import { HospitalService } from 'src/app/services/hospital.service';
import { TokenService } from 'src/app/services/token.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-update-biller',
  templateUrl: './update-biller.component.html',
  styleUrls: ['./update-biller.component.css'],
})
export class UpdateBillerComponent implements OnInit {
  id = 0;
  helper = new JwtHelperService();

  myToken = this.tokenService.getToken();

  isSuccess = false;
  dptList: Department[];
  Codes: any[] = [];
  filteredOptions: Observable<string[]>;
  hospitalName: string;
  imageUrl: any;
  modalMessage: string;
  showModal: boolean;
  s3BucketName: string = 'your-s3-bucket-name';
  region: string = 'your-region';
  accessKeyId: string = 'your-access-key-id';
  secretAccessKey: string = 'your-secret-access-key';
  billerId = 0;
  existingImage: string;

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private dptService: DepartmentService,
    private billerService: BillerService,
    private userService: UserService,
    private router: Router,
    private http: HttpClient,
    private emailService: EmailService,
    private hosptalService: HospitalService,
    private tokenService: TokenService
  ) {}
  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.billerId = res['id'];
    });
    this.billerService
      .getBillerById(this.billerId)
      .subscribe((existingData) => {
        this.doctorFrm.patchValue({
          FullName: existingData.FullName,
          Dob: existingData.Dob,
          Email: existingData.Email,
          PhoneNumber: existingData.PhoneNumber,
          NationalId: existingData.NationalId,
          Address: existingData.Address,
          Status: existingData.Status,
          StartDate: existingData.StartDate,
          DepartmentName: existingData.DepartmentName,
          DepartmentId: existingData.DepartmentId,
          // Store the image URL
          Image: existingData.Image, // Optional: Store in the form
        });
        this.existingImage = existingData.Image;
      });
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.id = parseInt(decodedToken.nameid);

    this.hosptalService.getHospitalById(this.id).subscribe((res) => {
      this.hospitalName = res.HospitalName.substring(0, 3);
    });

    this.getDepartments();

    this.countryCodes();
    this.filteredOptions =
      this.doctorFrm.controls.CountryCode.valueChanges.pipe(
        startWith(''),
        map((value: string) => this._filter(value || ''))
      );
  }

  doctorFrm = this.fb.group({
    DepartmentId: ['', Validators.required],
    FullName: ['', Validators.required],
    Email: ['', Validators.required],
    // CountryCode: ['+1', Validators.required],
    PhoneNumber: ['', Validators.required],
    NationalId: ['', Validators.required],
    Address: ['', Validators.required],
    Dob: ['', Validators.required],
    Status: [''],
    StartDate: ['', Validators.required],
    // Password: [
    //   '',
    //   [
    //     Validators.required,
    //     Validators.pattern(
    //       '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{7,}$'
    //     ),
    //   ],
    // ],
  });
  get StartDate() {
    return this.doctorFrm.get('StartDate');
  }
  get CountryCode() {
    return this.doctorFrm.get('CountryCode');
  }
  get FullName() {
    return this.doctorFrm.get('FullName');
  }
  get Email() {
    return this.doctorFrm.get('Email');
  }
  get DepartmentId() {
    return this.doctorFrm.get('DepartmentId');
  }
  get PhoneNumber() {
    return this.doctorFrm.get('PhoneNumber');
  }
  get NationalId() {
    return this.doctorFrm.get('NationalId');
  }

  get Dob() {
    return this.doctorFrm.get('Dob');
  }
  get Address() {
    return this.doctorFrm.get('Address');
  }

  get Status() {
    return this.doctorFrm.get('Status');
  }
  get Password() {
    return this.doctorFrm.get('Password');
  }

  getDepartments() {
    this.dptService.getDepartments().subscribe((res) => {
      this.dptList = res;
    });
  }

  // image1(e) {
  //   let formData = new FormData();
  //   let files = e.target.files;
  //   formData.append('file', files[0], files[0].name);

  //   this.billerService.imageUpload(formData).subscribe(
  //     (res) => {
  //       let stImg = environment.imgurl + res['path'];
  //       this.imageUrl = stImg;
  //     },
  //     (err) => console.log(err)
  //   );
  // }
  image(e: Event) {
    const inputElement = e.target as HTMLInputElement;

    if (inputElement.files && inputElement.files.length > 0) {
      const formData = new FormData();
      const file = inputElement.files[0];
      formData.append('file', file); // No need to append file name here

      this.billerService.imageUploadS3(formData).subscribe(
        (res: any) => {
          // Assuming res['path'] is the full S3 file URL returned by the backend
          this.imageUrl = res.path;
          console.log('File uploaded successfully:', this.imageUrl);
        },
        (err) => {
          // console.error('Error uploading file:', err);
        }
      );
    }
  }
  // image(e: any) {
  //   const file = e.target.files[0];

  //   if (!file) {
  //     console.error('No file selected');
  //     return;
  //   }

  //   // Step 1: Configure AWS S3
  //   const s3 = new AWS.S3({
  //     accessKeyId: this.accessKeyId,
  //     secretAccessKey: this.secretAccessKey,
  //     region: this.region,
  //   });

  //   const params = {
  //     Bucket: this.s3BucketName,
  //     Key: file.name,
  //     Body: file,
  //     ContentType: file.type,
  //     ACL: 'public-read',
  //   };

  //   s3.upload(params, (err: Error, data: AWS.S3.ManagedUpload.SendData) => {
  //     if (err) {
  //       console.error('Error uploading file to S3', err);
  //       return;
  //     }

  //     console.log('File successfully uploaded to S3', data);
  //     this.imageUrl = data.Location; // Get the file's S3 URL

  //   });
  // }
  private trigger: Subject<any> = new Subject();
  public webcamImage!: WebcamImage;
  private nextWebcam: Subject<any> = new Subject();
  sysImage = '';
  public getSnapshot(): void {
    this.trigger.next(void 0);
  }
  public captureImg(webcamImage: WebcamImage): void {
    this.webcamImage = webcamImage;
    this.sysImage = webcamImage!.imageAsDataUrl;
    // console.info('got webcam image', this.sysImage);
  }
  public get invokeObservable(): Observable<any> {
    return this.trigger.asObservable();
  }
  public get nextWebcamObservable(): Observable<any> {
    return this.nextWebcam.asObservable();
  }

  generatePassword(length: number): string {
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()';
    let password = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      password += characters[randomIndex];
    }
    return password;
  }

  formSubmit() {
    let fullName = this.doctorFrm.value['FullName'];
    let dob = this.doctorFrm.value['Dob'];
    let email = this.doctorFrm.value['Email'];
    let startDate = this.doctorFrm.value['StartDate'];
    let phoneNumber = this.doctorFrm.value['PhoneNumber'];
    let nationalId = this.doctorFrm.value['NationalId'];
    let address = this.doctorFrm.value['Address'];
    let status = this.doctorFrm.value['Status'];
    let password = this.doctorFrm.value['Password'];
    let departmentId = this.doctorFrm.value['DepartmentId'];
    let dptName = this.dptList.filter(
      (it) => it.DepartmentId == departmentId
    )[0].DepartmentName;

    // let userBody = {
    //   Email: email,
    //   Password: password,
    //   UserName: this.hospitalName + email,
    //   PhoneNumber: phoneNumber,
    // };
    // this.userService.registerBillingStaff(userBody).subscribe(
    //   (res) => {
    let body = {
      FullName: fullName,
      Dob: dob,
      Email: email,
      PhoneNumber: phoneNumber,
      NationalId: nationalId,
      Address: address,
      Status: status,
      StartDate: startDate,
      DepartmentName: dptName,
      DepartmentId: departmentId,
      Image:
        this.imageUrl && this.imageUrl.trim() !== ''
          ? this.imageUrl
          : this.existingImage,
    };

    this.billerService.updateBiller(this.billerId, body).subscribe((res) => {
      this.isSuccess = true;
      this.doctorFrm.reset();
      this.router.navigate(['hospitalDash']);
      setTimeout(() => {}, 2000);
    });
  }
  showModalWithMessage(message: string) {
    this.modalMessage = message;
    this.showModal = true;
  }

  closeModal() {
    this.showModal = false;
  }
  public countryCodes() {
    this.http.get('/assets/json/country-codes.json').subscribe({
      next: (res: any) => {
        this.Codes = res.map((x) => x.dial_code);
      },
    });
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.Codes.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }
}
