import { Component } from '@angular/core';

@Component({
  selector: 'app-medical-assistant-dash-board',
  templateUrl: './medical-assistant-dash-board.component.html',
  styleUrls: ['./medical-assistant-dash-board.component.css']
})
export class MedicalAssistantDashBoardComponent {

}
