import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { EmailService } from 'src/app/services/email.service';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.css'],
})
export class EmailComponent implements OnInit {
  isSuccess = false;
  selectedFile: File | null = null;

  constructor(
    private fb: FormBuilder,
    private emailService: EmailService,
    private tokenService: TokenService
  ) {}

  ngOnInit(): void {}

  Frm = this.fb.group({
    Email: ['', Validators.required],
    Subject: ['', Validators.required],
    Template: ['', Validators.required],
  });

  get Email() {
    return this.Frm.get('Email');
  }

  get Subject() {
    return this.Frm.get('Subject');
  }

  get Template() {
    return this.Frm.get('Template');
  }

  onFileChange(event: any) {
    this.selectedFile = event.target.files[0];
  }

  formSubmit() {
    const formData = new FormData();

    // Add text fields
    formData.append('Email', this.Frm.value['Email']);
    formData.append('Subject', this.Frm.value['Subject']);
    formData.append('Template', this.Frm.value['Template']);

    // Add file if selected
    if (this.selectedFile) {
      formData.append('Attachment', this.selectedFile, this.selectedFile.name);
    }

    this.emailService.sendGenericEmail(formData).subscribe((res) => {
      this.Frm.reset();
      this.isSuccess = true;
    });
  }
}
