import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Allergy } from 'src/app/Models/allergy.models';
import { Patient } from 'src/app/Models/patient.model';
import { PatientVisit } from 'src/app/Models/patientvisit.model';
import { PatientService } from 'src/app/services/patient.service';
import { PatientvisitService } from 'src/app/services/patientvisit.service';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-patient-tab-appearance',
  templateUrl: './patient-tab-appearance.component.html',
  styleUrls: ['./patient-tab-appearance.component.css'],
})
export class PatientTabAppearanceComponent implements OnInit {
  id = 0;
  patient: Patient;
  patientVisitList: PatientVisit[] = [];
  isAdmitted = false;
  filteredList;
  pvId = 0;
  patientVisitId: PatientVisit[];
  showItem = '';

  isResizing = false;
  leftPanelWidth: number = 50; // Default width 50%
  rightPanelWidth: number = 50; // Default width 50%
  patientAllergies: Allergy[];
  constructor(
    private patientService: PatientService,
    private route: ActivatedRoute,
    private router: Router,
    private ptVisitService: PatientvisitService,
    private tokenService: TokenService
  ) {
    this.start();
  }

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    this.patientService.getPatientvisits(this.id).subscribe((res) => {
      this.patient = res;

      this.filteredList = res.PatientVisits;
      this.patientVisitList = res.PatientVisits;

      //  if(res.PatientVisits.filter(x=>x.VisitType=="In Patient")){
      //   this.isAdmitted
      //  }
    });
    // this.getAllergies(this.id);
  }
  // getAllergies(id) {
  //   this.patientService.getPatientAllergy(id).subscribe((res) => {
  //     this.patientAllergies = res.Allergies;
  //   });
  // }
  printPage() {
    window.print();
  }

  search(searchValue) {
    // console.log(searchValue);
    if (searchValue.value != null) {
      this.filteredList = this.patientVisitList.filter((x) =>
        x.VistitDate.toLowerCase().includes(searchValue.value.toLowerCase())
      );
    }
  }

  deleteVisit(id) {
    let C_confirm = confirm('Are you Sure you want to delete ?');
    if (C_confirm == true) {
      this.ptVisitService.deletePatientVisit(id).subscribe((res) => {
        this.filteredList = this.patientVisitList.filter(
          (x) => x.PatientVisitId != id
        );
      });
    }
  }

  tabs: string[] = [
    'Completed Forms',
    'Patient Summary',
    'Appointments',
    'Invoices',
    'Payment History',
    'Insurances',
    'Id Proof',
    'Emergency Contact',
    'Pharmacy',
    'Allergies',
    'Surgeries',
    'Refferal',
    'Lab Orders',
    'Lab Reports',
    'Radiology Orders',
    'Radiology Reports',
    'Procedure Results',
    'Nerve Study Results',
    'EMG Test Results',
    'Vaccine',
    'Prescriptions',
    'Visit Summary',
    'Progress Notes',
    'Past medication',
    'Pain Chart',
  ];
  activatedTabIndex: number = 0;

  tabChange(tabIndex: number) {
    // debugger;
    this.activatedTabIndex = tabIndex;
  }
  viewMode = 'tab';

  tabs2: string[] = [
    'New Appointment',
    'New Forms',
    'New Invoice',
    'New Insurance',
    'New Id Proof',
    'New Emergency Contact',
    'New Pharmacy',
    'New History',
    // 'New Refferal',
    'Upload Lab Report',
    'Upload Radiology Report',
    'New Pain Chart',
  ];
  activatedTabIndex2: number = 0;

  tabChange2(tabIndex2: number) {
    // debugger;
    this.activatedTabIndex2 = tabIndex2;
  }

  logout() {
    // Clear local session data
    localStorage.removeItem('token');
    // Set a flag to notify other tabs/windows
    localStorage.setItem('logoutEvent', 'logout' + Math.random());
    // Redirect to login page
    this.router.navigate(['/']);
  }

  // Bind the eventListener
  private start(): void {
    window.addEventListener('storage', this.storageEventListener.bind(this));
  }

  // Logout only when key is 'logout-event'
  private storageEventListener(event: StorageEvent) {
    if (event.storageArea == localStorage) {
      if (event?.key && event.key == 'logout-event') {
        console.log('🔥 ~ storageEventListener ~ event', event.newValue);
        this.logout();
      }
    }
  }

  // Handle active listeners when onDestroy
  private stop(): void {
    window.removeEventListener('storage', this.storageEventListener.bind(this));
  }

  ngOnDestroy() {
    this.stop();
  }

  currentView: string = 'home';

  // You can add logic to switch the view dynamically
  changeView(view: string): void {
    this.currentView = view;
  }

  // Start resizing when the mouse is pressed on the resizer
  startResize(event: MouseEvent): void {
    this.isResizing = true;
    document.body.style.cursor = 'ew-resize'; // Change cursor to resizing
    event.preventDefault(); // Prevent text selection while resizing

    // Store the initial positions and panel widths
    const initialLeftPanelWidth = this.leftPanelWidth;
    const initialMouseX = event.clientX;

    // Listen to mousemove events to resize the panels
    const onMouseMove = (moveEvent: MouseEvent) => {
      if (this.isResizing) {
        const deltaX = moveEvent.clientX - initialMouseX; // Calculate mouse movement
        const newLeftPanelWidth =
          initialLeftPanelWidth + (deltaX / window.innerWidth) * 100; // Update width for the left panel

        // Ensure the new panel width is between 10% and 90%
        this.leftPanelWidth = Math.min(Math.max(newLeftPanelWidth, 10), 90);
        this.rightPanelWidth = 100 - this.leftPanelWidth; // Adjust right panel width accordingly

        // Save the panel sizes to localStorage
        localStorage.setItem('leftPanelWidth', this.leftPanelWidth.toString());
        localStorage.setItem(
          'rightPanelWidth',
          this.rightPanelWidth.toString()
        );
      }
    };

    // Stop resizing when mouse button is released
    const onMouseUp = () => {
      this.isResizing = false;
      document.body.style.cursor = 'default';
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    };

    // Attach event listeners
    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
  }

  @HostListener('window:resize')
  onResize(): void {
    // Ensure the panel widths adjust if the window is resized
    this.leftPanelWidth = Math.min(Math.max(this.leftPanelWidth, 10), 90);
    this.rightPanelWidth = 100 - this.leftPanelWidth;
  }
}
