<div class="container mat-elevation-z8">
    <h2>Add Claim Account Key</h2>
    <form [formGroup]="claimAccountKeyForm" (ngSubmit)="onSubmit()">
        <mat-form-field appearance="fill" class="full-width">
            <mat-label>Account Key</mat-label>
            <input matInput id="AccountKey" formControlName="AccountKey" />
            <mat-error *ngIf="claimAccountKeyForm.get('AccountKey')?.invalid">
                Account Key is required.
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" class="full-width">
            <mat-label>Status</mat-label>
            <mat-select id="Status" formControlName="Status">
                <mat-option value="Active">Active</mat-option>
                <mat-option value="Inactive">Inactive</mat-option>
            </mat-select>
            <mat-error *ngIf="claimAccountKeyForm.get('Status')?.invalid">
                Status is required.
            </mat-error>
        </mat-form-field>

        <!-- <mat-form-field appearance="fill" class="full-width">
            <mat-label>Hospital ID</mat-label>
            <input matInput id="HospitalId" formControlName="HospitalId" type="number" />
            <mat-error *ngIf="claimAccountKeyForm.get('HospitalId')?.invalid">
                Hospital ID is required and must be a number.
            </mat-error>
        </mat-form-field> -->

        <div class="button-group">
            <button mat-raised-button color="primary" type="submit" [disabled]="claimAccountKeyForm.invalid">
                Add
            </button>
        </div>
    </form>
    <p *ngIf="successMessage" class="success mat-body-2">{{ successMessage }}</p>
    <p *ngIf="errorMessage" class="error mat-body-2">{{ errorMessage }}</p>
</div>