import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import {
  loadStripe,
  Stripe,
  StripeElements,
  StripeCardElement,
} from '@stripe/stripe-js';
import { SubscriptionPlan } from 'src/app/Models/subscriptionplan.model';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { TokenService } from 'src/app/services/token.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.css'],
})
export class SubscriptionComponent implements OnInit {
  id = 0;
  helper = new JwtHelperService();
  myToken = this.tokenService.getToken();
  plans: SubscriptionPlan[] = [];
  selectedPlanId: number | null = null;
  HospitalId: number | null = null;
  stripe: Stripe | null = null;
  elements: StripeElements | null = null;
  card: StripeCardElement | null = null;

  constructor(
    private subscriptionService: SubscriptionService,
    private tokenService: TokenService,
    private router: Router
  ) {}

  async ngOnInit(): Promise<void> {
    // Decode the token to retrieve the HospitalId
    const decodedToken = this.helper.decodeToken(this.myToken);
    this.id = parseInt(decodedToken.nameid);
    if (decodedToken.role === 'Hospital') {
      this.HospitalId = this.id;
    }

    // Initialize Stripe
    const publishableKey = environment.stripePublishableKey;
    this.stripe = await loadStripe(publishableKey);
    if (this.stripe) {
      this.elements = this.stripe.elements();
      this.card = this.elements.create('card');
      this.card.mount('#card-element');
    }

    // Load subscription plans
    this.loadPlans();
  }

  // Load subscription plans from the server
  loadPlans(): void {
    this.subscriptionService.getSubscriptionPlans().subscribe({
      next: (plans) => {
        this.plans = plans;
      },
      error: (err) => {
        console.error('Error loading plans:', err);
        alert('Failed to load subscription plans.');
      },
    });
  }

  // Handle payment and subscription activation
  async subscribeToPlan(): Promise<void> {
    if (!this.HospitalId || !this.selectedPlanId) {
      alert('Please select a subscription plan.');
      return;
    }

    if (!this.card || !this.stripe) {
      alert('Stripe is not initialized.');
      return;
    }

    const selectedPlan = this.plans.find(
      (plan) => plan.PlanId === this.selectedPlanId
    );
    if (!selectedPlan) {
      alert('Invalid plan selected.');
      return;
    }

    // Extract the duration from the selected plan
    const durationMonths = selectedPlan.DurationMonths;
    if (!durationMonths) {
      alert('Invalid subscription duration.');
      return;
    }

    // Create payment intent on the backend
    this.subscriptionService
      .createPaymentIntent(
        selectedPlan.Price,
        this.HospitalId,
        this.selectedPlanId
      )
      .subscribe({
        next: async (res) => {
          const clientSecret = res.clientSecret;

          const result = await this.stripe!.confirmCardPayment(clientSecret, {
            payment_method: {
              card: this.card!,
            },
          });

          if (result.error) {
            console.error('Payment failed:', result.error.message);
            alert('Payment failed. Please try again.');
          } else if (
            result.paymentIntent &&
            result.paymentIntent.status === 'succeeded'
          ) {
            alert('Payment successful! Activating subscription.');

            // Call subscribeFacility to activate the subscription
            this.subscriptionService
              .subscribeFacility(this.HospitalId!, {
                planId: this.selectedPlanId!,
                durationMonths: durationMonths,
              })
              .subscribe({
                next: () => {
                  alert('Subscription activated successfully!');
                  this.router.navigate(['/hospitalDash/']);
                },
                error: (err) => {
                  console.error('Error activating subscription:', err);
                  alert(
                    'Subscription activation failed. Please contact support.'
                  );
                },
              });
          }
        },
        error: (err) => {
          console.error('Error creating payment intent:', err);
          alert('Failed to initiate payment. Please try again.');
        },
      });
  }

  // Logout user
  logout(): void {
    localStorage.removeItem('token');
    this.router.navigate(['/']);
  }
}
