import { Component } from '@angular/core';

@Component({
  selector: 'app-update-cms1500',
  templateUrl: './update-cms1500.component.html',
  styleUrls: ['./update-cms1500.component.css']
})
export class UpdateCms1500Component {

}
