<div>
    <div>
        <app-tabs2-component [tabsArray]="tabs2" (onTabChange)="tabChange2($event)"></app-tabs2-component>
    </div>


    <div *ngIf="activatedTabIndex2 ==0">
        <!-- <app-add-doctor-report></app-add-doctor-report> -->
        <app-add-doctor-progress-note></app-add-doctor-progress-note>

    </div>
    <div *ngIf="activatedTabIndex2 ==1">

        <app-add-visit-summary></app-add-visit-summary>

    </div>

    <div *ngIf="activatedTabIndex2 ==2">
        <app-add-patient-visit></app-add-patient-visit>
    </div>
    <div *ngIf="activatedTabIndex2 ==3">
        <app-add-prescription></app-add-prescription>
    </div>
    <div *ngIf="activatedTabIndex2 ==4">
        <app-add-vitals-by-doctor></app-add-vitals-by-doctor>
    </div>

    <div *ngIf="activatedTabIndex2 ==5">
        <app-add-service-request></app-add-service-request>

        <!-- <app-new-orders></app-new-orders> -->
    </div>
    <div *ngIf="activatedTabIndex2 ==6">
        <app-add-doctor-initial-evaluation></app-add-doctor-initial-evaluation>
    </div>
    <div *ngIf="activatedTabIndex2 ==7">

        <app-draw-pad></app-draw-pad>

    </div>
    <div *ngIf="activatedTabIndex2 ==8">

        <app-add-refferal></app-add-refferal>

    </div>
    <div *ngIf="activatedTabIndex2 ==9">

        <app-add-invoice></app-add-invoice>

    </div>
    <div *ngIf="activatedTabIndex2 ==10">

        <app-add-appointment-by-doctor></app-add-appointment-by-doctor>

    </div>
    <div *ngIf="activatedTabIndex2 ==11">

        <app-new-test-results></app-new-test-results>

    </div>
    <div *ngIf="activatedTabIndex2 ==12">

        <app-add-radiology-report-peach-recogintion-enabled></app-add-radiology-report-peach-recogintion-enabled>
        <!-- <app-add-radiology-report></app-add-radiology-report> -->

    </div>
    <div *ngIf="activatedTabIndex2 ==13">

        <app-add-upload-lab-repor></app-add-upload-lab-repor>

    </div>
    <div *ngIf="activatedTabIndex2 ==14">

        <app-add-upload-radiology-report></app-add-upload-radiology-report>

    </div>
    <div *ngIf="activatedTabIndex2 ==15">

        <app-add-mri></app-add-mri>

    </div>
    <div *ngIf="activatedTabIndex2 ==16">

        <app-add-review-of-system></app-add-review-of-system>

    </div>
    <div *ngIf="activatedTabIndex2 ==17">

        <app-clinical-assistance></app-clinical-assistance>

    </div>

    <div *ngIf="activatedTabIndex2 ==18">

        <app-claim></app-claim>

    </div>
    <div *ngIf="activatedTabIndex2 ==19">

        <app-claim-attachment></app-claim-attachment>

    </div>

    <!-- <div *ngIf="activatedTabIndex2 ==14">

        <app-add-doctor-progress-note></app-add-doctor-progress-note>

    </div> -->
    <!-- <mat-tab-group>


        <mat-tab label="New Visit">
            <ng-template matTabContent>

                <app-add-patient-visit></app-add-patient-visit>

            </ng-template>
        </mat-tab>

        <mat-tab label="New Progress Note">
            <ng-template matTabContent>
                <app-add-doctor-report></app-add-doctor-report>
            </ng-template>
        </mat-tab>

        <mat-tab label="New Prescription">
            <ng-template matTabContent>

                <app-add-prescription></app-add-prescription>

            </ng-template>
        </mat-tab>
        <mat-tab label="New Orders">
            <ng-template matTabContent>
                <app-new-orders></app-new-orders>


            </ng-template>

        </mat-tab>
        <mat-tab label="New Initial Evaluation">
            <ng-template matTabContent>
                <app-add-doctor-initial-evaluation></app-add-doctor-initial-evaluation>


            </ng-template>

        </mat-tab>
        <mat-tab label="New Rounds Book">
            <ng-template matTabContent>

                <app-draw-pad></app-draw-pad>

            </ng-template>
        </mat-tab>
        <mat-tab label="New Refferal">
            <ng-template matTabContent>
                <app-add-refferal></app-add-refferal>


            </ng-template>

        </mat-tab>
        <mat-tab label="New Invoice">
            <ng-template matTabContent>

                <app-add-invoice></app-add-invoice>

            </ng-template>

        </mat-tab>
        <mat-tab label="New Appointment">
            <ng-template matTabContent>

                <app-add-appointment-by-doctor></app-add-appointment-by-doctor>

            </ng-template>

        </mat-tab>
        <mat-tab label="New Test Reults">
            <ng-template matTabContent>

                <app-new-test-results></app-new-test-results>

            </ng-template>

        </mat-tab>
        <mat-tab label="New Study Report">
            <ng-template matTabContent>
                <app-add-radiology-report></app-add-radiology-report>


            </ng-template>

        </mat-tab>
        <mat-tab label="New Study Image">
            <ng-template matTabContent>

                <app-add-mri></app-add-mri>

            </ng-template>

        </mat-tab>

    </mat-tab-group> -->


</div>