import { Component } from '@angular/core';

@Component({
  selector: 'app-view-cms1500',
  templateUrl: './view-cms1500.component.html',
  styleUrls: ['./view-cms1500.component.css']
})
export class ViewCms1500Component {

}
