<h2 mat-dialog-title>Items</h2>
<div mat-dialog-content cdkDrag cdkDragRootElement=".cdk-overlay-pane">
    <table class="table">
        <thead>
            <tr>
                <th>Name</th>
                <th>Code</th>
                <th>Comments</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of data.items">
                <td>{{ item.Name }}</td>
                <td>{{ item.Code }}</td>
                <td>{{ item.Comments }}</td>
            </tr>
        </tbody>
    </table>

</div>
<div mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="close()">Close</button>
</div>