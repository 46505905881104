import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Department } from 'src/app/Models/department.model';
import { Invoice } from 'src/app/Models/invoice.model';
import { Patient } from 'src/app/Models/patient.model';
import { BillerService } from 'src/app/services/biller.service';
import { DepartmentService } from 'src/app/services/department.service';
import { FrontdeskService } from 'src/app/services/frontdesk.service';
import { HospitalService } from 'src/app/services/hospital.service';
import { PatientService } from 'src/app/services/patient.service';
import { TokenService } from 'src/app/services/token.service';
import { AddAppointmentHospitalComponent } from '../add-appointment-hospital/add-appointment-hospital.component';
import { AddAppointmentByFrontDeskStaffComponent } from '../add-appointment-by-front-desk-staff/add-appointment-by-front-desk-staff.component';

@Component({
  selector: 'app-front-desk-dash-board',
  templateUrl: './front-desk-dash-board.component.html',
  styleUrls: ['./front-desk-dash-board.component.css'],
})
export class FrontDeskDashBoardComponent implements OnInit {
  displayedColumns: string[] = [
    'Id',
    'FirstName',
    'LastName',
    'Gender',
    'PatientNationalId',
    'Email',
    'PhoneNumber',
    'PrimaryDoctor',
    'DoctorName',
    'WardName',
    'Appointment',
    'View',
    'Edit',
    'Delete',
  ];
  userList: Patient[] = [];
  id = 0;
  patientList: Patient[] = [];
  filteredList;
  dptList: Department;
  dataSource: MatTableDataSource<Patient>;
  helper = new JwtHelperService();

  myToken = this.tokenService.getToken();

  showItem = '';
  hospitalId: number;
  noDataMessage: string;

  constructor(
    private patientService: PatientService,
    private dptService: DepartmentService,
    private route: ActivatedRoute,
    private router: Router,
    private tokenService: TokenService,
    private frontDeskService: FrontdeskService,
    private hospitalService: HospitalService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.id = parseInt(decodedToken.nameid);

    this.frontDeskService
      .getFrontDeskStaffHospital(this.id)
      .subscribe((res) => {
        this.hospitalId = res.Hospital.HospitalId;
        // this.getPatients(this.hospitalId);
        this.getPatients(this.hospitalId);
      });
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  getPatients(id) {
    this.hospitalService.getPatients(id).subscribe((res) => {
      this.dataSource = new MatTableDataSource(res.Patients);
      this.dataSource.paginator = this.paginator;
    });
  }

  search(searchValue) {
    // console.log(searchValue);
    if (searchValue.value != null) {
      this.filteredList = this.patientList.filter((x) =>
        x.PatientNationalId.toLowerCase().includes(
          searchValue.value.toLowerCase()
        )
      );
    }
  }
  dialogRefs: MatDialogRef<any>[] = [];
  openAddAppointmentModal(userId, firstName: string, lastName: string): void {
    const dialogRef = this.dialog.open(
      AddAppointmentByFrontDeskStaffComponent,
      {
        width: '680px', // You can set the width or other configurations here
        data: { userId: userId, userFname: firstName, userLname: lastName },
        disableClose: true,
        hasBackdrop: false,
      }
    );

    this.dialogRefs.push(dialogRef); // Keep track of the opened dialogs

    dialogRef.afterClosed().subscribe(() => {
      // Remove from the dialogRefs list once closed
      this.dialogRefs = this.dialogRefs.filter((ref) => ref !== dialogRef);
    });
  }

  closeAllDialogs(): void {
    this.dialogRefs.forEach((ref) => ref.close());
    this.dialogRefs = [];
  }
  logout() {
    localStorage.removeItem('token');
    this.router.navigate(['/']);
  }
}
