import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Doctor } from 'src/app/Models/doctor.model';
import { Nurse } from 'src/app/Models/nurse.model';
import { Patient } from 'src/app/Models/patient.model';
import { DoctorService } from 'src/app/services/doctor.service';
import { NurseService } from 'src/app/services/nurse.service';
import { PatientService } from 'src/app/services/patient.service';
import { ReviewOfSystemService } from 'src/app/services/review-of-system.service';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-update-review-of-system',
  templateUrl: './update-review-of-system.component.html',
  styleUrls: ['./update-review-of-system.component.css'],
})
export class UpdateReviewOfSystemComponent implements OnInit {
  // reviewOfSystemForm: FormGroup;
  submitted = false;
  helper = new JwtHelperService();

  myToken = this.tokenService.getToken();

  id = 0;

  doctorId = 0;
  nurseId = 0;
  isSuccess = false;
  Frm: FormGroup;
  doctor: Doctor;
  patient: Patient;
  nurse: Nurse;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { ReviewOfSystemId: number },
    public dialogRef: MatDialogRef<UpdateReviewOfSystemComponent>,
    private fb: FormBuilder,
    private reviewofSystemsService: ReviewOfSystemService,
    private doctorService: DoctorService,
    private nurseService: NurseService,
    private patientService: PatientService,
    private route: ActivatedRoute,
    private tokenService: TokenService
  ) {}

  ngOnInit(): void {
    // Initialize the form first

    // Then fetch data and patch values
    this.id = this.data.ReviewOfSystemId;
    this.reviewofSystemsService
      .getReviewOfSystemById(this.id)
      .subscribe((res) => {
        console.log(res);
        this.reviewOfSystemForm.patchValue(res); // Populate form with fetched data
      });

    // Decode token and retrieve doctor information
    const decodedToken = this.helper.decodeToken(this.myToken);
    this.doctorId = parseInt(decodedToken.nameid);
    this.getDoctor(this.doctorId);
  }
  reviewOfSystemForm = this.fb.group({
    DateOfSymtomsBegan: [''],
    Chills: [false],
    Fatigue: [false],
    Fever: [false],
    WeightGain: [false],
    WeightLoss: [false],
    HearingLoss: [false],
    SinusPressure: [false],
    VisualChanges: [false],
    Cough: [false],
    ShortnessOfBreath: [false],
    Wheezing: [false],
    ChestPain: [false],
    Claudication: [false],
    Edema: [false],
    Palpitations: [false],
    AbdominalPain: [false],
    BloodInStool: [false],
    Constipation: [false],
    Diarrhea: [false],
    Heartburn: [false],
    LossOfAppetite: [false],
    Nausea: [false],
    Vomiting: [false],
    Dysuria: [false],
    Polyuria: [false],
    UrinaryFrequency: [false],
    ColdIntolerance: [false],
    HeatIntolerance: [false],
    Polydipsia: [false],
    Polyphagia: [false],
    Dizziness: [false],
    ExtremityNumbness: [false],
    ExtremityWeakness: [false],
    Headaches: [false],
    Seizures: [false],
    Tremors: [false],
    Anxiety: [false],
    Depression: [false],
    BreastDischarge: [false],
    BreastLump: [false],
    Hives: [false],
    MoleChanges: [false],
    Rash: [false],
    SkinLesion: [false],
    BackPain: [false],
    JointPain: [false],
    JointSwelling: [false],
    NeckPain: [false],
    EasilyBleeds: [false],
    EasilyBruises: [false],
    Lymphedema: [false],
    BloodClots: [false],
    FoodAllergies: [false],
    SeasonalAllergies: [false],
    NoneApply: [false],
    Vision: [false],
    Thinners: [false],
  });
  get DateOfSymtomsBegan() {
    return this.reviewOfSystemForm.get('DateOfSymtomsBegan');
  }
  get Chills() {
    return this.reviewOfSystemForm.get('Chills');
  }
  get Fatigue() {
    return this.reviewOfSystemForm.get('Fatigue');
  }
  get Fever() {
    return this.reviewOfSystemForm.get('Fever');
  }
  get WeightGain() {
    return this.reviewOfSystemForm.get('WeightGain');
  }
  get WeightLoss() {
    return this.reviewOfSystemForm.get('WeightLoss');
  }
  get HearingLoss() {
    return this.reviewOfSystemForm.get('HearingLoss');
  }
  get SinusPressure() {
    return this.reviewOfSystemForm.get('SinusPressure');
  }
  get VisualChanges() {
    return this.reviewOfSystemForm.get('VisualChanges');
  }
  get Cough() {
    return this.reviewOfSystemForm.get('Cough');
  }
  get ShortnessOfBreath() {
    return this.reviewOfSystemForm.get('ShortnessOfBreath');
  }
  get Wheezing() {
    return this.reviewOfSystemForm.get('Wheezing');
  }
  get ChestPain() {
    return this.reviewOfSystemForm.get('ChestPain');
  }
  get Claudication() {
    return this.reviewOfSystemForm.get('Claudication');
  }
  get Edema() {
    return this.reviewOfSystemForm.get('Edema');
  }
  get Palpitations() {
    return this.reviewOfSystemForm.get('Palpitations');
  }
  get AbdominalPain() {
    return this.reviewOfSystemForm.get('AbdominalPain');
  }
  get BloodInStool() {
    return this.reviewOfSystemForm.get('BloodInStool');
  }
  get Constipation() {
    return this.reviewOfSystemForm.get('Constipation');
  }
  get Diarrhea() {
    return this.reviewOfSystemForm.get('Diarrhea');
  }
  get Heartburn() {
    return this.reviewOfSystemForm.get('Heartburn');
  }
  get LossOfAppetite() {
    return this.reviewOfSystemForm.get('LossOfAppetite');
  }
  get Nausea() {
    return this.reviewOfSystemForm.get('Nausea');
  }
  get Vomiting() {
    return this.reviewOfSystemForm.get('Vomiting');
  }
  get Dysuria() {
    return this.reviewOfSystemForm.get('Dysuria');
  }
  get Polyuria() {
    return this.reviewOfSystemForm.get('Polyuria');
  }
  get UrinaryFrequency() {
    return this.reviewOfSystemForm.get('UrinaryFrequency');
  }
  get ColdIntolerance() {
    return this.reviewOfSystemForm.get('ColdIntolerance');
  }
  get HeatIntolerance() {
    return this.reviewOfSystemForm.get('HeatIntolerance');
  }
  get Polydipsia() {
    return this.reviewOfSystemForm.get('Polydipsia');
  }
  get Polyphagia() {
    return this.reviewOfSystemForm.get('Polyphagia');
  }
  get Dizziness() {
    return this.reviewOfSystemForm.get('Dizziness');
  }
  get ExtremityNumbness() {
    return this.reviewOfSystemForm.get('ExtremityNumbness');
  }
  get ExtremityWeakness() {
    return this.reviewOfSystemForm.get('ExtremityWeakness');
  }
  get Headaches() {
    return this.reviewOfSystemForm.get('Headaches');
  }
  get Seizures() {
    return this.reviewOfSystemForm.get('Seizures');
  }
  get Tremors() {
    return this.reviewOfSystemForm.get('Tremors');
  }
  get Anxiety() {
    return this.reviewOfSystemForm.get('Anxiety');
  }
  get Depression() {
    return this.reviewOfSystemForm.get('Depression');
  }
  get BreastDischarge() {
    return this.reviewOfSystemForm.get('BreastDischarge');
  }
  get BreastLump() {
    return this.reviewOfSystemForm.get('BreastLump');
  }
  get Hives() {
    return this.reviewOfSystemForm.get('Hives');
  }
  get MoleChanges() {
    return this.reviewOfSystemForm.get('MoleChanges');
  }
  get Rash() {
    return this.reviewOfSystemForm.get('Rash');
  }
  get SkinLesion() {
    return this.reviewOfSystemForm.get('SkinLesion');
  }
  get BackPain() {
    return this.reviewOfSystemForm.get('BackPain');
  }
  get JointPain() {
    return this.reviewOfSystemForm.get('JointPain');
  }
  get JointSwelling() {
    return this.reviewOfSystemForm.get('JointSwelling');
  }
  get NeckPain() {
    return this.reviewOfSystemForm.get('NeckPain');
  }
  get EasilyBleeds() {
    return this.reviewOfSystemForm.get('EasilyBleeds');
  }
  get EasilyBruises() {
    return this.reviewOfSystemForm.get('EasilyBruises');
  }
  get Lymphedema() {
    return this.reviewOfSystemForm.get('Lymphedema');
  }
  get BloodClots() {
    return this.reviewOfSystemForm.get('BloodClots');
  }
  get FoodAllergies() {
    return this.reviewOfSystemForm.get('FoodAllergies');
  }
  get SeasonalAllergies() {
    return this.reviewOfSystemForm.get('SeasonalAllergies');
  }
  get NoneApply() {
    return this.reviewOfSystemForm.get('NoneApply');
  }
  get Vision() {
    return this.reviewOfSystemForm.get('Vision');
  }
  get Thinners() {
    return this.reviewOfSystemForm.get('Thinners');
  }

  getDoctor(id) {
    this.doctorService.getDoctorById(id).subscribe((res) => {
      this.doctor = res;
    });
  }

  getPatient(id) {
    this.patientService.getPatientById(id).subscribe((res) => {
      this.patient = res;
    });
  }

  onSubmit() {
    let body = {
      ...this.reviewOfSystemForm.value,
      PatientId: this.id,
      DoctorName: this.doctor.DoctorName,
      DoctorId: this.doctorId,
    };
    this.reviewofSystemsService
      .updateReviewOfSystem(this.id, body)
      .subscribe((res) => {
        this.isSuccess = true;

        alert('Successfully Updated ');
        this.onClose();
      });
  }
  onClose(): void {
    this.dialogRef.close();
  }
}
