import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ServiceRequest } from '../Models/servicerequest.model';
import { EmailPayload } from '../Models/email.model';

@Injectable({
  providedIn: 'root',
})
export class ServiceRequestService {
  apiUrl = environment.Url + 'ServiceRequest';
  emailRefferal = environment.Url + 'Emails/refferalEmail';

  constructor(private http: HttpClient) {}

  getAllServiceRequests(): Observable<ServiceRequest[]> {
    return this.http.get<ServiceRequest[]>(this.apiUrl);
  }

  getServiceRequestById(id: number): Observable<ServiceRequest> {
    return this.http.get<ServiceRequest>(`${this.apiUrl}/${id}`);
  }

  createServiceRequest(
    serviceRequest: ServiceRequest
  ): Observable<ServiceRequest> {
    return this.http.post<ServiceRequest>(this.apiUrl, serviceRequest);
  }

  updateServiceRequest(id, serviceRequest): Observable<ServiceRequest> {
    return this.http.put<ServiceRequest>(
      `${this.apiUrl}/${id}`,
      serviceRequest
    );
  }

  deleteServiceRequest(id: number): Observable<ServiceRequest> {
    return this.http.delete<ServiceRequest>(`${this.apiUrl}/${id}`);
  }

  sendLabOroder(body): Observable<EmailPayload> {
    return this.http
      .post<EmailPayload>(this.emailRefferal, body)
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      if (error.status == 400) {
        console.log(error.error);
        return throwError(error.error);
      } else if (error.status == 401) {
        return throwError('invalid username or password');
      } else if (error.status == 0) {
        return throwError('network failure please try again later ');
      }
      return throwError(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }
}
