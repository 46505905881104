<div class="dialog-header">
    <button mat-raised-button color="primary" class="close-button" (click)="onClose()">Close</button>
</div>
<div class="container" cdkDrag cdkDragRootElement=".cdk-overlay-pane">
    <form [formGroup]="paymentForm" (ngSubmit)="createStripeToken()">

        <div class="row">
            <div class="col-lg-8">

                <mat-form-field>
                    <input class="mx-3" matInput formControlName="amount" placeholder="Amount" type="number" required>
                </mat-form-field>


                <mat-form-field>
                    <input class="mx-3" matInput formControlName="description" placeholder="Description" required>
                </mat-form-field>


                <div>
                    <stripe-card #stripeCard [(invalid)]="invalidError"></stripe-card>
                </div>
                <p *ngIf="invalidError" class="error">{{ invalidError }}</p>

                <button mat-raised-button class="mt-3 float-right" color="primary"
                    [disabled]="!paymentForm.valid">Pay</button>
            </div>
        </div>
    </form>

</div>