<div class="mat-elevation-z8">
    <table mat-table [dataSource]="studyResult" matSort class="w-100">
        <!-- Date Column -->
        <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
            <td mat-cell *matCellDef="let item">{{ item.createdDate | date: 'short' }}</td>
        </ng-container>

        <!-- Study Type Column -->
        <ng-container matColumnDef="studyType">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Study Type</th>
            <td mat-cell *matCellDef="let item">{{ item.StudyType }}</td>
        </ng-container>

        <!-- Sub Type Column -->
        <ng-container matColumnDef="studySubType">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Sub Type</th>
            <td mat-cell *matCellDef="let item">{{ item.StudySubType }}</td>
        </ng-container>

        <!-- Nerve/Sites Column -->
        <ng-container matColumnDef="nerveSite">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Nerve/Sites</th>
            <td mat-cell *matCellDef="let item">{{ item.NerveSite }}</td>
        </ng-container>

        <!-- Rec. Site Column -->
        <ng-container matColumnDef="recSite">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Rec. Site</th>
            <td mat-cell *matCellDef="let item">{{ item.RecSite }}</td>
        </ng-container>

        <!-- Latency Column -->
        <ng-container matColumnDef="latencyMs">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Latency (ms)</th>
            <td mat-cell *matCellDef="let item">{{ item.LatencyMs }}</td>
        </ng-container>

        <!-- Norm (ms) Column -->
        <ng-container matColumnDef="latencyNorm">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Norm (ms)</th>
            <td mat-cell *matCellDef="let item">{{ item.LatencyNorm }}</td>
        </ng-container>

        <!-- Amplitude (mV) Column -->
        <ng-container matColumnDef="amplitudeMv">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Amplitude (mV)</th>
            <td mat-cell *matCellDef="let item">{{ item.AmplitudeMv }}</td>
        </ng-container>

        <!-- Norm Amplitude (ms) Column -->
        <ng-container matColumnDef="amplitudeNorm">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Norm Amplitude (ms)</th>
            <td mat-cell *matCellDef="let item">{{ item.AmplitudeNorm }}</td>
        </ng-container>

        <!-- Amplitude Ratio (%) Column -->
        <ng-container matColumnDef="amplitudeRatio">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Amplitude Ratio (%)</th>
            <td mat-cell *matCellDef="let item">{{ item.AmplitudeRatio }}</td>
        </ng-container>

        <!-- Duration (ms) Column -->
        <ng-container matColumnDef="durationMs">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Duration (ms)</th>
            <td mat-cell *matCellDef="let item">{{ item.DurationMs }}</td>
        </ng-container>

        <!-- Segment Column -->
        <ng-container matColumnDef="segment">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Segment</th>
            <td mat-cell *matCellDef="let item">{{ item.Segment }}</td>
        </ng-container>

        <!-- Distance (cm) Column -->
        <ng-container matColumnDef="distance">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Distance (cm)</th>
            <td mat-cell *matCellDef="let item">{{ item.Distance }}</td>
        </ng-container>

        <!-- Velocity (m/s) Column -->
        <ng-container matColumnDef="velocityMs">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Velocity (m/s)</th>
            <td mat-cell *matCellDef="let item">{{ item.VelocityMs }}</td>
        </ng-container>

        <!-- Norm Velocity (m/s) Column -->
        <ng-container matColumnDef="velocityNorm">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Norm Velocity (m/s)</th>
            <td mat-cell *matCellDef="let item">{{ item.VelocityNorm }}</td>
        </ng-container>

        <!-- Comments Column -->
        <ng-container matColumnDef="comments">
            <th mat-header-cell *matHeaderCellDef>Comments</th>
            <td mat-cell *matCellDef="let item">{{ item.Comments }}</td>
        </ng-container>

        <ng-container matColumnDef="update">
            <th mat-header-cell *matHeaderCellDef>Update</th>
            <td mat-cell *matCellDef="let item">
                <button mat-icon-button [routerLink]="['/updateNerveStudyResults/', item.NerveStudyResultId]"
                    *ngIf="CurrentDoctorId === item.DoctorId" color="primary" aria-label="Edit">
                    <mat-icon>edit</mat-icon>
                </button>
            </td>
        </ng-container>

        <!-- Delete Button Column (conditionally shown) -->
        <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef>Delete</th>
            <td mat-cell *matCellDef="let item">
                <button mat-icon-button (click)="delete(item.NerveStudyResultId)"
                    *ngIf="CurrentDoctorId === item.DoctorId" color="warn" aria-label="Delete">
                    <mat-icon>delete</mat-icon>
                </button>
            </td>
        </ng-container>

        <!-- Header and Row Definitions -->
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <!-- Pagination -->
    <mat-paginator [pageSize]="5" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
</div>