<mat-tab-group (selectedTabChange)="tabChanged($event)">

    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">view_quilt</mat-icon>
            ICD 10 Diagnosis Code
        </ng-template>


        <div class="ml-3">
            <div class="row">
                <div class="col-lg-6 float-right">
                    <mat-form-field class="example-full-width mt-5">
                        <mat-label>Filter</mat-label>
                        <input matInput (keyup)="applyFilter($event)" placeholder="Ex.Mia" #input>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <button mat-raised-button color="primary" (click)="selectedCode()">Add Selected Codes To Favorite</button>
        <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
            Successfully Added to favorite
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class=" mat-elevation-z8">
            <!-- <h4>Upload JSON file only ,Please combine code , description and on Description colum enter "null"</h4>
            <div class="d-flex justify-content-end m-2">
                <form (keydown.enter)="$event.preventDefault()" (ngSubmit)="uploadFile()">
                    <input type="file" class="formControl" (change)=" onFileSelected($event)" accept="application/json">
                    <button type="submit btn btn-warning">Upload ICD 10 Codes</button>
                </form>

            </div> -->
            <table mat-table [dataSource]="dataSource" matSort>

                <ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Select </th>
                    <td mat-cell *matCellDef="let row"> <mat-checkbox class="example-margin"
                            (change)="selectCode($event, row)"></mat-checkbox> </td>
                </ng-container>
                <ng-container matColumnDef="Code">
                    <th class="message" mat-header-cell mat-sort-header *matHeaderCellDef>Code </th>
                    <td class="message" mat-cell *matCellDef="let row"> {{row.Code}} </td>
                </ng-container>


                <ng-container matColumnDef="Description">
                    <th class="message" mat-header-cell *matHeaderCellDef> Description </th>
                    <td class="message" mat-cell *matCellDef="let row"> {{row.Description}} </td>
                </ng-container>

                <!-- <ng-container matColumnDef="Update">
        <th mat-header-cell *matHeaderCellDef> Edit </th>
        <td mat-cell *matCellDef="let element">
          <button type="button" [routerLink]="['/editCptCode/',element.CptCodeId]" mat-mini-fab color="primary">
            <mat-icon matPrefix>edit</mat-icon>
          </button>
        </td>
      </ng-container> -->
                <ng-container matColumnDef="Delete">
                    <th mat-header-cell *matHeaderCellDef> Delete</th>
                    <td mat-cell *matCellDef="let element">
                        <button (click)="delete(element.CptCodeId)" mat-mini-fab
                            color="accent"><mat-icon>delete</mat-icon></button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
                </tr>
            </table>

            <mat-paginator [length]="totalItems" [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 20]"
                (page)="onPageChange($event)">
            </mat-paginator>
        </div>

    </mat-tab>

    <mat-tab label="Favorites">
        <div *ngFor="let favorite of favoriteDignosisCodes; let i = index">
            <mat-checkbox (change)="onFavoriteSelect(favorite, $event)">

                <ng-container *ngIf="!isEditing || editingIndex !== i">
                    {{ favorite.Code }} {{ favorite.Description }}
                </ng-container>
            </mat-checkbox>
            <button mat-button class="ml-3" color="primary" *ngIf="!isEditing || editingIndex !== i"
                (click)="editFavorite(favorite, i)">
                Edit
            </button>
            <button mat-button color="warn" *ngIf="!isEditing || editingIndex !== i"
                (click)="deleteFavorite(favorite.FavoriteDignosisCodeId, i)">
                Delete
            </button>
            <div *ngIf="isEditing && editingIndex === i">
                <button mat-button color="primary"
                    (click)="saveEditFavorite(favorite.FavoriteDignosisCodeId, favorite)">Save</button>
                <button mat-button (click)="cancelEdit()">Cancel</button>
            </div>
        </div>
    </mat-tab>

    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">add</mat-icon>
            Register ICD-10 Diagnosis Code
        </ng-template>
        <app-add-icd-code></app-add-icd-code>
    </mat-tab>


</mat-tab-group>