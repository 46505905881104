<div class="conatiner mx-2 my-5">
    <div class="row">
        <div class="col-md-6" *ngFor="let item of CommunicationList">
            <mat-card class="mat-elevation-z8 mt-3">
                <h2 class="clr">Communication Assessments</h2>
                <span>Nurse Name: {{item.NurseName}}</span>
                <p class="float-right">Date : {{item.createdDate | date: 'medium' }}</p>

                <hr>
                <mat-card-content>


                    <tr>
                        <th> Interpreter Required : </th>

                        <td>{{item.InterpreterRequired ?'Yes':'No'}}</td>
                    </tr>
                    <tr>
                        <th> Preffered Language : </th>
                        <td>{{item.PrefferedLanguage }}</td>

                    </tr>
                    <tr>
                        <th> Primary Language : </th>
                        <td>{{item.PrimaryLanguage }}</td>

                    </tr>

                    <tr>
                        <th> Primary Language : </th>
                        <td>{{item.PrimaryLanguage }}</td>

                    </tr>
                    <tr>
                        <th> AbleToRead : </th>
                        <td>{{item.AbleToRead ?'Yes':'No' }}</td>

                    </tr>

                    <tr>
                        <th> None : </th>
                        <td>{{item.None ?'Yes':'No' }}</td>

                    </tr>
                    <hr>
                    <mat-accordion>

                        <mat-expansion-panel hideToggle>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    Language Barriers
                                </mat-panel-title>

                            </mat-expansion-panel-header>
                            <tr>
                                <th> AgeRelated : </th>
                                <td>{{item.AgeRelated ?'Yes':'No' }}</td>

                            </tr>
                            <tr>
                                <th> Auditory : </th>
                                <td>{{item.Auditory ?'Yes':'No' }}</td>

                            </tr>
                            <tr>
                                <th> Cognitive : </th>
                                <td>{{item.Cognitive ?'Yes':'No' }}</td>

                            </tr>
                            <tr>
                                <th> Cultural : </th>
                                <td>{{item.Cultural ?'Yes':'No' }}</td>

                            </tr>
                            <tr>
                                <th> Emotional : </th>
                                <td>{{item.Emotional ?'Yes':'No' }}</td>

                            </tr>
                            <tr>
                                <th> EnergyLevel : </th>
                                <td>{{item.EnergyLevel ?'Yes':'No' }}</td>

                            </tr>
                            <tr>
                                <th> Financial : </th>
                                <td>{{item.Financial ?'Yes':'No' }}</td>

                            </tr>
                            <tr>
                                <th> Language : </th>
                                <td>{{item.Language ?'Yes':'No' }}</td>

                            </tr>
                            <tr>
                                <th> Motivation : </th>
                                <td>{{item.Motivation ?'Yes':'No' }}</td>

                            </tr>
                            <tr>
                                <th> Physical : </th>
                                <td>{{item.Physical ?'Yes':'No' }}</td>

                            </tr>
                            <tr>
                                <th> Reading Skills : </th>
                                <td>{{item.ReadingSkills ?'Yes':'No' }}</td>

                            </tr>
                            <tr>
                                <th> Religion : </th>
                                <td>{{item.Religion ?'Yes':'No' }}</td>

                            </tr>
                            <tr>
                                <th> Visual : </th>
                                <td>{{item.Visual ?'Yes':'No' }}</td>

                            </tr>
                            <tr>
                                <th> Instructions Refused : </th>
                                <td>{{item.InstructionsRefused ?'Yes':'No' }}</td>

                            </tr>
                        </mat-expansion-panel>
                        <hr>
                        <mat-expansion-panel hideToggle>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    Communiction Tools
                                </mat-panel-title>

                            </mat-expansion-panel-header>
                            <tr>
                                <th> Facial Expression : </th>
                                <td>{{item.FacialExpression ?'Yes':'No' }}</td>

                            </tr>
                            <tr>
                                <th> Letter Board : </th>
                                <td>{{item.LetterBoard ?'Yes':'No' }}</td>

                            </tr>
                            <tr>
                                <th> Pictire Board : </th>
                                <td>{{item.PictireBoard ?'Yes':'No' }}</td>

                            </tr>
                            <tr>
                                <th> Physical Gestures : </th>
                                <td>{{item.PhysicalGestures ?'Yes':'No' }}</td>

                            </tr>
                            <tr>
                                <th> Writing Tablet : </th>
                                <td>{{item.WritingTablet ?'Yes':'No' }}</td>

                            </tr>
                            <tr>
                                <th> Sign Language : </th>
                                <td>{{item.SignLanguage ?'Yes':'No' }}</td>

                            </tr>
                            <tr>
                                <th> Lip Reading : </th>
                                <td>{{item.LipReading ?'Yes':'No' }}</td>

                            </tr>
                            <tr>
                                <th> Interpretor : </th>
                                <td>{{item.Interpretor ?'Yes':'No' }}</td>

                            </tr>
                            <tr>
                                <th> Large Print Literature : </th>
                                <td>{{item.LargePrintLiterature ?'Yes':'No' }}</td>

                            </tr>
                            <tr>
                                <th> Braille : </th>
                                <td>{{item.Braille ?'Yes':'No' }}</td>

                            </tr>
                            <tr>
                                <th> Voice Prosthesis : </th>
                                <td>{{item.VoiceProsthesis ?'Yes':'No' }}</td>

                            </tr>
                            <tr>
                                <th> Speaking Valve : </th>
                                <td>{{item.SpeakingValve ?'Yes':'No' }}</td>

                            </tr>
                            <tr>
                                <th> ElectroLarinx : </th>
                                <td>{{item.ElectroLarinx ?'Yes':'No' }}</td>

                            </tr>
                            <tr>
                                <th> Amplifier : </th>
                                <td>{{item.Amplifier ?'Yes':'No' }}</td>

                            </tr>
                            <tr>
                                <th> Electronic Equipment : </th>
                                <td>{{item.ElectronicEquipment ?'Yes':'No' }}</td>

                            </tr>
                        </mat-expansion-panel>
                        <hr>
                        <mat-expansion-panel hideToggle>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    Communication Barriers
                                </mat-panel-title>

                            </mat-expansion-panel-header>
                            <tr>
                                <th> NoBarriers : </th>
                                <td>{{item.NoBarriers ?'Yes':'No' }}</td>

                            </tr>
                            <tr>
                                <th> Blind : </th>
                                <td>{{item.Blind ?'Yes':'No' }}</td>

                            </tr>
                            <tr>
                                <th> Deaf : </th>
                                <td>{{item.Deaf ?'Yes':'No' }}</td>

                            </tr>
                            <tr>
                                <th> Visual Barriers : </th>
                                <td>{{item.VisualBarriers ?'Yes':'No' }}</td>

                            </tr>
                            <tr>
                                <th> Health Literacy : </th>
                                <td>{{item.Blind ?'Yes':'No' }}</td>

                            </tr>
                            <tr>
                                <th> Cognitive Barriers : </th>
                                <td>{{item.CognitiveBarriers ?'Yes':'No' }}</td>

                            </tr>
                            <tr>
                                <th> Cultural Barriers : </th>
                                <td>{{item.CulturalBarriers ?'Yes':'No' }}</td>

                            </tr>
                            <tr>
                                <th> Motivation Barriers : </th>
                                <td>{{item.MotivationBarriers ?'Yes':'No' }}</td>

                            </tr>
                            <tr>
                                <th> Communication Comments : </th>
                                <td>{{item.CommunicationComments}}</td>

                            </tr>
                        </mat-expansion-panel>
                    </mat-accordion>
                    <hr>
                </mat-card-content>
                <button type="button" color="primary" mat-mini-fab
                    (click)="update(item.CommunicationAssessmentId)"><mat-icon>edit</mat-icon> </button>
                <!-- <button class="float-right"
                    [routerLink]="['/editCommunicationAssessment/',item.CommunicationAssessmentId]" color="primary"
                    mat-mini-fab><mat-icon>edit</mat-icon> </button> -->
                <button class="float-right ml-3" (click)="delete(item.CommunicationAssessmentId)" color="accent"
                    mat-mini-fab><mat-icon>delete</mat-icon> </button>
            </mat-card>
        </div>
    </div>
</div>