<div class="container">
    <div class="row">
        <div class="col-lg regFrm mat-elevation-z8">
            <h1>Blood Transfusion Consent Form</h1>
            <form (keydown.enter)="$event.preventDefault()" [formGroup]="Frm" (ngSubmit)="formSubmit()"
                class="example-form">
                <hr />

                <!-- Introduction Section -->
                <p>
                    You are about to receive a blood product transfusion as recommended by:
                </p>
                <mat-form-field class="example-full-width">
                    <mat-label>Doctor Name:</mat-label>
                    <input type="text" formControlName="DoctorName" matInput />
                    <mat-error *ngIf="Frm.get('DoctorName').invalid">Please enter the doctor's name</mat-error>
                </mat-form-field>
                <p>
                    The benefits of blood transfusions often outweigh the risks. Blood products are carefully matched to
                    your
                    blood type to minimize risks. However, with any transfusion, there is a small chance of short-term
                    or
                    long-term delayed reactions.
                </p>

                <!-- Risks Section -->
                <h4>Risks Involved When Receiving a Blood Product Transfusion</h4>
                <ul>
                    <li>Unexpected fever</li>
                    <li>Transfusion reaction, which may include kidney failure or anemia</li>
                    <li>Bleeding from the needle site</li>
                    <li>Pain in your back or chest</li>
                    <li>Weakness</li>
                    <li>Confusion</li>
                    <li>Rashes or itching</li>
                    <li>Other infections</li>
                </ul>

                <!-- Long-Term Symptoms Section -->
                <h4>Symptoms of Long-Term Delayed Reaction</h4>
                <ul>
                    <li>Unusual tiredness</li>
                    <li>Change in appetite</li>
                    <li>Unexplained weight loss</li>
                    <li>Dark urine</li>
                    <li>Yellow eyes or skin (jaundice)</li>
                    <li>Swollen glands</li>
                    <li>Heavy sweating at night</li>
                </ul>

                <!-- Actions Section -->
                <h4>What to Do If You Have Any of These Symptoms</h4>
                <ul>
                    <li>Notify the nurse or call your doctor immediately if symptoms occur at home.</li>
                    <li>Always contact the blood bank if you experience any short-term or long-term blood transfusion
                        reaction symptoms.</li>
                </ul>

                <!-- Consent Statement -->
                <p>
                    I have been given the opportunity to ask questions about my condition, the blood products I will
                    receive, and
                    the potential risks involved. This form has been fully explained to me. I have read the form or it
                    has been
                    read to me, and I understand its contents. I am satisfied with the explanations and voluntarily give
                    my
                    consent to receive blood products.
                </p>

                <!-- Consent Details -->
                <div class="row">
                    <div class="col-md-12">
                        <mat-form-field class="example-full-width">
                            <mat-label>Consent By:</mat-label>
                            <input type="text" formControlName="ConsentBy"
                                placeholder="Signature of Patient or Legally Responsible Party" matInput />
                            <mat-error *ngIf="Frm.get('ConsentBy').invalid">Please provide a signature</mat-error>
                        </mat-form-field>
                        <mat-form-field class="example-full-width">
                            <mat-label>Witness By:</mat-label>
                            <input type="text" formControlName="WitnessBy" placeholder="Signature of Witness"
                                matInput />
                            <mat-error *ngIf="Frm.get('WitnessBy').invalid">Please provide a witness
                                signature</mat-error>
                        </mat-form-field>
                        <mat-form-field class="example-full-width">
                            <mat-label>Language Line Assistance Confirmation Number:</mat-label>
                            <input type="text" formControlName="LanguageLineAssistanceConfirmationNumber" matInput />
                            <mat-error *ngIf="Frm.get('LanguageLineAssistanceConfirmationNumber').invalid">
                                Please provide a confirmation number
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <!-- Submit Button -->
                <div class="text-right mt-4">
                    <button mat-button color="primary" [disabled]="!Frm.valid">
                        <mat-icon>save</mat-icon>
                        Submit
                    </button>
                </div>

                <!-- Success Message -->
                <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-3" role="alert">
                    Successfully submitted
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>