<div class="ml-3">
    <div class="row">
        <div class="col-lg-6 float-right">
            <mat-form-field class="example-full-width mt-5">
                <mat-label>Filter</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Filter by Date or any field" />
            </mat-form-field>
        </div>
    </div>
</div>

<div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" matSort class="w-100">
        <!-- Date Column -->
        <ng-container matColumnDef="createdDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
            <td mat-cell *matCellDef="let item">{{ item.createdDate | date: 'short' }}</td>
        </ng-container>

        <!-- Side Column -->
        <ng-container matColumnDef="side">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Side</th>
            <td mat-cell *matCellDef="let item">{{ item.Side }}</td>
        </ng-container>

        <!-- Muscle Column -->
        <ng-container matColumnDef="muscle">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Muscle</th>
            <td mat-cell *matCellDef="let item">{{ item.Muscle }}</td>
        </ng-container>

        <!-- Nerve Column -->
        <ng-container matColumnDef="nerve">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Nerve</th>
            <td mat-cell *matCellDef="let item">{{ item.Nerve }}</td>
        </ng-container>

        <!-- Root Column -->
        <ng-container matColumnDef="root">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Root</th>
            <td mat-cell *matCellDef="let item">{{ item.Root }}</td>
        </ng-container>

        <!-- Ins.Act Column -->
        <ng-container matColumnDef="insAct">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Ins.Act</th>
            <td mat-cell *matCellDef="let item">{{ item.InsAct }}</td>
        </ng-container>

        <!-- Fibs Column -->
        <ng-container matColumnDef="fibs">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Fibs</th>
            <td mat-cell *matCellDef="let item">{{ item.Fibs }}</td>
        </ng-container>

        <!-- Psw Column -->
        <ng-container matColumnDef="psw">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Psw</th>
            <td mat-cell *matCellDef="let item">{{ item.Psw }}</td>
        </ng-container>

        <!-- Amp Column -->
        <ng-container matColumnDef="amp">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Amp</th>
            <td mat-cell *matCellDef="let item">{{ item.Amp }}</td>
        </ng-container>

        <!-- Duration Column -->
        <ng-container matColumnDef="duration">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Duration (ms)</th>
            <td mat-cell *matCellDef="let item">{{ item.Duration }}</td>
        </ng-container>

        <!-- Poly Column -->
        <ng-container matColumnDef="poly">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Poly</th>
            <td mat-cell *matCellDef="let item">{{ item.Poly }}</td>
        </ng-container>

        <!-- Recrt Column -->
        <ng-container matColumnDef="recrt">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Recrt</th>
            <td mat-cell *matCellDef="let item">{{ item.Recrt }}</td>
        </ng-container>

        <!-- Int. Pat Column -->
        <ng-container matColumnDef="intPat">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Int. Pat</th>
            <td mat-cell *matCellDef="let item">{{ item.IntPat }}</td>
        </ng-container>

        <!-- Comments Column -->
        <ng-container matColumnDef="comments">
            <th mat-header-cell *matHeaderCellDef>Comments</th>
            <td mat-cell *matCellDef="let item">{{ item.Comment }}</td>
        </ng-container>

        <!-- Update Button Column -->
        <ng-container matColumnDef="update">
            <th mat-header-cell *matHeaderCellDef>Update</th>
            <td mat-cell *matCellDef="let item">
                <button mat-icon-button [routerLink]="['/updateElectromyography/', item.ElectromyographyId]"
                    *ngIf="CurrentDoctorId === item.DoctorId" color="primary">
                    <mat-icon>edit</mat-icon>
                </button>
            </td>
        </ng-container>

        <!-- Delete Button Column -->
        <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef>Delete</th>
            <td mat-cell *matCellDef="let item">
                <button mat-icon-button (click)="delete(item.ElectromyographyId)"
                    *ngIf="CurrentDoctorId === item.DoctorId" color="warn">
                    <mat-icon>delete</mat-icon>
                </button>
            </td>
        </ng-container>

        <!-- Table Header and Row Definitions -->
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <!-- Pagination -->
    <mat-paginator [pageSize]="5" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
</div>