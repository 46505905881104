<div class="container">
  <div class="row">
    <div class="col-lg regFrm mat-elevation-z8">
      <h2>Add Hospital</h2>
      <form (keydown.enter)="$event.preventDefault()" [formGroup]="hsFrm" (ngSubmit)="formSubmit()"
        class="example-form">
        <div class="row">
          <!-- Hospital Name -->
          <div class="col-lg-4">
            <mat-form-field class="example-full-width">
              <mat-label>Hospital Name</mat-label>
              <input type="text" formControlName="HospitalName" matInput />
              <mat-error *ngIf="hsFrm.get('HospitalName')?.hasError('required')">Please enter Hospital Name</mat-error>
              <mat-error *ngIf="hsFrm.get('HospitalName')?.hasError('maxlength')">
                Hospital Name should not exceed 100 characters
              </mat-error>
            </mat-form-field>
          </div>

          <!-- Password -->
          <div class="col-lg-4">
            <mat-form-field class="example-full-width">
              <mat-label>Password</mat-label>
              <input type="password" formControlName="Password" matInput placeholder="Enter your password" />
              <mat-error *ngIf="hsFrm.get('Password')?.hasError('required')">Please enter Password</mat-error>
              <mat-error *ngIf="hsFrm.get('Password')?.hasError('pattern')">
                Password must contain at least 8 characters, including 1 uppercase letter, 1 number, and 1 special
                character
              </mat-error>
            </mat-form-field>
          </div>

          <!-- Image Upload -->
          <div class="col-lg-4">
            <label for="imageUpload">Upload Image:</label>
            <input type="file" id="imageUpload" (change)="image($event)" class="form-control" accept="image/*" />
            <mat-error>Please upload an image</mat-error>
          </div>
        </div>

        <div class="row">
          <div class="col-2">
            <mat-form-field class="example-full-width">
              <mat-label>Country Code</mat-label>
              <input type="text" formControlName="CountryCode" [matAutocomplete]="auto" matInput placeholder="+1, +91">
              <mat-error>Please enter a Phone Number with country code eg: +1,+91,+44</mat-error>
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                  {{ option }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="col-lg-4">
            <!-- Hospital Phone -->
            <mat-form-field class="example-full-width">
              <mat-label>Hospital Phone</mat-label>
              <input type="text" formControlName="HospitalPhone" matInput placeholder="Enter hospital phone number" />
              <mat-error *ngIf="hsFrm.get('HospitalPhone')?.hasError('required')">Please enter Hospital
                Phone</mat-error>
              <mat-error *ngIf="hsFrm.get('HospitalPhone')?.hasError('pattern')">
                Phone number must be in the format +123456789 or 123-456-7890
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-lg-6">
            <!-- Hospital Email -->
            <mat-form-field class="example-full-width">
              <mat-label>Hospital Email</mat-label>
              <input type="email" formControlName="HospitalEmail" matInput placeholder="Enter hospital email address" />
              <mat-error *ngIf="hsFrm.get('HospitalEmail')?.hasError('required')">Please enter Hospital
                Email</mat-error>
              <mat-error *ngIf="hsFrm.get('HospitalEmail')?.hasError('email')">Please enter a valid email
                address</mat-error>
            </mat-form-field>
          </div>


        </div>
        <div class="row">
          <!-- Hospital Address -->
          <div class="col-lg">
            <mat-form-field class="example-full-width">
              <mat-label>Hospital Address</mat-label>
              <input type="text" formControlName="HospitalAddress" matInput placeholder="Enter hospital address" />
              <mat-error *ngIf="hsFrm.get('HospitalAddress')?.hasError('required')">
                Please enter Hospital Address
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">

          <div class="col-lg-3">
            <mat-form-field class="example-full-width">
              <mat-label>State</mat-label>
              <input type="text" formControlName="State" matInput />
              <mat-error *ngIf="hsFrm.get('State').invalid">Please enter State</mat-error>
            </mat-form-field>
          </div>
          <div class="col-lg-3">
            <mat-form-field class="example-full-width">
              <mat-label>City</mat-label>
              <input type="text" formControlName="City" matInput />
              <mat-error *ngIf="hsFrm.get('City').invalid">Please enter City</mat-error>
            </mat-form-field>
          </div>
          <div class="col-lg-3">
            <mat-form-field class="example-full-width">
              <mat-label>Zip</mat-label>
              <input type="text" formControlName="Zip" matInput />
              <mat-error *ngIf="hsFrm.get('Zip').invalid">Please enter Zip</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <!-- Tax Id -->
          <div class="col-lg-3">
            <mat-form-field class="example-full-width">
              <mat-label>Tax Id</mat-label>
              <input type="text" formControlName="TaxId" matInput placeholder="Enter Tax ID" />
              <mat-error *ngIf="hsFrm.get('TaxId')?.hasError('required')">Please enter Tax Id</mat-error>
            </mat-form-field>
          </div>

          <!-- Provider No -->
          <div class="col-lg-3">
            <mat-form-field class="example-full-width">
              <mat-label>Provider No</mat-label>
              <input type="text" formControlName="ProviderNo" matInput placeholder="Enter Provider Number" />
              <mat-error *ngIf="hsFrm.get('ProviderNo')?.hasError('required')">Please enter Provider No</mat-error>
            </mat-form-field>
          </div>

          <!-- Group No -->
          <div class="col-lg-3">
            <mat-form-field class="example-full-width">
              <mat-label>Group No</mat-label>
              <input type="text" formControlName="GroupNo" matInput placeholder="Enter Group Number" />
              <mat-error *ngIf="hsFrm.get('GroupNo')?.hasError('required')">Please enter Group No</mat-error>
            </mat-form-field>
          </div>

          <!-- NPI Number -->
          <div class="col-lg-3">
            <mat-form-field class="example-full-width">
              <mat-label>NPI Number</mat-label>
              <input type="text" formControlName="NpiNumber" matInput placeholder="Enter NPI Number" />
              <mat-error *ngIf="hsFrm.get('NpiNumber')?.hasError('required')">Please enter NPI Number</mat-error>
              <mat-error *ngIf="hsFrm.get('NpiNumber')?.hasError('pattern')">
                NPI Number must be a valid 10-digit number
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <button class="float-right lgBtCl" mat-button>
          <mat-icon>save</mat-icon>
        </button>
        <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
          Successfully submitted
          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </form>


    </div>