<div id="dashboard">
  <div class="content">
    <div class="sidebar mat-elevation">
      <div class="header">
        <!-- <img src="../../../assets/admin.png" class="image" alt=""> -->

        <!-- <h4 class="text-white ml-2">Hospital Dashboard</h4> -->
      </div>



      <!-- <div class="ItemS  " >
            <mat-icon mat-list-icon>add</mat-icon>
            <div class="mx-2" [routerLink]="['/addDoctor/',this.id]"> Add Doctor</div>
            <mat-icon>navigate_next</mat-icon>
          </div>
        
           <div class="ItemS  " >
            <mat-icon mat-list-icon>add</mat-icon>
            <div class="mx-2" [routerLink]="['/addPatient/',this.id]"> Add Patient</div>
            <mat-icon>navigate_next</mat-icon>
          </div>
        
           <div class="ItemS  " >
            <mat-icon mat-list-icon>add</mat-icon>
            <div class="mx-2" [routerLink]="['/addWard/',this.id]"> Add Ward</div>
            <mat-icon>navigate_next</mat-icon>
          </div> -->









    </div>

    <div class="main">
      <div class="toolbar mat-elevation-z6"></div>

      <div class="container mt-3 ">
        <div class="row">
          <div class="col-lg-4 ">

            <!-- <h2>Methodist Hospital</h2> -->
            <!-- <img src="../../../assets/biserX.png" style="max-width: 270px;" alt=""> -->
            <div [innerHTML]="hospital?.HospitalLogo" class="cell"></div>
            <!-- <img src="{{hospital?.HospitalLogo}}" style="max-width: 270px;" alt=""> -->

          </div>
          <div class="col-lg-4"></div>
          <div class="col-lg-4 ">
            <p> {{hospital?.HospitalName}}</p>
            <p>Address: {{hospital?.HospitalAddress}}</p>
            <p>Phone: {{hospital?.HospitalPhone}}</p>
            <p>Email: {{hospital?.HospitalEmail}}</p>
            <!-- <p>Tax Id: {{hospital?.TaxId}}</p>
            <p>Provider No: {{hospital?.ProviderNo}}</p>
            <p>GroupNo: {{hospital?.GroupNo}}</p>
            <p>Npi Number: {{hospital?.NpiNumber}}</p> -->
          </div>
        </div>
      </div>
      <hr>
      <!-- <app-view-department></app-view-department> -->
    </div>
  </div>