<div class="subscription-plan-container">
    <h2>Subscription Plans</h2>

    <!-- List of Subscription Plans -->
    <table>
        <thead>
            <tr>
                <th>#</th>
                <th>Plan Name</th>
                <th>Price (USD)</th>
                <th>Max Users</th>
                <th>Max Appointments</th>
                <th>Features</th>
                <th>Discount 3 Months (%)</th>
                <th>Discount 12 Months (%)</th>
                <th>Duration (Months)</th>
                <th>Actions</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let plan of subscriptionPlans; let i = index">
                <td>{{ i + 1 }}</td>
                <!-- Inline Edit: Conditionally display input fields for editing -->
                <td>
                    <div *ngIf="editingPlan?.PlanId === plan.PlanId; else viewPlanName">
                        <input [(ngModel)]="editingPlan.PlanName" />
                    </div>
                    <ng-template #viewPlanName>{{ plan.PlanName }}</ng-template>
                </td>
                <td>
                    <div *ngIf="editingPlan?.PlanId === plan.PlanId; else viewPrice">
                        <input [(ngModel)]="editingPlan.Price" type="number" />
                    </div>
                    <ng-template #viewPrice>{{ plan.Price }}</ng-template>
                </td>
                <td>
                    <div *ngIf="editingPlan?.PlanId === plan.PlanId; else viewMaxUsers">
                        <input [(ngModel)]="editingPlan.MaxUsers" type="number" />
                    </div>
                    <ng-template #viewMaxUsers>{{ plan.MaxUsers }}</ng-template>
                </td>
                <td>
                    <div *ngIf="editingPlan?.PlanId === plan.PlanId; else viewMaxAppointments">
                        <input [(ngModel)]="editingPlan.MaxAppointments" type="number" />
                    </div>
                    <ng-template #viewMaxAppointments>{{ plan.MaxAppointments }}</ng-template>
                </td>
                <td>
                    <div *ngIf="editingPlan?.PlanId === plan.PlanId; else viewFeatures">
                        <input [(ngModel)]="editingPlan.Features" />
                    </div>
                    <ng-template #viewFeatures>{{ plan.Features }}</ng-template>
                </td>
                <td>
                    <div *ngIf="editingPlan?.PlanId === plan.PlanId; else viewDiscount3">
                        <input [(ngModel)]="editingPlan.Discount3Months" type="number" />
                    </div>
                    <ng-template #viewDiscount3>{{ plan.Discount3Months }}</ng-template>
                </td>
                <td>
                    <div *ngIf="editingPlan?.PlanId === plan.PlanId; else viewDiscount12">
                        <input [(ngModel)]="editingPlan.Discount12Months" type="number" />
                    </div>
                    <ng-template #viewDiscount12>{{ plan.Discount12Months }}</ng-template>
                </td>
                <td>
                    <div *ngIf="editingPlan?.PlanId === plan.PlanId; else viewDuration">
                        <input [(ngModel)]="editingPlan.DurationMonths" type="number" />
                    </div>
                    <ng-template #viewDuration>{{ plan.DurationMonths }}</ng-template>
                </td>
                <td>
                    <!-- Edit/Save/Cancel Actions -->
                    <button *ngIf="editingPlan?.PlanId === plan.PlanId" (click)="savePlan()">Save</button>
                    <button *ngIf="editingPlan?.PlanId === plan.PlanId" (click)="cancelEdit()">Cancel</button>
                    <button *ngIf="editingPlan?.PlanId !== plan.PlanId" (click)="editPlan(plan)">Edit</button>
                    <button (click)="deletePlan(plan.PlanId)">Delete</button>
                </td>
            </tr>
        </tbody>
    </table>

    <!-- Add New Plan -->
    <h3>Add New Plan</h3>
    <form (ngSubmit)="addPlan()">
        <div>
            <label>Plan Name:</label>
            <input [(ngModel)]="newPlan.PlanName" name="planName" required />
        </div>
        <div>
            <label>Price (USD):</label>
            <input [(ngModel)]="newPlan.Price" name="price" type="number" required />
        </div>
        <div>
            <label>Max Users:</label>
            <input [(ngModel)]="newPlan.MaxUsers" name="maxUsers" type="number" required />
        </div>
        <div>
            <label>Max Appointments:</label>
            <input [(ngModel)]="newPlan.MaxAppointments" name="maxAppointments" type="number" required />
        </div>
        <div>
            <label>Features:</label>
            <textarea [(ngModel)]="newPlan.Features" name="features" required></textarea>
        </div>
        <div>
            <label>Discount for 3 Months (%):</label>
            <input [(ngModel)]="newPlan.Discount3Months" name="discount3Months" type="number" />
        </div>
        <div>
            <label>Discount for 12 Months (%):</label>
            <input [(ngModel)]="newPlan.Discount12Months" name="discount12Months" type="number" />
        </div>
        <div>
            <label>Duration (Months):</label>
            <input [(ngModel)]="newPlan.DurationMonths" name="durationMonths" type="number" required />
        </div>
        <button type="submit">Add Plan</button>
    </form>
</div>