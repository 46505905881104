import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { ClaimService } from 'src/app/services/claim.service';

@Component({
  selector: 'app-update-claim',
  templateUrl: './update-claim.component.html',
  styleUrls: ['./update-claim.component.css'],
})
export class UpdateClaimComponent implements OnInit {
  // claimForm: FormGroup;
  claimResponse: string;
  payerList: any;
  payers: any[] = [];
  accountKey = '21291_29li6ZR873Ec7Jv#KHAlHpft';
  xmlList: any;
  attachedFiles: File;
  placeOfServiceCodes = [
    { code: '11', description: 'Office' },
    { code: '21', description: 'Inpatient Hospital' },
    { code: '22', description: 'Outpatient Hospital' },
    { code: '23', description: 'Emergency Room - Hospital' },
    { code: '31', description: 'Skilled Nursing Facility' },
    { code: '32', description: 'Nursing Facility' },
    { code: '41', description: 'Ambulance - Land' },
    { code: '42', description: 'Ambulance - Air or Water' },
    // Add more codes as needed
  ];
  uploadList: any[];
  errorMessage: null;
  claimId: any;
  attachmentTypes = [
    { code: 'AS', description: 'Admission Summary' },
    { code: 'A3', description: 'Allergies/Sensitivities Document' },
    { code: 'AM', description: 'Ambulance Certification' },
    { code: 'A4', description: 'Autopsy Report' },
    { code: 'BS', description: 'Baseline' },
    { code: 'BR', description: 'Benchmark Testing Results' },
    { code: 'BT', description: 'Blanket Test Results' },
    { code: 'CT', description: 'Certification' },
    { code: '13', description: 'Certified Test Report' },
    { code: '11', description: 'Chemical Analysis' },
    { code: 'CB', description: 'Chiropractic Justification' },
    { code: 'CK', description: 'Consent Form(s)' },
    { code: '10', description: 'Continued Treatment' },
    { code: 'V5', description: 'Death Notification' },
    { code: 'DA', description: 'Dental Models' },
    { code: 'DG', description: 'Diagnostic Report' },
    { code: 'DJ', description: 'Discharge Monitoring Report' },
    { code: 'DS', description: 'Discharge Summary' },
    { code: 'D2', description: 'Drug Profile Document' },
    { code: '04', description: 'Drugs Administered' },
    { code: 'DB', description: 'Durable Medical Equipment Prescription' },
    { code: 'EB', description: 'Explanation of Benefits' },
    { code: '07', description: 'Functional Goals' },
    { code: 'HC', description: 'Health Certificate' },
    { code: 'HR', description: 'Health Clinic Records' },
    { code: 'I5', description: 'Immunization Record' },
    { code: '06', description: 'Initial Assessment' },
    { code: '15', description: 'Justification for Admission' },
    { code: 'LA', description: 'Laboratory Results' },
    { code: 'M1', description: 'Medical Record Attachment' },
    { code: 'MT', description: 'Models' },
    { code: 'NN', description: 'Nursing Notes' },
    { code: 'OE', description: 'Objective Physical Examination' },
    { code: 'OB', description: 'Operative Note' },
    { code: 'OD', description: 'Orders and Treatments Document' },
    { code: 'OC', description: 'Oxygen Content Averaging Report' },
    { code: 'OX', description: 'Oxygen Therapy Certification' },
    { code: 'PQ', description: 'Paramedical Results' },
    { code: 'PE', description: 'Parenteral or Enteral Certification' },
    { code: 'P4', description: 'Pathology Report' },
    { code: 'P5', description: 'Patient Medical History Document' },
    { code: 'XP', description: 'Photographs' },
    { code: 'PZ', description: 'Physical Therapy Certification' },
    { code: 'PN', description: 'Physical Therapy Notes' },
    { code: 'B3', description: 'Physician Order' },
    { code: 'PY', description: 'Physicians Report' },
    { code: '08', description: 'Plan of Treatment' },
    { code: 'B2', description: 'Prescription' },
    { code: '09', description: 'Progress Report' },
    { code: 'PO', description: 'Prosthetics or Orthotic Certification' },
    { code: 'RB', description: 'Radiology Films' },
    { code: 'RR', description: 'Radiology Reports' },
    { code: '21', description: 'Recovery Plan' },
    { code: 'B4', description: 'Referral Form' },
    { code: 'RX', description: 'Renewable Oxygen Content Averaging Report' },
    {
      code: '03',
      description: 'Report Justifying Treatment Beyond Utilization Guidelines',
    },
    { code: 'RT', description: 'Report of Tests and Analysis Report' },
    { code: 'IR', description: 'State School Immunization Records' },
    { code: 'OZ', description: 'Support Data for Claim' },
    { code: 'SG', description: 'Symptoms Document' },
    { code: '05', description: 'Treatment Diagnosis' },
  ];

  constructor(
    private fb: FormBuilder,
    private claimsService: ClaimService,
    private http: HttpClient,
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<UpdateClaimComponent>,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    console.log(this.data);
    this.claimId = this.data;
    this.getClaimById(this.claimId);
  }

  // get claims(): FormArray {
  //   return this.claimForm.get('claim') as FormArray;
  // }

  // charges(claimIndex: number): FormArray {
  //   return this.claimForm.at(claimIndex).get('charge') as FormArray;
  // }

  getClaimById(claimId): void {
    this.errorMessage = null; // Reset error message
    this.uploadList = []; // Reset upload list

    this.claimsService.getClaimById(this.accountKey, claimId).subscribe({
      next: (response: any) => {
        try {
          const parsedResponse = JSON.parse(response); // Parse the JSON string into an object
          this.xmlList = parsedResponse.result || [];
          console.log(
            'parsedResponse.result' + JSON.stringify(parsedResponse.result)
          );
          this.claimForm.patchValue(parsedResponse.result[0]);
          // Pretty print the JSON
        } catch (error) {
          console.error('Error parsing JSON response:', error);
        }
      },
      error: (error: any) => {
        console.error('Error fetching upload list:', error);
      },
    });
  }

  claimForm = this.fb.group({
    attachmentType: [''],
    payerid: ['', Validators.required], //Auto
    payer_name: ['', Validators.required], //Get List drop down
    accept_assign: ['', Validators.required],
    auto_accident: ['', Validators.required],
    balance_due: ['', Validators.required], //not required
    bill_addr_1: ['', Validators.required],
    bill_city: ['', Validators.required],
    bill_name: ['', Validators.required],
    bill_npi: ['', Validators.required],
    bill_phone: ['', Validators.required],
    bill_state: ['', Validators.required],
    bill_taxid: ['', Validators.required],
    bill_taxid_type: ['E', Validators.required],
    bill_zip: ['', Validators.required],
    // charge: this.fb.array([this.createChargeGroup()]),
    claim_form: [''],
    diag_1: [''],
    diag_2: [''],
    diag_3: [''],
    diag_4: [''],
    diag_5: [''],
    diag_6: [''],
    diag_7: [''],
    diag_8: [''],
    diag_9: [''],
    diag_10: [''],
    diag_11: [''],
    diag_12: [''],
    employment_related: ['', Validators.required],
    ins_addr_1: ['', Validators.required], //Auto
    ins_city: ['', Validators.required], //Auto
    ins_dob: ['', Validators.required], //Auto
    ins_group: ['', Validators.required],
    ins_name_f: ['', Validators.required], //Auto
    ins_name_l: ['', Validators.required], //Auto
    ins_number: ['', Validators.required], //Auto
    ins_sex: ['', Validators.required],
    ins_state: ['', Validators.required], //Max Legth2
    ins_zip: ['', Validators.required],
    pat_addr_1: ['', Validators.required],
    pat_city: ['', Validators.required],
    pat_dob: ['', Validators.required],
    pat_name_f: ['', Validators.required],
    pat_name_l: ['', Validators.required],
    pat_rel: ['', Validators.required],
    pat_sex: ['', Validators.required],
    pat_state: ['', Validators.required],
    pat_zip: ['', Validators.required],

    pcn: ['', Validators.required],
    prov_name_f: ['', Validators.required],
    prov_name_l: ['', Validators.required],
    prov_name_m: [''],
    prov_npi: ['', Validators.required],
    prov_taxonomy: ['', Validators.required],
    ref_name_f: ['', Validators.required],
    ref_name_l: ['', Validators.required],
    ref_name_m: [''],
    ref_npi: ['', Validators.required],
    remote_batchid: ['', Validators.required], //Auto
    remote_claimid: ['', Validators.required], //Auto
    remote_fileid: ['', Validators.required], //Auto
    amount_paid: ['', Validators.required], //not required
    total_charge: ['1', Validators.required],
    charge: this.fb.array([this.createChargeGroup()]),
    // charge: ['', Validators.required],
    // charge_record_type: ['UN', Validators.required],
    // diag_ref: ['', Validators.required],
    // from_date: ['', Validators.required],
    // place_of_service: ['', Validators.required],
    // proc_code: ['', Validators.required],
    // remote_chgid: ['', Validators.required],
    // thru_date: ['', Validators.required],
    // units: ['1', Validators.required],
  });

  get charges(): FormArray {
    return this.claimForm.get('charge') as FormArray;
  }

  // Create a single charge group
  createChargeGroup(): FormGroup {
    return this.fb.group({
      charge: ['', Validators.required],
      place_of_service: ['', Validators.required],
      proc_code: ['', Validators.required],
      units: ['', Validators.required],
    });
  }
  // addClaim(): void {
  //   this.claims.push(this.createClaimGroup());
  // }

  addCharge(): void {
    this.charges.push(this.createChargeGroup());
  }

  onClose(): void {
    this.dialogRef.close();
  }
  onSubmit(): void {
    const formData = new FormData();

    // Append AccountKey
    formData.append('AccountKey', '21291_29li6ZR873Ec7Jv#KHAlHpft');

    // Create a JSON file and append it to the FormData
    const claimPayload = {
      ...this.claimForm.value,
    };
    // Convert the JSON payload to a Blob
    const jsonBlob = new Blob([JSON.stringify(claimPayload)], {
      type: 'application/json',
    });

    const fileName = 'claimData.json';
    formData.append('File', jsonBlob, fileName);
    formData.append('FileName', fileName);
    this.claimsService.sendClaimToInsurance(formData).subscribe(
      (res) => {
        console.log('Response:', res);
      },
      (err) => {
        console.error('Error:', err);
      }
    );

    // formData.append('File', jsonBlob, `Invoice_${this.invoice.InvoiceId}.json`);
    // formData.append('FileName', `Invoice_${this.invoice.InvoiceId}.json`);

    this.claimsService.sendClaimToInsurance(formData).subscribe(
      (res) => {
        console.log(res);
        // Parse XML response
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(res, 'text/xml');
        const message = xmlDoc
          .querySelector('result')
          ?.getAttribute('messages');
        this.claimResponse = message || 'No response message received.';
      },
      (err) => {
        // console.error('Error sending claim:', err);
        this.claimResponse = 'Failed to send the invoice. Please try again.';
      }
    );
  }
  onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files) {
      for (let i = 0; i < input.files.length; i++) {
        this.attachedFiles = input.files[i];
      }
    }
  }

  // Remove a specific file from the list
  removeFile(index: number) {
    //this.attachedFiles.splice(index, 1);
  }
  exportDataAsFile(data: any): Blob {
    const jsonStr = JSON.stringify(data, null, 2); // Convert data to JSON string
    return new Blob([jsonStr], { type: 'application/json' }); // Create a Blob for the file
  }

  uploadJsonFile(file: Blob, apiUrl: string): Observable<any> {
    const formData = new FormData();
    formData.append('file', file, 'data.json'); // Append the file to FormData with a name

    const headers = new HttpHeaders({
      // Add additional headers if required by the API
    });

    return this.http.post(apiUrl, formData, { headers }); // Send the file to the API
  }
}
function parseString(xmlData: any, arg1: (err: any, result: any) => void) {
  throw new Error('Function not implemented.');
}
