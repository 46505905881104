import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/internal/operators/catchError';
import { User } from '../Models/user.model';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  isLoggedIn(): boolean {
    return !!this.getToken();
  }
  getRole() {
    throw new Error('Method not implemented.');
  }
  isLogin = false;

  roleAs: string;

  url = environment.Url;
  TwoFactorurl = environment.Url + 'Login/LoginTwoStep';

  header = new HttpHeaders({
    Authorization: 'token' + localStorage.getItem('token'),
  });

  constructor(private http: HttpClient) {}

  login(body) {
    return this.http
      .post(this.url + 'Login', body)
      .pipe(catchError(this.handleError));
  }
  emergency(body) {
    return this.http
      .post(this.url + 'Login/emergency', body)
      .pipe(catchError(this.handleError));
  }

  TwoFa(body) {
    return this.http
      .post(this.TwoFactorurl, body)
      .pipe(catchError(this.handleError));
  }

  registerUser(body) {
    return this.http
      .post<User>(this.url + 'Users', body)
      .pipe(catchError(this.handleError));
  }
  getToken() {
    return localStorage.getItem('token');
  }
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      if (error.status == 400) {
        console.log(error.error);
        return throwError(error.error);
      } else if (error.status == 401) {
        return throwError('invalid username or password');
      } else if (error.status == 0) {
        return throwError('network failure please try again later ');
      }
      return throwError(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }
}
