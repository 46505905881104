<div class="container adjHight">
    <div class="row">
        <div class="col-lg-6">
            <div class="search-bar">
                <form [formGroup]="drugForm">
                    <mat-form-field class="example-full-width">
                        <mat-label>Search for a drug</mat-label>
                        <input type="text" matInput formControlName="drug" [matAutocomplete]="auto"
                            (input)="searchDrug()" />
                        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onDrugSelected($event)">
                            <mat-option *ngFor="let dosage of dosageDetails" [value]="dosage">
                                {{ dosage }}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </form>
            </div>
            <!-- 
            <div *ngIf="loading" class="loading-indicator">
                <p>Loading, please wait...</p>
            </div>

            <div *ngIf="!loading && dosageDetails.length">
                <h3>Dosage Details:</h3>
                <ul>
                    <li *ngFor="let dosage of dosageDetails">
                        {{ dosage }}
                    </li>
                </ul>
            </div> -->

            <div *ngIf="!loading && !dosageDetails.length && drugForm.get('drug')?.value">
                <p>No dosage details found for "{{ drugForm.get('drug')?.value }}".</p>
            </div>

        </div>
    </div>
</div>