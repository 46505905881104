<div class="container">
  <div class="row">
    <div class="col-lg regFrm mat-elevation-z8">
      <h2>New Department</h2>
      <form (keydown.enter)="$event.preventDefault()" [formGroup]="dptFrm" (ngSubmit)="formSubmit()"
        class="example-form">
        <!-- Department Details -->
        <div class="row">
          <div class="col-lg-6">
            <mat-form-field class="example-full-width">
              <mat-label>Department Name</mat-label>
              <input type="text" formControlName="DepartmentName" matInput />
              <mat-error *ngIf="dptFrm.get('DepartmentName').hasError('required')">
                Please enter the department name
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-lg-6">
            <mat-form-field class="example-full-width">
              <mat-label>Description</mat-label>
              <input type="text" formControlName="Description" matInput />
              <mat-error *ngIf="dptFrm.get('Description').hasError('required')">
                Please enter a description
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <!-- Submit Button -->
        <div class="text-right mt-3">
          <button mat-button color="primary" [disabled]="!dptFrm.valid">
            <mat-icon>save</mat-icon>
            Save
          </button>
        </div>

        <!-- Success Message -->
        <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-3" role="alert">
          Successfully created department
          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</div>