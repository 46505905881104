import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-split-a',
  templateUrl: './split-a.component.html',
  styleUrls: ['./split-a.component.css'],
})
export class SplitAComponent implements OnInit {
  id = 0;
  constructor(
    private route: ActivatedRoute,
    private tokenService: TokenService
  ) {}
  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
  }
  tabs2: string[] = [
    'New Progress Note',
    'After Visit Summary',
    'New Medical Report',
    'New Prescription',
    'New Vitals',
    'New Orders',
    'New Initial-evaluation',
    'New Rounds Book',
    'New Refferal',
    'New Invoice',
    'New Appointment',
    'New Procedure Test-results',
    'New Radiology Report',
    'Upload Lab Report',
    'Upload Radiology Report',
    'Upload Study Images',
    'New Review Of Systems',
    'Ask AI',
    'New Claim',
    'New Claim Attachment',
    // 'Add Progress',
  ];
  activatedTabIndex2: number = 0;

  tabChange2(tabIndex2: number) {
    // debugger;
    this.activatedTabIndex2 = tabIndex2;
  }
}
