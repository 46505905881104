<div class="container">
  <div class="row">
    <div class="col-lg regFrm mat-elevation-z8">
      <h1>Create New Invoice</h1>
      <form (keydown.enter)="$event.preventDefault()" [formGroup]="invForm" (ngSubmit)="formSubmit()"
        class="example-form">
        <!-- <mat-label>Patient Name</mat-label>
        <input type="text" formControlName="PatientName" matInput readonly /> -->
        <!-- <div class="row"> -->
        <!-- <div class="col-lg-9">
            <mat-form-field class="example-full-width">
              <mat-label>Artificial Intelligence Procedure Code Look Up</mat-label>
              <mat-select formControlName="ProcedureCodeLookUp" (ngModelChange)="onSelectChange($event)">
                <mat-option>
                  <lib-mat-select-search [list]="cptCodeList" [searchProperties]="['Code']"
                    (filtered)="filteredOptions = $event">
                  </lib-mat-select-search>
                </mat-option>
                <cdk-virtual-scroll-viewport [ngStyle]="{height: '200px'}" [itemSize]="20">
                  <mat-option *cdkVirtualFor="let entry of filteredOptions" [value]="entry" #e>
                    {{entry.Code}} &nbsp; <button type="button" class="ml-4" (click)="copy(entry.Code)" matPrefix>
                      &nbsp;
                      <mat-icon>content_copy</mat-icon></button>
                  </mat-option>
                </cdk-virtual-scroll-viewport>


              </mat-select>


              <mat-error> Codes required</mat-error>

            </mat-form-field>
          </div> -->

        <!-- <div class="col-lg-3">
            Basic Charge : ${{UnitCharge}}
          </div> -->
        <!-- </div> -->
        <hr>
        <div class="row">
          <div class="col-lg-4">
            <mat-form-field class="example-full-width">
              <mat-label>Patient Name</mat-label>
              <input type="text" formControlName="PatientName" matInput>
              <mat-error *ngIf="invForm.get('PatientName')?.hasError('required')">
                Please Enter Patient Name
              </mat-error>

            </mat-form-field>

          </div>
        </div>
        <div class="row">
          <div class="col-lg-3">
            <mat-form-field class="example-full-width">
              <mat-label>Insuarance Name</mat-label>
              <input type="text" formControlName="InsuaranceName" matInput>
              <mat-error *ngIf="invForm.get('InsuaranceName')?.hasError('required')"> </mat-error>
            </mat-form-field>

          </div>
          <div class="col-lg-3">
            <mat-form-field class="example-full-width">
              <mat-label>Insuarance No</mat-label>
              <input type="text" formControlName="InsuaranceNo" matInput>
              <mat-error *ngIf="invForm.get('InsuaranceNo')?.hasError('required')"> </mat-error>


            </mat-form-field>

          </div>
          <div class="col-lg-3">
            <mat-form-field class="example-full-width">
              <mat-label>Group No</mat-label>
              <input type="text" formControlName="GroupNo" matInput>
              <mat-error *ngIf="invForm.get('GroupNo')?.hasError('required')"> </mat-error>

            </mat-form-field>
          </div>
          <div class="col-lg-3">

            <mat-form-field class="compact-field">
              <mat-label>Patient Relationship</mat-label>
              <mat-select formControlName="InsuresRelationToPatient" required>
                <mat-option value="18">Self</mat-option>
                <mat-option value="19">Spouse</mat-option>
                <mat-option value="20">Child</mat-option>
              </mat-select>
            </mat-form-field>

          </div>
        </div>
        <div class="row">


          <div class="col-lg-3">
            <mat-form-field class="example-full-width">
              <mat-label>Subject</mat-label>
              <input type="text" formControlName="Title" matInput>
              <mat-error *ngIf="invForm.get('Title')?.hasError('required')"> </mat-error>

            </mat-form-field>
          </div>

          <div class="col-lg-3">
            <mat-form-field class="example-full-width">
              <mat-label>Date of Invoice</mat-label>
              <input type="date" formControlName="Date" matInput>
              <mat-error *ngIf="invForm.get('Date')?.hasError('required')"> </mat-error>

            </mat-form-field>
          </div>
          <div class="col-lg-3">
            <mat-form-field class="example-full-width">
              <mat-label>Provider Name</mat-label>
              <input type="text" formControlName="DoctorName" matInput>
              <mat-error *ngIf="invForm.get('DoctorName')?.hasError('required')"> </mat-error>


            </mat-form-field>
          </div>
          <div class="col-lg-3">
            <mat-form-field class="example-full-width">
              <mat-label>NPI Number</mat-label>
              <input type="text" formControlName="NpiNumber" matInput>
              <mat-error *ngIf="invForm.get('NpiNumber')?.hasError('required')"> </mat-error>


            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <mat-form-field class="example-full-width">
              <mat-label>Diagnosis Codes</mat-label>
              <input type="text" formControlName="DiagnosisCode" matInput>

              <mat-error> Please Enter DiagnosisCode </mat-error>


            </mat-form-field>
          </div>
        </div>
        <!-- <h4 class="text-primary text-bold">
          Unit Charge : {{UnitCharge}}
        </h4> -->
        <div class="" formArrayName="InvoiceItems">
          <div class="row" *ngFor="let item of InvoiceItems.controls; let i = index">
            <ng-container [formGroup]="item">
              <!-- <span class="float-right">{{ item.get('UnitCharge')?.value }}</span> -->
              <div class="col-lg-5">
                <mat-form-field class="example-full-width" *ngIf="!showCustomField[i]">
                  <mat-label>Procedure Code</mat-label>
                  <mat-select formControlName="ProcedureCode" (selectionChange)="onSelectChange($event.value, i)">
                    <mat-option>
                      <lib-mat-select-search [list]="cptCodeList" [searchProperties]="['Code']"
                        (filtered)="onFilter($event, i)">
                      </lib-mat-select-search>
                    </mat-option>
                    <cdk-virtual-scroll-viewport [ngStyle]="{ height: '200px' }" itemSize="25">
                      <mat-option *cdkVirtualFor="let entry of filteredMedicineLists[i]" [value]="entry.Code">
                        {{ entry.Code }}
                      </mat-option>
                    </cdk-virtual-scroll-viewport>
                  </mat-select>
                  <mat-error>
                    Please Enter ProcedureCode
                  </mat-error>
                </mat-form-field>

                <button mat-mini-fab color="mute" (click)="toggleCustomField(i)" *ngIf="!showCustomField[i]">
                  <mat-icon>
                    <span class="material-symbols-outlined">disabled_visible</span>
                  </mat-icon>
                </button>

                <mat-form-field *ngIf="showCustomField[i]" class="example-full-width">
                  <input matInput placeholder="Enter Custom Code" (keydown.enter)="saveCustomCode($event, i)" />
                </mat-form-field>
              </div>

              <div class="col-lg-1">
                <mat-form-field class="example-full-width">
                  <mat-label>Units</mat-label>
                  <input type="number" matInput formControlName="Units" (input)="updateAmount(i)">
                  <mat-error *ngIf="invForm.get('Units')?.hasError('required')"> </mat-error>

                </mat-form-field>
              </div>
              <div class="col-lg-2">
                <mat-form-field class="example-full-width">
                  <mat-label>Duration(min)</mat-label>
                  <input type="number" matInput formControlName="Duration" (input)="updateAmount(i)">
                  <mat-error *ngIf="invForm.get('Duration')?.hasError('required')"> </mat-error>

                </mat-form-field>
              </div>
              <div class="col-lg-2">
                <mat-form-field class="example-full-width">
                  <mat-label>Amount</mat-label>
                  <input type="number" matInput formControlName="Amount" />
                  <mat-error *ngIf="invForm.get('Amount')?.hasError('required')"> </mat-error>

                </mat-form-field>
              </div>
              <div class="col-lg-1 float-right">
                <button type="button" mat-mini-fab (click)="removeItem(i)">
                  <mat-icon>remove</mat-icon>
                </button>
              </div>
            </ng-container>
            <button class="float-right" type="button" mat-mini-fab color="primary" (click)="addItem()">
              <mat-icon>add</mat-icon>
            </button>
          </div>

        </div>


        <div class="row">
          <div class="col-lg-3">

            <mat-form-field class="example-full-width">
              <mat-label>CoPay</mat-label>
              <input type="number" matInput formControlName="CoPay" (input)="getGrandTotal()">
              <mat-error *ngIf="invForm.get('CoPay')?.hasError('required')">
                Please Enter CoPay Amount
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-lg-3">

            <mat-form-field class="example-full-width">
              <mat-label>SubTotal</mat-label>
              <input type="number" matInput formControlName="SubTotal" readonly>
            </mat-form-field>
          </div>

          <div class="col-lg-3">

            <mat-form-field class="example-full-width">
              <mat-label>Total</mat-label>
              <input type="number" matInput formControlName="Total" readonly>
            </mat-form-field>
          </div>

          <div class="col-lg-3">
            <mat-form-field class="example-full-width">
              <mat-label>Status</mat-label>
              <!-- <input type="text" formControlName="DoctorSpecialization" matInput > -->
              <mat-select formControlName="Status">

                <mat-option *ngFor="let sp of StatusList" [value]="sp">{{sp}}</mat-option>

              </mat-select>
            </mat-form-field>
          </div>
        </div>





        <!-- Diagnosis Code Button -->
        <button type="button" mat-raised-button (click)="openFavoriteDiagnosisPopup()" color="warn">
          <mat-icon matPrefix>add</mat-icon> Diagnosis Code
        </button>





        <button type="submit" class="float-right lgBtCl" mat-button>
          <mat-icon>save</mat-icon>
          Save</button>
        <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
          Successfully Created Invoice
          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </form>

    </div>
  </div>

</div>