<div class="container">

    <div class="col-lg regFrm mat-elevation-z8">
        <h1>New Prescription</h1>
        <form (keydown.enter)="$event.preventDefault()" [formGroup]="prescriptionFrm"
            (keydown.enter)="$event.preventDefault()" (ngSubmit)="formSubmit()" class="example-form">
            <div class="">
                <div>
                    <div class="row">

                        <div class="col-lg-6">
                            <mat-form-field class="example-full-width">
                                <mat-label>Drug Name</mat-label>
                                <!-- <mat-select formControlName="DrugName">
                                    <mat-option>
                                        <lib-mat-select-search [list]="medicineList" [searchProperties]="['DrugName']"
                                            (filtered)="flteredmedicineList = $event">
                                        </lib-mat-select-search>
                                    </mat-option>

                                    <cdk-virtual-scroll-viewport [ngStyle]="{height: '200px'}" itemSize="25">
                                        <mat-option *cdkVirtualFor="let entry of flteredmedicineList"
                                            [value]="entry.DrugName">
                                            {{entry.DrugName}}
                                        </mat-option>
                                    </cdk-virtual-scroll-viewport>
                                </mat-select> -->
                                <input type="text" formControlName="DrugName" matInput>
                                <mat-error>Drug Name required</mat-error>

                            </mat-form-field>
                        </div>
                        <!-- <div class="col-lg-3">
  
  
                    <mat-form-field class="example-full-width">
                      <mat-label>BrandName</mat-label>
                      <mat-select formControlName="BrandName">
  
                        <mat-option *ngFor="let br of medicineList" [value]="br.BrandName">{{br.BrandName}}</mat-option>
  
                      </mat-select>
  
                    </mat-form-field>
  
  
                  </div> -->
                        <div class="col-lg-4">
                            <mat-form-field class="example-full-width">
                                <mat-label>Amount PerDose</mat-label>
                                <!-- <mat-select formControlName="Strength">
  
                    <mat-option *ngFor="let ds of dose" [value]="ds">{{ds}}</mat-option>
  
                  </mat-select> -->
                                <input type="text" matInput formControlName="AmountPerDose">
                                <mat-error>Amount PerDose
                                    required</mat-error>
                            </mat-form-field>

                        </div>
                        <div class="col-lg-2">
                            <mat-form-field class="example-full-width">
                                <mat-label>Unit</mat-label>
                                <!-- <mat-select formControlName="Instruction"> -->
                                <input type="text" matInput formControlName="Unit">

                                <mat-error>Unit
                                    required</mat-error>
                            </mat-form-field>

                        </div>
                    </div>
                    <div class="row">


                        <div class="col-lg-4">
                            <mat-form-field class="example-full-width">
                                <mat-label>Route</mat-label>
                                <input type="text" matInput formControlName="Route">

                                <mat-error>Route
                                    required</mat-error>

                            </mat-form-field>

                        </div>

                        <div class="col-lg-4">
                            <mat-form-field class="example-full-width">
                                <mat-label>Frequency</mat-label>
                                <!-- <mat-select formControlName="Instruction"> -->
                                <input type="text" matInput formControlName="Frequency">


                            </mat-form-field>

                        </div>
                        <div class="col-lg-4">
                            <mat-form-field class="example-full-width">
                                <mat-label>Refill Duration</mat-label>
                                <!-- <input type="text" matInput formControlName="RefillDuration"> -->
                                <input type="text" matInput formControlName="RefillDuration">


                            </mat-form-field>

                        </div>
                    </div>
                    <div class="row">

                        <div class="col-lg-4">
                            <mat-form-field class="example-full-width">
                                <mat-label>Quantity</mat-label>
                                <!-- (input)="getGrandTotal()" -->
                                <input type="number" matInput formControlName="Quantity">


                            </mat-form-field>

                        </div>
                        <div class="col-lg-4">
                            <mat-form-field class="example-full-width">
                                <mat-label>Number of refills</mat-label>
                                <input type="text" matInput formControlName="Numberofrefills">
                                <mat-error>Number of refills required</mat-error>

                            </mat-form-field>

                        </div>
                        <div class="col-lg-4">

                            <p>Status</p>
                            <mat-radio-group formControlName="Status" aria-label="Select an option my-3">
                                <mat-radio-button class="mx-3" value="true">Current</mat-radio-button>
                                <mat-radio-button class="mx-3 " value="false"> Stopped</mat-radio-button>
                            </mat-radio-group>

                        </div>
                    </div>



                    <div class="row">

                        <div class="col-lg-6">
                            <!-- Direction of Use -->
                            <mat-form-field class="w-100">
                                <mat-label>Direction of Use (Patient Sig)</mat-label>

                                <textarea matInput formControlName="Instruction"
                                    placeholder="Enter directions"></textarea>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-6">
                            <!-- Note -->
                            <mat-form-field class="w-100">
                                <mat-label>Comments to Pharmacy</mat-label>
                                <textarea matInput formControlName="Note" placeholder="Enter note"></textarea>
                            </mat-form-field>
                        </div>


                    </div>
                    <div class="row">


                        <div class="col-lg-4">
                            <mat-form-field class="example-full-width">
                                <mat-label>Start Date</mat-label>
                                <input type="date" matInput formControlName="StartDate">
                                <mat-error>Start Date required</mat-error>

                            </mat-form-field>

                        </div>
                        <div class="col-lg-4">
                            <mat-form-field class="example-full-width">
                                <mat-label>End Date</mat-label>
                                <input type="date" matInput formControlName="EndDate">
                                <mat-error>End Date required</mat-error>

                            </mat-form-field>

                        </div>

                        <div class="col-lg-4">
                            <mat-form-field class="example-full-width">
                                <mat-label>Doctor Name </mat-label>
                                <input type="text" formControlName="DoctorName" matInput>
                                <mat-error>Doctor Name</mat-error>
                            </mat-form-field>
                        </div>

                    </div>








                </div>





            </div>




            <button class="float-right lgBtCl" mat-button>
                <mat-icon>save</mat-icon>
                Save</button>
            <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
                Successfully Created Prescription
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </form>

    </div>
</div>

<!-- <div class="col-lg-6">
                    <mat-form-field class="example-full-width">
                      <mat-label>Signature</mat-label>
                      <input type="text" formControlName="DoctorSignature" matInput>
                      <mat-error>Sign required</mat-error>
                    </mat-form-field>
                  </div> -->