// claim.service.ts
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError, map, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ClaimService {
  url = environment.Url + 'Claims';
  claimsUrl = environment.Url + 'Claims/upload-claim';
  stausUrl = environment.Url + 'Claims/TrackClaim';
  ediUrl = environment.Url + 'Claims/GenerateAndUpload';
  apiUrl = environment.Url + 'Claims/payerlist';
  // private apiUrl = 'https://svc.claim.md/services/payerlist/';

  constructor(private http: HttpClient) {}

  getPayerList(payer_name: string): Observable<any> {
    const headers = new HttpHeaders({
      Accept: 'application/xml',
    });

    // Pass AccountKey as query parameter
    const params = new HttpParams().set('payer_name', payer_name);
    params.set('payer_name', payer_name);

    return this.http.post(`${this.url}/payerlist`, {
      headers,
      params,
      responseType: 'text',
    });
  }
  sendClaimToInsurance(formData: FormData): Observable<any> {
    // return this.http.post(this.claimsUrl, formData);
    return this.http.post<string>(this.claimsUrl, formData, {
      responseType: 'text' as 'json',
    });
  }

  sendClaimToBackend(claimData: any): Observable<any> {
    return this.http.post(this.ediUrl, claimData, { responseType: 'text' });
  }

  getUploadList(accountKey: string): Observable<any> {
    const headers = new HttpHeaders({
      Accept: 'application/xml',
    });

    // Pass AccountKey as query parameter
    const params = new HttpParams().set('AccountKey', accountKey);

    return this.http.get(`${this.url}/get-claim-response`, {
      headers,
      params,
      responseType: 'text',
    });
  }
  getEraList(accountKey: string): Observable<any> {
    const headers = new HttpHeaders({
      Accept: 'application/xml',
    });

    // Pass AccountKey as query parameter
    const params = new HttpParams().set('AccountKey', accountKey);

    return this.http.get(`${this.url}/get-era-list`, {
      headers,
      params,
      responseType: 'text',
    });
  }
  getClaimNotes(accountKey: string): Observable<any> {
    const headers = new HttpHeaders({
      Accept: 'application/xml',
    });

    // Pass AccountKey as query parameter
    const params = new HttpParams().set('AccountKey', accountKey);

    return this.http.get(`${this.url}/get-claim-notes`, {
      headers,
      params,
      responseType: 'text',
    });
  }

  getEraPdf(eraId: string): Observable<any> {
    const headers = new HttpHeaders({
      Accept: 'application/xml',
    });

    // Pass AccountKey as query parameter

    return this.http.get(`${this.url}/get-era-pdf?eraId=${eraId}`, {
      headers,
      responseType: 'text',
    });
  }
  getClaimById(accountKey: string, claimId: string): Observable<any> {
    const headers = new HttpHeaders({
      Accept: 'application/xml',
    });

    // Pass AccountKey as query parameter
    const params = new HttpParams().set('ClaimId', claimId);
    params.set('ClaimId', claimId);

    return this.http.get(`${this.url}/get-claim-by-id`, {
      headers,
      params,
      responseType: 'text',
    });
  }
  // getUploadList(accountKey: string): Observable<any> {
  //   return this.http.get(`${this.url}/get-upload-list`, {
  //     params: { AccountKey: accountKey },
  //   });
  // }
  getClaimStatus(accountKey: string, uploadId: string): Observable<any> {
    return this.http.get(`${this.url}/get-claim-status`, {
      params: { AccountKey: accountKey, UploadID: uploadId },
    });
  }

  trackClaim(inboundId: string): Observable<any> {
    return this.http
      .post(this.stausUrl, { InboundId: inboundId }, { responseType: 'text' })
      .pipe(
        map((xmlString: string) => {
          // Parse the XML response
          const parser = new DOMParser();
          const xmlDoc = parser.parseFromString(xmlString, 'application/xml');

          // Convert XML to a JavaScript object
          const result = xmlDoc.getElementsByTagName('result')[0];
          return { result: result ? result.textContent : null };
        })
      );
  }
}
function parseString(
  xml: string,
  arg1: { explicitArray: boolean },
  arg2: (err: any, json: any) => void
) {
  throw new Error('Function not implemented.');
}

// getEraList(): Observable<any> {
//   const headers = new HttpHeaders({
//     Accept: 'application/xml',
//   });

//   return this.http.get(`${this.url}/get-era-list`, {
//     headers,
//     responseType: 'text',
//   });
// }
// getClaimNotes(): Observable<any> {
//   const headers = new HttpHeaders({
//     Accept: 'application/xml',
//   });

//   return this.http.get(`${this.url}/get-claim-notes`, {
//     headers,
//     responseType: 'text',
//   });
// }
