<div class="container mt-5">
    <div class="row">
        <div class="col-lg regFrm mat-elevation-z8">
            <form (keydown.enter)="$event.preventDefault()" [formGroup]="Frm" (ngSubmit)="formSubmit()"
                class="example-form">
                <h1 class="text-center my-5">New Electromyography</h1>

                <!-- Input Fields Section -->
                <div class="row">
                    <div class="col-lg-3">
                        <mat-form-field class="example-full-width">
                            <mat-label>Side</mat-label>
                            <input type="text" formControlName="Side" matInput />
                            <mat-error *ngIf="Frm.get('Side').invalid">Please enter a value</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-3">
                        <mat-form-field class="example-full-width">
                            <mat-label>Muscle</mat-label>
                            <input type="text" formControlName="Muscle" matInput />
                            <mat-error *ngIf="Frm.get('Muscle').invalid">Please enter a value</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-3">
                        <mat-form-field class="example-full-width">
                            <mat-label>Nerve</mat-label>
                            <input type="text" formControlName="Nerve" matInput />
                            <mat-error *ngIf="Frm.get('Nerve').invalid">Please enter a value</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-3">
                        <mat-form-field class="example-full-width">
                            <mat-label>Root</mat-label>
                            <input type="text" formControlName="Root" matInput />
                            <mat-error *ngIf="Frm.get('Root').invalid">Please enter a value</mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-3">
                        <mat-form-field class="example-full-width">
                            <mat-label>Ins.Act</mat-label>
                            <input type="text" formControlName="InsAct" matInput />
                            <mat-error *ngIf="Frm.get('InsAct').invalid">Please enter a value</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-3">
                        <mat-form-field class="example-full-width">
                            <mat-label>Fibs</mat-label>
                            <input type="text" formControlName="Fibs" matInput />
                            <mat-error *ngIf="Frm.get('Fibs').invalid">Please enter a value</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-3">
                        <mat-form-field class="example-full-width">
                            <mat-label>Psw</mat-label>
                            <input type="text" formControlName="Psw" matInput />
                            <mat-error *ngIf="Frm.get('Psw').invalid">Please enter a value</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-3">
                        <mat-form-field class="example-full-width">
                            <mat-label>Amp</mat-label>
                            <input type="text" formControlName="Amp" matInput />
                            <mat-error *ngIf="Frm.get('Amp').invalid">Please enter a value</mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-3">
                        <mat-form-field class="example-full-width">
                            <mat-label>Duration</mat-label>
                            <input type="text" formControlName="Duration" matInput />
                            <mat-error *ngIf="Frm.get('Duration').invalid">Please enter a value</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-3">
                        <mat-form-field class="example-full-width">
                            <mat-label>Poly</mat-label>
                            <input type="text" formControlName="Poly" matInput />
                            <mat-error *ngIf="Frm.get('Poly').invalid">Please enter a value</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-3">
                        <mat-form-field class="example-full-width">
                            <mat-label>Recrt</mat-label>
                            <input type="text" formControlName="Recrt" matInput />
                            <mat-error *ngIf="Frm.get('Recrt').invalid">Please enter a value</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-3">
                        <mat-form-field class="example-full-width">
                            <mat-label>Int.Pat</mat-label>
                            <input type="text" formControlName="IntPat" matInput />
                            <mat-error *ngIf="Frm.get('IntPat').invalid">Please enter a value</mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <!-- Comments Section -->
                <div class="row">
                    <div class="col-lg-12">
                        <mat-form-field class="example-full-width">
                            <mat-label>Comments</mat-label>
                            <textarea matInput formControlName="Comment" cdkTextareaAutosize cdkAutosizeMinRows="2"
                                cdkAutosizeMaxRows="5"></textarea>
                            <mat-error *ngIf="Frm.get('Comment').invalid">Please enter comments</mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <!-- Submit and Reset Buttons -->
                <div class="row mt-4">
                    <div class="col-lg-6 text-left">
                        <button type="button" class="btn btn-danger" (click)="resetFn($event)" mat-raised-button>
                            Reset
                        </button>
                    </div>
                    <div class="col-lg-6 text-right">
                        <button type="submit" [disabled]="Frm.invalid" class="btn btn-primary" mat-raised-button>
                            <mat-icon>save</mat-icon>
                            Save
                        </button>
                    </div>
                </div>

                <!-- Success Message -->
                <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
                    Successfully submitted
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>