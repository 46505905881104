import { Component } from '@angular/core';

@Component({
  selector: 'app-era-pdf',
  templateUrl: './era-pdf.component.html',
  styleUrls: ['./era-pdf.component.css']
})
export class EraPdfComponent {

}
