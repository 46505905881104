<app-navbar></app-navbar>
<div class="container">
    <div class="row">

        <div class="col-lg-4 lgForm mat-elevation-z6">
            <form (keydown.enter)="$event.preventDefault()" [formGroup]="logiForm" (ngSubmit)="loginSubmit()"
                class="example-form">
                <mat-form-field class="example-full-width">
                    <mat-label>UserName</mat-label>
                    <input type="text" formControlName="UserName" matInput>
                    <mat-error *ngIf="logiForm.controls.UserName.invalid"></mat-error>

                </mat-form-field>

                <!-- <mat-form-field class="example-full-width">
          <mat-label>Password</mat-label>
          <input type="password" matInput formControlName="Password">
        </mat-form-field> -->
                <mat-form-field class="example-full-width">
                    <mat-label> Password</mat-label>
                    <input matInput [type]="passwordFieldType" formControlName="Password" placeholder="New Password" />
                    <button mat-icon-button matSuffix type="button" (click)="togglePasswordVisibility()"
                        [attr.aria-label]="'Toggle password visibility'">
                        <mat-icon>{{ passwordFieldType === 'password' ? 'visibility' : 'visibility_off' }}</mat-icon>
                    </button>
                    <mat-error *ngIf="logiForm.controls.Password.invalid">
                        Password is required.
                    </mat-error>
                </mat-form-field>



                <button class="float-right lgBtCl" mat-button color="primary">
                    <mat-icon>login</mat-icon>
                    Login</button>



                <!-- <h4> <a [routerLink]="['/privacy-policy/']" class="clr" routerLinkActive="active">Privacy Policy</a></h4> -->
                <h4> <a [routerLink]="['/resetpassword']" class="clr" routerLinkActive="active">Forgot Password</a></h4>
            </form>
            <div *ngIf="error" class="alert alert-danger mt-2">{{netWorkFailure}}</div>

            <div *ngIf="IsthrowError" class="alert alert-danger mt-2">{{IsthrowError}}</div>
        </div>

    </div>

</div>