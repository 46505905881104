<div class="container">
    <div class="row">
        <div class="col-lg regFrm mat-elevation-z8">
            <h2>Update Nurse</h2>
            <form (keydown.enter)="$event.preventDefault()" [formGroup]="nurseFrm" (ngSubmit)="formSubmit()"
                class="example-form">
                <div class="row">
                    <div class="col-lg-4">
                        <mat-form-field appearance="fill" class="example-full-width">
                            <mat-label>Select Department</mat-label>
                            <mat-select formControlName="DepartmentId">

                                <mat-option *ngFor="let dpt of dptList"
                                    [value]="dpt.DepartmentId">{{dpt.DepartmentName}}</mat-option>

                            </mat-select>

                            <mat-error *ngIf="nurseFrm.get('DepartmentId')?.hasError('required')">You must make a
                                selection</mat-error>

                        </mat-form-field>
                    </div>

                    <div class="col-lg-6">
                        Upload Picture <input type="file" (change)="image($event)">


                    </div>

                </div>
                <div class="row">
                    <div class="col-lg-3">

                        <mat-form-field class="example-full-width">
                            <mat-label>Name of Nurse</mat-label>
                            <input type="text" formControlName="NurseName" matInput>
                            <mat-error *ngIf="nurseFrm.get('NurseName')?.hasError('required')">Please enter Nurse
                                Name</mat-error>
                        </mat-form-field>

                    </div>
                    <div class="col-lg-3">

                        <mat-form-field class="example-full-width">
                            <mat-label>NPI Number (If any)</mat-label>
                            <input type="text" formControlName="NpiNumber" matInput>
                            <mat-error>Please enter Npi Number</mat-error>
                        </mat-form-field>

                    </div>
                    <div class="col-lg-3">
                        <mat-form-field class="example-full-width">
                            <mat-label>Nurse Qualification</mat-label>
                            <input type="text" formControlName="NurseQualification" matInput>
                            <mat-error *ngIf="nurseFrm.get('NurseQualification')?.hasError('required')">Please enter
                                Nurse
                                Qualification</mat-error>
                        </mat-form-field>

                    </div>
                    <div class="col-lg-3">
                        <mat-form-field class="example-full-width">
                            <mat-label>Nurse Speciality</mat-label>

                            <mat-select formControlName="NursesSpeciality">

                                <mat-option *ngFor="let sp of NurseSpeciality" [value]="sp">{{sp}}</mat-option>

                            </mat-select>
                            <mat-error *ngIf="nurseFrm.get('NursesSpeciality')?.hasError('required')">please select an
                                item</mat-error>
                        </mat-form-field>

                    </div>

                </div>
                <div class="row">
                    <div class="col-lg-3">

                        <mat-form-field class="example-full-width">
                            <mat-label>Nurse Postion</mat-label>
                            <input type="text" formControlName="NursePostion" matInput>
                            <mat-error>Please enter Nurse Postion</mat-error>
                        </mat-form-field>

                    </div>
                    <div class="col-lg-3">
                        <mat-form-field class="example-full-width">
                            <mat-label> Is Licenced</mat-label>
                            <input type="text" formControlName="IsLicenced" matInput>
                            <mat-error *ngIf="nurseFrm.get('IsLicenced')?.hasError('required')">Please enter Is
                                Licenced</mat-error>
                        </mat-form-field>

                    </div>
                    <div class="col-lg-3">
                        <mat-form-field class="example-full-width">
                            <mat-label> Licence Number</mat-label>
                            <input type="text" formControlName="LicenceNumber" matInput>
                            <mat-error>Please enter LicenceNumber</mat-error>
                        </mat-form-field>

                    </div>
                    <div class="col-lg-3">

                        <mat-form-field class="example-full-width">
                            <mat-label>National Id</mat-label>
                            <input type="text" formControlName="SSN" matInput>
                            <mat-error *ngIf="nurseFrm.get('SSN')?.hasError('required')">Please enter SSN</mat-error>
                        </mat-form-field>
                    </div>

                </div>
                <div class="row">
                    <div class="col-lg-4">

                        <mat-form-field class="example-full-width">
                            <mat-label>Nurse D.O.B</mat-label>
                            <input type="date" formControlName="NurseDOB" matInput>
                            <mat-error *ngIf="nurseFrm.get('NurseDOB')?.hasError('required')">Please enter Nurse
                                D.O.B</mat-error>
                        </mat-form-field>

                    </div>
                    <div class="col-lg-4">
                        <mat-form-field class="example-full-width">
                            <mat-label> Nurse Email</mat-label>
                            <input type="text" formControlName="Email" matInput>
                            <mat-error *ngIf="nurseFrm.get('Email')?.hasError('required')">Please enter Nurse
                                Email</mat-error>
                        </mat-form-field>

                    </div>
                    <div class="col-1">
                        <mat-form-field class="example-full-width">
                            <mat-label>Country Code</mat-label>
                            <input type="text" formControlName="CountryCode" [matAutocomplete]="auto" matInput
                                placeholder="+1, +91">
                            <mat-error>Please enter a Phone Number with country code eg: +1,+91,+44</mat-error>
                            <mat-autocomplete #auto="matAutocomplete">
                                <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                                    {{ option }}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-3">

                        <mat-form-field class="example-full-width">
                            <mat-label>Nurse Phone</mat-label>
                            <input type="number" formControlName="PhoneNumber" matInput>
                            <mat-error *ngIf="nurseFrm.get('PhoneNumber')?.hasError('required')">Please enter Nurse
                                Phone</mat-error>
                        </mat-form-field>
                    </div>

                </div>
                <div class="row">
                    <div class="col-lg-2">

                        <mat-form-field class="example-full-width">
                            <mat-label> Start Date</mat-label>
                            <input type="date" formControlName="StartDate" matInput>
                            <mat-error>Please enter StartDate</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-2">

                        <mat-form-field class="example-full-width">
                            <mat-label> Password</mat-label>
                            <input type="text" formControlName="Password" matInput>
                            <mat-error>Please enter Password</mat-error>
                        </mat-form-field>

                        <span *ngIf="nurseFrm.get('Password')?.touched && nurseFrm.get('Password')?.invalid">
                            <mat-error *ngIf="nurseFrm.get('Password')?.errors.required">Please enter
                                Password</mat-error>
                            <mat-error *ngIf="nurseFrm.get('Password')?.errors.pattern"> Password must contain more than
                                7 characters,
                                1 numeric, 1 upper case letter, and 1 special character($ $ !%*?&).</mat-error>
                        </span>
                    </div>

                    <div class="col-lg-8">

                        <mat-form-field class="example-full-width">
                            <mat-label> Nurse Address</mat-label>
                            <input type="text" formControlName="NurseAddress" matInput>
                            <mat-error>Please enter Nurse Address</mat-error>
                        </mat-form-field>
                    </div>

                </div>

                <div class="row">
                    <div class="col-lg-4">

                        <p>Status</p>
                        <mat-radio-group formControlName="Status" aria-label="Select an option my-3">
                            <mat-radio-button class="mx-3" value="true">Active</mat-radio-button>
                            <mat-radio-button class="mx-3 " value="false"> Inactive</mat-radio-button>
                        </mat-radio-group>

                    </div>
                </div>

                <div class="row mt-5">
                    <h2>Capture Image from Camera</h2>
                    <div class="col-md-12">
                        <webcam [trigger]="invokeObservable" (imageCapture)="captureImg($event)" [height]="180"
                            [width]="180">
                        </webcam>
                    </div>
                    <div class="col-md-12">
                        <button type="button" class="btn btn-danger" (click)="getSnapshot()" type="button">
                            Capture Image
                        </button>
                    </div>
                    <div class="col-12">
                        <div id="results">Your taken image manifests here...</div>
                        <img [src]="webcamImage?.imageAsDataUrl" height="180px" />
                    </div>
                </div>
                <button class="float-right lgBtCl" mat-button>
                    <mat-icon>save</mat-icon>
                </button>
                <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
                    Successfully Registered Nurse
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </form>



        </div>
    </div>

</div>


<!-- Modal -->
<div class="modal-backdrop" *ngIf="showModal"></div>
<div class="modal" *ngIf="showModal">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Alert</h5>
                <button type="button" class="btn-close" (click)="closeModal()"></button>
            </div>
            <div class="modal-body">
                <p>{{ modalMessage }}</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="closeModal()">OK</button>
            </div>
        </div>
    </div>
</div>