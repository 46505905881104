import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Department } from 'src/app/Models/department.model';
import { Invoice } from 'src/app/Models/invoice.model';
import { Patient } from 'src/app/Models/patient.model';
import { BillerService } from 'src/app/services/biller.service';
import { DepartmentService } from 'src/app/services/department.service';
import { HospitalService } from 'src/app/services/hospital.service';
import { PatientService } from 'src/app/services/patient.service';
import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-biller-dash-board',
  templateUrl: './biller-dash-board.component.html',
  styleUrls: ['./biller-dash-board.component.css'],
})
export class BillerDashBoardComponent implements OnInit {
  displayedColumns: string[] = [
    'createdDate',
    'Id',
    'PatientName',
    'DoctorName',
    'CoPay',
    'InsurancePay',
    'BalanceAmount',
    'Total',
    'Status',
    'Detail',
  ];
  userList: Patient[] = [];
  id = 0;
  patientList: Patient[] = [];
  filteredList;
  dptList: Department;
  dataSource: MatTableDataSource<Invoice>;
  helper = new JwtHelperService();

  myToken = this.tokenService.getToken();

  showItem = '';
  hospitalId: number;
  noDataMessage: string;

  constructor(
    private patientService: PatientService,
    private dptService: DepartmentService,
    private route: ActivatedRoute,
    private router: Router,
    private tokenService: TokenService,
    private billerService: BillerService,
    private hospitalService: HospitalService
  ) {}

  ngOnInit(): void {
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.id = parseInt(decodedToken.nameid);

    this.billerService.getbillerHospital(this.id).subscribe((res) => {
      this.hospitalId = res.Hospital.HospitalId;
      // this.getPatients(this.hospitalId);
      this.getInvoice(this.hospitalId);
    });
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  getPatients(id) {
    this.hospitalService.getPatients(id).subscribe((res) => {
      // this.dataSource = new MatTableDataSource(res.Patients);
      // this.dataSource.paginator = this.paginator;
    });
  }
  getInvoice(id: number) {
    this.hospitalService.GetInvoices(id).subscribe((res) => {
      // Filter invoices where status is 'Pending'
      const pendingInvoices = res.Invoices.filter(
        (invoice: any) => invoice.Status === 'Pending'
      );
      this.dataSource = new MatTableDataSource(pendingInvoices);
      this.dataSource.paginator = this.paginator;
    });
  }

  search(searchValue) {
    // console.log(searchValue);
    if (searchValue.value != null) {
      this.filteredList = this.patientList.filter((x) =>
        x.PatientNationalId.toLowerCase().includes(
          searchValue.value.toLowerCase()
        )
      );
    }
  }

  logout() {
    localStorage.removeItem('token');
    this.router.navigate(['/']);
  }
}
