import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { IcdCode } from 'src/app/Models/icdcode.model';
import { IcdCodeService } from 'src/app/services/icd-code.service';
import { TokenService } from 'src/app/services/token.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog-component';
import { DoctorService } from 'src/app/services/doctor.service';
import { FavoriteDignosisCode } from 'src/app/Models/favoritediagnosiscode.model';
import { FavoriteDignosisCodeService } from 'src/app/services/favorite-dignosis-code.service';
import { forkJoin } from 'rxjs';
@Component({
  selector: 'app-view-icd-codes',
  templateUrl: './view-icd-codes.component.html',
  styleUrls: ['./view-icd-codes.component.css'],
})
export class ViewIcdCodesComponent implements OnInit {
  displayedColumns: string[] = ['select', 'Code', 'Description', 'Delete'];
  cptCodeList: IcdCode[] = [];
  dataSource: MatTableDataSource<IcdCode>;
  selectedFile: File;
  totalItems: number = 100; // Total number of items in the data source
  pageSize: number = 5; // Default page size
  icdCodeData;
  icdDataList: FavoriteDignosisCode[] = [];
  helper = new JwtHelperService();
  myToken = this.tokenService.getToken();
  DoctorId: number;
  isSuccess: boolean;
  navigation: any;
  favoriteDignosisCodes: any[] = [];
  selectedFavorites: any;
  constructor(
    private icdCodeService: IcdCodeService,
    private tokenService: TokenService,
    private dialog: MatDialog,
    private doctorService: DoctorService,
    private favoriteDignosisCodesService: FavoriteDignosisCodeService
  ) {}

  ngOnInit(): void {
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.DoctorId = parseInt(decodedToken.nameid);
    this.getIcdCodes();
    this.loadFavorites(this.DoctorId);
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  getIcdCodes() {
    this.icdCodeService.getIcdCodes().subscribe((res) => {
      this.dataSource = new MatTableDataSource(res);
      this.dataSource.paginator = this.paginator;
      this.cptCodeList = res;
    });
  }

  delete(id) {
    this.icdCodeService.getIcdCodeById(id).subscribe((res) => {
      this.getIcdCodes();
    });
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  onFileSelected(event) {
    this.selectedFile = event.target.files[0];
  }

  uploadFile() {
    const fileReader = new FileReader();
    fileReader.readAsText(this.selectedFile, 'UTF-8');
    fileReader.onload = () => {
      const fileContent = fileReader.result;
      try {
        const data = JSON.parse(fileContent as string);

        // Validate the data structure
        if (!Array.isArray(data.Cptcodes)) {
          // alert('Invalid data structure');
        }
        data.Cptcodes.forEach((item) => {
          if (typeof item.Code !== 'string') {
            // alert('Invalid data structure');
          }
          // Add more checks as necessary...
        });

        this.icdCodeService.UploadJsonIcd10Data(data).subscribe(
          (res) => {
            alert('Upload With success');
            this.getIcdCodes();
          },
          (err) => {
            alert('Error uploading the data');
          }
        );
        // this.http.post('https://your-api-url.com/upload', data)
        // .subscribe(
        //     response => console.log(response),
        //     error => console.log(error)
        // );
      } catch (error) {
        console.error('Invalid JSON format:', error);
      }
    };
    fileReader.onerror = (error) => console.log(error);
  }

  tabChanged(event: MatTabChangeEvent): void {
    if (event.index === 0) {
      this.getIcdCodes();
    }
  }

  onPageChange(event: any) {
    const { pageIndex, pageSize } = event;
    // For simplicity, let's log the page details
    console.log('Page Index:', pageIndex, 'Page Size:', pageSize);
    // Normally, you'd fetch the paged data from a server here
    // For example:
    // this.fetchData(pageIndex, pageSize);
  }

  selectCode(event, row) {
    this.icdCodeData = {
      Code: row.Code,
      Description: row.Description,
      DoctorId: this.DoctorId,
    };

    // console.log('Selected Code Data:', this.icdCodeData);

    const index = this.icdDataList?.findIndex(
      (data) => data.Code === this.icdCodeData.Code
    );

    if (event.checked) {
      if (index === -1) {
        this.icdDataList.push(this.icdCodeData);
      }
    } else if (index > -1) {
      this.icdDataList.splice(index, 1);
    }

    console.log('Updated ICD Data List:', this.icdDataList);
  }

  // emitSelectedIds() {
  //   this.selectedCodeIdsEmitter.emit(this.nursePatient);
  // }
  selectedCode() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Add selected code to favorite',
        content: 'Are you sure you want to Add selected code to favorite?',
      },
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res === true) {
        console.log('Submitting the following codes:', this.icdDataList);

        this.favoriteDignosisCodesService
          .saveMultipleFavoriteDignosisCodes(this.icdDataList)
          .subscribe({
            next: (response) => {
              console.log('Batch insert successful:', response);
              this.isSuccess = true;
            },
            error: (error) => {
              console.error('Batch insert error:', error);
            },
          });
        setTimeout(() => {
          // this.navigation.emit();
        }, 2000);
      }
    });
  }

  loadFavorites(id) {
    this.doctorService.getDoctorFavoriteDiagnosisCodes(id).subscribe((res) => {
      this.favoriteDignosisCodes = res.FavoriteDignosisCodes;
    });
  }
  // Handle favorite selection
  onFavoriteSelect(favorite: any, event: any) {
    if (event.checked) {
      this.selectedFavorites.push(favorite);
    } else {
      this.selectedFavorites = this.selectedFavorites.filter(
        (item) =>
          item.FavoriteDignosisCodeId !== favorite.FavoriteDignosisCodeId
      );
    }
  }
  isEditing: boolean = false;
  editingIndex: number | null = null;

  editFavorite(favorite: any, index: number): void {
    this.isEditing = true;
    this.editingIndex = index;
  }

  saveEditFavorite(id: number, updatedFavorite: any): void {
    this.favoriteDignosisCodesService
      .updateFavoriteDignosisCode(id, updatedFavorite)
      .subscribe(() => {
        alert('Favorite updated successfully!');
        this.isEditing = false;
        this.editingIndex = null;
        this.loadFavorites(this.DoctorId); // Reload the list to reflect changes
      });
  }

  cancelEdit(): void {
    this.isEditing = false;
    this.editingIndex = null;
  }

  deleteFavorite(id: number, index: number): void {
    if (
      confirm('Are you sure you want to delete this favorite prescription?')
    ) {
      this.favoriteDignosisCodesService
        .deleteFavoriteDignosisCode(id)
        .subscribe(() => {
          alert('Favorite deleted successfully!');
          this.favoriteDignosisCodes.splice(index, 1); // Remove from local array
        });
    }
  }
}
