import { HttpClient } from '@angular/common/http';
import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ToastrService } from 'ngx-toastr';
import { EmailPayload } from 'src/app/Models/email.model';
import { Hospital } from 'src/app/Models/hospital.model';
import { InvoiceService } from 'src/app/services/invoice.service';
import { PatientService } from 'src/app/services/patient.service';
import { PatientinsuranceService } from 'src/app/services/patientinsurance.service';
import { environment } from 'src/environments/environment';
import { StripeScriptTag, StripeSource } from 'stripe-angular';

import { TokenService } from 'src/app/services/token.service';
import html2pdf from 'html2pdf.js';
import { PaymentmethodService } from 'src/app/services/paymentmethod.service';

@Component({
  selector: 'app-print-invoice-pt-side',
  templateUrl: './print-invoice-pt-side.component.html',
  styleUrls: ['./print-invoice-pt-side.component.css'],
})
export class PrintInvoicePtSideComponent implements OnInit {
  @ViewChild('Invoice') Invoice!: ElementRef;
  helper = new JwtHelperService();
  patientId = 0;
  myToken = this.tokenService.getToken();
  id = 0;
  invoice;
  invalidError = {};
  isPaymentSuccess = false;
  partialAmount = 0;
  email = new FormControl('');
  hide = true;
  insurance;
  hospital: Hospital;
  @ViewChild('stripeCard') stripeCard: any;
  hospitalId: number;
  constructor(
    private invoiceService: InvoiceService,
    private route: ActivatedRoute,
    private router: Router,
    private patientService: PatientService,
    private http: HttpClient,
    private toastr: ToastrService,
    private patientInsurance: PatientinsuranceService,
    private tokenService: TokenService,

    private paymentMethodService: PaymentmethodService,
    private stripeScriptTag: StripeScriptTag // @Inject(MAT_DIALOG_DATA) // public data: { InvoiceId: string }, // private dialogRef: MatDialogRef<PrintInvoicePtSideComponent>
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    // const InvoiceId = this.data.InvoiceId;

    this.getPtInvoice(this.id);
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.patientId = parseInt(decodedToken.nameid);
    this.patientService.getpatienHospitalId(this.id).subscribe((res) => {
      this.hospitalId = res.Hospital.HospitalId;
      this.getPaymentMethod(this.hospitalId);
    });
  }
  getPaymentMethod(id) {
    this.paymentMethodService
      .getStripeKeyByProviderId(id)
      .subscribe((response: any) => {
        this.stripeScriptTag.setPublishableKey(response.publishableKey);
      });
  }
  getPatientInfo(id) {
    this.patientService.getPatientById(id).subscribe({
      next: (res) => {
        this.invoice['PatientId'] = res.PatientId;
        this.invoice['PatientAddress'] = res.PatientAddress;
        this.invoice['PatientDOB'] = res.PatientDOB;
        this.invoice['Email'] = res.Email;
      },
    });
  }

  getPtInvoice(id) {
    this.invoiceService.getInvoiceItems(id).subscribe((res: any) => {
      this.getPatientInfo(res.PatientId);
      this.getPatientHospital(res.PatientId);
      this.getPatientInsurance(res.PatientId);
      this.invoice = res;
    });
  }

  getPatientHospital(id) {
    this.patientService.getpatienHospitalId(id).subscribe((res) => {
      this.hospital = res.Hospital;
    });
  }
  getPatientInsurance(id) {
    this.patientService.getPatientInsurance(id).subscribe((res) => {
      this.insurance = res;
    });
  }
  printPage() {
    window.print();
  }
  printPageArea(areaID) {
    var printContent = document.getElementById(areaID).innerHTML;
    var originalContent = document.body.innerHTML;
    document.body.innerHTML = printContent;
    window.print();
    document.body.innerHTML = originalContent;
  }
  downloadAsPDF() {
    const content = document.getElementById('printable');
    const button = document.querySelector('button'); // Select the button
    if (content) {
      button?.classList.add('downloadButton');
      // Configure html2pdf options
      const options = {
        margin: 1, // Margins in cm
        filename: 'medical-record.pdf', // Output file name
        image: { type: 'jpeg', quality: 0.98 }, // Image quality
        html2canvas: { scale: 2 }, // Scale for higher resolution
        jsPDF: { unit: 'cm', format: 'a4', orientation: 'portrait' }, // A4 portrait format
      };

      // Generate PDF
      html2pdf().set(options).from(content).save();
    }
  }
  sendtoInsurance() {
    let body = {
      Email: this.email.value,
      Subject: 'Invoice' + this.invoice.InvoiceId,
      Template: `${this.Invoice.nativeElement.innerHTML}`,
    };
    this.patientService.sendInvoiceToInsurance(body).subscribe((res) => {
      alert('Invoice sent');
    });
  }

  sendInvoice(): void {
    let body: EmailPayload = {
      Email: this.invoice['PatientEmail'],
      Subject: 'Invoice' + this.invoice.InvoiceId,
      Template: `${this.Invoice.nativeElement.innerHTML}`,
    };

    this.patientService.sendInvoice(body).subscribe({
      next: (res) => alert('Invoice sent'),
    });
  }

  cardReady = false;
  extraData = {
    name: null,
    address_city: null,
    address_line1: null,
    address_line2: null,
    address_state: null,
    address_zip: null,
    price: 30,
  };

  onStripeInvalid(error: Error) {
    // console.log('Validation Error', error)
  }

  createStripeToken() {
    // Use the `stripe-card` directive to create a token
    if (this.stripeCard) {
      this.stripeCard.createToken().then((result: any) => {
        if (result.error) {
          this.invalidError = result.error.message;
          console.error('Error creating token:', result.error.message);
        } else {
          this.setStripeToken(result.token);
        }
      });
    } else {
      console.error('Stripe Card Element is not initialized');
    }
  }
  setStripeToken(token) {
    // console.log('Stripe token', token)
    let body = {
      TransactionId: token.id,
      InvoiceId: this.invoice.InvoiceId,
      Amount: this.invoice.TotalAmount,
      PaymentType: 'Card Payment',
      PatientId: this.invoice['PatientId'],
    };

    this.http.post(environment.Url + 'Payments', body).subscribe(
      (res) => {
        if (res['status'] == 'succeeded') {
          this.isPaymentSuccess = true;
        }
      },
      (err) => console.log(err)
    );
  }

  parialPayement(token, amount: number) {
    if (amount < 0) {
      this.toastr.error('You need to enter an amount greater then 0');
      return;
    }

    let body = {};
    if (amount < this.invoice.TotalAmount) {
      body = {
        TransactionId: token.id,
        InvoiceId: this.invoice.InvoiceId,
        Amount: amount,
        PaymentType: 'Card Payment',
        PatientId: this.invoice['PatientId'],
      };
    } else {
      body = {
        TransactionId: token.id,
        InvoiceId: this.invoice.InvoiceId,
        Amount: this.invoice.TotalAmount,
        PaymentType: 'Card Payment',
        PatientId: this.invoice['PatientId'],
      };
    }

    this.http.post(environment.Url + 'Payments', body).subscribe(
      (res) => {
        if (res['status'] == 'succeeded') {
          this.isPaymentSuccess = true;
        }
      },
      (err) => console.log(err)
    );
  }

  setStripeSource(source: StripeSource) {
    // console.log('Stripe source', source)
  }

  onStripeError(error: Error) {
    // console.error('Stripe error')
  }
  // onClose(): void {
  //   this.dialogRef.close();
  // }
}
