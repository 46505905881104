<div class="container">
  <div class="row">
    <div class="col-lg regFrm mat-elevation-z8">
      <h2>New Patient</h2>
      <form (keydown.enter)="$event.preventDefault()" [formGroup]="ptFrm" (ngSubmit)="formSubmit()"
        class="example-form">

        <div class="row">

          <div class="col-lg-4">

            <mat-form-field class="example-full-width">
              <mat-label>First Name</mat-label>
              <input type="text" formControlName="PatientFirstName" matInput>
              <mat-error>Please enter First Name</mat-error>
              <mat-error *ngIf="ptFrm.get('PatientFirstName')?.hasError('required')">Please enter First Name</mat-error>
            </mat-form-field>

          </div>
          <div class="col-lg-4">

            <mat-form-field class="example-full-width">
              <mat-label>Last Name</mat-label>
              <input type="text" formControlName="PatientLastName" matInput>
              <mat-error *ngIf="ptFrm.get('PatientLastName')?.hasError('required')">Please enter Last Name</mat-error>
            </mat-form-field>

          </div>
          <div class="col-lg-4">

            <mat-form-field class="example-full-width">
              <mat-label>Birth Date</mat-label>
              <input type="date" formControlName="PatientDOB" matInput>
              <mat-error *ngIf="ptFrm.get('PatientDOB')?.hasError('required')">Please enter D.O.B</mat-error>
            </mat-form-field>

          </div>
        </div>

        <div class="row">
          <div class="col-lg-4">

            <mat-form-field class="example-full-width">
              <mat-label>Gender</mat-label>
              <mat-select formControlName="PatientGender" required>
                <mat-option value="M">Male</mat-option>
                <mat-option value="F">Female</mat-option>
                <mat-option value="U">Unknown</mat-option>
              </mat-select>

              <!-- <mat-select formControlName="PatientGender">

                <mat-option *ngFor="let sex of Gender" [value]="sex">{{sex}}</mat-option>

              </mat-select> -->
              <mat-error *ngIf="ptFrm.get('PatientGender')?.hasError('required')">Please select an option</mat-error>

            </mat-form-field>

          </div>
          <div class="col-lg-4">

            <mat-form-field class="example-full-width">
              <mat-label>Email</mat-label>
              <input type="email" formControlName="Email" matInput>
              <mat-error *ngIf="ptFrm.get('Email')?.hasError('required')">Please enter Email</mat-error>
            </mat-form-field>

          </div>
          <div class="col-1">
            <mat-form-field class="example-full-width">
              <mat-label>Country Code</mat-label>
              <input type="text" formControlName="CountryCode" [matAutocomplete]="auto" matInput placeholder="+1, +91">
              <mat-error *ngIf="ptFrm.get('CountryCode')?.hasError('required')">Please enter a Phone Number with country
                code eg: +1,+91,+44</mat-error>
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                  {{ option }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="col-lg-3">

            <mat-form-field class="example-full-width">
              <mat-label>Phone Number</mat-label>
              <input type="number" formControlName="PhoneNumber" matInput>
              <mat-error *ngIf="ptFrm.get('PhoneNumber')?.hasError('required')">Please enter Cell Phone
                Number</mat-error>
            </mat-form-field>

          </div>
        </div>


        <div class="row">
          <div class="col-lg-3">
            <mat-form-field class="example-full-width">
              <mat-label>Doctor Name</mat-label>
              <mat-select formControlName="DoctorId" (selectionChange)="onDoctorChange($event.value)">
                <mat-option *ngFor="let doctor of doctorList" [value]="doctor.DoctorId">
                  {{ doctor.DoctorName }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="ptFrm.get('DoctorId')?.hasError('required')">
                Please select Doctor
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-lg-3">
            <mat-form-field class="example-full-width">
              <mat-label>Doctor Specialization</mat-label>
              <input type="text" [value]="selectedDoctor?.DoctorSpecialization" formControlName="DoctorSpecialization"
                matInput readonly />
              <mat-error *ngIf="ptFrm.get('DoctorSpecialization')?.hasError('required')">
                Select a Doctor specialization
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-lg-3">

            <mat-form-field class="example-full-width">
              <mat-label>Primary Doctors Name</mat-label>
              <input type="text" formControlName="PrimaryDoctorsName" matInput>
              <mat-error *ngIf="ptFrm.get('PrimaryDoctorsName')?.hasError('required')">Please enter Primary Doctor
                Name</mat-error>
            </mat-form-field>

          </div>
          <div class="col-lg-3">

            <mat-form-field class="example-full-width">
              <mat-label>Password</mat-label>
              <input type="text" formControlName="Password" matInput>

            </mat-form-field>
            <span *ngIf="ptFrm.get('Password')?.touched && ptFrm.get('Password')?.invalid">
              <mat-error *ngIf="ptFrm.get('Password')?.errors.required">Please enter Password</mat-error>
              <mat-error *ngIf="ptFrm.get('Password')?.errors.pattern"> Password must contain more than 7 characters, 1
                numeric, 1 upper case letter, and 1 special character($$!%*?&).</mat-error>
            </span>
          </div>

        </div>
        <div class="row">
          <div class="col-lg-3">

            <mat-form-field class="example-full-width">
              <mat-label> National Id</mat-label>
              <input type="text" formControlName="PatientNationalId" matInput>
              <mat-error *ngIf="ptFrm.get('PatientNationalId')?.hasError('required')">Please enter Patient
                NationalId</mat-error>
            </mat-form-field>

          </div>
          <div class="col-lg-3">

            <mat-form-field class="example-full-width">
              <mat-label>Blood Group</mat-label>
              <!-- <input type="text" formControlName="BloodGroup" matInput > -->
              <!-- <mat-error >Please enter Blood Group</mat-error> -->

              <mat-select formControlName="BloodGroup">

                <mat-option *ngFor="let bl of bloodGroup" [value]="bl">{{bl}}</mat-option>

              </mat-select>
              <mat-error *ngIf="ptFrm.get('BloodGroup')?.hasError('required')">Please select Blood Group</mat-error>
            </mat-form-field>

          </div>
          <div class="col-lg-3">

            <mat-form-field class="example-full-width">
              <mat-label>Marital Status</mat-label>
              <!-- <input type="text" formControlName="MaritalStatus" matInput > -->
              <!-- <mat-error >Please enter Patient Marital Status</mat-error> -->
              <mat-select formControlName="MaritalStatus">

                <mat-option *ngFor="let ms of maritalStatus" [value]="ms">{{ms}}</mat-option>

              </mat-select>
              <mat-error *ngIf="ptFrm.get('MaritalStatus')?.hasError('required')">Please select Marital
                Status</mat-error>

            </mat-form-field>

          </div>
          <div class="col-lg-3">

            <mat-form-field class="example-full-width">
              <mat-label>Occupation</mat-label>
              <input type="text" formControlName="Occupation" matInput>
              <mat-error *ngIf="ptFrm.get('Occupation')?.hasError('required')">Please enter Occupation</mat-error>
            </mat-form-field>

          </div>
        </div>
        <div class="row">
          <div class="col-lg-4">

            <mat-form-field class="example-full-width">
              <mat-label>Height</mat-label>
              <input type="text" formControlName="PatientHeight" matInput>
              <mat-error *ngIf="ptFrm.get('PatientHeight')?.hasError('required')">Please enter Patient
                Height</mat-error>
            </mat-form-field>

          </div>
          <div class="col-lg-4">

            <mat-form-field class="example-full-width">
              <mat-label>Weight</mat-label>
              <input type="text" formControlName="PatientWeight" matInput>
              <mat-error *ngIf="ptFrm.get('PatientWeight')?.hasError('required')">Please enter Patient
                Weight</mat-error>
            </mat-form-field>

          </div>
          <div class="col-lg-4">

            <mat-form-field class="example-full-width">
              <mat-label>Name Of Parent (If patient is a minor)</mat-label>
              <input type="text" formControlName="NameOfParent" matInput>
              <mat-error>Please enter Name Of Parent</mat-error>
            </mat-form-field>

          </div>
        </div>
        <div class="row">
          <div class="col-lg-4">

            <mat-form-field class="example-full-width">
              <mat-label>Referred Doctor</mat-label>
              <input type="text" formControlName="ReferredDoctor" matInput>
            </mat-form-field>

          </div>
          <div class="col-lg-3">
            <mat-form-field class="example-full-width">
              <mat-label>Department</mat-label>


              <mat-select formControlName="DepartmentId">

                <mat-option *ngFor="let dpt of departmentList"
                  [value]="dpt.DepartmentId">{{dpt.DepartmentName}}</mat-option>

              </mat-select>
              <mat-error *ngIf="ptFrm.get('DepartmentId')?.hasError('required')">Please select Patient Admission
                Department</mat-error>

            </mat-form-field>
          </div>
          <div class="col-lg-3">
            <mat-form-field class="example-full-width">
              <mat-label>Select OPD /IPD Ward</mat-label>


              <mat-select formControlName="WardId">

                <mat-option *ngFor="let wrd of wardList" [value]="wrd.WardId">{{wrd.WardName}}</mat-option>

              </mat-select>
              <mat-error *ngIf="ptFrm.get('WardId')?.hasError('required')">Please select Patient Admission
                (Ward/OPD)</mat-error>

            </mat-form-field>
          </div>





        </div>

        <div class="row">
          <div class="col-lg-12">
            <mat-form-field appearance="fill" class="example-full-width">
              <mat-label>Patient Address </mat-label>

              <textarea matInput formControlName="PatientAddress" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="1" cdkAutosizeMaxRows="12"></textarea>
              <mat-error *ngIf="ptFrm.get('PatientAddress')?.hasError('required')">Please select Patient
                Address</mat-error>

            </mat-form-field>



          </div>
        </div>
        <div class="row">

          <div class="col-lg-3">
            <mat-form-field class="example-full-width">
              <mat-label>State</mat-label>
              <input type="text" formControlName="State" matInput />
              <mat-error *ngIf="ptFrm.get('State').invalid">Please enter State</mat-error>
            </mat-form-field>
          </div>
          <div class="col-lg-3">
            <mat-form-field class="example-full-width">
              <mat-label>City</mat-label>
              <input type="text" formControlName="City" matInput />
              <mat-error *ngIf="ptFrm.get('City').invalid">Please enter City</mat-error>
            </mat-form-field>
          </div>
          <div class="col-lg-3">
            <mat-form-field class="example-full-width">
              <mat-label>Zip</mat-label>
              <input type="text" formControlName="Zip" matInput />
              <mat-error *ngIf="ptFrm.get('Zip').invalid">Please enter Zip</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <input type="file" (change)="image($event)">


          </div>
        </div>

        <div class="row mt-5">
          <div class="col-md-12">
            <h4>Capture Image from Camera</h4>


            <webcam [trigger]="invokeObservable" (imageCapture)="captureImg($event)" [height]="180" [width]="180"
              [videoOptions]="videoOptions" [allowCameraSwitch]="allowCameraSwitch">
            </webcam>
          </div>
          <div class="col-md-12">
            <button class="btn btn-danger" (click)="getSnapshot($event)" type="button">
              Capture Image
            </button>
          </div>
          <div class="col-12">
            <div id="results">Your taken image manifests here...</div>
            <img [src]="webcamImage?.imageAsDataUrl" height="180px" />
          </div>
        </div>




        <button class="float-right lgBtCl" mat-button>
          <mat-icon>save</mat-icon>
        </button>

      </form>
      <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
        Successfully Created New Patient
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div class="modal-backdrop" *ngIf="showModal"></div>
<div class="modal" *ngIf="showModal">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Alert</h5>
        <button type="button" class="btn-close" (click)="closeModal()"></button>
      </div>
      <div class="modal-body">
        <p>{{ modalMessage }}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="closeModal()">OK</button>
      </div>
    </div>
  </div>
</div>