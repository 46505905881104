<mat-tab-group (selectedTabChange)="tabChanged($event)">

    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">healing</mat-icon>
            Nurses
        </ng-template>

        <div class="container mt-3">

            <div class="row">
                <div class="col-lg-6 "><span class="float-right"></span></div>
                <div class="col-lg-6 ">
                    <mat-form-field appearance="fill" class="example-full-width">
                        <mat-label>Search by Name</mat-label>
                        <mat-icon matPrefix>search</mat-icon>
                        <input type="text" #searchValue (keyup)="search(searchValue)" matInput>

                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-4" *ngFor="let item of filteredList">
                    <div class="card" style="width: 18rem;">
                        <ul class="list-group list-group-flush">
                            <img src="{{item.NurseImage}}" class="doctor-image" alt="">
                            <li class="list-group-item divBg text-white"><span
                                    style="font-size: large;">{{item.NurseName}} </span></li>
                            <li class="list-group-item">NPI Number: {{item.NpiNumber}} </li>
                            <li class="list-group-item">Position: {{item.NursePostion}} </li>
                            <li class="list-group-item">Qulification: {{item.NurseQualification}}</li>
                            <li class="list-group-item">Specility: {{item.NurseSpeciality}}</li>
                            <li class="list-group-item">IsLicenced: {{item.IsLicenced}}</li>
                            <li class="list-group-item">LicenceNumber: {{item.LicenceNumber}}</li>
                            <li class="list-group-item">D.O.B: {{item.NurseDOB}}</li>
                            <li class="list-group-item">SSN: {{item.SSN}}</li>
                            <li class="list-group-item">Phone: {{item.PhoneNumber}}</li>
                            <li class="list-group-item">Email: {{item.Email}}</li>
                            <li class="list-group-item">StartDate: {{item.StartDate}}</li>
                            <li class="list-group-item">Status: {{item.Status? 'Active' : 'Inactive'}}</li>
                            <li class="list-group-item"> Address: {{item.NurseAddress}}</li>
                        </ul>

                    </div>
                    <button type="button" [routerLink]="['/updateNurseProfile/',item.NurseId]" mat-mini-fab
                        color="primary">
                        <mat-icon matPrefix>edit</mat-icon>
                    </button>
                    <button mat-mini-fab (click)="delete(item.NurseId)"> <mat-icon>delete</mat-icon> </button>
                </div>

                <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>



            </div>
        </div>
    </mat-tab>


    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">add</mat-icon>
            Register Nurse
        </ng-template>


        <app-add-nurse></app-add-nurse>
    </mat-tab>


</mat-tab-group>