import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ProviderEnrollmentService } from 'src/app/services/provider-enrollment.service';

@Component({
  selector: 'app-provider-enrollment',
  templateUrl: './provider-enrollment.component.html',
  styleUrls: ['./provider-enrollment.component.css'],
})
export class ProviderEnrollmentComponent {
  providerForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private providerService: ProviderEnrollmentService
  ) {
    this.providerForm = this.fb.group({
      providerName: ['', Validators.required],
      npi: ['', Validators.required],
      taxId: ['', Validators.required],
      address: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
      zipCode: ['', Validators.required],
      phoneNumber: ['', [Validators.required, Validators.pattern(/^\d{10}$/)]],
      email: ['', [Validators.required, Validators.email]],
    });
  }

  onSubmit() {
    if (this.providerForm.valid) {
      this.providerService.enrollProvider(this.providerForm.value).subscribe({
        next: (response) => {
          console.log('Enrollment Successful', response);
          alert('Provider enrollment successful!');
        },
        error: (err) => {
          console.error('Enrollment Failed', err);
          alert('Provider enrollment failed. Please try again.');
        },
      });
    }
  }
}
