import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-split-b-patient',
  templateUrl: './split-b-patient.component.html',
  styleUrls: ['./split-b-patient.component.css'],
})
export class SplitBPatientComponent implements OnInit {
  id = 0;
  constructor(
    private route: ActivatedRoute,
    private tokenService: TokenService
  ) {}
  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
  }
  tabs: string[] = [
    'Completed Forms',
    'Patient Summary',
    'Appointments',
    'Invoices',
    'Payment History',
    'Insurances',
    'Id Proof',
    'Emergency Contact',
    'Pharmacy',
    'Allergies',
    'Surgeries',
    'Refferal',
    'Vaccine',
    'Past medication',
    'Pain Chart',
    'Prescriptions',
    'Medical Reports',
    'After Visit Summary',
    'Progress Notes',
  ];
  activatedTabIndex2: number = 0;

  tabChange2(tabIndex2: number) {
    // debugger;
    this.activatedTabIndex2 = tabIndex2;
  }
}
