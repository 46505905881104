import { Component, OnInit } from '@angular/core';
import { SubscriptionPlan } from 'src/app/Models/subscriptionplan.model';
import { SubscriptionService } from 'src/app/services/subscription.service';

@Component({
  selector: 'app-subscription-plan',
  templateUrl: './subscription-plan.component.html',
  styleUrls: ['./subscription-plan.component.css'],
})
export class SubscriptionPlanComponent implements OnInit {
  subscriptionPlans: SubscriptionPlan[] = [];
  newPlan: SubscriptionPlan = this.initializeNewPlan();
  editingPlan: SubscriptionPlan | null = null; // Currently edited plan

  constructor(private subscriptionService: SubscriptionService) {}

  ngOnInit(): void {
    this.loadPlans();
  }

  initializeNewPlan(): SubscriptionPlan {
    return {
      PlanId: 0,
      PlanName: '',
      Price: 0,
      MaxUsers: 0,
      MaxAppointments: 0,
      Features: '',
      IsActive: true,
      Discount3Months: 0,
      Discount12Months: 0,
      DurationMonths: 0,
    };
  }

  loadPlans(): void {
    this.subscriptionService.getSubscriptionPlans().subscribe({
      next: (plans) => {
        this.subscriptionPlans = plans;
      },
      error: (err) => console.error('Error fetching plans:', err),
    });
  }

  editPlan(plan: SubscriptionPlan): void {
    this.editingPlan = { ...plan }; // Clone the selected plan for editing
  }

  savePlan(): void {
    if (!this.editingPlan) return;

    this.subscriptionService
      .updateSubscriptionPlan(this.editingPlan.PlanId, this.editingPlan)
      .subscribe({
        next: () => {
          alert('Plan updated successfully!');
          this.loadPlans();
          this.editingPlan = null; // Clear the editing state
        },
        error: (err) => console.error('Error updating plan:', err),
      });
  }

  cancelEdit(): void {
    this.editingPlan = null; // Clear the editing state
  }

  deletePlan(planId: number): void {
    if (confirm('Are you sure you want to delete this plan?')) {
      this.subscriptionService.deleteSubscriptionPlan(planId).subscribe({
        next: () => {
          alert('Plan deleted successfully!');
          this.loadPlans();
        },
        error: (err) => console.error('Error deleting plan:', err),
      });
    }
  }

  addPlan(): void {
    if (
      !this.newPlan.PlanName.trim() ||
      this.newPlan.Price <= 0 ||
      this.newPlan.DurationMonths <= 0
    ) {
      alert('Please provide valid plan details.');
      return;
    }

    this.subscriptionService.createSubscriptionPlan(this.newPlan).subscribe({
      next: () => {
        alert('Plan added successfully!');
        this.loadPlans();
        this.newPlan = this.initializeNewPlan(); // Reset the form
      },
      error: (err) => console.error('Error adding plan:', err),
    });
  }
}
