<div class="subscriptions-container">
    <h2>All Subscriptions</h2>

    <!-- Subscription Table -->
    <mat-table [dataSource]="subscriptions" class="mat-elevation-z8">
        <ng-container matColumnDef="index">
            <mat-header-cell *matHeaderCellDef> # </mat-header-cell>
            <mat-cell *matCellDef="let sub; let i = index">{{ i + 1 }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="hospital">
            <mat-header-cell *matHeaderCellDef> Hospital Name </mat-header-cell>
            <mat-cell *matCellDef="let sub">{{ sub.HospitalName }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="plan">
            <mat-header-cell *matHeaderCellDef> Plan Name </mat-header-cell>
            <mat-cell *matCellDef="let sub">{{ sub.PlanName }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="startDate">
            <mat-header-cell *matHeaderCellDef> Start Date </mat-header-cell>
            <mat-cell *matCellDef="let sub">{{ sub.StartDate | date }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="endDate">
            <mat-header-cell *matHeaderCellDef> End Date </mat-header-cell>
            <mat-cell *matCellDef="let sub">{{ sub.EndDate | date }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
            <mat-cell *matCellDef="let sub">{{ sub.Status }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="paymentStatus">
            <mat-header-cell *matHeaderCellDef> Payment Status </mat-header-cell>
            <mat-cell *matCellDef="let sub">{{ sub.PaymentStatus }}</mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>

    <!-- Pagination -->
    <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
</div>