import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Hospital } from '../../Models/hospital.model';
import { HospitalService } from '../../services/hospital.service';
import { UserService } from '../../services/user.service';
import { InvoiceService } from '../../services/invoice.service';
import { PatientService } from 'src/app/services/patient.service';
import { getFilteredDate } from '../../shared/utility';
import { AppointmentService } from 'src/app/services/appointment.service';
import { EmailService } from 'src/app/services/email.service';
import { EmailPayload } from 'src/app/Models/email.model';
import { TextMessage } from 'src/app/Models/textmessage.model';
import * as moment from 'moment';
import { DoctorService } from 'src/app/services/doctor.service';
import { NurseService } from 'src/app/services/nurse.service';
import { combineLatest } from 'rxjs';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-admindashboard',
  templateUrl: './admindashboard.component.html',
  styleUrls: ['./admindashboard.component.css'],
})
export class AdmindashboardComponent implements OnInit {
  helper = new JwtHelperService();
  showItem = '';
  id = 0;
  myToken = this.tokenService.getToken();
  Revenue: any = {};
  hospitalList: Hospital[];
  Patients: any = {};
  NewPatients: any[] = [];
  AlertsExecuted = false;
  HospitalStaff: any = {};

  constructor(
    private hospitalService: HospitalService,
    private router: Router,
    private userService: UserService,
    private invoiceService: InvoiceService,
    private patientService: PatientService,
    private appointmentService: AppointmentService,
    private emailServices: EmailService,
    private doctorService: DoctorService,
    private nurseService: NurseService,
    private tokenService: TokenService
  ) {}

  ngOnInit(): void {
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.id = parseInt(decodedToken.nameid);
    this.getHospitalRevene();
    this.getAllPatients();
    this.getHospitalStaff();
  }

  getHospitalStaff(): void {
    const doctors = this.doctorService.getDoctors();
    const nurses = this.nurseService.getNurses();
    combineLatest([doctors, nurses]).subscribe((res) => {
      this.HospitalStaff.Doctors = {
        active: res[0].filter(
          (doctor) => Boolean(doctor.Status) === Boolean('true')
        ).length,
        total: res[0].length,
      };
      this.HospitalStaff.Nurses = {
        active: res[1].filter((nurse) => nurse.Status === 'true').length,
        total: res[1].length,
      };
    });
  }

  getHospitalRevene() {
    this.invoiceService.getInvoices().subscribe({
      next: (res: any) => {
        const unpaid = res
          .map((r) => r.Status === 'UnPaid' && r.Total)
          .reduce((a, b) => Number(a) + Number(b), 0);
        const paid = res
          .map((r) => r.Status === 'Paid' && r.Total)
          .reduce((a, b) => Number(a) + Number(b), 0);
        const pending = res
          .map((r) => r.Status === 'Pending' && r.Total)
          .reduce((a, b) => Number(a) + Number(b), 0);
        const total = res.map((r) => r.Total);
        this.Revenue['Total'] = total.reduce(
          (acc, curr) => Number(acc) + Number(curr),
          0
        );

        // this.Revenue["Total"] = unpaid+paid+pending;
        this.Revenue['Unpaid'] = unpaid ? unpaid : 0;
        this.Revenue['Paid'] = paid ? paid : 0;
        this.Revenue['Pending'] = pending ? pending : 0;
        const currentMonth = res.filter((invoice) => {
          const startDate = getFilteredDate(new Date(new Date().setDate(1)));
          const endDate = getFilteredDate(new Date(new Date().setDate(30)));
          if (
            new Date(invoice.createdDate) <= new Date(endDate) ||
            new Date(invoice.createdDate) >= new Date(startDate)
          ) {
            return invoice;
          }
        });
        this.Revenue['MonthTotal'] = currentMonth
          .map((inv) => inv.Total)
          .reduce((acc, curr) => Number(acc) + Number(curr), 0);
        const Munpaid = currentMonth
          .map((r) => r.Status === 'UnPaid' && r.Total)
          .reduce((a, b) => Number(a) + Number(b), 0);
        const Mpaid = currentMonth
          .map((r) => r.Status === 'Paid' && r.Total)
          .reduce((a, b) => Number(a) + Number(b), 0);
        const Mpending = currentMonth
          .map((r) => r.Status === 'Pending' && r.Total)
          .reduce((a, b) => Number(a) + Number(b), 0);
        this.Revenue['MonthUnpaid'] = Munpaid ? Munpaid : 0;
        this.Revenue['MonthPaid'] = Mpaid ? Mpaid : 0;
        this.Revenue['MonthPending'] = Mpending ? Mpending : 0;
      },
    });
  }

  getAllPatients(): void {
    this.patientService.getPatients().subscribe({
      next: (res) => {
        this.Patients['Active'] = res.filter(
          (patient) => patient.Status === 'Active'
        ).length;
        this.Patients['Discharged'] = res.filter(
          (patient) => patient.Status === 'Discharged'
        ).length;
        this.Patients['Total'] = res.filter((patient) => patient).length;
        this.NewPatients = res.filter((patient) =>
          this.isAddedInLast30Days(
            patient.CreatedDate,
            res.map((p) => p.CreatedDate)
          )
        );
        this.Patients['New'] = this.NewPatients.length;
        this.Patients['NewActive'] = this.NewPatients.filter(
          (patient) => patient.Status === 'Active'
        ).length;
        this.Patients['NewDischarged'] = this.NewPatients.filter(
          (patient) => patient.Status === 'Discharged'
        ).length;
      },
    });
  }

  isAddedInLast30Days(date, patients) {
    const now = new Date();
    const last30Days = now;
    last30Days.setDate(last30Days.getDate() - 30);

    const existsInLast30Days = patients.filter((patient) => {
      return (
        now.getTime() - new Date(patient).getTime() <= 30 * 24 * 60 * 60 * 1000
      );
    });

    return existsInLast30Days.includes(date);
  }

  logout() {
    localStorage.removeItem('token');
    this.router.navigate(['/']);
  }
}
