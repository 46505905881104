<div class="toolbar mat-elevation-z6">

    <button mat-raised-button class="divBg mt-3 ml-3" [matMenuTriggerFor]="crt"><mat-icon>menu</mat-icon>Menu</button>


    <mat-menu class="ItemS" #crt="matMenu">
        <!-- <div class="ItemS" >
                  <mat-icon mat-list-icon>undo</mat-icon>
                  <div class="mx-2" [routerLink]="['/labDash/']"> Lab Dashboard</div>
                </div> -->



        <div class="ItemS " (click)="showItem='2'" [ngClass]="{'active': showItem =='2'}">
            <mat-icon mat-list-icon>dashboard</mat-icon>
            <div class="mx-2"> Dashboard</div>
            <mat-icon>navigate_next</mat-icon>
        </div>
        <div class="ItemS " (click)="showItem='8'" [ngClass]="{'active': showItem =='8'}">
            <mat-icon mat-list-icon>book_online</mat-icon>
            <div class="mx-2"> All Appointments</div>
            <mat-icon>navigate_next</mat-icon>
        </div>
        <div class="ItemS " (click)="showItem='18'" [ngClass]="{'active': showItem =='18'}">
            <mat-icon mat-list-icon>today</mat-icon>
            <div class="mx-2"> Todays Appointments</div>
            <mat-icon>navigate_next</mat-icon>
        </div>



        <div class="ItemS " (click)="showItem='35'" [ngClass]="{'active': showItem =='35'}">
            <mat-icon>sms</mat-icon>
            <div class="mx-2"> Send SMS</div>
            <mat-icon>navigate_next</mat-icon>
        </div>
        <div class="ItemS " (click)="showItem='36'" [ngClass]="{'active': showItem =='36'}">
            <mat-icon>mail</mat-icon>
            <div class="mx-2"> Send Email</div>
            <mat-icon>navigate_next</mat-icon>
        </div>
        <div class="ItemS " (click)="showItem='45'" [ngClass]="{'active': showItem =='45'}">
            <mat-icon>send</mat-icon>
            <div class="mx-2"> Send Fax</div>
            <mat-icon>navigate_next</mat-icon>
        </div>

        <button type="button" (click)="logout()" class="btn float-right mx-3 my-3 logout " color="mute"
            mat-raised-button>
            <span>Logout</span>
        </button>

    </mat-menu>


</div>


<span [ngSwitch]="showItem">

    <p *ngSwitchCase="'1'">

    </p>
    <p *ngSwitchCase="'8'">
        <app-view-all-appointments-front-desk></app-view-all-appointments-front-desk>
    </p>
    <p *ngSwitchCase="'18'">
        <app-view-appointments-today-frontdesk></app-view-appointments-today-frontdesk>
    </p>
    <p *ngSwitchCase="'35'">
        <app-sms></app-sms>
    </p>
    <p *ngSwitchCase="'36'">
        <app-email></app-email>
    </p>

    <p *ngSwitchCase="'45'">
        <app-fax></app-fax>
    </p>

    <div *ngSwitchDefault>



        <div class="ml-3">
            <div class="row">
                <div class="col-lg-6 float-right">
                    <mat-form-field class="example-full-width mt-5">
                        <mat-label>Filter</mat-label>
                        <input matInput (keyup)="applyFilter($event)" placeholder="Ex.Mia" #input>
                    </mat-form-field>
                </div>
            </div>



            <div class="mat-elevation-z8">
                <table mat-table [dataSource]="dataSource" matSort>


                    <ng-container matColumnDef="Id">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
                        <td mat-cell *matCellDef="let row"> {{row.PatientId}} </td>
                    </ng-container>


                    <ng-container matColumnDef="FirstName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> First Name </th>
                        <td mat-cell *matCellDef="let row"> {{row.PatientFirstName}} </td>
                    </ng-container>
                    <ng-container matColumnDef="LastName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Name </th>
                        <td mat-cell *matCellDef="let row"> {{row.PatientLastName}} </td>
                    </ng-container>
                    <ng-container matColumnDef="Gender">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Gender </th>
                        <td mat-cell *matCellDef="let row"> {{row.PatientGender}} </td>
                    </ng-container>
                    <ng-container matColumnDef="PatientNationalId">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> National Id </th>
                        <td mat-cell *matCellDef="let row"> {{row.PatientNationalId}} </td>
                    </ng-container>
                    <ng-container matColumnDef="Email">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
                        <td class="ml-4" mat-cell *matCellDef="let row"> {{row.Email}} </td>
                    </ng-container>
                    <ng-container matColumnDef="PhoneNumber">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Phone Number </th>
                        <td mat-cell *matCellDef="let row"> {{row.PhoneNumber}} </td>
                    </ng-container>
                    <ng-container matColumnDef="PrimaryDoctor">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Primary Doctor </th>
                        <td mat-cell *matCellDef="let row"> {{row.PrimaryDoctorsName}} </td>
                    </ng-container>
                    <ng-container matColumnDef="DoctorName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Current Doctor </th>
                        <td mat-cell *matCellDef="let row"> {{row.DoctorName}} </td>
                    </ng-container>
                    <ng-container matColumnDef="WardName">
                        <th class="ml-4" mat-header-cell *matHeaderCellDef mat-sort-header> Ward </th>
                        <td class="ml-4" mat-cell *matCellDef="let row"> {{row.WardName}} </td>
                    </ng-container>

                    <ng-container matColumnDef="Appointment">
                        <th mat-header-cell *matHeaderCellDef> New Appointment </th>
                        <td mat-cell *matCellDef="let row">
                            <button class="btn btn-primary"
                                (click)="openAddAppointmentModal(row.PatientId,row.PatientFirstName,row.PatientLastName)"><mat-icon>schedule</mat-icon></button>
                            <!-- <button class="btn btn-primary" (click)="modal3.show()"><mat-icon>schedule</mat-icon></button> -->

                            <!-- <app-modal #modal3 class="panel-demo1" [maximizable]="true" [backdrop]="false">
                            <ng-container class="app-modal-header">Patient Name: {{row?.PatientFirstName}} &nbsp;
                              {{row?.PatientLastName}}</ng-container>
                            <ng-container class="app-modal-body">
                  
                              <div>
                  
                                <app-add-appointment-hospital [item]="row.PatientId"></app-add-appointment-hospital>
                  
                  
                  
                              </div>
                  
                  
                  
                  
                            </ng-container>
                            <ng-container class="app-modal-footer">
                  
                              <button class="dt-button dt-blue" style="float: right;" (click)="modal3.hide()">Close</button>
                  
                            </ng-container>
                          </app-modal> -->


                        </td>
                    </ng-container>
                    <ng-container matColumnDef="View">
                        <th mat-header-cell *matHeaderCellDef> Patient Details</th>
                        <td mat-cell *matCellDef="let row">
                            <a type="button" target="_blank" [routerLink]="['/patientTabed/',row.PatientId]"
                                mat-mini-fab color="success">
                                <mat-icon matPrefix>remove_red_eye</mat-icon>
                            </a>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="Edit" *ngIf="!isNurse">
                        <th mat-header-cell *matHeaderCellDef> Edit</th>
                        <td mat-cell *matCellDef="let row">
                            <button type="button" [routerLink]="['/updatePatient/',row.PatientId]" mat-mini-fab
                                color="primary">
                                <mat-icon matPrefix>edit</mat-icon>
                            </button>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="Delete" *ngIf="!isNurse">
                        <th mat-header-cell *matHeaderCellDef> Delete</th>
                        <td mat-cell *matCellDef="let row">
                            <button type="button" (click)="deletePatient(row.PatientId)" mat-mini-fab color="accent">
                                <mat-icon matPrefix>delete</mat-icon>
                            </button>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
                    </tr>
                </table>

                <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
            </div>

        </div>
    </div>




</span>