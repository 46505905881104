import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Router, ActivatedRoute } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as moment from 'moment';
import { map, Observable, startWith } from 'rxjs';
import { Doctor } from 'src/app/Models/doctor.model';
import { Medicine } from 'src/app/Models/medicine.model';
import { DoctorService } from 'src/app/services/doctor.service';
import { MedicineService } from 'src/app/services/medicine.service';
import { PrescriptionService } from 'src/app/services/prescription.service';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-update-prescription-by-nurse',
  templateUrl: './update-prescription-by-nurse.component.html',
  styleUrls: ['./update-prescription-by-nurse.component.css'],
})
export class UpdatePrescriptionByNurseComponent implements OnInit {
  id = 0;
  doctorId = 0;
  helper = new JwtHelperService();
  myToken = this.tokenService.getToken();
  states;
  isSuccess = false;
  doctor: Doctor;

  flteredmedicineList: Observable<any>;
  options: string[] = [
    'Intracerebroventricular',
    'Intramuscular',
    'Intragastric',
    'Intraperitoneal',
    'Intradermal',
    'Intracerebral',
    'Intra-arterial',
    'Intrathecal',
    'Intravenous',
    'Oral',
    'Subcutaneous',
    'Topical',
  ];
  amountPerDoseOptions: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  filteredExt: Observable<string[]>;

  filteredOptions: Observable<string[]>;
  filteredDose: Observable<string[]>;
  filteredinstructions: Observable<string[]>;
  filteredFrequency: Observable<string[]>;
  filteredNoOfTablets: Observable<string[]>;
  filteredRefill: Observable<string[]>;
  QTimes: string[] = [
    'Q1-Every Hour',
    'Q2-Every 2 hours',
    'Q3-Every 3 hours',
    'Q4-Every 4 hours',
    'Q5-Every 5 hours',
    'Q4-Every 6 hours',
    'Q4-Every 6 hours',
    'Q8-Every 8 hours',
    'Q12-Every 12 hours',
  ];
  NumberOfRefillsList: string[] = ['1', '2', '3', '4', '5', '11'];

  DrugsList: string[] = [
    'Calpol 200mg',
    'Paracetamol 200mg',
    'Co-codomol 200mg',
    'Ibuprofen 200mg',
  ];
  instructionList: string[] = [
    'Take 1 tablet in the Morning',
    'Take 2 tablet in the Morning',
    'Take 3 tablet in the Morning',
    'Take 1 tablet in the Afternoon',
    'Take 2 tablet in the Afternoon',
    'Take 3 tablet in the Afternoon',
    'Take 1 tablet in the Evening',
    'Take 2 tablet in the Evening',
    'Take 3 tablet in the Evening',
    'Take 1 tablet at bedtime',
    'Take 2 tablet at bedtime',
    'Take 3 tablet at bedtime',
    'Take 1 tablet in the Morning and 1 tablet at bedtime',
    'Take 2 tablet in the Morning and 2 tablet at bedtime',
    'Take 3 tablet in the Morning and 3 tablet at bedtime',
    'Take 1 tablet in the Morning ,ake 1 tablet in the Afternoon and Take 1 tablet in the Evening ',
    'Take 2 tablet in the Morning ,ake 2 tablet in the Afternoon and Take 2 tablet in the Evening ',
    'Take 3 tablet in the Morning ,ake 3 tablet in the Afternoon and Take 3 tablet in the Evening ',
    'Take 1 tablet in the Morning ,ake 1 tablet in the Afternoon and Take 1 tablet in the bedtime ',
    'Take 2 tablet in the Morning ,ake 2 tablet in the Afternoon and Take 2 tablet in the bedtime ',
    'Take 3 tablet in the Morning ,ake 3 tablet in the Afternoon and Take 3 tablet in the bedtime ',
    'Take 1 tablet b.i.d as needed',
    'Take 2 tablet b.i.d as needed',
    'Take 3 tablet b.i.d as needed',
    'Take 1 tablet t.i.d as needed',
    'Take 2 tablet t.i.d as needed',
    'Take 3 tablet t.i.d as needed',
    'Take 1 tablet every hour',
    'Take 1 tablet every 2 hours',
    'Take 1 tablet every 4 hours',
    'Take 1 tablet every 6 hours',
    'Take 1 tablet every 8 hours',
    'Take 1 tablet every 12 hours',
  ];
  drugExtention: string[] = [
    'Tablet',
    'Capsules',
    'ML',
    'Applications',
    // 'suspensions',
    // 'solutions',
    // 'colloids',
  ];
  frequencyList: string[] = [
    'QD (quaque die) - Once a day',
    'BID (bis in die) - Twice a day',
    'TID (ter in die) - Three times a day',
    'QID (quater in die) - Four times a day',
    'QHS (quaque hora somni) - Every night at bedtime',
    'Q4H (quaque 4 hora) - Every 4 hours',
    'Q6H (quaque 6 hora) - Every 6 hours',
    'Q8H (quaque 8 hora) - Every 8 hours',
    'Q12H (quaque 12 hora) - Every 12 hours',
    'PRN (pro re nata) - As needed',

    // 'drop',
  ];
  prnInstruction: string[] = [
    'PRN Pain - As needed for pain',
    'PRN Fever - As needed for fever',
    'PRN Nausea - As needed for nausea',
    'PRN Anxiety - As needed for anxiety',
    'PRN Sleep - As needed for sleep',
  ];
  medicineList: Medicine[];
  dose: string[] = [
    '5 mg',
    '10 mg',
    '15 mg',
    '20 mg',
    '25 mg',
    '30 mg',
    '40 mg',
    '50 mg',
    '80 mg',
    '100 mg',
    '200 mg',
    '300 mg',
    '400 mg',
    '500 mg',
    '600 mg',
    '800 mg',
    '1000 mg',
    '5 ml',
    '10 ml',
    '15 ml',
    '20 ml',
    '25 ml',
    '30 ml',
    '40 ml',
    '50 ml',
    '80 ml',
    '100 ml',
    '200 ml',
    '300 ml',
    '400 ml',
    '500 ml',
    '600 ml',
    '800 ml',
    '1000 ml',
  ];
  directionOfUseOptions: string[] = [
    'Take one tablet by mouth daily with water',
    'Take one tablet by mouth twice daily with food',
    'Take one tablet by mouth three times daily after meals',
    'Take two tablets by mouth as needed for pain, not exceeding 6 tablets in 24 hours',
    'Apply a thin layer to the affected area twice daily',
    'Inject 10 units subcutaneously before meals',
    'Instill one drop into the affected eye twice daily',
    'Use one inhalation by mouth every 4 hours as needed',
    'Take with food or milk to avoid stomach upset',
    'Take on an empty stomach one hour before or two hours after meals',
    'Dissolve one tablet under the tongue every 8 hours as needed',
    'Take one teaspoon (5 mL) by mouth every 6 hours as needed',
    'Chew one tablet and swallow every 12 hours as needed',
    'Take one capsule by mouth at bedtime with a full glass of water',
    'Take one tablet by mouth every 4 hours as needed for pain',
    'Take with a full glass of water and remain upright for 30 minutes',
    'Shake well before use and take one tablespoon (15 mL) every 12 hours',
    'Use as directed by your doctor and complete the full course of medication',
    'Apply to clean, dry skin and cover with a bandage if instructed',
    'Do not exceed the recommended dosage as prescribed',
    'Take at the same time every day for the best results',
    'Apply to the affected area and avoid exposure to sunlight',
    'Inject into the abdomen or thigh as directed by your physician',
    'Use one suppository rectally as needed for relief',
  ];

  dosageOptions = ['50mg', '100mg', '150mg', '200mg', '400mg'];

  refill: string[] = ['30 Days', '90 Days', '6 Months', '1 Year'];
  noOfTablet: string[] = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];
  twiceValue: number;
  refillValue: number;
  frequencyValue: number;
  tabLets: number;
  today: any;
  patientId: any;
  noRefills: number;
  isApproved: any;
  constructor(
    private fb: UntypedFormBuilder,
    private prescriptionService: PrescriptionService,
    private router: Router,
    private route: ActivatedRoute,
    private medicineService: MedicineService,
    private doctorService: DoctorService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UpdatePrescriptionByNurseComponent>,
    private tokenService: TokenService
  ) {}

  ngOnInit(): void {
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.doctorId = parseInt(decodedToken.nameid);

    this.getMedicine();
    this.id = this.data.userId;

    // this.route.params.subscribe((res) => {
    //   this.id = res['id'];
    //   this.patientId = res['PatientId'];
    // });
    this.prescriptionService.getPrescriptionById(this.id).subscribe((res) => {
      this.prescriptionFrm.patchValue(res);
      this.isApproved = res.requestedApproval;
      // this.AmountPerDose.setValue(res.AmountPerDose);
    });
    this.doctorService.getDoctorById(this.doctorId).subscribe((res) => {
      this.doctor = res;
      this.DoctorName.setValue(res.DoctorName);
    });
    this.filteredOptions = this.Route.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value || ''))
    );
    this.filteredinstructions = this.Instruction.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter2(value || ''))
    );
    this.filteredFrequency = this.Frequency.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter3(value || ''))
    );
    this.filteredRefill = this.RefillDuration.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter4(value || ''))
    );
    this.filteredDose = this.AmountPerDose.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter5(value || ''))
    );
    this.filteredNoOfTablets = this.NoOfTablets.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter6(value || ''))
    );
    this.filteredExt = this.Unit.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter7(value || ''))
    );
  }

  prescriptionFrm = this.fb.group({
    requestedApproval: [''],
    DrugName: ['', Validators.required],
    AmountPerDose: [1, Validators.required],
    Unit: [''],
    Quantity: [{ value: null }],
    Route: ['', Validators.required],
    Frequency: ['', Validators.required],
    RefillDuration: ['', Validators.required],
    NumberOfRefills: ['1', Validators.required],
    StartDate: [new Date().toISOString().split('T')[0], Validators.required],
    EndDate: [{ value: null }],
    Instruction: ['', [Validators.maxLength(500)]],
    Note: ['', [Validators.maxLength(500)]],
    NurseName: [''],
  });
  selectDosage(Strength: string): void {
    this.prescriptionFrm.patchValue({ Strength }); // Update dosage value in the form
  }
  get NurseName() {
    return this.prescriptionFrm.get('NurseName');
  }
  get AmountPerDose() {
    return this.prescriptionFrm.get('AmountPerDose');
  }
  get requestedApproval() {
    return this.prescriptionFrm.get('requestedApproval');
  }
  get Unit() {
    return this.prescriptionFrm.get('Unit');
  }
  get NoOfTablets() {
    return this.prescriptionFrm.get('NoOfTablets');
  }
  get Frequency() {
    return this.prescriptionFrm.get('Frequency');
  }
  get Route() {
    return this.prescriptionFrm.get('Route');
  }
  get Numberofrefills() {
    return this.prescriptionFrm.get('Numberofrefills');
  }
  get RefillDuration() {
    return this.prescriptionFrm.get('RefillDuration');
  }

  get DoctorName() {
    return this.prescriptionFrm.get('DoctorName');
  }

  get StartDate() {
    return this.prescriptionFrm.get('StartDate');
  }

  get EndDate() {
    return this.prescriptionFrm.get('EndDate');
  }

  get Status() {
    return this.prescriptionFrm.get('Status');
  }
  get MorningTime() {
    return this.prescriptionFrm.get('MorningTime');
  }
  get AfternoonTime() {
    return this.prescriptionFrm.get('AfternoonTime');
  }
  get EveningTime() {
    return this.prescriptionFrm.get('EveningTime');
  }
  get NightTime() {
    return this.prescriptionFrm.get('NightTime');
  }
  get QTime() {
    return this.prescriptionFrm.get('QTime');
  }
  get DoctorId() {
    return this.prescriptionFrm.get('DoctorId');
  }
  get DoctorSignature() {
    return this.prescriptionFrm.get('DoctorSignature');
  }
  get Quantity() {
    return this.prescriptionFrm.get('Quantity');
  }
  get DrugName() {
    return this.prescriptionFrm.get('DrugName');
  }
  get Strength() {
    return this.prescriptionFrm.get('Strength');
  }

  get BrandName() {
    return this.prescriptionFrm.get('BrandName');
  }
  get DatePrescribed() {
    return this.prescriptionFrm.get('DatePrescribed');
  }

  get Instruction() {
    return this.prescriptionFrm.get('Instruction');
  }

  get Note() {
    return this.prescriptionFrm.get('Note');
  }

  getMedicine() {
    this.medicineService.getMedicines().subscribe((res) => {
      this.medicineList = res;
    });
  }
  getDoctor(id) {
    this.doctorService.getDoctorById(id).subscribe((res) => {
      this.doctor = res;
    });
  }
  approve(e) {
    // console.log((this.isApproved = true));
    this.isApproved = true;
  }
  formSubmit() {
    let body = {
      ...this.prescriptionFrm.value,
      requestedApproval: this.isApproved,
    };
    this.prescriptionService
      .updatePrescription(this.id, body)
      .subscribe((res) => {
        this.isSuccess = true;
        this.prescriptionFrm.reset();
        this.onClose();
      });
  }
  onClose(): void {
    this.dialogRef.close();
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }
  private _filter2(value: string): string[] {
    const x = value.toLowerCase();

    return this.instructionList.filter((ins) => ins.toLowerCase().includes(x));
  }

  private _filter3(value: string): string[] {
    const y = value.toLowerCase();

    return this.frequencyList.filter((fc) => fc.toLowerCase().includes(y));
  }

  private _filter4(value: string): string[] {
    const cx = value.toLowerCase();

    return this.refill.filter((gg) => gg.toLowerCase().includes(cx));
  }
  private _filter5(value: string): string[] {
    const ds = value.toLowerCase();

    return this.dose.filter((aa) => aa.toLowerCase().includes(ds));
  }
  private _filter6(value: string): string[] {
    const ftb = value.toLowerCase();

    return this.noOfTablet.filter((aa) => aa.toLowerCase().includes(ftb));
  }
  private _filter7(value: string): string[] {
    const ftb = value.toLowerCase();

    return this.drugExtention.filter((aa) => aa.toLowerCase().includes(ftb));
  }

  public getFrequency(e) {
    if (e.includes('Once a day')) {
      this.frequencyValue = 1;
    } else if (e.includes('Twice a day')) {
      this.frequencyValue = 2;
    } else if (e.includes('Twice a day')) {
      this.frequencyValue = 2;
    } else if (e.includes('Three times a day')) {
      this.frequencyValue = 3;
    } else if (e.includes('Four times a day')) {
      this.frequencyValue = 4;
    } else if (e.includes('Every night at bedtime')) {
      this.frequencyValue = 1;
    } else if (e.includes('Every 4 hours')) {
      this.frequencyValue = 6;
    } else if (e.includes('Every 6 hours')) {
      this.frequencyValue = 4;
    } else if (e.includes('Every 8 hours')) {
      this.frequencyValue = 3;
    } else if (e.includes('Every 12 hours')) {
      this.frequencyValue = 2;
    } else {
      this.frequencyValue = 1;
    }
    // console.log(this.frequencyValue);
    this.getGrandTotal();

    // this.invForm.get('Total').setValue(total);
    // this.SubTotal.setValue(total);
  }
  getRefillValue(v) {
    if (v.includes('30 Days')) {
      this.refillValue = 30;
    } else if (v.includes('90 Days')) {
      this.refillValue = 90;
    } else if (v.includes('6 Months')) {
      this.refillValue = 180;
    } else if (v.includes('1 Year')) {
      this.refillValue = 365;
    }
    // console.log(this.refillValue);
    this.getGrandTotal();
  }
  getNoOfTabletValue(tb) {
    if (tb.includes('1')) {
      this.tabLets = 1;
    } else if (tb.includes('2')) {
      this.tabLets = 2;
    } else if (tb.includes('3')) {
      this.tabLets = 3;
    } else if (tb.includes('4')) {
      this.tabLets = 4;
    } else if (tb.includes('5')) {
      this.tabLets = 5;
    } else if (tb.includes('6')) {
      this.tabLets = 6;
    } else if (tb.includes('7')) {
      this.tabLets = 7;
    } else if (tb.includes('8')) {
      this.tabLets = 8;
    } else if (tb.includes('9')) {
      this.tabLets = 9;
    } else if (tb.includes('10')) {
      this.tabLets = 10;
    }
    // console.log(this.tabLets);
    this.getGrandTotal();
  }
  getGrandTotal() {
    let total = 0;
    // let x = this.frequencyValue;
    // let y = this.refillValue;

    // console.log(this.frequencyValue);

    // console.log(this.refillValue);

    total = this.frequencyValue * this.refillValue * this.tabLets;
    // console.log(total);
    this.Quantity.setValue(total);
  }
  endDate(e) {
    this.noRefills = e.target.value;
    // this.prescriptionFrm.patchValue({ EndDate });
    this.setEndDate();
  }

  setEndDate() {
    let x = 0;
    x = this.noRefills * this.refillValue;
    let currentDate = moment();
    let futureDate = currentDate.add(x, 'days');
    this.EndDate.setValue(futureDate.format('YYYY-MM-DD'));
  }
  tabChanged(event: MatTabChangeEvent): void {
    if (event.index === 0) {
      this.prescriptionFrm;
    }
  }
}
