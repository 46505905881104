<div class="toolbar mat-elevation-z6">

    <button mat-raised-button class="divBg mt-3 ml-3" [matMenuTriggerFor]="crt"><mat-icon>menu</mat-icon>Menu</button>


    <mat-menu class="ItemS" #crt="matMenu">
        <!-- <div class="ItemS" >
                  <mat-icon mat-list-icon>undo</mat-icon>
                  <div class="mx-2" [routerLink]="['/labDash/']"> Lab Dashboard</div>
                </div> -->



        <div class="ItemS " (click)="showItem='2'" [ngClass]="{'active': showItem =='2'}">
            <mat-icon mat-list-icon>dashboard</mat-icon>
            <div class="mx-2"> Dashboard</div>
            <mat-icon>navigate_next</mat-icon>
        </div>
        <div class="ItemS " (click)="showItem='3'" [ngClass]="{'active': showItem =='3'}">
            <mat-icon>attach_money</mat-icon>
            <div class="mx-2"> All Invoices</div>
            <mat-icon>navigate_next</mat-icon>
        </div>

        <div class="ItemS " (click)="showItem='40'" [ngClass]="{'active': showItem =='40'}">
            <mat-icon mat-list-icon>
                account_balance
            </mat-icon>
            <div class="mx-2">Invoice Receivable</div>
            <mat-icon>navigate_next</mat-icon>
        </div>

        <div class="ItemS " (click)="showItem='44'" [ngClass]="{'active': showItem =='44'}">
            <mat-icon mat-list-icon>
                paid
            </mat-icon>
            <div class="mx-2"> Un Paid Balances</div>
            <mat-icon>navigate_next</mat-icon>
        </div>
        <div class="ItemS " (click)="showItem='53'" [ngClass]="{'active': showItem =='53'}">
            <mat-icon mat-list-icon>search</mat-icon>
            <div class="mx-2">Claim Status</div>
            <mat-icon>navigate_next</mat-icon>
        </div>
        <div class="ItemS " (click)="showItem='54'" [ngClass]="{'active': showItem =='54'}">
            <mat-icon mat-list-icon>money</mat-icon>
            <div class="mx-2">Electronic Remitance </div>
            <mat-icon>navigate_next</mat-icon>
        </div>
        <div class="ItemS " (click)="showItem='55'" [ngClass]="{'active': showItem =='55'}">
            <mat-icon mat-list-icon>note</mat-icon>
            <div class="mx-2">Claim Notes </div>
            <mat-icon>navigate_next</mat-icon>
        </div>
        <!-- <div class="ItemS " (click)="showItem='43'" [ngClass]="{'active': showItem =='43'}">
            <mat-icon mat-list-icon>
                pending
            </mat-icon>
            <div class="mx-2"> Insurance Pending Payments</div>
            <mat-icon>navigate_next</mat-icon>
        </div> -->
        <div class="ItemS " (click)="showItem='35'" [ngClass]="{'active': showItem =='35'}">
            <mat-icon>sms</mat-icon>
            <div class="mx-2"> Send SMS</div>
            <mat-icon>navigate_next</mat-icon>
        </div>
        <div class="ItemS " (click)="showItem='36'" [ngClass]="{'active': showItem =='36'}">
            <mat-icon>mail</mat-icon>
            <div class="mx-2"> Send Email</div>
            <mat-icon>navigate_next</mat-icon>
        </div>
        <div class="ItemS " (click)="showItem='45'" [ngClass]="{'active': showItem =='45'}">
            <mat-icon>send</mat-icon>
            <div class="mx-2"> Send Fax</div>
            <mat-icon>navigate_next</mat-icon>
        </div>

        <button type="button" (click)="logout()" class="btn float-right mx-3 my-3 logout " color="mute"
            mat-raised-button>
            <span>Logout</span>
        </button>

    </mat-menu>


</div>


<span [ngSwitch]="showItem">

    <p *ngSwitchCase="'1'">

    </p>
    <p *ngSwitchCase="'3'">
        <app-view-all-invoice-by-biller></app-view-all-invoice-by-biller>
    </p>
    <p *ngSwitchCase="'35'">
        <app-sms></app-sms>
    </p>
    <p *ngSwitchCase="'36'">
        <app-email></app-email>
    </p>
    <p *ngSwitchCase="'40'">
        <app-patient-has-balance-view-by-biller></app-patient-has-balance-view-by-biller>
    </p>
    <!-- <p *ngSwitchCase="'43'">
        <app-patient-has-insurance-pending-payment></app-patient-has-insurance-pending-payment>
    </p> -->
    <p *ngSwitchCase="'44'">
        <app-patient-has-unpaind-amount-view-by-biller></app-patient-has-unpaind-amount-view-by-biller>
    </p>
    <p *ngSwitchCase="'45'">
        <app-fax></app-fax>
    </p>
    <p *ngSwitchCase="'53'">
        <app-claim-status></app-claim-status>
    </p>
    <p *ngSwitchCase="'54'">
        <app-era-list></app-era-list>
    </p>
    <p *ngSwitchCase="'55'">
        <app-claim-notes></app-claim-notes>
    </p>
    <div *ngSwitchDefault>



        <div class="ml-3">
            <div class="row">
                <div class="col-lg-6 float-right">
                    <mat-form-field class="example-full-width mt-5">
                        <mat-label>Filter</mat-label>
                        <input matInput (keyup)="applyFilter($event)" placeholder="Ex.Mia" #input>
                    </mat-form-field>
                </div>
            </div>



            <div class="mat-elevation-z8">
                <table mat-table [dataSource]="dataSource" matSort>





                    <ng-container matColumnDef="createdDate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Invoice Date </th>
                        <td mat-cell *matCellDef="let row"> {{row.createdDate | date: 'short'}} </td>
                    </ng-container>


                    <ng-container matColumnDef="Id">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Patient Id </th>
                        <td mat-cell *matCellDef="let row"> {{row.PatientId}} </td>
                    </ng-container>


                    <ng-container matColumnDef="PatientName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Patient Name </th>
                        <td mat-cell *matCellDef="let row"> {{row.PatientName}} </td>
                    </ng-container>
                    <ng-container matColumnDef="DoctorName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> DoctorName </th>
                        <td mat-cell *matCellDef="let row"> {{row.DoctorName}} </td>
                    </ng-container>
                    <ng-container matColumnDef="CoPay">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> CoPay </th>
                        <td mat-cell *matCellDef="let row"> {{row.CoPay}} </td>
                    </ng-container>
                    <ng-container matColumnDef="InsurancePay">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Insurance Pay </th>
                        <td mat-cell *matCellDef="let row"> {{row.InsurancePay}} </td>
                    </ng-container>
                    <ng-container matColumnDef="BalanceAmount">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Balance Amount </th>
                        <td class="ml-4" mat-cell *matCellDef="let row"> {{row.BalanceAmount}} </td>
                    </ng-container>
                    <ng-container matColumnDef="Total">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Total Billed</th>
                        <td mat-cell *matCellDef="let row"> {{row.Total}} </td>
                    </ng-container>

                    <ng-container matColumnDef="Status">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                        <td mat-cell *matCellDef="let row"> {{row.Status}} </td>
                    </ng-container>


                    <ng-container matColumnDef="Detail">
                        <th mat-header-cell *matHeaderCellDef> Patient Details</th>
                        <td mat-cell *matCellDef="let row">
                            <!-- <button mat-mini-fab color="primary"
                      (click)="openAddAppointmentModal(row.PatientId,row.PatientFirstName,row.PatientLastName)"><mat-icon>visibility</mat-icon></button> -->
                            <a type="button" target="_blank" [routerLink]="['/billerPatient/',row.PatientId]"
                                mat-mini-fab color="primary">
                                <mat-icon matPrefix>remove_red_eye</mat-icon>
                            </a>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
                    </tr>
                </table>

                <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
            </div>

        </div>
    </div>




</span>