<div class="container mt-5">
  <div class="row">
    <div class="col-lg regFrm mat-elevation-z8">
      <h1 class="text-center mb-4">Family History</h1>
      <form (keydown.enter)="$event.preventDefault()" [formGroup]="Frm" (ngSubmit)="formSubmit()" class="example-form">
        <h4>Please indicate if any of your immediate relatives have had any of the following:</h4>
        <hr>
        <!-- Family History Fields -->
        <div class="row">
          <!-- Anesthesia Problems -->
          <div class="col-md-6">
            <mat-checkbox color="primary" formControlName="AnesthesiaProblems">
              Anesthesia Problems
            </mat-checkbox>
            <mat-form-field class="example-full-width">
              <mat-label>Who had Anesthesia Problems? (Father/Mother)</mat-label>
              <input type="text" formControlName="WhoHadAnesthesiaProblems" matInput />
            </mat-form-field>
          </div>
          <!-- Arthritis -->
          <div class="col-md-6">
            <mat-checkbox color="primary" formControlName="Arthritis">
              Arthritis
            </mat-checkbox>
            <mat-form-field class="example-full-width">
              <mat-label>Who had Arthritis? (Father/Mother)</mat-label>
              <input type="text" formControlName="WhoHadArthritis" matInput />
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <!-- Cancer -->
          <div class="col-md-6">
            <mat-checkbox color="primary" formControlName="Cancer">
              Cancer
            </mat-checkbox>
            <mat-form-field class="example-full-width">
              <mat-label>Who had Cancer? (Father/Mother)</mat-label>
              <input type="text" formControlName="WhoHadCancer" matInput />
            </mat-form-field>
          </div>
          <!-- Diabetes -->
          <div class="col-md-6">
            <mat-checkbox color="primary" formControlName="Diabetes">
              Diabetes
            </mat-checkbox>
            <mat-form-field class="example-full-width">
              <mat-label>Who had Diabetes? (Father/Mother)</mat-label>
              <input type="text" formControlName="WhoHadDiabetes" matInput />
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <!-- Heart Problems -->
          <div class="col-md-6">
            <mat-checkbox color="primary" formControlName="HeartProblems">
              Heart Problems
            </mat-checkbox>
            <mat-form-field class="example-full-width">
              <mat-label>Who had Heart Problems? (Father/Mother)</mat-label>
              <input type="text" formControlName="WhoHadHeartProblems" matInput />
            </mat-form-field>
          </div>
          <!-- Hypertension -->
          <div class="col-md-6">
            <mat-checkbox color="primary" formControlName="Hypertension">
              Hypertension
            </mat-checkbox>
            <mat-form-field class="example-full-width">
              <mat-label>Who had Hypertension? (Father/Mother)</mat-label>
              <input type="text" formControlName="WhoHadHypertension" matInput />
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <!-- Stroke -->
          <div class="col-md-6">
            <mat-checkbox color="primary" formControlName="Stroke">
              Stroke
            </mat-checkbox>
            <mat-form-field class="example-full-width">
              <mat-label>Who had Stroke? (Father/Mother)</mat-label>
              <input type="text" formControlName="WhoHadStroke" matInput />
            </mat-form-field>
          </div>
          <!-- Thyroid Disorder -->
          <div class="col-md-6">
            <mat-checkbox color="primary" formControlName="ThyroidDisorder">
              Thyroid Disorder
            </mat-checkbox>
            <mat-form-field class="example-full-width">
              <mat-label>Who had Thyroid Disorder? (Father/Mother)</mat-label>
              <input type="text" formControlName="WhoHadThyroidDisorder" matInput />
            </mat-form-field>
          </div>
        </div>

        <hr>
        <h4>If not listed above, please add below:</h4>
        <div class="row">
          <div class="col-md-4">
            <mat-form-field class="example-full-width">
              <mat-label>Problem</mat-label>
              <input type="text" formControlName="Problem" matInput />
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <mat-form-field class="example-full-width">
              <mat-label>Who had the problem? (Father/Mother)</mat-label>
              <input type="text" formControlName="WhoHadIt" matInput />
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <mat-form-field class="example-full-width">
              <mat-label>Description</mat-label>
              <input type="text" formControlName="Description" matInput />
            </mat-form-field>
          </div>
        </div>

        <!-- Submit Button -->
        <div class="text-right mt-4">
          <button class="btn btn-primary" mat-raised-button [disabled]="Frm.invalid">
            <mat-icon>save</mat-icon>
            Save
          </button>
        </div>

        <!-- Success Message -->
        <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
          Successfully submitted
          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</div>