import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EmergencyAccessService {
  apiUrl = environment.Url + 'emergency-access';
  constructor(private http: HttpClient) {}

  verifyFaceId(userId: string, credentialId: string) {
    const payload = { userId, credentialId };
    return this.http.post<{ token: string }>(this.apiUrl, payload);
  }
}
