<div class="container">
    <h2>Claim Response</h2>
    <div class="ml-3">
        <div class="row">
            <div class="col-lg-6 float-right">
                <mat-form-field class="example-full-width mt-5">
                    <mat-label>Filter</mat-label>
                    <input matInput (keyup)="applyFilter($event)" placeholder="Ex.Mia" #input>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class=" mat-elevation-z8">
        <table mat-table [dataSource]="dataSource" matSort>


            <ng-container matColumnDef="response_time">
                <th mat-header-cell *matHeaderCellDef> response_time </th>
                <td mat-cell *matCellDef="let row"> {{row.response_time }} </td>
            </ng-container>


            <ng-container matColumnDef="BillTaxId">
                <th mat-header-cell *matHeaderCellDef> Billing TaxId </th>
                <td mat-cell *matCellDef="let row"> {{row.bill_taxid }} </td>
            </ng-container>

            <ng-container matColumnDef="BillNpi">
                <th mat-header-cell *matHeaderCellDef> Billing Npi </th>
                <td mat-cell *matCellDef="let row"> {{row.bill_npi }} </td>
            </ng-container>
            <ng-container matColumnDef="pcn">
                <th mat-header-cell *matHeaderCellDef> Patient CN </th>
                <td mat-cell *matCellDef="let row"> {{row.pcn}} </td>
            </ng-container>
            <ng-container matColumnDef="InsNumber">
                <th mat-header-cell *matHeaderCellDef> Insurance Number </th>
                <td mat-cell *matCellDef="let row"> {{row.ins_number}} </td>
            </ng-container>
            <ng-container matColumnDef="claimmd_id">
                <th mat-header-cell *matHeaderCellDef> Claim Id </th>
                <td mat-cell *matCellDef="let row"> {{row.claimmd_id}} </td>
            </ng-container>
            <ng-container matColumnDef="FileName">
                <th mat-header-cell *matHeaderCellDef> FileName </th>
                <td mat-cell *matCellDef="let row"> {{row.filename}} </td>
            </ng-container>
            <ng-container matColumnDef="TotalCharge">
                <th mat-header-cell *matHeaderCellDef> TotalCharge </th>
                <td mat-cell *matCellDef="let row"> {{row.total_charge}} </td>
            </ng-container>
            <ng-container matColumnDef="Status">
                <th mat-header-cell *matHeaderCellDef> Status </th>
                <td mat-cell *matCellDef="let row" [ngClass]="getStatusClass(row.status)"> {{ getStatusText(row.status)
                    }} </td>
            </ng-container>



            <!-- <ng-container matColumnDef="Update">
                <th mat-header-cell *matHeaderCellDef> Edit </th>
                <td mat-cell *matCellDef="let row">
                    <button type="button" (click)="resubmit(row.claimmd_id)" mat-mini-fab color="mute">
                        <mat-icon matPrefix>edit</mat-icon>
                    </button>
                 
                </td>
            </ng-container> -->
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
            </tr>
        </table>

        <mat-paginator [pageSizeOptions]="[5, 10, 25,50, 100,150,200,500,750,1000]"></mat-paginator>
    </div>



    <!-- <div>
        <mat-card>
            <div class="container mt-4">
                <h3>Submitted Claims Status </h3>
                <table class="table table-bordered table-hover">
                    <thead>
                        <tr>
                            <th>Total Charges</th>
                            <th>Status</th>
                            <th>Filename</th>
                            <th>Claim id</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of xmlList; let i = index">
                            <td>{{ item.TotalCharge }}</td>
                            <td [ngClass]="getStatusClass(item.Status)">{{ getStatusText(item.Status) }}</td>
                            <td>{{ item.FileName }}</td>
                            <td>{{ item.ClaimMdId }}</td>
                            <td><button (click)="resubmit(item.ClaimMdId)"><mat-icon>edit</mat-icon></button></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </mat-card>
    </div> -->
</div>
<!-- 
<div>
    <h2>Track Claim Status</h2>
    <input [(ngModel)]="inboundId" placeholder="Enter Inbound ID" />
    <button (click)="trackClaim()">Track</button>

    <div *ngIf="status">
        <h3>Claim Status:</h3>
        <pre>{{ status | json }}</pre>
    </div>
</div> -->