import { Component } from '@angular/core';

@Component({
  selector: 'app-view-practice-manager',
  templateUrl: './view-practice-manager.component.html',
  styleUrls: ['./view-practice-manager.component.css']
})
export class ViewPracticeManagerComponent {

}
