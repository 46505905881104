import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SubscriptionPlan } from '../Models/subscriptionplan.model';
import { Subscription } from '../Models/subscription.model';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  private baseUrl = environment.Url + 'subscriptions';

  constructor(private http: HttpClient) {}

  checkSubscriptionStatus(
    hospitalId: number
  ): Observable<{ isSubscribed: boolean }> {
    return this.http.get<{ isSubscribed: boolean }>(
      `${this.baseUrl}/subscription-status/${hospitalId}`
    );
  }
  checkSubscriptionStatus1(hospitalId: number): Observable<any> {
    return this.http.get<Subscription>(
      `${this.baseUrl}/subscription-status/${hospitalId}`
    );
  }
  getAllSubscriptions(): Observable<any[]> {
    return this.http.get<any[]>(`${this.baseUrl}/all-subscriptions`);
  }

  getSubscriptionPlans(): Observable<SubscriptionPlan[]> {
    return this.http.get<SubscriptionPlan[]>(`${this.baseUrl}/getplans`);
  }

  createSubscriptionPlan(plan: SubscriptionPlan): Observable<SubscriptionPlan> {
    return this.http.post<SubscriptionPlan>(`${this.baseUrl}/plans`, plan);
  }

  updateSubscriptionPlan(
    planId: number,
    updatedPlan: SubscriptionPlan
  ): Observable<SubscriptionPlan> {
    return this.http.put<SubscriptionPlan>(
      `${this.baseUrl}/plans/${planId}`,
      updatedPlan
    );
  }

  deleteSubscriptionPlan(planId: number): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/plans/${planId}`);
  }

  subscribeFacility(
    hospitalId: number,
    subscriptionRequest: { planId: number; durationMonths: number }
  ): Observable<any> {
    return this.http.post(
      `${this.baseUrl}/subscribe/${hospitalId}`,
      subscriptionRequest
    );
  }

  createPaymentIntent(
    amount: number,
    hospitalId: number,
    planId: number
  ): Observable<any> {
    return this.http.post(`${this.baseUrl}/create-payment-intent`, {
      amount,
      hospitalId,
      planId,
    });
  }
}
