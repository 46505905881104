<div class="container">
    <div class="row">
        <div class="col-lg regFrm mat-elevation-z8">
            <h2>New Payment Method </h2>
            <form (keydown.enter)="$event.preventDefault()" [formGroup]="phFrm" (ngSubmit)="formSubmit()"
                class="example-form">

                <div class="row">
                    <div class="col-lg-6">

                        <mat-form-field class="example-full-width">
                            <mat-label>Provider Name</mat-label>
                            <input type="text" formControlName="ProviderName" matInput>
                            <mat-error>Please enter Provider Name</mat-error>
                        </mat-form-field>

                    </div>

                    <div class="col-lg-6">
                        <mat-form-field class="example-full-width">
                            <mat-label> Public Key </mat-label>
                            <input type="text" formControlName="PublicKey" matInput>
                            <mat-error>Please enter Public Key</mat-error>
                        </mat-form-field>

                    </div>

                </div>

                <div class="row">



                    <div class="col-lg-8">

                        <mat-form-field class="example-full-width">
                            <mat-label> Security Key</mat-label>
                            <input type="tel" formControlName="SecurityKey" matInput>
                            <mat-error>Please enter Security Key</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-4">

                        <p>Status</p>
                        <mat-radio-group formControlName="Status" aria-label="Select an option my-3">
                            <mat-radio-button class="mx-3" value="true">Active</mat-radio-button>
                            <mat-radio-button class="mx-3 " value="false"> Inactive</mat-radio-button>
                        </mat-radio-group>

                    </div>
                </div>


                <button class="float-right lgBtCl" mat-button>
                    <mat-icon>save</mat-icon>
                </button>
                <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
                    Successfully Created Payment Method
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </form>

        </div>
    </div>

</div>