import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  FormArray,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { PatientService } from '../../services/patient.service';
import { InvoiceService } from '../../services/invoice.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CptcodeService } from 'src/app/services/cptcode.service';
import { CptCode } from 'src/app/Models/cptcode.model';
import { Hospital } from 'src/app/Models/hospital.model';
import { Observable, observable } from 'rxjs';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { Clipboard } from '@angular/cdk/clipboard';

import { TokenService } from 'src/app/services/token.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { MatDialog } from '@angular/material/dialog';
import { FavoriteDiagnosisPopupComponent } from '../favorite-diagnosis-popup/favorite-diagnosis-popup.component';

@Component({
  selector: 'app-add-invoice',
  templateUrl: './add-invoice.component.html',
  styleUrls: ['./add-invoice.component.css'],
})
export class AddInvoiceComponent implements OnInit {
  patientId = 0;
  id = 0;
  helper = new JwtHelperService();
  flteredList;
  myToken = this.tokenService.getToken();
  StatusList: string[] = [
    'Pending',
    'CliamSubmitted',
    'CliamApproved',
    'CliamSuccessfull',
    'CliamPartialPaymentMade',
    'Paid',
    'UnPaid',
    'Balance',
    'Rejected',
    'Partial Payment Made',
  ];
  invForm: UntypedFormGroup;
  isSuccess = false;
  // cptCodeList: CptCode[];
  hospital: Hospital;
  hospitalId: number;
  UnitCharge: any;
  filteredOptions: Observable<CptCode[]>;
  filteredMedicineLists: any[][] = [];
  filteredCountries: Record<string, string>[] = [];
  DoctorId: any;
  cptCodeList: any[] = [];
  private readonly storageKey = 'cptCodes'; // Key for local storage
  favoriteDiagnosisCodes = [];

  randomizeOrder() {
    this.filteredCountries.forEach((_, i) => {
      const rand = Math.round(Math.random() * this.filteredCountries.length);
      moveItemInArray(this.filteredCountries, i, rand);
    });
  }
  constructor(
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private patientService: PatientService,
    private invoiceService: InvoiceService,
    private cptCodeService: CptcodeService,
    private clipBoard: Clipboard,
    private tokenService: TokenService,
    private localStorageService: LocalStorageService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.patientId = res['id'];
    });
    this.getPatient(this.patientId);

    this.patientService.getpatienHospitalId(this.patientId).subscribe((res) => {
      this.hospital = res.Hospital;
    });
    // let decodedToken = this.helper.decodeToken(this.myToken);
    // this.id = parseInt(decodedToken.nameid);

    this.getProcedureCode();

    this.invForm = this.fb.group({
      PatientName: ['', Validators.required],
      ProcedureCodeLookUp: [''],
      DiagnosisCode: [''],
      DoctorName: [''],
      NpiNumber: [''],
      GroupNo: [''],
      InsuresRelationToPatient: [''],
      InsuaranceNo: [''],
      InsuaranceName: [''],
      Title: ['', Validators.required],
      InvoiceItems: this.fb.array([]),
      Status: ['', Validators.required],
      Date: [new Date().toISOString().split('T')[0], Validators.required],
      CoPay: ['', Validators.required],
      SubTotal: [''],
      Total: [''],
      BalanceAmount: [''],
    });
    this.addItem();
    this.getPatientInsurance(this.patientId);
    this.getPatientDoctor(this.patientId);
  }

  public addItem(): void {
    this.UnitCharge = 0;
    const item = this.fb.group({
      Amount: [0],
      Description: [''],
      ProcedureCode: [''],
      Duration: [0],
      Units: [1],
      UnitCharge: [0], // Add UnitCharge here
    });
    this.InvoiceItems.push(item);
    this.showCustomField.push(false);
    this.filteredMedicineLists.push([...this.cptCodeList]);
  }

  public removeItem(i: number): void {
    this.InvoiceItems.removeAt(i);
    this.filteredMedicineLists.splice(i, 1);
    this.getGrandTotal();
  }
  get DiagnosisCode() {
    return this.invForm.get('DiagnosisCode');
  }
  get BalanceAmount() {
    return this.invForm.get('BalanceAmount');
  }
  get GroupNo() {
    return this.invForm.get('GroupNo');
  }
  get InsuresRelationToPatient() {
    return this.invForm.get('InsuresRelationToPatient');
  }
  get InsuaranceName() {
    return this.invForm.get('InsuaranceName');
  }
  get InsuaranceNo() {
    return this.invForm.get('InsuaranceNo');
  }
  get ProcedureCodeLookUp() {
    return this.invForm.get('ProcedureCodeLookUp');
  }
  get InvoiceItems(): UntypedFormArray {
    return this.invForm.get('InvoiceItems') as UntypedFormArray;
  }
  get NpiNumber() {
    return this.invForm.get('NpiNumber');
  }
  get DoctorName() {
    return this.invForm.get('DoctorName');
  }
  get CoPay() {
    return this.invForm.get('CoPay');
  }
  get SubTotal() {
    return this.invForm.get('SubTotal');
  }
  get Total() {
    return this.invForm.get('Total');
  }
  get Date() {
    return this.invForm.get('Date');
  }
  get Title() {
    return this.invForm.get('Title');
  }
  get Amount() {
    return this.invForm.get('Amount');
  }
  get Description() {
    return this.invForm.get('Description');
  }
  get ProcedureCode() {
    return this.invForm.get('ProcedureCode');
  }
  get Duration() {
    return this.invForm.get('Duration');
  }
  get Units() {
    return this.invForm.get('Units');
  }
  get Status() {
    return this.invForm.get('Status');
  }
  get PatientName() {
    return this.invForm.get('PatientName');
  }
  getProcedureCode() {
    // this.cptCodeService.getCptCodes().subscribe((res) => {
    //   this.cptCodeList = res;
    // });
    const cachedCptCodes = this.localStorageService.getItem<any[]>(
      this.storageKey
    );

    if (cachedCptCodes && cachedCptCodes.length > 0) {
      // Load from local storage
      this.cptCodeList = cachedCptCodes;
      this.filteredMedicineLists.push([...this.cptCodeList]);
    } else {
      // Fetch from server if not in local storage
      this.cptCodeService.getCptCodes().subscribe((data: any[]) => {
        this.cptCodeList = data;
        this.filteredMedicineLists.push([...this.cptCodeList]);
        // Cache the data in local storage
        this.localStorageService.setItem(this.storageKey, data);
      });
    }
  }
  onFilter(filtered: any[], index: number) {
    this.filteredMedicineLists[index] = filtered; // Update filtered list for the specific dropdown
  }
  getPatient(id: number) {
    this.patientService.getPatientById(id).subscribe((res) => {
      this.PatientName.setValue(
        `${res.PatientFirstName} ${res.PatientLastName}`
      );
      // this.PatientName.setValue(res.PatientLastName);
    });
  }
  getPatientInsurance(id: number) {
    this.patientService.getPatientInsurance(id).subscribe((res) => {
      this.InsuaranceName.setValue(res.PatientInsurances[0].InsuaranceName);
      this.InsuaranceNo.setValue(res.PatientInsurances[0].InsuaranceNo);
      this.GroupNo.setValue(res.PatientInsurances[0].GroupNo);
      this.InsuresRelationToPatient.setValue(
        res.PatientInsurances[0].InsuresRelationToPatient
      );
    });
  }

  getPatientDoctor(id: number) {
    this.patientService.getDoctorId(id).subscribe((res) => {
      this.DoctorName.setValue(res.Doctor.DoctorName);
      this.NpiNumber.setValue(res.Doctor.NpiNumber);
      this.DoctorId = res.DoctorId;
    });
  }
  copy(e) {
    this.clipBoard.copy(e);
  }
  formSubmit() {
    let body = {
      ...this.invForm.value,
      PatientId: this.patientId,
      HospitalId: this.hospital.HospitalId,
      DoctorId: this.DoctorId,
      SoftwareFee: 1.5,
    };
    // console.log(body);
    this.invoiceService.registerInvoice(body).subscribe((_) => {
      this.isSuccess = true;
      this.invForm.reset();
    });
  }

  public getGrandTotal(): void {
    let total = 0;
    const invoiceItems = this.InvoiceItems.value;
    let x = this.invForm.value['CoPay'];

    invoiceItems.forEach((invoiceItem: { Amount: number }) => {
      if (invoiceItem.Amount) {
        total = total + invoiceItem.Amount;
      }
    });

    total = total - x;
    this.invForm.get('Total').setValue(total);
    this.SubTotal.setValue(total);
    this.BalanceAmount.setValue(total);
  }
  onSelectChange(selectedCode: string, index: number): void {
    const selectedItem = this.cptCodeList.find(
      (item) => item.Code === selectedCode
    );
    const formGroup = this.InvoiceItems.at(index);

    if (selectedItem) {
      const units = formGroup.get('Units')?.value || 0;
      const newAmount = selectedItem.UnitCharge * units;

      formGroup.patchValue({
        UnitCharge: selectedItem.UnitCharge,
        Amount: newAmount,
      });
    }
  }
  showCustomField: boolean[] = [];

  toggleCustomField(index: number): void {
    this.showCustomField[index] = true;
  }

  saveCustomCode(event: Event, index: number) {
    const input = (event.target as HTMLInputElement).value;
    const control = this.InvoiceItems.at(index).get('ProcedureCode');
    if (control) {
      control.setValue(input);
      this.showCustomField[index] = false;
    }
  }

  filterMedicineList(searchTerm: string, index: number): void {
    this.filteredMedicineLists[index] = this.cptCodeList.filter((item) =>
      item.Code.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }

  updateAmount(index: number): void {
    const formGroup = this.InvoiceItems.at(index);
    const unitCharge = formGroup.get('UnitCharge')?.value || 0;
    const units = formGroup.get('Units')?.value || 0;
    formGroup.patchValue({
      Amount: unitCharge * units,
    });
  }

  amountCalculation() {
    let ad = 0;
    let a = this.InvoiceItems.value;
    a.forEach((element) => {
      let d = element.Units;
      let b = this.UnitCharge;
      ad = d * b;
      this.InvoiceItems.get('Amount').setValue(ad);
    });

    // this.InvoiceItems.get('Amount').setValue(ab);
  }
  openFavoriteDiagnosisPopup() {
    const dialogRef = this.dialog.open(FavoriteDiagnosisPopupComponent, {
      width: '400px',
      data: { favoriteDiagnosisCodes: this.favoriteDiagnosisCodes }, // Pass your favorite codes here
    });

    dialogRef.afterClosed().subscribe((selectedCodes) => {
      if (selectedCodes && selectedCodes.length > 0) {
        this.insertCodesIntoEditor(selectedCodes); // Handle multiple selected codes
      }
    });
  }
  insertCodesIntoEditor(selectedCodes) {
    // Assuming selectedCodes is an array of objects, extract the relevant property (e.g., 'code')
    const extractedCodes = selectedCodes.map(
      (codeObj) =>
        codeObj.Code + ',' + codeObj.Description || codeObj.toString()
    );

    // Get the current value from the form control
    const currentValue = this.invForm.get('DiagnosisCode')?.value || '';

    // Join the extracted codes into a comma-separated string
    const newCodes = extractedCodes.join(',');

    // Update the invForm control with the new value
    const updatedValue = currentValue
      ? `${currentValue},${newCodes}`
      : newCodes;
    this.invForm.get('DiagnosisCode')?.setValue(updatedValue);

    console.log('Updated DiagnosisCode:', updatedValue); // For debugging
  }
}
