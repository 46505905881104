<div class="container">
    <h2>Electronic Remitance Advice </h2>
    <div class="ml-3">
        <div class="row">
            <div class="col-lg-6 float-right">
                <mat-form-field class="example-full-width mt-5">
                    <mat-label>Filter</mat-label>
                    <input matInput (keyup)="applyFilter($event)" placeholder="Ex.Mia" #input>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class=" mat-elevation-z8">
        <table mat-table [dataSource]="dataSource" matSort>


            <ng-container matColumnDef="payer_name">
                <th mat-header-cell *matHeaderCellDef> Payer name </th>
                <td mat-cell *matCellDef="let row"> {{row.payer_name }} </td>
            </ng-container>


            <ng-container matColumnDef="paid_amount">
                <th mat-header-cell *matHeaderCellDef> Paid Amount </th>
                <td mat-cell *matCellDef="let row"> {{row.paid_amount }} </td>
            </ng-container>

            <ng-container matColumnDef="paid_date">
                <th mat-header-cell *matHeaderCellDef> Paid Date </th>
                <td mat-cell *matCellDef="let row"> {{row.paid_date }} </td>
            </ng-container>
            <ng-container matColumnDef="prov_name">
                <th mat-header-cell *matHeaderCellDef> Provider Name </th>
                <td mat-cell *matCellDef="let row"> {{row.prov_name}} </td>
            </ng-container>
            <ng-container matColumnDef="prov_taxid">
                <th mat-header-cell *matHeaderCellDef> Provider Tax ID </th>
                <td mat-cell *matCellDef="let row"> {{row.prov_taxid}} </td>
            </ng-container>
            <ng-container matColumnDef="check_number">
                <th mat-header-cell *matHeaderCellDef> Check Number </th>
                <td mat-cell *matCellDef="let row"> {{row.check_number}} </td>
            </ng-container>

            <ng-container matColumnDef="prov_npi">
                <th mat-header-cell *matHeaderCellDef> Provider Npi </th>
                <td mat-cell *matCellDef="let row"> {{row.prov_npi}} </td>
            </ng-container>


            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef> Action </th>
                <td mat-cell *matCellDef="let row">
                    <button type="button" (click)="getPdf(row.eraid)" mat-mini-fab color="mute">
                        <mat-icon matPrefix>visibility</mat-icon>
                    </button>

                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
            </tr>
        </table>

        <mat-paginator [pageSizeOptions]="[5, 10, 25,50, 100,150,200,500,750,1000]"></mat-paginator>
    </div>

    <iframe *ngIf="pdfSrc" [src]="pdfSrc" width="100%" height="600px"></iframe>

    <!-- <div>
        <mat-card>
            <div class="container mt-4">
                <h3>Submitted Claims Status </h3>
                <table class="table table-bordered table-hover">
                    <thead>
                        <tr>
                            <th>Total Charges</th>
                            <th>Status</th>
                            <th>Filename</th>
                            <th>Claim id</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of xmlList; let i = index">
                            <td>{{ item.TotalCharge }}</td>
                            <td [ngClass]="getStatusClass(item.Status)">{{ getStatusText(item.Status) }}</td>
                            <td>{{ item.FileName }}</td>
                            <td>{{ item.ClaimMdId }}</td>
                            <td><button (click)="resubmit(item.ClaimMdId)"><mat-icon>edit</mat-icon></button></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </mat-card>
    </div> -->
</div>
<!-- 
<div>
    <h2>Track Claim Status</h2>
    <input [(ngModel)]="inboundId" placeholder="Enter Inbound ID" />
    <button (click)="trackClaim()">Track</button>

    <div *ngIf="status">
        <h3>Claim Status:</h3>
        <pre>{{ status | json }}</pre>
    </div>
</div> -->