import { Component } from '@angular/core';

@Component({
  selector: 'app-update-practice-manager',
  templateUrl: './update-practice-manager.component.html',
  styleUrls: ['./update-practice-manager.component.css']
})
export class UpdatePracticeManagerComponent {

}
