<div>
    <div>
        <app-tab2-clinic-side [tabsArray]="tabs2" (onTabChange)="tabChange($event)"></app-tab2-clinic-side>
    </div>

    <div *ngIf="activatedTabIndex ==0">
        <app-add-appointment></app-add-appointment>


    </div>
    <div *ngIf="activatedTabIndex ==1">
        <app-patient-intake-items></app-patient-intake-items>

    </div>

    <div *ngIf="activatedTabIndex ==2">
        <app-add-invoice></app-add-invoice>

    </div>
    <div *ngIf="activatedTabIndex ==3">
        <app-add-patient-insurance></app-add-patient-insurance>

    </div>
    <div *ngIf="activatedTabIndex ==4">
        <app-add-patient-idproof></app-add-patient-idproof>

    </div>
    <div *ngIf="activatedTabIndex ==5">
        <app-add-emergency-contact></app-add-emergency-contact>

    </div>
    <div *ngIf="activatedTabIndex ==6">
        <app-add-patient-pharmacy></app-add-patient-pharmacy>

    </div>
    <div *ngIf="activatedTabIndex ==7">
        <app-patient-history></app-patient-history>


    </div>
    <!-- <div *ngIf="activatedTabIndex ==8">
        <app-add-refferal></app-add-refferal>


    </div> -->

    <div *ngIf="activatedTabIndex ==8">
        <app-add-upload-lab-repor></app-add-upload-lab-repor>


    </div>
    <div *ngIf="activatedTabIndex ==9">
        <app-add-upload-radiology-report></app-add-upload-radiology-report>


    </div>
    <div *ngIf="activatedTabIndex ==10">
        <app-add-pain-chart></app-add-pain-chart>


    </div>
    <div *ngIf="activatedTabIndex ==11">

        <app-claim></app-claim>

    </div>
    <div *ngIf="activatedTabIndex ==12">

        <app-claim-attachment></app-claim-attachment>

    </div>
</div>