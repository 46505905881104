// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  stripePublishableKey:
    'pk_live_51IyUaAJnyILmQggyZxkWE0x9h2yKfuLi8mA7nJLMscjV73ZBTxRQv1Jfm2kkodBZMJ2i1Oi5KmPGBi0piMVkyzZ000WSawXnfZ',
  apiUrl: 'https://svc.claim.md/services/',
  accountKey: '21291_il0wt6A031du9$5@CS#zP9oV',
  imgUrlS3: 'https://binsera-general-images.s3.amazonaws.com/Images/',
  Url2: 'https://binsera.net/api/',

  // Url2: 'http://localhost:46740/api/',

  Url: 'https://binseraneurology.com/api/',

  imgurl: 'https://binseraneurology.com/Images/',
  mriurl: 'https://binseraneurology.com/Uploads/MRI/',
  // Url: 'http://localhost:46740/api/',

  // imgurl: 'http://localhost:46740/Images/',
  // mriurl: 'http://localhost:46740/Uploads/MRI/',
};

/*
 * For easier debugging in development mode, you can import the following file
 *const ; to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
