<div class="ml-3">
    <div class="row">
        <div class="col-lg-6 float-right">
            <mat-form-field class="example-full-width mt-5">
                <mat-label>Filter</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Ex.Mia" #input>
            </mat-form-field>
        </div>
    </div>
</div>
<div class=" mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" matSort>



        <ng-container matColumnDef="Date">
            <th mat-header-cell *matHeaderCellDef> Date </th>
            <td mat-cell *matCellDef="let row"> {{row.createdDate | date:'medium'}} </td>
        </ng-container>
        <ng-container matColumnDef="ReportName">
            <th mat-header-cell *matHeaderCellDef> Report Name </th>
            <td mat-cell *matCellDef="let row"> {{row.ReportName}} </td>
        </ng-container>


        <ng-container matColumnDef="DoctorName">
            <th mat-header-cell *matHeaderCellDef> Physician </th>
            <td mat-cell *matCellDef="let row"> {{row.DoctorName}} </td>
        </ng-container>


        <ng-container matColumnDef="View">
            <th mat-header-cell *matHeaderCellDef> View </th>
            <td mat-cell *matCellDef="let row">
                <button class="btn mybtn" (click)="modal1.show();viewReport(row) " mat-mini-fab
                    color="primary"><mat-icon>visibility</mat-icon></button>&nbsp;

                <app-modal #modal1 class="panel-demo1" [maximizable]="true" [backdrop]="false">
                    <ng-container class="app-modal-header">Patient Name: {{patient?.PatientFirstName}} &nbsp;
                        {{patient?.PatientLastName}}</ng-container>
                    <ng-container class="app-modal-body">
                        <div class="row ">

                            <div class=" col-lg float-right send-email-prescription d-flex align-items-baseline ">

                                <mat-form-field class="w-100 mr-3" appearance="outline">
                                    <mat-label>Enter Email to send records</mat-label>
                                    <input matInput type="text" [formControl]="email" placeholder="Enter email">
                                </mat-form-field>
                                <button mat-raised-button color="primary" class="mx-5" (click)="send()">Send</button>
                                <br>
                                <button mat-mini-fab color="mute"
                                    (click)="downloadAsPDF()"><mat-icon>download</mat-icon></button>

                            </div>
                        </div>
                        <div id="printable" #PatientVisit>

                            <app-view-hospital></app-view-hospital>

                            <div class="row">

                                <div class="col-lg-2"><span class="bld">Patient No:</span>
                                    {{patient?.PatientId}}</div>
                                <div class="col-lg-3">
                                    <span class="bld">Patient Name:</span> {{patient?.PatientFirstName}}
                                    {{patient?.PatientLastName}}
                                </div>
                                <div class="col-lg-2"><span class="bld">Date Of Birth:</span> {{patient?.PatientDOB}}
                                </div>
                                <div class="col-lg-2"><span class="bld">SSN:</span> {{patient?.PatientNationalId}}</div>
                                <div class="col-lg-3"><span class="bld">Address:</span> {{patient?.PatientAddress}}
                                </div>
                            </div>
                            <hr>

                            <div class="row">
                                <div class="col-lg-3"> <span class="bld">Provider Name:</span> {{row?.DoctorName}},
                                    {{row.DoctorQualification}}</div>

                                <div class="col-lg-3"><span class="bld">Visit Date:</span> {{row?.VistitDate | date
                                    :'short'}}
                                </div>
                                <!-- <div class="col-lg-4"><span class="bld">Visit Time:</span> {{row?.VistTime}}</div> -->
                                <div class="col-lg-3"><span class="bld">Report Id :</span> {{row?.DoctorReportId}}</div>
                                <div class="col-lg-3"><span class="bld">Report Date :</span> {{row?.createdDate| date
                                    :'short'}}</div>
                            </div>
                            <hr>

                            <!-- <p>Decription: {{reportDetails}}</p> -->
                            <div class="mt-2" [innerHTML]="reportDetails"></div>
                            <div class="row mt-5">
                                <div class="col-lg-6">
                                    <div class="row">
                                        <apx-chart [series]="heartRateChart.series" [chart]="heartRateChart.chart"
                                            [xaxis]="heartRateChart.xAxis" [title]="heartRateChart.title"></apx-chart>
                                    </div>

                                    <div class="row">
                                        <apx-chart [series]="respirationChart.series" [chart]="respirationChart.chart"
                                            [xaxis]="respirationChart.xAxis"
                                            [title]="respirationChart.title"></apx-chart>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="row">
                                        <apx-chart [series]="tempratureChart.series" [chart]="tempratureChart.chart"
                                            [xaxis]="tempratureChart.xAxis" [title]="tempratureChart.title"></apx-chart>
                                    </div>
                                    <div class="row" *ngIf="bloodPressureChart.xAxis?.categories?.length > 0">
                                        <apx-chart [series]="bloodPressureChart.series"
                                            [chart]="bloodPressureChart.chart" [xaxis]="bloodPressureChart.xAxis"
                                            [title]="bloodPressureChart.title"></apx-chart>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <apx-chart [series]="OxygenSaturationChart.series" [chart]="OxygenSaturationChart.chart"
                                    [xaxis]="OxygenSaturationChart.xAxis"
                                    [title]="OxygenSaturationChart.title"></apx-chart>
                            </div>
                            <!-- mri Images start-->
                            <div *ngIf="templatesRadiologyStudyReports && templatesRadiologyStudyReports.length > 0">
                                <h3>Most Recent Radiology Reports</h3>
                                <div *ngFor="let report of templatesRadiologyStudyReports">
                                    <div class="report">
                                        <h4>Report Id: {{ report.UploadRadiologyReportId }}</h4>
                                        <!-- Replace with actual field name -->
                                        <p>Date Uploaded: {{ report.createdDate | date:'short' }}</p>
                                        <!-- Replace with actual date field -->


                                        <img [src]="report.StudyReport" alt="Radiology Image"
                                            *ngIf="report.StudyReport" />
                                        <!-- Replace with actual image URL field -->
                                        <p *ngIf="!report.StudyReport">No Study image available</p>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="templatesRadiologyStudyReports.length === 0">
                                <p>No Study reports available.</p>
                            </div>

                            <!-- mri Images end -->
                            <!-- lab report start-->
                            <div *ngIf="templatesLabReportsUploaded && templatesLabReportsUploaded.length > 0">
                                <h3>Most Recent Radiology Reports</h3>
                                <div *ngFor="let labreport of templatesLabReportsUploaded">
                                    <div class="report">
                                        <h4>Report Id: {{ labreport.UploadLabReportId }}</h4>
                                        <p>Date Uploaded: {{ labreport.createdDate | date:'short' }}</p>

                                        <div [innerHTML]="labreport.LabReportImage"></div>
                                        <p *ngIf="!labreport.LabReportImage">No image available</p>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="templatesLabReportsUploaded.length === 0">
                                <p>No Lab reports available.</p>
                            </div>

                            <!-- lab report end-->

                            <p class="mt-5">Reported & Signed By: {{row.DoctorName}}</p>
                            <p>Date: {{row.createdDate | date:'short'}}</p>

                        </div>
                    </ng-container>
                    <ng-container class="app-modal-footer">
                        <button type="button" (click)="print()" class="btn btn-primary ">Print</button>

                        <button class="dt-button dt-blue" style="float: right;" (click)="modal1.hide()">Close</button>
                    </ng-container>
                </app-modal>

            </td>
        </ng-container>
        <!-- modal  -->



        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
        </tr>
    </table>

    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100,150,200,500,750,1000]"></mat-paginator>
</div>