import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-nurse-side-split-b',
  templateUrl: './nurse-side-split-b.component.html',
  styleUrls: ['./nurse-side-split-b.component.css'],
})
export class NurseSideSplitBComponent implements OnInit {
  id = 0;
  constructor(
    private route: ActivatedRoute,
    private tokenService: TokenService
  ) {}
  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
  }
  tabs2: string[] = [
    'Vitals Report',
    'Nurse Progress Note',
    'Nurse  Note',
    'Prescription',
    'Doctor Progess Notes',
    'After Visit Summary',
    'Medical Reports',
    'Communication-Assessments',
    'Doctor Initial Evaluation',
    'All  Orders',

    'Lab-Reports',
    'Lab Chart',
    'Procedure Test Result',
    'Radiology-Reports',
    'View Study Image',
    'Rounds Book',
    'Nerve Study Result',
    'Electromyography Result',
    'Review Of Systems',

    'Allergy History',
    'Surgical History',
    'Patient Summary',
    'Pain Chart',
    'Vital Chart',
  ];
  activatedTabIndex2: number = 0;

  tabChange2(tabIndex2: number) {
    // debugger;
    this.activatedTabIndex2 = tabIndex2;
  }
}
