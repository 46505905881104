import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Allergy } from 'src/app/Models/allergy.models';
import { Doctor } from 'src/app/Models/doctor.model';
import { Patient } from 'src/app/Models/patient.model';
import { PatientVisit } from 'src/app/Models/patientvisit.model';
import { DoctorService } from 'src/app/services/doctor.service';
import { PatientService } from 'src/app/services/patient.service';
import { PatientvisitService } from 'src/app/services/patientvisit.service';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-doctor-patient-split-view',
  templateUrl: './doctor-patient-split-view.component.html',
  styleUrls: ['./doctor-patient-split-view.component.css'],
})
export class DoctorPatientSplitViewComponent implements OnInit {
  id = 0;
  patient?: Patient;
  patientVisitList: PatientVisit[] = [];
  isAdmitted = false;
  filteredList;
  pvId = 0;
  patientVisitId: PatientVisit[];
  showItem = '';
  helper = new JwtHelperService();
  myToken = this.tokenService.getToken();
  doctorId = 0;
  Doctor: Doctor;
  selectedIndex: number;
  buttonText: string = 'Open Modal';
  patientAllergies: Allergy[] = [];
  showDropdown: boolean = false;
  showDropdown1: boolean = false;
  showDropdown2: boolean = false;
  keywords: string[] = [
    'Allergy',
    'RxActive',
    'RxLast-5',
    'RxLast-10',
    'LatestVitals',
    'VitalsRecent-10',
    'BPRecent',
    'BPlast-10days',
  ];
  keywords1: string[] = [
    'LatestLabReports',
    'BSugarlast-5',
    'BSugarlast10d',
    'LatestCholesterol-5',
    'LatestCholesterol-10d',
  ];
  keywords2: string[] = [
    'Allergy',
    'RxActive',
    'RecentPharmacy',
    'RecentOrders',
    // 'RecentLabOrder',
    // 'RecentProcedureOrder',
    // 'RecentRadiologyOrder',
    'RefferalsToday',
    'RecentRefferals',
  ];
  // 'MostRecentProgressNote',
  // 'MostRecentStudyReports',
  //     'MostRecentLabReports',
  //     'MostRecentLabReportsUploaded',
  constructor(
    private patientService: PatientService,
    private route: ActivatedRoute,
    private router: Router,
    private ptVisitService: PatientvisitService,
    private doctorService: DoctorService,
    private tokenService: TokenService
  ) {
    this.start();
  }

  isResizing = false;
  leftPanelWidth: number = 50; // Default width 50%
  rightPanelWidth: number = 50; // Default width 50%

  // Initialize panel widths from localStorage if they exist
  ngOnInit(): void {
    const savedLeftPanelWidth = localStorage.getItem('leftPanelWidth');
    const savedRightPanelWidth = localStorage.getItem('rightPanelWidth');

    if (savedLeftPanelWidth && savedRightPanelWidth) {
      this.leftPanelWidth = parseFloat(savedLeftPanelWidth);
      this.rightPanelWidth = parseFloat(savedRightPanelWidth);
    }

    let decodedToken = this.helper.decodeToken(this.myToken);
    this.doctorId = parseInt(decodedToken.nameid);
    this.getDoctor(this.doctorId);

    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    this.patientService.getPatientById(this.id).subscribe((res) => {
      this.patient = res;

      this.filteredList = res.PatientVisits;
      this.patientVisitList = res.PatientVisits;
      //  if(res.PatientVisits.filter(x=>x.VisitType=="In Patient")){
      //   this.isAdmitted
      //  }
    });
    this.getAllergies(this.id);
  }

  printPage() {
    window.print();
  }

  getDoctor(id) {
    this.doctorService.getDoctorById(id).subscribe((res) => {
      this.Doctor = res;
    });
  }

  getAllergies(id) {
    this.patientService.getPatientAllergy(id).subscribe((res) => {
      this.patientAllergies = res.Allergies;
    });
  }
  search(searchValue) {
    // console.log(searchValue);
    if (searchValue.value != null) {
      this.filteredList = this.patientVisitList.filter((x) =>
        x.VistitDate.toLowerCase().includes(searchValue.value.toLowerCase())
      );
    }
  }

  deleteVisit(id) {
    let C_confirm = confirm('Are you Sure you want to delete ?');
    if (C_confirm == true) {
      this.ptVisitService.deletePatientVisit(id).subscribe((res) => {
        this.filteredList = this.patientVisitList.filter(
          (x) => x.PatientVisitId != id
        );
      });
    }
  }

  logout() {
    this.tokenService.clearToken();

    // Clear local session data
    // localStorage.removeItem('authToken');
    localStorage.removeItem('token');

    // Set a flag to notify other tabs/windows
    localStorage.setItem('logoutEvent', 'logout' + Math.random());
    // Redirect to login page
    this.router.navigate(['/']);
  }

  // Bind the eventListener
  private start(): void {
    window.addEventListener('storage', this.storageEventListener.bind(this));
  }

  // Logout only when key is 'logout-event'
  private storageEventListener(event: StorageEvent) {
    if (event.storageArea == localStorage) {
      if (event?.key && event.key == 'logout-event') {
        // console.log('🔥 ~ storageEventListener ~ event', event.newValue);
        this.logout();
      }
    }
  }

  // Handle active listeners when onDestroy
  private stop(): void {
    window.removeEventListener('storage', this.storageEventListener.bind(this));
  }

  ngOnDestroy() {
    this.stop();
  }

  currentView: string = 'home';

  // You can add logic to switch the view dynamically
  changeView(view: string): void {
    this.currentView = view;
  }

  // Start resizing when the mouse is pressed on the resizer
  startResize(event: MouseEvent): void {
    this.isResizing = true;
    document.body.style.cursor = 'ew-resize'; // Change cursor to resizing
    event.preventDefault(); // Prevent text selection while resizing

    // Store the initial positions and panel widths
    const initialLeftPanelWidth = this.leftPanelWidth;
    const initialMouseX = event.clientX;

    // Listen to mousemove events to resize the panels
    const onMouseMove = (moveEvent: MouseEvent) => {
      if (this.isResizing) {
        const deltaX = moveEvent.clientX - initialMouseX; // Calculate mouse movement
        const newLeftPanelWidth =
          initialLeftPanelWidth + (deltaX / window.innerWidth) * 100; // Update width for the left panel

        // Ensure the new panel width is between 10% and 90%
        this.leftPanelWidth = Math.min(Math.max(newLeftPanelWidth, 10), 90);
        this.rightPanelWidth = 100 - this.leftPanelWidth; // Adjust right panel width accordingly

        // Save the panel sizes to localStorage
        localStorage.setItem('leftPanelWidth', this.leftPanelWidth.toString());
        localStorage.setItem(
          'rightPanelWidth',
          this.rightPanelWidth.toString()
        );
      }
    };

    // Stop resizing when mouse button is released
    const onMouseUp = () => {
      this.isResizing = false;
      document.body.style.cursor = 'default';
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    };

    // Attach event listeners
    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
  }

  @HostListener('window:resize')
  onResize(): void {
    // Ensure the panel widths adjust if the window is resized
    this.leftPanelWidth = Math.min(Math.max(this.leftPanelWidth, 10), 90);
    this.rightPanelWidth = 100 - this.leftPanelWidth;
  }

  toggleDropdown() {
    this.showDropdown = !this.showDropdown;
    // console.log(
    //   `Toggling dropdown. Current state: ${!this.showDropdown} New state: ${
    //     this.showDropdown
    //   }`
    // );
  }
  toggleDropdown1() {
    this.showDropdown1 = !this.showDropdown1;
    // console.log(
    //   `Toggling dropdown. Current state: ${!this.showDropdown1} New state: ${
    //     this.showDropdown1
    //   }`
    // );
  }
  toggleDropdown2() {
    this.showDropdown2 = !this.showDropdown2;
    // console.log(
    //   `Toggling dropdown. Current state: ${!this.showDropdown1} New state: ${
    //     this.showDropdown1
    //   }`
    // );
  }
  copyToClipboard(keyword: string): void {
    navigator.clipboard
      .writeText(keyword)
      .then(() => {
        // alert(`Copied to clipboard: ${keyword}`);
      })
      .catch((err) => {
        console.error('Failed to copy text: ', err);
      });
  }
}
