<div class="ml-3">
    <div class="row">
        <div class="col-lg-6 float-right">
            <mat-form-field class="example-full-width mt-5">
                <mat-label>Filter</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Ex.Mia" #input>
            </mat-form-field>
        </div>
    </div>
</div>
<div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" matSort>




        <ng-container matColumnDef="PatientName">
            <th mat-header-cell *matHeaderCellDef> PatientName </th>
            <td mat-cell *matCellDef="let row"> {{row.PatientName}} </td>
        </ng-container>
        <ng-container matColumnDef="Title">
            <th mat-header-cell *matHeaderCellDef> Title </th>
            <td mat-cell *matCellDef="let row"> {{row.Title}} </td>
        </ng-container>

        <ng-container matColumnDef="Total">
            <th mat-header-cell *matHeaderCellDef> Amount </th>
            <td mat-cell *matCellDef="let row"> {{row.Total}} </td>
        </ng-container>
        <!-- <ng-container matColumnDef="Description">
          <th class="message" mat-header-cell *matHeaderCellDef > Description </th>
          <td class="message" mat-cell *matCellDef="let row"> {{row.Description}} </td>
        </ng-container> -->
        <ng-container matColumnDef="Date">
            <th mat-header-cell *matHeaderCellDef> Date </th>
            <td mat-cell *matCellDef="let row"> {{row.Date}} </td>
        </ng-container>
        <ng-container matColumnDef="Status">
            <th mat-header-cell *matHeaderCellDef> Status </th>
            <td mat-cell *matCellDef="let row"> {{row.Status}} </td>
        </ng-container>
        <ng-container matColumnDef="Detail">
            <th mat-header-cell *matHeaderCellDef> Patient Details</th>
            <td mat-cell *matCellDef="let row">
                <!-- <button mat-mini-fab color="primary"
          (click)="openAddAppointmentModal(row.PatientId,row.PatientFirstName,row.PatientLastName)"><mat-icon>visibility</mat-icon></button> -->
                <a type="button" target="_blank" [routerLink]="['/billerPatient/',row.PatientId]" mat-mini-fab
                    color="primary">
                    <mat-icon matPrefix>remove_red_eye</mat-icon>
                </a>
            </td>
        </ng-container>
        <!-- <ng-container matColumnDef="Print">
            <th mat-header-cell *matHeaderCellDef> Print </th>
            <td mat-cell *matCellDef="let element">
                <button type="button" [routerLink]="['/printInvoice/',element.InvoiceId]" mat-mini-fab color="primary">
                    <mat-icon matPrefix>print</mat-icon>
                </button>
            </td>
        </ng-container> -->
        <!-- <ng-container matColumnDef="Update">
            <th mat-header-cell *matHeaderCellDef> Edit </th>
            <td mat-cell *matCellDef="let row">
                <button type="button" (click)="updateInvoice(row.InvoiceId)" mat-mini-fab color="primary">
                    <mat-icon matPrefix>edit</mat-icon>
                </button>
             
            </td>
        </ng-container> -->
        <!-- <ng-container matColumnDef="Delete">
            <th mat-header-cell *matHeaderCellDef> Delete</th>
            <td mat-cell *matCellDef="let element">
                <button (click)="delete(element.InvoiceId)" mat-mini-fab
                    color="accent"><mat-icon>delete</mat-icon></button>
            </td>
        </ng-container> -->

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
        </tr>
    </table>

    <mat-paginator [pageSizeOptions]="[ 10, 25, 100]"></mat-paginator>
</div>