import { Component } from '@angular/core';

@Component({
  selector: 'app-update-medical-assistant',
  templateUrl: './update-medical-assistant.component.html',
  styleUrls: ['./update-medical-assistant.component.css']
})
export class UpdateMedicalAssistantComponent {

}
