import {
  HttpHeaders,
  HttpErrorResponse,
  HttpClient,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/internal/operators/catchError';
import { environment } from 'src/environments/environment';
import { Patient } from '../Models/patient.model';
import { EmailPayload } from '../Models/email.model';
import { FaxPayload } from '../Models/fax.mode';

@Injectable({
  providedIn: 'root',
})
export class PatientService {
  url = environment.Url + 'Patients';
  addPatientUrl = environment.Url + 'Users/createPatient';
  patientChats = environment.Url + 'Patients/patientChats';
  painCharts = environment.Url + 'Patients/painCharts';
  generalUploads = environment.Url + 'Patients/generalUploads';
  SearchPatient = environment.Url + 'Patients/SearchPatient';

  allergy = environment.Url + 'Patients/allergy';
  visits = environment.Url + 'Patients/visits';
  doctorNotes = environment.Url + 'Patients/doctorNotes';
  prescription = environment.Url + 'Patients/prescription';
  patientPrescription = environment.Url + 'Patients/patientPrescription';
  surgery = environment.Url + 'Patients/surgicalHistory';
  invoice = environment.Url + 'Patients/invoices';
  labReport = environment.Url + 'Patients/labReport';
  radiologyReport = environment.Url + 'Patients/radiologyReport';
  labOrder = environment.Url + 'Patients/labOrder';
  procedureOrder = environment.Url + 'Patients/procedureOrder';
  procedureTestResults = environment.Url + 'Patients/procedureTestResults';
  radiologyOrder = environment.Url + 'Patients/radiologyOrder';
  radiologyMRI = environment.Url + 'Patients/radiologyMRI';
  radiologyMRI_V1 = environment.Url + 'Patients/radiologyMRI_V1';

  emContact = environment.Url + 'Patients/emergencyContact';
  ptInsurance = environment.Url + 'Patients/patientInsurance';
  ptIdProof = environment.Url + 'Patients/patientIdProof';
  ptBooking = environment.Url + 'Patients/patientBooking';
  ptMedication = environment.Url + 'Patients/patientMedication';
  nurseReport = environment.Url + 'Patients/nurseReport';
  bradenScale = environment.Url + 'Patients/patientBradenScale';
  hesterDavies = environment.Url + 'Patients/patientHesterDavies';
  patientImport = environment.Url + 'Patients/patientImport';
  purposefulRounds = environment.Url + 'Patients/purposefulRounds';
  nursePatient = environment.Url + 'Patients/nursePatient';
  dischargeSummary = environment.Url + 'Patients/dischargeSummary';
  patientVaccines = environment.Url + 'Patients/patientVaccines';
  otherTask = environment.Url + 'Patients/otherTask';
  medicineGiven = environment.Url + 'Patients/medicineGiven';
  intakeAssessment = environment.Url + 'Patients/intakeAssessment';
  communicationAssessment =
    environment.Url + 'Patients/communicationAssessment';
  doctorReport = environment.Url + 'Patients/doctorReport';
  visitSummary = environment.Url + 'Patients/visitSummary';

  nurseProgressReport = environment.Url + 'Patients/nurseProgressReport';
  nurseNote = environment.Url + 'Patients/nurseNote';
  generalSaftey = environment.Url + 'Patients/generalSaftey';
  familyHistory = environment.Url + 'Patients/familyHistory';
  socialHistory = environment.Url + 'Patients/socialHistory';
  medicalHistory = environment.Url + 'Patients/medicalHistory';
  hippaCompliance = environment.Url + 'Patients/hippaCompliance';
  physicalTherapyVisits = environment.Url + 'Patients/physicalTherapyVisits';
  ptInitialEvaluations = environment.Url + 'Patients/ptInitialEvaluations';
  physicalTherapyBookings =
    environment.Url + 'Patients/physicalTherapyBookings';
  completePatientInformationUrl =
    environment.Url + 'Patients/completePatientForm';
  doctorEvaluation = environment.Url + 'Patients/doctorEvaluation';
  reviewOfSystem = environment.Url + 'Patients/reviewOfSystem';
  nurseDailyAssessment = environment.Url + 'Patients/nurseDailyAssessment';
  nurseHandOffNote = environment.Url + 'Patients/nurseHandOffNote';
  patientPayment = environment.Url + 'Patients/patientPayment';
  ticketToRide = environment.Url + 'Patients/ticketToRide';
  disclosureAndConsent = environment.Url + 'Patients/disclosureAndConsent';
  inPatientScreeningMRI = environment.Url + 'Patients/inPatientScreeningMRI';
  pastMedication = environment.Url + 'Patients/pastMedication';
  completeVaccine = environment.Url + 'Patients/completeVaccine';
  refferal = environment.Url + 'Patients/PatientRefferal';
  uploadedLabReport = environment.Url + 'Patients/uploadedLabReport';
  uploadedRadiologyReport =
    environment.Url + 'Patients/uploadedRadiologyReport';

  bloodTransfusionConsent =
    environment.Url + 'Patients/bloodTransfusionConsent';

  narcoticMedicalPolicyAgreement =
    environment.Url + 'Patients/narcoticMedicalPolicyAgreement';

  generalConsentForTreatment =
    environment.Url + 'Patients/generalConsentForTreatment';

  getPhotographicConsent = environment.Url + 'Patients/getPhotographicConsent';

  getOfficePolicy = environment.Url + 'Patients/getOfficePolicy';
  patientsPharmacy = environment.Url + 'Patients/patientsPharmacy';
  patienHospitalId = environment.Url + 'Patients/patienHospitalId';
  patientDoctorId = environment.Url + 'Patients/patientDoctorId';

  doctorId = environment.Url + 'Patients/doctorId';
  nerveStudyResult = environment.Url + 'Patients/nerveStudyResult';
  electromyography = environment.Url + 'Patients/electromyography';
  serviceRequest = environment.Url + 'Patients/serviceRequest';

  emailPresciption = environment.Url + 'Emails/generalEmail';
  emailInsuarance = environment.Url + 'Emails/generalEmail';
  emailInvoice = environment.Url + 'Emails/generalEmail';
  generalEmail = environment.Url + 'Emails/generalEmail';
  // generalEmail = environment.Url + 'Emails/generalEmail';
  emailRefferal = environment.Url + 'Emails/refferalEmail';
  faxPresciption = environment.Url + 'Emails/Fax';

  imgUrl = environment.Url + 'ImageUpload';
  imgUrlS3 = environment.Url + 'ImageUpload/Imageupload';
  claimsUrl = environment.Url + 'Claims/upload-claim';

  header = new HttpHeaders({
    Authorization: 'token' + localStorage.getItem('token'),
  });
  constructor(private http: HttpClient) {}
  private apiUrl = 'https://pharmacy-api.com/send-prescription'; // Replace with actual API endpoint

  // searchPatient(
  //   patientNationalId?: string,
  //   phoneNumber?: string
  // ): Observable<any> {
  //   const params: any = {};
  //   if (patientNationalId) params.patientNationalId = patientNationalId;
  //   if (phoneNumber) params.phoneNumber = phoneNumber;

  //   return this.http.get<any>(this.SearchPatient, { params });
  // }

  // Search patients by National ID or Phone Number
  searchPatients(nationalId?: string, phoneNumber?: string): Observable<any[]> {
    const params: any = {};
    if (nationalId) params.nationalId = nationalId;
    if (phoneNumber) params.phoneNumber = phoneNumber;

    return this.http.get<any[]>(this.SearchPatient, { params });
  }
  sendEPrescription(body: any) {
    return this.http.post(this.apiUrl, body, {
      headers: { 'Content-Type': 'application/json' },
    });
  }
  imageUpload(body) {
    return this.http.post(this.imgUrl, body);
  }
  imageUploadS3(body) {
    return this.http.post(this.imgUrlS3, body);
  }

  getImages(): Observable<any[]> {
    return this.http.get<
      any[]
    >(`${environment.Url}/images/Series001/IMG00001.dcm
`);
  }
  sendInvoiceToInsurance(body): Observable<EmailPayload> {
    return this.http
      .post<EmailPayload>(this.emailInsuarance, body)
      .pipe(catchError(this.handleError));
  }
  sendClaimToInsurance(formData: FormData): Observable<any> {
    // return this.http.post(this.claimsUrl, formData);
    return this.http.post<string>(this.claimsUrl, formData, {
      responseType: 'text' as 'json',
    });
  }

  sendRefferal(body): Observable<EmailPayload> {
    return this.http
      .post<EmailPayload>(this.emailRefferal, body)
      .pipe(catchError(this.handleError));
  }
  // sendGeneralEmail(body): Observable<EmailPayload> {
  //   return this.http
  //     .post<EmailPayload>(this.generalEmail, body)
  //     .pipe(catchError(this.handleError));
  // }
  sendPrescription(body): Observable<EmailPayload> {
    return this.http
      .post<EmailPayload>(this.emailPresciption, body)
      .pipe(catchError(this.handleError));
  }
  sendPrescriptionFax(body): Observable<FaxPayload> {
    return this.http
      .post<FaxPayload>(this.faxPresciption, body)
      .pipe(catchError(this.handleError));
  }
  sendInvoice(body): Observable<EmailPayload> {
    return this.http
      .post<EmailPayload>(this.emailInvoice, body)
      .pipe(catchError(this.handleError));
  }
  sendEmail(body): Observable<EmailPayload> {
    return this.http
      .post<EmailPayload>(this.generalEmail, body)
      .pipe(catchError(this.handleError));
  }
  PatientBulkImport(body): Observable<Patient> {
    return this.http
      .post<Patient>(this.patientImport, body)
      .pipe(catchError(this.handleError));
  }
  registerUser(body): Observable<Patient> {
    return this.http
      .post<Patient>(this.addPatientUrl, body)
      .pipe(catchError(this.handleError));
  }
  registerPatient(body): Observable<Patient> {
    return this.http
      .post<Patient>(this.url, body)
      .pipe(catchError(this.handleError));
  }

  getPatients(): Observable<Patient[]> {
    return this.http
      .get<Patient[]>(this.url)
      .pipe(catchError(this.handleError));
  }
  getPatientById(id): Observable<Patient> {
    return this.http
      .get<Patient>(this.url + '/' + id)
      .pipe(catchError(this.handleError));
  }
  getpatienHospitalId(id): Observable<Patient> {
    return this.http.get<Patient>(this.patienHospitalId + '/' + id);
  }
  getPatientDoctorId(id): Observable<Patient> {
    return this.http.get<Patient>(this.patientDoctorId + '/' + id);
  }
  getDoctorId(id): Observable<Patient> {
    return this.http.get<Patient>(this.doctorId + '/' + id);
  }
  GetnerveStudyResult(id): Observable<Patient> {
    return this.http.get<Patient>(this.nerveStudyResult + '/' + id);
  }
  GetElectromyography(id): Observable<Patient> {
    return this.http.get<Patient>(this.electromyography + '/' + id);
  }
  GetServiceRequest(id): Observable<Patient> {
    return this.http.get<Patient>(this.serviceRequest + '/' + id);
  }
  getPatientsPharmacy(id): Observable<Patient> {
    return this.http.get<Patient>(this.patientsPharmacy + '/' + id);
  }
  getPatientAllergy(id): Observable<Patient> {
    return this.http.get<Patient>(this.allergy + '/' + id);
  }
  getPatieninvoices(id): Observable<Patient> {
    return this.http.get<Patient>(this.invoice + '/' + id);
  }
  getPatienLabReport(id): Observable<Patient> {
    return this.http.get<Patient>(this.labReport + '/' + id);
  }
  getPatientRadiologyReport(id): Observable<Patient> {
    return this.http.get<Patient>(this.radiologyReport + '/' + id);
  }
  getPatienLabOrder(id): Observable<Patient> {
    return this.http.get<Patient>(this.labOrder + '/' + id);
  }
  getPatienProcedure(id): Observable<Patient> {
    return this.http.get<Patient>(this.procedureOrder + '/' + id);
  }
  getProcedureTestResults(id): Observable<Patient> {
    return this.http.get<Patient>(this.procedureTestResults + '/' + id);
  }
  getRadiologyOrder(id): Observable<Patient> {
    return this.http.get<Patient>(this.radiologyOrder + '/' + id);
  }
  getRadiologyMRI(id): Observable<Patient> {
    return this.http.get<Patient>(this.radiologyMRI + '/' + id);
  }
  getRadiologyMRI_V1(id): Observable<Patient> {
    return this.http.get<Patient>(this.radiologyMRI_V1 + '/' + id);
  }
  getPatientEMcontact(id): Observable<Patient> {
    return this.http.get<Patient>(this.emContact + '/' + id);
  }
  getPatientInsurance(id): Observable<Patient> {
    return this.http.get<Patient>(this.ptInsurance + '/' + id);
  }
  getPatientIdProof(id): Observable<Patient> {
    return this.http.get<Patient>(this.ptIdProof + '/' + id);
  }
  getPatientsurgery(id): Observable<Patient> {
    return this.http.get<Patient>(this.surgery + '/' + id);
  }
  getPatientBooking(id): Observable<Patient> {
    return this.http.get<Patient>(this.ptBooking + '/' + id);
  }
  getPatientMedication(id): Observable<Patient> {
    return this.http.get<Patient>(this.ptMedication + '/' + id);
  }
  getNurseReport(id): Observable<Patient> {
    return this.http.get<Patient>(this.nurseReport + '/' + id);
  }
  getPatientPrescription(id): Observable<Patient> {
    return this.http.get<Patient>(this.prescription + '/' + id);
  }
  getPTPrescription(id): Observable<Patient> {
    return this.http.get<Patient>(this.patientPrescription + '/' + id);
  }
  getPatientvisits(id): Observable<Patient> {
    return this.http.get<Patient>(this.visits + '/' + id);
  }
  getdoctorNotes(id): Observable<Patient> {
    return this.http.get<Patient>(this.doctorNotes + '/' + id);
  }
  getBradenScale(id): Observable<Patient> {
    return this.http.get<Patient>(this.bradenScale + '/' + id);
  }
  getHesterDavies(id): Observable<Patient> {
    return this.http.get<Patient>(this.hesterDavies + '/' + id);
  }
  getpurposefulRounds(id): Observable<Patient> {
    return this.http.get<Patient>(this.purposefulRounds + '/' + id);
  }
  getVaccines(id): Observable<Patient> {
    return this.http.get<Patient>(this.patientVaccines + '/' + id);
  }
  getotherTasks(id): Observable<Patient> {
    return this.http.get<Patient>(this.otherTask + '/' + id);
  }
  getIntakeAssessment(id): Observable<Patient> {
    return this.http.get<Patient>(this.intakeAssessment + '/' + id);
  }
  getCommunicationAssessment(id): Observable<Patient> {
    return this.http.get<Patient>(this.communicationAssessment + '/' + id);
  }
  getdischargeSummary(id): Observable<Patient> {
    return this.http.get<Patient>(this.dischargeSummary + '/' + id);
  }
  getmedicineGiven(id): Observable<Patient> {
    return this.http.get<Patient>(this.medicineGiven + '/' + id);
  }
  getDoctorReport(id): Observable<Patient> {
    return this.http.get<Patient>(this.doctorReport + '/' + id);
  }
  getVisitSummary(id): Observable<Patient> {
    return this.http.get<Patient>(this.visitSummary + '/' + id);
  }
  getNurseProgressReport(id): Observable<Patient> {
    return this.http.get<Patient>(this.nurseProgressReport + '/' + id);
  }
  getNurseNote(id): Observable<Patient> {
    return this.http.get<Patient>(this.nurseNote + '/' + id);
  }
  getGeneralSaftey(id): Observable<Patient> {
    return this.http.get<Patient>(this.generalSaftey + '/' + id);
  }
  getFamilyHistory(id): Observable<Patient> {
    return this.http.get<Patient>(this.familyHistory + '/' + id);
  }
  getSocialHistory(id): Observable<Patient> {
    return this.http.get<Patient>(this.socialHistory + '/' + id);
  }
  getMedicalHistory(id): Observable<Patient> {
    return this.http.get<Patient>(this.medicalHistory + '/' + id);
  }
  getHippaCompliance(id): Observable<Patient> {
    return this.http.get<Patient>(this.hippaCompliance + '/' + id);
  }
  getPTInitialEvaluations(id): Observable<Patient> {
    return this.http.get<Patient>(this.ptInitialEvaluations + '/' + id);
  }
  getPhysicalTherapyVisits(id): Observable<Patient> {
    return this.http.get<Patient>(this.physicalTherapyVisits + '/' + id);
  }
  getPhysicalTherapyBookings(id): Observable<Patient> {
    return this.http.get<Patient>(this.physicalTherapyBookings + '/' + id);
  }
  getDoctorEvaluation(id): Observable<Patient> {
    return this.http.get<Patient>(this.doctorEvaluation + '/' + id);
  }
  getReviewOfSystem(id): Observable<Patient> {
    return this.http.get<Patient>(this.reviewOfSystem + '/' + id);
  }
  getNurseDailyAssessment(id): Observable<Patient> {
    return this.http.get<Patient>(this.nurseDailyAssessment + '/' + id);
  }
  getNurseHandOffNote(id): Observable<Patient> {
    return this.http.get<Patient>(this.nurseHandOffNote + '/' + id);
  }
  getPatientPayment(id): Observable<Patient> {
    return this.http.get<Patient>(this.patientPayment + '/' + id);
  }
  getBloodTransfusionConsent(id): Observable<Patient> {
    return this.http.get<Patient>(this.bloodTransfusionConsent + '/' + id);
  }
  getTicketToRide(id): Observable<Patient> {
    return this.http.get<Patient>(this.ticketToRide + '/' + id);
  }
  getDisclosureAndConsent(id): Observable<Patient> {
    return this.http.get<Patient>(this.disclosureAndConsent + '/' + id);
  }
  getInPatientScreeningMRI(id): Observable<Patient> {
    return this.http.get<Patient>(this.inPatientScreeningMRI + '/' + id);
  }
  GetPastMedication(id): Observable<Patient> {
    return this.http.get<Patient>(this.pastMedication + '/' + id);
  }
  GetRefferal(id): Observable<Patient> {
    return this.http.get<Patient>(this.refferal + '/' + id);
  }
  GetUploadedRadiologyReport(id): Observable<Patient> {
    return this.http.get<Patient>(this.uploadedRadiologyReport + '/' + id);
  }
  GetUploadedLabReport(id) {
    return this.http.get<Patient>(this.uploadedLabReport + '/' + id);
  }
  GetPhotographicConsent(id): Observable<Patient> {
    return this.http.get<Patient>(this.getPhotographicConsent + '/' + id);
  }
  GetOfficePolicy(id): Observable<Patient> {
    return this.http.get<Patient>(this.getOfficePolicy + '/' + id);
  }
  GetGeneralConsentForTreatment(id): Observable<Patient> {
    return this.http.get<Patient>(this.generalConsentForTreatment + '/' + id);
  }
  GetNarcoticMedicalPolicyAgreement(id): Observable<Patient> {
    return this.http.get<Patient>(
      this.narcoticMedicalPolicyAgreement + '/' + id
    );
  }
  getPainCharts(id): Observable<Patient> {
    return this.http.get<Patient>(this.painCharts + '/' + id);
  }
  getGeneralUploads(id): Observable<Patient> {
    return this.http.get<Patient>(this.generalUploads + '/' + id);
  }
  getPatientChats(id): Observable<Patient> {
    return this.http.get<Patient>(this.patientChats + '/' + id);
  }
  updatePatient(id, body): Observable<Patient> {
    return this.http
      .put<Patient>(this.url + '/' + id, body)
      .pipe(catchError(this.handleError));
  }
  updatePatientCompleteVaccine(id, body): Observable<Patient> {
    return this.http
      .put<Patient>(this.completeVaccine + '/' + id, body)
      .pipe(catchError(this.handleError));
  }

  updateNursePatient(id, body): Observable<Patient> {
    return this.http
      .put<Patient>(this.nursePatient + '/' + id, body)
      .pipe(catchError(this.handleError));
  }

  deletePatient(id): Observable<Patient> {
    return this.http
      .delete<Patient>(this.url + '/' + id)
      .pipe(catchError(this.handleError));
  }

  completePatientInformation(id): Observable<Patient> {
    return this.http.get<Patient>(
      this.completePatientInformationUrl + '/' + id
    );
  }

  validateDicomFile(fileUrl) {
    let file;
    this.http.get(fileUrl).subscribe({
      next: (res: any) => {
        let blob = new Blob(res);
        file = blob;
        return file;
      },
    });
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      if (error.status == 400) {
        console.log(error.error);
        return throwError(error.error);
      } else if (error.status == 401) {
        return throwError('invalid username or password');
      } else if (error.status == 0) {
        return throwError('network failure please try again later ');
      }
      return throwError(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }
}
