import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { DoctorReport } from 'src/app/Models/doctorreport.model';
import { Patient } from 'src/app/Models/patient.model';
import { VisitSummary } from 'src/app/Models/visitsummary.model';
import { PatientService } from 'src/app/services/patient.service';
import { TokenService } from 'src/app/services/token.service';
import { VisitSummaryService } from 'src/app/services/visit-summary.service';
import { UpdateDoctorReportComponent } from '../update-doctor-report/update-doctor-report.component';
import { ViewDoctorReportDetailsComponent } from '../view-doctor-report-details/view-doctor-report-details.component';
import html2pdf from 'html2pdf.js';
import { UpdateVisitSummaryComponent } from '../update-visit-summary/update-visit-summary.component';

@Component({
  selector: 'app-view-visit-summary',
  templateUrl: './view-visit-summary.component.html',
  styleUrls: ['./view-visit-summary.component.css'],
})
export class ViewVisitSummaryComponent implements OnInit {
  email = new FormControl('');
  @ViewChild('PatientVisit', { static: false }) PatientVisit: ElementRef;

  displayedColumns: string[] = [
    'actions',
    'Date',
    'ReportName',
    'ChiefComplaint',
    'DoctorName',
    'Signature',
    'View',
    'Update',
    'Delete',
  ];

  id = 0;
  doctorReport: VisitSummary[] = [];
  dataSource: MatTableDataSource<any>;
  labDocument: string = '';
  patient: Patient;
  reportDetails: string;
  helper = new JwtHelperService();
  myToken = this.tokenService.getToken();

  CurrentDoctorId: number;
  afterVisistSummary: VisitSummary[] = [];

  constructor(
    private patientService: PatientService,
    private visitSummaryService: VisitSummaryService,
    private route: ActivatedRoute,
    private router: Router,
    private tokenService: TokenService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.CurrentDoctorId = parseInt(decodedToken.nameid);
    this.GetReports(this.id);
    this.getPatient(this.id);
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  // ngAfterViewInit() {
  //   this.dataSource.paginator = this.paginator;
  //   this.dataSource.sort = this.sort;
  // }

  getPatient(id) {
    this.patientService.getPatientById(id).subscribe((res) => {
      this.patient = res;
    });
  }
  GetReports(id) {
    this.patientService.getVisitSummary(id).subscribe((res) => {
      res.VisitSummarys = res.VisitSummarys.map((report) => {
        return {
          ...report,
          // Assuming 'Content' or similar field might have the <br> tags
          Content: report.Summary?.replace(/<br>/g, '</br>'),
        };
      });

      // Assign the processed data to the table data source
      this.dataSource = new MatTableDataSource(
        res.VisitSummarys.sort(function (x, y) {
          return y.VisitSummaryId - x.VisitSummaryId;
        })
      );
      this.dataSource.paginator = this.paginator;
    });
  }
  delete(id) {
    this.visitSummaryService.deleteVisitSummary(id).subscribe((res) => {
      alert('are you sure you wanted to delete record?');
      this.GetReports(this.id);
    });
  }
  // print() {
  //   window.print();
  // }
  print() {
    // Before printing, temporarily hide the body of the modal
    const modalBody = document.querySelector('.app-modal-body');

    // Open the print window and write the content
    let printWindow = window.open('', '', 'height=900,width=1200');
    printWindow.document.write('<html><head><title>Print</title>');

    // Include custom print styles
    printWindow.document.write(`
        <style>
            @media print {
                body {
                    margin: 10a;
                    padding: 0;
                }
                .app-modal-body {
                    display: none !important;   /* Hide modal body */
                }
                .app-modal-header, .app-modal-footer {
                    display: block !important;  /* Ensure header and footer are shown */
                }
                .dt-button, .btn-primary {
                    display: none !important;   /* Hide print buttons */
                }
                @page {
                    margin: 10;
                }
            }
        </style>
    `);

    // Write modal content (this can include only the visible parts)
    printWindow.document.write('<body>');
    printWindow.document.write(
      document.querySelector('.panel-demo1').innerHTML
    );
    printWindow.document.write('</body>');

    printWindow.document.close();

    // Print the page
    printWindow.print();
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  public viewReport(report: VisitSummary): void {
    // console.log('report: ', report);
    this.reportDetails = report.Summary;
  }

  copyRowData(row: any): void {
    const htmlContent = `
      <p><strong>Date:</strong> ${new Date(
        row.createdDate
      ).toLocaleString()}</p>
      <p><strong>Report Name:</strong> ${row.ReportName}</p>
      <p><strong>ChiefComplaint:</strong> ${row.ChiefComplaint}</p>
      <p><strong>Physician:</strong> ${row.DoctorName}</p>
      <p><strong>Summary:</strong> ${row.Summary}</p>
    `;

    navigator.clipboard
      .writeText(htmlContent)
      .then(() => {
        console.log('Row data copied to clipboard!');
      })
      .catch((err) => {
        console.error('Failed to copy row data: ', err);
      });
  }

  updateReport(reportId: number, event): void {
    const dialogRef = this.dialog.open(UpdateVisitSummaryComponent, {
      width: '50%',
      height: '80%',
      disableClose: false, // Prevent closing without confirmation
      hasBackdrop: false, // Disable the backdrop
      panelClass: 'custom-dialog-container', // Optional custom styling
      data: { reportId }, // Pass data to the dialog
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('Dialog closed:', result);
      // Handle any cleanup or actions after the dialog is closed
    });
  }

  detailView(id: number, event) {
    console.log(id);
    const dialogRef = this.dialog.open(ViewDoctorReportDetailsComponent, {
      width: '50%',
      height: '80%',
      disableClose: false, // Prevent closing without confirmation
      hasBackdrop: false, // Disable the backdrop
      panelClass: 'custom-dialog-container', // Optional custom styling
      data: { id }, // Pass data to the dialog
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('Dialog closed:', result);
      // Handle any cleanup or actions after the dialog is closed
    });
  }

  downloadAsPDF() {
    const content = document.getElementById('printable');
    const button = document.querySelector('button'); // Select the button
    if (content) {
      button?.classList.add('downloadButton');
      // Configure html2pdf options
      const options = {
        margin: 1, // Margins in cm
        filename: 'medical-record.pdf', // Output file name
        image: { type: 'jpeg', quality: 0.98 }, // Image quality
        html2canvas: { scale: 2 }, // Scale for higher resolution
        jsPDF: { unit: 'cm', format: 'a4', orientation: 'portrait' }, // A4 portrait format
      };

      // Generate PDF
      html2pdf().set(options).from(content).save();
    }
  }
  send() {
    let body = {
      Email: this.email.value,
      Subject: 'Medical Records',
      Template: `${this.PatientVisit.nativeElement.innerHTML}`,
    };
    this.patientService.sendEmail(body).subscribe((res) => {
      alert('Medical Records sent');
    });
  }
}
