<div class="container">
    <div class="row">
        <div class="col-lg regFrm mat-elevation-z8">
            <h1>Register ICD 10 Code</h1>
            <form (keydown.enter)="$event.preventDefault()" [formGroup]="cptFrm" (ngSubmit)="formSubmit()"
                class="example-form">
                <!-- ICD 10 Code Field -->
                <mat-form-field class="example-full-width">
                    <mat-label>ICD 10 Code (Enter Code and Description together)</mat-label>
                    <input type="text" formControlName="Code" matInput placeholder="e.g., J45 - Asthma" />
                    <mat-error *ngIf="cptFrm.get('Code')?.hasError('required')">
                        Please enter ICD 10 Code
                    </mat-error>
                    <mat-error *ngIf="cptFrm.get('Code')?.hasError('pattern')">
                        Code must follow the format (e.g., J45 - Description)
                    </mat-error>
                </mat-form-field>

                <!-- Description Field -->
                <mat-form-field class="example-full-width">
                    <mat-label>Description</mat-label>
                    <input type="text" formControlName="Description" matInput
                        placeholder="e.g., Chronic Obstructive Asthma" />
                    <mat-error *ngIf="cptFrm.get('Description')?.hasError('required')">
                        Please enter a Description
                    </mat-error>
                </mat-form-field>

                <!-- Save Button -->
                <button type="submit" [disabled]="cptFrm.invalid" class="float-right lgBtCl" mat-button>
                    <mat-icon>save</mat-icon>
                    Save
                </button>

                <!-- Success Message -->
                <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
                    Successfully submitted
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>