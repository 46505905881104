import { Component, OnInit } from '@angular/core';
import * as cornerstone from 'cornerstone-core';
import * as cornerstoneTools from 'cornerstone-tools';
import * as cornerstoneWADOImageLoader from 'cornerstone-wado-image-loader';

@Component({
  selector: 'app-dicom-viewer',
  templateUrl: './dicom-viewer.component.html',
  styleUrls: ['./dicom-viewer.component.css'],
})
export class DicomViewerComponent implements OnInit {
  ngOnInit() {
    cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
    const element = document.getElementById('dicomImage');
    cornerstone.enable(element);

    const imageId = 'wadouri:http://localhost:4200/assets/MRI.DCM'; // Replace with your DICOM file URL
    cornerstone.loadImage(imageId).then((image) => {
      cornerstone.displayImage(element, image);
    });
  }
}
