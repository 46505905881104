import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ClaimService } from 'src/app/services/claim.service';
import * as xml2js from 'xml-js';
import { ClaimComponent } from '../claim/claim.component';
import { MatDialog } from '@angular/material/dialog';
import { UpdateClaimComponent } from '../update-claim/update-claim.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { HospitalService } from 'src/app/services/hospital.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { TokenService } from 'src/app/services/token.service';
import { BillerService } from 'src/app/services/biller.service';
import { DoctorService } from 'src/app/services/doctor.service';
@Component({
  selector: 'app-claim-status',
  templateUrl: './claim-status.component.html',
  styleUrls: ['./claim-status.component.css'],
})
export class ClaimStatusComponent implements OnInit {
  displayedColumns: string[] = [
    'response_time',
    'BillTaxId',
    'BillNpi',
    'pcn',
    'InsNumber',
    'claimmd_id',
    'FileName',
    'TotalCharge',
    'Status',
    // 'Signature',
    // 'View',
    // 'Update',
    // 'Delete',
  ];
  accountKey = '';
  uploadList: any[] = [];
  errorMessage: string | null = null;
  claimStatus: any;
  xmlList: any[] = [];
  inboundId: string = '';
  status: any;

  helper = new JwtHelperService();

  myToken = this.tokenService.getToken();

  showItem = '';
  hospitalId: number;

  id = 0;

  dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private claimService: ClaimService,
    private dialog: MatDialog,
    private hospitalService: HospitalService,
    private tokenService: TokenService,
    private billerService: BillerService,
    private doctorService: DoctorService
  ) {}
  ngOnInit(): void {
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.id = parseInt(decodedToken.nameid);

    // Check the role in the decoded token
    if (decodedToken.role === 'Biller') {
      this.billerService.getbillerHospital(this.id).subscribe((res) => {
        this.hospitalId = res.Hospital.HospitalId;
        this.getCliamKey(this.hospitalId); // Fetch claim key for the biller's hospital
      });
    } else if (decodedToken.role === 'Hospital') {
      this.getCliamKey(this.id); // Fetch claim key directly using the hospital ID
    } else if (decodedToken.role === 'Doctor') {
      this.doctorService.getDoctorHospital(this.id).subscribe((res) => {
        this.hospitalId = res.Hospital.HospitalId;
        this.getCliamKey(this.hospitalId); // Fetch claim key for the biller's hospital
      });
    } else {
      console.error('Invalid role detected in token:', decodedToken.role);
    }
  }

  getCliamKey(id: number) {
    this.hospitalService.getclaimAccountKey(id).subscribe((res) => {
      // Assuming res.ClaimAccountKeys is an array, take the first item's AccountKey
      if (res.ClaimAccountKeys && res.ClaimAccountKeys.length > 0) {
        this.accountKey = res.ClaimAccountKeys[0].AccountKey;
        this.getUploadList(this.accountKey);
      } else {
        console.error(
          'No ClaimAccountKeys found for the provided hospital ID.'
        );
      }
    });
  }
  getUploadList(id): void {
    this.errorMessage = null; // Reset error message
    this.uploadList = []; // Reset upload list

    this.claimService.getUploadList(id).subscribe({
      next: (response: any) => {
        try {
          const parsedResponse = JSON.parse(response); // Parse the JSON string into an object
          this.xmlList = parsedResponse.result || [];
          // this.xmlList = data;
          this.dataSource = new MatTableDataSource(
            this.xmlList.sort((x, y) => {
              const dateX = new Date(x.response_time.replace(/am|pm/, '')); // Parse ResponseTime to Date
              const dateY = new Date(y.response_time.replace(/am|pm/, ''));
              return dateY.getTime() - dateX.getTime(); // Sort in descending order
            })
          );

          this.dataSource.paginator = this.paginator;

          // Pretty print the JSON
        } catch (error) {
          console.error('Error parsing JSON response:', error);
        }
      },
      error: (error: any) => {
        console.error('Error fetching upload list:', error);
      },
    });
  }

  resubmit(claimId: number): void {
    const dialogRef = this.dialog.open(UpdateClaimComponent, {
      width: '60%',
      height: '80%',
      disableClose: true, // Prevent closing without confirmation
      hasBackdrop: false, // Disable the backdrop
      data: claimId, // Pass data to the dialog
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('Dialog closed:', result);
      // Handle any cleanup or actions after the dialog is closed
    });
  }

  getStatusClass(status: string): string {
    switch (status) {
      case 'A':
        return 'status-accepted';
      case 'P':
        return 'status-pending';
      case 'R':
        return 'status-rejected';
      case 'C':
        return 'status-corrected';
      case 'D':
        return 'status-denied';
      case 'E':
        return 'status-error';
      case 'S':
        return 'status-submitted';
      case 'T':
        return 'status-transmitted';
      case 'F':
        return 'status-finalized';
      case 'I':
        return 'status-incomplete';
      case 'O':
        return 'status-on-hold';
      case 'U':
        return 'status-unknown';
      default:
        return 'status-undefined';
    }
  }

  getStatusText(status: string): string {
    switch (status) {
      case 'A':
        return 'Accepted';
      case 'P':
        return 'Pending';
      case 'R':
        return 'Rejected';
      case 'C':
        return 'Corrected';
      case 'D':
        return 'Denied';
      case 'E':
        return 'Error';
      case 'S':
        return 'Submitted';
      case 'T':
        return 'Transmitted';
      case 'F':
        return 'Finalized';
      case 'I':
        return 'Incomplete';
      case 'O':
        return 'On Hold';
      case 'U':
        return 'Unknown';
      default:
        return 'Undefined';
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
