<form [formGroup]="claimForm" (keydown.enter)="$event.preventDefault()" (ngSubmit)="onSubmit()">
    <mat-card>
        <mat-card-title>Claim Submission Form</mat-card-title>
        <mat-divider></mat-divider>
        <!-- <mat-form-field appearance="fill">
            <mat-label>Attachment Type</mat-label>
            <mat-select formControlName="attach_type_code">
                <mat-option *ngFor="let type of attachmentTypes" [value]="type.code">
                    {{ type.description }}
                </mat-option>
            </mat-select>
        </mat-form-field> -->
        <div>
            <div class="row">
                <div class="col lg-6">
                    <!-- File ID -->
                    <mat-form-field appearance="fill" class="full-width">
                        <mat-label>File ID</mat-label>
                        <input matInput formControlName="fileid" required />
                        <mat-error *ngIf="claimForm.get('fileid')?.hasError('required')">
                            File ID is required
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col lg-6">
                    <mat-form-field appearance="fill" class="full-width">
                        <mat-label>Unique Attachment ID #</mat-label>
                        <input matInput formControlName="attach_acn" placeholder="Enter Attachment ID" />
                    </mat-form-field>
                </div>
            </div>
        </div>




        <!-- Claims Array -->
        <div formArrayName="claim">
            <div *ngFor="let claim of claims.controls; let i = index" [formGroupName]="i" class="claim-section">

                <h2>Claim {{ i + 1 }}</h2>
                <mat-divider></mat-divider>
                <div>
                    <span [ngClass]="{
                        'text-green': Attachment === 'Yes',
                        'text-red': Attachment === 'No'
                      }">
                        Attachment Allowed: {{ Attachment }}
                    </span>
                </div>
                <div class="row">
                    <div class="col lg-4">


                        <mat-form-field appearance="fill" class="full-width" [ngClass]="{
                          'highlight-empty': claim.get('payer_name')?.invalid && !claim.get('payer_name')?.touched
                        }">
                            <mat-label>Payer Name</mat-label>
                            <input type="text" matInput formControlName="payer_name" [matAutocomplete]="auto" />
                            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onPayerChange($event)">
                                <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                                    {{ option }}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>


                    </div>

                    <div class="col lg-4">
                        <!-- Payer ID Field -->
                        <mat-form-field appearance="fill" class="full-width">
                            <mat-label>Payer ID</mat-label>
                            <input matInput [value]="selectedPayer?.payerid" formControlName="payerid" readonly />
                        </mat-form-field>
                    </div>
                    <div class="col lg-4">
                        <!-- claim_form Field -->
                        <mat-form-field appearance="fill">
                            <mat-label>Claim Form</mat-label>
                            <input matInput formControlName="claim_form" />

                        </mat-form-field>



                    </div>
                </div>

                <h2>Patient Details</h2>

                <div class="row">
                    <div class="col lg-2">

                        <mat-form-field appearance="fill">
                            <mat-label>Patient First Name</mat-label>
                            <input matInput formControlName="pat_name_f" required />
                        </mat-form-field>
                    </div>
                    <div class="col lg-2">
                        <mat-form-field appearance="fill">
                            <mat-label>Patient Last Name</mat-label>
                            <input matInput formControlName="pat_name_l" required />
                        </mat-form-field>
                    </div>
                    <div class="col lg-2">
                        <mat-form-field appearance="fill">
                            <mat-label>Patient Gender </mat-label>

                            <mat-select formControlName="pat_sex" required>
                                <mat-option value="M">Male</mat-option>
                                <mat-option value="F">Female</mat-option>
                                <mat-option value="U">Unknown</mat-option>
                            </mat-select>

                        </mat-form-field>

                    </div>
                    <div class="col lg-2">
                        <mat-form-field appearance="fill">
                            <mat-label>Patient DOB</mat-label>
                            <input matInput formControlName="pat_dob" type="date" required />
                        </mat-form-field>
                    </div>

                    <div class="col lg-2">

                        <mat-form-field appearance="fill">
                            <mat-label>Patient Address</mat-label>
                            <input matInput formControlName="pat_addr_1" required />
                        </mat-form-field>
                    </div>
                    <div class="col lg-2">

                        <mat-form-field appearance="fill">
                            <mat-label>Patient State</mat-label>
                            <input matInput formControlName="pat_state" required />
                        </mat-form-field>
                    </div>
                    <div class="col lg-2">

                        <mat-form-field appearance="fill">
                            <mat-label>Patient City</mat-label>
                            <input matInput formControlName="pat_city" required />
                        </mat-form-field>
                    </div>
                    <div class="col lg-2">

                        <mat-form-field appearance="fill">
                            <mat-label>Patient Zip</mat-label>
                            <input matInput formControlName="pat_zip" required />
                        </mat-form-field>
                    </div>
                    <div class="col lg-2">
                        <mat-form-field appearance="fill">
                            <mat-label>Patient Relationship</mat-label>
                            <mat-select formControlName="pat_rel" required>
                                <mat-option value="01">Spouse</mat-option>
                                <mat-option value="18">Self</mat-option>
                                <mat-option value="19">Child</mat-option>
                                <mat-option value="20">Employee</mat-option>
                                <mat-option value="21">Unknown</mat-option>
                                <mat-option value="39">Organ Donor</mat-option>
                                <mat-option value="40">Cadaver Donor</mat-option>
                                <mat-option value="53">Life Partner</mat-option>
                                <mat-option value="G8">Other Relationship</mat-option>
                            </mat-select>
                            <mat-error *ngIf="claim.get('pat_rel')?.hasError('required')">
                                Patient Relationship is required
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <h2>Insurance Details </h2>

                <div class="row">
                    <div class="col lg-3">

                        <!-- Insurance Details -->
                        <mat-form-field appearance="fill">
                            <mat-label>Insured First Name</mat-label>
                            <input matInput formControlName="ins_name_f" required />
                        </mat-form-field>
                    </div>
                    <div class="col lg-3">
                        <mat-form-field appearance="fill">
                            <mat-label>Insured Last Name</mat-label>
                            <input matInput formControlName="ins_name_l" required />
                        </mat-form-field>
                    </div>
                    <div class="col lg-3">
                        <mat-form-field appearance="fill">
                            <mat-label>Insured Gender</mat-label>
                            <mat-select formControlName="ins_sex" required>
                                <mat-option value="M">Male</mat-option>
                                <mat-option value="F">Female</mat-option>
                                <mat-option value="U">Unknown</mat-option>

                            </mat-select>
                            <mat-error *ngIf="claim.get('ins_sex')?.hasError('required')">
                                Insured Gender is required
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col lg-3">

                        <mat-form-field appearance="fill">
                            <mat-label>Insured DOB</mat-label>
                            <input matInput formControlName="ins_dob" type="date" required />
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col lg-3">
                        <mat-form-field appearance="fill">
                            <mat-label>Insured Number</mat-label>
                            <input matInput formControlName="ins_number" required />
                        </mat-form-field>
                    </div>
                    <div class="col lg-3">


                        <mat-form-field appearance="fill">
                            <mat-label>Insured Group</mat-label>
                            <input matInput formControlName="ins_group" required />
                        </mat-form-field>
                    </div>
                    <div class="col lg-3">

                        <mat-form-field appearance="fill">
                            <mat-label>Insured Address</mat-label>
                            <input matInput formControlName="ins_addr_1" required />
                        </mat-form-field>
                    </div>
                    <div class="col lg-3">
                        <mat-form-field appearance="fill">
                            <mat-label>Insured State</mat-label>
                            <input matInput formControlName="ins_state" required />
                        </mat-form-field>
                    </div>
                    <div class="col lg-3">
                        <mat-form-field appearance="fill">
                            <mat-label>Insured City</mat-label>
                            <input matInput formControlName="ins_city" required />
                        </mat-form-field>
                    </div>
                    <div class="col lg-3">
                        <mat-form-field appearance="fill">
                            <mat-label>Insured Zip</mat-label>
                            <input matInput formControlName="ins_zip" required />
                        </mat-form-field>
                    </div>

                    <div class="col lg-3">


                        <!-- Dropdown: Auto Accident -->
                        <mat-form-field appearance="fill" class="full-width" [ngClass]="{
                            'highlight-empty': claim.get('auto_accident')?.invalid && !claim.get('auto_accident')?.touched
                          }">
                            <mat-label>Auto Accident</mat-label>
                            <mat-select formControlName="auto_accident" required>
                                <mat-option value="Y">Yes</mat-option>
                                <mat-option value="N">No</mat-option>
                            </mat-select>
                            <mat-error *ngIf="claim.get('auto_accident')?.hasError('required')">
                                Auto Accident selection is required
                            </mat-error>
                        </mat-form-field>

                    </div>
                    <div class="col lg-3">

                        <!-- Dropdown: Employment Related -->
                        <mat-form-field appearance="fill" class="full-width" [ngClass]="{
                            'highlight-empty': claim.get('employment_related')?.invalid && !claim.get('employment_related')?.touched
                          }">
                            <mat-label>Employment Related</mat-label>
                            <mat-select formControlName="employment_related" required>
                                <mat-option value="Y">Yes</mat-option>
                                <mat-option value="N">No</mat-option>
                            </mat-select>
                            <mat-error *ngIf="claim.get('employment_related')?.hasError('required')">
                                Employment Related selection is required
                            </mat-error>
                        </mat-form-field>
                    </div>

                </div>
                <h2>Refering Provider Details</h2>

                <div class="row">
                    <div class="col lg-3">

                        <!-- Reference Details -->
                        <mat-form-field appearance="fill">
                            <mat-label>Referring First Name</mat-label>
                            <input matInput formControlName="ref_name_f" required />
                        </mat-form-field>
                    </div>
                    <div class="col lg-3">
                        <mat-form-field appearance="fill">
                            <mat-label>Referring Last Name</mat-label>
                            <input matInput formControlName="ref_name_l" required />
                        </mat-form-field>
                    </div>
                    <div class="col lg-3">

                        <mat-form-field appearance="fill">
                            <mat-label>Referring Middle Name</mat-label>
                            <input matInput formControlName="ref_name_m" />
                        </mat-form-field>
                    </div>
                    <div class="col lg-3">


                        <mat-form-field appearance="fill">
                            <mat-label>Referring NPI</mat-label>
                            <input matInput formControlName="ref_npi" required />
                        </mat-form-field>
                    </div>
                </div>
                <!-- Diagnosis Fields -->
                <h4>Diagnosis Information</h4>
                <!-- <div class="row">
                    <div class="col lg-3" *ngFor="let field of diagnosisFields">
                        <mat-form-field appearance="fill">
                            <mat-label>{{ field.label }}</mat-label>
                            <input matInput [formControlName]="field.name" />
                        </mat-form-field>
                    </div>
                </div> -->
                <div class="row">
                    <div class="col lg-3" *ngFor="let field of diagnosisFields" [ngClass]="{
                        'row-highlight': claim.get(field.name)?.untouched || claim.get(field.name)?.invalid
                      }">
                        <mat-form-field appearance="fill" class="full-width">
                            <mat-label>{{ field.label }}</mat-label>
                            <mat-select [formControlName]="field.name">
                                <!-- Search Input -->
                                <mat-option>
                                    <lib-mat-select-search [list]="favoriteDiagnosisCodes" [searchProperties]="['Code']"
                                        (filtered)="filteredDiagnosisCodes[field.name] = $event">
                                    </lib-mat-select-search>
                                </mat-option>

                                <!-- Options with Virtual Scrolling -->
                                <cdk-virtual-scroll-viewport [ngStyle]="{ height: '200px' }" itemSize="20">
                                    <mat-option
                                        *cdkVirtualFor="let entry of filteredDiagnosisCodes[field.name] || favoriteDiagnosisCodes"
                                        [value]="entry.Code">
                                        {{ entry.Code }} - {{ entry.Description }}
                                    </mat-option>
                                </cdk-virtual-scroll-viewport>

                                <!-- No Data State -->
                                <mat-option *ngIf="filteredDiagnosisCodes[field.name]?.length === 0" disabled>
                                    No matching data
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>






                <!-- Charges Array -->
                <div formArrayName="charge">
                    <h4>Charges</h4>
                    <div *ngFor="let charge of charges(i).controls; let j = index" [formGroupName]="j"
                        class="charge-section">
                        <mat-card>
                            <mat-card-title>Charge {{ j + 1 }}</mat-card-title>
                            <mat-divider></mat-divider>

                            <mat-form-field appearance="fill" class="full-width" [ngClass]="{
                                'highlight-empty': charge.get('from_date')?.invalid && !charge.get('from_date')?.touched
                              }">
                                <mat-label>From Date</mat-label>
                                <input matInput formControlName="from_date" type="date" required />
                                <mat-error *ngIf="charge.hasError('required')">
                                    From Date is required
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="fill" class="full-width" [ngClass]="{
                                'highlight-empty': charge.get('thru_date')?.invalid && !charge.get('thru_date')?.touched
                              }">
                                <mat-label>Thru Date</mat-label>
                                <input matInput formControlName="thru_date" type="date" required />
                                <mat-error *ngIf="charge.hasError('required')">
                                    Thru Date is required
                                </mat-error>
                            </mat-form-field>
                            <!-- 
                            <mat-form-field appearance="fill">
                                <mat-label> Place of service</mat-label>
                                <input matInput formControlName="place_of_service" type="number" required />
                            </mat-form-field> -->
                            <mat-form-field appearance="fill">
                                <mat-label>Select Place of Service</mat-label>
                                <mat-select formControlName="place_of_service">
                                    <mat-option *ngFor="let pos of placeOfServiceCodes" [value]="pos.code">
                                        {{ pos.code }} - {{ pos.description }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="claimForm.get('place_of_service')?.hasError('required')">
                                    Place of Service is required.
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="fill">
                                <mat-label>Remote CHG ID</mat-label>
                                <input matInput formControlName="remote_chgid" required />
                                <mat-error *ngIf="charge.hasError('required')">
                                    Remote CHG ID is required
                                </mat-error>
                            </mat-form-field>
                            <!-- 
                            <mat-form-field appearance="fill">
                                <mat-label>Procedure Code</mat-label>
                                <input matInput formControlName="proc_code" required />
                            </mat-form-field> -->
                            <mat-form-field appearance="fill" class="full-width" [ngClass]="{
                                'highlight-empty': charge.get('proc_code')?.invalid && !charge.get('proc_code')?.touched
                              }">
                                <mat-label>Procedure Code</mat-label>
                                <mat-select formControlName="proc_code"
                                    (selectionChange)="onSelectChange($event.value, i, j)">
                                    <mat-option>
                                        <lib-mat-select-search [list]="filteredMedicineLists[i]"
                                            [searchProperties]="['Code']" (filtered)="onFilter($event, i)">
                                        </lib-mat-select-search>
                                    </mat-option>
                                    <cdk-virtual-scroll-viewport [ngStyle]="{ height: '200px' }" itemSize="25">
                                        <mat-option *cdkVirtualFor="let entry of filteredMedicineLists[i]"
                                            [value]="entry.Code">
                                            {{ entry.Code }}
                                        </mat-option>
                                    </cdk-virtual-scroll-viewport>
                                </mat-select>
                                <mat-error>
                                    Please Enter ProcedureCode
                                </mat-error>
                            </mat-form-field>



                            <mat-form-field appearance="fill">
                                <mat-label>Charge Record Type</mat-label>
                                <input matInput formControlName="charge_record_type" required />
                                <mat-error *ngIf="charge.hasError('required')">
                                    Charge Record Type is required
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="fill">
                                <mat-label>Diagnosis Reference</mat-label>
                                <input matInput formControlName="diag_ref" required />
                                <mat-error *ngIf="charge.hasError('required')">
                                    Diagnosis Reference is required
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="fill" class="full-width" [ngClass]="{
                                'highlight-empty': charge.get('charge')?.invalid && !charge.get('charge')?.touched
                              }">
                                <mat-label>Charge Amount</mat-label>
                                <input matInput formControlName="charge" type="number" required />
                                <mat-error *ngIf="charge.hasError('required')">
                                    Charge Amount is required
                                </mat-error>
                            </mat-form-field>



                            <mat-form-field appearance="fill" class="full-width" [ngClass]="{
                                'highlight-empty': charge.get('units')?.invalid && !charge.get('units')?.touched
                              }">
                                <mat-label>Units</mat-label>
                                <input matInput formControlName="units" type="number" required />
                                <mat-error *ngIf="charge.hasError('required')">
                                    Units are required
                                </mat-error>
                            </mat-form-field>

                            <button mat-button color="warn" (click)="charges(i).removeAt(j)" type="button">
                                Remove Charge
                            </button>
                        </mat-card>
                    </div>

                    <button mat-raised-button color="primary" type="button" (click)="addCharge(i)">
                        Add Charge
                    </button>
                </div>
                <div class="row">
                    <div class="col lg-3">

                        <mat-form-field appearance="fill">
                            <mat-label>Billing Tax ID</mat-label>
                            <input matInput formControlName="bill_taxid" required />
                            <mat-error *ngIf="claim.get('bill_taxid')?.hasError('required')">
                                Billing Tax ID is required
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col lg-3">
                        <mat-form-field appearance="fill">
                            <mat-label>Billing Tax ID Type</mat-label>
                            <mat-select formControlName="bill_taxid_type" required>
                                <mat-option value="E">E</mat-option>
                                <mat-option value="EIN">EIN</mat-option>
                                <mat-option value="SSN">SSN</mat-option>
                            </mat-select>
                            <mat-error *ngIf="claim.get('bill_taxid_type')?.hasError('required')">
                                Billing Tax ID Type is required
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col lg-3">
                        <mat-form-field appearance="fill">
                            <mat-label>Patient Account Number (47147-1) </mat-label>
                            <input matInput formControlName="pcn" required />
                            <mat-error *ngIf="claim.get('pcn')?.hasError('required')">
                                PCN is required
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col lg-3">

                        <mat-form-field appearance="fill" class="full-width" [ngClass]="{
                            'highlight-empty': claim.get('accept_assign')?.invalid && !claim.get('accept_assign')?.touched
                          }">
                            <mat-label>Accept Assign</mat-label>
                            <mat-select formControlName="accept_assign" required>
                                <mat-option value="Y">Yes</mat-option>
                                <mat-option value="N">No</mat-option>
                            </mat-select>
                            <mat-error *ngIf="claim.get('accept_assign')?.hasError('required')">
                                Provider Accepts Assignment of Payment is required
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col lg-4">
                        <mat-form-field appearance="fill" class="full-width" [ngClass]="{
                            'highlight-empty': claim.get('amount_paid')?.invalid && !claim.get('amount_paid')?.touched
                          }">
                            <mat-label>Amount Paid </mat-label>
                            <input matInput formControlName="amount_paid" placeholder="100.00" type="number" required />

                        </mat-form-field>

                    </div>
                    <div class="col lg-4">
                        <mat-form-field appearance="fill">
                            <mat-label>total charge </mat-label>
                            <input matInput formControlName="total_charge" placeholder="100.00" type="number"
                                required />
                        </mat-form-field>
                    </div>
                    <div class="col lg-4">
                        <mat-form-field appearance="fill" class="full-width" [ngClass]="{
                            'highlight-empty': claim.get('balance_due')?.invalid && !claim.get('balance_due')?.touched
                          }">
                            <mat-label>Balance Due</mat-label>
                            <input matInput formControlName="balance_due" placeholder="0.00" type="number" required />
                        </mat-form-field>

                    </div>

                </div>

                <h2>Provider Details </h2>
                <div class="row">
                    <div class="col lg-3">
                        <!-- Provider Details -->
                        <mat-form-field appearance="fill">
                            <mat-label>Provider First Name</mat-label>
                            <input matInput formControlName="prov_name_f" required />
                        </mat-form-field>
                    </div>
                    <div class="col lg-3">

                        <mat-form-field appearance="fill">
                            <mat-label>Provider Last Name</mat-label>
                            <input matInput formControlName="prov_name_l" required />
                        </mat-form-field>
                    </div>
                    <div class="col lg-3">
                        <mat-form-field appearance="fill">
                            <mat-label>Provider Middle Name</mat-label>
                            <input matInput formControlName="prov_name_m" />
                        </mat-form-field>
                    </div>
                    <div class="col lg-3">
                        <mat-form-field appearance="fill">
                            <mat-label>Provider NPI</mat-label>
                            <input matInput formControlName="prov_npi" required />
                        </mat-form-field>
                    </div>
                    <div class="col lg-3">
                        <mat-form-field appearance="fill">
                            <mat-label>Provider Taxonomy</mat-label>
                            <input matInput formControlName="prov_taxonomy" required />
                        </mat-form-field>
                    </div>
                </div>
                <h2>Billing Provider Information</h2>

                <div class="row">

                    <div class="col lg-4">
                        <mat-form-field appearance="fill">
                            <mat-label>Billing Name</mat-label>
                            <input matInput formControlName="bill_name" required />
                            <mat-error *ngIf="claim.get('bill_name')?.hasError('required')">
                                Billing Name is required
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col lg-4">

                        <mat-form-field appearance="fill">
                            <mat-label>Billing Address</mat-label>
                            <input matInput formControlName="bill_addr_1" required />
                            <mat-error *ngIf="claim.get('bill_addr_1')?.hasError('required')">
                                Billing Address is required
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col lg-4">

                        <mat-form-field appearance="fill">
                            <mat-label>Billing City</mat-label>
                            <input matInput formControlName="bill_city" required />
                            <mat-error *ngIf="claim.get('bill_city')?.hasError('required')">
                                Billing City is required
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col lg-3">

                        <mat-form-field appearance="fill">
                            <mat-label>Billing State</mat-label>
                            <input matInput formControlName="bill_state" required />
                            <mat-error *ngIf="claim.get('bill_state')?.hasError('required')">
                                Billing State is required
                            </mat-error>
                        </mat-form-field>

                    </div>
                    <div class="col lg-3">
                        <mat-form-field appearance="fill">
                            <mat-label>Billing ZIP</mat-label>
                            <input matInput formControlName="bill_zip" required />
                            <mat-error *ngIf="claim.get('bill_zip')?.hasError('required')">
                                Billing ZIP is required
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col lg-3">

                        <mat-form-field appearance="fill">
                            <mat-label>Billing Phone</mat-label>
                            <input matInput formControlName="bill_phone" required />
                            <mat-error *ngIf="claim.get('bill_phone')?.hasError('required')">
                                Billing Phone is required
                            </mat-error>
                        </mat-form-field>

                    </div>
                    <div class="col lg-3">

                        <mat-form-field appearance="fill">
                            <mat-label>Billing NPI</mat-label>
                            <input matInput formControlName="bill_npi" required />
                            <mat-error *ngIf="claim.get('bill_npi')?.hasError('required')">
                                Billing NPI is required
                            </mat-error>
                        </mat-form-field>

                    </div>
                </div>

                <div class="row">
                    <div class="col lg-4">

                        <mat-form-field appearance="fill">
                            <mat-label> file-Id</mat-label>
                            <input matInput formControlName="remote_fileid" required />
                            <mat-error *ngIf="claim.get('remote_fileid')?.hasError('required')">
                                fileid is required
                            </mat-error>
                        </mat-form-field>

                    </div>
                    <div class="col lg-4">

                        <mat-form-field appearance="fill">
                            <mat-label>Batch Id</mat-label>
                            <input matInput formControlName="remote_batchid" required />
                            <mat-error *ngIf="claim.get('remote_batchid')?.hasError('required')">
                                batchid is required
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col lg-4">


                        <mat-form-field appearance="fill">
                            <mat-label>Claim Id</mat-label>
                            <input matInput formControlName="remote_claimid" required />
                            <mat-error *ngIf="claim.get('remote_claimid')?.hasError('required')">
                                claimid is required
                            </mat-error>
                        </mat-form-field>
                    </div>

                </div>

























                <button mat-button color="warn" type="button" (click)="claims.removeAt(i)">
                    Remove Claim
                </button>
            </div>

            <button mat-raised-button color="primary" type="button" (click)="addClaim()">
                Add Claim
            </button>
        </div>
        <div *ngIf="claimResponse" class="claim-response">
            <span>{{ claimResponse }}</span>
            <button class="close-btn" (click)="closeResponse()">X</button>
        </div>

        <!-- Submit Button -->
        <div class="form-actions">
            <button mat-raised-button color="primary" type="submit" [disabled]="claimForm.invalid">
                Submit
            </button>
        </div>
    </mat-card>
</form>