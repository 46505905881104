import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-split-a-patient',
  templateUrl: './split-a-patient.component.html',
  styleUrls: ['./split-a-patient.component.css'],
})
export class SplitAPatientComponent implements OnInit {
  id = 0;
  constructor(
    private route: ActivatedRoute,
    private tokenService: TokenService
  ) {}
  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
  }
  tabs2: string[] = [
    'New Appointment',
    'New Forms',
    'New Invoice',
    'New Insurance',
    'New Id Proof',
    'New Emergency Contact',
    'New Pharmacy',
    'New History',
    // 'New Refferal',
    'Upload Lab Report',
    'Upload Radiology Report',
    'New Pain Chart',
    'New Claim',
    'New Claim Attachment',
  ];
  activatedTabIndex: number = 0;

  tabChange(tabIndex: number) {
    // debugger;
    this.activatedTabIndex = tabIndex;
  }
}
