<div class="container">
    <div class="row">
        <div class="col-lg regFrm mat-elevation-z8">
            <h1>Register CPT Code</h1>

            <form (keydown.enter)="$event.preventDefault()" [formGroup]="cptFrm" (ngSubmit)="formSubmit()"
                class="example-form">
                <!-- CPT Code -->
                <mat-form-field class="example-full-width">
                    <mat-label>CPT Code</mat-label>
                    <input type="text" formControlName="Code" matInput />
                    <mat-error *ngIf="cptFrm.get('Code').hasError('required')">
                        Please enter a valid CPT Code
                    </mat-error>
                </mat-form-field>

                <!-- Description -->
                <mat-form-field class="example-full-width">
                    <mat-label>Description</mat-label>
                    <input type="text" formControlName="Description" matInput />
                    <mat-error *ngIf="cptFrm.get('Description').hasError('required')">
                        Please enter a description
                    </mat-error>
                </mat-form-field>

                <!-- Price -->
                <mat-form-field class="example-full-width">
                    <mat-label>Price</mat-label>
                    <input type="text" formControlName="Price" matInput />
                    <mat-error *ngIf="cptFrm.get('Price').hasError('required')">
                        Please enter a valid price
                    </mat-error>
                    <mat-error *ngIf="cptFrm.get('Price').hasError('pattern')">
                        Price must be a valid numeric value
                    </mat-error>
                </mat-form-field>

                <!-- Submit Button -->
                <div class="text-right mt-3">
                    <button mat-button color="primary" [disabled]="!cptFrm.valid">
                        <mat-icon>save</mat-icon>
                        Save
                    </button>
                </div>

                <!-- Success Message -->
                <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-3" role="alert">
                    Successfully submitted
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>