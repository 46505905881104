import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionGuard implements CanActivate {
  id = 0;
  // hospitalList: Hospital[] = [];
  helper = new JwtHelperService();

  myToken = this.tokenService.getToken();
  isSuccess = false;
  private hospitalId: number; // Dynamic hospital ID from user context or token

  constructor(
    private subscriptionService: SubscriptionService,
    private router: Router,
    private tokenService: TokenService
  ) {
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.id = parseInt(decodedToken.nameid);
    // Replace this with actual logic to retrieve the hospital ID dynamically
    this.hospitalId = this.id;
  }

  canActivate(): Observable<boolean> {
    if (!this.hospitalId) {
      // If hospital ID is missing, redirect to login or subscription page
      this.router.navigate(['/login']);
      return of(false);
    }

    return this.subscriptionService
      .checkSubscriptionStatus(this.hospitalId)
      .pipe(
        map((response) => {
          if (response.isSubscribed) {
            return true;
          } else {
            // Redirect to the subscription page if not subscribed
            this.router.navigate(['/Subscriptions']);
            return false;
          }
        }),
        catchError((error) => {
          console.error('Error checking subscription status:', error);
          // Redirect to subscription page in case of an error
          this.router.navigate(['/Subscriptions']);
          return of(false);
        })
      );
  }

  private getLoggedInHospitalId(): number {
    // Replace this with actual logic to retrieve hospital ID from the authentication context
    // Example: Retrieve from a token or session storage
    const token = localStorage.getItem('authToken');
    if (token) {
      const payload = JSON.parse(atob(token.split('.')[1])); // Decode JWT payload
      return payload.hospitalId || null;
    }
    return null;
  }
}
