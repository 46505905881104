// claim.component.ts
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormArray,
  Validators,
  FormControl,
} from '@angular/forms';
import { map, Observable, startWith } from 'rxjs';
import { ClaimService } from 'src/app/services/claim.service';
import * as moment from 'moment';
import { data } from 'jquery';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { DoctorService } from 'src/app/services/doctor.service';
import { ActivatedRoute } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { TokenService } from 'src/app/services/token.service';
import { PatientService } from 'src/app/services/patient.service';
import { Patient } from 'src/app/Models/patient.model';
import { Hospital } from 'src/app/Models/hospital.model';
import { Doctor } from 'src/app/Models/doctor.model';
import { MatSelect } from '@angular/material/select';
import { CptcodeService } from 'src/app/services/cptcode.service';
import { HospitalService } from 'src/app/services/hospital.service';
import { ClaimAccountKey } from 'src/app/Models/claimaccountkey.model';

@Component({
  selector: 'app-claim',
  templateUrl: './claim.component.html',
  styleUrls: ['./claim.component.css'],
})
export class ClaimComponent implements OnInit {
  claimForm: FormGroup;
  claimResponse: string;
  payerList: any[] = [];
  payers: any[] = [];
  xmlList: any;
  filteredOptions: Observable<string[]>;
  filteredIcdCodes: Observable<string[]>;
  selectedPayer: { payer_name: string; payerid: string } | null = null;
  placeOfServiceCodes = [
    { code: '11', description: 'Office' },
    { code: '21', description: 'Inpatient Hospital' },
    { code: '22', description: 'Outpatient Hospital' },
    { code: '23', description: 'Emergency Room - Hospital' },
    { code: '31', description: 'Skilled Nursing Facility' },
    { code: '32', description: 'Nursing Facility' },
    { code: '41', description: 'Ambulance - Land' },
    { code: '42', description: 'Ambulance - Air or Water' },
    // Add more codes as needed
  ];
  attachmentTypes = [
    { code: 'AS', description: 'Admission Summary' },
    { code: 'A3', description: 'Allergies/Sensitivities Document' },
    { code: 'AM', description: 'Ambulance Certification' },
    { code: 'A4', description: 'Autopsy Report' },
    { code: 'BS', description: 'Baseline' },
    { code: 'BR', description: 'Benchmark Testing Results' },
    { code: 'BT', description: 'Blanket Test Results' },
    { code: 'CT', description: 'Certification' },
    { code: '13', description: 'Certified Test Report' },
    { code: '11', description: 'Chemical Analysis' },
    { code: 'CB', description: 'Chiropractic Justification' },
    { code: 'CK', description: 'Consent Form(s)' },
    { code: '10', description: 'Continued Treatment' },
    { code: 'V5', description: 'Death Notification' },
    { code: 'DA', description: 'Dental Models' },
    { code: 'DG', description: 'Diagnostic Report' },
    { code: 'DJ', description: 'Discharge Monitoring Report' },
    { code: 'DS', description: 'Discharge Summary' },
    { code: 'D2', description: 'Drug Profile Document' },
    { code: '04', description: 'Drugs Administered' },
    { code: 'DB', description: 'Durable Medical Equipment Prescription' },
    { code: 'EB', description: 'Explanation of Benefits' },
    { code: '07', description: 'Functional Goals' },
    { code: 'HC', description: 'Health Certificate' },
    { code: 'HR', description: 'Health Clinic Records' },
    { code: 'I5', description: 'Immunization Record' },
    { code: '06', description: 'Initial Assessment' },
    { code: '15', description: 'Justification for Admission' },
    { code: 'LA', description: 'Laboratory Results' },
    { code: 'M1', description: 'Medical Record Attachment' },
    { code: 'MT', description: 'Models' },
    { code: 'NN', description: 'Nursing Notes' },
    { code: 'OE', description: 'Objective Physical Examination' },
    { code: 'OB', description: 'Operative Note' },
    { code: 'OD', description: 'Orders and Treatments Document' },
    { code: 'OC', description: 'Oxygen Content Averaging Report' },
    { code: 'OX', description: 'Oxygen Therapy Certification' },
    { code: 'PQ', description: 'Paramedical Results' },
    { code: 'PE', description: 'Parenteral or Enteral Certification' },
    { code: 'P4', description: 'Pathology Report' },
    { code: 'P5', description: 'Patient Medical History Document' },
    { code: 'XP', description: 'Photographs' },
    { code: 'PZ', description: 'Physical Therapy Certification' },
    { code: 'PN', description: 'Physical Therapy Notes' },
    { code: 'B3', description: 'Physician Order' },
    { code: 'PY', description: 'Physicians Report' },
    { code: '08', description: 'Plan of Treatment' },
    { code: 'B2', description: 'Prescription' },
    { code: '09', description: 'Progress Report' },
    { code: 'PO', description: 'Prosthetics or Orthotic Certification' },
    { code: 'RB', description: 'Radiology Films' },
    { code: 'RR', description: 'Radiology Reports' },
    { code: '21', description: 'Recovery Plan' },
    { code: 'B4', description: 'Referral Form' },
    { code: 'RX', description: 'Renewable Oxygen Content Averaging Report' },
    {
      code: '03',
      description: 'Report Justifying Treatment Beyond Utilization Guidelines',
    },
    { code: 'RT', description: 'Report of Tests and Analysis Report' },
    { code: 'IR', description: 'State School Immunization Records' },
    { code: 'OZ', description: 'Support Data for Claim' },
    { code: 'SG', description: 'Symptoms Document' },
    { code: '05', description: 'Treatment Diagnosis' },
  ];
  diagnosisFields = [
    { name: 'diag_1', label: 'Diagnosis Code A' },
    { name: 'diag_2', label: 'Diagnosis Code B' },
    { name: 'diag_3', label: 'Diagnosis Code C' },
    { name: 'diag_4', label: 'Diagnosis Code D' },
    { name: 'diag_5', label: 'Diagnosis Code E' },
    { name: 'diag_6', label: 'Diagnosis Code F' },
    { name: 'diag_7', label: 'Diagnosis Code G' },
    { name: 'diag_8', label: 'Diagnosis Code H' },
    { name: 'diag_9', label: 'Diagnosis Code I' },
    { name: 'diag_10', label: 'Diagnosis Code J' },
    { name: 'diag_11', label: 'Diagnosis Code K' },
    { name: 'diag_12', label: 'Diagnosis Code L' },
  ];
  favoriteDiagnosisCodes = [];
  filteredMedicineLists: any[][] = [];
  filteredCountries: Record<string, string>[] = [];
  cptCodeList: any[] = [];
  private readonly storageKeys = {
    cptCodes: 'cptCodes',
    favoriteIcdCodes: 'favoriteIcdCodes',
  };
  // Key for local storage
  filteredLists: any[][] = [];
  patientId = 0;
  DoctorId = 0;
  icdCodeList: any[] = [];
  helper = new JwtHelperService();
  myToken = this.tokenService.getToken();
  patient: Patient;
  hospital: Hospital;
  Doctor: Doctor;
  searchText: { [key: string]: string } = {};
  searchControls: { [key: string]: FormControl } = {};
  filteredDiagnosisCodes: { [key: string]: any[] } = {};
  accountKey = '';
  today: any;
  Attachment: string;
  constructor(
    private tokenService: TokenService,
    private fb: FormBuilder,
    private claimsService: ClaimService,
    private http: HttpClient,
    private localStorageService: LocalStorageService,
    private doctorService: DoctorService,
    private patientService: PatientService,
    private route: ActivatedRoute,
    private cptCodeService: CptcodeService,
    private hospitalService: HospitalService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.patientId = res['id'];
    });
    this.getPatientInsurance(this.patientId);
    this.getPatient(this.patientId);

    this.getHospital();
    this.getPatientDoctorId(this.patientId);
    this.claimForm = this.fb.group({
      // attachmentType: [''],
      fileid: [this.generateUniqueFileId(), Validators.required],
      claim: this.fb.array([this.createClaimGroup()]),
    });
    this.fetchPayers();
    const claimArray = this.claimForm.get('claim') as FormArray;
    const firstClaimGroup = claimArray.at(0) as FormGroup;

    this.filteredOptions = firstClaimGroup.controls[
      'payer_name'
    ].valueChanges.pipe(
      startWith(''),
      map((value: string) => this._filter(value || ''))
    );

    // Watch for changes in payer_name
    firstClaimGroup
      .get('payer_name')
      ?.valueChanges.subscribe((value: string) => {
        const selectedPayer = this.payers.find(
          (payer) => payer.payer_name === value
        );

        if (selectedPayer) {
          // Autofill payerid
          firstClaimGroup.patchValue({ payerid: selectedPayer.payerid });

          // Autofill claim_form if "1500claims" is "yes"
          if (selectedPayer['1500claims'] === 'yes') {
            firstClaimGroup.patchValue({ claim_form: '1500' });
          }
          this.Attachment =
            selectedPayer['attachments'] === 'yes' ? 'Yes' : 'No';
        }
      });
    this.diagnosisFields.forEach((field) => {
      this.searchControls[field.name] = new FormControl(''); // Create FormControl for search
      this.filteredDiagnosisCodes[field.name] = [
        ...this.favoriteDiagnosisCodes,
      ]; // Clone the full list

      // Subscribe to search value changes to filter options
      this.searchControls[field.name].valueChanges.subscribe((searchValue) => {
        this.filterOptions(field.name, searchValue || '');
      });
    });

    // Watch for changes in diagnosis fields to update diag_ref
    this.diagnosisFields.forEach((field) => {
      firstClaimGroup.get(field.name)?.valueChanges.subscribe(() => {
        this.updateDiagnosisReference(firstClaimGroup);
      });
    });

    this.loadFavoriteDiagnosisCodes(this.DoctorId);
    this.getProcedureCode();
  }
  // Filter logic for dropdown options
  filterOptions(fieldName: string, searchValue: string) {
    searchValue = searchValue.toLowerCase();
    this.filteredDiagnosisCodes[fieldName] = this.favoriteDiagnosisCodes.filter(
      (option) => option.Code.toLowerCase().includes(searchValue)
    );
  }
  isFieldEmpty(groupPath: string, controlName: string): boolean {
    const control = this.claimForm.get(groupPath)?.get(controlName);
    return !control?.value || control?.value.trim() === ''; // Check for empty or whitespace-only value
  }
  // Reset filtered options when dropdown opens
  onDropdownOpened(fieldName: string) {
    this.filteredDiagnosisCodes[fieldName] = [...this.favoriteDiagnosisCodes];
  }
  keepDropdownOpen(matSelect: MatSelect) {
    setTimeout(() => {
      matSelect.open();
    });
  }
  loadFavoriteDiagnosisCodes(id) {
    // Attempt to retrieve cached codes from local storage
    const cachedIcdCodes = this.localStorageService.getItem<any[]>(
      this.storageKeys.favoriteIcdCodes
    );

    if (cachedIcdCodes && cachedIcdCodes.length > 0) {
      // Load from local storage
      this.favoriteDiagnosisCodes = cachedIcdCodes;
    } else {
      // Fetch from server if not in local storage
      this.doctorService
        .getDoctorFavoriteDiagnosisCodes(id)
        .subscribe((data) => {
          // Assume data.FavoriteDignosisCodes is the array we need
          this.favoriteDiagnosisCodes = data.FavoriteDignosisCodes;

          // Cache the data in local storage
          this.localStorageService.setItem(
            this.storageKeys.favoriteIcdCodes,
            this.favoriteDiagnosisCodes
          );
        });
    }
  }
  getProcedureCode() {
    // Check local storage
    const cachedCptCodes = this.localStorageService.getItem<any[]>(
      this.storageKeys.cptCodes
    );

    if (cachedCptCodes && cachedCptCodes.length > 0) {
      // Load from local storage
      this.cptCodeList = cachedCptCodes;
      this.filteredMedicineLists.push([...this.cptCodeList]); // Ensure it's a flat array
    } else {
      // Fetch from server if not in local storage
      this.cptCodeService.getCptCodes().subscribe((data: any[]) => {
        this.cptCodeList = data;
        this.filteredMedicineLists.push([...this.cptCodeList]); // Ensure it's a flat array

        // Cache the data in local storage
        this.localStorageService.setItem(this.storageKeys.cptCodes, data);
      });
    }
  }
  onFilter(filteredList: any[], index: number): void {
    this.filteredMedicineLists[index] = filteredList;
  }

  filterMedicineList(searchTerm: string, index: number): void {
    this.filteredMedicineLists[index] = this.cptCodeList.filter((item) =>
      item.Code.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }
  showCustomField: boolean[] = [];

  toggleCustomField(index: number): void {
    this.showCustomField[index] = true;
  }

  onSelectChange(
    selectedCode: string,
    claimIndex: number,
    chargeIndex: number
  ): void {
    const chargeArray = this.charges(claimIndex);
    const chargeGroup = chargeArray.at(chargeIndex) as FormGroup;

    const selectedItem = this.cptCodeList.find(
      (item) => item.Code === selectedCode
    );
    if (!selectedItem) {
      console.warn('No matching item found for selected code:', selectedCode);
      return;
    }

    const units = chargeGroup.get('units')?.value || 0;
    const charge = parseFloat(selectedItem.UnitCharge || '0');
    const totalChargeForThisItem = charge * units;

    // Update the specific charge group
    chargeGroup.patchValue({
      charge: charge.toFixed(2),
    });

    // Recalculate total charge for all charges
    const claimArray = this.claimForm.get('claim') as FormArray;
    const claimGroup = claimArray.at(claimIndex) as FormGroup;

    const totalCharge = chargeArray.controls.reduce((sum, group) => {
      const groupCharge = parseFloat(
        (group.get('charge')?.value || '0') as string
      );
      return sum + groupCharge;
    }, 0);

    // Update the total charge field in the claim group
    claimGroup.patchValue({
      total_charge: totalCharge.toFixed(2),
    });

    console.log('Updated Charge Group:', chargeGroup.value);
    console.log('Updated Total Charge:', claimGroup.get('total_charge')?.value);
  }
  getCliamKey(id: number) {
    this.hospitalService.getclaimAccountKey(id).subscribe((res) => {
      // Assuming res.ClaimAccountKeys is an array, take the first item's AccountKey
      if (res.ClaimAccountKeys && res.ClaimAccountKeys.length > 0) {
        this.accountKey = res.ClaimAccountKeys[0].AccountKey;
      } else {
        console.error(
          'No ClaimAccountKeys found for the provided hospital ID.'
        );
      }
    });
  }

  getPatientInsurance(id: number) {
    this.patientService.getPatientInsurance(id).subscribe((res) => {
      const claimAArray = this.claimForm.get('claim') as FormArray;
      const firstAClaimGroup = claimAArray.at(0) as FormGroup;
      // Patch the form values
      firstAClaimGroup.patchValue({
        ins_group: res.PatientInsurances[0].GroupNo,
        ins_number: res.PatientInsurances[0].InsuaranceNo,
        pat_rel: res.PatientInsurances[0].InsuresRelationToPatient,
        //   payer_name: res.PatientInsurances[0].InsuaranceName,
        // payerid: res.PatientInsurances[0].PayerId,
      });
    });
  }
  getPatient(id) {
    this.patientService.getPatientById(id).subscribe((res) => {
      const claimXzArray = this.claimForm.get('claim') as FormArray;
      const firstXzClaimGroup = claimXzArray.at(0) as FormGroup;
      // Patch the form values
      firstXzClaimGroup.patchValue({
        pcn: res.PatientId,
        pat_name_f: res.PatientFirstName,
        pat_name_l: res.PatientLastName,
        ins_name_f: res.PatientFirstName,
        ins_name_l: res.PatientLastName,
        pat_dob: res.PatientDOB,
        ins_dob: res.PatientDOB,
        pat_sex: res.PatientGender,
        ins_sex: res.PatientGender,
        ins_addr_1: res.PatientAddress,
        pat_addr_1: res.PatientAddress,
        pat_zip: res.Zip,
        ins_zip: res.Zip,
        pat_city: res.City,
        ins_city: res.City,
        pat_state: res.State,
        ins_state: res.State,
      });
    });
  }
  getHospital() {
    this.patientService.getpatienHospitalId(this.patientId).subscribe((res) => {
      this.getCliamKey(res.Hospital.HospitalId);
      const claimHzArray = this.claimForm.get('claim') as FormArray;
      const firstHzClaimGroup = claimHzArray.at(0) as FormGroup;
      // Patch the form values
      firstHzClaimGroup.patchValue({
        bill_addr_1: res.Hospital.HospitalAddress,
        bill_name: res.Hospital.HospitalName,
        bill_npi: res.Hospital.NpiNumber,
        bill_state: res.Hospital.State,
        bill_city: res.Hospital.City,
        bill_zip: res.Hospital.ZipCode,
        bill_phone: res.Hospital.HospitalPhone,
        bill_taxid: res.Hospital.TaxId,
      });
    });
  }
  getDoctor(id) {
    this.doctorService.getDoctorById(id).subscribe((res) => {});
  }

  getPatientDoctorId(id) {
    this.patientService.getPatientDoctorId(id).subscribe((res) => {
      this.Doctor = res.Doctor;
      const npi = res.Doctor.NpiNumber;
      if (this.Doctor?.DoctorName) {
        // Split the DoctorName into first and last name
        const [firstName, ...lastNameParts] = this.Doctor.DoctorName.split(' '); // Splits into an array
        const lastName = lastNameParts.join(' '); // Join remaining parts to handle names with titles like "Doe MD"
        const claimXArray = this.claimForm.get('claim') as FormArray;
        const firstXClaimGroup = claimXArray.at(0) as FormGroup;
        // Patch the form values
        firstXClaimGroup.patchValue({
          prov_name_f: firstName,
          prov_name_l: lastName,
          ref_name_f: firstName,
          ref_name_l: lastName,
          prov_npi: npi,
          ref_npi: npi,
        });
      }
    });
  }
  updateDiagnosisReference(claimGroup: FormGroup): void {
    // Get all filled diagnosis fields
    const filledDiagnoses = Object.keys(claimGroup.controls)
      .filter((key) => key.startsWith('diag_') && claimGroup.get(key)?.value) // Check if the field is filled
      .map((key, index) => String.fromCharCode(65 + index)); // Map to letters A, B, C...

    const diagRef = filledDiagnoses.join(''); // Combine letters (e.g., "AB" for diag_1 and diag_2)

    // Update diag_ref in all charges
    const chargeArray = claimGroup.get('charge') as FormArray;
    chargeArray.controls.forEach((chargeGroup) => {
      chargeGroup.patchValue({ diag_ref: diagRef });
    });
  }

  get claims(): FormArray {
    return this.claimForm.get('claim') as FormArray;
  }

  charges(index: number): FormArray {
    const claimArray = this.claimForm.get('claim') as FormArray;
    const claimGroup = claimArray.at(index) as FormGroup;
    return claimGroup.get('charge') as FormArray;
  }

  createClaimGroup(): FormGroup {
    return this.fb.group({
      attach_acn: [''],

      // attach_type_code: [''],
      payerid: ['', Validators.required], //Auto
      payer_name: ['', Validators.required], //Get List drop down
      accept_assign: ['', Validators.required],
      auto_accident: ['', Validators.required],
      balance_due: ['', Validators.required], //not required
      bill_addr_1: ['', Validators.required],
      bill_city: ['', Validators.required],
      bill_name: ['', Validators.required],
      bill_npi: ['', Validators.required],
      bill_phone: ['', Validators.required],
      bill_state: ['', Validators.required],
      bill_taxid: ['', Validators.required],
      bill_taxid_type: ['EIN', Validators.required],
      bill_zip: ['', Validators.required],
      charge: this.fb.array([this.createChargeGroup()]),
      claim_form: [''],
      diag_1: [''],
      diag_2: [''],
      diag_3: [''],
      diag_4: [''],
      diag_5: [''],
      diag_6: [''],
      diag_7: [''],
      diag_8: [''],
      diag_9: [''],
      diag_10: [''],
      diag_11: [''],
      diag_12: [''],
      employment_related: ['', Validators.required],
      ins_addr_1: ['', Validators.required], //Auto
      ins_city: ['', Validators.required], //Auto
      ins_dob: ['', Validators.required], //Auto
      ins_group: ['', Validators.required],
      ins_name_f: ['', Validators.required], //Auto
      ins_name_l: ['', Validators.required], //Auto
      ins_number: ['', Validators.required], //Auto
      ins_sex: ['', Validators.required],
      ins_state: ['', Validators.required], //Max Legth2
      ins_zip: ['', Validators.required],
      pat_addr_1: ['', Validators.required],
      pat_city: ['', Validators.required],
      pat_dob: ['', Validators.required],
      pat_name_f: ['', Validators.required],
      pat_name_l: ['', Validators.required],
      pat_rel: ['', Validators.required],
      pat_sex: ['', Validators.required],
      pat_state: ['', Validators.required],
      pat_zip: ['', Validators.required],

      pcn: ['', Validators.required],
      prov_name_f: ['', Validators.required],
      prov_name_l: ['', Validators.required],
      prov_name_m: ['P'],
      prov_npi: ['', Validators.required],
      prov_taxonomy: ['208D00000X', Validators.required],
      ref_name_f: ['', Validators.required],
      ref_name_l: ['', Validators.required],
      ref_name_m: ['P'],
      ref_npi: ['', Validators.required],
      remote_batchid: [this.generateUniqueRemoteFileId(), Validators.required], //Auto
      remote_claimid: [
        this.generateUniqueRemote_claimid(),
        Validators.required,
      ], //Auto
      remote_fileid: [this.generateUniqueRemoteFileId(), Validators.required], //Auto
      amount_paid: ['', Validators.required], //not required
      total_charge: ['0.00', Validators.required],
    });
  }

  createChargeGroup(): FormGroup {
    return this.fb.group({
      charge: ['', Validators.required],
      charge_record_type: ['UN', Validators.required],
      diag_ref: ['', Validators.required],
      from_date: ['', Validators.required],
      place_of_service: ['11', Validators.required],
      proc_code: ['', Validators.required],
      remote_chgid: [
        this.generateUniqueRemote_remote_chgid(),
        Validators.required,
      ], //Auto
      thru_date: ['', Validators.required],
      units: ['1', Validators.required],
    });
  }

  addClaim(): void {
    this.claims.push(this.createClaimGroup());
  }

  addCharge(claimIndex: number): void {
    const chargeArray = this.charges(claimIndex);
    chargeArray.push(this.createChargeGroup());
    this.filteredMedicineLists.push([...this.cptCodeList]); // Add a new filtered list for the new charge
  }

  removeCharge(index: number): void {
    const claimArray = this.claimForm.get('claim') as FormArray;
    const claimGroup = claimArray.at(0) as FormGroup;

    const chargeArray = claimGroup.get('charge') as FormArray;

    // Remove the specific charge group
    chargeArray.removeAt(index);

    // Remove the corresponding filtered list
    this.filteredMedicineLists.splice(index, 1);
  }

  fetchPayers(): void {
    this.http.get('/assets/json/payer-list.json').subscribe({
      next: (res: any) => {
        this.payers = res; // Keep the original object structure for claim_form logic
      },
      error: (err) => {
        console.error('Error fetching payer list:', err);
      },
    });
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.payers
      .map((payer) => payer.payer_name)
      .filter((name) => name.toLowerCase().includes(filterValue));
  }
  private _filter1(value: string): string[] {
    const filterValue1 = value.toLowerCase();
    return this.favoriteDiagnosisCodes
      .map((x) => x.Code)
      .filter((name) => name.toLowerCase().includes(filterValue1));
  }
  onPayerChange(event: any): void {
    const selectedPayerName = event.option.value;
    this.selectedPayer =
      this.payers.find((payer) => payer.payer_name === selectedPayerName) ||
      null;

    const claimArray = this.claimForm.get('claim') as FormArray;
    const firstClaimGroup = claimArray.at(0) as FormGroup;

    // Update payerid in the form
    if (this.selectedPayer) {
      firstClaimGroup.patchValue({
        payerid: this.selectedPayer.payerid,
      });
    }
  }
  generateUniqueFileId(): string {
    const randomNumber = Math.floor(Math.random() * 10000); // Random 4-digit number
    const timestamp = Date.now(); // Current time in milliseconds
    return `${timestamp}-${randomNumber}`; // Combine timestamp and random number
  }

  generateNextFileId(): void {
    // Generate a new unique fileId and update the form
    const nextFileId = this.generateUniqueFileId();
    this.claimForm.patchValue({ fileid: nextFileId });
  }
  generateUniqueRemoteFileId(): string {
    const randomNumber = Math.floor(Math.random() * 10000); // Random 4-digit number
    const timestamp = Date.now(); // Current time in milliseconds
    return `${timestamp}-${randomNumber}`; // Combine timestamp and random number
  }

  generateNextRemoteFileId(): void {
    // Generate a new unique fileId and update the form
    const nextFileId = this.generateUniqueRemoteFileId();
    this.claimForm.patchValue({ remote_fileid: nextFileId });
  }
  generateUniqueRemote_claimid(): string {
    const randomNumber = Math.floor(Math.random() * 10000); // Random 4-digit number
    const timestamp = Date.now(); // Current time in milliseconds
    return `${timestamp}-${randomNumber}`; // Combine timestamp and random number
  }

  generateNextRemote_claimid(): void {
    // Generate a new unique fileId and update the form
    const nextFileId = this.generateUniqueRemote_claimid();
    this.claimForm.patchValue({ remote_claimid: nextFileId });
  }
  generateUniqueRemote_batchid(): string {
    const randomNumber = Math.floor(Math.random() * 10000); // Random 4-digit number
    const timestamp = Date.now(); // Current time in milliseconds
    return `${timestamp}-${randomNumber}`; // Combine timestamp and random number
  }

  generateNextRemote_batchid(): void {
    // Generate a new unique fileId and update the form
    const nextFileId = this.generateUniqueRemote_batchid();
    this.claimForm.patchValue({ remote_batchid: nextFileId });
  }
  generateUniqueRemote_remote_chgid(): string {
    const randomNumber = Math.floor(Math.random() * 10000); // Random 4-digit number
    const timestamp = Date.now(); // Current time in milliseconds
    return `${timestamp}-${randomNumber}`; // Combine timestamp and random number
  }

  generateNextRemote_remote_chgid(): void {
    // Generate a new unique fileId and update the form
    const nextFileId = this.generateUniqueRemote_remote_chgid();
    this.claimForm.patchValue({ remote_chgid: nextFileId });
  }
  generateFileName = (): string => {
    const now = new Date();
    const timestamp = `${now.getFullYear()}-${(now.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')}_${now
      .getHours()
      .toString()
      .padStart(2, '0')}-${now.getMinutes().toString().padStart(2, '0')}-${now
      .getSeconds()
      .toString()
      .padStart(2, '0')}.${now.getMilliseconds().toString().padStart(3, '0')}`;
    return `file_name_${timestamp}`;
  };

  onSubmit(): void {
    // Append AccountKey

    const formData = new FormData();
    formData.append('AccountKey', this.accountKey);
    // formData.append('AccountKey', '21291_il0wt6A031du9$5@CS#zP9oV');
    // Create a JSON file and append it to the FormData
    const claimPayload = {
      ...this.claimForm.value,
    };
    // Convert the JSON payload to a Blob
    const jsonBlob = new Blob([JSON.stringify(claimPayload)], {
      type: 'application/json',
    });

    const fileName = this.generateFileName();

    formData.append('File', jsonBlob, fileName);
    formData.append('FileName', fileName);

    this.claimsService.sendClaimToInsurance(formData).subscribe(
      (res) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(res, 'text/xml');
        const message = xmlDoc
          .querySelector('result')
          ?.getAttribute('messages');
        this.claimResponse = message || 'No response message received.';
      },
      (err) => {
        this.claimResponse = 'Failed to send the invoice. Please try again.';
      }
    );

    // this.claimsService.sendClaimToInsurance(formData).subscribe(
    //   (res) => {
    //     console.log('Response:', res);
    //   },
    //   (err) => {
    //     console.error('Error:', err);
    //   }
    // );

    // formData.append('File', jsonBlob, `Invoice_${this.invoice.InvoiceId}.json`);
    // formData.append('FileName', `Invoice_${this.invoice.InvoiceId}.json`);
  }

  exportDataAsFile(data: any): Blob {
    const jsonStr = JSON.stringify(data, null, 2); // Convert data to JSON string
    return new Blob([jsonStr], { type: 'application/json' }); // Create a Blob for the file
  }

  uploadJsonFile(file: Blob, apiUrl: string): Observable<any> {
    const formData = new FormData();
    formData.append('file', file, 'data.json'); // Append the file to FormData with a name

    const headers = new HttpHeaders({
      // Add additional headers if required by the API
    });

    return this.http.post(apiUrl, formData, { headers }); // Send the file to the API
  }
}
function parseString(xmlData: any, arg1: (err: any, result: any) => void) {
  throw new Error('Function not implemented.');
}
