<div class="container">
    <div class="row">
        <div class="col-lg regFrm mat-elevation-z8">
            <h2>New Billing Staff</h2>
            <form (keydown.enter)="$event.preventDefault()" [formGroup]="doctorFrm" (ngSubmit)="formSubmit()"
                class="example-form">
                <div class="row">
                    <div class="col-lg-4">
                        <mat-form-field appearance="fill" class="example-full-width">
                            <mat-label>Select Department</mat-label>
                            <mat-select formControlName="DepartmentId">

                                <mat-option *ngFor="let dpt of dptList"
                                    [value]="dpt.DepartmentId">{{dpt.DepartmentName}}</mat-option>

                            </mat-select>

                            <mat-error *ngIf="DepartmentId.hasError('required')">You must make a selection</mat-error>

                        </mat-form-field>
                    </div>
                    <div class="col-lg-6">
                        Upload Picture <input type="file" (change)="image($event)">


                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-4">

                        <mat-form-field class="example-full-width">
                            <mat-label>FullName</mat-label>
                            <input type="text" formControlName="FullName" matInput>
                            <mat-error *ngIf="doctorFrm.get('FullName').invalid">Please enter Full Name</mat-error>
                        </mat-form-field>

                    </div>
                    <div class="col-1">
                        <mat-form-field class="example-full-width">
                            <mat-label>Country Code</mat-label>
                            <input type="text" formControlName="CountryCode" [matAutocomplete]="auto" matInput
                                placeholder="+1, +91">
                            <mat-error>Please enter a Phone Number with country code eg: +1,+91,+44</mat-error>
                            <mat-autocomplete #auto="matAutocomplete">
                                <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                                    {{ option }}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-3">
                        <mat-form-field class="example-full-width">
                            <mat-label>Phone</mat-label>
                            <input type="text" formControlName="PhoneNumber" matInput>
                            <mat-error *ngIf="doctorFrm.get('PhoneNumber').invalid">Please enter PhoneNumber</mat-error>
                        </mat-form-field>

                    </div>
                    <div class=" col-lg-4">

                        <mat-form-field class="example-full-width">
                            <mat-label>NationalId</mat-label>
                            <input type="tel" formControlName="NationalId" matInput>
                            <mat-error *ngIf="doctorFrm.get('NationalId').invalid">Please enter National ID</mat-error>
                        </mat-form-field>
                    </div>

                </div>
                <div class="row">
                    <div class="col-lg-4">

                        <mat-form-field class="example-full-width">
                            <mat-label> D.O.B</mat-label>
                            <input type="date" formControlName="Dob" matInput>
                            <mat-error *ngIf="doctorFrm.get('Dob').invalid">Please enter Date of Birth</mat-error>
                        </mat-form-field>

                    </div>
                    <div class="col-lg-4">
                        <mat-form-field class="example-full-width">
                            <mat-label> Email</mat-label>
                            <input type="email" formControlName="Email" matInput>
                            <mat-error *ngIf="doctorFrm.get('Email').invalid">Please enter a valid email</mat-error>
                        </mat-form-field>

                    </div>
                    <div class="col-lg-4">

                        <mat-form-field class="example-full-width">
                            <mat-label> Password</mat-label>
                            <input type="text" formControlName="Password" matInput>
                            <mat-error>Please enter Password</mat-error>
                        </mat-form-field>
                        <span *ngIf="doctorFrm.get('Password')?.touched && doctorFrm.get('Password')?.invalid">
                            <mat-error *ngIf="doctorFrm.get('Password')?.errors.required">Please enter
                                Password</mat-error>
                            <mat-error *ngIf="doctorFrm.get('Password')?.errors.pattern"> Password must contain more
                                than 7
                                characters, 1 numeric, 1 upper case letter, and 1 special
                                character($$!%*?&).</mat-error>
                        </span>
                    </div>

                </div>
                <div class="row">



                    <div class="col-lg-4">

                        <mat-form-field class="example-full-width">
                            <mat-label> Address</mat-label>
                            <textarea matInput formControlName="Address" cdkTextareaAutosize
                                #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="40"></textarea>
                            <mat-error *ngIf="doctorFrm.get('Address').invalid">Please enter a valid Address</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-3">

                        <mat-form-field class="example-full-width">
                            <mat-label> Start Date</mat-label>
                            <input type="date" formControlName="StartDate" matInput>
                            <mat-error *ngIf="doctorFrm.get('StartDate').invalid">Please enter a valid
                                StartDate</mat-error>

                        </mat-form-field>
                    </div>
                    <div class="col-lg-5">

                        <p>Status</p>
                        <mat-radio-group formControlName="Status" aria-label="Select an option my-3">
                            <mat-radio-button class="mx-3" value="true">Active</mat-radio-button>
                            <mat-radio-button value="false"> Inactive</mat-radio-button>
                        </mat-radio-group>

                    </div>
                </div>
                <div class="row mt-5">
                    <h2>Capture Image from Camera</h2>
                    <div class="col-md-12">
                        <webcam [trigger]="invokeObservable" (imageCapture)="captureImg($event)" [height]="180"
                            [width]="180">
                        </webcam>
                    </div>
                    <div class="col-md-12">
                        <button type="button" class="btn btn-danger" (click)="getSnapshot()" type="button">
                            Capture Image
                        </button>
                    </div>
                    <div class="col-12">
                        <div id="results">Your taken image manifests here...</div>
                        <img [src]="webcamImage?.imageAsDataUrl" height="180px" />
                    </div>
                </div>

                <button class="float-right lgBtCl" mat-button>
                    <mat-icon>save</mat-icon>
                </button>

                <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
                    Successfully Created Radiology Member
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </form>

        </div>
    </div>

</div>
<!-- Modal -->
<div class="modal-backdrop" *ngIf="showModal"></div>
<div class="modal" *ngIf="showModal">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Alert</h5>
                <button type="button" class="btn-close" (click)="closeModal()"></button>
            </div>
            <div class="modal-body">
                <p>{{ modalMessage }}</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="closeModal()">OK</button>
            </div>
        </div>
    </div>
</div>