import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { map, Observable, startWith } from 'rxjs';
import { BillerService } from 'src/app/services/biller.service';
import { ClaimService } from 'src/app/services/claim.service';
import { DoctorService } from 'src/app/services/doctor.service';
import { HospitalService } from 'src/app/services/hospital.service';
import { PatientService } from 'src/app/services/patient.service';
import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-claim-attachment',
  templateUrl: './claim-attachment.component.html',
  styleUrls: ['./claim-attachment.component.css'],
})
export class ClaimAttachmentComponent implements OnInit {
  claimForm: FormGroup;
  selectedFile: File | null = null;
  claimResponse: string = '';
  selectedPayer: { payer_name: string; payerid: string } | null = null;
  payers: any[] = [];
  filteredOptions: Observable<string[]>;

  attachmentTypes = [
    { code: 'AS', description: 'Admission Summary' },
    { code: 'A3', description: 'Allergies/Sensitivities Document' },
    { code: 'AM', description: 'Ambulance Certification' },
    { code: 'A4', description: 'Autopsy Report' },
    { code: 'BS', description: 'Baseline' },
    { code: 'BR', description: 'Benchmark Testing Results' },
    { code: 'BT', description: 'Blanket Test Results' },
    { code: 'CT', description: 'Certification' },
    { code: '13', description: 'Certified Test Report' },
    { code: '11', description: 'Chemical Analysis' },
    { code: 'CB', description: 'Chiropractic Justification' },
    { code: 'CK', description: 'Consent Form(s)' },
    { code: '10', description: 'Continued Treatment' },
    { code: 'V5', description: 'Death Notification' },
    { code: 'DA', description: 'Dental Models' },
    { code: 'DG', description: 'Diagnostic Report' },
    { code: 'DJ', description: 'Discharge Monitoring Report' },
    { code: 'DS', description: 'Discharge Summary' },
    { code: 'D2', description: 'Drug Profile Document' },
    { code: '04', description: 'Drugs Administered' },
    { code: 'DB', description: 'Durable Medical Equipment Prescription' },
    { code: 'EB', description: 'Explanation of Benefits' },
    { code: '07', description: 'Functional Goals' },
    { code: 'HC', description: 'Health Certificate' },
    { code: 'HR', description: 'Health Clinic Records' },
    { code: 'I5', description: 'Immunization Record' },
    { code: '06', description: 'Initial Assessment' },
    { code: '15', description: 'Justification for Admission' },
    { code: 'LA', description: 'Laboratory Results' },
    { code: 'M1', description: 'Medical Record Attachment' },
    { code: 'MT', description: 'Models' },
    { code: 'NN', description: 'Nursing Notes' },
    { code: 'OE', description: 'Objective Physical Examination' },
    { code: 'OB', description: 'Operative Note' },
    { code: 'OD', description: 'Orders and Treatments Document' },
    { code: 'OC', description: 'Oxygen Content Averaging Report' },
    { code: 'OX', description: 'Oxygen Therapy Certification' },
    { code: 'PQ', description: 'Paramedical Results' },
    { code: 'PE', description: 'Parenteral or Enteral Certification' },
    { code: 'P4', description: 'Pathology Report' },
    { code: 'P5', description: 'Patient Medical History Document' },
    { code: 'XP', description: 'Photographs' },
    { code: 'PZ', description: 'Physical Therapy Certification' },
    { code: 'PN', description: 'Physical Therapy Notes' },
    { code: 'B3', description: 'Physician Order' },
    { code: 'PY', description: 'Physicians Report' },
    { code: '08', description: 'Plan of Treatment' },
    { code: 'B2', description: 'Prescription' },
    { code: '09', description: 'Progress Report' },
    { code: 'PO', description: 'Prosthetics or Orthotic Certification' },
    { code: 'RB', description: 'Radiology Films' },
    { code: 'RR', description: 'Radiology Reports' },
    { code: '21', description: 'Recovery Plan' },
    { code: 'B4', description: 'Referral Form' },
    { code: 'RX', description: 'Renewable Oxygen Content Averaging Report' },
    {
      code: '03',
      description: 'Report Justifying Treatment Beyond Utilization Guidelines',
    },
    { code: 'RT', description: 'Report of Tests and Analysis Report' },
    { code: 'IR', description: 'State School Immunization Records' },
    { code: 'OZ', description: 'Support Data for Claim' },
    { code: 'SG', description: 'Symptoms Document' },
    { code: '05', description: 'Treatment Diagnosis' },
  ];
  helper = new JwtHelperService();

  myToken = this.tokenService.getToken();
  id = 0;
  patientId = 0;
  showItem = '';
  hospitalId: number;
  accountKey = '';
  Attachment: any;
  constructor(
    private fb: FormBuilder,
    private claimsService: ClaimService,
    private http: HttpClient,
    private dialog: MatDialog,
    private hospitalService: HospitalService,
    private tokenService: TokenService,
    private billerService: BillerService,
    private doctorService: DoctorService,
    private patientService: PatientService,
    private route: ActivatedRoute
  ) {
    this.claimForm = this.fb.group({
      attach_acn: ['', Validators.required],
      attach_type_code: ['', Validators.required],
      payerid: ['', Validators.required],
      payer_name: ['', Validators.required],
      ins_number: ['', Validators.required],
      fileName: [''],
    });
  }

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.patientId = res['id'];
    });
    this.getPatientInsurance(this.patientId);

    let decodedToken = this.helper.decodeToken(this.myToken);
    this.id = parseInt(decodedToken.nameid);

    // Check the role in the decoded token
    if (decodedToken.role === 'Biller') {
      this.billerService.getbillerHospital(this.id).subscribe((res) => {
        this.hospitalId = res.Hospital.HospitalId;
        this.getCliamKey(this.hospitalId); // Fetch claim key for the biller's hospital
      });
    } else if (decodedToken.role === 'Hospital') {
      this.getCliamKey(this.id); // Fetch claim key directly using the hospital ID
    } else if (decodedToken.role === 'Doctor') {
      this.doctorService.getDoctorHospital(this.id).subscribe((res) => {
        this.hospitalId = res.Hospital.HospitalId;
        this.getCliamKey(this.hospitalId); // Fetch claim key for the biller's hospital
      });
    } else {
      console.error('Invalid role detected in token:', decodedToken.role);
    }
  }

  getCliamKey(id: number) {
    this.hospitalService.getclaimAccountKey(id).subscribe((res) => {
      // Assuming res.ClaimAccountKeys is an array, take the first item's AccountKey
      if (res.ClaimAccountKeys && res.ClaimAccountKeys.length > 0) {
        this.accountKey = res.ClaimAccountKeys[0].AccountKey;
      } else {
        console.error(
          'No ClaimAccountKeys found for the provided hospital ID.'
        );
      }
    });

    this.fetchPayers();

    this.filteredOptions = this.claimForm.controls[
      'payer_name'
    ].valueChanges.pipe(
      startWith(''),
      map((value: string) => this._filter(value || ''))
    );
  }
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.payers
      .map((payer) => payer.payer_name)
      .filter((name) => name.toLowerCase().includes(filterValue));
  }
  fetchPayers(): void {
    this.http.get('/assets/json/payer-list.json').subscribe({
      next: (res: any) => {
        this.payers = res; // Keep the original object structure for claim_form logic
      },
      error: (err) => {
        console.error('Error fetching payer list:', err);
      },
    });
  }
  getPatientInsurance(id: number) {
    this.patientService.getPatientInsurance(id).subscribe((res) => {
      // Patch the form values
      this.claimForm.patchValue({
        ins_group: res.PatientInsurances[0].GroupNo,
        ins_number: res.PatientInsurances[0].InsuaranceNo,
        pat_rel: res.PatientInsurances[0].InsuresRelationToPatient,
        //  payer_name: res.PatientInsurances[0].InsuaranceName,
        // payerid: res.PatientInsurances[0].PayerId,
      });
    });
  }
  onPayerChange(event: any): void {
    const selectedPayerName = event.option.value;
    this.selectedPayer =
      this.payers.find((payer) => payer.payer_name === selectedPayerName) ||
      null;

    // Update payerid in the form
    if (this.selectedPayer) {
      this.claimForm.patchValue({
        payerid: this.selectedPayer.payerid,
      });

      this.Attachment =
        this.selectedPayer['attachments'] === 'yes' ? 'Yes' : 'No';
    }
  }
  onFileSelected(event: any): void {
    const file = event.target.files[0]; // Get the selected file

    if (file) {
      // Get the selected attach_type_code from the form
      const attachTypeCode = this.claimForm.get('attach_type_code')?.value;

      if (!attachTypeCode) {
        console.error('Attachment type code is missing.');
        alert('Please select an attachment type before selecting a file.');
        return;
      }

      // Find the description corresponding to the selected attach_type_code
      const attachmentType = this.attachmentTypes.find(
        (it) => it.code === attachTypeCode
      );
      if (!attachmentType) {
        console.error('Invalid attachment type code.');
        alert('Invalid attachment type code.');
        return;
      }

      const description = attachmentType.description; // Get the description
      const customFileName = `${description.replace(
        /\s+/g,
        '_'
      )}_${Date.now()}.pdf`; // Create a custom file name

      const reader = new FileReader();

      // Read the file as a Blob
      reader.onload = (e: any) => {
        const fileContent = e.target.result; // Get the file content as base64 or array buffer
        const pdfBlob = new Blob([fileContent], { type: 'application/pdf' }); // Convert to Blob

        // Store the Blob and custom filename
        this.selectedFile = new File([pdfBlob], customFileName, {
          type: 'application/pdf',
        });

        // Update the form with the custom file name
        this.claimForm.patchValue({
          fileName: customFileName, // Update the form control with the custom filename
        });

        console.log(
          'Selected file and generated filename:',
          this.selectedFile.name
        );
      };

      reader.readAsArrayBuffer(file); // Read the file
    } else {
      console.error('No file selected!');
    }
  }

  uploadClaim(): void {
    if (!this.selectedFile || !this.claimForm.valid) {
      alert('Please fill all required fields and select a file!');
      return;
    }

    const formData = new FormData();

    formData.append('AccountKey', this.accountKey); // Replace with valid AccountKey
    formData.append('attach_acn', this.claimForm.get('attach_acn')?.value); // Attachment ID
    formData.append(
      'attach_type_code',
      this.claimForm.get('attach_type_code')?.value
    ); // Attachment Type Code
    formData.append('File', this.selectedFile, this.selectedFile.name); // Append the converted Blob with custom file name
    formData.append('FileName', this.selectedFile.name); // Append the file name as a separate field

    this.claimsService.sendClaimToInsurance(formData).subscribe(
      (res) => {
        this.claimResponse = 'Attachment uploaded successfully.';
      },
      (err) => {
        console.error('Upload failed', err);
        this.claimResponse = 'Failed to upload the attachment.';
      }
    );
  }
}
