import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { NerveStudyResult } from 'src/app/Models/nervestudyresult.model';
import { PatientService } from 'src/app/services/patient.service';
import { NerveStudyResultService } from 'src/app/services/nerve-study-result.service';
import { TokenService } from 'src/app/services/token.service';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'app-view-nerve-study-results',
  templateUrl: './view-nerve-study-results.component.html',
  styleUrls: ['./view-nerve-study-results.component.css'],
})
export class ViewNerveStudyResultsComponent implements OnInit {
  id: number = 0;
  isPatient: boolean = false;
  studyResult: MatTableDataSource<NerveStudyResult> = new MatTableDataSource();
  displayedColumns: string[] = [
    'date',
    'studyType',
    'studySubType',
    'nerveSite',
    'recSite',
    'latencyMs',
    'latencyNorm',
    'amplitudeMv',
    'amplitudeNorm',
    'amplitudeRatio',
    'durationMs',
    'segment',
    'distance',
    'velocityMs',
    'velocityNorm',
    'comments',
    'update',
    'delete',
  ];
  helper = new JwtHelperService();

  myToken = this.tokenService.getToken();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  CurrentDoctorId: number;

  constructor(
    private route: ActivatedRoute,
    private patientService: PatientService,
    private nerveStudyResultService: NerveStudyResultService,
    private tokenService: TokenService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.id = params['id'];
      this.getNerveStudyResult(this.id);
    });
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.CurrentDoctorId = parseInt(decodedToken.nameid);
  }

  getNerveStudyResult(id: number): void {
    this.patientService.GetnerveStudyResult(id).subscribe((response) => {
      this.studyResult = new MatTableDataSource(response.NerveStudyResults);
      this.studyResult.paginator = this.paginator;
      this.studyResult.sort = this.sort;
    });
  }

  delete(id: number): void {
    if (confirm('Are you sure you want to delete this record?')) {
      this.nerveStudyResultService.deleteNerveStudyResult(id).subscribe(() => {
        this.getNerveStudyResult(this.id);
      });
    }
  }
}
