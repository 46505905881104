import { Component, HostListener, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService } from 'src/app/services/auth.service';
import { TokenService } from 'src/app/services/token.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-two-fa-emergency-access',
  templateUrl: './two-fa-emergency-access.component.html',
  styleUrls: ['./two-fa-emergency-access.component.css'],
})
export class TwoFaEmergencyAccessComponent implements OnInit {
  otp: any;
  inputDigit: string = 'Verify Code';
  btnStaus: string = 'btn-light';
  decodedToken: any;
  UserName: any;
  id = 0;
  helper = new JwtHelperService();

  myToken = this.tokenService.getToken();

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode == KEY_CODE.Enter) {
      // Your row selection code
      // console.log(event);
      this.veryFy(event);
    }
  }
  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private tokenService: TokenService
  ) {}

  public configurations = {
    length: 5,
  };

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.UserName = res['unique_name'];
      this.decodedToken = res;
    });
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.id = parseInt(decodedToken.nameid);
  }

  onOtpChange(event: any) {
    this.otp = event;
    if (this.otp.length < this.configurations.length) {
      this.inputDigit =
        this.configurations.length - this.otp.length + ' digits left';
    }
    if (this.otp.length === this.configurations.length) {
      this.inputDigit = 'Go';
      this.btnStaus = 'btn-primary';
    }
  }

  veryFy(e) {
    let body = {
      TwoFactorCode: this.otp.toString(),
      UserName: this.UserName,
    };

    this.authService.TwoFa(body).subscribe((res) => {
      if (res === true && this.decodedToken.role == 'Admin') {
        this.router.navigate(['/adminDash/']);
      } else if (res === true && this.decodedToken.role == 'Hospital') {
        this.router.navigate(['/hospitalDash/']);
      } else if (res === true && this.decodedToken.role == 'Doctor') {
        this.router.navigate(['/doctorDash/']);
      } else if (res === true && this.decodedToken.role == 'Nurse') {
        this.router.navigate(['/nurseDash/']);
      } else if (res === true && this.decodedToken.role == 'LabManager') {
        this.router.navigate(['/labDash/']);
      } else if (res === true && this.decodedToken.role == 'PharmacyMember') {
        this.router.navigate(['/pharmacyDash/']);
      } else if (
        res === true &&
        this.decodedToken.role == 'PhysicalTherapyDoctor'
      ) {
        this.router.navigate(['/physicalTherapyDash/']);
      } else if (res === true && this.decodedToken.role == 'RadiologyMember') {
        this.router.navigate(['/radiologyDash/']);
      } else if (res === true && this.decodedToken.role == 'Biller') {
        this.router.navigate(['/billerDash/']);
      } else if (res === true && this.decodedToken.role == 'PracticeManager') {
        this.router.navigate(['/practiceManagerDash/']);
      } else if (res === true && this.decodedToken.role == 'FrontDeskStaff') {
        this.router.navigate(['frontDeskDash/']);
      } else if (res === true && this.decodedToken.role == 'MedicalAssistant') {
        this.router.navigate(['/medicalAssistantDash/']);
      } else {
        alert('not user found');
      }
    });
  }
}
export enum KEY_CODE {
  UP_ARROW = 38,
  DOWN_ARROW = 40,
  RIGHT_ARROW = 39,
  LEFT_ARROW = 37,
  Enter = 13,
}
