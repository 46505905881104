import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Doctor } from 'src/app/Models/doctor.model';
import { Patient } from 'src/app/Models/patient.model';
import { DoctorService } from 'src/app/services/doctor.service';
import { HospitalService } from 'src/app/services/hospital.service';
import { PatientService } from 'src/app/services/patient.service';
import { ServiceRequestService } from 'src/app/services/service-request.service';
import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-add-service-request',
  templateUrl: './add-service-request.component.html',
  styleUrls: ['./add-service-request.component.css'],
})
export class AddServiceRequestComponent implements OnInit {
  helper = new JwtHelperService();
  myToken = this.tokenService.getToken();

  serviceRequestForm: FormGroup;
  categories = ['Lab', 'Radiology', 'Procedure']; // Dropdown options
  id = 0;
  allItems = {
    Lab: [
      // General Tests
      { name: 'Complete Blood Count (CBC)', code: 'CBC001' },
      { name: 'Basic Metabolic Panel (BMP)', code: 'BMP002' },
      { name: 'Comprehensive Metabolic Panel (CMP)', code: 'CMP003' },
      { name: 'Lipid Panel', code: 'LIP004' },
      { name: 'Thyroid Stimulating Hormone (TSH)', code: 'TSH005' },
      { name: 'Hemoglobin A1c', code: 'HBA1C006' },

      // Vitamin and Nutrient Tests
      { name: 'Vitamin D, 25-Hydroxy', code: 'VD007' },
      { name: 'Vitamin B12', code: 'VB1216' },
      { name: 'Folate (Folic Acid)', code: 'FOL015' },
      { name: 'Iron Studies', code: 'IRON008' },
      { name: 'Ferritin', code: 'FER014' },
      { name: 'Zinc Level', code: 'ZINC017' },
      { name: 'Magnesium Level', code: 'MAG018' },
      { name: 'Calcium Level', code: 'CAL019' },

      // Inflammation and Infection
      { name: 'C-Reactive Protein (CRP)', code: 'CRP009' },
      { name: 'Erythrocyte Sedimentation Rate (ESR)', code: 'ESR012' },
      { name: 'Procalcitonin (PCT)', code: 'PCT021' },
      { name: 'Lactic Acid', code: 'LACT022' },
      {
        name: 'White Blood Cell Count (WBC) with Differential',
        code: 'WBC013',
      },
      // Kidney Function
      { name: 'Blood Urea Nitrogen (BUN)', code: 'BUN020' },
      { name: 'Creatinine', code: 'CRE023' },
      { name: 'Urinalysis', code: 'UA024' },
      { name: 'Microalbumin', code: 'MA025' },
      { name: '24-Hour Urine Protein', code: 'URP026' },
      // Liver Function

      { name: 'Liver Function Panel', code: 'LFP018' },
      { name: 'Alanine Transaminase (ALT)', code: 'ALT026' },
      { name: 'Alanine Transaminase (ALT)', code: 'ALT026' },
      { name: 'Aspartate Transaminase (AST)', code: 'AST027' },
      { name: 'Bilirubin', code: 'BILI028' },
      { name: 'Alkaline Phosphatase (ALP)', code: 'ALP029' },
      { name: 'Gamma-Glutamyl Transferase (GGT)', code: 'GGT030' },
      // Heart Health
      { name: 'Troponin I', code: 'TROP030' },
      { name: 'Troponin T', code: 'TROP031' },
      { name: 'BNP (B-type Natriuretic Peptide)', code: 'BNP032' },
      { name: 'Lipid Panel (Cholesterol, HDL, LDL)', code: 'LIP004' },
      { name: 'CK-MB (Creatine Kinase-MB)', code: 'CKMB035' },
      { name: 'High-Sensitivity CRP', code: 'HSCRP036' },
      { name: 'Apolipoprotein A1', code: 'APOA037' },
      { name: 'Apolipoprotein B', code: 'APOB038' },

      // Diabetes Monitoring
      { name: 'Glucose, Fasting', code: 'GLU033' },
      { name: 'Glucose, Random', code: 'GLU034' },
      { name: 'Hemoglobin A1c (HbA1c)', code: 'HBA1C006' },
      { name: 'Glucose Tolerance Test (GTT)', code: 'GTT041' },
      { name: 'Insulin Levels', code: 'INS042' },
      { name: 'C-Peptide', code: 'CPEP043' },

      // Endocrinology and Hormones
      { name: 'Free T3 (Triiodothyronine)', code: 'FT3044' },
      { name: 'Free T4 (Thyroxine)', code: 'FT4045' },
      { name: 'Cortisol (AM)', code: 'CORT046' },
      { name: 'Cortisol (PM)', code: 'CORT047' },
      { name: 'DHEA-S (Dehydroepiandrosterone Sulfate)', code: 'DHEA048' },
      { name: 'ACTH (Adrenocorticotropic Hormone)', code: 'ACTH049' },
      { name: 'Parathyroid Hormone (PTH)', code: 'PTH050' },
      { name: 'Testosterone (Total and Free)', code: 'TEST051' },
      { name: 'Estradiol (E2)', code: 'E2052' },

      // Blood Coagulation
      { name: 'Prothrombin Time (PT)', code: 'PT010' },
      { name: 'Partial Thromboplastin Time (PTT)', code: 'PTT011' },
      { name: 'INR (International Normalized Ratio)', code: 'INR040' },

      // Infectious Diseases
      { name: 'HIV Antigen/Antibody Test', code: 'HIV053' },
      { name: 'Hepatitis A, B, and C Panels', code: 'HEP054' },
      { name: 'COVID-19 PCR Test', code: 'COVID055' },
      { name: 'COVID-19 Antigen Test', code: 'COVID056' },
      { name: 'Tuberculosis (TB) QuantiFERON', code: 'TB057' },
      { name: 'Syphilis Screening (RPR or VDRL)', code: 'SYP058' },

      // Allergy and Immunology
      { name: 'Immunoglobulin E (IgE)', code: 'IGE046' },
      { name: 'Rheumatoid Factor (RF)', code: 'RF047' },
      { name: 'ANA (Antinuclear Antibodies)', code: 'ANA048' },
      { name: 'Allergy Panel (Food)', code: 'ALL049' },
      { name: 'Allergy Panel (Environmental)', code: 'ALL050' },
      // Genetic and Cancer Testing
      { name: 'BRCA1/BRCA2', code: 'BRCA059' },
      { name: 'Oncotype DX', code: 'ONCO060' },
      { name: 'PSA (Prostate-Specific Antigen)', code: 'PSA061' },
      { name: 'CEA (Carcinoembryonic Antigen)', code: 'CEA062' },
      { name: 'AFP (Alpha-Fetoprotein)', code: 'AFP063' },

      // Toxicology
      { name: 'Blood Alcohol Level', code: 'BAL064' },
      { name: 'Drug Screens (THC, Cocaine, Opiates, etc.)', code: 'DS065' },
      { name: 'Heavy Metals Panel (Lead, Mercury, Arsenic)', code: 'HMP066' },
    ],
    Radiology: [
      // General Radiology
      { name: 'Chest X-Ray (PA and Lateral)', code: 'XR001' },
      { name: 'Abdominal X-Ray', code: 'XR002' },
      { name: 'Pelvic X-Ray', code: 'XR003' },
      { name: 'Spinal X-Ray (Cervical)', code: 'XR004' },
      { name: 'Spinal X-Ray (Thoracic)', code: 'XR005' },
      { name: 'Spinal X-Ray (Lumbar)', code: 'XR006' },

      // Computed Tomography (CT)
      { name: 'CT Head without Contrast', code: 'CT001' },
      { name: 'CT Chest with Contrast', code: 'CT002' },
      { name: 'CT Abdomen and Pelvis without Contrast', code: 'CT003' },
      { name: 'CT Angiography (CTA) Chest', code: 'CT004' },
      { name: 'CT Angiography (CTA) Abdomen', code: 'CT005' },

      // Magnetic Resonance Imaging (MRI)
      { name: 'MRI Brain without Contrast', code: 'MRI001' },
      { name: 'MRI Brain with and without Contrast', code: 'MRI002' },
      { name: 'MRI Spine (Cervical)', code: 'MRI003' },
      { name: 'MRI Spine (Lumbar)', code: 'MRI004' },
      { name: 'MRI Knee without Contrast', code: 'MRI005' },

      // Ultrasound
      { name: 'Ultrasound Abdomen Complete', code: 'US001' },
      { name: 'Ultrasound Pelvis', code: 'US002' },
      { name: 'Ultrasound Thyroid', code: 'US003' },
      { name: 'Ultrasound Breast', code: 'US004' },
      { name: 'Ultrasound Obstetric (First Trimester)', code: 'US005' },

      // Mammography
      { name: 'Screening Mammogram', code: 'MAM001' },
      { name: 'Diagnostic Mammogram', code: 'MAM002' },

      // Nuclear Medicine
      { name: 'PET Scan (Whole Body)', code: 'PET001' },
      { name: 'Bone Scan', code: 'NM001' },
      { name: 'Thyroid Scan', code: 'NM002' },

      // Fluoroscopy
      { name: 'Barium Swallow', code: 'FL001' },
      { name: 'Upper GI Series', code: 'FL002' },
      { name: 'Hysterosalpingography (HSG)', code: 'FL003' },

      // Specialized Imaging
      { name: 'Bone Density (DEXA Scan)', code: 'DEX001' },
      { name: 'Carotid Doppler Ultrasound', code: 'DOP001' },
      { name: 'Echocardiogram (Echo)', code: 'ECHO001' },
    ],
    Procedure: [
      // General Surgery
      { name: 'Appendectomy', code: 'PROC001' },
      { name: 'Cholecystectomy (Gallbladder Removal)', code: 'PROC002' },
      { name: 'Hernia Repair', code: 'PROC003' },
      { name: 'Mastectomy', code: 'PROC004' },
      { name: 'Thyroidectomy', code: 'PROC005' },

      // Orthopedic Surgery
      { name: 'Total Knee Replacement', code: 'ORTH001' },
      { name: 'Total Hip Replacement', code: 'ORTH002' },
      { name: 'Arthroscopy (Knee)', code: 'ORTH003' },
      { name: 'Rotator Cuff Repair', code: 'ORTH004' },

      // Gastrointestinal Procedures
      { name: 'Colonoscopy', code: 'GI001' },
      { name: 'Upper Endoscopy (EGD)', code: 'GI002' },
      { name: 'Hemorrhoidectomy', code: 'GI003' },
      { name: 'Liver Biopsy', code: 'GI004' },

      // Cardiovascular Procedures
      { name: 'Coronary Angioplasty (PCI)', code: 'CARD001' },
      { name: 'Coronary Artery Bypass Grafting (CABG)', code: 'CARD002' },
      { name: 'Pacemaker Insertion', code: 'CARD003' },
      { name: 'Carotid Endarterectomy', code: 'CARD004' },

      // Obstetrics and Gynecology
      { name: 'Cesarean Section (C-Section)', code: 'OBGYN001' },
      { name: 'Hysterectomy', code: 'OBGYN002' },
      { name: 'Dilation and Curettage (D&C)', code: 'OBGYN003' },
      { name: 'Tubal Ligation', code: 'OBGYN004' },

      // Urological Procedures
      { name: 'Cystoscopy', code: 'URO001' },
      { name: 'Prostatectomy', code: 'URO002' },
      { name: 'Ureteroscopy', code: 'URO003' },

      // ENT Procedures
      { name: 'Tonsillectomy', code: 'ENT001' },
      { name: 'Septoplasty', code: 'ENT002' },
      { name: 'Myringotomy (Ear Tubes)', code: 'ENT003' },

      // Neurological Procedures
      { name: 'Craniotomy', code: 'NEURO001' },
      { name: 'Spinal Fusion', code: 'NEURO002' },
      { name: 'Ventriculoperitoneal (VP) Shunt Placement', code: 'NEURO003' },

      // Cosmetic and Reconstructive Procedures
      { name: 'Breast Augmentation', code: 'COS001' },
      { name: 'Liposuction', code: 'COS002' },
      { name: 'Rhinoplasty', code: 'COS003' },
    ],
  };

  filteredItems: { name: string; code: string }[] = [];
  isSuccess: boolean;
  hospitalId: number;
  doctorList: Doctor[];
  patient: Patient;
  doctorId: number;

  constructor(
    private fb: FormBuilder,
    private serviceRequestService: ServiceRequestService,
    private route: ActivatedRoute,
    private doctorService: DoctorService,
    private patientService: PatientService,
    private hospitalService: HospitalService,

    private tokenService: TokenService
  ) {
    this.serviceRequestForm = this.fb.group({
      orderedBy: ['', Validators.required],
      subject: ['', Validators.required],
      description: ['', Validators.required],
      category: ['', Validators.required],
      scheduledDate: ['', Validators.required],
      items: this.fb.array([]), // FormArray for multiple items
    });
  }

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });

    this.getPatient(this.id);

    let decodedToken = this.helper.decodeToken(this.myToken);
    this.doctorId = parseInt(decodedToken.nameid);
    this.getDoctor(this.doctorId);

    this.addItem(); // Add an initial item
  }

  get items(): FormArray {
    return this.serviceRequestForm.get('items') as FormArray;
  }

  onCategoryChange(): void {
    const selectedCategory = this.serviceRequestForm.get('category')?.value;
    this.filteredItems = this.allItems[selectedCategory] || [];
    // Reset items if category changes
    this.items.clear();
    this.addItem();
  }

  addItem(): void {
    const itemGroup = this.fb.group({
      name: ['', Validators.required],
      code: ['', Validators.required],
      comments: [''],
    });
    this.items.push(itemGroup);
  }

  removeItem(index: number): void {
    this.items.removeAt(index);
  }

  onItemSelected(index: number, selectedName: string): void {
    const selectedItem = this.filteredItems.find(
      (item) => item.name === selectedName
    );
    if (selectedItem) {
      this.items.at(index).get('code')?.setValue(selectedItem.code);
    }
  }
  getDoctor(id) {
    this.doctorService.getDoctorById(id).subscribe((res) => {
      this.serviceRequestForm.controls.orderedBy.setValue(res.DoctorName);
    });
  }
  formatDate(date: string | Date): string {
    const d = new Date(date);
    return d.toLocaleDateString(); // Adjust format as needed
  }

  getPatient(id) {
    this.patientService.getPatientById(id).subscribe((res) => {
      this.patient = res;
      this.serviceRequestForm.patchValue({
        subject: `Patient/${res.PatientId}`,
      });
    });
  }
  onSubmit(): void {
    if (this.serviceRequestForm.valid) {
      const serviceRequest = {
        ...this.serviceRequestForm.value,
        PatientId: this.id,
      };

      this.serviceRequestService
        .createServiceRequest(serviceRequest)
        .subscribe({
          next: () => {
            alert('Service request submitted successfully!');
            this.serviceRequestForm.reset();
            this.items.clear();
            this.addItem(); // Reset items
            this.isSuccess = true;
          },
          error: (err) => {
            console.error(err);
            alert('Failed to submit service request.');
          },
        });
    } else {
      alert('Please fill out all required fields.');
    }
  }
}
