import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-split-b',
  templateUrl: './split-b.component.html',
  styleUrls: ['./split-b.component.css'],
})
export class SplitBComponent implements OnInit {
  id = 0;
  constructor(
    private route: ActivatedRoute,
    private tokenService: TokenService
  ) {}
  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
  }
  tabs: string[] = [
    'Progress Notes',
    'After Visit Summary',
    'Medical Reports ',
    'Recent Prescriptions',
    'Lab Reports',
    'Radiology Reports',
    'Orders',
    'Test Results',
    'Study Reports',
    'Vital Reports',
    'Nurse Progress Notes',
    'Nurse Notes',
    'Inital Evaluation',
    'Refferal',
    'Rounds Book',
    'View Invoice',
    'Study Images',
    'Patient Summary',
    'Allergies',
    'Vital Chart Graph',
    'Lab Chart Graph',
    'Review Of Systems',
    'Pain Chart',
    'Claim Status',
    'ERA list',
    'Claim Notes',
  ];
  activatedTabIndex: number = 0;

  tabChange(tabIndex: number) {
    // debugger;
    this.activatedTabIndex = tabIndex;
  }
}
