<div class="col-lg regFrm mat-elevation-z8">
    <h1>After Visit Summary</h1>
    <form (keydown.enter)="$event.preventDefault()" [formGroup]="Frm" (ngSubmit)="formSubmit()" class="example-form">

        <div class="row">

            <!-- Visit Time -->
            <div class="col-lg-2">
                <mat-form-field class="example-full-width">
                    <mat-label>Visit Time</mat-label>
                    <input [ngxTimepicker]="picker" matInput formControlName="VisitTime" />
                    <ngx-material-timepicker #picker></ngx-material-timepicker>
                    <mat-error *ngIf="Frm.get('VisitTime')?.invalid">Please enter a visit time</mat-error>
                </mat-form-field>
            </div>
            <div class="col-lg-4 col-md-6">
                <mat-form-field appearance="fill" class="example-full-width">
                    <mat-label>Chief Complaint</mat-label>

                    <textarea matInput formControlName="ChiefComplaint" cdkTextareaAutosize
                        #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="40"></textarea>


                    <mat-error *ngIf="ChiefComplaint.invalid && ChiefComplaint.touched">Please enter Chief
                        Complaint</mat-error>
                </mat-form-field>
            </div>



            <!-- Next Follow Up -->
            <div class="col-lg-2">
                <mat-form-field class="example-full-width">
                    <mat-label>Next Follow Up</mat-label>
                    <input type="date" formControlName="NextFollowUp" matInput />
                    <mat-error *ngIf="Frm.get('NextFollowUp')?.invalid">Please enter a next follow-up date</mat-error>
                </mat-form-field>
            </div>

            <!-- Next Follow Up -->
            <div class="col-lg-4 col-md-6">
                <mat-form-field appearance="fill" class="example-full-width">
                    <mat-label>Follow Up Instructions</mat-label>

                    <textarea matInput formControlName="FollowUpInstructions" cdkTextareaAutosize
                        #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="40"></textarea>


                    <mat-error *ngIf="FollowUpInstructions.invalid && FollowUpInstructions.touched">Please enter Follow
                        Up Instructions</mat-error>
                </mat-form-field>
            </div>


        </div>
        <!-- Quill Editor -->
        <button type="button" color="primary" mat-raised-button class="float-right"
            (click)="pasteIntoQuill(quillEditor)">
            Paste into Editor
        </button>
        <button color="primary" mat-raised-button (click)="generate()">AI-Assistance</button>

        <div #editorContainer id="editor" class="editor-container"></div>

        <!-- Voice Recognition Buttons -->
        <button type="button" mat-raised-button color="primary" (click)="startVoiceRecognition()">Start Voice
            Recognition</button>
        <button type="button" class="mx-5" mat-raised-button color="accent" (click)="stopRecognition()">Stop</button>

        <!-- Diagnosis Code Button -->
        <button type="button" mat-raised-button (click)="openFavoriteDiagnosisPopup()" color="warn">
            <mat-icon matPrefix>add</mat-icon> Diagnosis Code
        </button>

        <!-- Success Message -->
        <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
            Successfully submitted
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <!-- Submit Button -->
        <button type="submit" class="float-right lgBtCl" mat-button>
            <mat-icon>save</mat-icon>
            Sign & Save
        </button>
    </form>
</div>